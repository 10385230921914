import * as globals from 'components/common/GlobalConstants'

const styles = theme => ({
    root: {
    },
    headerText: {
        fontSize: "12px !important",
        padding: "6px 0px !important",
        border: "1px solid white",
        borderRadius: "4px"
    },
    headerToday: {
        color: "white !important",
        backgroundColor: globals.v2.COLORS.BLUE,
        border: "1px solid " + globals.v2.COLORS.BLUE,
    },
    slotTimeLabel: {
        fontSize: "12px !important",
    },
    dayCellContent: {
        border: "1px solid red"
    },
    eventTextContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
    eventAddbutton: {
        backgroundColor: "white !important",
        border: "2px solid #69CEF1",
        borderRadius: "4px"
    },
    addButtonIcon: {
        fill: "#69CEF1 !important",
    },
    eventText: {
        textAlign: "center",
        fontSize: "11px !important",
        color: "#69CEF1 !important",
    }
})

export default styles