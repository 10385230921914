import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

const mapDispatchToProps = dispatch => { return {} }

const mapStateToProps = state => {
    return {
        onlineStatus: state.onlineStatus
    }
}

const FooterFunctionClass = (props) => {
    const {
        // drawData,
        // history,
        classes,
        onClickCancel,
        canContinueSave = false,
        onClickEdit,
        onlineStatus
    } = props

    return (
        <>
            <Grid container justify="space-between" className={classes.footerContainer}>
                <Button
                    className={classes.cancelButton}
                    onClick={onClickCancel}
                    disabled={false}
                >Back</Button>
                {onlineStatus &&
                    <Button
                        color="primary"
                        className={classes.acceptButton}
                        onClick={onClickEdit}
                        disabled={!canContinueSave}
                    >Edit</Button>
                }
            </Grid>
        </>
    )
}

const Footer = withCookies(
    connect(mapStateToProps, mapDispatchToProps)(
        withStyles(styles, { withTheme: true })(FooterFunctionClass)
    )
)
export default Footer
