import React, {Component} from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import $ from 'jquery';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ApplicationBar from 'components/common/mobile/ApplicationBar';
import SimpleBottomNavigation from 'components/common/mobile/SimpleBottomNavigation';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import { connect } from "react-redux";

import SortDropdown from "components/common/mobile/SortDropdown.js";
import FilterDropdown from "components/common/mobile/FilterDropdown.js";

import Icon from '@material-ui/core/Icon';

import styles from "./styles.js"
import { updatePatientDrawStatus, updatePatientRoomNumber, mpSign, logEvent, createSiteWorkflowDefaultValues } from "js/actions/index";

import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import ImmutableJS from "immutable";
import NoDrawListScreen from 'components/common/mobile/NoDrawListScreen.js';
import CurrentDate from 'components/common/mobile/CurrentDate.js';
import SnackBarPopup from 'components/common/mobile/SnackBarPopup.js';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

// import { ReactComponent as PrintSVG } from 'components/common/mobile/CustomOfflineSVG/print.svg';
// import { ReactComponent as PrintDisabledSVG } from 'components/common/mobile/CustomOfflineSVG/print_disabled.svg';

import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Divider from '@material-ui/core/Divider';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import * as globals from 'components/common/GlobalConstants';
import * as helper from 'components/common/Helper.js'
import ApprovalSignaturePad from 'components/common/mobile/ApprovalSignaturePad';
import CommonDialog from 'components/common/mobile/CommonDialog';
import Grid from '@material-ui/core/Grid';
import PrintLabelButton2 from 'components/common/controls/PrintLabelButton2'
import PrintLabelDialog from 'components/common/controls/PrintLabelDialog'
  
const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const mapDispatchToProps = dispatch => {
  return {
    updatePatientDrawStatus: drawItem => dispatch(updatePatientDrawStatus(drawItem)),
    mpSign: signData => dispatch(mpSign(signData)),
    updatePatientRoomNumber: patient => dispatch(updatePatientRoomNumber(patient)),
    logEvent: event => dispatch(logEvent(event)),
    createSiteWorkflowDefaultValues: site => dispatch(createSiteWorkflowDefaultValues(site))
  }
}

const mapStateToProps = state => {
  return { drawData: state.drawData }
};

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class SiteClass extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        const { cookies } = this.props;
    
        const dialogdata = {
        "require_signature" : {
            "title":"Signature", 
            "content": "Approval signature is required.", 
            "buttons" : ["OK"], 
            "visible": false, 
            eventHandler: this.onSignatureRequired
        },
        };
        
        this.state = {
            ...this.state,
            siteId : "",
            selectedPatient: "",
            selectedSkipReason: "",
            initialApprovalDialogOpened: false,
            finalApprovalDialogOpened: false,
            skipReasonDialogOpened: false,
            editRoomNumberDialogOpened: false,
            skipReasonWarningOpened: false,   
            order: cookies.get('drawsOrder') ? cookies.get('drawsOrder') : 'asc',
            orderBy: cookies.get('selectedHouseholdOrderBy') ? cookies.get('selectedHouseholdOrderBy') : 'name',
            filteredBy: cookies.get('selectedHouseholdFilter') ? cookies.get('selectedHouseholdFilter') : "filter_all",
            snackbarOpened: false,
            snackbarMessage: "",
            snackbarVariant: "default",
            searchPatientModeActivated: false,
            searchWord: "",
            dialogdata : dialogdata,
        }
    }

    componentDidMount() {
        this.convertProgressBarTransformIntoWidth();
        const siteId = this.props.match.params.siteId;
        const { cookies } = this.props;
        cookies.set('selected_site', siteId, { path: '/'}); // , { path: '/', maxAge: 57600 }
        
        this.props.logEvent({
            gps_log: true,
            module: "site-household", 
            action: "open_site", 
            description: "Opened site-household", 
            module_id: siteId,
            drawlist_site_id: siteId,
            patient_draw_id: ""
        })
   
        const theSite = this.props.drawData.draw_list.filter(function(site) {
          return(site.id === siteId); 
        })
    
        this.props.createSiteWorkflowDefaultValues({
            site : theSite[0]
        })
    }

    // when page refreshed
    componentDidUpdate() {
        //helper.siteHasInitialApproval()
        this.convertProgressBarTransformIntoWidth();
    }

    componentWillMount() {
        this.setState({...this.state, siteId: this.props.match.params.siteId });
    };

    convertProgressBarTransformIntoWidth() {
        // force reactjs to use percentage width instead of scalex to support border radius
        var percentage_cont = $("#draw-progress");
        var percentage_el = $("#draw-progress div:first-child");
        var percentage = percentage_cont.attr("aria-valuenow");

        percentage_el.css({
            'transform': 'none',
            'width': percentage + "%",
        });
    }

  updateFinalApprovalSignpadDimensions = () => {
    var dialog_content = $("#final-nurse-approval-dialog > div > div > div:nth-child(2)");

    var padding = parseInt(dialog_content.css("padding-left"));
    var computed_width = (dialog_content.outerWidth() - (padding * 2));
    var computed_height = (dialog_content.outerHeight() - (padding * 2));

    var signature_canvas = $("#final-approval-signature-pad-container > canvas");
    
    signature_canvas.css({
      width: computed_width, 
      height: computed_height
    });

    this.signaturePadFinal.clear();
  }

  handleFinalApprovalDialogEntered = () => {
    window.addEventListener("resize", this.updateFinalApprovalSignpadDimensions);
  }

  handleFinalApprovalDialogExited = () => {
    window.removeEventListener("resize", this.updateFinalApprovalSignpadDimensions);
  }

  handleOpenInitialApprovalDialog = () => {
    this.setState({...this.state, initialApprovalDialogOpened: true }); // , signatureWarningOpened: false
  };

  // handleCloseInitialApprovalDialog = () => {
  //   this.setState({...this.state, initialApprovalDialogOpened: false });
  // };

  handleOpenFinalApprovalDialog = () => {
    this.setState({...this.state, finalApprovalDialogOpened: true }); // , signatureFinalWarningOpened: false
  };

  // handleCloseFinalApprovalDialog = () => {
  //   this.setState({...this.state, finalApprovalDialogOpened: false });
  // };

  handleOpenSkipReasonDialog(patient) {
    this.setState({...this.state, selectedPatient: patient, skipReasonDialogOpened: true, skipReasonWarningOpened: false });
  };

  handleCloseSkipReasonDialog = () => {
    this.setState({...this.state, skipReasonDialogOpened: false });
  };
  
  handleOpenEditRoomNumberDialog(patient) {
    this.setState({...this.state, selectedPatient: patient, patientRoomNumber: patient.patient_room_number, editRoomNumberDialogOpened: true });
  };

  selectPatientRoomNumber = () => {
    this.patientRoomNumberInput.focus();
    this.patientRoomNumberInput.select();
  };

  handleCloseEditRoomNumberDialog = () => {
    this.setState({...this.state, editRoomNumberDialogOpened: false });
  };

  handleChangePatientRoomNumber = () => event => {
    this.setState({...this.state, patientRoomNumber: event.target.value});
  }

  handleRequestSort = (orderByValue) => {
    const { cookies } = this.props;
    const orderBy = orderByValue;
    let order = 'desc';

    if(this.state.orderBy === orderBy && this.state.order === 'desc') {
      order = 'asc';
    }

    cookies.set('drawsOrder', order, { path: '/'});
    cookies.set('selectedHouseholdOrderBy', orderBy, { path: '/'});
    this.setState({...this.state, order, orderBy });
  };

  handleInitialApprovalBack = () => {
    this.setState({...this.state, initialApprovalPageNumber: 1 });
  }

  handleChangeSkipReason = event => {
    this.setState({ ...this.state, selectedSkipReason: event.target.value, skipReasonWarningOpened: false });
  };

  handleSubmitSkipReason = event => {
    var skipped_reason_jQuery = $('#skip-reason-others').val();

    if(this.state.selectedSkipReason === "") {
      this.setState({ ...this.state, skipReasonWarningOpened: true});
    } else {
      if(this.state.selectedSkipReason === "others") {
        if(skipped_reason_jQuery === "") {
          this.setState({ ...this.state, skipReasonWarningOpened: true});
        } else {
          this.submitSkipReason(skipped_reason_jQuery);
        }
      } else {
        this.submitSkipReason(this.state.selectedSkipReason);
      }
    }
  };

  // handleFinalApprovalBack = () => {
  //   this.setState({...this.state, finalApprovalPageNumber: 1 });
  // }

  handleChangeFilter = (filterValue) => {
    const { cookies } = this.props;
    cookies.set('selectedHouseholdFilter', filterValue, { path: '/'});

    this.setState({...this.state, filteredBy: filterValue });
  };

  // handleInitialApprovalNext = event => {
  //   var name = $("#name-of-initial-approving-nurse").val();
  //   if(name === ""){
  //     this.handleOpenSnackbar("warning", "Name must not be empty.");
  //     return;
  //   }
  //   var pageNumber = this.state.initialApprovalPageNumber;
  //   pageNumber++;
  //   if(pageNumber === 2) pageNumber = 2;
  //   this.setState({...this.state, initialApprovalPageNumber: pageNumber, initialApprovalClearButtonDisabled : true });
    
  //   // initial trigger on load of initial approval page(display is set to block)
  //   window.dispatchEvent(new Event('resize'));
  // };

  // handleFinalApprovalNext = event => {
  //   var name = $("#name-of-final-approving-nurse").val();
  //   if(name === ""){
  //     this.handleOpenSnackbar("warning", "Name must not be empty.");
  //     return;
  //   }
  //   var pageNumber = this.state.finalApprovalPageNumber;
  //   pageNumber++;
  //   if(pageNumber === 2) pageNumber = 2;
  //   this.setState({...this.state, finalApprovalPageNumber: pageNumber, finalApprovalClearButtonDisabled : true });

  //   // initial trigger on load of initial approval page(display is set to block)
  //   window.dispatchEvent(new Event('resize'));
  // };

  handleSubmitPatientRoomNumber = event => {
    var room_number = $('#patient-room-number').val();
    
    if(room_number === "" ) {
      this.handleOpenSnackbar("danger", "Invalid Room Number.");
    } else {
      const payload = {
        "siteId": this.state.siteId ,
        "patientId":  this.state.selectedPatient.id, 
        "roomNumber": room_number,
      };
  
      console.log('handleSubmitPatientRoomNumber');
      console.log(payload);
      this.props.updatePatientRoomNumber(payload);
      this.setState({...this.state, editRoomNumberDialogOpened: false});
    }
  };

  submitSkipReason(skip_reason) {
    const payload = {
      "siteId": this.state.siteId ,
      "patientId":  this.state.selectedPatient.id, 
      "patientDrawId": this.state.selectedPatient.patient_draw[0].id,
      "type": "m", 
      "status":  "skipped",
      "notes": skip_reason
    };

    // console.log('submitSkipReason');
    // console.log(payload);
    this.props.updatePatientDrawStatus(payload);
    this.setState({...this.state, skipReasonDialogOpened: false});
  }

  goToPatient(url) {
    this.props.history.push(url);
  }

  showNurseApprovalSnackBar = () => {
    this.handleOpenSnackbar("warning", "Needs approval first...");
  }
  
  handleOpenSnackbar = (variant="default", message) => {
    this.setState({...this.state, snackbarOpened: true, snackbarVariant: variant, snackbarMessage: message});
  }
  
  handleCloseSnackbar = () => {
    this.setState({...this.state, snackbarOpened: false});
  }

  handleSearchPatientModeActivate = () => {
    if(!this.state.searchPatientModeActivated) {
      $(".contInvolvedInSearch").fadeOut();
      this.setState({...this.state, searchPatientModeActivated: true});
    }
  }

  handleSearchPatientModeDeActivate = () => {    
    if(this.state.searchPatientModeActivated) {
      $(".contInvolvedInSearch").fadeIn();
      this.setState({...this.state, searchPatientModeActivated: false});
    }
  }

  handleSearchInputChange = (evt) => {
    this.setState({...this.state, searchWord: evt.target.value});
  }

  handleClearSearchInput = () => {
    this.setState({...this.state, searchWord: ""});
  }

  initialSignaturePadOnEvent = (data) =>{
    console.log(data);
    
    if(data.command === ApprovalSignaturePad.CANCEL()) {        
      this.setState({...this.state, initialApprovalDialogOpened: false});
    }

    if(data.command === ApprovalSignaturePad.SUBMIT()) {
      this.setState({...this.state, initialApprovalDialogOpened: false});
      this.handleInitialApprovalDone(data);
    }
  }  

  finalSignaturePadOnEvent = (data) =>{
    console.log("finalSignaturePadOnEvent");
    console.log(data);
    
    if(data.command === ApprovalSignaturePad.CANCEL()) {        
      this.setState({...this.state, finalApprovalDialogOpened: false});
    }

    if(data.command === ApprovalSignaturePad.SUBMIT()) {
      this.setState({...this.state, finalApprovalDialogOpened: false});
      this.handleFinalApprovalDone(data);
    }
  }  

  onSignatureRequired = (button) => {}

  showCommonDialog = (id) => {
      var dialogdata = this.state.dialogdata;
      dialogdata[id].visible = true;
      this.setState({...this.state, dialogdata : dialogdata});
  };

  handleInitialApprovalDone = (signatureData) => {    
    if((signatureData.signature && signatureData.signature.length === 0) || signatureData.signature === "" || signatureData.signature === undefined) {
      this.showCommonDialog('require_signature');
      return;
    } 

    const payload = {
      "siteId": this.state.siteId,
      "first_name": signatureData.first_name, 
      "last_name": signatureData.last_name,
      "type": "initial",
      "signatory_type" : "head of household",
      "signature": signatureData.signature,
      "meta_data" : {     
        "ratio": signatureData.ratio,
        "canvas_width": signatureData.canvasWidth,
        "canvas_height": signatureData.canvasHeight,
        "max_draw_width": signatureData.maxDrawWidth,
        "max_draw_height": signatureData.maxDrawHeight
      }
    };

    this.props.mpSign(payload);
    this.convertProgressBarTransformIntoWidth();
  }

  handleFinalApprovalDone = (signatureData) => {    
    if((signatureData.signature && signatureData.signature.length === 0) || signatureData.signature === "" || signatureData.signature === undefined) {
      this.showCommonDialog('require_signature');
      return;
    } 

    const payload = {
      "siteId": this.state.siteId,
      "first_name": signatureData.first_name, 
      "last_name": signatureData.last_name,
      "type": "final",
      "signatory_type" : "head of household",
      "signature": signatureData.signature,
      "meta_data": {     
        "ratio": signatureData.ratio,
        "canvas_width": signatureData.canvasWidth,
        "canvas_height": signatureData.canvasHeight,
        "max_draw_width": signatureData.maxDrawWidth,
        "max_draw_height": signatureData.maxDrawHeight
      }
    };

    this.props.mpSign(payload);
    this.convertProgressBarTransformIntoWidth();
  }

  render() {
    const { classes } = this.props;
    const drawData = this.props.drawData;    
    const { selectedPatient, order, orderBy, skipReasonWarningOpened, searchPatientModeActivated, searchWord } = this.state;
    // var showFinalApprovalButton = false;
        
    if($.isEmptyObject(drawData.draw_list)) {
      return(<NoDrawListScreen />);
    }

    const siteId = this.props.match.params.siteId;

    const theSite = drawData.draw_list.filter(function(site) {
      return(site.id === siteId); 
    });

    var patientDone = 0;
    var totalPatients = 0;
    const siteInfo = theSite[0].site;
    const siteAddress = siteInfo.address + ", " + siteInfo.state + " " + siteInfo.zipcode;
    const patients = theSite[0].patient_list;  
    const siteHasInitialApproval = helper.siteHasInitialApproval(theSite[0])
    const siteHasFinalApproval = helper.siteHasFinalApproval(theSite[0])
    const siteInitialApprovalName = helper.getSiteInitialApprovalName(theSite[0])
    const siteFinalApprovalName = helper.getSiteFinalApprovalName(theSite[0])
    const siteComplete = helper.siteComplete(theSite[0])

    // console.log("Initial sign " + siteHasInitialApproval);
    // console.log("Final sign " + siteHasFinalApproval);
    // console.log("Site complete " + siteComplete);
    // console.log("Initial approval ")
    // console.log(siteInitialApprovalName);
    // console.log("Final approval ")
    // console.log(siteFinalApprovalName);

    const PatientItem = ({patient, site}) => (
      <TableRow 
        key={patient.id}
        className={(patient.patient_draw[0].status !== "remaining") ? classes.patientRowCompleted : ''}>
        
        <TableCell 
          colSpan={4}
          className={classNames(classes.patientsTableCell)}
          >
          <div className={classes.patientContainer}>
            <div className={classes.badgePatientInfoCont}>
              <div className={classes.patientInfo}
                onClick={() => {(siteHasInitialApproval) ? 
                this.goToPatient(`/patient_household/${patient.id}/${site.id}`) : 
                this.showNurseApprovalSnackBar()}}
              >
                <Typography 
                  className={classes.patientName}
                  variant="body1">
                  {patient.patient.last_name + ", " + patient.patient.first_name + " " + patient.patient.middle_name}
                </Typography>
              </div>
            </div>    
            <div onClick={() => {((!siteHasInitialApproval) && 
                      patient.patient_draw[0].status === "remaining") ?
                      this.handleOpenSkipReasonDialog(patient) : 
                      console.log('already skipped OR can skip before approval only...')}} 
              className={
                classNames(classes.optionsContainer, classes.centered, classes.statusContainer,
                  ((!siteHasInitialApproval) && 
                    patient.patient_draw[0].status === "remaining") && 
                    classes.skipButton)}
            >
              { ((!siteHasInitialApproval) && patient.patient_draw[0].status === "remaining")
              ?
                (<Typography className={classes.skipButtonLabel}>Skip</Typography>)
              :
                patient.patient_draw[0].status === "done"
                ?
                (<Typography className={classes.doneStatus}>Done</Typography>)
                :
                (<Typography className={classes.deferredStatus}>{
                  ["remaining"].indexOf(patient.patient_draw[0].status) === -1
                  ? 
                    (patient.patient_draw[0].status === "refused") ? "Unable" : patient.patient_draw[0].status.charAt(0).toUpperCase() + patient.patient_draw[0].status.slice(1)
                  : 
                    ""
                  }</Typography>)
              }
            </div>
            <div className={classNames(classes.optionsContainer, classes.centered, classes.lastTableCell)}>
              <PrintLabelButton2 onClick={()=>{this.showDocumentList(patient.patient_draw[0].labels)}} labels={patient.patient_draw[0].labels} />
            

                {/* <button 
               // disabled={!printLabelsEnabled}
                //onClick={() => {props.onClick(labels)}} 
                //onClick={() => {this.setDisplayPrintLabelsDialog(true)}} 
                className={classes.printIconCont}>
                {patient.patient_draw[0].labels.length>0
                ?
                <PrintDisabledSVG className={classNames(classes.printIcon)}></PrintDisabledSVG>
                :
                <PrintSVG className={classNames(classes.printIcon)}></PrintSVG>
                }
               
                </button> */}

            </div>   
          </div>
        </TableCell>
      </TableRow>
    );

    const patientsInjectedWithKeysForSorting = stableSort(patients, getSorting(order, orderBy)).map(patient => {
      var immuPatient = ImmutableJS.fromJS(patient).toJS();
      
      var drawItem = patient.patient_draw[0];
      if(drawItem.status === "done" || drawItem.status === "completed") {
        patientDone++;
      }
      if(drawItem.status === "skipped" || drawItem.status === "refused" || drawItem.status === "unable") {;
        patientDone++;
      }

      totalPatients++;
      
      // will be used just for sorting
      // keys that was used under row_headers
      immuPatient.patient_name = immuPatient.patient.last_name + ", " + immuPatient.patient.first_name + " " + immuPatient.patient.middle_name;
      // immuPatient.tests = immuPatient.patient_draw[0].draw_test[0].test.name;
      immuPatient.status = immuPatient.patient_draw[0].status;

      return immuPatient;
    });
    
    var filteredPatientsBySearchResult = patientsInjectedWithKeysForSorting;
    if(searchWord !== "") {
      var searchTerm = searchWord.toLowerCase();

      filteredPatientsBySearchResult = patientsInjectedWithKeysForSorting.filter(function (el) {
        var patient = el.patient;
        var last_name = patient.last_name || '';
        var first_name = patient.first_name || '';
        var middle_name = patient.middle_name || '';

        return last_name.toLowerCase().indexOf(searchTerm) !== -1 ||
        first_name.toLowerCase().indexOf(searchTerm) !== -1 ||
        middle_name.toLowerCase().indexOf(searchTerm) !== -1;
      });
    }
    
    const myPatients = stableSort(filteredPatientsBySearchResult, getSorting(order, orderBy)).map(patient => {      
      var drawItem = patient.patient_draw[0];
      var el = "";
      
      if(drawItem.status === "done" || drawItem.status === "completed") {
        if(["filter_all", "filter_completed"].indexOf(this.state.filteredBy) > -1) {
          el = <PatientItem key={patient.id} patient={patient} site={theSite[0]} />;
        }
      }

      // if(drawItem.draw_sign_off.sof_first_name === "") {
      //   showFinalApprovalButton = true;
      // }

      if(drawItem.status === "skipped" || drawItem.status === "refused" || drawItem.status === "unable") {
        // if((drawItem.status === "refused" || drawItem.status === "unable") && drawItem.draw_sign_off.sof_first_name === "") {
        //   showFinalApprovalButton = true;
        // }
        
        if(["filter_all", "filter_deferred"].indexOf(this.state.filteredBy) > -1) {
          el = <PatientItem key={patient.id} patient={patient} site={theSite[0]} />;
        }
      }
      
      if(drawItem.status === "pending" || drawItem.status === "" || drawItem.status==="remaining") {
        if(["filter_all", "filter_remaining"].indexOf(this.state.filteredBy) > -1) {
          el = <PatientItem key={patient.id} patient={patient} site={theSite[0]} />;
        }
      }

      return(el);
    });

    var filteredMyPatients = myPatients.filter(function (el) {
      return el !== "";
    });

    var percentage = Math.round(patientDone/totalPatients * 100)

    // showFinalApprovalButton = (
    //   patients[0].patient_draw[0].draw_sign_on.son_first_name !== "" &&
    //   percentage === 100 && 
    //   showFinalApprovalButton) ? true : false;

    // showFinalApprovalButton = (
    //   patients[0].patient_draw[0].draw_sign_on.son_first_name !== "" &&
    //   percentage === 100
    //   ) ? true : false;

    const sort_values = [
      { id: 'patient_name', label: 'Name' },
      // { id: 'patient_room_number', label: 'Room' },
      // { id: 'fasting', label: 'Fasting' },
      { id: 'status', label: 'Status' },
    ];

    const filter_values = [
      { id: 'filter_all', label: 'All' },
      { id: 'filter_completed', label: 'Completed' },
      { id: 'filter_deferred', label: 'Deferred' },
      { id: 'filter_remaining', label: 'Remaining' },
    ];

    // const nurseNameInputProps = {
    //   className: classes.resizeTextField
    // };

    var channel_url;

    // ** Need to confirm if Site has a source/channel, default for now
    // if(patient[0].patient_draw[0].source) {        
    //     channel_url = patient[0].patient_draw[0].source.url
    //     // if has channel value but not existing then point to default channel 
    //     if(!globals.CHANNEL_DATA.hasOwnProperty(channel_url)){
    //       channel_url = globals.DEFAULT_CHANNEL_URL
    //     }        
    // } else {
      channel_url = globals.DEFAULT_CHANNEL_URL
    // }

    const channel = globals.CHANNEL_DATA[channel_url]
    
    return(
      <div className={classes.root}>
            
        <main className={classes.content}>
            <div className={classNames(classes.headerInfo)}>
              <ApplicationBar back="/sites" icon="home" title={siteInfo.name} channel={channel} isInfoHidden={searchPatientModeActivated}  />

              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
                spacing={8}
              >
                {!searchPatientModeActivated &&
                  <Grid item xs={7}>
                    <Typography className={classes.siteAddress}>{siteAddress}</Typography> 
                  </Grid>
                }
                {!searchPatientModeActivated &&
                  <Grid item xs={5}>
                    <CurrentDate />
                  </Grid>
                }
              </Grid>

              { !siteHasInitialApproval &&
                <ListItem 
                  className={classNames(classes.obtainApprovalMessageContainer, "contInvolvedInSearch")}>
                  <Icon className={classes.obtainApprovalIcon}>error_outline</Icon>
                  <ListItemText
                    primary="Obtain initial approval to begin draws."
                    classes={{
                      root: classes.obtainApprovalMessageRoot,
                      primary: classes.obtainApprovalMessage
                    }}
                  />
                </ListItem>
              }

              { siteHasInitialApproval &&
                <div className={classNames(classes.initialApprovalContainer, "contInvolvedInSearch")}>
                  <Typography className={classes.initialFinalApprovalText}>Approved by [Initial]:</Typography>    
                  <Typography className={classes.initialFinalApprovalText}>{siteInitialApprovalName.first_name + " " + siteInitialApprovalName.last_name}</Typography>
                </div>           
              }

              { percentage === 100 && siteHasFinalApproval && 
                <div className={classNames(classes.initialApprovalContainer, "contInvolvedInSearch")}>
                  <Typography className={classes.initialFinalApprovalText}>Approved by [Final]:</Typography>
                  {/* <Typography className={classes.initialFinalApprovalText}>{siteFinalApprovalName}</Typography> */}
                  <Typography className={classes.initialFinalApprovalText}>{siteFinalApprovalName.first_name + " " + siteFinalApprovalName.last_name}</Typography>
                </div>
              }
              
              { siteHasInitialApproval &&
                <div className={classNames(classes.progressContainer, "contInvolvedInSearch")}>
                  <LinearProgress 
                    id="draw-progress"
                    className={classNames(
                      classes.allowanceTopBottom, 
                      classes.progressBar,
                    )}
                    classes={{
                      colorPrimary: classes.linearColorPrimary,
                      barColorPrimary: classes.linearBarColorPrimary
                    }}
                    variant="determinate"
                    value={percentage} />
                  
                  <Typography
                        style={{float: "left"}}
                        className={classes.progressPercentageText}>
                        {patientDone}/{totalPatients}
                    </Typography>
                  <Typography
                    align="right"
                    className={classes.progressPercentageText}>
                    {percentage}% Complete</Typography>
                </div>
              }
            </div>

            <div className={classNames(classes.patientListContainer)}>
              <Paper component="form" className={classNames(classes.searchPatientContainer, searchPatientModeActivated ? classes.searchPatientContainerFocused: "")}>
                <IconButton 
                  type="button" 
                  className={classes.searchPatientIcon} 
                  aria-label="search">
                  <SearchIcon style={{ fontSize: 18 }} />
                </IconButton>
                <InputBase
                  fullWidth
                  // onBlur={this.handleSearchPatientModeDeActivate} 
                  onFocus={this.handleSearchPatientModeActivate} 
                  onChange={this.handleSearchInputChange}
                  placeholder={searchPatientModeActivated ? "Begin typing to search patients" : "Search"}
                  inputProps={{ 
                    'aria-label': 'search patient',
                    className: classes.searchPatientInput
                  }}
                  value={searchWord}
                />
                {searchWord !== "" &&
                  <IconButton 
                    onClick={this.handleClearSearchInput}
                    className={classes.searchPatientIcon}
                    aria-label="clear-search">
                    <HighlightOffIcon style={{ fontSize: 18 }} />
                  </IconButton>
                }
                {(searchWord !== "" && searchPatientModeActivated) &&
                  <Divider className={classes.divider} orientation="vertical" />
                }
                { searchPatientModeActivated && 
                  <IconButton 
                    onClick={this.handleSearchPatientModeDeActivate}
                    className={classes.doneButton} 
                    aria-label="directions">
                    <CheckCircleOutlineIcon style={{ fontSize: 18 }} />
                  </IconButton>
                }
              </Paper>
              
              <FilterDropdown
                containerClassNames={classNames(classes.filterDropdownContainer)}
                filter_values={filter_values}
                filterBy={this.state.filteredBy}
                onChangeFilter={this.handleChangeFilter}
              />

              <SortDropdown
                containerClassNames={classNames(classes.sortDropdownContainer)}
                sort_values={sort_values}
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
              />

              <Table className={classes.myTable}>
                <TableBody>
                  {filteredMyPatients.length === 0
                  ?
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.sitesSortableCell} colSpan={4}>
                            <Typography className={classNames(classes.tableCell, classes.centered)} variant="body2">No data to show.</Typography>
                        </TableCell>
                    </TableRow>
                  : 
                    filteredMyPatients
                  }
                </TableBody>
              </Table>
              
              <div className={classNames(classes.centered, classes.bottomButtonsContainer, "contInvolvedInSearch")}>
                {/* { (patients[0].patient_draw[0].draw_sign_on.son_first_name === "" || 
                   patients[0].patient_draw[0].draw_sign_on.son_first_name === null) &&
                  <Button
                      onClick={() => {this.handleOpenInitialApprovalDialog();}}
                      variant="contained"
                      size="large" 
                      color="primary"
                      className={classNames(classes.allowanceTop, classes.approvalButton, classes.margins)}>
                        Initial Approval
                    </Button>
                } */}

              { !siteHasInitialApproval &&
                  <Button
                      onClick={() => {this.handleOpenInitialApprovalDialog();}}
                      variant="contained"
                      size="large" 
                      color="primary"
                      className={classNames(classes.allowanceTop, classes.approvalButton, classes.margins)}>
                        Initial Approval
                    </Button>
                }

                { (siteComplete && siteHasInitialApproval && !siteHasFinalApproval) &&
                  <Button
                      onClick={() => {this.handleOpenFinalApprovalDialog();}}
                      variant="contained"
                      size="large" 
                      color="primary"
                      className={classNames(classes.allowanceTop, classes.approvalButton, classes.margins)}>
                        Final Approval
                    </Button>
                }

                { (siteHasInitialApproval && percentage === 100 && siteHasFinalApproval) &&
                  <div>
                    <Typography variant="h5">Site Draws Completed!</Typography>
                    {/* <Icon className={classes.greatJobIcon}>check_circle</Icon> */}
                    <Link to={'/sites'}>
                      <Button 
                        className={classNames(classes.allowanceTop, classes.approvalButton)}
                        variant="contained" 
                        color="primary">Done</Button></Link>
                  </div>
                }
              </div>
            </div>
            
            <div className={classes.toolbar} />
            <div className={classes.toolbar} />
        </main>
        <SimpleBottomNavigation />

        <CommonDialog data={this.state.dialogdata['require_signature']} />
        <ApprovalSignaturePad 
          visible={this.state.initialApprovalDialogOpened} 
          onEvent={this.initialSignaturePadOnEvent}
          signatureType="initial"
          siteType={globals.HOUSEHOLD}
        />
        <ApprovalSignaturePad
          firstName={siteInitialApprovalName.first_name}
          lastName={siteInitialApprovalName.last_name}
          visible={this.state.finalApprovalDialogOpened} 
          onEvent={this.finalSignaturePadOnEvent}
          signatureType="final"
          siteType={globals.HOUSEHOLD}
        />
         
        <Dialog
          id="skip-patient-reason-dialog"
          fullScreen={false}
          classes={{
            paper: classes.dialogPaper
          }}
          open={this.state.skipReasonDialogOpened}
          // onClose={this.handleCloseSkipReasonDialog}
          // onEnter={this.handleOnShowDialog}
          aria-labelledby="responsive-dialog-title"
          >
          <DialogTitle id="responsive-dialog-title" onClose={this.handleCloseSkipReasonDialog}>Patient Skip Reason</DialogTitle>
          <DialogContent>
              <br/>
              <FormControl component="fieldset">
                  <FormLabel component="legend" style={{color: "rgba(0, 0, 0, 0.87)"}}>
                    Please select an option for skipping patient "{selectedPatient !== "" && 
                      selectedPatient.patient.last_name + ", " + selectedPatient.patient.first_name + " " + selectedPatient.patient.middle_name}":
                    </FormLabel><br/>
                  <RadioGroup
                      aria-label="Please select an option." 
                      name="skip_reason_option"
                      className={classes.group}
                      value={this.state.selectedSkipReason}
                      onChange={this.handleChangeSkipReason}
                  >
                  {/* <FormControlLabel value="ate_prior" control={<Radio className={classes.radioButton} />} label="Ate Prior" />
                  <FormControlLabel value="not_well" control={<Radio className={classes.radioButton} />} label="Not Well" />
                  <FormControlLabel value="visitors" control={<Radio className={classes.radioButton} />} label="Visitors" />
                  <FormControlLabel value="deceased" control={<Radio className={classes.radioButton} />} label="Deceased" /> */}
                  <FormControlLabel value="not present" control={<Radio className={classes.radioButton} />} label="Not Present" />
                  <FormControlLabel value="refused" control={<Radio className={classes.radioButton} />} label="Refused" />
                  <FormControlLabel value="unwell" control={<Radio className={classes.radioButton} />} label="Unwell" />
                      <FormControlLabel value="others" control={<Radio className={classes.radioButton} />} label="Others" />
                  </RadioGroup>
                  { this.state.selectedSkipReason === "others" &&
                  <TextField
                      id="skip-reason-others"
                      label="Please specify other reason..."
                      multiline
                      rows="4"
                      margin="normal"
                      defaultValue={this.state.selectedSkipReason === "others" ? this.state.selectedPatient.patient_draw[0].status_notes_m : ""}
                      />
                  }

                  { skipReasonWarningOpened && <Typography className={classes.signaturePopover} color="error">** Skip Reason is required.</Typography>}
              </FormControl>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={this.handleCloseSkipReasonDialog} className={classNames(classes.signatureButton)} variant="contained" color="default">Close</Button> */}
            <Button onClick={this.handleSubmitSkipReason} className={classNames(classes.signatureButton)} variant="contained" color="secondary">Submit</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          id="edit-patient-room-number-dialog"
          fullScreen={false}
          classes={{
            paper: classes.dialogPaper
          }}
          open={this.state.editRoomNumberDialogOpened}
          onEntered={this.selectPatientRoomNumber}
          aria-labelledby="responsive-dialog-title"
          >
          <DialogTitle id="responsive-dialog-title" onClose={this.handleCloseEditRoomNumberDialog}>Edit Room Number</DialogTitle>
          <DialogContent>
            <br />
            <FormControl component="fieldset">
              <FormLabel component="legend" style={{color: "rgba(0, 0, 0, 0.87)"}}>
                Please enter/change Room Number of "{selectedPatient !== "" && 
                  selectedPatient.patient.last_name + ", " + selectedPatient.patient.first_name + " " + selectedPatient.patient.middle_name}":
                </FormLabel>
                <TextField
                  id="patient-room-number"
                  inputRef={input => { this.patientRoomNumberInput = input; }}
                  value={this.state.patientRoomNumber}
                  onChange={this.handleChangePatientRoomNumber()}
                  type="number"
                  InputProps={{
                    classes: {
                      input: classes.textFieldRoomNumber,
                    },
                  }}
                  margin="normal"
                  // autoFocus
                />
            </FormControl>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={this.handleCloseEditRoomNumberDialog} className={classNames(classes.buttons)} variant="contained">Close</Button> */}
            <Button onClick={this.handleSubmitPatientRoomNumber} className={classNames(classes.buttons)} variant="contained" color="secondary">Submit</Button>
          </DialogActions>
        </Dialog>

        <PrintLabelDialog showDocumentList={(m)=>{this.showDocumentList=m}} onClick={(label)=>{helper.printLabel(label) }} />
        {/* <PrintLabelDialog onClick={(label)=>{helper.printLabel(label)}}  /> */}

        <SnackBarPopup 
          open={this.state.snackbarOpened}
          onClose={this.handleCloseSnackbar}
          variant={this.state.snackbarVariant}
          message={<span id="snackbar">{this.state.snackbarMessage}</span>}
        ></SnackBarPopup>
      </div>
    );
  }
}

SiteClass.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
};

const Site =  withCookies( connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles)(SiteClass))));
export default Site;