import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import ApplicationBar from 'components/common/mobile/ApplicationBar';
import SimpleBottomNavigation from 'components/common/mobile/SimpleBottomNavigation';
import { connect } from "react-redux";
import $ from 'jquery';

// import GridList from '@material-ui/core/GridList';
// import GridListTile from '@material-ui/core/GridListTile';
// import GridListTileBar from '@material-ui/core/GridListTileBar';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import ImmutableJS from "immutable";
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
// import LoadingSpinner from "components/common/mobile/LoadingSpinner.js";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// import Badge from '@material-ui/core/Badge';
import ReactTimeout from 'react-timeout'
import GoogleMapComponent from './GoogleMapComponent';
import styles from './styles.js';
// import RequestTimeoutScreen from 'components/common/mobile/RequestTimeoutScreen';
// import NoDrawListScreen from  'components/common/mobile/NoDrawListScreen';
import SwipeableViews from 'react-swipeable-views';
import TabContainer from 'components/common/mobile/TabContainer';
import MobileStepper from '@material-ui/core/MobileStepper';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
// import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import EnhancedTableHead from "components/common/mobile/EnhancedTableHead.js";
import moment from 'moment-timezone';

const mapStateToProps = state => {
  return { drawData: state.drawData};
};

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class DashboardClass extends Component {
    static propTypes = {
      cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        const { cookies } = this.props;

        this.state = {
            ...this.state, 
            timeout : false,
            milesDrivenPercentage: 0,
            googleMapKey: this.generateID(),
            tabValue: 0,
            order: cookies.get('sitesOrder') ? cookies.get('sitesOrder') : 'asc',
            orderBy: cookies.get('sitesOrderBy') ? cookies.get('sitesOrderBy') : 'site_order',
            marginBottomForLandscape: false,
        }
        this.interval = null;
        this.previousSiteOrdering = [];
    }

    componentDidMount() {
        console.log('Dashboard did mount.');
        this.setState({...this.state, timeout : false });

        // Find matches
        var mql = window.matchMedia("(orientation: portrait)");
        this.computeTabSlideContainerDimension(mql.matches);

        // If there are matches, we're in portrait
        // Add a media query change listener
        mql.addListener(function(m) {
            this.computeTabSlideContainerDimension(m.matches);
            // if(m.matches) {
            //     console.log("Portrait orientation");
            // } else {
            //     console.log("Landscape orientation");
            // }
        }.bind(this));

        // resize listener
        window.addEventListener("resize", function() {
            var mql = window.matchMedia("(orientation: portrait)");
            this.computeTabSlideContainerDimension(mql.matches);
        }.bind(this), false);
    }

    computeTabSlideContainerDimension = (isPortrait) => {
        // console.log("computeTabSlideContainerDimension......")
        // console.log("isPortrait=" + isPortrait)
        var cont = $("#tab-slide-container");
        var map_div = $("#map-component-div");
        var upcoming_sites_div = $("#upcoming-sites-div");
        var showMarginBottom = false;
        
        var window_height = window.innerHeight;
        var application_bar_height = 50;
        var overview_container = $("#overview-container").outerHeight();
        var bottom_navbar_height = 50;
        var cont_margin = 8 + 24;
        var top_floating_allowance = 15;

        var height = 0;
        if(isPortrait) {
            var computed_height = window_height - (application_bar_height + top_floating_allowance + overview_container + bottom_navbar_height + cont_margin);
            height = computed_height;
        } else {
            height = cont.width() * 1.5;
            showMarginBottom = true;
        }
        cont.css({
            "height": height + "px",
            "max-height": height + "px"
        });

        map_div.css({
            "height": (height - 60) + "px",
            "max-height": (height - 60) + "px"
        });

        upcoming_sites_div.css({
            "height": (height - 60) + "px",
            "max-height": (height - 60) + "px",
        });

        // if(this.state.tabValue === 1) {
            // if tab view === google map, redraw map
            this.setState({marginBottomForLandscape: showMarginBottom, googleMapKey: this.generateID()});
        // }
    }

    convertProgressBarTransformIntoWidth() {
        // force reactjs to use percentage width instead of scalex to support border radius
        var percentage_cont = $("#overall-progress");
        var percentage_el = $("#overall-progress div:first-child");
        var percentage = percentage_cont.attr("aria-valuenow");

        percentage_el.css({
            'transform': 'none',
            'width': percentage + "%",
        });
    }
    
    componentWillMount() {
        this.convertProgressBarTransformIntoWidth();  
    }

    componentDidUpdate() {
        // console.log("dashboard componentDidUpdate");
        
        const drawData = JSON.parse(JSON.stringify(this.props.drawData));
        if(drawData.draw_list !== undefined) {
    
            // // console.log("previous site ordering:");
            // // console.log(this.previousSiteOrdering);
        
            // // console.log("current site ordering:");
            const current_site_ordering = drawData.draw_list.map(site => {
                return {"site_name": site.site.name, "order": site.site_order};
            });
            
            // // console.log(current_site_ordering);
            if(JSON.stringify(this.previousSiteOrdering) !== JSON.stringify(current_site_ordering)) {
                this.setState({googleMapKey: this.generateID()});
                // // console.log("reinitialize map.");
            } else {
                // // console.log("do not reinitialize map.");
            }
            this.previousSiteOrdering = current_site_ordering;
            this.convertProgressBarTransformIntoWidth();
        }
    }

    componentWillReceiveProps() {
        // // console.log("dashboard componentWillReceiveProps");
        
        // const drawData = JSON.parse(JSON.stringify(this.props.drawData));
        // if(drawData.draw_list !== undefined) {
    
        //     // console.log("previous site ordering:");
        //     // console.log(this.previousSiteOrdering);
        
        //     // console.log("current site ordering:");
        //     const current_site_ordering = drawData.draw_list.map(site => {
        //         return site.site_order;
        //     });
            
        //     // console.log(current_site_ordering);
            // if(JSON.stringify(this.previousSiteOrdering) !== JSON.stringify(current_site_ordering)) {
                // this.setState({googleMapKey: this.generateID()});
                // // console.log("reinitialize map.");
            // } else {
            //     // console.log("do not reinitialize map.");
            // }
        //     this.previousSiteOrdering = current_site_ordering;
        // }
    }

    goToSite(url) {
        this.props.history.push(url);
    }

    setTimeIsUp = () => {
        // console.log("TIME IS UP");
        this.setState({...this.state, timeout : true });
    }

    handleMilesDrivenPercentageCBHandler = (responseData) => {
        var miles_driven_percentage = 0;
        if(responseData.metersDriven > 0 && responseData.totalMetersDrive > 0) {
            $("#miles-driven-container").text(this.round(responseData.metersDriven, 1) + "/" + this.round(responseData.totalMetersDriven, 1));
            miles_driven_percentage = responseData.percentage;
        }
        this.setState({...this.state, milesDrivenPercentage: miles_driven_percentage });
    }
    
    round(value, precision) {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }
    
    generateID() {
        return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
    }

    handleChangeTabIndex = (newValue) => {
        this.setState({...this.state, tabValue: newValue });
    }

    handleRequestSort = (event, property) => {
        const { cookies } = this.props;
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        cookies.set('sitesOrder', order, { path: '/'});
        cookies.set('sitesOrderBy', orderBy, { path: '/'});
        this.setState({...this.state, order, orderBy });
    };

    render() {   
        const drawData = JSON.parse(JSON.stringify(this.props.drawData));
        const { classes, theme } = this.props;
        const { order, orderBy } = this.state;   
        // console.log(drawData)
        // console.log("drawData")
        
        const phlebData = drawData.user;
        const display_name = (phlebData !== undefined) ? phlebData.first_name + "'s " : "";

        var alertsListItemsData = [];
        
        var totalSitesDone = 0;
        var totalSites = 0;
        var totalDrawsDone = 0; // whole site, including all status
        var totalDrawItems = 0; // whole site, completed and deferred
        var totalDone = 0; // whole site, including all status
        
        const SiteItem = ({data, siteDone, siteTotalDrawItems, hasStatRequest, hasFasting, isApproved}) => (
            <TableRow 
                key={data.site.id}
                className={classNames(classes.sitesTableRow, (siteDone === siteTotalDrawItems && isApproved) ? classes.siteRowCompleted : '')}>
                <TableCell 
                    colSpan={3}
                    className={classNames(classes.tableCell, classes.sitesTableCell)}
                    >
                    <div className={classes.badgesContainer}>
                        {hasFasting &&
                            <Typography
                            className={classNames(classes.badges, classes.FASTING)}
                            variant="button"></Typography>
                        }
                        { hasStatRequest &&
                            <Typography  
                            className={classNames(classes.badges, classes.STAT)}
                            variant="button"></Typography>
                        }
                        { (!hasFasting && !hasStatRequest )
                            ? 
                            <span>&nbsp;</span> : ""
                        }
                    </div>
                    <div className={classes.siteInfo}
                        onClick={() => this.goToSite(`/patient/${data.patient_list[0].id}/${data.id}`)}
                    >
                        <Typography 
                            className={classes.siteName}
                            variant="body1">
                        {data.site_order}. {data.site.name}
                        </Typography>
                        <Typography
                            className={classes.siteAddress}>
                            {data.site.address}
                        </Typography>
                        { data.site.address_line_2 !== "" &&
                        <Typography
                            className={classes.siteAddress}>
                            {data.site.address_line_2}
                        </Typography>
                        }
                        {/* Number of draws */}
                        {/* <Typography className={classes.siteDraws}>
                            {siteTotalDrawItems} {siteTotalDrawItems > 1 ? "draws" : "draw"} 
                        </Typography> */}
                    </div>                    
                </TableCell>
                <TableCell
                    colSpan={1}
                    className={classNames(classes.tableCell, classes.sitesTableCell)}
                    >
                    <div className={classes.siteInfo}>
                        <Typography
                            className={classes.siteName}
                            variant="body1">
                                { data.collection_time === undefined || data.collection_time === ""
                                ?
                                        ""
                                :
                                        moment(data.collection_time, "hh:mm:ss").format('hh:mm\xA0A')
                                }
                        </Typography>
                    </div>
                </TableCell>
            </TableRow> 
        );

        // *********************** GOOGLE MAP PLOTTING OF ADDRESSES STARTS HERE
        var mapComponentID = this.generateID();
        var zoom = 12;
        // phlebs home address
        // marked as home/test tube icon for now
        var start = {
            lat: 0.0,
            lng: 0.0,
        };

        // will be marked as star(temporarily)
        // but should have option on what icon it would be
        var end = {
            lat: 0.0,
            lng: 0.0,
        };

        if(phlebData !== undefined) {
            start = {
                lat: parseFloat(phlebData.latitude),
                lng: parseFloat(phlebData.longitude)
            };
    
            end = {
                lat: parseFloat(drawData.lab_information.latitude),
                lng: parseFloat(drawData.lab_information.longitude)
            };
        }

        // will not be used since preserveViewport=false
        var center = start;

        // phleb's car location, for now it will be placed in the
        // middle of source(solid lineSymbol) and destination(dotted/dashed)
        var way_points = [];
        var way_points_options = [];

        var site_ctr = 0;
        var phlebs_loc_plotted = false;

        // temp solution, it should be sorting on multi dimensional array
        var mySitesInjectedWithKeysForSorting = [];
        var mysites = [];
        var filteredMySites = [];
        var percentage = 0;
        var sitesCompletedPercentage = 0;
        var drawsCompletedPercentage = 0;
        
        if((phlebData !== undefined)) {
            mySitesInjectedWithKeysForSorting = drawData.draw_list.map(site => {
                var immuSite = ImmutableJS.fromJS(site).toJS();
                // overview section
                var stats = {
                    siteDone: 0,
                    siteItemCompleted: 0,
                    siteItemDeferred: 0,
                    siteItemPending: 0,
                    patientInSiteDone: 0,
                    siteTotalDrawItems: 0,
                    siteApproved: true,
                };
    
                var site_has_stat = false;
                var site_has_fasting = false;
                
                immuSite.patient_list.map(patient => {
                    patient.patient_draw.map(drawItem => {
                        if(drawItem.status === "done" || drawItem.status === "completed") {
                            // totalItemCompleted++;
                            stats.siteItemCompleted++;
                            totalDrawsDone++;
                            stats.patientInSiteDone++;
                            stats.siteDone++;
                            totalDone++;
                        }
                        if(drawItem.status === "skipped" || drawItem.status === "refused" || drawItem.status === "unable") {
                            // totalItemDeferred++;
                            stats.siteItemDeferred++;
                            totalDrawsDone++;
                            stats.patientInSiteDone++;
                            stats.siteDone++;
                            totalDone++;
                            
                            // if((drawItem.status === "refused" || drawItem.status === "unable") && drawItem.draw_sign_off.sof_first_name === "") {
                            //     // if status in[refused,unable] it needs approval first
                            //     stats.siteApproved = false;
                            // }
                        }
                        if(drawItem.status === "pending" || drawItem.status === "" || drawItem.status==="remaining") {
                            // totalItemPending++;
                            stats.siteItemPending++;
                        }
                        
                        if(patient.urgent) {
                            site_has_stat = true;
                        }
    
                        if(patient.fasting) {
                            site_has_fasting = true;
                        }
    
                        stats.siteTotalDrawItems++;
                        totalDrawItems++;
                        return '';
                    });
                
                   // if(patient.urgent && patient.patient_draw[0].status === "remaining") {
                    if(patient.hasOwnProperty('newstat') && patient.patient_draw[0].status === "remaining") {
                        site_has_stat = true;
                        alertsListItemsData.push({'site_id': site.id, 'site': site.site, 'patient': patient.patient});
                    }
    
                    return '';
                });
                
                // will be used just for sorting
                // keys that was used under row_headers
                immuSite.site_name = immuSite.site.name;
                immuSite.address = immuSite.site.address;
                immuSite.address_line_2 = (immuSite.site.address_line_2 !== undefined) ? immuSite.site.address_line_2 : '';
                immuSite.status = (stats.siteDone === stats.siteTotalDrawItems) ? "Complete" : "";
                immuSite.no_of_draws = stats.siteTotalDrawItems;
                immuSite.siteDone = stats.siteDone;
                immuSite.siteTotalDrawItems = stats.siteTotalDrawItems;
                immuSite.hasStatRequest = site_has_stat;
                immuSite.hasFasting = site_has_fasting;
                immuSite.isApproved = stats.siteApproved;
    
                totalSitesDone = totalSitesDone + ((stats.patientInSiteDone === stats.siteTotalDrawItems) ? 1 : 0);
                totalSites++;
                site_ctr++;
    
                // phlebs location
                if(// (stats.patientInSiteDone > 0) &&
                    (stats.patientInSiteDone < stats.siteTotalDrawItems) && 
                    !phlebs_loc_plotted) {
    
                    phlebs_loc_plotted = true;
                    way_points.push({
                        location: {
                            lat: parseFloat(immuSite.site.latitude), 
                            lng: parseFloat(immuSite.site.longitude)
                        },
                        stopover: true,
                    })
                    way_points_options.push(
                        {
                            labelFonts: {
                                text: "0",
                                color: 'black',
                                fontSize: "12px",
                                fontWeight: "bold"
                            },
                            type: "car"
                        }, // !important, it will determine the linesymbol if dashed or dotted
                    );
                }
                
                way_points.push({
                    location: {
                        lat: parseFloat(immuSite.site.latitude), 
                        lng: parseFloat(immuSite.site.longitude)
                    },
                    stopover: true,
                });
    
                var site_status = "not_visited";
                var markerLabelColor = 'white';
                if(stats.patientInSiteDone > 0 && stats.patientInSiteDone < stats.siteTotalDrawItems) {
                    site_status = "active";
                    markerLabelColor = "#66CAEE";
                } else if(stats.patientInSiteDone === stats.siteTotalDrawItems) {
                    // event if 100% completed but not yet approved
                    if(stats.siteApproved) {
                        site_status = "completed";
                        markerLabelColor = "#12D003";
                    } else {
                        site_status = "active";
                        markerLabelColor = "#66CAEE";
                    }
                } else if(site_has_stat) {
                    site_status = "with_stat";
                    markerLabelColor = "#FEDC4F";
                }
    
                // drawData.draw_list.length + 1 to include lab_location to countings
                way_points_options.push({ 
                    type: "site", // site_ctr === (drawData.draw_list.length + 1) ? "star" : 
                    icon: site_status,
                    labelFonts: {
                        text: site.site_order.toString(),
                        color: markerLabelColor,
                        fontSize: "16px",
                        fontWeight: "bold"
                    }
                });
    
                if(site_ctr === drawData.draw_list.length) {
                    // we will add lab_location as last way_point
                    // to add its miles driven to computation
                    way_points.push({
                        location: {
                            lat: parseFloat(drawData.lab_information.latitude),
                            lng: parseFloat(drawData.lab_information.longitude)
                        },
                        stopover: true,
                    });
                    way_points_options.push(
                        {
                            labelFonts: {
                                text: "x",
                                color: 'white',
                                fontSize: "12px",
                                fontWeight: "bold"
                            },
                            type: "star"
                        }, // !important, it will determine the linesymbol if dashed or dotted
                    );
                }
    
                return immuSite;
            });

            mysites = stableSort(mySitesInjectedWithKeysForSorting, getSorting(order, orderBy)).map(site => {
                return <SiteItem key={site.id} data={site} siteDone={site.siteDone} siteTotalDrawItems={site.siteTotalDrawItems} hasStatRequest={site.hasStatRequest} hasFasting={site.hasFasting} isApproved={site.isApproved} />;
            });
    
            filteredMySites = mysites.filter(function (el) {
              return el !== "";
            });

            percentage = Math.round(totalDone/totalDrawItems * 100);

            sitesCompletedPercentage = Math.round(totalSitesDone/totalSites * 100);
            drawsCompletedPercentage = Math.round(totalDrawsDone/totalDrawItems * 100);
        }

        const row_headers = [
            { id: 'site_order', sortingEnabled: true, label: 'Ordering' },
            { id: 'site_name', sortingEnabled: true, label: 'Site' },
            { id: 'status', sortingEnabled: true, label: 'Status' },
            { id: 'no_of_draws', sortingEnabled: true, label: 'Draws' },
        ];

        const alertsListItems = $.map(alertsListItemsData, function({site_id, site, patient}) {
            return(
                <ListItem 
                    key={patient.id} 
                    className={classes.alertsItem}
                    onClick={() => this.goToSite(`/site/${site_id}`)}>

                    <Icon className={classes.alertsIcon}>error_outline</Icon>
                    <ListItemText
                        primary={site.name + " - " + patient.last_name + ", " + patient.first_name} // "New STAT request at " + 
                        // secondary="Marleov Zoldik"
                        classes={{
                            primary: classes.alertsPrimaryText,
                            secondary: classes.alertsSecondaryText,
                        }}
                    />
                </ListItem>);
        }.bind(this));
        
        return(
            <div className={classes.root}>
                <main className={classes.content}>
                    <ApplicationBar />
                    <div className={classes.margins}>
                        <Paper id="overview-container" className={classNames(classes.paperUI, classes.overviewSection, classes.paddings)} elevation={5}>
                            <Typography variant="h5" className={classNames(classes.overviewTitle)}>
                                {display_name}Overview
                            </Typography>
                            <Grid 
                                className={classes.circularProgressContainer} 
                                container>
                                <Grid 
                                    className={classes.circularProgressContainerLeftChild}
                                    item xs>
                                    <div className={classes.wrapper}>
                                        <Fab className={classes.fab}>
                                            <img alt="draws icon" className={classes.overviewIcons} src="/images/test_tube_outline.svg" />
                                            <CircularProgress size={46.83} thickness={3.6} variant="static" value={drawsCompletedPercentage} className={classes.fabProgress} />
                                        </Fab>
                                    </div>
                                    <div className={classes.labelContainer}>
                                        <Typography className={classNames(classes.progressDetails, classes.progressLabel)} variant="body2">Draws</Typography>
                                        <Typography className={classNames(classes.progressDetails, classes.progressNumbers)} variant="h6">{totalDrawsDone}/{totalDrawItems}</Typography>
                                    </div>
                                </Grid>
                                <Grid item xs>
                                    <div className={classes.wrapper}>
                                        <Fab className={classes.fab}>
                                            <img alt="sites icon" className={classes.overviewIcons} src="/images/sites_outline.svg" />
                                            <CircularProgress size={46.83} thickness={3.6} variant="static" value={sitesCompletedPercentage} className={classes.fabProgress} />
                                        </Fab>
                                    </div>
                                    <div className={classes.labelContainer}>
                                        <Typography className={classNames(classes.progressDetails, classes.progressLabel)} variant="body2">Sites</Typography>
                                        <Typography className={classNames(classes.progressDetails, classes.progressNumbers)} variant="h6">{totalSitesDone}/{totalSites}</Typography>
                                    </div>
                                </Grid>
                                <Grid                                     
                                    className={classes.circularProgressContainerRightChild}
                                    item xs>
                                    <div className={classes.wrapper}>
                                        <Fab className={classes.fab} >
                                            <img alt="car icon" className={classes.overviewIcons} src="/images/car_outline.svg" />
                                            <CircularProgress size={46.83} thickness={3.6} variant="static" value={this.state.milesDrivenPercentage} className={classes.fabProgress} />
                                        </Fab>
                                    </div>
                                    <div className={classes.labelContainer}>
                                        <Typography className={classNames(classes.progressDetails, classes.progressLabel)} variant="body2">Miles</Typography>
                                        <Typography id="miles-driven-container" className={classNames(classes.progressDetails, classes.progressNumbers)} variant="h6">0.0/0.0</Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper id="tab-slide-container" className={classNames(classes.paperUI, classes.tabSlideSection)} elevation={5}>
                            <MobileStepper
                                classes={{
                                    root: classes.mobileStepperContainer,
                                    dot: classes.mobileStepperDots,
                                    dotActive: classes.mobileStepperDotActive
                                }}
                                variant="dots"
                                steps={3}
                                position="static"
                                activeStep={this.state.tabValue}>
                            </MobileStepper>
                            <SwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={this.state.tabValue}
                                onChangeIndex={this.handleChangeTabIndex}
                                disableLazyLoading={true} // important to set the height of map container
                            >
                                <TabContainer
                                    class="tab-slide-container" 
                                    dir={theme.direction} 
                                    className={classNames(classes.tabContainer, classes.itemOne)}>
                                    <Typography className={classes.tabTitle}>Upcoming Sites</Typography>
                                    <div id="upcoming-sites-div" className={classNames(classes.upcomingSitesContainer)}>
                                        <div className={classNames(classes.progressContainer)}>
                                            <Typography 
                                                className={classNames(classes.allowanceTop, classes.overallProgress)}
                                                align="left"
                                                variant="body1">
                                                Overall Progress
                                            </Typography>

                                            <LinearProgress 
                                                id="overall-progress"
                                                className={classNames(
                                                    classes.progressBar,
                                                )}
                                                classes={{
                                                    // bar: classes.progressBarContainer,
                                                    colorPrimary: classes.linearColorPrimary,
                                                    barColorPrimary: classes.linearBarColorPrimary
                                                }}
                                                variant="determinate"
                                                value={percentage}  />                                        
                                            <Typography
                                                style={{float: "left", marginLeft: "6px"}}
                                                className={classes.progressPercentageText}>
                                                {totalDone}/{totalDrawItems}
                                            </Typography>
                                            <Typography
                                                align="right"
                                                style={{marginRight: "6px"}}
                                                className={classes.progressPercentageText}>
                                                {percentage}% Complete</Typography>
                                        </div>
                                        <Table className={classes.myTable}>
                                            <EnhancedTableHead
                                                row_headers={row_headers}
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={this.handleRequestSort}
                                            />
                                            <TableBody>
                                            {filteredMySites.length === 0
                                            ?
                                                <TableRow className={classes.tableRow}>
                                                    <TableCell className={classes.sitesSortableCell} colSpan={4}>
                                                        <Typography className={classNames(classes.tableCell, classes.centered)} variant="body2">No data to show.</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            : 
                                            filteredMySites
                                            }
                                            </TableBody>
                                        </Table>
                                    </div>
                                </TabContainer>
                                <TabContainer
                                    class="tab-slide-container"
                                    dir={theme.direction}
                                    className={classNames(classes.tabContainer, classes.itemTwo)}>
                                    <Typography className={classes.tabTitle}>Route Map</Typography>
                                    <Typography component="div" id="map-component-div" className={classes.mapDiv}>
                                        <GoogleMapComponent
                                            key={this.state.googleMapKey}
                                            id={mapComponentID}
                                            zoom={zoom}
                                            center={center}
                                            start={start}
                                            end={end}
                                            wayPoints={way_points}
                                            wayPointsOptions={way_points_options}
                                            milesDrivenPercentageResponseHandler={this.handleMilesDrivenPercentageCBHandler}>
                                        </GoogleMapComponent>
                                    </Typography>
                                </TabContainer>
                                <TabContainer
                                    class="tab-slide-container"
                                    dir={theme.direction}
                                    className={classNames(classes.tabContainer, classes.itemThree)}>
                                    <Typography className={classes.tabTitle}>STAT Request</Typography>
                                    <List>
                                        {alertsListItems}
                                    </List>
                                </TabContainer>
                            </SwipeableViews>
                        </Paper>

                        {/* <Paper className={classNames(classes.paperUI, classes.alertSection, classes.paddings)} elevation={5}>
                            <Typography variant="h5" className={classNames(classes.alertsTitle)}>
                                <Badge badgeContent={alertsListItemsData.length} classes={{ badge: classes.alertBadge }}>
                                    STAT Requests
                                </Badge>
                            </Typography>
                            <List>
                                {alertsListItems}
                            </List>
                        </Paper> */}
                    </div>

                    {this.state.marginBottomForLandscape &&
                        <div>
                            <div className={classes.toolbar} />
                            <div className={classes.toolbar} />
                        </div>
                    }
                </main>
                <SimpleBottomNavigation />
            </div>
        );
    }
}

DashboardClass.propTypes = {
    classes: PropTypes.object.isRequired
};

const Dashboard = ReactTimeout(withCookies(connect(mapStateToProps)(withStyles(styles, { withTheme: true })(DashboardClass))));
export default Dashboard;
