import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
// import classNames from 'classnames'

import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

// import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
// import IconButton from '@material-ui/core/IconButton'
// import LinearProgress from '@material-ui/core/LinearProgress'

// import uuid from "uuid"

const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const StepperFunctionClass = (props) => {
    const {
        classes,
        // history,
        // match,
        // step,
        isBtnBackDisabled,
        isBtnNextDisabled,
        isBtnBackVisible,
        isBtnNextVisible,
        btnBackLabel,
        btnNextLabel,
        onClickBtnNext,
        onClickBtnBack
    } = props

    // if not set, always visible
    const isBtnBackVisibleValue = isBtnBackVisible === false ? false : true
    const isBtnNextVisibleValue = isBtnNextVisible === false ? false : true

    const btnBackLabelValue = btnBackLabel ? btnBackLabel : "Back"
    const btnNextLabelValue = btnNextLabel ? btnNextLabel : "Next"

    return (
        <>
            <Grid container justify="space-between" className={classes.stepperContainer}>
                <div>
                    {isBtnBackVisibleValue &&
                        <Button
                            className={classes.stepperBackButton}
                            onClick={onClickBtnBack}
                            disabled={isBtnBackDisabled}
                        >{btnBackLabelValue}</Button>
                    }
                </div>
                <div>
                    {isBtnNextVisibleValue &&
                        <Button
                            color="primary"
                            className={classes.stepperNextButton}
                            onClick={onClickBtnNext}
                            disabled={isBtnNextDisabled}
                        >{btnNextLabelValue}</Button>
                    }
                </div>
            </Grid>
        </>
    )
}

const Stepper = withRouter(
    withCookies(
        connect(mapStateToProps)(
            withStyles(styles, { withTheme: true })(StepperFunctionClass)
        )
    )
)
export default Stepper
