import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import Typography from '@material-ui/core/Typography'
import moment from 'moment-timezone'
import uuid from 'uuid'

const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const WorkScheduleTimeOnSelectedDateFunctionClass = (props) => {
    const {
        classes,
        history,
        selectedDate,
        workScheduleTimeOnSelectedDate
    } = props

    // console.log("workScheduleTimeOnSelectedDate")
    // console.log(workScheduleTimeOnSelectedDate)

    const previewWorkSchedule = (schedule) => {
        history.push("work-schedule/preview/" + schedule.id)
    }

    return (
        <div className={classes.timeSlotsContainer}>
            <Typography className={classes.timeSlotDate}>{moment(selectedDate).format("dddd, MMM DD, YYYY")}</Typography>
            {workScheduleTimeOnSelectedDate.map(time => {
                const tf = moment(time.time_from, "hh:mm:ss").format('LT')
                const tt = moment(time.time_to, "hh:mm:ss").format('LT')
                return <Typography
                    key={uuid.v4()}
                    className={classes.timeSlots}
                    onClick={() => previewWorkSchedule(time)}
                >{tf} - {tt}</Typography>
            })}
        </div>
    )
}

const WorkScheduleTimeOnSelectedDate = withCookies(
    connect(mapStateToProps)(
        withStyles(styles, { withTheme: true })(WorkScheduleTimeOnSelectedDateFunctionClass)
    )
)
export default WorkScheduleTimeOnSelectedDate
