import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.js'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import classNames from 'classnames';
import Badge from '@material-ui/core/Badge';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import { ReactComponent as DrawRequestIconSVG } from 'components/common/mobile/CustomOfflineSVG/draw_request_outline.svg';

import Typography from '@material-ui/core/Typography';

// import ApplicationBar from 'components/common/mobile/ApplicationBar';
// import SimpleBottomNavigation from 'components/common/mobile/SimpleBottomNavigation';

import ApplicationBar from 'components/common/workflow/ApplicationBar'
import SimpleBottomNavigation from 'components/common/workflow/SimpleBottomNavigation'

import Grid from '@material-ui/core/Grid'

import Button from '@material-ui/core/Button';
import { connect } from "react-redux";
// import * as globals from 'components/common/GlobalConstants';

import * as helper from 'components/common/Helper.js'
const mapStateToProps = state => {
  return { 
        drawData: state.drawData,
        drawRequests: state.drawRequests,
        newAppVersion: state.newAppVersion
    };
};


// const styles = theme => ({
//     root: {
//         // display: 'flex',
//         // paddingTop: "15px !important",
//         // paddingLeft: "10px !important",
//         // paddingRight: "10px !important",
//     },
//     toolbar: theme.mixins.toolbar,
//     content: {
//         flexGrow: 1,
//     },
//     customApplicationBarHeader: {
//         padding: "7px 17px",
//         marginLeft: "-15px",
//         marginRight: "-15px"
//     },
//     listULMainHeader: {
//         marginTop: 25
//     },
//     notificationMainHeader: {
//         color: "#707070 !important",
//         fontSize: '24px !important',
//         fontWeight: 'bold',
//         padding: "0px !important",
//         margin: "0px !important"
//     },
//     notificationMainContainer: {
//         margin: 15,
//     },
//     listUL: {
//         paddingTop: 0,
//         paddingBottom: 0
//     },
//     list: {
//       marginBottom: theme.spacing.unit,
//       padding: '11px 16px',
//     //   padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px`,
//       fontSize: "18px !important",
//       whiteSpace : "nowrap"
//     },
//     list_content_cont: {
//         width: '100%',
//         border: "1px solid rgba(0, 0, 0, 0.16)",
//         padding: '8px 16px',
//         // paddingLeft: 36
//     },
//     notificationBadge: {
//         height: 14,
//         minWidth: 14,
//         fontSize: 10,
//         left: 10,
//         marginRight: 10
//     },
//     imgIcon: {
//         width: 18,
//         height: 18
//     },
//     badgeRoot: {
//         marginRight: 15
//     },
//     update_title: {
//         fontSize: 18,
//         fontWeight: 'bold'
//     },
//     update_message: {
//         whiteSpace: 'break-spaces',
//         // marginTop: 15,
//         fontSize: '16px !important'
//     },
//     update_cont: {
//         width: "100%",
//         border: "1px solid rgba(0, 0, 0, 0.06)",
//         padding: '8px 16px',
//         textAlign: "center"
//     },
//     update_button: {
//         fontSize: 16,
//         padding: '5px 10px',
//         backgroundColor: "#68CBEF",
//         textTransform: 'none',
//         borderRadius: 0,
//         display: "block",
//         color: "#fff",
//         margin: "5px auto",
//     }
// });

class NotificationsClass extends Component {
    componentDidMount() {
        // console.log('Notifications did mount.');
        // console.log(this.props.drawData);
    }

    goTo(url) {
        this.props.history.push(url);
    }
    
    render() {   
        const { classes, newAppVersion } = this.props;

        var totalDrawRequest = 0;
        try {
            this.props.drawRequests.draw_request.map(obj => {
                if(obj.work_status === ''){
                    totalDrawRequest++;
                }
                return true;
            });
        } catch(e) {
            
        }

        return(
            // <div className={classes.root}>
            //     <main className={classes.content}>
            //         <ApplicationBar isInfoHidden={true} />
            //         <div className={classNames(classes.notificationMainContainer)}>
            //             <div>
            //                 <List classes={{root: classNames(classes.listUL, classes.listULMainHeader)}}>
            //                     <ListItem>
            //                         <ListItemText
            //                             classes={{
            //                                 primary: classes.notificationMainHeader
            //                             }}
            //                             primary="Notifications"
            //                         />
            //                     </ListItem>
            // <div className={classes.root}>
            // <main className={classes.content}>
            //     <div className={classNames(classes.mainContainer)}>
            //         <ApplicationBar isInfoHidden={true} />
                    
            //         <div className={classes.titleContainer}>
            //             <Grid
            //                 container
            //                 alignItems="center"
            //                 justify="space-between"
            //             >
            //                 <div>
            //                     <Typography className={classes.title}>Settings</Typography>
            //                 </div>
            //             </Grid>
            //         </div>                  
            //                     {newAppVersion && 
            //                     <ListItem 
            //                         className={classNames(classes.list)}
            //                         children={
            //                             <div className={classes.update_cont}>
            //                                 <Typography className={classes.update_title}>
            //                                     Update Available!
            //                                 </Typography>
            //                                 <Typography className={classes.update_message}>
            //                                     A newer version of this application is released.
            //                                 </Typography>
            //                                 <div className={classes.update_button_cont}>
            //                                     <Button
            //                                         //onClick={() => {this.goTo("/settings");}}
            //                                         onClick={()=>{
            //                                             helper.updateApp()
            //                                         }}
            //                                         variant="contained"
            //                                         className={classes.update_button}
            //                                         >UPDATE NOW</Button>
            //                                 </div>
            //                             </div>
            //                         }>
            //                     </ListItem>
            //                     }
            //                     {totalDrawRequest > 0 && 
            //                     <ListItem 
            //                         onClick={() => {this.goTo("/draw_request");}}
            //                         button className={classNames(classes.list)}
            //                         children={
            //                             <div className={classes.list_content_cont}>
            //                                 <Typography className={classes.label}>
            //                                     <Badge  
            //                                         classes={{
            //                                             root: classes.badgeRoot,
            //                                             badge: classes.notificationBadge
            //                                         }} 
            //                                         badgeContent={totalDrawRequest} 
            //                                         color="error">                                                        
            //                                     <DrawRequestIconSVG className={classes.imgIcon}></DrawRequestIconSVG>
            //                                     </Badge> New Draw Request
            //                                 </Typography>
            //                             </div>
            //                         }>
            //                     </ListItem>
            //                     }
            //                 </div>
            //                 <div className={classes.toolbar} />
            //                 <div className={classes.toolbar} />
            //                 </main>
            //                 {/* </List>
                            
            //             </div>
            //         </div>

            //         <div className={classes.toolbar} />
            //         <div className={classes.toolbar} />
            //     </main> */}
            //     <SimpleBottomNavigation />
            // </div>


            <div className={classes.root}>
                <main className={classes.content}>
                    <div className={classNames(classes.mainContainer)}>
                        <ApplicationBar isInfoHidden={true} />
                        
                        <div className={classes.titleContainer}>
                            <Grid
                                container
                                alignItems="center"
                                justify="space-between"
                            >
                                <div>
                                    <Typography className={classes.title}>Notifications</Typography>
                                </div>
                            </Grid>
                        </div>
                        <Typography  className={classes.no_notifications}>No notifications</Typography>
                        {newAppVersion && 
                                <ListItem 
                                    className={classNames(classes.list)}
                                    children={
                                        <div className={classes.update_cont}>
                                            <Typography className={classes.update_title}>
                                                Update Available!
                                            </Typography>
                                            <Typography className={classes.update_message}>
                                                A newer version of this application is released.
                                            </Typography>
                                            <div className={classes.update_button_cont}>
                                                <Button
                                                    //onClick={() => {this.goTo("/settings");}}
                                                    onClick={()=>{
                                                        helper.updateApp()
                                                    }}
                                                    variant="contained"
                                                    className={classes.update_button}
                                                    >UPDATE NOW</Button>
                                            </div>
                                        </div>
                                    }>
                                </ListItem>
                                }
                                {totalDrawRequest > 0 && 
                                <ListItem 
                                    onClick={() => {this.goTo("/draw_request");}}
                                    button className={classNames(classes.list)}
                                    children={
                                        <div className={classes.list_content_cont}>
                                            <Typography className={classes.label}>
                                                <Badge  
                                                    classes={{
                                                        root: classes.badgeRoot,
                                                        badge: classes.notificationBadge
                                                    }} 
                                                    badgeContent={totalDrawRequest} 
                                                    color="error">                                                        
                                                <DrawRequestIconSVG className={classes.imgIcon}></DrawRequestIconSVG>
                                                </Badge> New Draw Request
                                            </Typography>
                                        </div>
                                    }>
                                </ListItem>
                                }

                        <div className={classes.toolbar} />
                        <div className={classes.toolbar} />
                    </div>
                </main>

                <SimpleBottomNavigation />
            </div>


        );
    }
}

NotificationsClass.propTypes = {
    classes: PropTypes.object.isRequired,
};

const Notifications = connect(mapStateToProps)(withStyles(styles)(NotificationsClass));
export default Notifications;