import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import ApplicationBar from 'components/common/tablet/ApplicationBar';
import SimpleBottomNavigation from 'components/common/tablet/SimpleBottomNavigation';

import { connect } from "react-redux";

const mapStateToProps = state => {
  return { drawData: state.drawData};
};

const styles = theme => ({
    root: {
        paddingTop: "25px !important",
        paddingLeft: "40px !important",
        paddingRight: "40px !important",
        paddingBottom: "40px !important",
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
    },
});

class NotificationsClass extends Component {
    componentDidMount() {
        console.log('Notifications did mount.');
        console.log(this.props.drawData);
    }
    
    render() {   
        const { classes } = this.props;

        return(
            <div className={classes.root}>
                <main className={classes.content}>
                    <ApplicationBar title="Notifications" />
                    <div className={classes.toolbar} />

                    <div className={classes.toolbar} />
                    <div className={classes.toolbar} />
                </main>
                <SimpleBottomNavigation />
            </div>
        );
    }
}

NotificationsClass.propTypes = {
    classes: PropTypes.object.isRequired,
};

const Notifications = connect(mapStateToProps)(withStyles(styles)(NotificationsClass));
export default Notifications;