import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import { Link } from "react-router-dom";
import classNames from 'classnames';
import * as globals from 'components/common/GlobalConstants';
import ConfirmDialog from 'components/common/mobile/ConfirmDialog';
import Typography from '@material-ui/core/Typography';
import ApplicationBar from 'components/common/mobile/ApplicationBar';
import SimpleBottomNavigation from 'components/common/mobile/SimpleBottomNavigation';
import NotesConsolidated from 'components/common/mobile/NotesConsolidated';

import Grid from '@material-ui/core/Grid';

import $ from 'jquery';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import Checkbox from '@material-ui/core/Checkbox';
import { connect } from "react-redux";
import { 
    updatePatientDrawStatus,
    verifyPatientName,
    verifyPatientDoB,
    saveNote,
    deleteNote,
    saveDrawRequestDocument,
    deleteDrawRequestDocument,
    logEvent,
    updateWorkflowValue

} from "js/actions/index";

import MDSpinner from "react-md-spinner";

import styles from "./styles.js";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow';
import CommonDialog from "./CommonDialog";
import NoDrawListScreen from 'components/common/mobile/NoDrawListScreen.js';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PatientSignaturePad from 'components/common/mobile/PatientSignaturePad';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CustomPDFViewer from "components/common/mobile/CustomPDFViewer";
// import LoadingSpinner from "components/common/mobile/LoadingSpinner.js";

import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
// import Iframe from 'react-iframe';
import moment from 'moment-timezone';
import printJS from 'print-js';

import { ReactComponent as PrintSVG } from 'components/common/mobile/CustomOfflineSVG/print.svg';
import { ReactComponent as PrintDisabledSVG } from 'components/common/mobile/CustomOfflineSVG/print_disabled.svg';

const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing.unit * 2,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing.unit,
      top: theme.spacing.unit,
      color: theme.palette.grey[500],
    },
  }))(props => {
    const { children, classes, onClose } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});

const mapDispatchToProps = dispatch => {
  return {
    updatePatientDrawStatus: drawItem => dispatch(updatePatientDrawStatus(drawItem)),
    verifyPatientName: verifiedPatientNameData => dispatch(verifyPatientName(verifiedPatientNameData)),
    verifyPatientDoB: verifiedPatientDoBData => dispatch(verifyPatientDoB(verifiedPatientDoBData)),
    saveNote: note => dispatch(saveNote(note)),
    deleteNote: note => dispatch(deleteNote(note)),
    saveDrawRequestDocument: document => dispatch(saveDrawRequestDocument(document)),
    deleteDrawRequestDocument: document => dispatch(deleteDrawRequestDocument(document)),
    logEvent: event => dispatch(logEvent(event)),
    updateWorkflowValue: value => dispatch(updateWorkflowValue(value))
    
  }
}

const mapStateToProps = state => {
    return { 
        drawData: state.drawData, 
        drawRequestDocumentsData: state.drawRequestDocumentsData,
        drawRequestNotesData: state.drawRequestNotesData,
        workflowData: state.workflowData
    }
};


function TabContainer({ children, dir }) {
    return (
        <Typography component="div" dir={dir} style={{ padding: '24px 12px' }}>
        {children}
        </Typography>
    );
}
  
TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired,
};

class PatientClass extends Component {
    static propTypes = {
      cookies: instanceOf(Cookies).isRequired
    };
  
    constructor(props) {
        super(props);
        // const { cookies } = this.props;
   
        const dialogdata = {
            "save_status_done" : {
                "title":"Patient Draw Save", 
                "content": "Continue saving patient draw status to done?", 
                "buttons" : ["Continue"], 
                "visible": false, 
                eventHandler: this.onSaveStatusDone
            },
            "reset_draw_status" : {
                "title":"Reset Patient Draw", 
                "content": "Continue resetting patient draw status?", 
                "buttons" : ["Continue"], 
                "visible": false, 
                eventHandler: this.onResetStatus
            },
            "require_barcode" : {
                "title":"Barcode", 
                "content": "Fedex barcode is required? Please scan at least one.", 
                "buttons" : ["OK"], 
                "visible": false, 
                eventHandler: this.onBarcodeRequired
            },
            "require_signature" : {
                "title":"Signature", 
                "content": "Patient or guiardian signature is required.", 
                "buttons" : ["OK"], 
                "visible": false, 
                eventHandler: this.onSignatureRequired
            },
        };

        this.state = {
            ...this.state,
            siteId : "",
            patientId: "",
            dialogOpenedPatientRefused: false,
            dialogOpenedPrintBarcodes: false,
            selectedPatientDraw: "",
            drawItemStatus: "remaining",
            drawItemStatusReason: "",
            tabValue: 0, // cookies.get('patient-tab-value') ? parseInt(cookies.get('patient-tab-value')) : 0,
            isPatientRefusesUnableToDraw: false,
            drawSiteValue: "right_arm",
            dialogdata : dialogdata,
            printBarcodesInfo: {"current": 0, "total": 0},
            openedPatientPDFDialog: false,
            openedAttachmentDialog: false,
            attachmentSource: '',
            patientSignatureVisible: false,
            barcode: "",
        };

        this.selectedPatientDraw = undefined;
        this.drawSiteValue = "right_arm";
        this.modifiedDrawSite = false;
        this.thereIsBarcode = false;
        this.requireBarcode = false;
    }

    componentDidMount() {        
        if(globals.DEBUG_ALLOWED) {
            console.log("Patient page did mount...")
            console.log("siteId")
            console.log(this.state.siteId)

            console.log("patientId")
            console.log(this.state.patientId)
        }

        console.log("componentDidMount")
        // console.log(this.addNoteAnchorRef)
        var log = {
            module: "patient-individual", 
            action: "open_patient", 
            description: "Opened patient-individual", 
            entry_id: this.state.patientId,
            patient_draw_id: this.selectedPatientDraw.id,
        }
        console.log(log)
        this.props.logEvent(log)
    }

    componentWillMount() {
       // console.log('PatientClass: componentWillMount.');        
      
        this.setState({...this.state, 
            patientId: this.props.match.params.patientId , 
            siteId: this.props.match.params.siteId });
    }
    
    handleOpenDialog =  event => {
        this.setState({...this.state, dialogOpenedPatientRefused: true});
    };

   
    handleCloseDialogPatientRefused = () => {
        this.setState({...this.state, dialogOpenedPatientRefused: false });
    };

    handleChangeDrawItemStatus = event => {
        this.setState({ ...this.state, drawItemStatus: event.target.value });
    };

    handleTabChange = (event, tabValue) => {
        // const { cookies } = this.props;
        // cookies.set('patient-tab-value',tabValue, { path: '/'});
        this.setState({...this.state, tabValue });
    };
  
    handleTabChangeIndex = index => {
        // const { cookies } = this.props;
        // cookies.set('patient-tab-value',index, { path: '/'});
        this.setState({...this.state, tabValue: index });
    };

    handleChangeBarcode =(event)=> {
        this.setState({...this.state,barcode: event.target.value })
    }

    setDeleteRequisitionDialogHandler = (actionHandler, postActionHandler, title, message, actionText, chainedFunction) => {
        // if(chainedFunction === null) chainedFunction = ()=>{};
         this.setState(
           {
            ...this.state, 
            actionHandler: actionHandler, 
            postActionHandler : postActionHandler, 
            confirmDialogMessage: message,
            confirmDialogTitle: title,
            confirmDialogActionText: actionText,
           }, 
            chainedFunction
         );
       }

       setDeleteNoteDialogVisible = (value) => {
        //console.log("setDeleteNoteDialogVisible " + value);
         this.setState({ ...this.state, setDeleteNotesDialog: value});
       }

    addRequisition = () => {
        
        if(this.state.barcode === "") return

        const siteId = this.state.siteId
        const patientId =  this.state.patientId
        console.log("siteId")
        console.log(siteId)
        console.log("patientId")
        console.log(patientId)
        console.log("this.state.barcode")
        console.log(this.state.barcode)

        var document =
        {
            "id": "",
            "filename": "",
            "path": "",
            "doc_type": "metadata", 
            "metadata": {
                "requisition_code": this.state.barcode,
            }
        }
        var payload = {
            siteId: siteId, 
            patientId: patientId, 
            target : "patient_draw",
            document : document 
        }
        console.log(payload)
        
        this.setState({...this.state, barcode: ""},
                this.updateDrawRequestDocument(payload)
        )
           
    }

    updateDrawRequestDocument = (payload) => {
        this.props.saveDrawRequestDocument(payload)
    }


    removeDrawRequestDocument = (document) => {
        const siteId = this.state.siteId
        const patientId =  this.state.patientId
  
        var payload = {
            siteId: siteId, 
            patientId: patientId, 
            target : "patient_draw",
            document : document 
        }
        console.log(payload)
        
        this.setState({...this.state, barcode: ""},
                this.deleteDrawRequestDocument(payload)
        )
           
    }

    deleteDrawRequestDocument = (payload) => {
        this.props.deleteDrawRequestDocument(payload)
    }


    handlePatientIdentityVerificationCheckboxChange = name => event => {
   
        if(name === "isPatientNameVerified") {
            const patientNamePayload = {
                "siteId": this.state.siteId ,
                "patientId":  this.state.patientId, 
                "patientDrawId": this.selectedPatientDraw.id,
                // "workflow_key": "name_verified",
                // "workflow_value": event.target.checked,
                "workflow_values" : [
                    {
                        key : "name_verified",
                        value : event.target.checked
                    }
                    // ,
                    // {
                    //     key : "dob_verified",
                    //     value : event.target.checked
                    // }
                ]
            }
            this.props.updateWorkflowValue(patientNamePayload)
        } else if(name === "isPatientDoBVerified") {
            const patientDoBPayload = {
                "siteId": this.state.siteId ,
                "patientId":  this.state.patientId, 
                "patientDrawId": this.selectedPatientDraw.id,
                "workflow_key": "dob_verified",
                "workflow_value": event.target.checked,
            }
            this.props.updateWorkflowValue(patientDoBPayload)
        }
    };

    handleOnShowDialogPatientRefused = () => {
        this.setState({...this.state, 
            drawItemStatus: this.selectedPatientDraw.status });
    };
    
    handleOnContinueDraw = () => {
        // const { cookies } = this.props;
        this.setState({...this.state, tabValue : 1 });
    };

    handleSubmitRefuseReason = event => {
        var refused_reason_jQuery = $('#patient-unable-to-draw-reason').val();
        var patient_refused_unable =  $('[name=draw_item_option]:checked').val();
        const payload = {
          "siteId": this.state.siteId ,
          "patientId":  this.state.patientId, 
          "patientDrawId": this.selectedPatientDraw.id,
          "type": "p", 
          "status":  patient_refused_unable,
          "notes": refused_reason_jQuery
        };
        this.props.updatePatientDrawStatus(payload);
        this.setState({...this.state, skipReasonDialogOpened: false, dialogOpenedPatientRefused: false});
        setTimeout(()=>{ this.goBackToSite() }, globals.DELAY_AFTER_DONE_TO_SITE_PAGE)
        //this.goBackToSite();
    };

    showCommonDialog = (id) => {
        var dialogdata = this.state.dialogdata;
        dialogdata[id].visible = true;
        this.setState({...this.state, dialogdata : dialogdata});
    };

    onSaveStatusDone = (button) => {
        console.log("this.requireBarcode")
        console.log(this.requireBarcode)
        if(this.requireBarcode) {
            
            this.showCommonDialog('require_barcode');
           // this.showCommonDialog('reset_draw_status');
            return;
        }

        if(button === 'Continue') {
            this.setState({...this.state, patientSignatureVisible: true});
           // this.handleDrawDone();
        }
    };

    handleDrawDone = (signatureData) => {
        var first_name = "";
        var last_name = "";
        
        // Additional check if signature is present before setting "Done"
        if((signatureData.sign_off && signatureData.sign_off.length === 0) || signatureData.sign_off === "" || signatureData.sign_off === undefined) {
            this.showCommonDialog('require_signature');
            return;
        }
      
        if(signatureData.signatory_type === "Patient") {                
            const drawData = this.props.drawData;          
            const siteId = this.state.siteId;
            const patientId =  this.state.patientId;
            
            const theSite = drawData.draw_list.filter(function(site) {
                return(site.id === siteId); 
            });
            
            const patient = theSite[0].patient_list.filter(function(patient) {
                return(patient.id === patientId); 
            });
            
            first_name = patient[0].patient.first_name;
            last_name = patient[0].patient.last_name ;
        } else if(signatureData.signatory_type === "Guardian") { 
            first_name = signatureData.first_name;
            last_name = signatureData.last_name;
        }

        const payload = {
            "siteId": this.state.siteId ,
            "patientId":  this.state.patientId, 
            "patientDrawId": this.selectedPatientDraw.id,
            "type": "p", 
            "status":  "done",
            "notes": "",
            "draw_source": this.drawSiteValue,
            "withSignature" : true, 
            "signature" : {
                "sof_first_name": first_name, 
                "sof_last_name": last_name,
                "sof_signatory_type": signatureData.signatory_type,
                "sof_sign_off": signatureData.sign_off,
                "sof_sign_off_metadata": {     
                    "ratio": signatureData.ratio,
                    "canvas_width": signatureData.canvasWidth,
                    "canvas_height": signatureData.canvasHeight,
                    "max_draw_width": signatureData.maxDrawWidth,
                    "max_draw_height": signatureData.maxDrawHeight
                }
            }
        }
        console.log(payload)
        this.modifiedDrawSite = false
        this.props.updatePatientDrawStatus(payload)
        this.setState({...this.state, skipReasonDialogOpened: false})
        setTimeout(()=>{ this.goBackToSite() }, globals.DELAY_AFTER_DONE_TO_SITE_PAGE)
        //this.goBackToSite();
    }

    goBackToSite() {
        this.props.history.push('/sites');
        // this.props.history.push('/site/' + this.props.match.params.siteId);
    };

    onResetStatus = (button) => {
        if(button === 'Continue') {
            this.handleResetDraw();
        }
    };

    onBarcodeRequired = (button) => {
    };
    
    onSignatureRequired = (button) => {
    };

    handleResetDraw = event => {
        console.log('PatientClass: handleResetDraw.');
        const payload = {
            "siteId": this.state.siteId ,
            "patientId":  this.state.patientId,
            "withSignature" : true, 
            "patientDrawId": this.selectedPatientDraw.id,
            "type": "r", 
            "status":  "remaining", // default status
            "notes": ""
        };
        this.modifiedDrawSite = false;
        this.props.updatePatientDrawStatus(payload);
        // this.goTo("/sites");
        this.goTo("/patient/" + this.state.patientId + "/" + this.state.siteId);
    };

    handleOpenPrintBarcodesDialog = event => {
        this.setState({...this.state, printBarcodesInfo: {"current": 1, "total": 3}, dialogOpenedPrintBarcodes: true});
        // workaround      

        setTimeout(function() { 
            this.setState({...this.state, printBarcodesInfo: {"current": 2, "total": 3}});       
        }.bind(this), 2000);
        setTimeout(function() { 
            this.setState({...this.state, printBarcodesInfo: {"current": 3, "total": 3}});       
        }.bind(this), 3000);
        setTimeout(function() { 
            this.handleClosePrintBarcodesDialog(); 
        }.bind(this), 4000);
    };

    handleClosePrintBarcodesDialog = event => {
        this.setState({...this.state, dialogOpenedPrintBarcodes: false});
    };

    pad(n) {
        return (n < 10) ? ("0" + n) : n;
    }

    triggerPatientPDFDialog = () => {
        this.setState({...this.state, openedPatientPDFDialog: !this.state.openedPatientPDFDialog });
    }

    PatientSignaturePadOnEvent = (data) =>{
        console.log(data);
        
        if(data.command === PatientSignaturePad.CANCEL()) {        
            this.setState({...this.state, patientSignatureVisible: false});
        }

        if(data.command === PatientSignaturePad.SUBMIT()) {
            this.setState({...this.state, patientSignatureVisible: false});
            this.handleDrawDone(data);
        }
    }  

    loadAttachment = (e, url) => {        
        this.setState({...this.state, attachmentSource: url, openedAttachmentDialog: true});
        e.preventDefault();
    }

    closeAttachmentDialog = () => {
        this.setState({...this.state, openedAttachmentDialog: false });
    }

    handlePrintLabels = (labels) => {
        console.log("Printing...")
        try {
            
            var fpath = globals.SERVER + labels[0].path;
            printJS({printable: fpath, type:'pdf', showModal:true})

        } catch(e) {
            console.log(e)
        }
        // labels.forEach((label, index) => {
        //     console.log(label.path); 
        //     //printJS(label.path)
            
        // });
    }

    goTo(url) {
        this.props.history.push(url);
    }

    render() {
        const { classes, drawData, drawRequestDocumentsData, drawRequestNotesData, workflowData} = this.props;

        if($.isEmptyObject(drawData.draw_list)) {
          return(<NoDrawListScreen />);
        }
     
        const siteId = this.state.siteId;
        const patientId =  this.state.patientId;
        console.log("sid: " + siteId)
        console.log("pid: " + patientId)
        
        const theSite = drawData.draw_list.filter(function(site) {
           return(site.id === siteId); 
        })

        const theSitedrawRequestDocumentsData = drawRequestDocumentsData.draw_request_documents.filter(function (draw_req_doc) {
            return (draw_req_doc.drawlist_site_id === siteId && draw_req_doc.drawlist_patient_id === patientId);
        })
      
        const theSitedrawRequestNotesData = drawRequestNotesData.draw_request_notes.filter(function (draw_req_note) {
            return (draw_req_note.drawlist_site_id === siteId && draw_req_note.drawlist_patient_id === patientId);
        })

        console.log("theSitedrawRequestNotesData",theSitedrawRequestNotesData)

        // console.log("theSitedrawRequestDocumentsData")
        // console.log(theSitedrawRequestDocumentsData)
        
        const patient = theSite[0].patient_list.filter(function(patient) {
           return(patient.id === patientId); 
        })

        const patientWorkflowData = workflowData.workflow.filter(function(workflow) {
            return(workflow.drawlist_patient_id === patientId)
        })
        console.log("patientWorkflowData")
        console.log(patientWorkflowData)
        
        // const siteCompleted = theSite.site_status !== undefined ? theSite.site_status : ""
        this.selectedPatientDraw =  patient[0].patient_draw[0]
        const drawStatus = this.selectedPatientDraw.status
        const patientDrawId =  this.selectedPatientDraw.id
        const patientName = patient[0].patient.last_name + ", " + patient[0].patient.first_name + " " + patient[0].patient.middle_name;
        
        const city = patient[0].patient.city !== undefined && patient[0].patient.city !== "" ? patient[0].patient.city + " ": "";        
        const city_state_zipcode = city + [patient[0].patient.state, patient[0].patient.zipcode].filter(function(el) { return el; }).join( ", " );
        const patientAddress = [patient[0].patient.address, patient[0].patient.address_line_2].filter(function(el) { return el; }).join( ", " ) + ", " + city_state_zipcode;

        //const isPatientNameVerified = patient[0].patient_draw[0].name_verified;
        //const isPatientDoBVerified = patient[0].patient_draw[0].dob_verified;

        const isPatientNameVerified = patientWorkflowData[0].workflow_data.name_verified
        const isPatientDoBVerified = patientWorkflowData[0].workflow_data.dob_verified


        const labels = this.selectedPatientDraw.labels;
        var printLabelsEnabled = false;
        if(labels && labels.length > 0) {
            printLabelsEnabled = true;
        }

        if(!this.modifiedDrawSite) {
            this.drawSiteValue = this.selectedPatientDraw.draw_source === "" || this.selectedPatientDraw.draw_source === null ? "right_arm" : this.selectedPatientDraw.draw_source;
        }
        const oldURL = "/sites/";
        var thereIsBarcode = false;

        // patient[0].patient_draw[0].draw_request_documents.forEach((item, index) => {
        theSitedrawRequestDocumentsData.forEach((item, index) => {
                //console.log("Item " + item + " Index " + index)
                //console.log(item)
                if(item.client_deleted === false &&  item.metadata !== undefined && item.metadata.requisition_code !== undefined ){
                    thereIsBarcode = true;
                }
        });   

        var channel_url;

        if(patient[0].patient_draw[0].source) {
            
            channel_url = patient[0].patient_draw[0].source.url

            // if has channel value but not existing then point to default channel 
            if(!globals.CHANNEL_DATA.hasOwnProperty(channel_url)){
                channel_url = globals.DEFAULT_CHANNEL_URL
            } 
            
        } else {
            channel_url = globals.DEFAULT_CHANNEL_URL
        }

        const channel = globals.CHANNEL_DATA[channel_url]

        const barcodeTitle = channel.title
        const commandText = channel.commandText
        const inputGuideText = channel.inputGuideText

        if(channel.barcode !== "" && thereIsBarcode === false ) {
            this.requireBarcode = true
            //console.log("Setting to Required Barcode " + this.requireBarcode )
        } else {
            this.requireBarcode = false
        }

        // console.log("There is barcode " + thereIsBarcode)
        // console.log("Required Barcode " + this.requireBarcode )
        // console.log("channel.barcode  " + channel.barcode )
        //console.log("patient[0]")
        //console.log(patient[0].patient_draw[0].status)
        var patientReadOnly = false; // patient[0].patient_draw[0].status !== "remaining" ? true: false;
        
        return(
            <div className={classes.root}>
                <main className={classes.content}>
                    <div className={classNames(classes.headerInfo)}>

                        <ApplicationBar back={oldURL} icon="person" title={patientName} channel={channel}/>
                        
                        { patient[0].urgent &&
                            <Typography 
                            className={classNames(classes.STAT)}
                            variant="button">STAT</Typography>
                        }
                        { patient[0].fasting &&
                            <Typography 
                            className={classNames(classes.FASTING)}
                            variant="button">FASTING</Typography>
                        }
                        
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            // alignItems="flex-start"
                            spacing={8}
                        >
                            <Grid item xs={7}>
                                <Typography className={classes.patientAddress}>{patientAddress}</Typography> 
                            </Grid>
                            <Grid item xs={5} style={{ textAlign: "right" }}>
                                {/* <CurrentDate /> */}
                                <Button 
                                    onClick={() => {this.handlePrintLabels(labels)}}
                                    disabled={!printLabelsEnabled}
                                    className={classNames(classes.mainButtonWhiteBorderedSM, classes.printButton)}>
                                    {printLabelsEnabled ? <PrintSVG></PrintSVG> : <PrintDisabledSVG></PrintDisabledSVG>} Print
                                </Button>
                            </Grid>
                        </Grid>                        
                        <br/>
                        <div>
                            <Typography className={classes.pleaseVerify}>Please verify:</Typography>
                            <Table className={classes.table}>
                                <TableBody>
                                    <TableRow key='1xxx'>
                                        <TableCell className={classes.patientIdentityCellCont}>
                                            <label className={classes.patientIdentityText} htmlFor="patient-name-verify-chkbx">
                                            Patient Name
                                            </label>
                                        </TableCell>
                                        <TableCell className={classNames(classes.patientIdentityCellCont, classes.patientIdentityLastCellCont)} align="right">
                                            <Checkbox
                                                id="patient-name-verify-chkbx"
                                                checked={isPatientNameVerified}
                                                disabled={this.selectedPatientDraw.status !== "remaining"}
                                                onChange={this.handlePatientIdentityVerificationCheckboxChange('isPatientNameVerified')}
                                                value="isPatientNameVerified"
                                                />
                                        </TableCell>
                                    </TableRow> 

                                    <TableRow key='2xxx'>
                                        <TableCell className={classes.patientIdentityCellCont}>
                                            <label className={classes.patientIdentityText} htmlFor="patient-dob-verify-chkbx">
                                                Patient Date of Birth
                                            </label>
                                            <Typography className={classNames(classes.patientIdentityText, classes.patientBirthDate)}>
                                                {moment(patient[0].patient.birth_date, "YYYY-MM-DD").format('MM/DD/YYYY')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classNames(classes.patientIdentityCellCont, classes.patientIdentityLastCellCont)} align="right">
                                            <Checkbox
                                                id="patient-dob-verify-chkbx"
                                                checked={isPatientDoBVerified}
                                                disabled={this.selectedPatientDraw.status !== "remaining"}
                                                onChange={this.handlePatientIdentityVerificationCheckboxChange('isPatientDoBVerified')}
                                                value="isPatientDoBVerified"
                                                />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>  
                        </div>
                    {/* "START: Requested by Erik" */}
                    <br/>
                                {/* <Typography 
                                    align="left"   
                                    className={classes.drawPatientTitle}>
                                    Requisitions
                                </Typography> */}

                                {/* <BarcodeReader siteId={siteId} patientId={patientId} 
                                                patientDrawId={patientDrawId} notes={patient[0].patient.notes} 
                                                target="patient" draw_request_documents={patient[0].patient_draw[0].draw_request_documents}
                                                title={barcodeTitle}
                                                commandText={commandText}
                                                inputGuideText={inputGuideText}
                                                readOnly={patientReadOnly}
                                                />
                                <br/> */}


                                {/* <Typography 
                                    align="left"   
                                    className={classes.drawPatientTitle}>
                                    Attachments
                                </Typography> */}
                                
                                {/* <PhotoCapture 
                                    siteId={siteId} 
                                    patientId={patientId} 
                                    patientDrawId={patientDrawId} 
                                    notes={null} 
                                    //notes={patient[0].patient.notes} 
                                    target="patient" 
                                    draw_request_documents={patient[0].patient_draw[0].draw_request_documents}
                                    readOnly={patientReadOnly}
                                /> */}
                                
{/* 
                                <Typography 
                                    align="left"   
                                    className={classes.drawPatientTitle}>
                                    Notes
                                </Typography> */}
                                {/* <br />
                                <Notes addNew="button" siteId={siteId} patientId={patientId} 
                                    patientDrawId={patientDrawId} notes={patient[0].patient.notes} 
                                    target="patient" readOnly={patientReadOnly}
                                    readOnly={patientReadOnly}
                                    /> */}

                                <NotesConsolidated 
                                    siteId={siteId} 
                                    patientId={patientId} 
                                    patientDrawId={patientDrawId} 
                                    target="patient" 
                                    // draw_request_documents={patient[0].patient_draw[0].draw_request_documents}
                                    draw_request_documents={theSitedrawRequestDocumentsData}   
                                    notes={theSitedrawRequestNotesData}
                                    //notes={patient[0].patient.notes} 
                                    patientReadOnly={patientReadOnly}
                                    barcodeTitle={barcodeTitle}
                                    barcodeCommandText={commandText}
                                    barcodeInputGuideText={inputGuideText}
                                    drawStatus={drawStatus}
                                />

                        {/* "END: Requested by Erik" */}
                    </div>
                    {/* <div className={classNames(classes.margins, classes.patientContainer)}>

                        <Grid
                            container
                            direction="column"
                            justify="center"
                            className={classes.gridContainer}>
                            <Grid item xs={12}>
                                <Tabs
                                    className={classes.swipeableTab}
                                    value={this.state.tabValue}
                                    onChange={this.handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth">
                                    <Tab 
                                        label="Patient Identity Verification"
                                        classes={{
                                            label: classes.patientIdentityTab
                                        }} />
                                    {((isPatientDoBVerified && isPatientNameVerified) || this.selectedPatientDraw.status !== "remaining")  &&
                                    <Tab 
                                        label="Patient Draw"
                                        classes={{
                                            label: classes.patientIdentityTab
                                        }}
                                    />
                                    }
                                </Tabs>
                                <SwipeableViews
                                    className={classes.swipeableContent}
                                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                    index={this.state.tabValue}
                                    onChangeIndex={this.handleTabChangeIndex}
                                    disabled={true} // disable swiping
                                    >
                                    <TabContainer className={classes.tabContainer} dir={theme.direction}>
                                        <Table className={classes.table}>
                                            <TableBody>
                                                <TableRow key='1xxx'>
                                                    <TableCell className={classes.patientIdentityCellCont}>
                                                        <label className={classes.patientIdentityText} htmlFor="patient-name-verify-chkbx">
                                                        Patient Name
                                                        </label>
                                                    </TableCell>
                                                    <TableCell className={classNames(classes.patientIdentityCellCont, classes.patientIdentityLastCellCont)} align="right">
                                                        <Checkbox
                                                            id="patient-name-verify-chkbx"
                                                            checked={isPatientNameVerified}
                                                            disabled={this.selectedPatientDraw.status !== "remaining"}
                                                            onChange={this.handlePatientIdentityVerificationCheckboxChange('isPatientNameVerified')}
                                                            value="isPatientNameVerified"
                                                            />
                                                    </TableCell>
                                                </TableRow> 

                                                <TableRow key='2xxx'>
                                                    <TableCell className={classes.patientIdentityCellCont}>
                                                        <label className={classes.patientIdentityText} htmlFor="patient-dob-verify-chkbx">
                                                            Patient Date of Birth
                                                        </label>
                                                        <Typography className={classes.patientIdentityText}>
                                                            {moment(patient[0].patient.birth_date, "YYYY-MM-DD").format('MM/DD/YYYY')}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classNames(classes.patientIdentityCellCont, classes.patientIdentityLastCellCont)} align="right">
                                                        <Checkbox
                                                            id="patient-dob-verify-chkbx"
                                                            checked={isPatientDoBVerified}
                                                            disabled={this.selectedPatientDraw.status !== "remaining"}
                                                            onChange={this.handlePatientIdentityVerificationCheckboxChange('isPatientDoBVerified')}
                                                            value="isPatientDoBVerified"
                                                            />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>                                   
                                        <Grid
                                            container
                                            alignContent="center"
                                            direction="column"
                                            justify="center"
                                            alignItems="center">

                                            <Grid item xs={12}>
                                            {isPatientDoBVerified && isPatientNameVerified &&
                                                <Button  
                                                    variant="contained"
                                                    onClick={this.handleOnContinueDraw} 
                                                    className={classNames(classes.margins, classes.mainButton)}>
                                                    Continue
                                                </Button> }
                                            { this.selectedPatientDraw.status === "remaining" &&
                                            <Button 
                                                variant="contained"
                                                onClick={this.handleOpenDialog} 
                                                className={classNames(classes.mainButton, classes.margins)}
                                                >Unable to draw</Button>
                                            }
                                            </Grid>                            
                                        </Grid>
                                    </TabContainer>
                                </SwipeableViews>
                            </Grid>
                        </Grid>
                                        <br />
                                                         
                        <Grid
                            container
                            alignContent="center"
                            direction="column"
                            justify="center"
                            alignItems="center">

                            <Grid item xs={12}>
                            {isPatientDoBVerified && isPatientNameVerified &&
                                <Button  
                                    variant="contained"
                                    onClick={this.handleOnContinueDraw} 
                                    className={classNames(classes.margins, classes.mainButton)}>
                                    Continue
                                </Button> 
                            }
                            { this.selectedPatientDraw.status === "remaining" &&
                            <Button 
                                variant="contained"
                                onClick={this.handleOpenDialog} 
                                className={classNames(classes.mainButton, classes.margins)}
                                >Unable to draw</Button>
                            }
                            </Grid>                            
                        </Grid>

                        <BarcodeReader siteId={siteId} patientId={patientId} 
                                        patientDrawId={patientDrawId} notes={patient[0].patient.notes} 
                                        target="patient" draw_request_documents={patient[0].patient_draw[0].draw_request_documents}
                                        title={barcodeTitle}
                                        commandText={commandText}
                                        inputGuideText={inputGuideText}
                                        readOnly={patientReadOnly}
                                        />
                        <br/>
                        <PhotoCapture 
                            siteId={siteId} 
                            patientId={patientId} 
                            patientDrawId={patientDrawId} 
                            notes={patient[0].patient.notes} 
                            target="patient" 
                            draw_request_documents={patient[0].patient_draw[0].draw_request_documents}
                            readOnly={patientReadOnly}
                        />
                        <br />
                        <Notes addNew="button" siteId={siteId} patientId={patientId} 
                            patientDrawId={patientDrawId} notes={patient[0].patient.notes} 
                            target="patient" readOnly={patientReadOnly}
                            readOnly={patientReadOnly}
                                        />
                    </div> */}
                    <div className={classNames(classes.margins, classes.patientContainer)}>
                        { this.selectedPatientDraw.status !== "remaining" &&
                            <div className={classes.yellowPaperBG}>
                                { this.selectedPatientDraw.status === "refused" 
                                ?
                                <div>
                                    <Typography variant="body1" className={classes.statusNotes}>
                                        This patient is marked as: unable
                                    </Typography>
                                    <Typography variant="body1" className={classes.statusNotes}>
                                        {this.selectedPatientDraw.status_notes_p === "" ? "Reason: refused" : this.selectedPatientDraw.status_notes_p}
                                    </Typography>
                                </div>
                                :
                                <div>
                                    <Typography variant="body1" className={classes.statusNotes}>
                                        {"This patient is marked as: " + this.selectedPatientDraw.status}
                                    </Typography>
                                    <Typography variant="body1" className={classes.statusNotes}>
                                        {["unable"].indexOf(this.selectedPatientDraw.status) > -1 ? "Reason: " + this.selectedPatientDraw.status_notes_p : ''}
                                    </Typography>
                                </div>
                                }
                            </div>
                        }
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            spacing={8}
                        >
                            <Grid item xs={8}>
                                <Button 
                                    disabled={this.selectedPatientDraw.status !== "remaining"}
                                    variant="contained"
                                    onClick={this.handleOpenDialog} 
                                    className={classNames(classes.mainButtonRed, classes.margins)}
                                    >Unable to Draw</Button>
                            </Grid>
                            <Grid item xs={4} style={{ textAlign: "right" }}>
                                {/* siteCompleted is not for individual: always can reset */}
                                {/* && siteCompleted !== "completed" */}
                                {/* {isPatientDoBVerified && isPatientNameVerified && */}
                                <div>
                                { this.selectedPatientDraw.status === "remaining" ?
                                <Button
                                    onClick={ () => this.onSaveStatusDone('Continue')}
                                    disabled={ !(isPatientDoBVerified && isPatientNameVerified) }
                                    variant="contained"
                                    className={classNames(classes.mainButtonBlue, classes.margins)}
                                    >Done</Button>
                                :
                                <Button 
                                    onClick={ () => this.showCommonDialog('reset_draw_status')} 
                                    className={classNames(classes.mainButtonBlue, classes.margins)}
                                    variant="contained"
                                    >Reset</Button>
                                    // <Icon>cached</Icon> 
                                }
                                </div>
                                {/* } */}
                            </Grid>
                        </Grid>

                        {/* <Link to={oldURL}>
                            <Button 
                                //className={classNames(classes.mainButtonBlue, classes.margins)} 
                                className={classNames(classes.mainButtonWhiteBorderedSM, classes.printButton)}
                                fullWidth={true}
                                variant="contained">
                                Go Back to Patients List
                            </Button>
                        </Link> */}
                    </div>

                    {/* <div className={classNames(classes.margins, classes.patientContainer)}>     
                        <Grid
                            container
                            alignContent="center">
                            <Grid item xs={12}>
                                <div className={classes.textCentered}>
                                    { this.selectedPatientDraw.status !== "remaining" ? 
                                    <div>
                                        <Paper className={classes.yellowPaperBG}>
                                            <List component="nav">
                                                <ListItem>
                                                    { this.selectedPatientDraw.status === "refused" 
                                                    ?
                                                    <ListItemText 
                                                        variant="body1"
                                                        classes={{
                                                            primary: classes.statusNotes,
                                                            secondary: classes.statusNotes,
                                                        }}
                                                        primary={"This patient is marked as: unable"}
                                                        secondary={this.selectedPatientDraw.status_notes_p === "" ? "Reason: refused" : this.selectedPatientDraw.status_notes_p} />
                                                    :
                                                    <ListItemText 
                                                        variant="body1"
                                                        classes={{
                                                            primary: classes.statusNotes,
                                                            secondary: classes.statusNotes,
                                                        }}
                                                        primary={"This patient is marked as: " + this.selectedPatientDraw.status}
                                                        secondary={["unable"].indexOf(this.selectedPatientDraw.status) > -1 ? "Reason: " + this.selectedPatientDraw.status_notes_p : ''} />
                                                    }
                                                </ListItem>
                                                { siteCompleted !== "completed" &&
                                                <ListItem>
                                                    <ListItemText></ListItemText>
                                                    <Button 
                                                        onClick={ () => this.showCommonDialog('reset_draw_status')} 
                                                        variant="contained"
                                                        className={classes.resetButton}
                                                        ><Icon>cached</Icon> Reset</Button>
                                                </ListItem>
                                                }
                                            </List>
                                        </Paper>
                                        <br/>
                                        <Link to={oldURL}>
                                            <Button className={classNames(classes.mainButtonBlue, classes.margins)} variant="contained">
                                                Go Back to Patients List
                                            </Button>
                                        </Link>
                                    </div>
                                    :
                                    <div>
                                        
                                    </div>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </div>     */}

                    <div className={classes.toolbar} />
                </main>
                <SimpleBottomNavigation />  
                
                <PatientSignaturePad 
                    visible={this.state.patientSignatureVisible} 
                    onEvent={this.PatientSignaturePadOnEvent}
                />

                <Dialog
                    id="unable-to-draw-dialog"
                    fullScreen={false}
                    classes={{
                        paper: classes.dialogPaper
                    }}
                    open={this.state.dialogOpenedPatientRefused}
                    onClose={this.handleCloseDialogPatientRefused}
                    onEnter={this.handleOnShowDialogPatientRefused}
                    aria-labelledby="responsive-dialog-title"
                    >
                    <DialogTitle id="responsive-dialog-title">Patient is unable to draw</DialogTitle>
                    <DialogContent>
                        <FormControl 
                            fullWidth={true}
                            component="fieldset">
                            <RadioGroup
                                aria-label="Please select an option." 
                                name="draw_item_option"
                                className={classes.group}
                                value={this.state.drawItemStatus === "remaining" ? "refused_hard_stick" : this.state.drawItemStatus}
                                onChange={this.handleChangeDrawItemStatus}
                                id='patient_refused_unable'>

                                <FormControlLabel value="refused" control={<Radio className={classes.radioButton} />} label="Refused" />
                                <FormControlLabel value="refused_hard_stick" control={<Radio className={classes.radioButton} />} label="Hard Stick" />
                                <FormControlLabel value="refused_other_reason" control={<Radio className={classes.radioButton} />} label="Other" />
                                {/* <FormControlLabel value="unable" control={<Radio />} label="Others" /> */}
                            </RadioGroup>

                            { this.state.drawItemStatus === "refused_other_reason" &&
                            <TextField
                                // className={classes.content}
                                hidden={!this.state.isPatientRefusesUnableToDraw}
                                id="patient-unable-to-draw-reason"
                                label="Please specify reason here..."
                                multiline
                                rows="4"
                                margin="normal"
                                defaultValue={this.selectedPatientDraw.status_notes_p}
                                // variant="fullWidth"
                                fullWidth
                                />
                            }
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.buttons}
                            onClick={this.handleSubmitRefuseReason}
                            hidden={!this.state.isPatientRefusesUnableToDraw}
                            > Submit</Button>
                    </DialogActions>
                </Dialog>   

                <Dialog
                    fullScreen={false}
                    classes={{
                        paper: classes.dialogPaper
                    }}
                    // variant="fullWidth"
                    // maxWidth="xs"
                    open={this.state.dialogOpenedPrintBarcodes}
                    onClose={this.handleCloseDialogPatientRefused}
                    aria-labelledby="responsive-dialog-title"
                    >
                    <DialogTitle id="responsive-dialog-title">Printing Barcodes</DialogTitle>
                    <DialogContent>
                        <Grid
                            container
                            alignContent="center"
                            direction="column"
                            justify="center"
                            alignItems="center"
                            style={{height: '150px'}}
                            >
                            <br />
                            <MDSpinner size={50} />
                            <br/>
                            <Typography variant="h6">
                                Barcode {this.state.printBarcodesInfo.current} of {this.state.printBarcodesInfo.total}
                            </Typography>
                        </Grid>
                    </DialogContent>
                </Dialog>   

                <CommonDialog data={this.state.dialogdata['save_status_done']} />
                <CommonDialog data={this.state.dialogdata['reset_draw_status']} />
                <CommonDialog data={this.state.dialogdata['require_barcode']} />
                <CommonDialog data={this.state.dialogdata['require_signature']} />
                <Dialog
                    id="patient-details-pdf-dialog"
                    fullScreen={true}
                    open={this.state.openedPatientPDFDialog}
                    aria-labelledby="responsive-dialog-title"
                    >
                    <DialogContent className={classes.pdfDialogContainer}>
                        <Button onClick={this.triggerPatientPDFDialog} className={classNames(classes.btnClosePDF)} variant="contained" color="default">x</Button>
                        <CustomPDFViewer />
                    </DialogContent>
                </Dialog>

                <ConfirmDialog 
                    visible = {this.state.setDeleteNotesDialog} 
                    title = {this.state.confirmDialogTitle} 
                    message = {this.state.confirmDialogMessage} 
                    actionText= {this.state.confirmDialogActionText} 
                    actionHandler={this.state.actionHandler} 
                    postActionHandler={this.state.postActionHandler} 
                    onClose={() =>{ 
                        setTimeout(() => {
                            this.setDeleteNoteDialogVisible(false);
                        }, 100);

                    } }
                />
                
            </div>
        );

    }
}

PatientClass.propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    theme: PropTypes.object.isRequired,
};

const Patient = withCookies(connect(mapStateToProps,mapDispatchToProps)(withMobileDialog()(withStyles(styles, { withTheme: true })(PatientClass))));

export default Patient;
