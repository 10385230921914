import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { connect } from "react-redux"
// import moment from 'moment'

import Typography from '@material-ui/core/Typography'
// import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
// import ArrowRightIcon from '@material-ui/icons/ArrowRight'

// import uuid from 'uuid'
// import Calendar from 'react-calendar/dist/entry.nostyle'
// import moment from 'moment-timezone'

import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
} from '@material-ui/core'

import SwipeableWeekPicker from 'components/common/workflow/SwipeableWeekPicker'

import * as globals from 'components/common/GlobalConstants'

const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const styles = theme => ({
    dialogContainer: {
        width: "100%",
        margin: "20px",
        borderRadius: "10px"
    },
    dialogTitleContainer: {
        padding: "15px !important",
        borderBottom: "1px solid #E6E6E6",
    },
    dialogTitle: {
        fontSize: "20px !important",
        fontWeight: "600",
        textAlign: "center"
    },
    dialogContent: {
        padding: "0px !important",
        paddingTop: "0px !important",
        overflow: "hidden", // REAL: important for swipeable hijack
        // minHeight: "300px"
    },
    dialogActions: {
        padding: "20px !important",
        margin: "0px"
    },
    btnCancel: {
        textTransform: 'capitalize',
        padding: "8px 20px",
        fontSize: "15px !important",
        backgroundColor: globals.v2.COLORS.GRAY,
        color: "black",
        fontWeight: 500,
        "&:focus": { backgroundColor: globals.v2.COLORS.GRAY + " !important" },
        "&:active": { backgroundColor: globals.v2.COLORS.GRAY + " !important" },
        "&:hover": { backgroundColor: globals.v2.COLORS.GRAY + " !important" },
        "&:disabled": { backgroundColor: globals.v2.COLORS.GRAY_DISABLED + " !important" },
    },
    btnSelect: {
        textTransform: 'capitalize',
        padding: "8px 20px",
        fontSize: "15px !important",
        backgroundColor: globals.v2.COLORS.YELLOW,
        color: "black",
        fontWeight: 500,
        "&:focus": { backgroundColor: globals.v2.COLORS.YELLOW + " !important" },
        "&:active": { backgroundColor: globals.v2.COLORS.YELLOW + " !important" },
        "&:hover": { backgroundColor: globals.v2.COLORS.YELLOW + " !important" },
        "&:disabled": { backgroundColor: globals.v2.COLORS.YELLOW_DISABLED + " !important" },
        marginRight: "0px"
    },
    confirmCompletionAvatar: {
        backgroundColor: globals.v2.COLORS.BLUE,
        width: "40px",
        height: "40px",
        marginRight: "10px"
    },
    confirmCompletionIcon: {
        fontSize: "28px"
    },
    confirmCompletionMessage: {
        marginLeft: "50px",
        fontSize: "14px !important"
    },
})

const SelectWeekDialogFunctionClass = (props) => {
    const {
        classes,
        open = false,
        initialSelectedWeek,
        onCancel,
        onSelect
    } = props

    const [modalSelectedWeek, setModalSelectedWeek] = useState(initialSelectedWeek === "" ? 2 : initialSelectedWeek)

    const getAllWeeks = () => {
        let allWeeks = []

        for(var i = globals.v2.SCHEDULE.MINIMUM_WEEKS_ALLOWED; i <= globals.v2.SCHEDULE.MAXIMUM_WEEKS_ALLOWED; i++) {
            allWeeks.push(i)
        }

        return allWeeks
    }

    return (
        <Dialog
            id="select-date-dialog"
            fullScreen={false}
            open={open}
            maxWidth={"md"}
            classes={{
                paperScrollPaper: classes.dialogContainer
            }}
        >
            <DialogTitle className={classes.dialogTitleContainer}>
                <Typography className={classes.dialogTitle}>{modalSelectedWeek} {modalSelectedWeek === 1 ? "Week" : "Weeks"}</Typography>
            </DialogTitle>
            <DialogContent className={classNames(classes.dialogContent, "modal-dialog-content")}>
                <SwipeableWeekPicker
                    initialSelectedWeek={initialSelectedWeek}
                    allWeeks={getAllWeeks()}
                    onChangeWeek={setModalSelectedWeek}
                />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button onClick={onCancel} className={classNames(classes.btnCancel)} color="primary">Cancel</Button>
                <Button onClick={() => onSelect(modalSelectedWeek)} className={classNames(classes.btnSelect)} color="primary">Select</Button>
            </DialogActions>
        </Dialog>
    )
}

const SelectWeekDialog = withRouter(
    withCookies(
        connect(mapStateToProps)(
            withStyles(styles, { withTheme: true })(SelectWeekDialogFunctionClass)
        )
    )
)
export default SelectWeekDialog
