import React, { useState, useEffect } from 'react'

import { withStyles } from '@material-ui/core/styles'
import patientStyles from './patientStyles'

import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import classNames from 'classnames'

import { FormControl, Button } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const ExistingNewPatientFunctionClass = (props) => {
    const { classes, history } = props

    const minDate = new Date();
        minDate.setFullYear(minDate.getFullYear() - 18); //for setting birthday

    const [open, setOpen] = React.useState(false);

    const dialogClickOpen = () => {
         setOpen(true);
    };

    const dialogClickClose = () => {
        setOpen(false);
    };

    const goToAddPatients = () => {
        history.push("/NewPatient")
    };
    
    const SearchPatient = () => {
        history.push("/SearchPatient")
    };

    return (
        <>
        <div className={classes.patientDetailsContainer}>
            <FormControl variant="outlined" className={classNames(classes.fullWidth)}>
                <Button className={classes.cancelButton} onClick={dialogClickOpen}> Add Existing/New Patient </Button>

                <Dialog id="options-dialog" fullScreen={false} open={open} maxWidth={"md"}
                    classes={{paperScrollPaper: classes.addDataOptionsDialogContainer}}>               
                    <DialogContent 
                        className={classes.addDataOptionsDialogContent} 
                        onClose={dialogClickClose} 
                        aria-labelledby="confirm-title" 
                        id="confirm-dialog" 
                        fullScreen={false} 
                        disableBackdropClick={true}
                        classes = {{ paper: classes.cancelDialogBox}} 
                    >
                        <Button classes={{root: classes.btnCaptureData, label: classes.btnCaptureDataLabel}} onClick={SearchPatient}> Add Existing Patients </Button>
                        <Button classes={{root: classes.btnCaptureData, label: classes.btnCaptureDataLabel }} onClick={goToAddPatients}> Add New Patients </Button>
                        <Button className={classes.btnCloseAddData} onClick={dialogClickClose}> Close </Button>   
                    </DialogContent>
                </Dialog>
            </FormControl>       
        </div>
        </>
    )
}
const ExistingNewPatient = withRouter (
withCookies(
    connect(mapStateToProps)(
        withStyles(patientStyles, { withTheme: true })(ExistingNewPatientFunctionClass)
        )
    )
)
export default ExistingNewPatient