// import * as globals from 'components/common/GlobalConstants';

const styles = theme => ({
    // sitesTableRow: {
    // },
    // tableCell: {
    //     padding: '0px 0px 10px 14px !important',
    // },
    // sitesTableCell: {
    //     border: 'none !important',
    // },
    // container: {
    //     "& div": {
    //         marginRight: "10px",
    //     },
    // },
    content: {
        backgroundColor: '#F8F8F8',
        borderRadius: 4,
        margin: "0px 5px !important",
        padding: '20px',
        boxShadow: '0px 3px 6px #0000001A',
    },
    siteRowCompleted: {
        backgroundColor: '#D0D0D0',
    },
    siteInfo: {
        display: "table-cell",
        verticalAlign: "top",
        paddingLeft: "5px",
        width: "1000px", // just a hack for now
        '& .orders': {
            borderRadius: "15px",
            padding: "8px 16px",
            fontSize: "12px",
            color: "#69CEF1 !important",
            backgroundColor: "white",
            fontWeight: 500
        }
    },
    siteName: {
        fontSize: "18px !important",
        fontWeight: 600,
    },
    collectionTime: {
        fontSize: "14px !important",
        fontWeight: 500,
    },
    siteAddress: {
        fontSize: "12px !important",
    },
    siteType: {
        color: "white !important",
        padding: "2px 5px",
        display: "inline",
        fontSize: "11px !important",
        borderRadius: "3px",
        backgroundColor: "#69CEF1",
        textTransform: "capitalize"
    },
    patientCounterPerSite: {
        marginLeft: 7,
        fontSize: "12px !important",
        color: '#707070',
        display: 'inline',
        whiteSpace: 'nowrap'
    },
    optionsContainer: {
        // display: "table-cell",
        // verticalAlign: "middle",
        // padding: "10px 10px"
    },
    optionsIcon: {
        width: 25,
        height: 25,
        padding: '8px 15px'
    },
    forFinalApproval: {
        position: "absolute",
        bottom: 24,
        right: 14,
        fontSize: "12px !important"
    },
    ordersButtonContainer: {
        marginTop: "18px"
    },
    ordersButton: {
        backgroundColor: "white",
        borderRadius: "4px",
        fontSize: "16px",
        padding: "8px 16px",
        boxShadow: "0px 1px 3px #f0f0f0",
        marginRight: "10px",
        "&:focus": {
            backgroundColor: "white",
        }
    },
    ordersButtonIcon: {
        fill: "#69CEF1 !important",
        width: 24,
        height: 24,
        marginRight: "10px",
    },
    navigationIcon: {
        marginTop: "-5px",
        transform: "rotate(45deg)"
    }
});

export default styles;