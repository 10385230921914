import React from 'react';
import { withStyles } from '@material-ui/core/styles';
//import classNames from 'classnames';

import Grid from '@material-ui/core/Grid';
//import Icon from '@material-ui/core/Icon';
//import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    gridRoot: {
        display: 'flex',
        padding: "15%",
        height: '90vh'
    },
    btnNoDrawlist: {
        marginRight: theme.spacing.unit,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
}); 
// const closeWindow =()=>{
//     //window.alert("Close window")
//     //window.close('','_parent','')
//     var customWindow = window.open('', '_blank', '');
//     customWindow.close(); 
//     //window.open('','_self').close()
// }
const NoMultiTabErrorClass =(props)=> {
 
      const { classes } = props;

        return(
            <Grid
                container
                className={classes.gridRoot}
                alignContent="center"
                direction="column"
                justify="center"
                alignItems="flex-start"
            >
                <Typography style={{"color":"#0277bd"}} variant="h4">The Phleb app is already open in the other tab.</Typography><br/>
                <Typography>Please close this window.</Typography>
                <br/>
                <Grid container>
                    {/* <Button 
                        variant="contained" 
                        color="secondary" 
                        className={classNames(classes.btnNoDrawlist)}
                        // onClick={}
                    >Retry<Icon className={classNames(classes.rightIcon)}>refresh</Icon>
                    </Button> */}
                    {/* <Button 
                        variant="contained" 
                        color="secondary"
                        className={classNames(classes.btnNoDrawlist)}
                         //onClick={window.close('','_parent','')} 
                         onClick={closeWindow} 
                        >
                        <Icon className={classNames(classes.leftIcon)}>Close</Icon>Logout
                    </Button> */}
                </Grid>
            </Grid>)
        
}

const NoMultiTabError = withStyles(styles)(NoMultiTabErrorClass)
export default NoMultiTabError
