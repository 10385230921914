import React, { useRef, useEffect, useLayoutEffect } from 'react'
import classNames from 'classnames'

import SignaturePad from 'react-signature-pad-wrapper'
import trimCanvas from 'trim-canvas'

// import CheckIcon from '@material-ui/icons/Check';
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import $ from 'jquery'
// import uuid from "uuid"
// import testImage from './test-images/step7-signature.PNG'

export const SignBelow = (props) => {
    const {
        readOnly,
        classes,
        // hasEdit,
        signatureData,
        captureSignature,
        acceptSignature,
        clearSignature,
    } = props

    // https://reactjs.org/docs/hooks-reference.html#uselayouteffect
    useLayoutEffect(() => {
        $("#sign-button").height($("#sign-button").width() * 1.25)
        $("#medication-image-container").height($("#medication-image-container").width() * 1.1)

        var signature_canvas_jquery = $("#patient-signature-pad-container > canvas")
        signature_canvas_jquery.css({
            width: $("#medication-image-container").width() + "px",
            height: $("#medication-image-container").height() + "px"
        })

        var signature_img_jquery = $("#patient-signature-pad-container > img")
        signature_img_jquery.css({
            width: $("#medication-image-container").width() + "px",
            height: $("#medication-image-container").height() + "px"
        })

        // IMPORTANT!!!
        // scale the canvas accordingly(ratio) especially after resizing it
        // https://github.com/szimek/signature_pad#handling-high-dpi-screens
        const canvas = signRef.current.canvas
        const ratio = Math.max(window.devicePixelRatio || 1, 1)
        canvas.width = canvas.offsetWidth * ratio
        canvas.height = canvas.offsetHeight * ratio
        canvas.getContext("2d").scale(ratio, ratio)
        signRef.current.clear()
    }, [])

    const signRef = useRef(null)
    const processSignatureData = () => {
        var trimmed_canvas = getTrimmedCanvas()
        var canvas = signRef.current.canvas

        captureSignature({
            signature: signRef.current.toData(),
            metadata: {
                ratio: window.devicePixelRatio || 1,
                canvas_width: canvas.width,
                canvas_height: canvas.height,
                max_draw_width: trimmed_canvas.width,
                max_draw_height: trimmed_canvas.height
            }
        })
        acceptSignature()
    }

    const triggerClearSignature = () => {
        signRef.current.clear()
        clearSignature()
    }

    const triggerAcceptSignature = () => {
        acceptSignature()
    }

    useEffect(() => {
        //  if (signatureData.sign_off !== undefined) {
        //        signRef.current.clear()
        //         signRef.current.fromData(signatureData.sign_off)
        //     }
        if (signatureData.sign_off !== undefined) {
            signRef.current.clear()
            signRef.current.fromData(signatureData.sign_off)
            if (readOnly) {
                signRef.current.off()
            }
        }


    }, [signatureData])

    const getTrimmedCanvas = () => {
        // copy the canvas
        var canvas = signRef.current.canvas
        var copy = document.createElement('canvas')
        copy.width = canvas.width
        copy.height = canvas.height
        copy.getContext('2d').drawImage(canvas, 0, 0)
        // then trim it
        return trimCanvas(copy)
    }

    useEffect(() => {
        // CALL YOUR API OR ASYNC FUNCTION HERE
        // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function
        return () => { signRef.current = false }
    }, [])


    return (
        <div
            id="sign-button"
        >
            <div
                style={{
                    textAlign: "center",
                    position: "relative",
                }}
            >
                <Typography className={classes.signBelowLabel}>
                    {readOnly ? "Signature" : "Please sign below"}
                </Typography>
                {!readOnly &&
                    <>
                        {signatureData && Object.keys(signatureData).length !== 0 &&
                            <IconButton aria-label="RotateLeft" className={classNames(classes.btnResetSignature)} onClick={() => triggerClearSignature()}>
                                <RotateLeftIcon></RotateLeftIcon>
                            </IconButton>
                        }
                    </>
                }
                {/* {!readOnly &&
                    <>
                        {hasEdit &&
                            <IconButton aria-label="Check" className={classNames(classes.btnAcceptSignature)} onClick={() => triggerAcceptSignature()}>
                                <CheckIcon></CheckIcon>
                            </IconButton>
                        }
                        {signatureData && Object.keys(signatureData).length !== 0 &&
                            <IconButton aria-label="RotateLeft" className={classNames(classes.btnResetSignature)} onClick={() => triggerClearSignature()}>
                                <RotateLeftIcon></RotateLeftIcon>
                            </IconButton>
                        }
                    </>
                } */}
            </div>
            <div>
                <div id="medication-image-container">
                    <div
                        id="patient-signature-pad-container"
                        className={classes.signaturePadContainer}
                    >
                        <SignaturePad
                            id="patient-signature-canvas"
                            redrawOnResize={true}
                            options={{
                                onEnd: processSignatureData
                            }}
                            ref={signRef}
                        />
                        {/* <img id="patient-signature-img" src={signatureData.signature} className={classes.signatureImage} alt="captured signature" /> */}
                    </div>
                </div>
            </div>
        </div>
    )
}