import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
import classNames from 'classnames'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import ApplicationBar from 'components/common/workflow/ApplicationBar'
// import SimpleBottomNavigation from 'components/common/iv-infusion/SimpleBottomNavigation'

import ApptDetailsHeader from './ApptDetailsHeader'
import AboutDetails from './AboutDetails'


const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const AboutFunctionClass = (props) => {
    const { classes } = props


    return (
        <>
            <div className={classes.root}>
                <main className={classes.content}>
                    <div className={classNames(classes.mainContainer)}>
                        <ApplicationBar />

                        <ApptDetailsHeader />
                        <AboutDetails />
                    </div>
                </main>
            </div>
        </>
    )
}

const About = withCookies(
    connect(mapStateToProps)(
        withStyles(styles, { withTheme: true })(AboutFunctionClass)
    )
)
export default About
