import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import $ from 'jquery';

const styles = theme => ({
    dateSection: {
        display: 'flex',
        alignItems: 'center',
        textShadow: '1px 1px 2px #fff',
    },
    datetimeLabel: {
        display: "inline",
        fontSize: "18px !important",
        // fontWeight: "regular !important",
    },
});

class CurrentDate extends React.Component {
    componentDidMount() {
        this.handleRealTimeDate();
    }

    handleRealTimeDate() {
        var currentDate = new Date(),
        display_day = currentDate.getDay(),
        day = currentDate.getDate(),
        month = currentDate.getMonth() + 1,
        year = currentDate.getFullYear();
        
        // var currentTime = new Date(),
        // hours = currentTime.getHours(),
        // minutes = currentTime.getMinutes();

        // if (minutes < 10) {
        //     minutes = "0" + minutes;
        // }

        // var suffix = "AM";
        // if (hours >= 12) {
        //     suffix = "PM";
        //     hours = hours - 12;
        // }
        // if (hours === 0) {
        //     hours = 12;
        // }
        // var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        // var days = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
        var days = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
        
        // console.log(days[display_day] + ", " + months[month - 1] + " " + day + ", " + year + " " + hours + ":" + minutes + " " + suffix);
        $("#datetime-holder").html(days[display_day] + ", " + months[month - 1] + " " + day + ", " + year);// + " " + hours + ":" + minutes + " " + suffix);
    };
  
    render() {
        var textAlignment = "flex-start";
        const { classes, textAlign } = this.props;

        if(textAlign === "right") {
            textAlignment = "flex-end";
        }

        return (
            <div 
                className={classes.dateSection}
                style={{
                    justifyContent: textAlignment
                }}
                >
                <Typography 
                    id="datetime-holder" 
                    className={classes.datetimeLabel}
                    variant="body1"></Typography>
            </div>
        );
    }
}

CurrentDate.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CurrentDate);



