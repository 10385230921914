import React from 'react';
// import classNames from 'classnames';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import * as helper from 'components/common/Helper.js'
import moment from 'moment-timezone';

// import { ReactComponent as PrintSVG } from 'components/common/mobile/CustomOfflineSVG/print.svg';
// import { ReactComponent as PrintDisabledSVG } from 'components/common/mobile/CustomOfflineSVG/print_disabled.svg';


import PrintLabelButton2 from 'components/common/controls/PrintLabelButton2'
import PrintLabelDialog from 'components/common/controls/PrintLabelDialog'
var showDocumentList = {} 
const FutureOrderSiteDrawList = (props) => {
  
  const {classes, futureOrder, siteOrder} = props;
  // console.log("FutureOrderDrawList: futureOder");
  // console.log(futureOrder);
  // console.log(siteOrder);

  const drawSiteItem = futureOrder.draw_list.filter(siteItem => {
    // console.log(typeof(siteItem.site_order)  + " " + typeof(siteOrder));
    return siteItem.site_order+"" === siteOrder;
  });
 

 

//  console.log(drawSiteItem);

 const patientList = drawSiteItem[0].patient_list.map(patientItem => {
      //console.log("drawItem");
      // console.log(drawItem.patient_list[0].patient_draw);
      //var DrawListDetails = "";
      var age;
      //var gender = patientItem.patient.gender === "male" ? "Male" : "Female";
      age = moment().diff( patientItem.patient.birth_date, 'years');
          
      var secondaryText = "Age " + age;
     
      if(patientItem.urgent === true) {
        secondaryText = secondaryText + ", Urgent"
      }

      if(patientItem.fasting === true) {
        secondaryText = secondaryText + ", Fasting"
      }
    
      // console.log(patientItem)
    //   var labels = patientItem.patient_draw[0].labels;
      // var printLabelsEnabled = false;

      // if(labels && labels.length > 0) {
      //   printLabelsEnabled = true;
      // }
        //console.log(patientItem.patient_draw);
        // DrawListDetails = patientItem.patient_draw.map(draw => {
        
        //   return(
        //     <ListItem className={classes.drawItem} key={Math.random()}>
        //       <ListItemIcon>
        //       <img alt="Draw Request" className={classes.imgIcon} src="/images/draw_request_outline.svg" />
        //       </ListItemIcon>
        //       <ListItemText style={{"margin": 0, "padding": 0}} primary={draw.name} />
        //     </ListItem>
        //       );
        // });


       return(     
         <div className={classes.patientListItems} key={Math.random()}>
                   
          <ListItem key={patientItem.patient.address}
            className={classes.futureOrderDrawList}>
            {/* <Icon className={classes.icon}>{patientItem.site_type === "individual" ? "person" : "person"}</Icon> */}
            <ListItemText
              classes={{
                primary: classes.futureOrdersListPrimary,
                secondary: classes.futureOrdersListSecondary
              }} 

              secondary= {secondaryText}

            />
      
            <ListItemIcon className={classes.printListCont}>
                {/* 
                <PrintLabelButton 
                  labels={patientItem.patient_draw[0].labels}
                  onClick={(label) => {helper.printLabel(label)}} 
                /> */}
                 <PrintLabelButton2 onClick={()=>{showDocumentList(patientItem.patient_draw[0].labels)}} labels={patientItem.patient_draw[0].labels} />

            </ListItemIcon>
          </ListItem>
                </div>
        );
   });
   
  return(
    <React.Fragment>
    {patientList}
    <PrintLabelDialog showDocumentList={(m)=>{showDocumentList=m}} onClick={(label)=>{helper.printLabel(label) }} />
    </React.Fragment>
    )
}

export default FutureOrderSiteDrawList;
