import React, {Component} from 'react';
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles';

import styles from "./styles.js";
import { ReactComponent as PrintSVG } from 'components/common/mobile/CustomOfflineSVG/print.svg';
import { ReactComponent as PrintDisabledSVG } from 'components/common/mobile/CustomOfflineSVG/print_disabled.svg';

// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
//import withMobileDialog from '@material-ui/core/withMobileDialog';
// import MuiDialogTitle from '@material-ui/core/DialogTitle';
// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
 

// 
// import { makeStyles } from '@material-ui/core/styles/';
// import ListSubheader from '@material-ui/core/ListSubheader';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import Collapse from '@material-ui/core/Collapse';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import DraftsIcon from '@material-ui/icons/Drafts';
// import SendIcon from '@material-ui/icons/Send';
// import ExpandLess from '@material-ui/icons/ExpandLess';
// import ExpandMore from '@material-ui/icons/ExpandMore';
// import StarBorder from '@material-ui/icons/StarBorder';
// import DescriptionIcon from '@material-ui/icons/Description';


// ===


// const DialogTitle = withStyles(theme => ({
//     root: {
//       borderBottom: `1px solid ${theme.palette.divider}`,
//       margin: 0,
//       padding: theme.spacing.unit * 2,
//     },
//     closeButton: {
//       position: 'absolute',
//       right: theme.spacing.unit,
//       top: theme.spacing.unit,
//       color: theme.palette.grey[500],
//     },
//   }))(props => {
//     const { children, classes, onClose } = props;
//     return (
//       <MuiDialogTitle disableTypography className={classes.root}>
//         <Typography variant="h6">{children}</Typography>
//         {onClose ? (
//           <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
//             <CloseIcon />
//           </IconButton>
//         ) : null}
//       </MuiDialogTitle>
//     );
//   });
  


class PrintLabelButton extends Component {
  
    // constructor(props) {
    //     super(props);
       

    //  }// constructor 
  
    componentDidUpdate(prevProps, prevState) {
     //   console.log("componentDidUpdate")
    }

    onClick=()=>{
        if(typeof this.props.onClick === "function") {
            this.props.onClick()
       } else {
           console.log("Please implement onClick callback")
       }
    }

    render() {
        const {classes, labels} = this.props; 
        var printLabelsEnabled = false

        if(labels && labels.length > 0) {
            printLabelsEnabled = true;
        }
        
        
        return( 
            <div>
                <button 
                disabled={!printLabelsEnabled}
                //onClick={() => {props.onClick(labels)}} 
                onClick={() => {this.onClick()}} 
                className={classes.printIconCont}>
                {!printLabelsEnabled
                ?
                <PrintDisabledSVG className={classNames(classes.printIcon)}></PrintDisabledSVG>
                :
                <PrintSVG className={classNames(classes.printIcon)}></PrintSVG>
                }
                </button>
            </div>
        )

    }

}

export default (withStyles(styles)(PrintLabelButton))