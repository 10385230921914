import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

// import ImmutableJS from "immutable";
// import EllipsisText from "react-ellipsis-text";

// import Fab from '@material-ui/core/Fab';
// import CameraAltIcon from '@material-ui/icons/CameraAlt';

// import Badge from '@material-ui/core/Badge';

import Typography from '@material-ui/core/Typography';

import ConfirmDialog from 'components/common/mobile/ConfirmDialog';
// import NotesIcon from '@material-ui/icons/Notes';
// import DeleteIcon from '@material-ui/icons/Delete';
// import AddIcon from '@material-ui/icons/Add';
// import $ from 'jquery';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Button from '@material-ui/core/Button';

// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
// import { InputBase } from '@material-ui/core';
// import Icon from '@material-ui/core/Icon';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// import TextField from '@material-ui/core/TextField';

import styles from "./styles.js";

import TextField from '@material-ui/core/TextField'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

// import InputAdornment from '@material-ui/core/InputAdornment';
// import HighlightOff from '@material-ui/icons/HighlightOff';

import { BrowserMultiFormatReader } from '@zxing/library'; // , BrowserQRCodeReader
// import classNames from 'classnames';

import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';

import { connect } from "react-redux";

// import useSound from "use-sound"
//import scannerSFX from '../../../../scanner_sound.mp3';
import * as globals from 'components/common/GlobalConstants';
import * as helper from 'components/common/Helper.js'
import $ from 'jquery'
import CameraPermissionError from 'components/common/mobile/CameraPermissionErrorComponent'
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos'

import {
    // updatePatientDrawStatus,
    // verifyPatientName,
    // verifyPatientDoB,
    // saveNote,
    // deleteNote,
    saveDrawRequestDocument,
    deleteDrawRequestDocument,
    logEvent,
    setCameraAvailable
} from "js/actions";

const EDIT = "edit"
const NEW = "new"
const PREVIEW = "preview"

// const StyledBadgeNotSelected = withStyles((theme) => ({
//     badge: {
//         backgroundColor: '#0277bd',
//         color: 'white',
//         right: 22,
//         top: 22,
//         // border: `2px solid ${theme.palette.background.paper}`,
//         // padding: '0 4px',
//         height: 21,
//         width: 21,
//         fontWeight: 'bold'
//     },
// }))(Badge);

// const StyledBadgeSelected = withStyles((theme) => ({
//     badge: {
//         backgroundColor: 'white',
//         color: '#0277bd',
//         right: 22,
//         top: 22,
//         // border: `2px solid ${theme.palette.background.paper}`,
//         // padding: '0 4px',
//         height: 21,
//         width: 21,
//         fontWeight: 'bold'
//     },
// }))(Badge);

// const DialogTitle = withStyles(theme => ({
//     root: {
//       borderBottom: `1px solid ${theme.palette.divider}`,
//       margin: 0,
//       padding: theme.spacing.unit * 2,
//     },
//     closeButton: {
//       position: 'absolute',
//       right: theme.spacing.unit,
//       top: theme.spacing.unit,
//       color: theme.palette.grey[500],
//     },
//   }))(props => {
//     const { children, classes, onClose } = props;
//     return (
//       <MuiDialogTitle disableTypography className={classes.root}>
//         <Typography variant="h6">{children}</Typography>
//         {onClose ? (
//           <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
//             <CloseIcon />
//           </IconButton>
//         ) : null}
//       </MuiDialogTitle>
//     );
// });

const mapDispatchToProps = dispatch => {
    return {
        // updatePatientDrawStatus: drawItem => dispatch(updatePatientDrawStatus(drawItem)),
        // verifyPatientName: verifiedPatientNameData => dispatch(verifyPatientName(verifiedPatientNameData)),
        // verifyPatientDoB: verifiedPatientDoBData => dispatch(verifyPatientDoB(verifiedPatientDoBData)),
        // saveNote: note => dispatch(saveNote(note)),
        // deleteNote: note => dispatch(deleteNote(note)),
        saveDrawRequestDocument: document => dispatch(saveDrawRequestDocument(document)),
        deleteDrawRequestDocument: document => dispatch(deleteDrawRequestDocument(document)),
        logEvent: event => dispatch(logEvent(event)),
        setCameraAvailable: value => dispatch(setCameraAvailable(value))
    }
}



const mapStateToProps = state => {
    return {
        drawData: state.drawData,
        isCameraAvailable: state.isCameraAvailable
    }
}

class BarcodeReaderClass extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        //const { cookies } = this.props;

        this.state = {
            ...this.state,
            siteId: "",
            patientId: "",
            // dialogOpenedPatientRefused: false,
            dialogOpenedBarcodeScanner: false,
            dialogOpenedBarcodeCapture: false,
            barcodeOperation: NEW,
            barcodeCaptureOperation: NEW,
            selectedBarcode: null,
            setDeleteBarcodeDialog: false,
            confirmDialogMessage: "",
            confirmDialogTitle: "",
            barcode: "",
            btnScanDisabled: false,
            barcodeValue: "",
            barcodeComment: "",
            maxChars: globals.DEFAULT_BARCODE_COMMENT_MAX_CHARACTERS,
            errorOnCamera: false,
            devices: [],
            cameraId: "",
            zoomSliderDisabled: true,
            zoomSliderValue: 0,
            fieldDataCapture: props.fieldDataCapture ? props.fieldDataCapture : false
        };
        this.selectedPatientDraw = undefined;
        this.drawSiteValue = "right_arm";
        this.modifiedDrawSite = false;

        this.codeReader = new BrowserMultiFormatReader()
        this.soundEffect = new Audio();

        // onClick of first interaction on page before I need the sounds
        this.soundEffect.play();
    }

    componentWillMount() {
        this.setState({
            ...this.state,
            patientId: this.props.patientId,
            siteId: this.props.siteId,
            readOnly: this.props.readOnly
        });
    }

    componentDidMount() {
        this.listCamera() // Temp for experiment only
        const cam_id = helper.getLocalStorageValue("camid_b")
        if (cam_id !== "") {
            this.changeCameraTo(cam_id)
        }

        this.props.barcodeCaptureRef.current = {
            addNewBarcode: this.addNewBarcode,
            openBarcode: this.openBarcode,
            deleteBarcode: this.deleteBarcode
        }

        // if (typeof this.props.deleteBarcode === "function") {
        //     this.props.deleteBarcode(this.deleteBarcode)
        // }

        // if (typeof this.props.addNewBarcode === "function") {
        //     this.props.addNewBarcode(this.addNewBarcode)
        // }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (globals.DEBUG_ALLOWED) {
            console.log("Barcode: Camera count ", this.state.devices.length)
        }

        if (helper.getMobileOperatingSystem() === "iOS" || helper.getMobileOperatingSystem() === "Android") {
            if (this.state.devices.length < 2) {
                this.listCamera()
            }
        } else {
            if (this.state.devices.length === 0) {
                this.listCamera()
            }
        }
    }

    changeCameraTo = (cam_id) => {
        this.setState({ ...this.state, cameraId: cam_id, isShowWhiteFlash: false }, () => {
            if (this.state.dialogOpenedBarcodeScanner === true) {
                this.startScanning()
            } else {
                // console.log("Camera initialization only...")
            }
        })

        // helper.setCookieValue("camid_b",cam_id)
        helper.setLocalStorageValue("camid_b", cam_id)
        if (globals.DEBUG_ALLOWED) console.log("Changing camera to camera " + cam_id)
    }

    // handleOpenDialog = event => {
    //     this.setState({ ...this.state, dialogOpenedPatientRefused: true });
    // };

    // setDeleteBarcodeDialogHandler = (actionHandler, postActionHandler, title, message, actionText, chainedFunction) => {
    //     // if(chainedFunction === null) chainedFunction = ()=>{};
    //     this.setState(
    //         {
    //             ...this.state,
    //             actionHandler: actionHandler,
    //             postActionHandler: postActionHandler,
    //             confirmDialogMessage: message,
    //             confirmDialogTitle: title,
    //             confirmDialogActionText: actionText,
    //         },
    //         chainedFunction
    //     );
    // }

    setDeleteBarcodeDialogVisible = (value) => {
        this.setState({ ...this.state, setDeleteBarcodeDialog: value });
    }

    // handleChangeBarcode = (event) => {
    //     this.setState({ ...this.state, barcode: event.target.value })
    // }

    // clearChangeBarcode = () => {
    //     this.setState({ ...this.state, barcode: "" })
    // }

    // setNotesDialogVisibility = (value, barcodeOperation = NEW) => {
    //     var selectedBarcode = this.state.selectedBarcode;
    //     if (barcodeOperation === NEW) {
    //         selectedBarcode = {
    //             "id": "",
    //             "client_timezone": "",
    //             "author_type": "",
    //             "message": "",
    //             "client_deleted": "false",
    //             "client_timestamp": ""
    //         }
    //     }
    //     this.setState({
    //         ...this.state, barcodeOperation: barcodeOperation,
    //         selectedBarcode: selectedBarcode, dialogOpenedBarcodeScanner: value
    //     });
    // };

    // deleteNote = (operation) => {
    //     if (this.state.readOnly) {
    //         return
    //     }
    //     this.props.deleteNote({
    //         "barcodeOperation": this.state.barcodeOperation,
    //         "siteId": this.state.siteId,
    //         "patientId": this.state.patientId,
    //         "patientDrawId": this.props.patientDrawId,
    //         "target": this.props.target,
    //         "note": this.state.selectedBarcode,
    //     });
    //     this.scanBarcodeDialogVisibility(false);
    // }

    // openNote = (noteId) => {
    //     var iNotes = ImmutableJS.fromJS(this.props.notes);
    //     var noteIndex = iNotes.findIndex(id => id.get('id') === noteId);
    //     this.setState({ ...this.state, selectedBarcode: iNotes.getIn([noteIndex]).toJS() }, () => {
    //         this.scanBarcodeDialogVisibility(true, EDIT)
    //     });
    // };

    acceptBarcode = () => {
        if (this.state.barcodeValue !== "") {
            this.addRequisition()
            // this.setState({ ...this.state, barcode: this.state.barcodeValue, barcodeValue: "" }, () => {
            //     setTimeout(() => {
            //         this.scanBarcodeDialogVisibility(false)
            //     }, 1000)
            // })
        } else {
            console.log("barcode value is empty")
        }
    }

    openBarcode = (barcode) => {
        // var iBarcodes = ImmutableJS.fromJS(this.props.barcodes)
        // var barcodeIndex = iBarcodes.findIndex(id => id.get('id') === barcodeId)
        // console.log(iBarcodes.getIn([barcodeIndex]).toJS())
        console.log("openBarcode barcode")
        console.log(barcode)
        this.setState({
            ...this.state,
            selectedBarcode: barcode,
            barcodeValue: barcode.metadata.requisition_code,
            barcodeComment: barcode.metadata.comment
        }, () => {
            this.setCaptureBarcodeDialogVisibility(true, PREVIEW)
        })
    }

    updateDrawRequestDocument = (payload) => {
        this.props.saveDrawRequestDocument(payload)
    }

    deleteDrawRequestDocument = (payload) => {
        this.props.deleteDrawRequestDocument(payload)
    }

    setDeleteRequisitionDialogHandler = (actionHandler, postActionHandler, title, message, actionText, chainedFunction) => {
        // if(chainedFunction === null) chainedFunction = ()=>{};
        if (this.props.readOnly) {
            return
        }

        this.setState(
            {
                ...this.state,
                actionHandler: actionHandler,
                postActionHandler: postActionHandler,
                confirmDialogMessage: message,
                confirmDialogTitle: title,
                confirmDialogActionText: actionText,
            },
            chainedFunction
        );
    }

    // setDeleteBarcodeDialogVisible = (value) => {
    //     this.setState({ ...this.state, setDeleteBarcodeDialog: value });
    // }

    removeDrawRequestDocument = (document) => {
        const siteId = this.state.siteId
        const patientId = this.state.patientId

        var payload = {
            siteId: siteId,
            type: "barcode",
            patientId: patientId,
            target: "patient_draw",
            document: document
        }

        this.setState({ ...this.state, barcodeValue: "", barcodeComment: "" },
            this.deleteDrawRequestDocument(payload)
        )

    }

    addRequisition = () => {
        //    if(this.state.readOnly) {
        //        return
        //    }
        if (this.state.barcodeValue === "") return

        const siteId = this.state.siteId
        const patientId = this.state.patientId

        var comment = $('#barcode_field').val();

        var document = { ...this.state.selectedBarcode }
        document.metadata = {
            "requisition_code": this.state.barcodeValue,
            "comment": comment.replace(/(<([^>]+)>)/ig, ""),
            "field_data_capture": this.state.fieldDataCapture
        }

        var payload = {
            siteId: siteId,
            patientId: patientId,
            type: "barcode",
            target: "patient_draw",
            doc_type: "metadata",
            path: "",
            filename: "",
            documentOperation: this.state.barcodeCaptureOperation, // edit or new
            document: document,
        }

        console.log("updateDrawRequestDocument payload")
        console.log(payload)

        this.updateDrawRequestDocument(payload)
        this.setState({
            ...this.state,
            dialogOpenedBarcodeCapture: false,
            dialogOpenedBarcodeScanner: false,
            selectedBarcode: null,
            barcodeValue: "",
            barcodeComment: ""
        }, this.codeReader.reset())

        // this.setCaptureBarcodeDialogVisibility(false, this.state.barcodeCaptureOperation)
        // this.setState({ ...this.state, barcodeValue: "", barcodeComment: "" }, () => {
        //     console.log("addRequisition payload")
        //     console.log(payload)
        //     setTimeout(() => {
        //     }, 1000)
        // })
    }

    handleCameraError = (error) => {
        this.props.logEvent({
            module: "barcode_reader2",
            action: "load_camera",
            description: error.toString(),
            //module_id: siteId
        })
        console.log('handleCameraError in barcode2', error.toString());
        this.props.setCameraAvailable(false) // This is for Redux/Global state for camera availability
        this.setState({ ...this.state, errorOnCamera: true })
    }

    scanBarcodeDialogVisibility = (value, barcodeOperation = NEW) => {
        // if(this.state.readOnly) {
        //     return
        // }

        if (value === false) {
            this.stopScanning()
        } else {
            this.startScanning()
        }

        var selectedBarcode = this.state.selectedBarcode;

        if (barcodeOperation === NEW) {
            selectedBarcode = {
                id: "",
                doc_type: "metadata",
                metadata: {
                    requisition_code: '',
                    comment: ''
                }
            }
        }

        this.setState({
            ...this.state,
            barcodeOperation: barcodeOperation,
            errorOnCamera: false,
            selectedBarcode: selectedBarcode,
            dialogOpenedBarcodeScanner: value,
            barcodeValue: "",
            barcodeComment: ""
        })
    }

    setCaptureBarcodeDialogVisibility = (value, barcodeCaptureOperation = NEW) => {
        this.setState({
            ...this.state,
            barcodeCaptureOperation: barcodeCaptureOperation,
            dialogOpenedBarcodeCapture: value
        })
    }

    listCamera() {

        //if (helper.getMobileOperatingSystem() === "iOS") { // temp ios camera fix
        if (false) {
            var constraints = {
                audio: false,
                video: true,
            }

            navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
                navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
                    const devices = mediaDevices.filter(({ kind }) => kind === "videoinput")
                    if (globals.DEBUG_ALLOWED) {
                        console.log("Devices in listdevices, BarcodeReader2 - iOS")
                        console.log(devices)
                    }
                    this.setState({ ...this.state, devices: devices })
                })
            }).catch((err) => {
                console.log("ERROR in Barcode2 camera iOS")
                if (!this.state.errorOnCamera) {
                    this.handleCameraError(err)
                    console.error("Device access checks failed(BarcodeReader2): ", err);
                }
            })

        } else {
            navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
                const devices = mediaDevices.filter(({ kind }) => kind === "videoinput")
                //console.log("Devices in listdevices, BarcodeReader2 - Android, Unknown")
                //console.log(devices)
                this.setState({ ...this.state, devices: devices })
            }).catch((err) => {
                console.log("ERROR in Barcode2 camera - Android, Unknown")
                if (!this.state.errorOnCamera) {
                    this.handleCameraError(err)
                    console.error("Device access checks failed(BarcodeReader2): ", err);
                }
            })
        }
    }

    startScanning = () => {
        console.log("startScanning")
        // const zoomSliderInput = document.querySelector('input[type="range"]')
        // const torchInput = document.getElementById('torch')
        // zoomSliderInput.hidden = true
        // torchInput.hidden = true
        // console.log("startScanning = ()")
        // console.log("Listing listVideoInputDevices")
        // var deviceId = "";
        var devIds = []
        this.codeReader.listVideoInputDevices().then(videoInputDevices => {
            videoInputDevices.forEach(device => {
                //if (deviceId === "") {
                // deviceId = device.deviceId
                devIds.push(device)
                //}
                if (globals.DEBUG_ALLOWED) {
                    console.log("Camera available:")
                    console.log(`${device.label}, ${device.deviceId}`)
                }
            }
            )
            var cameraId = null
            if (this.state.cameraId && this.state.cameraId !== "null") {
                if (globals.DEBUG_ALLOWED) {
                    console.log("this.state.cameraId is  NOT empty")
                    console.log(typeof this.state.cameraId)
                }
                cameraId = this.state.cameraId
            } //else {
            // cameraId = deviceId
            // }

            const camIdResult = devIds.find(element => element.deviceId === cameraId);
            if (globals.DEBUG_ALLOWED) {
                console.log("cameraId")
                console.log(cameraId)
                console.log("devIds")
                console.log(devIds)
                console.log("camIdResult")
                console.log(camIdResult)
            }

            if (camIdResult === undefined) {
                const backCamera = helper.findBackCamera(devIds)
                if (backCamera) {
                    if (globals.DEBUG_ALLOWED) console.log("Back camera found")
                    cameraId = backCamera
                } else {
                    if (globals.DEBUG_ALLOWED) console.log("Default camera")
                    cameraId = devIds[0].deviceId
                }
                this.setState({ ...this.state, cameraId: cameraId })
            }

            if (globals.DEBUG_ALLOWED) console.log("Accessing camera device : " + cameraId)
            if (!cameraId) {
                this.handleCameraError("Camera not loaded")
            }

            this.codeReader.reset()
            this.codeReader.decodeFromVideoDevice(cameraId, 'video', (result, err) => {
                if (result) {
                    this.listCamera()
                    console.log(result)
                    try {

                        if (this.state.barcodeValue === "") {
                            // later on when you actually want to play a sound at any point without user interaction
                            this.soundEffect.src = "/files/beep.mp3";
                            this.soundEffect.play();

                            this.setState({
                                ...this.state,
                                barcodeValue: result.text,
                                barcodeComment: "",
                                barcodeCaptureOperation: NEW,
                                dialogOpenedBarcodeCapture: true
                            })
                        }
                    } catch (e) {
                        console.log(e)
                    }
                }
                //if (err && !(err instanceof BrowserQRCodeReader.NotFoundException)) {
                if (err) {
                    // This is intentionally NOP on error, 
                    //console.error("decodeFromVideoDevice error...")
                    //console.error(err)
                    //return
                    //document.getElementById('result').textContent = err
                }
            }).catch((err) => {
                console.log("decodeFromVideoDevice")
                console.log(err)
                this.handleCameraError(err)
            })

            // console.log(`Started continous decode from camera `)
            setTimeout(() => {

                if (globals.DEBUG_ALLOWED) {
                    console.log("this.codeReader.stream")
                    console.log(this.codeReader.stream)
                }

                window.codeReader = this.codeReader
                if (this.codeReader.stream) {
                    this.attachZoomFunction(this.codeReader)

                } else {
                    if (globals.DEBUG_ALLOWED) console.log("No selected camera")
                }

            }, 2000)
        }).catch((err) => {
            console.log("1.0")
            console.error(err)
            this.handleCameraError(err)
        })

        this.setState({ ...this.state, btnScanDisabled: true })
    }


    attachZoomFunction = (codeReader) => {
        //console.log("Check 0")    
        //document.querySelector('video').srcObject = mediaStream;
        //console.log("Check 1")    
        const zoomSliderInput = document.querySelector('input[type="range"]')
        const torchInput = document.getElementById('torch')
        if (zoomSliderInput) {
            zoomSliderInput.hidden = true
        }
        if (torchInput) {
            torchInput.hidden = true
        }

        try {
            const track = codeReader.stream.getVideoTracks()[0];
            const capabilities = track.getCapabilities();

            const settings = track.getSettings();
            this.capabilities = capabilities
            this.track = track

            //console.log("Check 2")    

            //input.style.display ="none"
            //$('.ui-slider-handle').hide();
            zoomSliderInput.value = 0
            if (!('zoom' in capabilities)) {
                return ('Zoom is not supported by ' + track.label)
            }
            console.log("Zoom is OK")

            zoomSliderInput.min = capabilities.zoom.min
            zoomSliderInput.max = capabilities.zoom.max
            zoomSliderInput.step = capabilities.zoom.step
            var zoomSliderValue = helper.getLocalStorageValue("zoom_v") !== "" ? helper.getLocalStorageValue("zoom_v") : 0

            if (zoomSliderValue === 0) {
                zoomSliderInput.value = settings.zoom
                zoomSliderValue = settings.zoom
            } else {
                zoomSliderInput.value = zoomSliderValue
                track.applyConstraints({ advanced: [{ zoom: zoomSliderValue }] });
            }

            console.log("Slider is set.")

            zoomSliderInput.oninput = function (event) {
                helper.setLocalStorageValue("zoom_v", event.target.value)
                track.applyConstraints({ advanced: [{ zoom: event.target.value }] });
            }

            torchInput.onchange = (event) => {
                if (event.target.checked) {
                    track.applyConstraints({ advanced: [{ torch: true }] });
                } else {
                    this.startScanning()
                }
            }
            console.log("Zoom is attached in slider.")
            //$('.ui-slider-handle').show();
            helper.setLocalStorageValue("zoom_v", zoomSliderValue)
            this.setState({ ...this.state, zoomSliderDisabled: false })

            zoomSliderInput.hidden = false
            torchInput.hidden = false
            //input.style.display ="block"

        } catch (e) {

            if (!this.errorOnCamera) {
                this.handleCameraError(e)
                console.log("ERROR! Wasn't able to load camera! Following items should be met in order to use the camera:")
                console.log("Access the web app via HTTPS.")
                console.log("Make sure the browser supports access to camera devices and currently set to allowed access to it.")
                console.log("ORIGINAL ERROR MESSAGE:")
                console.log(e)
            }
            return
        }

    }

    sleep = (ms = 0) => {
        return new Promise(r => setTimeout(r, ms));
    }

    stopScanning = () => {
        this.setState({ ...this.state, btnScanDisabled: false },
            this.codeReader.reset())
    }

    resetBarcode = () => {
        this.setState({
            ...this.state,
            barcodeValue: "",
            barcodeComment: "",
            dialogOpenedBarcodeCapture: false
        })
    }

    handleToggleTorch = (event) => {
        console.log("Torch combobox")
        console.log(event.target.checked)
        if (event.target.checked) {

            if (this.capabilities !== undefined) {
                console.log("Torch combobox this.capabilities !== undefined")
                if (this.capabilities.torch !== undefined) {
                    var torchValue = event.target.checked
                    console.log("Torch combobox this.capabilities.torch !== undefined")
                    console.log(" torch: " + !torchValue)
                    this.track.applyConstraints({ advanced: [{ torch: torchValue }] });
                }
            }
        } else {
            this.startScanning()
        }

    }

    deleteBarcode = (barcode) => {
        this.setDeleteRequisitionDialogHandler(
            () => this.removeDrawRequestDocument(barcode),
            () => {
                this.setDeleteBarcodeDialogVisible(false, EDIT)
                this.setCaptureBarcodeDialogVisibility(false, this.state.barcodeCaptureOperation)
                // this.scanBarcodeDialogVisibility(false,EDIT)
            },
            "Delete Confirmation",
            "Confirm to delete this requisition number?",
            "Delete",
            () => {
                this.setDeleteBarcodeDialogVisible(true)
            },
        )
    }

    addNewBarcode = () => {
        this.listCamera()
        this.scanBarcodeDialogVisibility(true, NEW)
    }

    findNextCamera = (cameraId) => {

        var devices = this.state.devices

        for (var i = 0; i < devices.length; i++) {
            if (cameraId === devices[i].deviceId) {
                console.log("WOW there is a match!")
                if (i === devices.length - 1) {
                    return devices[0].deviceId
                } else {
                    return devices[i + 1].deviceId
                }
            }

        }
    }

    render() {
        var { cameraId, barcodeCaptureOperation } = this.state
        const { classes, readOnly } = this.props

        //if (devices.length === 0) return (<div style={{ width: "100%", height: "100%", backgroundColor: "black" }}></div>)

        // alert("device length: " + devices.length)

        // if (DEBUG_ALLOWED) {
        //     console.log("devices")
        //     console.log(devices)
        //     console.log("cameraId")
        //     console.log(cameraId)
        // }
        // const title = this.props.title !== undefined ? this.props.title : "Requisitions"
        // const commandText = this.props.commandText !== undefined ? this.props.commandText : "SCAN BARCODE"
        // const inputGuideText = this.props.inputGuideText !== undefined ? this.props.inputGuideText : "Add requisition #"

        // var requisitionIds = "";
        // if (this.props.draw_request_documents) {
        //     requisitionIds = this.props.draw_request_documents.map(doc => {
        //         //console.log(doc)
        //         if (doc.doc_type !== undefined && doc.doc_type === "metadata"
        //             && doc.client_deleted !== undefined && doc.client_deleted !== true) {
        //             return (
        //                 <ListItem
        //                     key={Math.random()}
        //                     disableGutters={true}
        //                     className={classes.requisitionList}
        //                     aria-label={title}>
        //                     {doc.metadata.requisition_code}
        //                     {!this.state.readOnly &&
        //                         <ListItemSecondaryAction>
        //                             <Icon
        //                                 //onClick={() => this.removeDrawRequestDocument(doc)}
        //                                 onClick={() => {
        //                                     this.deleteBarcode(doc)
        //                                 }}
        //                                 disabled={this.state.readOnly}
        //                                 classes={{ root: classes.clearButton }}
        //                             >clear</Icon>
        //                         </ListItemSecondaryAction>
        //                     }
        //                 </ListItem>
        //             );
        //         }
        //         return null
        //     });

        //     requisitionIds = requisitionIds.filter(function (el) {
        //         return el != null;
        //     });
        // }
        const textFieldDisabled = barcodeCaptureOperation !== NEW && (barcodeCaptureOperation === PREVIEW || readOnly)

        return (
            <div>
                <Dialog
                    id="notes-dialog"
                    fullScreen={true}
                    classes={{
                        paper: classes.dialogPaperForBarcode
                    }}
                    open={this.state.dialogOpenedBarcodeScanner}
                    // onClose={this.handleCloseDialogPatientRefused}
                    onEnter={this.handleOnShowDialogPatientRefused}
                    aria-labelledby="notes-title">

                    {/* <DialogTitle id="notes-title" onClose={() => this.scanBarcodeDialogVisibility(false)}>Scan Barcode</DialogTitle> */}
                    <DialogContent className={classes.barcodeDialogContent}>
                        <IconButton
                            className={classes.closeCameraButton}
                            aria-label="close-dialog"
                            onClick={() => this.scanBarcodeDialogVisibility(false)}
                            color="secondary"
                        >
                            <CloseIcon
                                className={classes.closeIcon}
                            />
                        </IconButton>
                        {this.state.errorOnCamera ?
                            <CameraPermissionError />
                            :
                            <div
                                style={{
                                    position: "relative",
                                    backgroundColor: "black"
                                }}
                            >
                                <IconButton
                                    className={classes.switchCameraButton}
                                    aria-label="camera"
                                    onClick={() => {
                                        this.changeCameraTo(this.findNextCamera(cameraId))
                                    }}
                                    color="secondary"
                                >
                                    <FlipCameraIosIcon
                                        className={classes.cameraIcon}
                                    />
                                </IconButton>

                                <video
                                    id="video"
                                    // width="100%"
                                    // height="300"                            
                                    //height={window.innerHeight - (36 + 16)}
                                    // ref={this.webcamRef}
                                    // screenshotFormat="image/jpeg"
                                    height={window.innerHeight - 65}
                                    width={window.innerWidth}
                                    style={{
                                        display: "block"
                                    }}
                                ></video>

                                {/* <div className={classes.codeValueContainer}>
                                <Typography className={classes.codeText}>Code value:</Typography>
                                <Typography className={classes.codeText} variant="h5">{this.state.barcodeValue}</Typography>
                            </div> */}

                                <div
                                    style={{
                                        position: "absolute",
                                        bottom: 0,
                                        width: window.innerWidth - 20,
                                        textAlign: "left",
                                        padding: 10
                                    }}>
                                    {/* {devices.map((device, key) => (
                                    <IconButton style={{ padding: 0 }} aria-label="camera" key={key + 1} onClick={() => this.changeCameraTo(device.deviceId)}>
                                        {(cameraId === device.deviceId) ?
                                            <StyledBadgeSelected badgeContent={key + 1} color="secondary">
                                                <CameraAltIcon style={{
                                                    fontSize: "45px",
                                                    color: "#0277bd"
                                                }} />
                                            </StyledBadgeSelected>
                                            :
                                            <StyledBadgeNotSelected badgeContent={key + 1} color="secondary">
                                                <CameraAltIcon style={{
                                                    fontSize: "45px",
                                                    color: "#0277bd"
                                                }} />
                                            </StyledBadgeNotSelected>
                                        }
                                    </IconButton>
                                ))} */}
                                    <input
                                        type="checkbox"
                                        id="torch"
                                        //onChange={this.handleToggleTorch} 
                                        width="100%"
                                        //disabled={this.state.zoomSliderDisabled}
                                        hidden={this.state.zoomSliderDisabled}
                                        className={classes.zoomSlider} />

                                    <input type="range" width="100%" hidden={this.state.zoomSliderDisabled} disabled={this.state.zoomSliderDisabled} className={classes.zoomSlider} />
                                </div>
                            </div>
                        }
                    </DialogContent>

                    {/* <DialogActions className={classes.barcodeDialogActions}>
                        {this.state.barcodeValue !== "" &&
                            <>
                                <Button
                                    variant="contained"
                                    className={classNames(classes.buttons, classes.btnTakeAgain)}
                                    onClick={this.resetBarcode}
                                    color="default">Scan again</Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classNames(classes.buttons, classes.btnUsePhoto)}
                                    onClick={this.acceptBarcode}
                                >Use barcode</Button>
                            </>
                        }
                    </DialogActions> */}
                </Dialog>

                <Dialog
                    id="barcode-dialog"
                    fullScreen={false}
                    classes={{
                        paper: classes.dialogPaperForBarcodeCaptured
                    }}
                    open={this.state.dialogOpenedBarcodeCapture}
                    aria-labelledby="barcode-title"
                >
                    <MuiDialogTitle disableTypography className={classes.dialogTitleContainer}>
                        <Typography className={classes.title}>{this.state.barcodeValue}</Typography>
                    </MuiDialogTitle>
                    <DialogContent
                        classes={{
                            root: classes.dialogContentContainer
                        }}
                    >
                        <Typography className={classes.commentLabel}>Add comments</Typography>
                        <TextField
                            id="barcode_field"
                            type="text"
                            variant="outlined"
                            fullWidth={true}
                            disabled={textFieldDisabled}
                            InputProps={{
                                maxLength: this.state.maxChars,
                                classes: {
                                    notchedOutline: classes.barcodeCommentField,
                                    root: classNames(classes.commentField, textFieldDisabled ? classes.commentFieldDisabled : "")
                                },
                            }}
                            rows={5}
                            multiline={true}
                            defaultValue={this.state.barcodeComment}
                        />
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        {(barcodeCaptureOperation === PREVIEW || barcodeCaptureOperation === EDIT) && !readOnly &&
                            <Typography
                                className={classNames(classes.deleteText)}
                                onClick={() => {
                                    this.deleteBarcode(this.state.selectedBarcode)
                                }}
                            >
                                Delete
                            </Typography>
                        }

                        <Button
                            className={classes.btnClose}
                            onClick={() => {
                                this.resetBarcode()
                            }}
                            color="default">Cancel</Button>

                        {barcodeCaptureOperation === NEW &&
                            <Button
                                color="secondary"
                                className={classes.btnSave}
                                onClick={() => {
                                    this.addRequisition()
                                }}
                            >Done</Button>
                        }

                        {!readOnly &&
                            <>
                                {barcodeCaptureOperation === PREVIEW &&
                                    <Button
                                        color="secondary"
                                        className={classes.btnSave}
                                        onClick={() => {
                                            this.setState({ ...this.state, barcodeCaptureOperation: EDIT })
                                        }}
                                    >Edit</Button>
                                }
                                {barcodeCaptureOperation === EDIT &&
                                    <Button
                                        color="secondary"
                                        className={classes.btnSave}
                                        // onClick={this.saveNote} // TODO: must have updating of comment
                                        onClick={() => {
                                            this.addRequisition()
                                        }}
                                    // disabled={barcodeCaptureOperation === EDIT && readOnly}
                                    >Done</Button>
                                }
                            </>
                        }
                    </DialogActions>
                </Dialog>

                <ConfirmDialog
                    type="warning"
                    visible={this.state.setDeleteBarcodeDialog}
                    title={this.state.confirmDialogTitle}
                    message={this.state.confirmDialogMessage}
                    actionText={this.state.confirmDialogActionText}
                    actionHandler={this.state.actionHandler}
                    postActionHandler={this.state.postActionHandler}
                    onClose={() => {
                        setTimeout(() => {
                            this.setDeleteBarcodeDialogVisible(false);
                        }, 100);
                    }}
                />
            </div >
        );
    }
}

BarcodeReaderClass.propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    theme: PropTypes.object.isRequired,
};

const BarcodeReader = withCookies(connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles, { withTheme: true })(BarcodeReaderClass))));

export default BarcodeReader;
