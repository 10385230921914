import React from 'react';
import Typography from '@material-ui/core/Typography';
//import Icon from '@material-ui/core/Icon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment-timezone';
import * as globals from 'components/common/GlobalConstants';
import * as helper from 'components/common/Helper.js';

const FutureOrdersList = (props) => {
  
  var list = [];

  const {classes, futureOrders, onItemClick} = props;
  var clickItem;
  var classItem;
 // var individuals_count = 0;
 // var sites_count = 0;
  var patients_count = 0;
  var urgent_count = 0;
  var fasting_count = 0;

  console.log("futureOrders.future_orders");
  // console.log(helper.sortObjectByKey(futureOrders.future_orders, 'asc'));
  
  var sorted_fo = helper.sortFutureOrdersObjectByKey(futureOrders.future_orders, 'asc');
  var ctr = 0;
  for ( var item in sorted_fo) {
    // console.log("Date: " + item)
    // console.log(futureOrders.future_orders[item].draw_list);
    
   // individuals_count = 0;
   // sites_count = 0;
    patients_count = 0;
    urgent_count = 0;
    fasting_count = 0;

    if(futureOrders.future_orders[item].draw_list === undefined || futureOrders.future_orders[item].draw_list.length === 0) {
      continue;
    }

    ctr++;

    clickItem = null;
    classItem = classes.futureOrdersListPrimaryInActive;

    if(futureOrders.future_orders[item].draw_list.length > 0) {
      clickItem = onItemClick.bind(null, item) 
      classItem = classes.futureOrdersListPrimary;

     for( var drawItemIndex in futureOrders.future_orders[item].draw_list) {
        
        if(futureOrders.future_orders[item].draw_list[drawItemIndex].site_type === globals.INDIVIDUAL){
          //individuals_count++;
        }

        if(futureOrders.future_orders[item].draw_list[drawItemIndex].site_type === globals.HOUSEHOLD ||
          futureOrders.future_orders[item].draw_list[drawItemIndex].site_type === globals.ORGANIZATION){
          //sites_count++;
        }

        for( var patientIndex in futureOrders.future_orders[item].draw_list[drawItemIndex].patient_list) {
          if(futureOrders.future_orders[item].draw_list[drawItemIndex].patient_list[patientIndex].urgent === true){
            urgent_count++;
          }
          if(futureOrders.future_orders[item].draw_list[drawItemIndex].patient_list[patientIndex].fasting === true){
            fasting_count++;
          }
          patients_count++;
        }
      }
    }

    //var sites_count_text = sites_count > 0? sites_count + (sites_count > 1 ? " Sites, " : " Site, "): ""
    //var individuals_count_text = individuals_count > 0 ? individuals_count + (individuals_count > 1 ? " Individuals, " : " Individual, "): ""
    var patients_count_text = patients_count > 0 ? patients_count + (patients_count > 1 ? " Patients, " : " Patient, "): ""
    var urgent_count_text = urgent_count > 0 ? urgent_count + (urgent_count > 1 ? " Urgent, " : " Urgent, "): ""
    var fasting_count_text = fasting_count > 0 ? fasting_count + (fasting_count > 1 ? " Fasting, " : " Fasting, "): ""
    
    //var secondaryText = sites_count_text + individuals_count_text + patients_count_text + urgent_count_text + fasting_count_text
    var secondaryText =  patients_count_text + urgent_count_text + fasting_count_text
    secondaryText = secondaryText.trim().replace(/,\s*$/, "");

    list.push(                    
      <ListItem key={Math.random()}
        className={classes.futureOrdersList}>
              {/* <Icon className={classes.icon}>calendar_today</Icon> */}
        <ListItemText
          classes={{
            primary: classItem,
            secondary: classes.futureOrdersListSecondary
          }}
          onClick={clickItem}
          primary={moment(item,'YYYY-MM-DD').format( "MMMM D, YYYY")}
          secondary={secondaryText}

          // secondary={moment(futureOrders.future_orders[item].draw_list[0].collection_time, "hh:mm:ss").format("hh:mm A")}
        />
      </ListItem>
    );

   }

   if(ctr === 0) {
    return(<Typography className={classes.centered} variant="body2">No Future Orders.</Typography>);
   } else {
    return(list);
   }
}

export default FutureOrdersList;