import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import { connect } from "react-redux";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';

import ApplicationBar from 'components/common/tablet/ApplicationBar';
import SimpleBottomNavigation from 'components/common/tablet/SimpleBottomNavigation';
import * as globals from 'components/common/GlobalConstants';

const mapStateToProps = state => {
  return { drawData: state.drawData};
};

const styles = theme => ({
    root: {
        // display: 'flex',
        paddingTop: "25px !important",
        paddingLeft: "40px !important",
        paddingRight: "40px !important",
        paddingBottom: "40px !important",
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
    },
    list: {
      border: "1px solid rgba(0, 0, 0, 0.16)",
      marginBottom: theme.spacing.unit,
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px`,
      fontSize: "32px !important",
    },
    icon: {
        fontSize: "38px !important",
    },
    label: {
        fontSize: "38px !important",
        color: "#707070",
    },
    buttons: {
        margin: theme.spacing.unit * 2,
        marginLeft: 0,
    },
});

class SettingsClass extends Component {
    static propTypes = {
      cookies: instanceOf(Cookies).isRequired
    };
  
    constructor(props) {
      super(props);
      
      this.state = {
          anchorEl: null,
          aboutDialogOpened: false,
      };
    }

    componentDidMount() {
        console.log('Settings did mount.');
        console.log(this.props.drawData);
    }
      
    handleProfileSettings = () => {
        window.location.href = '/test';
    };
    
    handleOpenDialog = () => {
        this.setState({ aboutDialogOpened: true });
    };
  
    handleCloseDialog = () => {
        this.setState({ aboutDialogOpened: false });
    };
      
    handleLogoutClick(e) {
      this.props.history.push("/logout");
    };
    
    render() {   
        const { classes } = this.props;

        return(
            <div className={classes.root}>
                <main className={classes.content}>
                    <ApplicationBar />
                    <br/>
                    <br/>
                    <List component="nav">
                        <ListItem 
                            onClick={() => {this.handleProfileSettings();}}
                            button className={classNames(classes.list)}>
                            <ListItemIcon>
                                <Icon className={classes.icon}>person</Icon>
                            </ListItemIcon>
                            <ListItemText classes={{primary: classes.label}} primary="Profile Settings" />
                        </ListItem>
                        <ListItem 
                            onClick={() => {this.handleOpenDialog();}}
                            button 
                            className={classNames(classes.list)}>
                            <ListItemIcon>
                                <Icon className={classes.icon}>info</Icon>
                            </ListItemIcon>
                            <ListItemText classes={{primary: classes.label}} primary="About" />
                        </ListItem>
                        <ListItem 
                            onClick={this.handleLogoutClick.bind(this)}
                            button 
                            className={classNames(classes.list)}>
                            <ListItemIcon>
                                <Icon className={classes.icon}>exit_to_app</Icon>
                            </ListItemIcon>
                            <ListItemText classes={{primary: classes.label}} primary="Logout" />
                        </ListItem>
                    </List>

                    <Dialog
                        fullScreen={false}
                        open={this.state.aboutDialogOpened}
                        onClose={this.handleCloseDialog}
                        aria-labelledby="responsive-dialog-title"
                        >
                        <DialogTitle id="responsive-dialog-title">{globals.ABOUT_TITLE}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                            {globals.ABOUT_CONTENT}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseDialog} className={classes.buttons} variant="contained">Close</Button>
                        </DialogActions>
                    </Dialog>

                    <div className={classes.toolbar} />
                    <div className={classes.toolbar} />
                </main>
                <SimpleBottomNavigation />
            </div>
        );
    }
}

SettingsClass.propTypes = {
    classes: PropTypes.object.isRequired,
};

const Settings = connect(mapStateToProps)(
    withCookies(withMobileDialog() (
        withStyles(styles)(SettingsClass))
    )
);

export default Settings;