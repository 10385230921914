import React, { Component } from 'react'
import Webcam from "react-webcam"
//import FormControl from '@material-ui/core/FormControl'
import Fab from '@material-ui/core/Fab'
// import CameraAltIcon from '@material-ui/icons/CameraAlt'
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos'

import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import { withStyles } from '@material-ui/core/styles'
import WhiteFlash from './WhiteFlash'
//import Button from '@material-ui/core/Button'
import * as helper from 'components/common/Helper.js'
import * as globals from 'components/common/GlobalConstants'

import styles from "./styles.js"
// import CameraswitchIcon from '@material-ui/icons/CameraAlt'
// const videoConstraints = {
//   // width: 1280,
//   // height: 720,
//   //facingMode: "user"
//   deviceId : null
//   //facingMode: { exact: "environment" }
// }
import { logEvent } from "js/actions/index"
import { connect } from "react-redux"
// import uuid from "uuid"

const mapDispatchToProps = dispatch => {
    return {
        // updatePatientDrawStatus: drawItem => dispatch(updatePatientDrawStatus(drawItem)),
        // mpSign: signData => dispatch(mpSign(signData)),
        // updatePatientRoomNumber: patient => dispatch(updatePatientRoomNumber(patient)),
        logEvent: event => dispatch(logEvent(event))
    }
}

const StyledBadgeNotSelected = withStyles((theme) => ({
    badge: {
        backgroundColor: '#0277bd',
        color: 'white',
        right: 22,
        top: 22,
        // border: `2px solid ${theme.palette.background.paper}`,
        // padding: '0 4px',
        height: 21,
        width: 21,
        fontWeight: 'bold'
    },
}))(Badge)

const StyledBadgeSelected = withStyles((theme) => ({
    badge: {
        backgroundColor: 'white',
        color: '#0277bd',
        right: 22,
        top: 22,
        // border: `2px solid ${theme.palette.background.paper}`,
        // padding: '0 4px',
        height: 21,
        width: 21,
        fontWeight: 'bold'
    },
}))(Badge)

// const set_camera = React.useCallback(
//   (param) => {
//     webcamRef.current.videoConstraints ={deviceId: param}
//   },
//     [webcamRef]
// )


// const stop_camera = React.useCallback(
//   (param) => {
//     webcamRef.current.stopAndCleanup()
//     webcamRef.current.requestUserMedia()
//   },
//     [webcamRef]
// )

// const camera_change = React.useCallback(
//   () => {
//     const cam_id = camera_item.current.props.cameraId
//     setCameraId(cam_id)
//     console.log("camera_change " + cam_id )
//     console.log(camera_item.current)
//   },
//     [camera_item]
// )



class CustomWebcam extends Component {
    // const classes = useStyles()

    constructor(props) {
        super(props)

        this.state = {
            ...this.state,
            cameraId: "",
            devices: [],
            isShowWhiteFlash: false,
            readyForCapture: false
        }

        this.webcamRef = React.createRef()
        this.alternateCameraDevices = {}
    }

    handleDevices = (mediaDevices) => {
        const devices = mediaDevices.filter(({ kind }) => kind === "videoinput")
        this.setState({ ...this.state, devices: devices })
        //console.log(devices)
    }

    selectCamera = () => {
        return
        // const {devices, cameraId} = this.state
        // if(cameraId === '') {

        //   if(devices.length  === 1 ) {

        //     this.setState({...this.state, cameraId: devices[0].deviceId })
        //     console.log("Settings cameraId to " + devices[0].deviceId)
        //     this.restartCamera()

        //   } else {

        //     devices.map((device, key) => {
        //       console.log("HERE  " )
        //       console.log( device.label)
        //       if(device.label.toLowerCase().indexOf("back") !==-1 || device.label.toLowerCase().indexOf("usb") !==-1) {
        //         //setCameraId(device.deviceId)
        //         this.setState({...this.state, cameraId: device.deviceId })
        //         console.log("Settings cameraId to " + device.deviceId)
        //         this.restartCamera()
        //         return // to break the loop
        //       }
        //       })
        //     } //      if(devices.length  == 1 ) 
        //   } // if(cameraId === '')

    }

    listCamera = () => {

        //  if (helper.getMobileOperatingSystem() === "iOS") { // temp ios camera fix
        if (false) {
            var constraints = {
                audio: false,
                video: true,
            }

            navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
                navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
                    const devices = mediaDevices.filter(({ kind }) => kind === "videoinput")
                    if (globals.DEBUG_ALLOWED) {
                        console.log("Devices in listdevices, CustomWebcam2 - iOS")
                        console.log(devices)
                    }
                    this.setState({ ...this.state, devices: devices })
                })
            }).catch((err) => {
                if (globals.DEBUG_ALLOWED) console.log("ERROR in CustomWebcam2 camera iOS")
                if (!this.state.cameraHasError) {
                    this.props.onCameraError(err)
                    if (globals.DEBUG_ALLOWED) console.error("Device access checks failed(CustomWebCam2): ", err)
                }
            })

        } else {

            navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
                const devices = mediaDevices.filter(({ kind }) => kind === "videoinput")
                //if (globals.DEBUG_ALLOWED) {
                //console.log("Devices in listdevices, CustomWebcam2 - Android or Unknown")
                //console.log(devices)
                // }

                if (globals.DEBUG_ALLOWED) {
                    console.log("Devices in listdevices, CustomWebcam2")
                    console.log(devices)
                }


                if (helper.getMobileOperatingSystem() === "iOS") {
                    var facingValues = ["environment", "user", "environment", "user", "environment", "user"]
                    var devIndex = 0

                    devices.forEach((device) => {
                        //device.deviceId = facingValues[devIndex]
                        this.alternateCameraDevices[device.deviceId] = facingValues[devIndex]
                        devIndex++
                    })
                }

                this.setState({ ...this.state, devices: devices })
            }).catch((err) => {

                if (globals.DEBUG_ALLOWED) {
                    console.log("ERROR in CustomWebcam2 camera android/other OS")
                }

                if (!this.state.cameraHasError) {
                    this.props.onCameraError(err)

                    if (globals.DEBUG_ALLOWED) {
                        console.error("Device access checks failed(CustomWebCam2): ", err)
                    }
                }
            })
        }


    }

    componentDidMount = () => {
        this.listCamera()
        const cam_id = helper.getLocalStorageValue("camid")
        if (cam_id !== "") {
            this.changeCameraTo(cam_id)
        }

    }

    componentDidUpdate = (prevProps, prevState) => {
        if (globals.DEBUG_ALLOWED) {
            console.log("CustomWebcam: Camera count ", this.state.devices.length)
        }

        if (helper.getMobileOperatingSystem() === "iOS" || helper.getMobileOperatingSystem() === "Android") {
            if (this.state.devices.length < 2) {
                this.listCamera()
            }
        } else {
            if (this.state.devices.length === 0) {
                this.listCamera()
            }
        }
    }

    componentWillUnmount = () => {
        this.stopCamera()
    }

    changeCameraTo = (cam_id) => {
        this.setState({ ...this.state, cameraId: cam_id, isShowWhiteFlash: false, readyForCapture: false }, () => {
            setTimeout(() => {
                this.setState({ ...this.state, readyForCapture: true })
            }, 1000)
        })
        // helper.setCookieValue("camid",cam_id)
        helper.setLocalStorageValue("camid", cam_id)

        // Real: revert and remove
        // const selectedCamera = this.state.devices.find(element => element.deviceId === cam_id)
        // this.props.onChangeCamera(selectedCamera)

        if (globals.DEBUG_ALLOWED) console.log("Changing camera to camera " + cam_id)
    }

    capture = () => {
        const imageSrc = this.webcamRef.current.getScreenshot()
        if(imageSrc) {
            this.setState({ ...this.state, isShowWhiteFlash: true }, () => {
                setTimeout(() => { // needed to have enough delay in showing white flash
                    if (this.props.onTakePhoto) {
                        this.props.onTakePhoto(imageSrc)
                        // setTimeout(()=>{
                        //   this.stopCamera()
                        // },1000)
                    }
                }, 300)
            })
        } else {
            console.log("missing imageSrc, must retake")
            console.log(imageSrc)
        }
    }

    restartCamera = () => {
        try {
            this.webcamRef.current.stopAndCleanup()
            this.webcamRef.current.requestUserMedia()
        } catch (e) { }
    }

    stopCamera = () => {
        try {
            this.webcamRef.current.stopAndCleanup()
            if (globals.DEBUG_ALLOWED) console.log("Camera stopped.")
            //this.webcamRef.current.requestUserMedia()
        } catch (e) {
            if (globals.DEBUG_ALLOWED) console.log("Problem stopping camera.")
        }
    }

    findNextCamera = (cameraId) => {

        var devices = this.state.devices

        for (var i = 0; i < devices.length; i++) {
            if (cameraId === devices[i].deviceId) {
                console.log("WOW there is a match!")
                if (i === devices.length - 1) {
                    return devices[0].deviceId
                } else {
                    return devices[i + 1].deviceId
                }
            }

        }
    }

    render() {
        const { classes } = this.props
        var { devices, cameraId } = this.state
        if (devices.length === 0) return (<div style={{ width: "100%", height: "100%", backgroundColor: "black" }}></div>)
        if (globals.DEBUG_ALLOWED) {
            console.log("devices")
            console.log(devices)
            console.log("cameraId")
            console.log(cameraId)
        }

        const camIdResult = devices.find(element => element.deviceId === cameraId)
        if (globals.DEBUG_ALLOWED) {
            console.log("camIdResult")
            console.log(camIdResult)
            console.log("getMobileOperatingSystem()")
            console.log(helper.getMobileOperatingSystem())
        }

        if (camIdResult === undefined) {
            const backCamera = helper.findBackCamera(devices)
            if (backCamera) {
                if (globals.DEBUG_ALLOWED) console.log("Back camera found")
                cameraId = backCamera
            } else {
                if (globals.DEBUG_ALLOWED) console.log("Default camera")
                cameraId = devices[0].deviceId
            }

        }

        if (globals.DEBUG_ALLOWED) {
            console.log("cameraId final")
            console.log(cameraId)
        }
        // alert("device length: " + devices.length)
        // cameraId = "7FF8BD0F0CD5DE4F06C57F641F6268D83967A291"
        return (
            <div style={{
                position: "relative",
                backgroundColor: "black"
            }}>
                <IconButton
                    className={classes.switchCameraButton}
                    aria-label="camera"
                    onClick={() => {
                        // console.log(this.findNextCamera(cameraId))
                        this.changeCameraTo(this.findNextCamera(cameraId))
                    }}
                    color="secondary"
                >
                    <FlipCameraIosIcon
                        className={classes.cameraIcon}
                    />
                </IconButton>

                <WhiteFlash
                    isShowWhiteFlash={this.state.isShowWhiteFlash}
                    height={window.innerHeight - 65}
                    width={window.innerWidth}
                />

                {/* {helper.getMobileOperatingSystem() === "iOS" ?
                    <Webcam
                        // key={uuid.v4()} // needed to refresh previous displayed stream // Real: revert and remove
                        audio={false}
                        height={window.innerHeight - 65} // dialog action height
                        ref={this.webcamRef}
                        screenshotFormat="image/jpeg"
                        width={window.innerWidth}
                        //videoConstraints={{deviceId: cameraId,facingMode: { exact: "environment" }}}
                        videoConstraints={{ facingMode: { exact: this.alternateCameraDevices[cameraId] } }}
                        //videoConstraints={{ deviceId: cameraId }}
                        style={{
                            display: "block"
                        }}
                        onUserMediaError={(err) => {
                            this.props.onCameraError(err)
                            console.log(err)
                        }}
                    />
                    :
                    <Webcam
                        // key={uuid.v4()} // Real: revert and remove
                        audio={false}
                        height={window.innerHeight - 65}
                        ref={this.webcamRef}
                        screenshotFormat="image/jpeg"
                        width={window.innerWidth}
                        //videoConstraints={{deviceId: cameraId,facingMode: { exact: "environment" }}}
                        //videoConstraints={{facingMode: { exact: "environment" }}}
                        videoConstraints={{ deviceId: cameraId }}
                        style={{
                            display: "block"
                        }}
                        onUserMediaError={(err) => {
                            this.props.onCameraError(err)

                            console.log(err)
                        }}
                    />
                } */}


                <Webcam
                    // key={uuid.v4()} // Real: revert and remove
                    audio={false}
                    height={window.innerHeight - 65}
                    ref={this.webcamRef}
                    screenshotFormat="image/jpeg"
                    width={window.innerWidth}
                    //videoConstraints={{deviceId: cameraId,facingMode: { exact: "environment" }}}
                    //videoConstraints={{facingMode: { exact: "environment" }}}
                    videoConstraints={{ deviceId: cameraId }}
                    style={{
                        display: "block"
                    }}
                    onUserMediaError={(err) => {
                        this.props.onCameraError(err)

                        console.log(err)
                    }}
                />

                <div style={{
                    position: "absolute",
                    bottom: 95,
                    width: window.innerWidth,
                    textAlign: "center"
                }}>
                    <div className={classes.fabBorderContainer}>
                        <div className={this.state.readyForCapture ? classes.fabBorder : classes.fabBorderDisabled}></div>
                    </div>
                    <Fab
                        onClick={() => {
                            if (this.state.readyForCapture) {
                                this.capture()
                            }
                        }}
                        style={{
                            marginTop: "5px",
                            width: "50px",
                            height: "50px",
                            backgroundColor: this.state.readyForCapture ? "white" : "rgba(255,255,255,0.5)",
                        }}
                    ><></></Fab>
                    {/* Real: revert and remove */}
                    {/* 
                    <Fab
                        disabled={this.props.cameraHasError}
                        onClick={this.capture}
                        style={{
                            backgroundColor: this.props.cameraHasError ? "rgba(255, 255, 255, 0.1)" : "rgba(255, 255, 255, 0.3)",
                            border: this.props.cameraHasError ? "3px solid gray" : "3px solid white"
                        }}
                    ><></></Fab> 
                    */}
                </div>

                {/* <div
                    style={{
                        position: "absolute",
                        bottom: 0,
                        width: window.innerWidth - 20,
                        textAlign: "left",
                        padding: 10
                    }}>
                    {devices.map((device, key) => (
                        <IconButton style={{ padding: 0 }} aria-label="camera" key={key + 1}
                            onClick={() => this.changeCameraTo(device.deviceId)}>
                            {(cameraId === device.deviceId) ?
                                <StyledBadgeSelected badgeContent={key + 1} color="secondary">
                                    <CameraAltIcon style={{
                                        fontSize: "45px",
                                        color: "#0277bd"
                                    }} />
                                </StyledBadgeSelected>
                                :
                                <StyledBadgeNotSelected badgeContent={key + 1} color="secondary">
                                    <CameraAltIcon style={{
                                        fontSize: "45px",
                                        color: "#0277bd"
                                    }} />
                                </StyledBadgeNotSelected>
                            }
                        </IconButton>
                    ))}
                </div> */}
            </div>
        )
    } // WebcamCapture
}
export default connect(null, mapDispatchToProps)((withStyles(styles, { withTheme: true })(CustomWebcam)))