import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import { connect } from "react-redux";
import $ from 'jquery';
import Typography from '@material-ui/core/Typography';
import {   isMobileOnly  } from "react-device-detect";
// import {
//     Chart,
//     ChartLegend,
//     ChartSeries,
//     ChartSeriesItem,
//     ChartSeriesLabels
//   } from '@progress/kendo-react-charts';

const mapStateToProps = state => {
  return { drawData: state.drawData};
};

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
    },
    margins: {
      margin: theme.spacing.unit * 2.5,
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
    },
});

class TestPageClass extends Component {
    
    constructor(props) {
        super(props);
    
        this.state = {...this.state,responseText:"response"};
    }
    componentDidMount() {
        console.log('Secret did mount.');

    }

    onClick = () => {
        console.log("Accessing GPS api");
        var dev_request = $.ajax({
            url: "http://localhost:8000/gps",
            method: "GET"
      
        });

        dev_request.done(this.requestDoneHandler.bind(this));

        dev_request.fail(function( jqXHR, textStatus ) {
            console.log(textStatus);
            const responseText = this.state.responseText + ", " + textStatus;
            console.log("responseText " + this.state.responseText);
            this.setState({...this.state,responseText: responseText});
        }.bind(this)); //.bind(this));

    };

    requestDoneHandler(response) {
        console.log(response);
        const responseText = this.state.responseText + " " + response;
        console.log("responseText " + this.state.responseText);
        this.setState({...this.state,responseText: responseText});
    }

    onClickHome(){
        window.location.href = '/';
    }

    testHandler(){
        console.log("TestHandler was called.");
    }

    render() {   
        // const { classes } = this.props;
        
        if (isMobileOnly) { 
            return(
                <div>
                <h1>Mobile</h1>
                <Button onClick={this.onClick}>Test Button</Button>
                <Button onClick={this.onClickHome}>Home</Button>
                <Typography>{this.state.responseText}</Typography>
                </div>
            );
        } else {
            return(
                <div>
                <h1>Desktop</h1>
                <Button onClick={this.onClick}>Test Button</Button>
                <Button onClick={this.onClickHome}>Home</Button>
                <Typography>{this.state.responseText}</Typography>
                </div>
            );
        }
    }
}

TestPageClass.propTypes = {
    classes: PropTypes.object.isRequired,
};




const TestPage = connect(mapStateToProps)(withStyles(styles)(TestPageClass));
export default TestPage;