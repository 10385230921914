import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';

const styles = theme => ({
  snackBarMessage: {
    fontSize: 22,
  },
  snackbar: {
    height: 80,
  },
  warning: {
    backgroundColor: amber[700],
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  danger: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  }
});

class SnackBarPopup extends React.Component {  
  render() {
    const { classes, message, open, onClose, variant } = this.props;

    return (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          disableWindowBlurListener={true}
          autoHideDuration={3000}
          open={open}
          onClose={onClose}
        >
          <SnackbarContent
            className={classNames(classes[variant], classes.snackbar)}
            aria-describedby='snackbar'
            message={<span id="snackbar" className={classes.snackBarMessage}>{message}</span>} />
        </Snackbar>
      );
    }
}

SnackBarPopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  message: PropTypes.node,
  variant: PropTypes.oneOf(['default', 'success', 'warning', 'error', 'danger', 'info']).isRequired,
};

export default withStyles(styles)(SnackBarPopup);