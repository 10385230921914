import React, { useLayoutEffect } from 'react'
import classNames from 'classnames'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
// import TextField from '@material-ui/core/TextField'
import $ from 'jquery'
import * as globals from 'components/common/GlobalConstants'
// import Dialog from '@material-ui/core/Dialog';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogActions from '@material-ui/core/DialogActions'

// import testImage from './test-images/step6-photo-capture.PNG'
import uuid from "uuid"
import moment from 'moment-timezone'

// import CustomWebcam2 from 'components/common/mobile/CustomWebcam2'
// import CapturedImageView from 'components/common/mobile/CapturedImageView'
// import CameraPermissionError from 'components/common/mobile/CameraPermissionErrorComponent'
import {
    Dialog,
    DialogContent
} from '@material-ui/core'

export const NoDataCaptureContainer = (props) => {
    const { classes } = props

    useLayoutEffect(() => {
        $("#no-data-capture-label-container").height($("#no-data-capture-label-container").width() * 1)
    }, [])

    return (
        <Grid
            id="no-data-capture-label-container"
            container
            justify="center"
            alignItems="center"
            className={classes.noDataCaptureContainer}
        >
            <div style={{ textAlign: "center" }}>
                <Typography className={classes.noDataCapturedLabel}>
                    No data captured yet
                </Typography>
            </div>
        </Grid>
    )
}

export const AddDataOptions = (props) => {
    const {
        classes,
        optionsActive,
        setOptionsActive,
        // onCaptureData,
        onOpenCaptureNoteDialog,
        onOpenCapturePhotoDialog,
        onOpenCaptureBarcodeDialog
    } = props

    return (
        <div>
            <div className={classes.btnAddDataContainer}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Button
                        className={classNames(classes.btnCaptureData, classes.btnAddData)}
                        onClick={() => setOptionsActive(true)}
                    >Add Data</Button>
                </Grid>
            </div>

            <Dialog
                id="options-dialog"
                fullScreen={false}
                open={optionsActive}
                maxWidth={"md"}
                classes={{
                    paperScrollPaper: classes.addDataOptionsDialogContainer
                }}
            >
                <DialogContent className={classes.addDataOptionsDialogContent}>
                    <>
                        <Button
                            className={classes.btnCaptureData}
                            style={{ display: "none" }}
                        >workaround for on show, first button touch riffle is clicked</Button>
                        <Button
                            classes={{
                                root: classes.btnCaptureData,
                                label: classes.btnCaptureDataLabel
                            }}
                            onClick={() => {
                                setOptionsActive(false)
                                onOpenCapturePhotoDialog()
                            }}
                        >Add a photo</Button>
                        <Button
                            classes={{
                                root: classes.btnCaptureData,
                                label: classes.btnCaptureDataLabel
                            }}
                            onClick={() => {
                                setOptionsActive(false)
                                onOpenCaptureNoteDialog()
                            }}
                        >Add a note</Button>

                        <Button
                            classes={{
                                root: classes.btnCaptureData,
                                label: classes.btnCaptureDataLabel
                            }}
                            onClick={() => {
                                setOptionsActive(false)
                                onOpenCaptureBarcodeDialog()
                            }}
                        >Scan a barcode</Button>
                        <Button
                            className={classes.btnCloseAddData}
                            onClick={() => setOptionsActive(false)}
                        >Close</Button>
                    </>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export const CapturedNoteDisplay = (props) => {
    const { classes, data, onOpenNote } = props
    const item = data.item
    // const date = moment.unix(item.client_timestamp).tz(item.client_timezone).format("MMM D, YYYY")
    // const time = moment.unix(item.client_timestamp).tz(item.client_timezone).format("hh:mma")

    var date = ""
    var time = ""

    try {
        date = moment.unix(item.client_timestamp).tz(item.client_timezone).format("MMM D, YYYY")
        time = moment.unix(item.client_timestamp).tz(item.client_timezone).format("hh:mma")
    } catch (e) {
        console.log("Error here")
        console.log(e)
    }

    return (
        <div className={classes.capturedDataContainer} onClick={() => onOpenNote(item.id)}>
            {date &&
                <Typography className={classes.capturedDataDateTimeLabel}>{date} | {time}</Typography>
            }
            <Typography className={classes.capturedDataMainNote}>{item.message}</Typography>
        </div>
    )
}

export const CapturedPhotoDisplay = (props) => {
    const { classes, data, onOpenPhoto } = props
    const item = data.item

    var date = ""
    var time = ""

    try {
        date = moment.unix(item.client_timestamp).tz(item.client_timezone).format("MMM D, YYYY")
        time = moment.unix(item.client_timestamp).tz(item.client_timezone).format("hh:mma")
    } catch (e) {
        console.log("Error here")
        console.log(e)
    }

    const filename = item.filename
    var image = item.metadata.image.data

    if (item.doc_type === "offline_uploaded" && item.path !== undefined) {
        image = (globals.USE_PAMP_COUCHDB_SERVER) ? item.path : globals.SERVER + item.path
    }
    return (
        <div className={classes.capturedDataContainer} onClick={() => onOpenPhoto(item)}>
            <Grid
                container
                direction="row"
                justifycontent="flex-start"
                wrap="nowrap"
            >
                <div className={classes.capturedImageDiv}>
                    <img src={image} height="90px" width="90px" alt="..." className={classes.capturedImage} />
                </div>
                <div>
                    {date &&
                        <Typography className={classes.capturedDataDateTimeLabel}>{date} | {time}</Typography>
                    }
                    <Typography className={classes.capturedDataMainNote}>{filename}</Typography>
                </div>
            </Grid>
        </div>
    )
}

export const CapturedBarcodeDisplay = (props) => {
    const { classes, data, onOpenBarcode } = props
    const item = data.item

    // const date = moment.unix(item.client_timestamp).tz(item.client_timezone).format("MMM D, YYYY")
    // const time = moment.unix(item.client_timestamp).tz(item.client_timezone).format("hh:mma")

    var date = ""
    var time = ""

    try {
        date = moment.unix(item.client_timestamp).tz(item.client_timezone).format("MMM D, YYYY")
        time = moment.unix(item.client_timestamp).tz(item.client_timezone).format("hh:mma")
    } catch (e) {
        console.log("Error here")
        console.log(e)
    }

    const barcode = item.metadata.requisition_code
    const comment = item.metadata.comment

    return (
        <div className={classes.capturedDataContainer} onClick={() => onOpenBarcode(item)}>
            {date &&
                <Typography className={classes.capturedDataDateTimeLabel}>{date} | {time}</Typography>
            }
            <Typography className={classes.capturedDataMainNote} style={{ marginBottom: "10px" }}>{barcode}</Typography>
            <Typography className={classes.capturedDataSubNote} style={{ marginBottom: "10px" }}>{comment}</Typography>
        </div>
    )
}

export const DocumentsDataDisplay = (props) => {
    const {
        classes,
        drawRequestDocumentsData,
        drawRequestNotesData,
        match,
        onOpenNote,
        onOpenPhoto,
        onOpenBarcode
    } = props

    const appointment_id = match.params.appointment_id  // site_id
    const order_id = match.params.order_id              // patient_id
    var theSiteDrawRequestDocumentsData = []
    var theSiteDrawRequestNotesData = []

    if (drawRequestDocumentsData.draw_request_documents) {
        theSiteDrawRequestDocumentsData = drawRequestDocumentsData.draw_request_documents.filter(function (draw_req_doc) {
            return (draw_req_doc.drawlist_site_id === appointment_id && draw_req_doc.drawlist_patient_id === order_id &&
                draw_req_doc.doc_type !== "") // temp filter documents uploaded in portal
        })
    }

    if (drawRequestNotesData.draw_request_notes) {
        theSiteDrawRequestNotesData = drawRequestNotesData.draw_request_notes.filter(function (draw_req_note) {
            return (draw_req_note.drawlist_site_id === appointment_id && draw_req_note.drawlist_patient_id === order_id)
        })
    }

    var consolidatedNotes = []

    if (theSiteDrawRequestDocumentsData) {
        theSiteDrawRequestDocumentsData.forEach((doc) => {
            if (doc.doc_type !== undefined && doc.metadata.image !== undefined
                && doc.client_deleted !== undefined && doc.client_deleted !== true
                && doc.doc_type === "offline_upload") {
                consolidatedNotes.push({
                    type: "photo_upload",
                    item: doc
                })
            } else if (doc.doc_type !== undefined && doc.filename !== undefined
                && doc.client_deleted !== undefined && doc.client_deleted !== true &&
                doc.doc_type === "offline_uploaded" && doc.path !== undefined
            ) {
                consolidatedNotes.push({
                    type: "photo_uploaded",
                    item: doc
                })
            } else if (doc.doc_type !== undefined && doc.doc_type === "metadata"
                && doc.client_deleted !== undefined && doc.client_deleted !== true) {
                consolidatedNotes.push({
                    type: "barcode",
                    item: doc
                })
            } else if (doc.path !== undefined && doc.path !== "") {
                var ext = doc.path.split('.').pop();
                var type = ["pdf", "jpg"].includes(ext) ? ext : "jpg";

                consolidatedNotes.push({
                    type: type === "jpg" ? "photo_uploaded" : "pdf",
                    item: doc
                })
            }
        })
    }

    if (theSiteDrawRequestNotesData) {
        theSiteDrawRequestNotesData.forEach(note => {
            if (!(note.client_deleted !== undefined && note.client_deleted === true)) {
                consolidatedNotes.push({
                    type: "note",
                    item: note
                })
            }
        })
    }

    const otherDocCategories = new Set([
        "IV Kit",
        "Venipuncture Site",
        "Completed Medication Container"
    ])
    // remove docs with categories above
    consolidatedNotes = consolidatedNotes.filter((doc) => {
        if (doc.item.metadata &&
            doc.item.metadata.image &&
            doc.item.metadata.image.category) {
            // add item if category not in list
            return !otherDocCategories.has(doc.item.metadata.image.category)
        } else {
            return true
        }
    })

    consolidatedNotes = consolidatedNotes.sort((a, b) => a.item.client_timestamp - b.item.client_timestamp)

    // console.log("theSiteDrawRequestDocumentsData")
    // console.log(theSiteDrawRequestDocumentsData)
    // console.log("theSiteDrawRequestNotesData")
    // console.log(theSiteDrawRequestNotesData)
    // console.log("consolidatedNotes")
    // console.log(consolidatedNotes)

    return (
        <div className={classes.dataCaptureContainer}>
            {consolidatedNotes.length === 0
                ?
                <NoDataCaptureContainer classes={classes} />
                :
                <>
                    {consolidatedNotes.map((data, index) => {
                        // console.log("consolidatedNotes data")
                        // console.log(data)
                        if (data.type === "note") {
                            return <CapturedNoteDisplay
                                key={uuid.v4()}
                                classes={classes}
                                data={data}
                                onOpenNote={(note_id) => onOpenNote(note_id)}
                            />
                        } else if (data.type === "photo_upload" || data.type === "photo_uploaded") {
                            return <CapturedPhotoDisplay
                                key={uuid.v4()}
                                classes={classes}
                                data={data}
                                onOpenPhoto={(photo_id) => onOpenPhoto(photo_id)}
                            />
                        } else if (data.type === "barcode") {
                            return <CapturedBarcodeDisplay
                                key={uuid.v4()}
                                classes={classes}
                                data={data}
                                onOpenBarcode={(barcode_id) => onOpenBarcode(barcode_id)}
                            />
                        }
                        return ""
                    })}
                </>
            }
        </div>
    )
}