//import * as globals from 'components/common/GlobalConstants';

const styles = theme => ({

    root: {
        flexGrow: 1,
    },
    keyContainer: {
        padding: "10px",
        margin: "20px"
    },
    paper: {
      
        padding: "10px",
      //  textAlign: 'center',
        margin: "25px",
        color: theme.palette.text.secondary,
        border: "0"
    },

    keyNumeric: {
        alignItems: 'center',
        borderRadius: "5px",
        width: "80px",
        height: "40px",
        fontWeight: "normal",
        fontSize: "23px !important",
       // display: 'flex',
        alignItems: 'center',
        textAlign: "center !important",
        //margin: "auto",
        paddingTop: "8px",
        marginTop: "12px",
        boxShadow: '0 0px 5px 0 rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.05)',
        boxSizing: "borderBox",
        backgroundColor: "white",
        color: "#333333 !important",
        "&:hover": {
            backgroundColor: "white",
        }        

        // riffle
    },

    ripple: {
        // backgroundPosition: "center",
        // transition: "background 0.8s",
        // "&:hover": {
        //     background: "#47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%"
        // },
        // "&:active": {
        //     backgroundColor: "#6eb9f7",
        //     backgroundSize: "100%",
        //     transition: "background 0s",
        // }
    },
    
    keyText: {
        alignItems: 'center',
        borderRadius: "5px",
        width: "80px",
        height: "40px",
        fontWeight: "normal",
        fontSize: "23px !important",
       // display: 'flex',
        alignItems: 'center',
        textAlign: "center !important",
        //margin: "auto",
        paddingTop: "8px",
        marginTop: "12px",
        boxShadow: '0 0px 5px 0 rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.05)',
        boxSizing: "borderBox",
        backgroundColor: "white",
        color: "#333333 !important",
        "&:hover": {
            backgroundColor: "white",
        }        


    },
    keyPadMessage:{
        fontSize: "18px !important",
        textAlign: "center",
        width: "80% !important",
        margin: "auto",
        color: "#ff6961 !important",
        height: "15px",
        paddingBottom: "10px"
    },  
    resetLink:{
        fontSize: "18px !important",
        textAlign: "center",
        width: "80% !important",
        margin: "auto",
        color: "#ff6961 !important",
        height: "15px",
        textDecoration: "underline !important"
    },
    keyPadTitle: {
        fontSize: "22px !important",
        textAlign: "center",
        width: "80%",
        margin: "auto",
        color: "lightgray",
        marginBottom: "15px",
    },
    keyPadInstruction: {
        fontSize: "16px !important",
        textAlign: "center",
        width: "70%",
        margin: "auto",
        color: "lightgray"
    },
    divider: {
        backgroundColor: "#f7f7f7",
        position: 'absolute',
        bottom: 0,
        width: "100%",
    },
    mainContainer: {
        paddingTop: "40px",    
    },
    keysContainer:{
            backgroundColor: "#f7f7f7",
            paddingTop: "20px",
            paddingBottom: "60px",
            width: "300px",
            margin: "auto",
    },
    keyDisplayContainer: {
        // width: "40%",
        // margin: "auto",
        width: "300px",
        margin: "auto",
    },

    keyDisplayOn: {
        borderRadius: "50%",
        width: "5px",
        height: "5px",
        fontWeight: "bold",
        fontSize: "20px !important",
       // display: 'flex',
        alignItems: 'center',
        textAlign: "center !important",
        margin: "auto",
        padding: "2px",
        //boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 2px 10px 0 rgba(0, 0, 0, 0.11)',
        boxSizing: "borderBox",
        //backgroundColor: "#68CBEF",
        backgroundColor: "gray",
        border: "1px solid gray",
        color: "white !important"

    },
    keyDisplayOff: {
        borderRadius: "50%",
        width: "5px",
        height: "5px",
        fontWeight: "bold",
        fontSize: "20px !important",
       // display: 'flex',
        alignItems: 'center',
        textAlign: "center !important",
        margin: "auto",
        padding: "2px",
        //boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 2px 10px 0 rgba(0, 0, 0, 0.11)',
        boxSizing: "borderBox",
        //backgroundColor: "#68CBEF",
       // backgroundColor: "lightgray",
        border: "1px solid gray",
        color: "white !important",
        display: "none",

    },
    keyIcon: {
       
     margin: "auto"

    },

    formContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        textAlign: 'center',
        width: '60%',
    },
    colMargin: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    loginButton: {
        fontSize: 18,
        marginTop: 30,
        padding: "13px 28px",
        backgroundColor: "#68CBEF",
        textTransform: 'none',
        borderRadius: 0,
    },
    card: {
        boxShadow: 'none',
    },
    media: {
        objectFit: 'contain',
    },
    teloPointLogo: {
        marginBottom: 30,
    
    },
    logoContainer:{
        width: "70%",
        margin: "auto"
    },
    digitBox:{
        paddingTop: "25px",
        paddingBottom: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
        height: "30px",
        width: "30px",
        border: "1px solid #e6e6e6",
        borderRadius: "5px",
        margin: "3px",
    },
    digitBoxRed:{
        paddingTop: "25px",
        paddingBottom: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
        height: "30px",
        width: "30px",
        border: "1px solid #ff6961",
        borderRadius: "5px",
        margin: "3px",
    },    
    displayContainer:{
        //backgroundColor: "#f7f7f7",
        //paddingTop: "20px",
       // paddingBottom: "10px",
       padding: "0 !important",
       margin: "0 !important",
        width: "1550px",
        //margin: "auto",
    },
});


export default styles;