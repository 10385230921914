import React from 'react' // , { useState }
import { Link } from "react-router-dom"
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import $ from 'jquery'

// import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
// import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
// import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'

import IconButton from '@material-ui/core/IconButton'
import CallIcon from '@material-ui/icons/Call'
import SettingsIcon from '@material-ui/icons/Settings'
import NotificationsIcon from '@material-ui/icons/Notifications'
// import PropTypes from 'prop-types'
// import Icon from '@material-ui/core/Icon'
import { Detector } from "react-detect-offline"
import Badge from '@material-ui/core/Badge'
// import SyncIcon from '@material-ui/icons/Sync'

// import * as globals from 'components/common/GlobalConstants'
import styles from "./styles.js"

import { withCookies } from 'react-cookie'
// import { instanceOf } from 'prop-types'
import { connect } from "react-redux"
import { setOnlineStatus, logEvent, setNewAppVersion } from "js/actions/index"

// import { ReactComponent as SettingsGrayFillSVG } from 'components/common/mobile/CustomOfflineSVG/settings_gray_fill.svg'
// import { ReactComponent as SettingsSelectedSVG } from 'components/common/mobile/CustomOfflineSVG/settings_selected.svg'
// import { ReactComponent as AlertSelectedSVG } from 'components/common/mobile/CustomOfflineSVG/alert_selected.svg'
// import { ReactComponent as AlertGrayFillSVG } from 'components/common/mobile/CustomOfflineSVG/alert_gray_fill.svg'
import { ReactComponent as TeloPointLogoSVG } from 'components/common/mobile/CustomOfflineSVG/TeloPoint.svg'
// import { ReactComponent as CallGrayFillSVG } from 'components/common/mobile/CustomOfflineSVG/call.svg'
import * as helper from 'components/common/Helper.js'
import Grid from '@material-ui/core/Grid'
// import GPSControl from 'components/common/controls/GPSControl'

const mapDispatchToProps = dispatch => {
    return {
        setOnlineStatus: status => dispatch(setOnlineStatus(status)),
        logEvent: status => dispatch(logEvent(status)),
        setNewAppVersion: value => dispatch(setNewAppVersion(value))
    }
}

const mapStateToProps = state => {
    return {
        db: state.db,
        onlineStatus: state.onlineStatus,
        drawRequests: state.drawRequests,
        syncStatus: state.syncStatus,
        newAppVersion: state.newAppVersion
    }
}

const ApplicationBar = (props) => {
    // const [anchorEl, setAnchorEl] = useState(null)
    // const [aboutDialogOpened, setAboutDialogOpened] = useState(false)
    // const [syncIconDisabled, setSyncIconDisabled] = useState(false)
    // const [accessLocationBlocked, setAccessLocationBlocked] = useState(false)

    // checkGPSTimer = null
    // checkGPSInterval = 3000
    // innerRef = React.createRef()

    // checkGPSTimerHandler()
    // checkGPSTimer = setInterval(checkGPSTimerHandler, checkGPSInterval)

    // componentWillUnmount() {
    //     clearTimeout(checkGPSTimer)
    // }

    // const checkGPSTimerHandler = () => {
    //     innerRef.current && innerRef.current.getLocation()
    // }

    const handleRealTimeDate = () => {
        var currentDate = new Date(),
            display_day = currentDate.getDay(),
            day = currentDate.getDate(),
            month = currentDate.getMonth() + 1,
            year = currentDate.getFullYear()
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"]
        var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
        $("#datetime-holder").html(days[display_day] + ", " + months[month - 1] + " " + day + ", " + year)// + " " + hours + ":" + minutes + " " + suffix)
    }

    handleRealTimeDate()

    // const handleOpenDialog = () => {
    //     setAboutDialogOpened(true)
    // }

    // const handleCloseDialog = () => {
    //     setAboutDialogOpened(false)
    // }

    // const handleMenu = (event) => {
    //     setState({ anchorEl: event.currentTarget })
    // }

    // const handleClose = () => {
    //     setState({ anchorEl: null })
    // }

    // const handleClickShowPassword = () => {
    //     setState(state => ({ showPassword: !state.showPassword }))
    // }

    // const forceSync = () => {

    //     if (!globals.ALLOW_USER_TO_FORCE_SYNC) {
    //         return
    //     }

    //     const { syncIconDisabled } = state
    //     if (!syncIconDisabled) {
    //         console.log("Force Sync...")
    //         setState({ ...state, syncIconDisabled: true })
    //         //props.db.sync()x
    //         props.db.createAndConnectPouchDB()

    //         window.setTimeout(function () {
    //             setState({
    //                 syncIconDisabled: false,
    //             })
    //         }.bind(this), globals.FORCE_SYNC_TIMEOUT_BEFORE_ANOTHER_TRIGGER)
    //     } else {
    //     }
    // }

    const { classes, isInfoHidden } = props // newAppVersion
    // const path_name = (window.location.pathname.split('/')[1])
    const info_hidden = isInfoHidden ? isInfoHidden : false
    // const server_sync = cookies.get('sync_status') || globals.SYNCED

    // var sync_image_icon = ''
    // var sync_icon_cont_class = ''
    // if (server_sync === globals.SYNCED) {
    //     sync_icon_cont_class = classes.sync_icon_synched
    // } else if (server_sync === globals.UNSYNCED) {
    //     sync_icon_cont_class = classes.sync_icon_unsynched
    // } else if (server_sync === globals.SYNCED_UP) {
    //     sync_icon_cont_class = classes.sync_icon_up
    //     sync_image_icon = <ArrowUpwardIcon className={classes.sync_badge_content_green} />
    // } else if (server_sync === globals.SYNCED_DOWN) {
    //     sync_icon_cont_class = classes.sync_icon_down
    //     sync_image_icon = <ArrowDownwardIcon className={classes.sync_badge_content_green} />
    // } else if (server_sync === globals.SYNC_ERROR) {
    //     sync_icon_cont_class = classes.sync_icon_error
    //     sync_image_icon = <PriorityHighIcon className={classes.sync_badge_content_red} />
    // }

    // var totalNotificationItems = 2

    // if (newAppVersion) totalNotificationItems++

    // try {
    //     props.drawRequests.draw_request.map(obj => {
    //         if (obj.work_status === '') {
    //             totalNotificationItems++
    //         }
    //         return true
    //     })
    // } catch (e) {
    // }

    return (
        <div>
            <AppBar
                id="application_bar"
                position="fixed"
                color="default"
                classes={{
                    positionFixed: classes.appBarPositionFixed,
                    colorDefault: classes.appBarColorDefault
                }}
            >
                <Toolbar
                    classes={{
                        gutters: classes.toolbarGutters,
                        regular: classes.appBar
                    }}
                >
                    <div className={classes.grow}>
                        <TeloPointLogoSVG
                            className={classes.logo}
                            onClick={() => window.location.href = "/"}
                        ></TeloPointLogoSVG>
                    </div>
                    <Detector render={({ online }) => {
                        if (props.onlineStatus === false) {
                            if (online) {
                                props.setOnlineStatus(true)
                            }
                        } else {
                            if (!online) props.setOnlineStatus(false)
                        }

                        return "" // <Icon className={classNames(classes.wifiIcon, online ? classes.online : classes.offline)}>{online ? "wifi" : "wifi_off"}</Icon>
                    }}
                    />
                    {/* 
                        <GPSControl ref={innerRef} displayGPSIcon={globals.DISPLAY_GPS_ICON_ON_APPLICATION_BAR}
                            onGPSEvent={(data) => {
                                if (globals.DEBUG_ALLOWED) {
                                    // console.log("GPS data : ")
                                    // console.log(data)
                                }

                                // for review if we will use === or ==
                                if (data.isGeolocationEnabled === false) {

                                    if (data.positionError !== undefined) {
                                        if (globals.DEBUG_ALLOWED) {
                                            console.log("GPS disabled")
                                            console.log(data)
                                        }

                                        if (state.accessLocationBlocked !== true)
                                            if (globals.ENFORCE_GPS_AFTER_LOGIN) {
                                                setState({ ...state, accessLocationBlocked: true })
                                            }
                                    }

                                } else if (data.isGeolocationEnabled) {

                                    //console.log("GPS enabled")

                                    if (data.coords) {
                                        window.$location.latitude = data.coords.latitude
                                        window.$location.longitude = data.coords.longitude
                                        window.$location.heading = data.coords.heading
                                    }

                                    if (state.accessLocationBlocked !== false) {
                                        setState({ ...state, accessLocationBlocked: false })
                                    }
                                }

                            }}
                            onPositionError={(error) => {
                            }}
                        />
                        <Detector render={({ online }) => {
                            if (props.onlineStatus === false) {
                                if (online) {
                                    props.setOnlineStatus(true)
                                }
                            } else {
                                if (!online) props.setOnlineStatus(false)
                            }

                            return <Icon className={classNames(classes.wifiIcon, online ? classes.online : classes.offline)}>{online ? "wifi" : "wifi_off"}</Icon>
                        }

                        } />
                        {globals.SYNC_STATUS_VISIBLE &&
                            <IconButton
                                onClick={forceSync}
                                aria-label="Sync"
                                className={classNames(classes.noHoverBG, classes.sync_icon_cont, sync_icon_cont_class)}
                            >
                                <Badge
                                    overlap={"circle"}
                                    classes={{ badge: classes.sync_badge }}
                                    badgeContent={sync_image_icon}
                                >
                                    <SyncIcon className={classes.icon} />
                                </Badge>
                            </IconButton>
                        }
                         */}
                    <div>
                        <a href={"tel:00000000"}>
                            <IconButton aria-label="Call" className={classes.callIconCont}>
                                <CallIcon
                                    classes={{
                                        root: classes.callIcon
                                    }}
                                ></CallIcon>
                            </IconButton>
                        </a>
                        {/* <IconButton
                            aria-label="Call"
                            component={Link}
                            to="/call"
                            className={classNames(classes.notification_icon_cont, classes.noHoverBG, path_name === "notifications" ? classes.notification_icon_cont_selected : classes.notification_icon_cont_not_selected)}
                        >
                            <CallGrayFillSVG className={classNames(classes.alertIcon)}></CallGrayFillSVG>
                        </IconButton> */}
                        <IconButton
                            aria-label="Notification"
                            component={Link}
                            to="/notifications"
                            className={classes.notificationIconCont}
                        // className={classNames(classes.notification_icon_cont, classes.noHoverBG, path_name === "notifications" ? classes.notification_icon_cont_selected : classes.notification_icon_cont_not_selected)}
                        >
                            <Badge
                                classes={{ badge: classes.notificationBadge }}
                                // badgeContent={totalNotificationItems}
                                color="error">
                                <NotificationsIcon
                                    classes={{
                                        root: classes.notificationIcon
                                    }}
                                />

                            </Badge>

                        </IconButton>
                        <IconButton
                            aria-label="Settings"
                            component={Link}
                            to="/workflow_settings"
                            className={classes.settingsIconCont}
                        >
                            <SettingsIcon
                                classes={{
                                    root: classes.settingsIcon
                                }}
                            />
                            {/* {path_name === "settings"
                                ?
                                <SettingsSelectedSVG className={classes.settings_icon}></SettingsSelectedSVG>
                                :
                                <SettingsGrayFillSVG className={classes.settings_icon}></SettingsGrayFillSVG>
                            } */}
                        </IconButton>
                    </div>
                    <div> </div>
                </Toolbar>
            </AppBar>
            <div className={classes.toolbarPlaceHolder} />
            {!info_hidden &&
                <div>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        spacing={8}
                        style={{ marginTop: "25px" }}
                    >
                        <Grid item xs={7}>
                            {(props.channel !== undefined && props.title !== undefined) && <Typography className={classNames(classes.channelName)}>{props.channel.name}</Typography>}

                        </Grid>
                        <Grid item xs={2} style={{ textAlign: "buttom" }}>
                            {props.back !== undefined &&
                                <Link to={props.back}>
                                    <Button
                                        className={classNames(classes.mainButtonWhiteBorderedSM, classes.printButton)}
                                        fullWidth={true}
                                        variant="contained">
                                        Back
                                    </Button>
                                </Link>
                            }
                        </Grid>
                    </Grid>
                    {(props.title !== undefined) && <Typography className={classNames(classes.pageTitle)}>{props.title}</Typography>}
                </div>
            }

            <Dialog
                id="gps-access-blocked-dialog"
                fullScreen={false}
                classes={{
                    root: classes.dialogRoot
                }}
                open={false} // accessLocationBlocked
                aria-labelledby="responsive-dialog-title"
                BackdropProps={{ style: { marginTop: 50 } }} // temp here, find a way to access backdrop class
            >
                <DialogContent>
                    {helper.getMobileOperatingSystem() === "iOS"
                        ?
                        <div>
                            <Typography style={{ color: 'red', fontSize: '20px', textAlign: 'center' }}>iPhone location services need to be enabled.</Typography>
                            <br />
                            <Typography style={{ color: 'black' }}>
                                Open Settings, scroll down, tap "Privacy".<br />
                                1) Tap "Location Services" and set to enabled<br />
                                2) Scroll down to "Safari Websites" and set to "While using the APP".
                            </Typography>
                        </div>
                        :
                        <div>
                            <Typography style={{ color: 'red', fontSize: '20px', textAlign: 'center' }}>Location service is not enabled.</Typography>
                            <br />
                            <Typography style={{ color: 'black' }}>
                                Please make sure that location service is enabled and permitted to this web browser.
                            </Typography>
                        </div>
                    }
                    <br />
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            onClick={() => window.location = '/'}
                            variant="contained"
                            color="default"
                            size="large"
                            classes={{
                                root: classNames(classes.blueButton),
                                label: classNames(classes.scheduleWhiteButtonLabel)
                            }}
                        >OK</Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default withCookies(
    withMobileDialog()(
        withStyles(styles)(
            connect(mapStateToProps, mapDispatchToProps)(ApplicationBar)
        )
    )
)