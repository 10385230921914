import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import classNames from 'classnames';

// import ApplicationBar from 'components/common/mobile/ApplicationBar'
// import SimpleBottomNavigation from 'components/common/mobile/SimpleBottomNavigation'

import ApplicationBar from 'components/common/workflow/ApplicationBar'
import SimpleBottomNavigation from 'components/common/workflow/SimpleBottomNavigation'

import { connect } from "react-redux";
import $ from 'jquery';

import Typography from '@material-ui/core/Typography';

import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import ImmutableJS from "immutable";

import ReactTimeout from 'react-timeout';
import GoogleMapComponent from 'components/common/mobile/GoogleMapComponent';

import styles from './styles.js';

import { logEvent} from "js/actions/index";
// import * as globals from 'components/common/GlobalConstants';
// import * as helper from 'components/common/Helper.js';

const mapDispatchToProps = dispatch => {
    return {
        logEvent: event => dispatch(logEvent(event))
    }
}

const mapStateToProps = state => {
  return { drawData: state.drawData};
};

class RouteclassClass extends Component {
    static propTypes = {
      cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        const { cookies } = this.props;

        this.state = {
            ...this.state, 
            timeout : false,
            milesDrivenPercentage: 0,
            googleMapKey: this.generateID(),
            tabValue: 0,
            order: cookies.get('sitesOrder') ? cookies.get('sitesOrder') : 'asc',
            orderBy: cookies.get('sitesOrderBy') ? cookies.get('sitesOrderBy') : 'site_order',
            marginBottomForLandscape: false,
        }
        this.interval = null;
        this.previousSiteOrdering = [];
    }

    componentDidMount() {
        // console.log('Dashboard did mount.');
        this.setState({...this.state, timeout : false });

        // Find matches
        var mql = window.matchMedia("(orientation: portrait)");
        // console.log(mql);
        this.computeTabSlideContainerDimension(mql.matches);

        // If there are matches, we're in portrait
        // Add a media query change listener
        mql.addListener(function(m) {
            this.computeTabSlideContainerDimension(m.matches);
            // if(m.matches) {
            //     console.log("Portrait orientation");
            // } else {
            //     console.log("Landscape orientation");
            // }
        }.bind(this));

        // resize listener
        window.addEventListener("resize", function() {
            var mql = window.matchMedia("(orientation: portrait)");
            this.computeTabSlideContainerDimension(mql.matches);
        }.bind(this), false)

        this.props.logEvent({module: "map", action: "visit_map", description: "Visited Map Page"})
        
    }

    computeTabSlideContainerDimension = (isPortrait) => {
        // console.log("computeTabSlideContainerDimension......")
        // console.log("isPortrait=" + isPortrait)
        var map_div = $("#map-component-div");
        // var upcoming_sites_div = $("#upcoming-sites-div");
        var showMarginBottom = false;
        
        // var window_height = window.innerHeight;
        var application_bar_height = 70;
        var bottom_navbar_height = 70;
        
        if(isPortrait) {
            // var computed_height = window_height - (application_bar_height + top_floating_allowance + overview_container + bottom_navbar_height + cont_margin);
            // height = computed_height;
        } else {
            // height = cont.width() * 1.5;
            // showMarginBottom = true;
        }

        map_div.css({
            "height": (window.innerHeight - bottom_navbar_height - application_bar_height) + "px",
            "width": window.innerWidth + "px",
        });

        // if(this.state.tabValue === 1) {
            // if tab view === google map, redraw map
            this.setState({marginBottomForLandscape: showMarginBottom, googleMapKey: this.generateID()});
        // }
    }

    convertProgressBarTransformIntoWidth() {
        // force reactjs to use percentage width instead of scalex to support border radius
        var percentage_cont = $("#overall-progress");
        var percentage_el = $("#overall-progress div:first-child");
        var percentage = percentage_cont.attr("aria-valuenow");

        percentage_el.css({
            'transform': 'none',
            'width': percentage + "%",
        });
    }
    
    componentWillMount() {
        this.convertProgressBarTransformIntoWidth();  
    }

    componentDidUpdate() {
        // console.log("dashboard componentDidUpdate");
        
        const drawData = JSON.parse(JSON.stringify(this.props.drawData));
        if(drawData.draw_list !== undefined) {
    
            const current_site_ordering = drawData.draw_list.map((site, index) => {
                return {"site_name": site.site.name, "index": index, "order": site.site_order};
                // return {"site_name": site.site.name, "order": index + 1};
            });
            
            // // console.log(current_site_ordering);
            if(JSON.stringify(this.previousSiteOrdering) !== JSON.stringify(current_site_ordering)) {
                this.setState({googleMapKey: this.generateID()});
                // // console.log("reinitialize map.");
            } else {
                // // console.log("do not reinitialize map.");
            }
            this.previousSiteOrdering = current_site_ordering;
            this.convertProgressBarTransformIntoWidth();
        }
    }

    componentWillReceiveProps() {
        
    }

    goToSite(url) {
        this.props.history.push(url);
    }

    setTimeIsUp = () => {
        this.setState({...this.state, timeout : true });
    }

    handleMilesDrivenPercentageCBHandler = (responseData) => {
        var miles_driven_percentage = 0;
        if(responseData.metersDriven > 0 && responseData.totalMetersDrive > 0) {
            $("#miles-driven-container").text(this.round(responseData.metersDriven, 1) + "/" + this.round(responseData.totalMetersDriven, 1));
            miles_driven_percentage = responseData.percentage;
        }
        this.setState({...this.state, milesDrivenPercentage: miles_driven_percentage });
    }
    
    round(value, precision) {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }
    
    generateID() {
        return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
    }

    handleChangeTabIndex = (newValue) => {
        this.setState({...this.state, tabValue: newValue });
    }

    handleRequestSort = (event, property) => {
        const { cookies } = this.props;
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        // cookies.set('sitesOrder', order, { path: '/'});
        // cookies.set('sitesOrderBy', orderBy, { path: '/'});
        this.setState({...this.state, order, orderBy });
    };

    render() {   
        const drawData = JSON.parse(JSON.stringify(this.props.drawData));
        const { classes } = this.props;
        
        const phlebData = drawData.user;

        // console.log("drawData")
        // console.log(drawData)

        var totalSitesDone = 0;

        // *********************** GOOGLE MAP PLOTTING OF ADDRESSES STARTS HERE
        var mapComponentID = this.generateID();
        var zoom = 12;
        // phlebs home address
        // marked as home/test tube icon for now
        var start = {
            lat: 40.7794799, // NYC
            lng: -73.9785617,
        };

        // will be marked as star(temporarily)
        // but should have option on what icon it would be
        var end = {
            lat: 0.0,
            lng: 0.0,
        };

        if(phlebData && drawData.draw_list !== undefined) {
            // console.log("drawData.draw_list length");
            // console.log(drawData);

            if(drawData.draw_list.length === 0) {
                start = {
                    lat: parseFloat(phlebData.latitude),
                    lng: parseFloat(phlebData.longitude)
                };
            }
    
        }

        // will not be used since preserveViewport=false
        var center = start;

        // phleb's car location, for now it will be placed in the
        // middle of source(solid lineSymbol) and destination(dotted/dashed)
        var way_points = [];
        var way_points_options = [];

        var site_ctr = 0;
        var phlebs_loc_plotted = false;
        
        if(phlebData !== undefined && drawData.draw_list !== undefined) {
            drawData.draw_list.map(site => {
                var immuSite = ImmutableJS.fromJS(site).toJS();
                // console.log(immuSite);
                // overview section
                var stats = {
                    siteDone: 0,
                    siteItemCompleted: 0,
                    siteItemDeferred: 0,
                    siteItemPending: 0,
                    patientInSiteDone: 0,
                    siteTotalDrawItems: 0,
                    siteApproved: true,
                };
    
                var site_has_stat = false;
                var site_has_fasting = false;
                
                immuSite.patient_list.map(patient => {
                    patient.patient_draw.map(drawItem => {
                        if(drawItem.status === "done" || drawItem.status === "completed") {
                            // totalItemCompleted++;
                            stats.siteItemCompleted++;
                            // totalDrawsDone++;
                            stats.patientInSiteDone++;
                            stats.siteDone++;
                            // totalDone++;
                        }
                        if(drawItem.status === "skipped" || drawItem.status === "refused" || drawItem.status === "unable") {
                            // totalItemDeferred++;
                            stats.siteItemDeferred++;
                            // totalDrawsDone++;
                            stats.patientInSiteDone++;
                            stats.siteDone++;
                            // totalDone++;
                        }
                        if(drawItem.status === "pending" || drawItem.status === "" || drawItem.status==="remaining") {
                            // totalItemPending++;
                            stats.siteItemPending++;
                        }
                        
                        if(patient.urgent) {
                            site_has_stat = true;
                        }
    
                        if(patient.fasting) {
                            site_has_fasting = true;
                        }
    
                        stats.siteTotalDrawItems++;
                        // totalDrawItems++;
                        return '';
                    });
                
    
                    return '';
                });
                
                // will be used just for sorting
                // keys that was used under row_headers
                // console.log(stats)
                immuSite.site_name = immuSite.site.name;
                // immuSite.display_address = immuSite.display_address
                immuSite.address = immuSite.site.address;
                immuSite.address_line_2 = (immuSite.site.address_line_2 !== undefined) ? immuSite.site.address_line_2 : '';
                immuSite.status = (stats.siteDone === stats.siteTotalDrawItems) ? "Complete" : "";
                immuSite.no_of_draws = stats.siteTotalDrawItems;
                immuSite.siteDone = stats.siteDone;
                immuSite.siteTotalDrawItems = stats.siteTotalDrawItems;
                immuSite.hasStatRequest = site_has_stat;
                immuSite.hasFasting = site_has_fasting;

                // Temporary bypass 
                // if(site.site_type === globals.INDIVIDUAL) {
                //     immuSite.isApproved = helper.siteComplete(site);// stats.siteApproved;
                // } else {
                //     immuSite.isApproved = helper.siteComplete(site) && helper.siteHasFinalApproval(site);
                //     immuSite.forFinalApproval = helper.siteComplete(site) && !helper.siteHasFinalApproval(site);
                // }

                immuSite.isApproved = true 
                immuSite.forFinalApproval = true 
                
                // Temporary bypass 
                
                totalSitesDone = totalSitesDone + ((stats.patientInSiteDone === stats.siteTotalDrawItems) ? 1 : 0);
                // totalSites++;
                site_ctr++;
    
                var site_status = "not_visited";
                var markerLabelColor = 'white';
                if(stats.patientInSiteDone > 0 && stats.patientInSiteDone < stats.siteTotalDrawItems) {
                    site_status = "active";
                    markerLabelColor = "#66CAEE";
                } else if(stats.patientInSiteDone === stats.siteTotalDrawItems) {
                    // event if 100% completed but not yet approved
                    if(stats.siteApproved) {
                        site_status = "completed";
                        markerLabelColor = "#12D003";
                    } else {
                        site_status = "active";
                        markerLabelColor = "#66CAEE";
                    }
                } 
                // temporary overriden here
                markerLabelColor = "white"//"#747474";
                // temporary removed with stat
                // else if(site_has_stat) {
                //     site_status = "with_stat";
                //     markerLabelColor = "#FEDC4F";
                // }
    
                // need to have this if drawData.draw_list length is greater than 1
                // if drawData.draw_list length == 0, centered on by phleb address
                // as a replacement for phleb's home address
                if(site_ctr === 1) {
                    start = {
                        lat: parseFloat(immuSite.site.latitude), 
                        lng: parseFloat(immuSite.site.longitude)
                    };
                }

                // as a replacement for lab address
                if(site_ctr === drawData.draw_list.length) {
                    end = {
                        lat: parseFloat(immuSite.site.latitude), 
                        lng: parseFloat(immuSite.site.longitude)
                    };
                }

                // hide plotting of car coords
                // if(// (stats.patientInSiteDone > 0) &&
                //     (stats.patientInSiteDone < stats.siteTotalDrawItems) && 
                //     !phlebs_loc_plotted &&
                //     drawData.draw_list.length > 1) {
                //     // show car only if more than 1 drawlist
                //     phlebs_loc_plotted = true;
                //     way_points.push({
                //         location: {
                //             lat: parseFloat(immuSite.site.latitude), 
                //             lng: parseFloat(immuSite.site.longitude)
                //         },
                //         stopover: true,
                //     })
                //     way_points_options.push(
                //         {
                //             labelFonts: {
                //                 text: "0",
                //                 color: 'white !important',
                //                 fontSize: "14px",
                //             },
                //             type: "car"
                //         }, // !important, it will determine the linesymbol if dashed or dotted
                //     );
                // }
                
                way_points.push({
                    location: {
                        lat: parseFloat(immuSite.site.latitude), 
                        lng: parseFloat(immuSite.site.longitude)
                    },
                    stopover: true,
                });

                var infoWindowContent = immuSite.site_type.charAt(0).toUpperCase() + ' ' + immuSite.patient_list.length;

                if(immuSite.site_type.toLowerCase() === "individual") {
                    infoWindowContent = 'I';
                }

                // var targetSite = ""
                // var site_type = immuSite.site_type;

                // if(site_type  === globals.ORGANIZATION) {
                //     targetSite = "/site_organization/" + immuSite.id;
                // } else  if(site_type  === globals.HOUSEHOLD) {
                //     targetSite = "/site_household/" + immuSite.id;
                // } else  if(site_type  === globals.INDIVIDUAL || site_type  === undefined) {
                //     targetSite = "/patient/" + immuSite.patient_list[0].id + "/" + immuSite.id
                // }
                
                // immuSite.link = targetSite;
                immuSite.link = "/appointments/today/" + immuSite.id;

                way_points_options.push({ 
                    type: "site", // site_ctr === (drawData.draw_list.length + 1) ? "star" : 
                    icon: site_status,
                    labelFonts: {
                        text: site_ctr.toString(), // site.site_order.toString(),
                        color: markerLabelColor,
                        fontSize: "14px",
                    },
                    infoWindowObj: {
                        siteData: immuSite,
                        content: infoWindowContent,
                        // font: {
                        //     color: infoWindowContentFont[immuSite.site_type.toLowerCase()]
                        // }
                    }
                });
    
   
                return immuSite;
            });
        }
        
        return(
            <div className={classes.root}>
                <main className={classes.content}>
                    <ApplicationBar isInfoHidden={true} />                        
                    <Typography component="div" id="map-component-div" className={classes.mapDiv}>
                        <GoogleMapComponent
                            key={this.state.googleMapKey}
                            id={mapComponentID}
                            showHomeMarker={true}
                            home={phlebData ? {
                                lat: parseFloat(phlebData.latitude),
                                lng: parseFloat(phlebData.longitude)
                            } : start}
                            showHomeMarkerLabel={false}
                            zoom={zoom}
                            center={center}
                            start={start}
                            end={end}
                            wayPoints={way_points}
                            wayPointsOptions={way_points_options}
                            milesDrivenPercentageResponseHandler={this.handleMilesDrivenPercentageCBHandler}>
                        </GoogleMapComponent>
                    </Typography>

                    {this.state.marginBottomForLandscape &&
                        <div>
                            <div className={classes.toolbar} />
                            <div className={classes.toolbar} />
                        </div>
                    }
                </main>
                <SimpleBottomNavigation />
            </div>
        );
    }
}

RouteclassClass.propTypes = {
    classes: PropTypes.object.isRequired
};

const Dashboard = ReactTimeout(withCookies(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(RouteclassClass))));
export default Dashboard;
