import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import QRCodeStyles from './QRCodeStyles.js'
import classNames from 'classnames'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import ApplicationBar from 'components/common/workflow/ApplicationBar'

import ApptDetailsHeader from './ApptDetailsHeader'
import ScanQRCode from './ScanQRCode'


const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const QRCodeFunctionClass = (props) => {
    const { classes } = props

    return (
        <>
            <div className={classes.root}>
                <main className={classes.content}>
                    <div className={classNames(classes.mainContainer)}>
                        <ApplicationBar />

                        <ApptDetailsHeader />
                        <ScanQRCode />
                    </div>
                </main>
                 
            </div>
        </>
    )
}

const QRSettings = withCookies(
    connect(mapStateToProps)(
        withStyles(QRCodeStyles, { withTheme: true })(QRCodeFunctionClass)
    )
)
export default QRSettings
