import React, {Component} from 'react' 
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom' 

import  Dashboard from "../mobile/Dashboard"
import  RouteMap from "../mobile/RouteMap"
// import BarcodeScanner from 'components/common/experiment/BarcodeScanner'
import Notifications from  "../workflow/Notifications"
import Logout from "../mobile/Logout"
import Sites from "../mobile/Sites"
// import Site from "../mobile/Sites/Site"
import SiteOrganization from "../mobile/Sites/SiteOrganization"
import SiteHousehold from "../mobile/Sites/SiteHousehold"

import Patient from "../mobile/Patients/Patient"
import PatientOrganization from "../mobile/PatientsOrganization/Patient"
import PatientHousehold from "../mobile/PatientsHousehold/Patient"

import Settings from "../mobile/Settings"
import WorkflowSettings from "../workflow/Settings"
import ProfileSettings from "../workflow/Settings/ProfileSettings"
import ChangePassword from "../workflow/Settings/ChangePassword"
import About from "../workflow/Settings/About"
import ViewLogs from "../workflow/Settings/Logs"

//Mockup pages
import NewPatient from "../workflow/AppointmentOrders/NewPatient"
import ExistingNewPatient from "../workflow/AppointmentOrders/ExistingNewPatient"
// import SearchPatient from "../workflow/Settings/SearchPatient"
// import Sample from "../workflow/Settings/Sample"
import ScanQRCode from "../workflow/Settings/ScanQRCode"

// import Barcode from "../mobile/Barcode"
import PhotoCapture from "../mobile/PhotoCapture"

import EditRoutes from "../mobile/Sites/EditRoutes"
// import PhlebSchedules from "../mobile/PhlebSchedules"
import PhlebSchedulesV2Class from "../mobile/PhlebSchedulesv2"
import AddEditSchedule from "../mobile/PhlebSchedulesv2/AddEditSchedule"
// import EditRepeatingSchedule from "../mobile/PhlebSchedules/EditRepeatingSchedule"
// import EditIndividualSchedule from "../mobile/PhlebSchedules/EditIndividualSchedule"
//import ScheduleSettings from "../mobile/PhlebSchedules/ScheduleSettings"
import ScheduleSettings from "../mobile/PhlebSchedulesv2/ScheduleSettings"
import FutureOrdersMain from "../mobile/FutureOrders/FutureOrdersMain"
import FutureOrderDetails from "../mobile/FutureOrders/FutureOrderDetails"
import FutureOrderSiteDetails from "../mobile/FutureOrders/FutureOrderSiteDetails"
import UnlockScreen from "../mobile/PinCode/UnlockScreen"
import LockScreen from "../mobile/PinCode/LockScreen"
import CreatePinCode from "../mobile/PinCode/CreatePinCode"
import GPSLock from "../mobile/GPSLock"

import WorkTerritory from "../mobile/WorkTerritory"
import Logs from "../mobile/Logs"
import DrawRequest from "../mobile/DrawRequest"
import Login from "components/Login"

// iv infusion routes
import Appointments from "../workflow/Appointments"
import AppointmentOrders from "../workflow/AppointmentOrders"

import DefaultWorkflowOrder from "../workflow/AppointmentOrder/DefaultWorkflowOrder"
import IvInfusionWorkflowOrder from "../workflow/AppointmentOrder/IvInfusionWorkflowOrder"

import WorkSchedule from "../workflow/WorkSchedule"
import ManageWorkSchedule from "../workflow/WorkSchedule/Monthly/ManageSchedule"
import PreviewWorkSchedule from "../workflow/WorkSchedule/Monthly/PreviewSchedule"

import Map from "../workflow/RouteMap"
class  MobileRouter extends Component {
    componentDidMount() {
        // console.log("componentDidMount MobileRouter")
    }      
    render() {
      
        return(
            <div>
                <BrowserRouter>
                    <Switch>
                        {/* workflow routes */}
                        <Route path="/appointments/:appointment_type" exact={true} render={(props) => <Appointments {...props} />} />
                        <Route path="/appointments/today/:appointment_id" exact={true} render={(props) => <AppointmentOrders {...props} />} />
                        <Route path="/appointments/today/:appointment_id/default/:order_id/:step" exact={true} render={(props) => <DefaultWorkflowOrder {...props} />} />
                        <Route path="/appointments/today/:appointment_id/iv-infusion/:order_id/:step" exact={true} render={(props) => <IvInfusionWorkflowOrder {...props} />} />
                        <Route path="/workflow_settings/" render={(props) => <WorkflowSettings {...props} />} />
                        <Route path="/profile_settings/" render={(props) => <ProfileSettings {...props} />} />
                        <Route path="/change_password/" render={(props) => <ChangePassword {...props} />} />
                        <Route path="/about/" render={(props) => <About {...props} />} />
                        <Route path="/view_logs/" render={(props) => <ViewLogs {...props} />} />
                        
                        <Route path="/appointments/today/:appointment_id/new-patient" render={(props) => <NewPatient {...props} />} />
                        <Route path="/appointments/today/:appointment_id/existing-new-patient/" render={(props) => <ExistingNewPatient {...props} />} />
                        {/* <Route path="/SearchPatient/" render={(props) => <SearchPatient {...props} />} />
                        <Route path="/Sample/" render={(props) => <Sample {...props} />} /> */}
                        <Route path="/ScanQRCode/" render={(props) => <ScanQRCode {...props} />} />
                       
                        <Route path="/work-schedule/" exact={true} render={(props) => <WorkSchedule {...props} />} />
                        
                        <Route path="/work-schedule/manage/:schedule_id/:work_schedule_date/:work_schedule_time" exact={true} render={(props) => <ManageWorkSchedule {...props} />} />
                        <Route path="/work-schedule/preview/:schedule_id" exact={true} render={(props) => <PreviewWorkSchedule {...props} />} />

                        <Route path="/map/" render={(props) => <Map {...props} />} />
                        {/* v1 routes */}
                        <Route path="/logout/:reason"  render={(props) => <Logout {...props}/>}  />
                        <Route path="/dashboard/" render={(props) => <Dashboard {...props} />} />
                        <Route path="/route_map/" render={(props) => <RouteMap {...props} />} />
                        

                        {/* <Route path="/schedulesv1/" render={(props) => <PhlebSchedules {...props} />} /> */}
                        <Route path="/schedules/" render={(props) => <PhlebSchedulesV2Class {...props} />} />
                        <Route path="/draw_request/" render={(props) => <DrawRequest {...props} />} />
                        <Route path="/work_territory/" render={(props) => <WorkTerritory {...props} />} />
                        <Route path="/logs/" render={(props) => <Logs {...props} />} />
                        <Route path="/add_edit_schedule/:scheduleId/:date" render={(props) => <AddEditSchedule {...props} />} />
                        {/* <Route path="/edit_repeating_schedule/:scheduleId" render={(props) => <EditRepeatingSchedule {...props} />} />
                        <Route path="/edit_individual_schedule/:scheduleId" render={(props) => <EditIndividualSchedule {...props} />} /> */}
                        <Route path="/schedule_settings/" render={(props) => <ScheduleSettings {...props} />} />
                        <Route path="/future_orders/" render={(props) => <FutureOrdersMain {...props} />} />
                        <Route path="/future_order_details/:futureOrderDate/:viewType" render={(props) => <FutureOrderDetails {...props} />} />
                        <Route path="/future_order_site_details/:futureOrderDate/:siteOrder/:viewType" render={(props) => <FutureOrderSiteDetails {...props} />} />
                        <Route path="/unlock_screen" render={(props) => <UnlockScreen {...props} />} />
                        <Route path="/lock_screen" render={(props) => <LockScreen {...props} />} />
                        <Route path="/create_pin_code" render={(props) => <CreatePinCode {...props} />} />
                        <Route path="/gps_lock" render={(props) => <GPSLock {...props} />} />
                        <Route path="/sites/" render={(props) => <Sites {...props} />} />
                        {/* <Route path="/site/:siteId" render={(props) => ( <Site {...props} /> )} /> */}
                        <Route path="/site_organization/:siteId" render={(props) => ( <SiteOrganization {...props} /> )} />
                        <Route path="/site_household/:siteId" render={(props) => ( <SiteHousehold {...props} /> )} />
                        
                        <Route path="/patient/:patientId/:siteId" render={(props) => <Patient {...props} />} />
                        <Route path="/patient_organization/:patientId/:siteId" render={(props) => <PatientOrganization {...props} />} />
                        <Route path="/patient_household/:patientId/:siteId" render={(props) => <PatientHousehold {...props} />} />
                        <Route path="/notifications/" render={(props) => <Notifications {...props} />} />
                        <Route path="/settings/" render={(props) => <Settings {...props} />} />
                        <Route path="/barcode/" render={(props) => <PhotoCapture {...props} />} />
                        
                        <Route path="/login"  render={(props) => <Login {...props}/>}  />
                        <Route path="/editroutes/" render={(props) => <EditRoutes {...props} />} />
                        
                        <Redirect to="/login" />
                    </Switch>
                </BrowserRouter>
            </div>
        )
    }
}

export default MobileRouter
