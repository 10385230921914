import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    container: {
        margin: "10%",
        marginTop: "180px"
    },
    text: {
        color: "white !important"

    }
});

class CameraPermissionError extends React.Component {
    // Important note:
    // When a user blocks camera access for a specific website, it is stored in the browser's settings or permissions system. JavaScript running on a website does not have the capability to modify these browser settings or permissions. It is up to the user to manually change their settings and allow camera access again if they choose to do so.

    // componentDidMount() {
    //     navigator.mediaDevices.enumerateDevices()
    //     console.log("request for camera permission here...")
    // }

    render() {
        const { classes } = this.props;

        return (
            <div className={classNames(classes.container)}>
                <Typography variant="h5" align="center" className={classes.text}>Failed to load Camera</Typography>
                <br />
                <Typography className={classes.text}>Please check the following:</Typography>
                <br />
                <Typography className={classes.text}>- Camera permission in your browser(Browser Camera Permission).</Typography>
                <br />
                <Typography className={classes.text}>- Check if your browser has access to your device camera(Device Camera Settings).</Typography>
                <br />
                <Typography className={classes.text}>- Or, if you are using an iOS device, please use Safari browser.</Typography>
                <br />
                {/* <button onClick={()=>{
                            if(this.props.retryCamera !== undefined) {
                                this.props.retryCamera()
                            } 
                    }
                    
                }>Retry</button> */}
            </div>
        );
    }
}

export default withStyles(styles)(CameraPermissionError);