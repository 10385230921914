import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
import classNames from 'classnames'

import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import CallIcon from '@material-ui/icons/Call'
import RoomIcon from '@material-ui/icons/Room'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import * as helper from 'components/common/Helper.js'
import moment from 'moment'

import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'

const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const AppointmentListItemFunctionClass = (props) => {
    // console.log("AppointmentListItemFunctionClass props")
    // console.log(props)
    const [processedDraws, setProcessedDraws] = useState(0)
    // const [status, setStatus] = useState("")
    const { classes, appointment, history, expanded, handleChange } = props
    // const isActive = appointment.active ? "active" : "inactive"
    // const isActive = appointment.site_order === 1 ? "active" : "inactive"

    useEffect(() => {
        // Update the document title using the browser API
        // console.log("appointment")
        // console.log(appointment)
        var count = 0
        appointment.patient_list.map(patient => {
            if (patient.patient_draw[0].status !== "remaining") {
                count++
            }
        })
        setProcessedDraws(count)
        // setStatus(appointment.patient_list[0].patient_draw[0].status)
    }, [appointment])

    const loadAppointmentDetails = () => {
        // appointment.id == patient_list[n].drawlist_site
        history.push("/appointments/today/" + appointment.id)
    }

    const appt_key = 'panel_' + appointment.id
    const isActive = expanded === appt_key
    const address = appointment.display_address ? appointment.display_address : [appointment.site.address, appointment.site.state, appointment.site.zipcode].join(", ")

    const city = appointment.site.city !== undefined && appointment.site.city !== "" ? appointment.site.city + " " : "";
    const city_state_zipcode = city + [appointment.site.state, appointment.site.zipcode].filter(function (el) { return el; }).join(", ");
    const map_link = [appointment.site.address, appointment.site.address_line_2, city_state_zipcode].filter(function (el) { return el; }).join(", ");

    const siteDone = processedDraws === appointment.patient_list.length

    // console.log("appointment")
    // console.log(appointment)

    const service_name = appointment.service_name
    const visit_contact = appointment.visit_contact
    
    const order_length = appointment.patient_list.length

    return (
        <>
            <ExpansionPanel
                key={appt_key}
                square={false}
                expanded={isActive}
                onChange={handleChange(appt_key)}
                classes={{
                    root: classes.apptListItemPanelRoot,
                    expanded: classes.apptListItemSummaryExpanded,
                }}
                className={classNames(
                    classes.apptListItemContainer,
                    // isActive ? "active" : "inactive"
                    siteDone ? "done" : (isActive ? "active" : "inactive")
                )}
            >
                <ExpansionPanelSummary
                    classes={{
                        root: classNames(classes.apptListItemSummary, isActive ? classes.apptListItemSummaryActive : ""),
                        content: classes.apptListItemSummaryContent,
                    }}
                    aria-controls={appt_key + "-content"}
                    id={appt_key + "-header"}
                >
                    <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                    >
                        <div>
                            <Typography className={classes.apptListItemTime}>{moment(appointment.collection_time, "HH:mm:ss").format("h:mm A")}</Typography>
                        </div>
                        <Typography className={classNames("orders")}>{processedDraws}/{appointment.patient_list.length} Order{order_length !== 1 && "s"}</Typography>
                    </Grid>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    classes={{
                        root: classes.apptListItemDetailsRoot,
                    }}
                >
                    <Typography className={classes.orderName}>{visit_contact}</Typography>
                    <Typography className={classes.orderAddress}>{address}</Typography>

                    <Grid container justify="space-between" className={classNames(classes.activeButtonsContainer)}>
                        <div>
                            <IconButton
                                aria-label="Call"
                                classes={{
                                    root: classes.ordersButton
                                }}
                                onClick={() => {
                                    window.location.href = "tel:" + appointment.site.phone_number
                                }}>
                                <CallIcon className={classNames(classes.ordersButtonIcon)}></CallIcon> Call
                            </IconButton>
                            <IconButton aria-label="Map" className={classes.ordersButton} onClick={() => {
                                helper.navigate(appointment.site.latitude, appointment.site.longitude, map_link)
                            }}>
                                <RoomIcon className={classNames(classes.ordersButtonIcon)}></RoomIcon> Map
                            </IconButton>
                        </div>
                        <IconButton aria-label="Enter" className={classNames(classes.openOrderButton)} onClick={loadAppointmentDetails}>
                            <ArrowForwardIcon className={classNames()}></ArrowForwardIcon>
                        </IconButton>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            {/* <div
                className={classNames(classes.apptListItemContainer, isActive ? "active" : "inactive")}
                onClick={() => {
                    if (!isActive) {
                        onSetActive(appointment.id)
                    }
                }}
            >
                <Grid container justify="space-between">
                    <div>
                        <Typography className={classes.apptListItemTime}>{moment(appointment.collection_time, "HH:mm:ss").format("h:mm A")}</Typography>
                    </div>
                    <Typography className={classNames("orders")}>{processedDraws}/{appointment.patient_list.length} Orders</Typography>
                </Grid>
            </div> */}
        </>
    )
}

const AppointmentListItem = withRouter(
    withCookies(
        connect(mapStateToProps)(
            withStyles(styles, { withTheme: true })(AppointmentListItemFunctionClass)
        )
    )
)
export default AppointmentListItem
