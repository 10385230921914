

const styles = theme => ({
    root: {
        // display: 'flex',
        background: "#E8E8E8",
        paddingTop: "25px !important",
        paddingLeft: "40px !important",
        paddingRight: "40px !important",
        paddingBottom: "40px !important",
    },
    gridRoot: {
        flexGrow: 1,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
    },
    margins: {
      marginTop: theme.spacing.unit,
    },
    paperUI: {
        ...theme.mixins.gutters(),
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 4,
        borderRadius: 10,
    },
    paddings: {
        padding: `${theme.spacing.unit * 4}px ${theme.spacing.unit * 4}px`,
    },
    overviewSection: {
        background: "linear-gradient(180deg, #68CBEF, #1C8FB9)",
        maxWidth: "100%",
        paddingBottom: 15,
    },
    mapSection: {
        // minHeight: 317,
        minHeight: 420,
        padding: 0,
    },
    alertSection: {
        background: "linear-gradient(180deg, #FFDD50, #C6A934)",
        minHeight: 262,
    },
    alertBadge: {
        fontSize: 22,
        background: "#000",
        color: "#F3D24A",
        padding: 5,
        borderRadius: 10,
        top: 1,
        right: -43,
        zIndex: 0,
    },
    overviewTitle: {
        fontWeight: "bold",
        color: "white",
        fontSize: 50,
    },
    alertsTitle: {
        fontWeight: "bold",
        color: "black",
        marginBottom: 14,
    },
    circularProgressContainer: {
        margin: `${theme.spacing.unit * 3.5}px 0px`,
    },
    wrapper: {
        position: 'relative',
        padding: theme.spacing.unit,
        textAlign: 'center',
    },
    fab: {
        color: "white",
        background: "#42ADD4",
        width: 88,
        height: 88,
        // zIndex: 1100,
    },
    overviewIcons: {
        width: 45,
        height: 45,
    },
    alertsItem: {
        background: "white",
        borderRadius: 10,
        padding: '12px 14px',
        marginBottom: 15,
    },
    alertsIcon: {
        fontSize: 30,
        color: "black",
    },
    alertsPrimaryText: {
        fontSize: 16,
        fontFamily: "Arial, Bold",
        color: "black",
    },
    fabProgress: {
        color: "#19DB00",
        position: 'absolute',
        // top: -6,
        // left: -6,
        zIndex: 1,
    },
    progressDetails: {
        color: "white !important",
        lineHeight: 1.15,
    },
    progressLabel: {
        fontSize: "16px !important",
        fontWeight: "350 !important",
    },
    progressNumbers: {
        fontSize: "22px !important",
    },
    labelContainer: {
        textAlign: "center"
        // paddingLeft: 10
    },
    siteListRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        // transform: 'translateZ(0)',
    },
    gridListTileRoot: {
        width: "33%",
        height: "152px",
        padding: "14.5px",
        paddingBottom: "30px",
    },
    gridListTile: {
        borderRadius: 5,
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    },
    gridListTileWithStat: {
        borderRadius: 5,
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        color: '#000000 !important',
    },
    title: {
        color: "#707070",
        fontSize: "18px !important",
    },
    titleBar: {
        background: 'none',
    },
    gridListDraws: {
        fontFamily: 'Arial',
        fontWeight: 'bold',
        fontSize: "32px !important",
        position: 'absolute',
        top: 45,
        left: 15,
        color: '#8C8C8C',
    },
    titleWithStat: {
        color: '#000000 !important',
    },
    gridListDrawsWithStat: {
        color: '#000000 !important',
    },
    checkCircle: {
        color: "#12D003",
        fontSize: 34,
        // marginTop: -5,
        marginRight: 10,
    }
});

export default styles