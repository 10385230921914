import React, { useState, useEffect } from 'react'

import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
import classNames from 'classnames'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import ApplicationBar from 'components/common/workflow/ApplicationBar'

import ApptOrderHeader from './ApptOrderHeader'
import StepDescription from './StepDescription'
import Step1 from './iv-infusion/Step1'
import Step2 from './iv-infusion/Step2'
import Step3 from './iv-infusion/Step3'
import Step4 from './iv-infusion/Step4'
import Step5 from './iv-infusion/Step5'
import Step6 from './iv-infusion/Step6'

import Step7 from './iv-infusion/Step7'
import ConfirmCompletionDialog from './ConfirmCompletionDialog'

import Stepper from './Stepper'
import moment from 'moment-timezone';
import {
    updateWorkflowValue,
    updatePatientDrawStatus
} from "js/actions/index"

const mapDispatchToProps = dispatch => {
    return {
        updateWorkflowValue: value => dispatch(updateWorkflowValue(value)),
        updatePatientDrawStatus: value => dispatch(updatePatientDrawStatus(value)),
    }
}

const mapStateToProps = state => {
    return {
        drawData: state.drawData,
        workflowData: state.workflowData
    }
}

const IvInfusionWorkflowOrderFunctionClass = (props) => {
    const {
        drawData,
        workflowData,
        classes,
        history,
        match,
        updateWorkflowValue,
        updatePatientDrawStatus,
    } = props
    const appointment_id = match.params.appointment_id  // site_id
    const order_id = match.params.order_id              // patient_id
    const workflow_type = match.url.split('/')[4]

    var patient_draw_id = 0

    // START LOAD patient order data and patient workflow data
    var order = {
        patient: {
            last_name: "",
            first_name: "",
            birth_date: ""
        }
    }
    var patientWorkflowData = null
    var isOrderRemaining = true

    // const [isOrderRemaining, setIsOrderRemaining] = useState(true)

    if (drawData.draw_list !== undefined) {
        const theSite = drawData.draw_list.find(function (site) {
            return site.id === appointment_id
        })

        order = theSite.patient_list.find(function (patient) {
            return patient.id === order_id
        })

        // console.log("order.patient_draw[0]")
        // console.log(order.patient_draw[0])
        if (order.patient_draw[0].status !== "remaining") {
            isOrderRemaining = false
        }

        patient_draw_id = order.patient_draw[0].id
        if (workflowData.workflow !== undefined) {
            patientWorkflowData = workflowData.workflow.filter(function (workflow) {
                return workflow.drawlist_site_id === appointment_id &&
                    workflow.drawlist_patient_id === order_id &&
                    workflow.patient_draw_id === patient_draw_id
            })
        }
    }

    // console.log("isOrderRemaining")
    // console.log(isOrderRemaining)
    // END LOAD patient order data and patient workflow data
    console.log("patientWorkflowData")
    console.log(patientWorkflowData)

    const step = match.params.step
    // const [btnBackDisabled, setBtnBackDisabled] = useState(false)
    const btnBackDisabled = false
    const [btnNextDisabled, setBtnNextDisabled] = useState(true)

    const onBack = (step) => {
        history.push("/appointments/today/" + appointment_id + "/" + workflow_type + "/" + order_id + "/" + step)
    }

    const onNext = (step) => {
        history.push("/appointments/today/" + appointment_id + "/" + workflow_type + "/" + order_id + "/" + step)
    }

    // processing of data
    const [step1Data, setStep1Data] = useState(null)
    // const [step2Data, setStep2Data] = useState(null)
    // const [step3Data, setStep3Data] = useState(null)
    // const step3Data = null
    // const [step4Data, setStep4Data] = useState(null)
    // const [step5Data, setStep5Data] = useState(null)
    // const [step6Data, setStep6Data] = useState(null)
    const step6Data = null
    const [step7Data, setStep7Data] = useState(null)

    const executeUpdateWorkflowValues = (wf_values) => {
        const payload = {
            "siteId": appointment_id,
            "patientId": order_id,
            "patientDrawId": patient_draw_id,
            "workflow_values": wf_values
        }
        updateWorkflowValue(payload)
    }

    // Verify patient information
    const saveStep1Data = () => {
        console.log("saveStep1Data")
        console.log(step1Data)

        // step1Data &&: to check if there is changes
        // TODO: must have checking of old and new values is there is diff
        if (step1Data && isOrderRemaining) {
            const name = step1Data.find(x => x.key === "name_verified")
            const dob = step1Data.find(x => x.key === "dob_verified")
            const dosage = step1Data.find(x => x.key === "dosage_verified")
            const indication = step1Data.find(x => x.key === "indication_verified")

            executeUpdateWorkflowValues([
                { key: "name_verified", value: name.verified },
                { key: "dob_verified", value: dob.verified },
                { key: "dosage_verified", value: dosage.verified },
                { key: "dosage", value: dosage.value },
                { key: "indication_verified", value: indication.verified },
                { key: "indication", value: indication.value },
            ])
        }
    }

    // IV Kit photo
    // const saveStep2Data = () => {
    // auto save when captured
    // console.log("saveStep2Data")
    // console.log(step2Data)
    // }

    // Venipuncture site and photo
    // const saveStep3Data = () => {
    //     if (step3Data) {
    //         // save venipuncture site only, photo capture auto save upon Capture & Accept
    //         executeUpdateWorkflowValues([
    //             { key: "venipuncture_site", value: step3Data.venipuncture_site },
    //         ])
    //     }
    // }

    // Infusion time
    // const saveStep4Data = () => {
    //     console.log("saveStep4Data")
    //     console.log(step4Data)

    //     if (step4Data && isOrderRemaining) {
    //         executeUpdateWorkflowValues([
    //             { key: "infusion_start_time", value: step4Data.infusion_start_time },
    //             { key: "infusion_end_time", value: step4Data.infusion_end_time },
    //             { key: "infusion_total_time", value: step4Data.infusion_total_time },
    //         ])
    //     }
    // }

    // Photo of medication container
    // const saveStep5Data = () => {
    // no saving of data after Next button, capture photo is auto save
    // console.log("saveStep5Data")
    // console.log(step5Data)
    // }

    // Field data capture
    const saveStep6Data = () => {
        console.log("saveStep6Data")
        console.log(step6Data)
    }

    // Signature
    const saveStep7Data = () => {
        console.log("saveStep7Data")
        console.log(step7Data)

        if (step7Data && isOrderRemaining) {
            var timezone = moment.tz.guess(true) || "America/New_York"
            var unixEpoch = moment().unix();

            console.log("order.patient_draw")
            console.log(order)

            const payload = {
                "first_name": order.patient.first_name, // We assume patient name but better confirm it. 
                "last_name": order.patient.last_name,  // 
                "signatory_type": "patient", // ? 
                "sign_off": step7Data.signature,
                "sign_off_metadata": step7Data.metadata,
                "client_timezone": timezone,
                "client_timestamp": unixEpoch
            }

            executeUpdateWorkflowValues([
                { key: "sign_off", value: payload },
            ])
        }
    }

    useEffect(() => {
        saveStep7Data()
    }, [step7Data])

    // ** DEFAULT VALUES
    // btnNextLabel: Next
    // btnBackVisible: true
    // btnNextVisible: true
    const steps = [
        {
            description: "Verify Patient Information",
            btnBackVisible: false,
            btnNextLabel: isOrderRemaining ? "Verify" : "Next",
            onClickBtnNext: () => {
                onNext(2)
                setBtnNextDisabled(true) // for btnNext in Step 2

                console.log("isOrderRemaining")
                console.log(isOrderRemaining)
                if (isOrderRemaining) {
                    saveStep1Data()
                }
            },
            content: <Step1
                isOrderRemaining={isOrderRemaining}
                onSetBtnNextDisabled={(val) => setBtnNextDisabled(val)}
                onChangeData={(data) => setStep1Data(data)}
                order={order}
                patientWorkflowData={patientWorkflowData}
                onConfirmUnableToPerformOrder={(data) => confirmUnableToPerformOrder(data)}
            />
        },
        {
            description: "Take a Photo of the IV Kit",
            onClickBtnBack: () => { onBack(1) },
            onClickBtnNext: () => {
                onNext(3)
                setBtnNextDisabled(true)
                // saveStep2Data() autosave picture onCapture & Accept
            },
            content: <Step2
                isOrderRemaining={isOrderRemaining}
                siteId={appointment_id}
                patientId={order_id}
                patientDrawId={patient_draw_id}
                patientWorkflowData={patientWorkflowData}
                onSetBtnNextDisabled={(val) => setBtnNextDisabled(val)}
            // onChangeData={(data) => setStep2Data(data)}
            />
        },
        {
            description: "Indicate venipuncture site and take a photo",
            onClickBtnBack: () => { onBack(2) },
            onClickBtnNext: () => {
                onNext(4)
                setBtnNextDisabled(true)
                // saveStep3Data() # moved inside onChangeData
            },
            content: <Step3
                isOrderRemaining={isOrderRemaining}
                siteId={appointment_id}
                patientId={order_id}
                patientDrawId={patient_draw_id}
                patientWorkflowData={patientWorkflowData}
                onSetBtnNextDisabled={(val) => setBtnNextDisabled(val)}
                onChangeData={(data) => {
                    // REAL: for testing, auto save on redux on change
                    // setStep3Data(data)
                    // saveStep3Data()
                    console.log("executeUpdateWorkflowValues")
                    console.log(data)
                    executeUpdateWorkflowValues([
                        { key: "venipuncture_site", value: data.venipuncture_site },
                    ])
                }}
            />
        },
        {
            description: "Infusion Time",
            onClickBtnBack: () => { onBack(3) },
            onClickBtnNext: () => {
                onNext(5)
                setBtnNextDisabled(true)
                // saveStep4Data()
            },
            content: <Step4
                isOrderRemaining={isOrderRemaining}
                patientWorkflowData={patientWorkflowData}
                onSetBtnNextDisabled={(val) => setBtnNextDisabled(val)}
                onChangeData={(data) => {
                    // setStep4Data(data)
                    // console.log("setStep4Data")
                    // console.log(data)
                    executeUpdateWorkflowValues([
                        { key: "infusion_start_time", value: data.infusion_start_time },
                        { key: "infusion_end_time", value: data.infusion_end_time },
                        { key: "infusion_total_time", value: data.infusion_total_time },
                    ])
                }}
            />
        },
        {
            description: "Take a photo of the completed medication container",
            onClickBtnBack: () => { onBack(4) },
            onClickBtnNext: () => {
                onNext(6)
                setBtnNextDisabled(false) // Next Step, Field data capture is optional
                // saveStep5Data()
            },
            content: <Step5
                isOrderRemaining={isOrderRemaining}
                siteId={appointment_id}
                patientId={order_id}
                patientDrawId={patient_draw_id}
                patientWorkflowData={patientWorkflowData}
                onSetBtnNextDisabled={(val) => setBtnNextDisabled(val)}
            // onChangeData={(data) => setStep5Data(data)}
            />
        },
        {
            description: "Field data capture (optional)",
            onClickBtnBack: () => { onBack(5) },
            onClickBtnNext: () => {
                onNext(7)
                setBtnNextDisabled(true)
                saveStep6Data()
            },
            content: <Step6
                isOrderRemaining={isOrderRemaining}
                patientWorkflowData={patientWorkflowData}
                onSetBtnNextDisabled={(val) => setBtnNextDisabled(val)}
                // onChangeData={(data) => setStep6Data(data)} // no need here
                siteId={appointment_id}
                patientId={order_id}
                patientDrawId={patient_draw_id}
            />
        },
        {
            description: "Get patient's signature",
            btnNextLabel: isOrderRemaining ? "Complete" : "Close",
            onClickBtnBack: () => { {
                onBack(6) 
                // executeUpdateWorkflowValues([
                //     { key: "sign_off", value: {} },
                // ])
            }},
            onClickBtnNext: () => {
                if (isOrderRemaining) {
                    setConfirmCompletionDialogOpen(true)
                } else {
                    goBackToOrdersList()
                }
            },
            content: <Step7
                isOrderRemaining={isOrderRemaining}
                patientWorkflowData={patientWorkflowData}
                onSetBtnNextDisabled={(val) => setBtnNextDisabled(val)}
                // on every edit
                onChangeData={(data) => setStep7Data(data)}
                // save if done editing
                onAcceptSignature={() => {
                    // saveStep7Data()
                }}
                // save empty sign directly
                onClearSignature={() => {
                    executeUpdateWorkflowValues([
                        { key: "sign_off", value: {} },
                    ])
                }}
            />
        },
    ]

    const goBackToOrdersList = () => {
        history.push("/appointments/today/" + appointment_id)
    }

    const confirmDrawCompletion = () => {
        // set done draw here
        const payload = {
            "siteId": appointment_id,
            "patientId": order_id,
            "patientDrawId": patient_draw_id,
            "type": "p",
            "status": "done",
            "notes": "",
            "draw_source": "right_arm",
            "withSignature": false,
        }
        updatePatientDrawStatus(payload)

        history.push("/appointments/today/" + appointment_id)
    }

    const confirmUnableToPerformOrder = (data) => {
        const payload = {
            "siteId": appointment_id,
            "patientId": order_id,
            "patientDrawId": patient_draw_id,
            "type": "p",
            "status": data.status,
            "notes": data.notes
        }
        updatePatientDrawStatus(payload)

        history.push("/appointments/today/" + appointment_id)
    }

    const [confirmCompletionDialogOpen, setConfirmCompletionDialogOpen] = useState(false)

    return (
        <>
            <div className={classes.root}>
                <main className={classes.content}>
                    <div className={classNames(classes.mainContainer)}>
                        <ApplicationBar />

                        <ApptOrderHeader step={step} />
                        <StepDescription step={step} description={steps[step - 1].description} />

                        {steps[step - 1].content}

                        <Stepper
                            step={step}

                            isBtnBackDisabled={btnBackDisabled}
                            isBtnBackVisible={steps[step - 1].btnBackVisible}
                            btnBackLabel={steps[step - 1].btnBackLabel}
                            onClickBtnBack={steps[step - 1].onClickBtnBack}

                            isBtnNextDisabled={btnNextDisabled}
                            isBtnNextVisible={steps[step - 1].btnNextVisible}
                            btnNextLabel={steps[step - 1].btnNextLabel}
                            onClickBtnNext={steps[step - 1].onClickBtnNext}
                        />

                        {/* modals */}
                        <ConfirmCompletionDialog
                            open={confirmCompletionDialogOpen}
                            onClose={() => setConfirmCompletionDialogOpen(false)}
                            onConfirm={() => confirmDrawCompletion()}
                        />
                    </div>
                </main>
            </div>
        </>
    )
}

const IvInfusionWorkflowOrder = withCookies(
    connect(mapStateToProps, mapDispatchToProps)(
        withStyles(styles, { withTheme: true })(IvInfusionWorkflowOrderFunctionClass)
    )
)
export default IvInfusionWorkflowOrder
