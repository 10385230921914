import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.js'

import classNames from 'classnames';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import Icon from '@material-ui/core/Icon';
import { connect } from "react-redux";
//import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
// import UpdateIcon from '@material-ui/icons/Update';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import $ from 'jquery';
import Typography from '@material-ui/core/Typography'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
// import ApplicationBar from 'components/common/mobile/ApplicationBar';
// import SimpleBottomNavigation from 'components/common/mobile/SimpleBottomNavigation';
import ApplicationBar from 'components/common/workflow/ApplicationBar'
import SimpleBottomNavigation from 'components/common/workflow/SimpleBottomNavigation'

import * as globals from 'components/common/GlobalConstants';
import MiniLoadingSpinner from "components/common/mobile/MiniLoadingSpinner.js";

import Encryption from 'components/common/Encryption';

import * as helper from 'components/common/Helper.js'

// import { ReactComponent as DrawRequestOutlineSVG } from 'components/common/mobile/CustomOfflineSVG/draw_request_outline.svg';

import ConfirmDialog from 'components/common/mobile/ConfirmDialog'

import Grid from '@material-ui/core/Grid'

const mapStateToProps = state => {
    return {
        drawData: state.drawData,
        newAppVersion: state.newAppVersion
    };
};

class SettingsClass extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            anchorEl: null,
            aboutDialogOpened: false,
            upToDateDialogOpened: false,
            changePasswordDialogOpened: false,
            changePasswordLoadingDialogOpened: false,
            changePasswordMessage: '',
            setDeleteNotesDialog : false,
            confirmDialogMessage: "",
            confirmDialogTitle : "",
        };
    }

    setDeleteNotesDialogHandler = (actionHandler, postActionHandler, title, message, actionText, chainedFunction) => {
        // if(chainedFunction === null) chainedFunction = ()=>{};
        this.setState(
        {
            ...this.state, 
            actionHandler: actionHandler, 
            postActionHandler : postActionHandler, 
            confirmDialogMessage: message,
            confirmDialogTitle: title,
            confirmDialogActionText: actionText,
        }, 
        chainedFunction
        );
    }

    setDeleteNoteDialogVisible = (value) => {
    //console.log("setDeleteNoteDialogVisible " + value);
        this.setState({ ...this.state, setDeleteNotesDialog: value});
    }

    componentDidMount() {
        // console.log("componentDidMount SettingsClass")
    }

    handleChangePassword = () => {
        this.setState({ ...this.state, changePasswordLoadingDialogOpened: true });

        var security = new Encryption();
        var decryptedCred = JSON.parse(security.decrypt(this.props.allCookies.credentials))

        var phleb = decryptedCred.phleb_id;
        var token = decryptedCred.token;
        var user_data = { user_id: phleb };

        var request = $.ajax({
            beforeSend: function (xhr, settings) {
                xhr.setRequestHeader('Authorization', 'Token ' + token);
            },
            url: globals.SERVER + "/api/v1/password/reset/",
            method: "POST",
            data: user_data
        });

        request.done(function (response) {
            this.showPasswordResetMessage(response.message);
        }.bind(this));

        request.fail(function (jqXHR, textStatus) {
            setTimeout(() => {

                try {
                    this.showPasswordResetMessage(jqXHR.responseJSON.message);
                } catch (err) {
                    this.showPasswordResetMessage('Failed to get response from TeloPoint at this time.');
                }

            }, 2000);

        }.bind(this));

    };


    showPasswordResetMessage(message) {
        this.setState({
            ...this.state,
            aboutDialogOpened: false,
            changePasswordDialogOpened: true,
            changePasswordLoadingDialogOpened: false,
            changePasswordMessage: message
        });
    };

    handleProfileSettings = () => {
        // window.location.href = '/test';
    };

    handleOpenDialog = () => {
        this.setState({
            ...this.state,
            aboutDialogOpened: true
        });
    };

    handleCloseDialog = () => {
        this.setState({
            ...this.state,
            aboutDialogOpened: false,
            changePasswordDialogOpened: false,
            upToDateDialogOpened: false,
        });
    };

    handleLogoutClick(e) {        
        this.setDeleteNotesDialogHandler(
            () => this.props.history.push("/logout/user"), 
            ()=>{
                 this.setDeleteNoteDialogVisible(false)
                // this.setNotesDialogVisibility(false,EDIT)
                }
                 ,
            "Logout Confirmation",
            "Are you sure you want to logout?",
            "Logout",
            ()=> this.setDeleteNoteDialogVisible(true), 
        )
    }

    handleUpdateClick(e) {
        console.log("Updating...")
        console.log("{ action: 'skipWaiting' }")

        if ('newServiceWorker' in navigator) {
            helper.updateApp()
        } else {
            this.setState({
                ...this.state,
                upToDateDialogOpened: true
            });
        }
    }

    goTo(url) {
        this.props.history.push(url);
    }

    render() {
        const { classes } = this.props; // , newAppVersion

        return (
            <div className={classes.root}>
                <main className={classes.content}>
                    <div className={classNames(classes.mainContainer)}>
                        <ApplicationBar isInfoHidden={true} />
                        
                        <div className={classes.titleContainer}>
                            <Grid
                                container
                                alignItems="center"
                                justify="space-between"
                            >
                                <div>
                                    <Typography className={classes.title}>Settings</Typography>
                                </div>
                            </Grid>
                        </div>

                        <div className={classNames(classes.appointmentListContainer)}>
                            {/* <Typography className={classes.pageTitle}>Settings</Typography> */}
                            <div className={classNames(classes.settingsContent)}>
                                <List classes={{ root: classes.UL }}>

                                    <ListItem
                                        onClick={() => { this.goTo("/profile_settings") }}
                                        button className={classNames(classes.list)}>
                                        {/* <ListItemIcon>
                                            <DrawRequestOutlineSVG className={classes.imgIcon}></DrawRequestOutlineSVG>
                                            </ListItemIcon> */}
                                        <ListItemText classes={{ primary: classes.label }} primary="Profile Settings" />
                                        <ArrowForwardIosIcon className={classNames(classes.forwardIcon)}></ArrowForwardIosIcon>
                                    </ListItem>

                                    <ListItem
                                        onClick={() => { this.goTo("/change_password") }}
                                        // onClick={() => {this.handleChangePassword();}}
                                        button className={classNames(classes.list)}>
                                        {/* <ListItemIcon>
                                            <Icon className={classes.icon}>lock</Icon>
                                            </ListItemIcon> */}
                                        <ListItemText classes={{ primary: classes.label }} primary="Change Password" />
                                        <ArrowForwardIosIcon className={classNames(classes.forwardIcon)}></ArrowForwardIosIcon>
                                    </ListItem>

                                    <ListItem
                                        // onClick={() => {this.handleOpenDialog();}}
                                        onClick={() => { this.goTo("/about") }}
                                        button
                                        className={classNames(classes.list)}>
                                        <ListItemText classes={{ primary: classes.label }} primary="About" />
                                        <ArrowForwardIosIcon className={classNames(classes.forwardIcon)}></ArrowForwardIosIcon>
                                    </ListItem>

                                    {globals.DISPLAY_LOGS_PAGE &&
                                        <ListItem
                                            // onClick={() => {this.goTo("/logs");}}
                                            onClick={() => { this.goTo("/view_logs") }}
                                            button
                                            className={classNames(classes.list)}>
                                            {/* 
                                <ListItemIcon>
                                    <Icon className={classes.icon}>description</Icon>
                                </ListItemIcon> */}
                                            <ListItemText classes={{ primary: classes.label }} primary="Logs" />
                                            <ArrowForwardIosIcon className={classNames(classes.forwardIcon)}></ArrowForwardIosIcon>
                                        </ListItem>
                                    }

                                    <ListItem
                                        onClick={() => { this.goTo("/lock_screen"); }}
                                        button className={classNames(classes.list)}>
                                        {/* <ListItemIcon>
                                <Icon className={classes.icon}>lock</Icon>
                                </ListItemIcon> */}
                                        <ListItemText classes={{ primary: classes.label }} primary="Lock Screen" />
                                    </ListItem>

                                    {/* <ListItem
                                        onClick={() => { this.goTo("/NewPatient"); }}
                                        button
                                        className={classNames(classes.list)}>
                                        <ListItemText classes={{ primary: classes.label }} primary="Add New Patient" />
                                    </ListItem> */}

                                    {/* <ListItem
                                        onClick={() => { this.goTo("/Sample"); }}
                                        button
                                        className={classNames(classes.list)}>
                                        <ListItemText classes={{ primary: classes.label }} primary="Sample" />
                                    </ListItem> */}

                                    <ListItem
                                        onClick={this.handleLogoutClick.bind(this)}
                                        button
                                        className={classNames(classes.list)} style={{ borderBottom: "none" }}>
                                        {/* <ListItemIcon>
                                    <Icon className={classes.icon}>exit_to_app</Icon>
                                </ListItemIcon> */}
                                        <ListItemText classes={{ primary: classes.label }} primary="Logout" />
                                    </ListItem>
                                </List>
                            </div>
                        </div>

                        <Dialog
                            fullScreen={false}
                            classes={{
                                paper: classes.dialogPaper
                            }}
                            open={this.state.aboutDialogOpened}
                            onClose={this.handleCloseDialog}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">{globals.ABOUT_TITLE}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {globals.ABOUT_CONTENT} <br />
                                    Version : {globals.APP_VERSION}<br />
                                    Code : {helper.getCodeVersion()}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCloseDialog} className={classes.buttons} variant="contained">Close</Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            fullScreen={false}
                            classes={{
                                paper: classes.dialogPaper
                            }}
                            open={this.state.upToDateDialogOpened}
                            onClose={this.handleCloseDialog}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogContent>
                                <DialogContentText>App is already up-to-date.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCloseDialog} className={classes.buttons} variant="contained">Close</Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            fullScreen={false}
                            classes={{
                                paper: classes.miniDialogPaper
                            }}
                            open={this.state.changePasswordLoadingDialogOpened}
                            onClose={this.handleCloseDialog}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">Change Password</DialogTitle>
                            <DialogContent>
                                <MiniLoadingSpinner />
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            fullScreen={false}
                            classes={{
                                paper: classes.miniDialogPaper
                            }}
                            open={this.state.changePasswordDialogOpened}
                            onClose={this.handleCloseDialog}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">Change Password</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {this.state.changePasswordMessage}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCloseDialog} className={classes.buttons} variant="contained">Close</Button>
                            </DialogActions>
                        </Dialog>

                        <ConfirmDialog 
                            visible = {this.state.setDeleteNotesDialog} 
                            title = {this.state.confirmDialogTitle} 
                            message = {this.state.confirmDialogMessage} 
                            actionText= {this.state.confirmDialogActionText} 
                            actionHandler={this.state.actionHandler} 
                            postActionHandler={this.state.postActionHandler} 
                            onClose={() =>{ 
                            //console.log("ConfirmDialog On close")
                                setTimeout(() => {
                                    this.setDeleteNoteDialogVisible(false);
                                }, 100);

                            } }
                         />

                        <div className={classes.toolbar} />
                        <div className={classes.toolbar} />
                    </div>
                </main>

                <SimpleBottomNavigation />
            </div>
        );
    }
}

SettingsClass.propTypes = {
    classes: PropTypes.object.isRequired,
};

const Settings = connect(mapStateToProps)(
    withCookies(withMobileDialog()(
        withStyles(styles)(SettingsClass))
    )
);

export default Settings;
