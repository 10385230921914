import React from 'react';

//import ListItem from '@material-ui/core/ListItem';
//import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment-timezone';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import * as helper from 'components/common/Helper.js';

//import Paper from '@material-ui/core/Paper';

const loadFutureOrder = (props, value, viewType) => {
    if(value === undefined) return;
    goTo(props, `/future_order_details/${value}/${viewType}`);
} 

const goTo = (props, url) => {
    props.history.push(url);
}

const FutureOrdersScroller = (props) => {

    const {classes, futureOrders, futureOrderDate, viewType } = props;

    var list = [];
    var ctr = 0;
    var currentPointer = 0;
    
    var sorted_fo = helper.sortFutureOrdersObjectByKey(futureOrders.future_orders, 'asc');
        
    for ( var item in sorted_fo) {
        //console.log(item)
        if(futureOrders.future_orders[item].draw_list === undefined || futureOrders.future_orders[item].draw_list.length === 0) {
            continue;
         } else {
            
            if(item === futureOrderDate) currentPointer = ctr;

            ctr++;

            list.push(item);
         }
        
    }
    
    var backDate = list[(currentPointer-1)];
    var nextDate = list[(currentPointer+1)];

    if(backDate !== undefined){

    } else {

    }

    if(nextDate !== undefined){

    } else {
        
    }


    return(
        <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.futureOrderGrid}>
            <Grid item>
                <Button 
                    disabled={backDate === undefined ? true : false}  
                    onClick={loadFutureOrder.bind(null,props,backDate, viewType)} 
                    className={backDate === undefined ? classes.blueButtonDisabled : classes.blueButton}
                    classes={{
                        root: classNames(
                            classes.switchViewButton,
                            classes.switchViewButtonLeft,
                            classes.switchViewButtonRight
                        ),
                        label: classes.switchViewButtonLabel
                    }}>
                    <Icon>chevron_left</Icon>
                </Button>
            </Grid>
            <Grid item>
                <Typography 
                    className={classes.scrollHeaderText} 
                    // noWrap={true}
                    >
                    {/* {moment(futureOrderDate,'YYYY-MM-DD').format( "MMMM D, YYYY")} {"(" + (currentPointer + 1) +" of "+list.length + ")"}  */}
                    {moment(futureOrderDate,'YYYY-MM-DD').format( "MMMM D, YYYY")} 
                </Typography>
            </Grid>
            <Grid item>
                <Button  
                    
                    onClick={loadFutureOrder.bind(null,props,nextDate, viewType)} 
                    className={nextDate === undefined ? classes.blueButtonDisabled : classes.blueButton}
                    classes={{
                        root: classNames(
                            classes.switchViewButton,
                            classes.switchViewButtonLeft,
                            classes.switchViewButtonRight
                        ),
                        label: classes.switchViewButtonLabel
                    }}>
                    <Icon>chevron_right</Icon>                    
                </Button>
            </Grid>
        </Grid>
        );
}

export default FutureOrdersScroller;
