import React, { useLayoutEffect } from 'react'
// import classNames from 'classnames'

import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import CameraAltIcon from '@material-ui/icons/CameraAlt'
import Grid from '@material-ui/core/Grid'

import $ from 'jquery'
import uuid from 'uuid'
import moment from 'moment'
import * as globals from 'components/common/GlobalConstants'
// import testImage from './test-images/step3-venipuncture-site.PNG'

export const VenipunctureDropdown = (props) => {
    const { isOrderRemaining, classes, selectVenipuncture, selectedValue } = props

    const venipuncture_sites = [
        "Right Hand",
        "Left Hand",
        "Right Arm",
        "Left Arm"
    ]

    return (
        <>
            <Typography className={classes.venipuctureSelectLabel}>Venipuncture Site</Typography>
            <FormControl variant="outlined" className={classes.venipuctureFormControl}>
                <Select
                    id="select"
                    displayEmpty
                    onChange={(event) => {
                        selectVenipuncture(event.target.value)
                    }}
                    className={classes.venipuctureSelect}
                    disableUnderline
                    value={selectedValue ? selectedValue : ""}
                    disabled={!isOrderRemaining}
                >
                    <MenuItem value="">
                        <Typography className={classes.venipuctureMenuItemPlaceholder}>Select site</Typography>
                    </MenuItem>
                    {venipuncture_sites.map((site) => {
                        return <MenuItem key={uuid.v4()} value={site}>{site}</MenuItem>
                    })}
                </Select>
            </FormControl>
        </>
    )
}

export const TapHere = (props) => {
    const { classes, isOrderRemaining } = props

    // https://reactjs.org/docs/hooks-reference.html#uselayouteffect
    useLayoutEffect(() => {
        $("#tap-here-button").height($("#tap-here-button").width() * 1.1)
    }, [])

    return (
        <Grid
            id="tap-here-button"
            container
            justify="center"
            alignItems="center"
            className={classes.tapHereContainer}
        // onClick={() => {
        //     simulateCapturePhoto()
        // }}
        >
            <div style={{ textAlign: "center" }}>
                <CameraAltIcon className={classes.tapHereIcon} />
                <Typography className={classes.tapHereLabel}>
                    {isOrderRemaining ? "Tap here to take a photo": "No photo"}
                </Typography>
            </div>
        </Grid>
    )
}

export const CapturedPhotoDisplay = (props) => {
    const { classes, data } = props
    // console.log("data")
    // console.log(data)
    // const item = data.item
    // const date = moment.unix(item.client_timestamp).tz(item.client_timezone).format("MMM D, YYYY")
    // const time = moment.unix(item.client_timestamp).tz(item.client_timezone).format("hh:mma")
    // const filename = item.filename
    // const image = item.metadata.image.data

    const item = data.item

    var date = ""
    var time = ""

    try {
        date = moment.unix(item.client_timestamp).tz(item.client_timezone).format("MMM D, YYYY")
        time = moment.unix(item.client_timestamp).tz(item.client_timezone).format("hh:mma")
    } catch (e) {
        console.log("Error here")
        console.log(e)
    }

    const filename = item.filename
    var image = item.metadata.image.data

    if (item.doc_type === "offline_uploaded" && item.path !== undefined) {
        image = (globals.USE_PAMP_COUCHDB_SERVER) ? item.path : globals.SERVER + item.path
    }

    return (
        <div className={classes.capturedDataContainer}>
            <Grid
                container
                direction="row"
                justifycontent="flex-start"
                wrap="nowrap"
            >
                <div className={classes.capturedImageDiv}>
                    <img src={image} className={classes.capturedImage} alt={filename} />
                </div>
                <div>
                    <Typography className={classes.capturedDataDateTimeLabel}>{date} | {time}</Typography>
                    <Typography className={classes.capturedDataMainNote}>{filename}</Typography>
                </div>
            </Grid>
        </div>
    )
}

// export const CapturedPhoto = (props) => {
//     const { classes } = props

//     useLayoutEffect(() => {
//         $("#venipuncture-image-container").height($("#venipuncture-image-container").width() * 1.1)
//     }, [])

//     return (
//         <div id="venipuncture-image-container">
//             <img src={testImage} className={classes.venipunctureCapturedPhoto} alt="test image only" />
//         </div>
//     )
// }