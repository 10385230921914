import React, { useState, useEffect, useRef } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
// import classNames from 'classnames'

import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import PhotoCapture2 from 'components/common/mobile/PhotoCapture2'
import * as globals from 'components/common/GlobalConstants'
import {
    TapHere,
    CapturedPhotoDisplay,
} from './Step5Components.js'
import uuid from "uuid"

const mapStateToProps = state => {
    return {
        drawData: state.drawData,
        workflowData: state.workflowData,
        drawRequestDocumentsData: state.drawRequestDocumentsData,
    }
}

const Step5FunctionClass = (props) => {
    const {
        isOrderRemaining,
        classes,
        onSetBtnNextDisabled,
        // onChangeData,
        drawRequestDocumentsData,
        siteId,
        patientId,
        patientDrawId
    } = props

    const photoCaptureRef = useRef(null)
    const patientReadOnly = false

    // const [noCapturedPhoto, setNoCapturedPhoto] = useState(true)

    // const simulateCapturePhoto = () => {
    //     setNoCapturedPhoto(false)
    //     // to enable next button going Step 6
    //     onSetBtnNextDisabled(false)
    //     onChangeData({
    //         imageBlob: "mocked-" + uuid.v4()
    //     })
    // }

    const medicationContainerLabel = "Completed Medication Container"

    var patientDocs = []
    var medicationContainerReqDoc = null
    if (drawRequestDocumentsData.draw_request_documents) {
        patientDocs = drawRequestDocumentsData.draw_request_documents.filter(function (draw_req_doc) {
            return draw_req_doc.drawlist_site_id === siteId && draw_req_doc.drawlist_patient_id === patientId
        })
    }
    // console.log("patientDocs")
    // console.log(patientDocs)

    // filter Completed Medication Container Category req doc
    // undefined if no result
    medicationContainerReqDoc = patientDocs.find(doc =>
        doc.metadata.image &&
        doc.metadata.image.category &&
        doc.client_deleted === false &&
        doc.metadata.image.category === medicationContainerLabel
    )

    // console.log("medicationContainerReqDoc")
    // console.log(medicationContainerReqDoc)

    const [dataUri, setDataUri] = useState("") // load redux dataUri
    const [id, setID] = useState("") // load redux id

    useEffect(() => {

        var imgDoc = ""

        if (medicationContainerReqDoc) {
            if (medicationContainerReqDoc.doc_type == "offline_upload") {
                imgDoc = medicationContainerReqDoc.metadata.image.data
            } else if (medicationContainerReqDoc.doc_type == "offline_uploaded") {
                imgDoc = (globals.USE_PAMP_COUCHDB_SERVER) ? medicationContainerReqDoc.path : globals.SERVER + medicationContainerReqDoc.path
            }
        }

        setID(medicationContainerReqDoc ? medicationContainerReqDoc.id : "")
        setDataUri(medicationContainerReqDoc ? imgDoc : "")
    }, [medicationContainerReqDoc, setDataUri])

    useEffect(() => {
        if (isOrderRemaining) {
            if (dataUri !== "") {
                onSetBtnNextDisabled(false)
            } else {
                onSetBtnNextDisabled(true)
            }
        }
    }, [dataUri, isOrderRemaining, onSetBtnNextDisabled])

    useEffect(() => {
        // force enable after done/unable
        if (!isOrderRemaining) {
            onSetBtnNextDisabled(false)
        }
    }, [isOrderRemaining, onSetBtnNextDisabled])

    useEffect(() => {
        // CALL YOUR API OR ASYNC FUNCTION HERE
        // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function
        return () => { photoCaptureRef.current = false }
    }, [])

    return (
        <>
            <div
                className={classes.medicationPhotoContainer}
            >
                {dataUri === "" || (typeof medicationContainerReqDoc === 'undefined' || medicationContainerReqDoc === null)
                    ?
                    <div
                        onClick={() => {
                            if (isOrderRemaining) {
                                photoCaptureRef.current.addNewPhoto({ id: id, category: medicationContainerLabel })
                            }
                        }}
                    >
                        <TapHere classes={classes} isOrderRemaining={isOrderRemaining} />
                    </div>
                    :
                    <div
                        onClick={() => {
                            photoCaptureRef.current.addNewPhoto({ id: id, category: medicationContainerLabel, dataUri: dataUri })
                        }}
                    >
                        <CapturedPhotoDisplay
                            key={uuid.v4()}
                            classes={classes}
                            data={{ item: medicationContainerReqDoc }}
                        />
                    </div>
                }
            </div>

            <PhotoCapture2
                // key={uuid.v4()} // needed to assign addNewPhoto to photoCaptureRef
                photoCaptureRef={photoCaptureRef}
                siteId={siteId}
                patientId={patientId}
                patientDrawId={patientDrawId}
                notes={null}
                target="patient"
                draw_request_documents={drawRequestDocumentsData}
                // addNewPhoto={m => photoCaptureRef.addNewPhoto = m}
                // deletePhoto={m => photoCaptureRef.deletePhoto = m}
                readOnly={patientReadOnly || !isOrderRemaining}
            />
        </>
    )
}

const Step5 = withRouter(
    withCookies(
        connect(mapStateToProps)(
            withStyles(styles, { withTheme: true })(Step5FunctionClass)
        )
    )
)
export default Step5
