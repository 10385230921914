import * as globals from 'components/common/GlobalConstants';

const styles = theme => ({
    root: {
        // display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        paddingLeft: 8,
        paddingRight: 8,
    },
    overrideBlue: {
        background: globals.BLUE_GRADIENT_BG,
    },
    headerInfo: {
        paddingTop: "15px",
        paddingLeft: "15px",
        paddingRight: "15px",
    },
    scheduleContainer: {
        padding: "15px",
    },
    list: {
      border: "1px solid rgba(0, 0, 0, 0.16)",
      marginBottom: theme.spacing.unit,
      fontSize: "12px !important",
    },
    label: {
        fontSize: "14px !important",
        color: "#707070",
    },
    // button: {
    //     fontSize: "14px !important",
    //     textTransform: "none",
    //     border: "none",
    //     borderRadius: 0,
    //     padding: "6px 16px"
    // },
    customApplicationBarHeader: {
        padding: "7px 17px",
        marginLeft: "-15px",
        marginRight: "-15px"
    },
    listULMainHeader: {
        marginTop: 25
    },
    listLI: {
        paddingLeft: '7px !important'
    },
    schedulesMainHeader: {
        color: "#707070 !important",
        fontSize: '24px !important',
        fontWeight: 'bold',
        padding: "0px !important",
        margin: "0px !important"
    },
    schedulesMainContainer: {
        margin: 15, 
        // height: "50em"
    },
    mapDiv: { 
        marginTop: 7,
    },
    listUL: {
        paddingTop: 0,
        paddingBottom: 0
    },
    settingsContent: {
        paddingLeft: 16,
        paddingRight: 16,
    },
    textFieldContainer: {
        padding: 0
    },
    listItemRoot: {
        padding: "0px !important",
        // border: "1px solid black",
    },
    futureOrderDrawList: {
        //  border: "1px solid blue",
        //  height: "6em",
          margin: "0px !important",
          padding: "0px !important",
          marginBottom: "0px !important",
          //margin: "10px 0px !important",
          //padding: "5px 11px",      
         // boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 2px 10px 0 rgba(0, 0, 0, 0.11)',
    },

    siteAddress: {
        margin: "20px 0px 1px 0px !important",
        textAlign: "center"
    },

    siteAddress2: {
        marginBottom: "20px !important",
        textAlign: "center"
    },

    imgIcon: {
        width: 20,
        height: 20
    },
    drawItem: {
        margin: "0px !important",
        padding: "0px  !important",
        marginLeft: "35px !important",
        marginBottom: "5px"
        
    },
    futureOrdersListPrimary: {
        fontWeight: "bold",
        fontSize: "18px !important",
        color: "#707070 !important",
        
    },
    futureOrdersListSecondary: {
        //fontWeight: "bold",
        fontSize: "12px !important",
        color: "#707070 !important",
        
    },
    workTerritoryRoot: {
        fontSize: 14,
        padding: "0px !important",
        border: "1px solid black !important",
        marginRight: 47
    },
    futureOrderGrid: {
        paddingLeft: 8,
        paddingRight: 8,
        marginBottom: 25
    },
    workTerritoryInput: {
        padding: "7px 10px !important",
    //     fontSize: 14,
    //     padding: "0px 10px !important",
    //     border: "1px solid black !important",
    //     borderRadius: "0px !important"
    },
    workTerritoryNote: {
        fontSize: "12px !important",
        margin: "10px 0px"
    },
    clearButton: {
        color: "#767676",
        marginRight: 5
    },
    addbutton: {
        fontWeight: "bold"
    },
    futureOrderLink: {
        padding: "4px !important",
        fontWeight: "bold",
        color: "white !important",
        backgroundColor: "#68CBEF !important",
    },
    blueButton: {
        minWidth: 36,
        width: 36,
        
        padding: "4px !important",
        fontWeight: "bold",
        color: "white !important",
        backgroundColor: "#68CBEF !important",
    },
    blueButtonDisabled: {
        minWidth: 36,
        width: 36,
        
        padding: "4px !important",
        fontWeight: "bold",
        color: "#EEEEEE !important",
        backgroundColor: "#CFCFCF !important",
    },
    listPaper: {
        marginTop: 30
    },
    listRoot: {
        padding: 0
    },
    listOdd: {
        backgroundColor: "#F4F4F4"
    },
    listHeader: {
        fontSize: 10,
        color: "white !important",
        backgroundColor: "#68CBEF !important",
        padding: "4px 16px"
    },
    listContent: {
        color: "#767676",
        fontSize: 14,
        padding: "8px 16px"
    },
    switchViewButton: {
        backgroundColor: "white",
        color: "#777777",
        borderRadius: 0,
        padding: "2px 3px",
        minWidth: 40,
        paddingLeft: 10,
        paddingRight: 10,
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 2px 10px 0 rgba(0, 0, 0, 0.11)',
        '&:not([class*="switchViewButtonSelected"]):not([class*="blueButton"]):hover':{
            backgroundColor: "#e0e0e0 !important",
        }
    },
    switchViewButtonSelected: {
        backgroundColor: "#68CBEF !important",
        color: "white",
    },
    switchViewButtonLeft: {
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
    },
    switchViewButtonRight: {
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
    },
    googleMapContainer: {
        // width: "100%",
        padding: 10,
    },
    futureOrdersListContainer: {
        padding: 8,
        paddingTop: 0       
    },
    patientListItems:{
        marginBottom: "10px",
        padding: "14px 16px",    
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 2px 10px 0 rgba(0, 0, 0, 0.11)',

    },
    printListCont: {
        marginRight: 0
    },
    printIconCont: {
        padding: 8,
        border: 'none',
        backgroundColor: 'white'
    },
    printIcon: {
        width: 20,
        height: 20,
    },
    switchViewButtonLabel: {
        textTransform: 'capitalize'
    },
    backIcon: {
        marginTop: "-3px"
    }
});

export default styles;
