import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import classNames from 'classnames';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import $ from 'jquery';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Icon from '@material-ui/core/Icon';

import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { connect } from "react-redux";
import { 
    updatePatientDrawStatus,
    verifyPatientName,
    verifyPatientDoB
} from "js/actions/index";
import MDSpinner from "react-md-spinner";

import styles from "./styles.js";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow';
import CommonDialog from "./CommonDialog";

import ApplicationBar from 'components/common/tablet/ApplicationBar';
import SimpleBottomNavigation from 'components/common/tablet/SimpleBottomNavigation';
import NoDrawListScreen from 'components/common/tablet/NoDrawListScreen.js';
import CurrentDate from 'components/common/tablet/CurrentDate.js';

import moment from 'moment-timezone';

const mapDispatchToProps = dispatch => {
  return {
    updatePatientDrawStatus: drawItem => dispatch(updatePatientDrawStatus(drawItem)),
    verifyPatientName: verifiedPatientNameData => dispatch(verifyPatientName(verifiedPatientNameData)),
    verifyPatientDoB: verifiedPatientDoBData => dispatch(verifyPatientDoB(verifiedPatientDoBData)),
  };
};

const mapStateToProps = state => {
  return { drawData: state.drawData }
};


function TabContainer({ children, dir }) {
    return (
        <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
        {children}
        </Typography>
    );
}
  
TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired,
};

class PatientClass extends Component{
  
    constructor(props) {
        super(props);

        const dialogdata = {
            "save_status_done" : {"title":"Patient Draw Save","content": "Continue saving patient draw status to done?","buttons" : ["Continue","Cancel"],"visible": false, eventHandler: this.onSaveStatusDone},
            "reset_draw_status" : {"title":"Reset Patient Draw","content": "Continue resetting patient draw status?","buttons" : ["Continue","Cancel"],"visible": false, eventHandler: this.onResetStatus}
        };

        this.state = {...this.state,
            siteId : "",
            patientId: "",
            dialogOpenedPatientRefused: false,
            dialogOpenedPrintBarcodes: false,
            // selectedPatientDraw: "",
            drawItemStatus: "remaining",
            drawItemStatusReason: "",
            tabValue: 0,
            isPatientRefusesUnableToDraw: false,
            drawSiteValue: "right_arm",
            dialogdata : dialogdata,
            printBarcodesInfo: {"current": 0, "total": 0}
        };
        this.selectedPatientDraw = undefined;
        this.drawSiteValue = "right_arm";
        this.modifiedDrawSite = false;
        console.log("PatientClass: constructor");
    }
  
    componentWillMount() {
        // console.log('PatientClass: componentWillMount.');        
      
        this.setState({...this.state, 
            patientId: this.props.match.params.patientId , 
            siteId: this.props.match.params.siteId });
    }

    // temporary solution
    refreshPropsData() {

    }

    componentWillUpdate() {
        // console.log('PatientClass: componentWillUpdate.');
    }
    
    handleOpenDialog =  event => {
        this.setState({...this.state, dialogOpenedPatientRefused: true});
    };
  
    handleCloseDialogPatientRefused = () => {
        this.setState({...this.state, dialogOpenedPatientRefused: false });
    };

    handleChangeDrawItemStatus = event => {
        this.setState({ ...this.state, drawItemStatus: event.target.value });
    };

    handleTabChange = (event, tabValue) => {
      this.setState({...this.state, tabValue });
    };
  
    handleTabChangeIndex = index => {
      this.setState({...this.state, tabValue: index });
    };

    handlePatientIdentityVerificationCheckboxChange = name => event => {
        if(name === "isPatientNameVerified") {
            const patientNamePayload = {
                "siteId": this.state.siteId ,
                "patientId":  this.state.patientId, 
                "patientDrawId": this.selectedPatientDraw.id,
                "verified": event.target.checked,
            };
            this.props.verifyPatientName(patientNamePayload);
        } else if(name === "isPatientDoBVerified") {
            const patientDoBPayload = {
                "siteId": this.state.siteId ,
                "patientId":  this.state.patientId, 
                "patientDrawId": this.selectedPatientDraw.id,
                "verified": event.target.checked,
            };
            this.props.verifyPatientDoB(patientDoBPayload);
        }
    };

    handleOnShowDialogPatientRefused = () => {
        this.setState({...this.state, 
            drawItemStatus: this.selectedPatientDraw.status });
    };
    
    handleOnContinueDraw = () => {
        this.setState({...this.state, tabValue : 1 });
    };
    
    // handleOnBackToIdentityVerification = () => {
    //     this.setState({...this.state, tabValue : 0 });
    // };

    handleSubmitRefuseReason = event => {
        var refused_reason_jQuery = $('#patient-unable-to-draw-reason').val();
        var patient_refused_unable =  $('[name=draw_item_option]:checked').val();
        const payload = {
          "siteId": this.state.siteId ,
          "patientId":  this.state.patientId, 
          "patientDrawId": this.selectedPatientDraw.id,
          "type": "p", 
          "status":  patient_refused_unable,
          "notes": refused_reason_jQuery
        };
        this.props.updatePatientDrawStatus(payload);
        this.setState({...this.state, skipReasonDialogOpened: false, dialogOpenedPatientRefused: false});

        this.goBackToSite();
    };


    showCommonDialog = (id) => {
        var dialogdata = this.state.dialogdata;
        dialogdata[id].visible = true;
        this.setState({...this.state, dialogdata : dialogdata});
    };

    onSaveStatusDone = (button) => {
        if(button === 'Continue') {
            this.handleDrawDone();
        }
    };

    handleDrawDone = event => {
        const payload = {
            "siteId": this.state.siteId ,
            "patientId":  this.state.patientId, 
            "patientDrawId": this.selectedPatientDraw.id,
            "type": "p", 
            "status":  "done",
            "notes": "",
            "draw_source": this.drawSiteValue,
        };
        this.modifiedDrawSite = false;
        this.props.updatePatientDrawStatus(payload);
        this.setState({...this.state, skipReasonDialogOpened: false});

        this.goBackToSite();
    };

    goBackToSite() {
        this.props.history.push('/site/' + this.props.match.params.siteId);
    };

    onResetStatus = (button) => {
        if(button === 'Continue') {
            this.handleResetDraw();
        }
    };
    
    handleResetDraw = event => {
        console.log('PatientClass: handleResetDraw.');
        const payload = {
            "siteId": this.state.siteId ,
            "patientId":  this.state.patientId, 
            "patientDrawId": this.selectedPatientDraw.id,
            "type": "r", 
            "status":  "remaining", // default status
            "notes": ""
        };
        this.modifiedDrawSite = false;
        this.props.updatePatientDrawStatus(payload);
    };

    handleChangeDrawSite = (event, value) => {
        if(this.selectedPatientDraw.status === "remaining") {
            this.drawSiteValue = value;
            this.modifiedDrawSite = true;
            this.setState({...this.state});
        }
    };

    handleOpenPrintBarcodesDialog = event => {
        this.setState({...this.state, printBarcodesInfo: {"current": 1, "total": 3}, dialogOpenedPrintBarcodes: true});
        // workaround      

        setTimeout(function() { 
            this.setState({...this.state, printBarcodesInfo: {"current": 2, "total": 3}});       
        }.bind(this), 2000);
        setTimeout(function() { 
            this.setState({...this.state, printBarcodesInfo: {"current": 3, "total": 3}});       
        }.bind(this), 3000);
        setTimeout(function() { 
            this.handleClosePrintBarcodesDialog(); 
        }.bind(this), 4000);
    };

    handleClosePrintBarcodesDialog = event => {
        this.setState({...this.state, dialogOpenedPrintBarcodes: false});
    };

    pad(n) {
        return (n < 10) ? ("0" + n) : n;
    }

    // formatDate(date) {
    //     var currentDate = new Date(date),
    //     day = currentDate.getDate(),
    //     month = currentDate.getMonth(),
    //     year = currentDate.getFullYear();
        
    //     // var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
       
    //     // return months[month] + " " + day + ", " + year;
    //     return this.pad(month + 1) + "/" + this.pad(day) + "/" + year;
    // };

    render() {
        // console.log('PatientClass: render');
        const { classes, theme } = this.props;
        const drawData = this.props.drawData;  
        
        if($.isEmptyObject(drawData.draw_list)) {
          return(<NoDrawListScreen />);
        }
     
        const siteId = this.state.siteId;
        const patientId =  this.state.patientId;
        
        const theSite = drawData.draw_list.filter(function(site) {
           return(site.id === siteId); 
        });
        
        const patient = theSite[0].patient_list.filter(function(patient) {
           return(patient.id === patientId); 
        });
       
        this.selectedPatientDraw =  patient[0].patient_draw[0];

        const patientName = patient[0].patient.last_name + ", " + patient[0].patient.first_name + " " + patient[0].patient.middle_name;
        const patientAddress = patient[0].patient.address + ", " + patient[0].patient.state + " " + patient[0].patient.zipcode;
        const isPatientNameVerified = patient[0].patient_draw[0].name_verified;
        const isPatientDoBVerified = patient[0].patient_draw[0].dob_verified;
        // console.log("this.modifiedDrawSite");
        // console.log(this.modifiedDrawSite);

        if(!this.modifiedDrawSite) {
            this.drawSiteValue = this.selectedPatientDraw.draw_source === "" ? "right_arm" : this.selectedPatientDraw.draw_source;
        }
        const oldURL = "/site/" + siteId;
        
        const DrawItem = ({drawItem, colors}) => (
            <TableRow key={drawItem.id}>
                <TableCell className={classes.patientIdentityCellCont}>
                    <Typography>{drawItem.name}</Typography>
                </TableCell>
                <TableCell className={classes.patientIdentityCellCont}>
                    { (colors[0] !== null && colors[0] !== undefined) &&
                    Object.keys(colors[0]).map(key =>
                        <Typography key={key + colors[0][key]}>{key + ": " + colors[0][key]}</Typography>
                    )
                    }
                </TableCell>
            </TableRow>
        );

        const drawItemsList = patient[0].patient_draw[0].draw_test.map(drawItem =>{
            var test_colors = drawItem.test.color;//(drawItem.test.color instanceof Array) ? drawItem.test.color : JSON.parse(drawItem.test.color);
            
            // console.log("colors");
            // console.log(test_colors);
            return(<DrawItem key={drawItem.test.id} drawItem={drawItem.test} colors={test_colors} />);
        });

        return(
            <div className={classes.root}>
                <main className={classes.content}>
                    <ApplicationBar back={oldURL} icon="person" title={patientName} />

                    { patient[0].urgent &&
                        <Typography 
                        className={classNames(classes.margins,classes.STAT)}
                        variant="button">STAT</Typography>
                    }
                    <div className={classes.margins}>
                        {/* <Typography><Icon className={classes.icon}>date_range</Icon> {this.formatDate(patient[0].patient.birth_date)}</Typography>  */}
                        <Typography className={classes.patientAddress}>{patientAddress}</Typography> 
                        <CurrentDate textAlign="right" />

                        <Grid
                            container
                            direction="column"
                            justify="center"
                            className={classes.gridContainer}>

                            <Grid item xs={12}>
                                <Tabs
                                    className={classes.swipeableTab}
                                    value={this.state.tabValue}
                                    onChange={this.handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    fullWidth
                                    >
                                    <Tab 
                                        label="Patient Identity Verification"
                                        className={classes.tabContainer}
                                        classes={{
                                            label: classes.patientIdentityTab
                                        }} />
                                    {isPatientDoBVerified && isPatientNameVerified  &&
                                    <Tab 
                                        label="Patient Draw"
                                        className={classes.tabContainer}
                                        classes={{
                                            label: classes.patientIdentityTab
                                        }}
                                    />
                                    }
                                </Tabs>
                                <SwipeableViews
                                    className={classes.swipeableContent}
                                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                    index={this.state.tabValue}
                                    onChangeIndex={this.handleTabChangeIndex}
                                    >
                                    <TabContainer dir={theme.direction}>
                                        <Table className={classes.table}>
                                            <TableBody>
                                                <TableRow key='1xxx'>
                                                    <TableCell className={classes.patientIdentityCellCont}>
                                                        <Typography className={classes.patientIdentityText}>
                                                        Patient Name
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.patientIdentityCellCont} numeric>
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon className={classes.patientCheckbox} />} 
                                                            checkedIcon={<CheckBoxIcon className={classes.patientCheckbox} />} 
                                                            checked={isPatientNameVerified}
                                                            onChange={this.handlePatientIdentityVerificationCheckboxChange('isPatientNameVerified')}
                                                            value="isPatientNameVerified"
                                                            />
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow key='2xxx'>
                                                    <TableCell className={classes.patientIdentityCellCont}>
                                                        <Typography className={classes.patientIdentityText}>
                                                            Patient Date of Birth
                                                        </Typography>
                                                        <Typography className={classes.patientIdentityText}>
                                                            {moment(patient[0].patient.birth_date, "YYYY-MM-DD").format('MM/DD/YYYY')}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.patientIdentityCellCont} numeric>
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon className={classes.patientCheckbox} />} 
                                                            checkedIcon={<CheckBoxIcon className={classes.patientCheckbox} />} 
                                                            checked={isPatientDoBVerified}
                                                            onChange={this.handlePatientIdentityVerificationCheckboxChange('isPatientDoBVerified')}
                                                            value="isPatientDoBVerified"
                                                            />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    <br/>                                       
                                        <Grid
                                            container
                                            alignContent="center"
                                            direction="column"
                                            justify="center"
                                            alignItems="center">

                                            <Grid item xs={12}>
                                            {isPatientDoBVerified && isPatientNameVerified &&
                                                <Button  
                                                    variant="contained"
                                                    onClick={this.handleOnContinueDraw} 
                                                    className={classNames(classes.margins, classes.mainButton)}>
                                                    Continue
                                                </Button> }
                                            { this.selectedPatientDraw.status === "remaining" &&
                                            <Button 
                                                variant="contained"
                                                onClick={this.handleOpenDialog} 
                                                className={classNames(classes.mainButton, classes.margins)}
                                                >Unable to draw</Button>
                                            }
                                            </Grid>
                                        </Grid>
                                    </TabContainer>
                                    <TabContainer dir={theme.direction}>
                                        <div>
                                            <Typography 
                                                align="left"    
                                                variant="h6">
                                                Draw Site on Patient
                                            </Typography>

                                            <br/>
                                            <Paper square>
                                                <Tabs
                                                    value={this.drawSiteValue}
                                                    onChange={this.handleChangeDrawSite}
                                                    fullWidth={true}
                                                    indicatorColor="secondary"
                                                    textColor="secondary"
                                                    >
                                                    <Tab classes={{label: classes.tabLabel}} value="right_arm" label="Right Arm" />
                                                    <Tab classes={{label: classes.tabLabel}} value="left_arm" label="Left Arm" />
                                                    <Tab classes={{label: classes.tabLabel}} value="right_hand" label="Right Hand" />
                                                    <Tab classes={{label: classes.tabLabel}} value="left_hand" label="Left Hand" />
                                                    {/* <Tab icon={<DoneIcon  />} value="others" label="Others" /> */}
                                                </Tabs>
                                            </Paper>
                                            <br/>
                                            <br/>

                                            <Typography 
                                                align="left"    
                                                variant="h6">
                                                Draw Tests
                                            </Typography>
                                            
                                            <Table className={classes.table}>
                                                <TableBody>
                                                {drawItemsList}
                                                </TableBody>
                                            </Table>
                                            <br/>
                                            <Grid
                                                container
                                                alignContent="center">
                                                <Grid item xs={12}>
                                                    <div className={classes.textCentered}>
                                                        { this.selectedPatientDraw.status !== "remaining" ? 
                                                        <div>
                                                            { this.selectedPatientDraw.status === "done" &&
                                                            <div>
                                                                <Button
                                                                    onClick={this.handleOpenPrintBarcodesDialog}
                                                                    className={classNames(classes.mainButton, classes.margins)}
                                                                    variant="contained"
                                                                    >Reprint Barcodes</Button>
                                                                <br />
                                                                <br />
                                                            </div>
                                                            }
                                                            <Paper className={classes.yellowPaperBG}>
                                                                <List component="nav">
                                                                    <ListItem>
                                                                        { this.selectedPatientDraw.status === "refused" 
                                                                        ?
                                                                        <ListItemText 
                                                                            variant="body1"
                                                                            classes={{
                                                                                primary: classes.statusNotes,
                                                                                secondary: classes.statusNotes,
                                                                            }}
                                                                            primary={"This patient is marked as: unable"}
                                                                            secondary={this.selectedPatientDraw.status_notes_p === "" ? "Reason: refused" : this.selectedPatientDraw.status_notes_p} />
                                                                        :
                                                                        <ListItemText 
                                                                            variant="body1"
                                                                            classes={{
                                                                                primary: classes.statusNotes,
                                                                                secondary: classes.statusNotes,
                                                                            }}
                                                                            primary={"This patient is marked as: " + this.selectedPatientDraw.status}
                                                                            secondary={["unable"].indexOf(this.selectedPatientDraw.status) > -1 ? "Reason: " + this.selectedPatientDraw.status_notes_p : ''} />
                                                                        }
                                                                    </ListItem>
                                                                    <ListItem>
                                                                        <ListItemText></ListItemText>
                                                                        <Button 
                                                                            onClick={ () => this.showCommonDialog('reset_draw_status')} 
                                                                            variant="contained"
                                                                            className={classes.resetButton}
                                                                            ><Icon>cached</Icon> Reset</Button>
                                                                    </ListItem>
                                                                </List>
                                                            </Paper>
                                                            <br/>
                                                            <Link to={oldURL}>
                                                                <Button className={classNames(classes.mainButton, classes.margins)} variant="contained">
                                                                    Go Back to Site Draws List
                                                                </Button>
                                                            </Link>
                                                        </div>
                                                        :
                                                        <div>
                                                            <Button
                                                                 variant="contained"
                                                                onClick={this.handleOpenPrintBarcodesDialog}
                                                                className={classNames(classes.mainButton, classes.margins)}
                                                                >Print Barcodes</Button>
                                                            <Button
                                                                 variant="contained"
                                                                onClick={ () => this.showCommonDialog('save_status_done')}
                                                                className={classNames(classes.mainButton, classes.margins)}
                                                                >Done</Button>
                                                        </div>
                                                        }
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </TabContainer>
                                </SwipeableViews>
                            </Grid>
                        </Grid> 
                    </div>      

                    <Dialog
                        fullScreen={false}
                        fullWidth={true}
                        maxWidth="xs"
                        open={this.state.dialogOpenedPatientRefused}
                        onClose={this.handleCloseDialogPatientRefused}
                        onEnter={this.handleOnShowDialogPatientRefused}
                        aria-labelledby="responsive-dialog-title"
                        >
                        <DialogTitle id="responsive-dialog-title">Patient is unable to draw</DialogTitle>
                        <DialogContent>
                            <FormControl fullWidth={true} component="fieldset">
                              
                                <RadioGroup
                                    aria-label="Please select an option." 
                                    name="draw_item_option"
                                    className={classes.group}
                                    value={this.state.drawItemStatus === "remaining" ? "refused_hard_stick" : this.state.drawItemStatus}
                                    onChange={this.handleChangeDrawItemStatus}
                                    id='patient_refused_unable'>

                                    <FormControlLabel value="refused" control={<Radio className={classes.radioButton} />} label="Refused" />
                                    <FormControlLabel value="refused_hard_stick" control={<Radio className={classes.radioButton} />} label="Hard Stick" />
                                    <FormControlLabel value="refused_other_reason" control={<Radio className={classes.radioButton} />} label="Other" />
                                    {/* <FormControlLabel value="unable" control={<Radio />} label="Others" /> */}
                                </RadioGroup>

                                { this.state.drawItemStatus === "refused_other_reason" &&
                                <TextField
                                    className={classes.content}
                                    hidden={!this.state.isPatientRefusesUnableToDraw}
                                    id="patient-unable-to-draw-reason"
                                    label="Please specify reason here..."
                                    multiline
                                    rows="4"
                                    margin="normal"
                                    defaultValue={this.selectedPatientDraw.status_notes_p}
                                    fullWidth={true}
                                    />
                                }
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.buttons}
                                onClick={this.handleSubmitRefuseReason}
                                hidden={!this.state.isPatientRefusesUnableToDraw}
                                > Submit</Button>
                            <Button 
                                variant="contained"
                                className={classes.buttons}
                                onClick={this.handleCloseDialogPatientRefused} 
                                color="default">Cancel</Button>
                        </DialogActions>
                    </Dialog>   
                    
                    <Dialog
                        fullScreen={false}
                        fullWidth={true}
                        maxWidth="xs"
                        open={this.state.dialogOpenedPrintBarcodes}
                        onClose={this.handleCloseDialogPatientRefused}
                        aria-labelledby="responsive-dialog-title"
                        >
                        <DialogTitle id="responsive-dialog-title">Printing Barcodes</DialogTitle>
                        <DialogContent>
                            <Grid
                                container
                                alignContent="center"
                                direction="column"
                                justify="center"
                                alignItems="center"
                                style={{height: '150px'}}
                                >
                                <MDSpinner size={50} />
                                <br/>
                                <Typography variant="h6">
                                    Barcode {this.state.printBarcodesInfo.current} of {this.state.printBarcodesInfo.total}
                                </Typography>
                            </Grid>
                        </DialogContent>
                    </Dialog>   

                    <CommonDialog data={this.state.dialogdata['save_status_done']} />
                    <CommonDialog data={this.state.dialogdata['reset_draw_status']} />

                    <div className={classes.toolbar} />
                    <div className={classes.toolbar} />
                </main>
                <SimpleBottomNavigation />
            </div>
        );

    }
}

PatientClass.propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    theme: PropTypes.object.isRequired,
};
  

//const Patient = connect(null,mapDispatchToProps, mapStateToProps)(withMobileDialog()(withStyles(styles, { withTheme: true })(PatientClass)));
const Patient = connect(mapStateToProps,mapDispatchToProps)(withMobileDialog()(withStyles(styles, { withTheme: true })(PatientClass)));

export default Patient;