import * as globals from 'components/common/GlobalConstants';
import grey from '@material-ui/core/colors/grey';

const styles = theme => ({
    root: {
        paddingTop: "25px !important",
        paddingLeft: "40px !important",
        paddingRight: "40px !important",
        paddingBottom: "40px !important",
        // display: 'flex',
        // fullWidth: true
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
    },
    overrideBlue: {
        background: globals.BLUE_GRADIENT_BG,
        color: "#fff",
    },
    overrideButtonBlue: {
        background: globals.LIGHT_BLUE_GRADIENT_BG,
        color: "#01579B",
    },
    avatar: {
        margin: 'auto',
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
        width: 100,
        height: 100,
        fontSize: 50,
    },
    grid_item: {
        padding: theme.spacing.unit,
    },
    margins: {
        margin: theme.spacing.unit * 2.5,
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    warning_icon: {
        top: 50,
    },
    group: {
        margin: `${theme.spacing.unit}px 0`,
    },
    swipeableTab: {
        backgroundColor: grey[50],
    },
    badge: {
        padding: `0 ${theme.spacing.unit * 2}px`,
    },
    progressBar: {
      height: 17,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 300,
    },
    table: {
        margin: `${theme.spacing.unit * 2}px auto`,
        width: '80%'
    },
    tableRow: {
        border: 'none'
    },
    patientInfoGrid: {
        textAlign: "left",
        padding: theme.spacing.unit,
    },
    icon: {
        lineHeight: 1.2,
        fontSize: 20,
    },
    textCentered: {
        textAlign: "center"
    },
    yellowPaperBG: {
        background: "#FFFBE7",
    },
    STAT: {
        fontSize: '22px !important',
        color: '#000',
        backgroundColor: '#FAD94E',
        display: 'inline',
        padding: `${theme.spacing.unit * .5}px ${theme.spacing.unit}px`,
        borderRadius: 3
    },
    mainButton: {
        backgroundColor: "#68CBEF",
        textTransform: 'none',
        color: "#fff",
        fontSize: 24,
        padding: `${theme.spacing.unit * 1.5}px ${theme.spacing.unit * 2.5}px`,
        display: "block",
        margin: `${theme.spacing.unit * 4.5}px auto`,
    },
    resetButton: {
        textTransform: 'none',
        fontSize: 24,
        padding: `${theme.spacing.unit * 1.5}px ${theme.spacing.unit * 2.5}px`,
    },
    statusNotes: {
        fontSize: '20px !important',
    },
    tabContainer: {
        padding: `${theme.spacing.unit * 1.5}px 0px`,
    },
    patientIdentityText: {
        fontSize: '32px !important',
        padding: `${theme.spacing.unit * 1.5}px 0px`,
    },
    patientIdentityTab: {
        fontSize: '22px !important',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit}px`,
        // paddingLeft: '0px',
        // paddingRight: '0px',
    },
    patientIdentityBirthdateCellCont: {
        padding: '0px',
        border: "none",
    },
    patientIdentityCellCont: {
        padding: `${theme.spacing.unit * 2}px 0px`,
        border: "none",
    },
    buttons: {
        margin: theme.spacing.unit * 2,
        marginLeft: 0,
    },
    radioButton: {
      padding: "6px 12px",
    },
    patientAddress: {
        fontSize: '18px !important',
        width: '50%',
        float: 'left'
    },
    gridContainer: {
        marginTop: 143,
        marginBottom: 125,
    },
    patientCheckbox: {
        width: 45,
        height: 45,
    },
    tabLabel: {
        fontSize: 18
    }
});

export default styles;