import React, { Component } from 'react';
import PropTypes from 'prop-types';

import $ from 'jquery';

class GoogleMapComponent extends Component {
    constructor(props) {
        super(props);

        this.randID = this.generateID();
        this.map = null;
        this.mapEl = null;
        this.zoom = 12;
        this.start = null; // {lat: 41.782120, lng: -87.933825};
        this.end = null; // {lat: 41.819940, lng: -87.604880};
        this.waypts = null;
        this.wayptsOptions = null;
        this.totalMeters = 0;
        this.totalMetersDriven = 0;
    }

    triggerResponseHandler = (value) => {
        if(this.props.milesDrivenPercentageResponseHandler !== undefined) {
            this.props.milesDrivenPercentageResponseHandler(value);
        }
    }

    componentDidMount() {
        this.mapEl = $("#googleMap_" + this.randID);
        this.generateMapContainerDimension();
        this.initMap();

        console.log("GoolgeMapComponent componentDidMount.");
        // console.log(this.props);
    }

    generateMapContainerDimension() {
        var width = this.mapEl.parent().width();
        var height = this.mapEl.parent().height();

        this.mapEl.css({
            width: width+"px",
            height: height+"px",
        });
    }

    initMap() {
        var mapProps = {
            center: this.start,
            zoom: this.zoom,
            panControl: false,
            streetViewControl: false,
            // styles: [{"featureType":"all","elementType":"all","stylers":[{"saturation":"32"},{"lightness":"-3"},{"visibility":"on"},{"weight":"1.18"}]},{"featureType":"administrative","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"landscape","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"landscape.man_made","elementType":"all","stylers":[{"saturation":"-70"},{"lightness":"14"}]},{"featureType":"poi","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"saturation":"100"},{"lightness":"-14"}]},{"featureType":"water","elementType":"labels","stylers":[{"visibility":"off"},{"lightness":"12"}]}]
            styles: [{"featureType":"road","elementType":"labels","stylers":[{"visibility":"off"}]}]
        };
        // console.log("#googleMap_" + this.randID);
        this.map = new window.google.maps.Map(this.mapEl.get(0), mapProps);
        
        var directionsService = new window.google.maps.DirectionsService();
        var directionsDisplay = new window.google.maps.DirectionsRenderer({
            // preserveViewport: true,
            suppressPolylines: true,
            suppressMarkers: true,
        });
        directionsDisplay.setMap(this.map);
        this.calculateAndDisplayRoute(directionsService, directionsDisplay);
        this.addHomeMarker(this.start); // point A
    }

    addHomeMarker(location) {
        var icon = {
            url: '/images/map/home.png',
            size: new window.google.maps.Size(22, 21),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(12, 21) // base pointer
        };

        this.addCustomMarker(location, icon);
    }

    addStarMarker(location, status="not_visited", label) {
        var icon = {
            url: '/images/map/final_site_' + status + '.png?v=4',
            size: new window.google.maps.Size(31, 31),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(15, 15)
        };

        this.addCustomMarker(location, icon, label);
    }

    addSiteMarker(location, status="not_visited", label) {
        var icon = {
            url: '/images/map/site_' + status + '.png?v=4',
            size: new window.google.maps.Size(23, 23),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(12, 12)
        };

        this.addCustomMarker(location, icon, label);
    }

    addCarMarker(steps) {
        var mid_step = parseInt(steps.lat_lngs.length / 2);
        var location = steps.lat_lngs[mid_step];
        var next_step = steps.lat_lngs[mid_step + 1];
        
        var heading = window.google.maps.geometry.spherical.computeHeading(location, next_step);

        var marker = new window.google.maps.Marker({
            position: location,
            animation: window.google.maps.Animation.DROP,
            icon: {
                path: "M12.407,12.84h1.875c-.072.289-.144.649-.216.866A2.169,2.169,0,0,1,12.624,15c-.144.072-.216.144-.216.289,0,1.154.072,2.308.072,3.462a3.719,3.719,0,0,1-2.958,3.679,2,2,0,0,1-.649.072c-1.082,0-2.092,0-3.174.072A3.756,3.756,0,0,1,1.948,18.9c0-1.154,0-2.308-.072-3.535,0-.144-.072-.216-.216-.289A2.091,2.091,0,0,1,0,12.984H1.8V12.7c0-2.958-.072-5.843-.072-8.8A3.7,3.7,0,0,1,5.482.072c1.01,0,2.092,0,3.1-.072a3.719,3.719,0,0,1,3.679,2.958v.866c.072,2.885.072,5.771.144,8.728Zm-1.154,2.741c-.289-.938-.649-1.8-.938-2.669-1.082.072-2.164.216-3.246.216s-2.164-.072-3.246-.144c-.289.866-.577,1.8-.866,2.669C5.122,16.952,9.377,16.807,11.253,15.581ZM2.885,3.967c.289.866.577,1.731.866,2.525.072.144.144.216.289.216.649-.072,1.371-.144,2.02-.216a18.274,18.274,0,0,1,3.967.144c.144,0,.216,0,.289-.216.216-.721.433-1.371.649-2.02.072-.144.072-.361.144-.5C8.945,2.6,4.689,2.813,2.885,3.967Z",
                scaledSize: new window.google.maps.Size(17, 28),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(8, 14),
                fillColor: "#707070",
                fillOpacity: 1,
                rotation: heading + 180 //bearing + 180 // + 180 // just to flip image directing top
            },            
            zIndex: Math.round(location.lat * -100000) << 5
        });
        marker.setMap(this.map);
    }

    addCustomMarker(location, icon=null, labelFont={
        text: "0",
        color: 'black',
        fontSize: "12px",
    }) {
        // console.log(labelFont);
        var marker = new window.google.maps.Marker({
            position: location,
            animation: window.google.maps.Animation.DROP,
            icon: icon,            
            label: {
                text: labelFont.text,
                color: labelFont.color,
                fontSize: labelFont.fontSize,
                fontWeight: labelFont.fontWeight,
                textAlign: labelFont.textAlign
            },
            zIndex: Math.round(location.lat * -100000) << 5
        });
        marker.metadata = {class: "google-map-markers"};
        marker.setMap(this.map);
    }

    // radians(n) {
    //     return n * (Math.PI / 180);
    // }

    // degrees(n) {
    //     return n * (180 / Math.PI);
    // }

    // getBearing(startLat,startLong,endLat,endLong) {
    //     startLat = this.radians(startLat);
    //     startLong = this.radians(startLong);
    //     endLat = this.radians(endLat);
    //     endLong = this.radians(endLong);

    //     var dLong = endLong - startLong;

    //     var dPhi = Math.log(Math.tan(endLat/2.0+Math.PI/4.0)/Math.tan(startLat/2.0+Math.PI/4.0));
    //     if (Math.abs(dLong) > Math.PI){
    //         if (dLong > 0.0)
    //             dLong = -(2.0 * Math.PI - dLong);
    //         else
    //             dLong = (2.0 * Math.PI + dLong);
    //     }

    //     return (this.degrees(Math.atan2(dLong, dPhi)) + 360.0) % 360.0;
    // }

    createPolyLine(pathSegment, strokeOptions = {
        type:"solid",
        color: "black",
        opacity: 1,
        weight: 3,
    }) {
        // console.log(type);
        switch(strokeOptions.type) {
            case "dashed":
                var lineSymbol = {
                    path: 'M 0,-1 0,1',
                    strokeOpacity: strokeOptions.opacity,//1,
                    scale: strokeOptions.weight
                };

                new window.google.maps.Polyline({
                    path: pathSegment,
                    strokeColor: strokeOptions.color, //'#707070',
                    strokeOpacity: 0,
                    icons: [{
                    icon: lineSymbol,
                    offset: '0',
                    repeat: '20px'
                    }],
                    map: this.map
                });
                break;

            case "dotted":
                // // ***** Dotted
                var dottedSymbol = {
                    path: window.google.maps.SymbolPath.CIRCLE,
                    fillOpacity: strokeOptions.opacity,//1,
                    scale: strokeOptions.weight
                };

                new window.google.maps.Polyline({
                    path: pathSegment,
                    strokeColor: strokeOptions.color,//'#707070',
                    strokeOpacity: 0,
                    icons: [{
                        icon: dottedSymbol,
                        offset: '0',
                        repeat: '13px'
                    }],
                    map: this.map
                });
                break;
            case "solid":
                // ***** Solid Line
                new window.google.maps.Polyline({
                    path: pathSegment,
                    strokeColor: strokeOptions.color,//'#707070',
                    strokeOpacity: strokeOptions.opacity,//1,
                    strokeWeight: strokeOptions.weight,
                    map: this.map
                });
                break;
            default:
                console.log("invalid path segment type");
                break;
        }
    }

    calculateAndDisplayRoute(directionsService, directionsDisplay) {
        this.totalMeters = 0;
        this.totalMetersDriven = 0;

        directionsService.route({
            origin: this.start,
            destination: this.end,
            waypoints: this.waypts,
            optimizeWaypoints: false,
            travelMode: 'DRIVING'
        }, function(response, status) {
            if (status === 'OK') {
                directionsDisplay.setDirections(response);
                // console.log(response);

                // var bounds = new window.google.maps.LatLngBounds();
                var route = response.routes[0];
                var legs = route.legs;
                var solid_lineSymbol = true;
                for (var i = 0; i < legs.length; i++) {
                    // var routeSegment = i + 1;
                    // var summaryPanel = '';
                    // summaryPanel += 'Route Segment: ' + routeSegment + ': ';
                    // summaryPanel += route.legs[i].start_address + ' to ';
                    // summaryPanel += route.legs[i].end_address + '..... distance: ';
                    // summaryPanel += route.legs[i].distance.text;
                    // console.log(summaryPanel);

                    // catch undefined destination on last site
                    if(i < legs.length - 1) {
                        switch(this.wayptsOptions[i].type) {
                            case 'car':
                                // console.log("calculating midpoint for car location...");
                                // this.addCarMarker(this.waypts[i].location);
                                break;
                            case 'site':
                                this.addSiteMarker(this.waypts[i].location, this.wayptsOptions[i].icon, this.wayptsOptions[i].labelFonts);
                                break;
                            case 'star':
                                this.addStarMarker(this.waypts[i].location, this.wayptsOptions[i].icon, this.wayptsOptions[i].labelFonts);
                                break;
                            default:
                                console.log("invalid marker type");
                                break;
                        }

                        this.totalMeters += legs[i].distance.value;
                        var mid_distance = legs[i].distance.value / 2;
                        var steps = legs[i].steps;
                        var current_step_distance = 0;
                        var phlebs_loc_plotted = false;
                        
                        for (var j = 0; j < steps.length; j++) {
                            current_step_distance += steps[j].distance.value;
                            var nextSegment = steps[j].path;
                            // console.log(steps[j]);

                            // if phleb loc && next site is not active OR if it is last site
                            if(this.wayptsOptions[i].type === 'car'
                                && (['site','star'].includes(this.wayptsOptions[i + 1].type) && this.wayptsOptions[i + 1].icon !== 'active')) {
                                // ********** ACCURATE MID DISTANCE of step, NOT YET DONE
                                if(current_step_distance > mid_distance &&
                                    !phlebs_loc_plotted) {

                                    phlebs_loc_plotted = true;
                                    this.addCarMarker(steps[j]);
                                }

                                if(phlebs_loc_plotted) {
                                    this.createSegmetLine("dashed", nextSegment);
                                } else {
                                    this.totalMetersDriven += steps[j].distance.value;
                                    this.createSegmetLine("solid", nextSegment);
                                }
                            } else {
                                // after the phlebs loc is plotted, path line should be dashed
                                if(solid_lineSymbol) {
                                    this.totalMetersDriven += steps[j].distance.value;
                                    this.createSegmetLine("solid", nextSegment);
                                } else {
                                    this.createSegmetLine("dashed", nextSegment);
                                }
                            }
                        }

                        // when phlebs location found, change path line symbol
                        if(this.wayptsOptions[i].type === "car") {
                            solid_lineSymbol = false;
                        }
                    }
                }
            } else {
                console.log('Directions request failed due to ' + status);
            }

            var meters = this.totalMeters / 1609.34;
            var metersDriven = this.totalMetersDriven / 1609.34;   

            this.triggerResponseHandler({
                "metersDriven": metersDriven,
                "totalMetersDriven": meters,
                "percentage": Math.round(metersDriven/meters * 100)
            });      
        }.bind(this));
    }

    createSegmetLine(type="solid", nextSegment) {
        if(type === "solid") {
            this.createPolyLine(nextSegment, {
                type: "solid",
                color: '#707070',
                opacity: .8,
                weight: 3
            });
        } else {
            this.createPolyLine(nextSegment, {
                type: "dashed",
                color: '#000000',
                opacity: 1,
                weight: 3
            });
        }
    }

    generateID() {
        return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
    }

    render() {
        // console.log("GoogleMapComponent");
        // console.log(this.props);

        const {zoom, center, start, end, wayPoints, wayPointsOptions} = this.props;
        this.zoom = zoom;
        this.center = center;
        this.start = start;
        this.end = end;
        this.waypts = wayPoints;
        this.wayptsOptions = wayPointsOptions;

        // console.log(this.randID);

        return (
            <div id={"googleMap_" + this.randID}></div>
        );
    }
}

GoogleMapComponent.propTypes = {
    center: PropTypes.object.isRequired,
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired,
    wayPoints: PropTypes.array.isRequired,
    wayPointsOptions: PropTypes.array.isRequired,
    zoom: PropTypes.number.isRequired,
    milesDrivenPercentageResponseHandler: PropTypes.func.isRequired,
    // style: PropTypes.object.isRequired,
};

export default GoogleMapComponent;