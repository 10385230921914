import React, { useState, useEffect, useRef } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
// import classNames from 'classnames'

import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { connect } from "react-redux"
// import moment from 'moment'
// import GlobalImagePreview from 'components/common/mobile/GlobalImagePreview'
import * as globals from 'components/common/GlobalConstants'
import {
    TapHere,
    CapturedPhotoDisplay
    // CapturedPhoto
} from './Step2Components.js'
import PhotoCapture2 from 'components/common/mobile/PhotoCapture2'
// import uuid from "uuid"

// import {
//     CapturePhotoDialog,
// } from './Step6Components.js' // we will reuse this component

// import {
//     saveDrawRequestDocument,
//     logEvent
// } from "js/actions"

const mapStateToProps = state => {
    return {
        drawData: state.drawData,
        drawRequestDocumentsData: state.drawRequestDocumentsData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // logEvent: event => dispatch(logEvent(event)),
    };
};

const Step2FunctionClass = (props) => {
    const {
        isOrderRemaining,
        classes,
        onSetBtnNextDisabled,
        // onChangeData,
        drawRequestDocumentsData,
        siteId,
        patientId,
        patientDrawId,
    } = props

    const photoCaptureRef = useRef(null)
    // var photoCaptureRef = {}
    const patientReadOnly = false

    const ivKitLabel = "IV Kit"
    // const [photoThumbnailView, setPhotoThumbnailView] = useState(true)
    var patientDocs = []
    var ivKitReqDoc = null
    if (drawRequestDocumentsData.draw_request_documents) {
        patientDocs = drawRequestDocumentsData.draw_request_documents.filter(function (draw_req_doc) {
            return draw_req_doc.drawlist_site_id === siteId && draw_req_doc.drawlist_patient_id === patientId
        })
    }
    // console.log("patientDocs")
    // console.log(patientDocs)

    // filter IV Kit Category req doc
    // undefined if no result
    ivKitReqDoc = patientDocs.find(doc =>
        doc.metadata.image &&
        doc.metadata.image.category &&
        doc.client_deleted === false &&
        doc.metadata.image.category === ivKitLabel
    )

    // console.log("ivKitReqDoc")
    // console.log(ivKitReqDoc)

    const [dataUri, setDataUri] = useState("") // load redux dataUri
    const [id, setID] = useState("") // load redux id

    useEffect(() => {
        console.log("ivKitReqDoc")
        console.log(ivKitReqDoc)
        var imgDoc = ""
        if (ivKitReqDoc) {
            if (ivKitReqDoc.doc_type === "offline_upload") {
                imgDoc = ivKitReqDoc.metadata.image.data
            } else if (ivKitReqDoc.doc_type === "offline_uploaded") {
                imgDoc = (globals.USE_PAMP_COUCHDB_SERVER) ? ivKitReqDoc.path : globals.SERVER + ivKitReqDoc.path
            }
        }

        setID(ivKitReqDoc ? ivKitReqDoc.id : "")
        setDataUri(ivKitReqDoc ? imgDoc : "")
    }, [ivKitReqDoc, setDataUri])

    // console.log("Step2 ivKitReqDoc")
    // console.log(ivKitReqDoc)

    // const handleCaptureIVKit = (dataUri) => {
    //     // source: PhotoCapture2
    //     if (dataUri === "") return

    //     var timeTaken = moment(new Date()).format("M-D-YY-h:mm:ss-A")
    //     var document = {
    //         "id": "",
    //         "filename": "Photo-" + timeTaken + ".jpg",
    //         "path": "Photo",
    //         "doc_type": "offline_upload",
    //         "metadata": {
    //             "image": {
    //                 "category": "IV Kit",
    //                 "data": dataUri,
    //                 "filename": "Photo-" + timeTaken + ".jpg",
    //                 "resize_conf": {
    //                     "imageType": "IMAGE_TYPES.JPG",
    //                     "imageCompression": .5,
    //                     "isImageMirror": false,
    //                     "sizeFactor": 0.5
    //                 },
    //             }
    //         }
    //     }
    //     var payload = {
    //         siteId: siteId,
    //         patientId: patientId,
    //         type: "photo",
    //         target: "patient_draw",
    //         document: document
    //     }

    //     saveDrawRequestDocument(payload)
    //     setCapturePhotoDialogOpen(false)

    //     setDataUri(dataUri)
    // }

    useEffect(() => {
        // disabling of buttons if status === remaining only(edit mode)
        if (isOrderRemaining) {
            onSetBtnNextDisabled(dataUri === "")
        }
    }, [dataUri, isOrderRemaining, onSetBtnNextDisabled])

    useEffect(() => {
        // force enable after done/unable
        if (!isOrderRemaining) {
            onSetBtnNextDisabled(false)
        }
    }, [isOrderRemaining, onSetBtnNextDisabled])

    useEffect(() => {
        // CALL YOUR API OR ASYNC FUNCTION HERE
        // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function
        return () => { photoCaptureRef.current = false }
    }, [])

    return (
        <>
            <div className={classes.ivKitCapturedPhotoContainer}>
                {dataUri === "" || (typeof ivKitReqDoc === 'undefined' || ivKitReqDoc === null)
                    ?
                    <div
                        onClick={() => {
                            if (isOrderRemaining) {
                                photoCaptureRef.current.addNewPhoto({ id: id, category: ivKitLabel })
                            }
                            // photoCaptureRef.addNewPhoto({ id: id, category: ivKitLabel })
                            // setPhotoThumbnailView(false)
                        }}>
                        <TapHere
                            isOrderRemaining={isOrderRemaining}
                            classes={classes}
                        />
                    </div>
                    :
                    // <>
                    //     {photoThumbnailView ?
                    <div
                        onClick={() => {
                            // ** NEW CAPTURE ONLY
                            // photoCaptureRef.current.addNewPhoto({ id: id, category: ivKitLabel })
                            // ** FOR EDIT
                            photoCaptureRef.current.addNewPhoto({ id: id, category: ivKitLabel, dataUri: dataUri })
                        }}
                    >
                        <CapturedPhotoDisplay
                            // key={uuid.v4()}
                            classes={classes}
                            data={{ item: ivKitReqDoc }}
                        />
                    </div>
                    // :
                    // <GlobalImagePreview
                    //     dataUri={dataUri}
                    //     width={window.innerWidth - 40} // minus 40 for padding
                    //     height={window.innerHeight - 300 - (36 + 20)} // 36 h and 20 pad for ok cancel button cont height
                    // />
                    // }
                    // </>
                }
            </div>

            <PhotoCapture2
                // key={uuid.v4()} // needed to assign addNewPhoto to photoCaptureRef
                // ref={photoCaptureRef}
                photoCaptureRef={photoCaptureRef}
                siteId={siteId}
                patientId={patientId}
                patientDrawId={patientDrawId}
                notes={null}
                target="patient"
                draw_request_documents={drawRequestDocumentsData}
                // addNewPhoto={m => photoCaptureRef.addNewPhoto = m}
                // deletePhoto={m => photoCaptureRef.deletePhoto = m}
                readOnly={patientReadOnly || !isOrderRemaining}
            />

            {/* <CapturePhotoDialog
                classes={classes}
                open={capturePhotoDialogOpen}
                handleClose={() => setCapturePhotoDialogOpen(false)}
                capturedPhoto={dataUri}
                capturePhoto={handleCaptureIVKit}
                logEvent={logEvent}
            /> */}
        </>
    )
}

const Step2 = withRouter(
    withCookies(
        connect(mapStateToProps, mapDispatchToProps)(
            withStyles(styles, { withTheme: true })(Step2FunctionClass)
        )
    )
)
export default Step2
