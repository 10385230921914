import React, { useState, useRef, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
// import classNames from 'classnames'
import * as globals from 'components/common/GlobalConstants'
import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { connect } from "react-redux"
// import uuid from "uuid"

// import Grid from '@material-ui/core/Grid'
// import Typography from '@material-ui/core/Typography'
// import Button from '@material-ui/core/Button'

// import * as helper from 'components/common/Helper.js'
// import ImmutableJS from "immutable"
// import moment from "moment"

// import {
//     saveNote,
//     deleteNote,
//     saveDrawRequestDocument,
//     deleteDrawRequestDocument,
//     logEvent
// } from "js/actions"

import {
    DocumentsDataDisplay,
    AddDataOptions,
} from './Step2Components.js'
import BarcodeReader2 from 'components/common/mobile/BarcodeReader2'
import PhotoCapture2 from 'components/common/mobile/PhotoCapture2'
import Notes2 from 'components/common/mobile/Notes2'

const mapStateToProps = state => {
    return {
        drawData: state.drawData,
        drawRequestDocumentsData: state.drawRequestDocumentsData,
        drawRequestNotesData: state.drawRequestNotesData,
    }
}

const mapDispatchToProps = dispatch => {
    return {}
};

const Step2FunctionClass = (props) => {
    const {
        isOrderRemaining,
        classes,
        onSetBtnNextDisabled,
        // onChangeData,
        match,
        siteId,
        patientId,
        patientDrawId,
        drawRequestDocumentsData,
        drawRequestNotesData,
        // saveNote, // redux
        // logEvent,
        // saveDrawRequestDocument
    } = props

    // console.log("drawRequestNotesData")
    // console.log(drawRequestNotesData)

    const [optionsActive, setOptionsActive] = useState(false)
 
    const patientIdenficationLabel = "Patient Identification"

    const noteCaptureRef = useRef(null)
    const photoCaptureRef = useRef(null)
    const barcodeCaptureRef = useRef(null)
    var notes = []
    var barcodes = []
    const patientReadOnly = false

    if (drawRequestNotesData.draw_request_notes) {
        notes = drawRequestNotesData.draw_request_notes.filter(function (draw_req_note) {
            return (draw_req_note.drawlist_site_id === siteId && draw_req_note.drawlist_patient_id === patientId)
        })
    }

    if (drawRequestDocumentsData.draw_request_documents) {
        barcodes = drawRequestDocumentsData.draw_request_documents.filter(function (draw_req_doc) {
            return (draw_req_doc.drawlist_site_id === siteId &&
                draw_req_doc.drawlist_patient_id === patientId &&
                draw_req_doc.doc_type !== undefined && draw_req_doc.doc_type === "metadata" &&
                draw_req_doc.client_deleted !== undefined && draw_req_doc.client_deleted !== true)
        })
    }
    
    // always allow, step 6 not required
    onSetBtnNextDisabled(false)

    useEffect(() => {
        // CALL YOUR API OR ASYNC FUNCTION HERE
        // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function
        return () => { 
            noteCaptureRef.current = false 
            photoCaptureRef.current = false 
            barcodeCaptureRef.current = false 
        }
    }, [])

    return (
        <>
            <DocumentsDataDisplay
                classes={classes}
                match={match}
                drawRequestDocumentsData={drawRequestDocumentsData}
                drawRequestNotesData={drawRequestNotesData}
                onOpenNote={(note_id) => noteCaptureRef.current.openNote(note_id)}
                onOpenPhoto={(item) => {
                    console.log("item")
                    console.log(item)
                    var imgDoc = ""
        
                    if(item) {
                        if(item.doc_type == "offline_upload") {
                            imgDoc = item.metadata.image.data
                        } else if(item.doc_type == "offline_uploaded") {
                            imgDoc =   (globals.USE_PAMP_COUCHDB_SERVER) ? item.path : globals.SERVER + item.path           
                        }
                    }
                    // console.log("imgDoc")
                    // console.log(imgDoc)
                    photoCaptureRef.current.addNewPhoto({ id: item.id, category: patientIdenficationLabel, dataUri: imgDoc })
                }}
                onOpenBarcode={(barcode) => barcodeCaptureRef.current.openBarcode(barcode)}
            />

            <AddDataOptions
                classes={classes}
                setOptionsActive={setOptionsActive}
                optionsActive={optionsActive}
                onOpenCaptureNoteDialog={() => noteCaptureRef.current.addNewNote()}
                onOpenCapturePhotoDialog={() => photoCaptureRef.current.addNewPhoto({ id: "", category: patientIdenficationLabel })}
                onOpenCaptureBarcodeDialog={() => barcodeCaptureRef.current.addNewBarcode()}
                onCaptureData={() => {
                    setOptionsActive(false)
                    onSetBtnNextDisabled(false)
                    // console.log(onCapture)
                }}
            />

            <Notes2
                // key={uuid.v4()} // needed to assign addNewNote to noteCaptureRef
                noteCaptureRef={noteCaptureRef}
                siteId={siteId}
                patientId={patientId}
                patientDrawId={patientDrawId}
                notes={notes}
                target="patient"
                // addNewNote={m => noteCaptureRef.addNewNote = m}
                // openNote={m => noteCaptureRef.openNote = m}
                // removeNote={m => noteCaptureRef.removeNote = m}
                readOnly={patientReadOnly || !isOrderRemaining}
            // readOnly={this.props.patientReadOnly || isDrawDone}
                fieldDataCapture={true}
            />

            <PhotoCapture2
                // key={uuid.v4()} // needed to assign addNewPhoto to photoCaptureRef
                photoCaptureRef={photoCaptureRef}
                siteId={siteId}
                patientId={patientId}
                patientDrawId={patientDrawId}
                notes={null}
                target="patient"
                draw_request_documents={drawRequestDocumentsData}
                // addNewPhoto={m => photoCaptureRef.addNewPhoto = m}
                // deletePhoto={m => photoCaptureRef.deletePhoto = m}
                canForceAdd={true} // even if readOnly=true, we can force add new photo
                readOnly={patientReadOnly || !isOrderRemaining}
                fieldDataCapture={true}
            />

            <BarcodeReader2
                // key={uuid.v4()} // needed to assign addNewBarcode to barcodeCaptureRef
                barcodeCaptureRef={barcodeCaptureRef}
                siteId={siteId}
                patientId={patientId}
                patientDrawId={patientDrawId}
                // notes={null}
                barcodes={barcodes}
                target="patient"
                draw_request_documents={drawRequestDocumentsData}
                title={"Telopoint"}
                commandText={"Scan Barcode"}
                inputGuideText={"Enter Barcode"}
                readOnly={patientReadOnly || !isOrderRemaining}
            // addNewBarcode={m => barcodeCaptureRef.addNewBarcode = m}
            // deleteBarcode={m => barcodeCaptureRef.deleteBarcode = m}
                fieldDataCapture={true}
            />

            {/* 
            <AddNoteDialog
                classes={classes}
                open={addNoteDialogOpen}
                handleClose={() => setAddNoteDialogOpen(false)}
                saveNote={handleSaveNote}
            />
            
            <CapturePhotoDialog
                classes={classes}
                open={capturePhotoDialogOpen}
                handleClose={() => setCapturePhotoDialogOpen(false)}
                capturePhoto={handleCapturePhoto}
                logEvent={logEvent}
            /> */}
        </>
    )
}

const Step2 = withRouter(
    withCookies(
        connect(mapStateToProps, mapDispatchToProps)(
            withStyles(styles, { withTheme: true })(Step2FunctionClass)
        )
    )
)
export default Step2
