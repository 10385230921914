import React from 'react';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';

import { ReactComponent as SitesGrayFillSVG } from 'components/common/mobile/CustomOfflineSVG/sites_gray_fill.svg';
import { ReactComponent as SitesWhiteFillSVG } from 'components/common/mobile/CustomOfflineSVG/sites_white_fill.svg';

const FutureOrderViewToggle = (props) => {

    const {classes, viewType, handleGoTo, handleSwitchView } = props;

    return(
        <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.futureOrderGrid}>
            <Grid item>
                <Button 
                    onClick={handleGoTo.bind(null, '/future_orders')}
                    variant="contained"
                    classes={{
                        root: classNames(
                            classes.switchViewButton,
                            classes.switchViewButtonLeft,
                            classes.switchViewButtonRight
                        ),
                        label: classes.switchViewButtonLabel
                    }}>
                    <Icon className={classes.backIcon}>chevron_left</Icon> Back
                </Button>
            </Grid>
            <Grid item>
                <Button
                    onClick={handleSwitchView.bind(null,"list")}
                    variant="contained"
                    classes={{
                        root: classNames(
                            classes.switchViewButton,
                            classes.switchViewButtonLeft,
                            (viewType === 'list' ? classes.switchViewButtonSelected : "")),
                        label: classes.switchViewButtonLabel
                    }}><Icon>reorder</Icon></Button>
                <Button
                    onClick={handleSwitchView.bind(null,"map")}
                    variant="contained"
                    classes={{
                        root: classNames(
                            classes.switchViewButton,
                            classes.switchViewButtonRight,
                            (viewType === "map" ? classes.switchViewButtonSelected : "")),
                        label: classes.switchViewButtonLabel
                    }}>
                        {viewType === "map"
                        ?
                        <SitesWhiteFillSVG className={classNames(classes.locationIcon)}></SitesWhiteFillSVG>
                        // <img alt="TeloPoint Location Icon" className={classNames(classes.locationIcon)} src="/images/sites_white_fill.svg" />
                        :
                        // <img alt="TeloPoint Location Icon" className={classNames(classes.locationIcon)} src="/images/sites_gray_fill.svg" />
                        <SitesGrayFillSVG className={classNames(classes.locationIcon)}></SitesGrayFillSVG>
                        }
                </Button>
            </Grid>
        </Grid>
        );

}

export default FutureOrderViewToggle;