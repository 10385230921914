import * as globals from 'components/common/GlobalConstants';

const styles = theme => ({
    root: {
        // display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        paddingLeft: 8,
        paddingRight: 8,
       // marginTop: 0,
       
    },
    overrideBlue: {
        background: globals.BLUE_GRADIENT_BG,
    },
    headerInfo: {
        paddingTop: "15px",
        paddingLeft: "15px",
        paddingRight: "15px",
    },
    scheduleContainer: {
        padding: "15px",
    },
    list: {
      border: "1px solid rgba(0, 0, 0, 0.16)",
      marginBottom: theme.spacing.unit,
      fontSize: "12px !important",
    },
    label: {
        fontSize: "14px !important",
        color: "#707070",
    },
    buttons: {
        fontSize: "14px !important",
        textTransform: "none",
        border: "none",
        borderRadius: 0,
        padding: "6px 16px"
    },
    customApplicationBarHeader: {
        padding: "7px 17px",
        marginLeft: "-15px",
        marginRight: "-15px"
    },
    listULMainHeader: {
        marginTop: 25
    },
    schedulesMainHeader: {
        color: "#707070 !important",
        fontSize: '24px !important',
        fontWeight: 'bold',
        padding: "0px !important",
        margin: "0px !important"
    },
    schedulesMainContainer: {
        margin: 15,
    },
    listUL: {
        paddingTop: 0,
        paddingBottom: 0
    },

    textFieldContainer: {
        padding: 0
    },
    listItemRoot: {
        padding: "0px !important",
        // border: "1px solid black",
    },
    futureOrdersList:{
        margin: "10px 0px !important",
        padding: "5px 11px",      
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 2px 10px 0 rgba(0, 0, 0, 0.11)',
    },
    futureOrdersListPrimary: {
        //fontWeight: "bold",
        fontSize: 22,
        color: "#107094 !important",
    },
    futureOrdersListSecondary: {
        fontSize: "16px",
        color: "#107000 !important",
    },
    futureOrdersListPrimaryInActive: {
        //fontWeight: "bold",
        fontSize: 26,
        color: "#D6D6D6 !important",
    },
    workTerritoryRoot: {
        fontSize: 14,
        padding: "0px !important",
        border: "1px solid black !important",
        marginRight: 47
    },
    workTerritoryInput: {
        padding: "7px 10px !important",
    //     fontSize: 14,
    //     padding: "0px 10px !important",
    //     border: "1px solid black !important",
    //     borderRadius: "0px !important"
    },
    workTerritoryNote: {
        fontSize: "12px !important",
        margin: "10px 0px"
    },
    clearButton: {
        color: "#767676",
        marginRight: 5
    },
    addbutton: {
        fontWeight: "bold"
    },
    blueButton: {
        minWidth: 36,
        width: 36,
        right: -5,
        padding: "4px !important",
        fontWeight: "bold",
        color: "white !important",
        backgroundColor: "#68CBEF !important",
    },
    listPaper: {
        marginTop: 30
    },
    listRoot: {
        padding: 0
    },
    listOdd: {
        backgroundColor: "#F4F4F4"
    },
    listHeader: {
        fontSize: 10,
        color: "white !important",
        backgroundColor: "#68CBEF !important",
        padding: "4px 16px"
    },
    listContent: {
        color: "#767676",
        fontSize: 14,
        padding: "8px 16px"
    },
    logout: {
        textAlign: "center",
        margin: "auto !important",
        color: "#5c92aa  !important"
    }
});

export default styles;