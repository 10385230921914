import React from 'react';
import MDSpinner from "react-md-spinner";
//import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

class MiniLoadingSpinner extends React.Component{
    
    render() {
        return(
            <Grid
                container
                alignContent="center"
                direction="column"
                justify="center"
                alignItems="center"
                style={{height: '10vh'}}
                >
                <MDSpinner size={50} />
            </Grid>
        );
    }
}

export default MiniLoadingSpinner;