import React from 'react' // , { useState }
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import styles from "./styles.js"
import * as globals from 'components/common/GlobalConstants';
import * as helper from 'components/common/Helper.js'
import { ReactComponent as TeloPointLogoSVG } from 'components/common/mobile/CustomOfflineSVG/TeloPoint.svg'

const AboutDetails = (props) => {
    const { classes } = props

     return (
        <div>
            <div className={classes.grow}>
                <div className={classes.container}>
                    <TeloPointLogoSVG className={classes.logo}
                            ></TeloPointLogoSVG>
                </div>
                
                <br />
                <Typography className={classes.aboutLabel}>{globals.ABOUT_TITLE}</Typography>
                <Typography className={classes.aboutLabel}> {globals.ABOUT_CONTENT}</Typography>
                <br />
                <Typography className={classes.aboutLabel}>Version: {globals.APP_VERSION}</Typography>
                <br />
                <Typography className={classes.aboutLabel}>Code: {helper.getCodeVersion()}</Typography>

            </div>
        </div>
    )
}

export default
    withMobileDialog()(
        withStyles(styles)
            (AboutDetails)
        
    )
