// export const ADD_ARTICLE = "ADD_ARTICLE"
// export const DELETE_ARTICLE = "DELETE_ARTICLE"
export const UPDATE_DRAW_ITEM = "UPDATE_DRAW_ITEM"
export const LOAD_DATA_FROM_POUCHDB = "LOAD_DATA_FROM_POUCHDB"
export const SETUP_NEW_DB = "SETUP_NEW_DB"
export const SET_LOGGED_IN_STATUS = "SET_LOGGED_IN_STATUS"
export const SET_SCREEN_LOCK_STATUS = "SET_SCREEN_LOCK_STATUS"

export const SET_SCREEN_SESSION_STARTED = "SET_SCREEN_SESSION_STARTED"

//export const MP_SIGN_ON = "MP_SIGN_ON"
export const MP_SIGN = "MP_SIGN"

//export const UPDATE_PATIENT_DRAW_M = "UPDATE_PATIENT_DRAW_M" // skipped
export const UPDATE_PATIENT_DRAW = "UPDATE_PATIENT_DRAW"   // [remaining], refused,unable,done

export const VERIFY_PATIENT_NAME = "VERIFY_PATIENT_NAME"
export const VERIFY_PATIENT_DOB = "VERIFY_PATIENT_DOB"

export const UPDATE_PATIENT_ROOM_NUMBER = "UPDATE_PATIENT_ROOM_NUMBER"

export const NEW_STAT_ARRIVED = "NEW_STAT_ARRIVED"

export const UPDATE_SITE_ORDERING = "UPDATE_SITE_ORDERING"

export const ADD_SCHEDULE = "ADD_SCHEDULE"

export const UPDATE_SCHEDULES = "UPDATE_SCHEDULES"

export const DELETE_SCHEDULE = "DELETE_SCHEDULE"

export const SCHEDULES_DATA_ARRIVED = "SCHEDULES_DATA_ARRIVED"

export const UPDATE_SCHEDULE_SETTINGS = "UPDATE_SCHEDULE_SETTINGS"

export const ACCEPT_DRAW_REQUEST = "ACCEPT_DRAW_REQUEST"

export const DRAW_REQUEST_ARRIVED = "DRAW_REQUEST_ARRIVED"
export const DRAW_REQUEST_DOCUMENTS_ARRIVED = "DRAW_REQUEST_DOCUMENTS_ARRIVED"
export const DRAW_REQUEST_NOTES_ARRIVED = "DRAW_REQUEST_NOTES_ARRIVED"
export const WORKFLOW_DATA_ARRIVED = "WORKFLOW_DATA_ARRIVED"
export const CREATE_SITE_WORKLOW_DEFAULT_VALUES = "CREATE_SITE_WORKLOW_DEFAULT_VALUES"
export const UPDATE_WORKFLOW_VALUE = "UPDATE_WORKFLOW_VALUE"

export const GEO_LOCATION_DATA_ARRIVED = "GEO_LOCATION_DATA_ARRIVED"

export const ADD_GEO_LOCATION = "ADD_GEO_LOCATION"

export const FUTURE_ORDERS_ARRIVED = "FUTURE_ORDERS_ARRIVED"

export const UPDATE_SERVICED_ZIPCODES = "UPDATE_SERVICED_ZIPCODES"

export const SAVE_NOTE = "SAVE_NOTE"

export const DELETE_NOTE = "DELETE_NOTE"

export const SET_ONLINE_STATUS = "SET_ONLINE_STATUS"

export const SAVE_DRAW_REQUEST_DOCUMENT = "SAVE_DRAW_REQUEST_DOCUMENT"

export const DELETE_DRAW_REQUEST_DOCUMENT = "DELETE_DRAW_REQUEST_DOCUMENT"

export const SET_SYNC_STATUS = "SET_SYNC_STATUS"

export const LOG_EVENT = "LOG_EVENT"

export const DELETE_LOGS = "DELETE_LOGS";

export const SET_FORCE_LOGOUT = "SET_FORCE_LOGOUT";

export const CLEAR_ALL_DATA = "CLEAR_ALL_DATA"

export const DO_LOGOUT = "DO_LOGOUT";

export const UPDATE_LOGS = "UPDATE_LOGS";

export const NEW_APP_VERSION = "NEW_APP_VERSION"

export const SET_CAMERA_AVAILABLE = "SET_CAMERA_AVAILABLE"

export const ADD_GPS_LOG = "ADD_GPS_LOG"

export const SET_SELECTED_SCHEDULE_VIEW = "SET_SELECTED_SCHEDULE_VIEW"

export const START_APPOINTMENT = "START_APPOINTMENT"

export const SET_PATIENTS_DOESNT_EXIST_IN_APPT = "SET_PATIENTS_DOESNT_EXIST_IN_APPT"
export const SET_PATIENTS_DOESNT_EXIST_IN_APPT_INDICATOR = "SET_PATIENTS_DOESNT_EXIST_IN_APPT_INDICATOR"

export const SET_ADDED_EXISTING_PATIENT_IN_APPT = "SET_ADDED_EXISTING_PATIENT_IN_APPT"
export const SET_ADD_EXISTING_PATIENT_IN_APPT_INDICATOR = "SET_ADD_EXISTING_PATIENT_IN_APPT_INDICATOR"
export const SET_PATIENT_ID_BEING_ADDED = "SET_PATIENT_ID_BEING_ADDED"