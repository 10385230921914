
const styles = theme => ({
    root: {
        // display: 'flex',
        // background: "#E8E8E8",
        paddingTop: "15px !important",
        paddingLeft: "14px !important",
        paddingRight: "14px !important",
        // paddingBottom: "40px !important",
    },
    gridRoot: {
        display: 'flex',
        padding: "15%",
        height: '90vh'
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
    },
    margins: {
      marginTop: theme.spacing.unit,
    },
    paperUI: {
        ...theme.mixins.gutters(),
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        borderRadius: 10,
    },
    paddings: {
        // padding: `${theme.spacing.unit * 1.5}px ${theme.spacing.unit * 1.5}px`,
        padding: "14px 20px !important",
        paddingBottom: "10px !important",
    },
    overviewSection: {
        background: "linear-gradient(180deg, #68CBEF, #1C8FB9)",
        maxWidth: "100%",
        paddingBottom: 15,
    },
    mapSection: {
        // minHeight: 317,
        minHeight: 420,
        padding: 0,
    },
    alertSection: {
        // background: "linear-gradient(180deg, #FFDD50, #C6A934)",
        background: "linear-gradient(180deg, white, white)",
        minHeight: 262,
    },
    alertBadge: {
        fontSize: 12,
        background: "#000",
        color: "#F3D24A",
        // padding: 1,
        borderRadius: 7,
        top: 5,
        right: -33,
        zIndex: 0,
    },
    overviewTitle: {
        fontWeight: "bold",
        color: "white",
        fontSize: 24,
    },
    alertsTitle: {
        fontWeight: "bold",
        color: "black",
        marginBottom: 20,
        fontSize: 24,
    },
    circularProgressContainer: {
        margin: `${theme.spacing.unit * 1.5}px 0px`,
    },
    circularProgressContainerLeftChild: {
        marginRight: "20px"
    },
    circularProgressContainerRightChild: {
        marginLeft: "20px"
    },
    wrapper: {
        position: 'relative',
        padding: theme.spacing.unit,
        textAlign: 'center',
    },
    fab: {
        color: "white",
        background: "#42ADD4",
        width: 46.83,
        height: 46.83,
        // zIndex: 1100,
    },
    overviewIcons: {
        width: 23,
        height: 23,
    },
    alertsItem: {
        background: "white",
        borderRadius: 10,
        padding: '6.96px 8.8px',
        marginBottom: 12,
    },
    alertsIcon: {
        fontSize: 15,
        color: "black",
    },
    alertsPrimaryText: {
        fontSize: 12,
        fontFamily: "Arial, Bold",
        color: "black",
    },
    fabProgress: {
        color: "#19DB00",
        position: 'absolute',
        // top: -6,
        // left: -6,
        zIndex: 1,
    },
    progressDetails: {
        color: "white !important",
        lineHeight: 1.15,
    },
    progressLabel: {
        fontSize: "11px !important",
        marginBottom: 2
    },
    progressNumbers: {
        fontSize: "12px !important",
        fontWeight: "bold !important",
    },
    labelContainer: {
        textAlign: "center"
        // paddingLeft: 10
    },
    siteListRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        marginLeft: "-3.5px",
        marginRight: "-3.5px",
        marginBottom: "-3.5px"
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        // transform: 'translateZ(0)',
    },
    gridListTileRoot: {
        width: "50%",
        height: "83px",
        padding: "6.5px",
        paddingBottom: "10px",
    },
    gridListTile: {
        borderRadius: 3,
        boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 4px 14px 0 rgba(0, 0, 0, 0.19)',
    },
    gridListTileWithStat: {
        borderRadius: 3,
        boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 4px 14px 0 rgba(0, 0, 0, 0.19)',
        color: '#000000 !important',
    },
    title: {
        color: "#707070",
        fontSize: "14px !important",
    },
    titleBar: {
        background: 'none',
    },
    gridListDraws: {
        fontFamily: 'Arial',
        fontWeight: 'bold',
        fontSize: "18px !important",
        position: 'absolute',
        top: 35,
        left: 15,
        color: '#8C8C8C',
    },
    titleWithStat: {
        color: '#000000 !important',
    },
    gridListDrawsWithStat: {
        color: '#000000 !important',
    },
    checkCircle: {
        color: "#12D003",
        fontSize: 22,
        // marginTop: -5,
        marginRight: 10,
    },
    tabSlideSection: {
        padding: 0,
        position: "relative"
    },
    tabContainer: {
        borderRadius: 10,
    },
    tabTitle: {
        color: "#707070",
        fontSize: "24px !important",
        fontWeight: "bold",
        padding: "12px 20px !important",
    },
    itemOne: {
        // backgroundColor: "#12D003",
    },
    itemTwo: {
        // backgroundColor: "blue",
    },
    itemThree: {
        // backgroundColor: "red",
    },
    mobileStepperContainer: {
        position: "absolute",
        right: 0,
        height: 46,
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: "white",
        borderTopRightRadius: 10,
    },
    mobileStepperDots: {
        border: "1px solid #707070",
        backgroundColor: "white",
        marginRight: 4,
    },
    mobileStepperDotActive: {
        backgroundColor: "#707070"
    },
    overallProgress: {
        fontSize: '13px !important',
    },
    upcomingSitesContainer: {
        padding: "0px 20px",
        overflowY: "scroll"
    },
    progressPercentageText: {
        fontSize: "10px !important",
    },
    progressBar: {
        marginTop: 6.46,
        marginBottom: 5.65,
        borderRadius: 16,
        height: 14.89,
        boxShadow: '0 10px 15px 0 rgba(0, 0, 0, 0.15), 0 34px 60px 0 rgba(0, 0, 0, 0.11)',
    },
    linearColorPrimary: {
        backgroundColor: "white",//"#f1f8e9",
    },
    //hijacked inline style, b'coz LinearProgress bar is using transform scalex, so border radius is a problem
    linearBarColorPrimary: {
        backgroundColor: "#12D003",
        borderRadius: 30,
    },
    myTable: {
        marginTop: 10,
        marginBottom: 30,
        boxShadow: '0 10px 15px 0 rgba(0, 0, 0, 0.15), 0 34px 60px 0 rgba(0, 0, 0, 0.11)',
    },
    siteListContainer: {
        padding: "15px",
    },
    siteName: {
        fontSize: "12px !important",
        fontWeight: 'bold',
        color: '#707070'
    },
    siteAddress: {
        fontSize: "11px !important",
    },
    siteStatus: {
        fontSize: "11px !important",
    },
    siteDraws: {
        fontSize: "11px !important",
        fontWeight: "bold",
        color: "#68CBEF !important"
    },
    withSTAT: {
        backgroundColor: '#FEDC4F'
    },
    badges: {
        fontSize: 10,
        padding: `${theme.spacing.unit * .2}px ${theme.spacing.unit * .2}px`,
        marginRight: "4px",
        marginTop: "2px",
        marginBottom: "4px",
        width: 8, 
        height: 8,
        borderRadius: "50%",
    },
    STAT: {
        color: '#000',
      //  backgroundColor: '#FAD94E',#D20815
        backgroundColor: '#D20815',
        textTransform: 200
    },
    FASTING: {
        color: '#000',
        backgroundColor: '#0000FF',
    },
    siteRowCompleted: {
        backgroundColor: '#D0D0D0',
        // textShadow: '.1px .6px #FAFAFA'
    },
    badgesContainer: {
        display: "table-cell",
        verticalAlign: "top",
        minWidth: 14, 
    },
    sitesTableRow: {
        // display: "table",
        // width: "100%"
    },
    siteInfo: {
        display: "table-cell",
        verticalAlign: "top",
        paddingLeft: "5px",
        width: "1000px" // just a hack for now
    },
    tableRow: {
        height: '18px !important',
        width: "100%"
    },
    tableHeader: {
        height: '18px !important',
        width: "100%"
    },
    tableCellHeader: {
        padding: "4px 0px !important",
        paddingRight: "4px !important",
        backgroundColor: "white",//"#68CBEF",
        color: "#000 !important",
        fontSize: '10px !important',
        whiteSpace: 'nowrap', 
        textAlign: 'center',
        minHeight: '0',
    },
    lastTableCellHeader: {
        paddingRight: "0px !important",
    },
    nowrap: {
        whiteSpace: 'nowrap',
    },
    tableCell: {
        padding: "10px 14px",
    },
    sitesSortableCell: {
        padding: "0px !important"  
    },
    sitesTableCell: {
        paddingRight: "10px !important",
    },
    lastTableCell: {
        // paddingRight: "14px !important",
    },
    statusCompleted: {
        fontWeight: "bold",
        color: "#12D003 !important",
    },
});

export default styles