import * as globals from 'components/common/GlobalConstants'

const styles = theme => ({
    // general
    root: {
    },
    content: {
        flexGrow: 1,
    },
    mainContainer: {
        padding: "15px 20px",
        marginBottom: "100px"
    },
    titleContainer: {
        padding: "11px 20px 15px 20px",
        marginLeft: "-20px",
        marginRight: "-20px",
        borderBottom: "1px solid #E6E6E6",
    },
    scheduleFieldsContainer: {
        padding: "25px 0px 10px 0px"
    },
    title: {
        fontSize: "20px !important",
    },
    labels: {
        fontSize: "12px !important",
        fontWeight: 500
    },
    formControl: {
        border: "1px solid #E6E6E6",
        borderRadius: "5px",
        marginTop: "8px",
        marginBottom: "19px"
    },
    formControlDisabled: {
        backgroundColor: "#F7F7F7 !important",
        border: "1px solid #E6E6E6 !important"
    },
    fullWidth: {
        width: "100%",
    },
    weekSelectFormControl: {
        minWidth: "70px"
    },
    weekNumberLabel: {
        fontSize: "14px !important",
        marginBottom: "14px",
        marginLeft: "10px"
    },
    selectField: {
        padding: "5px 15px",
        border: "none !important",
    },
    selectMenu: {
        "&:focus": { backgroundColor: "white !important" }
    },
    selectFieldFont: {
        fontSize: "14px !important",
    },
    placeholder: {
        color: "#9A9BA0 !important"
    },
    footerContainer: {
        position: "fixed",
        bottom: "0px",
        left: "0px",
        backgroundColor: "white",
        padding: "15px 20px",
        borderTop: "1px solid #efefef"
    },
    cancelButton: {
        textTransform: 'capitalize',
        padding: "10px 20px",
        fontSize: "14px !important",
        backgroundColor: "#f7f7f7",
        color: "#333333 !important",
        fontWeight: 500,
        "&:focus": { backgroundColor: "#f7f7f7 !important" },
        "&:active": { backgroundColor: "#f7f7f7 !important" },
        "&:hover": { backgroundColor: "#f7f7f7 !important" },
        "&:disabled": { backgroundColor: "#f7f7f7 !important" },
    },
    acceptButton: {
        textTransform: 'capitalize',
        padding: "10px 20px",
        fontSize: "14px !important",
        backgroundColor: "#ffdc51",
        color: "#333333 !important",
        fontWeight: 500,
        "&:focus": { backgroundColor: "#ffdc51 !important" },
        "&:active": { backgroundColor: "#ffdc51 !important" },
        "&:hover": { backgroundColor: "#ffdc51 !important" },
        "&:disabled": { backgroundColor: "#e6e6e6 !important" },
    },
    daysOfWeek: {
        textTransform: 'capitalize',
        padding: "10px 16px",
        margin: "0px 10px 10px 0px",
        fontSize: "14px !important",
        backgroundColor: "white",
        color: "black",
        fontWeight: 500,
        border: "1px solid #E6E6E6",
        borderRadius: "4px",
        "&:focus": { backgroundColor: "white !important" },
        "&:active": { backgroundColor: "white !important" },
        "&:hover": { backgroundColor: "white !important" },
        "&:disabled": { backgroundColor: "white !important" },
    },
    activeDayOfWeek: {
        backgroundColor: "#69CEF11A !important",
        border: "1px solid #69CEF11A",
        "& span": {
            color: globals.v2.COLORS.BLUE + " !important",
            fontWeight: 600,
        }
    },
    onlineOnlyWarning: {
        color: globals.v2.COLORS.RED + " !important",
        textAlign: "center",
        padding: "0px 10px",
        marginTop: 20
    },
    deleteScheduleBtn: {
        color: globals.v2.COLORS.RED + " !important",
        textAlign: "center",
        fontSize: "14px !important",
        marginTop: "20px"
    },
    deleteLockedScheduleWarningText: {
        color: globals.v2.COLORS.RED + " !important",
        fontSize: "14px !important",
    }
})

export default styles