import React, { useState, useEffect } from 'react'

import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
import classNames from 'classnames'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import ApplicationBar from 'components/common/workflow/ApplicationBar'
import SimpleBottomNavigation from 'components/common/workflow/SimpleBottomNavigation'
import uuid from "uuid"
import moment from 'moment-timezone'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import ImmutableJS from "immutable"
import * as helper from 'components/common/Helper.js'
import * as globals from 'components/common/GlobalConstants'

import SwipeableViews from 'react-swipeable-views'
import Weekly from './Weekly'
import Monthly from './Monthly'

import {
    setSelectedScheduleView
} from "js/actions/index"

const mapStateToProps = state => {
    return {
        drawData: state.drawData,
        scheduleData: state.scheduleData,
        selectedScheduleView: state.selectedScheduleView
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setSelectedScheduleView: view => dispatch(setSelectedScheduleView(view)),
    }
}

const WorkScheduleFunctionClass = (props) => {
    const { classes, history, scheduleData, selectedScheduleView, setSelectedScheduleView } = props

    const [calendarViewUUID, setCalendarUUID] = useState(uuid.v4())
    const [weeklyCalendarViewUUID, setWeeklyCalendarViewUUID] = useState(uuid.v4())

    const [activeStartDate, setActiveStartDate] = useState(new Date()) // .valueOf()
    const [periodic, setPeriodic] = useState(selectedScheduleView) // weekly

    const viewToday = () => {
        setActiveStartDate(new Date())
        setCalendarUUID(uuid.v4())
    }

    const shiftPeriodic = () => {
        const val = periodic === "weekly" ? "monthly" : "weekly"
        setPeriodic(val)
        setSelectedScheduleView(val)
    }

    const handleSwipeChangeIndex = (index) => {
        const val = index === 1 ? "monthly" : "weekly"
        setPeriodic(val)
        setSelectedScheduleView(val)
    }

    useEffect(() => {
        setWeeklyCalendarViewUUID(uuid.v4())
    }, [activeStartDate])

    const goToAddWorkSchedule = () => {
        const currentDate = moment(new Date()).format("YYYY-MM-DD")
        // disable past dates
        const selectedDate = moment(activeStartDate, "YYYY-MM-DD").isBefore(moment(currentDate, "YYYY-MM-DD")) ? currentDate : activeStartDate
        history.push("/work-schedule/manage/0/" + moment(selectedDate).format("YYYY-MM-DD") + "/08:00")
    }

    const [expandedScheduleDays, setExpandedScheduleDays] = useState([])

    const enumerateDaysBetweenDates = (startDate, endDate) => {
        var dates = []
        
        var currDate = moment(startDate).startOf('day')
        var lastDate = moment(endDate).startOf('day')

        if (currDate.isSame(lastDate)) {
            dates.push(moment(currDate.clone().toDate()).format('YYYY-MM-DD'))
        } else {
            if (startDate !== "") dates.push(startDate)
            while (currDate.add(1, 'days').diff(lastDate) <= 0) {
                dates.push(moment(currDate.clone().toDate()).format('YYYY-MM-DD'))
            }
        }
        // if(endDate !== "" && endDate !== startDate) dates.push(endDate)

        return dates
    }

    // const enumerateDaysOfWeeksBetweenDates = (startDate, endDate, daysOfWeek) => {
    //     let start = moment(startDate)
    //     let end = moment(endDate)
    //     var dow = daysOfWeek
    //     var nosOfDay = { Sunday: 0, Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6 }
    //     var dates = []

    //     dow.forEach((item) => {
    //         var current = start.clone()

    //         if (moment(current.day(nosOfDay[item]).clone().toDate()).isSameOrAfter(start)) {
    //             dates.push(moment(current.day(nosOfDay[item]).clone().toDate()).format('YYYY-MM-DD'))
    //         }

    //         while (current.day(7 + nosOfDay[item]).isSameOrBefore(end)) {
    //             dates.push(moment(current.clone().toDate()).format('YYYY-MM-DD'))
    //         }
    //     })

    //     return dates
    // }

    // const enumerateDaysOfYearsBetweenDates = (startDate, endDate) => {
    //     var currDate = moment(startDate).startOf('day')
    //     var lastDate = moment(endDate).startOf('day')
    //     var dates = []

    //     dates.push(moment(currDate.clone().toDate()).format('YYYY-MM-DD'))
    //     while (currDate.add(1, 'years').diff(lastDate) <= 0) {
    //         dates.push(moment(currDate.clone().toDate()).format('YYYY-MM-DD'))
    //     }

    //     return dates
    // }

    // const enumerateDaysOfMonthsBetweenDates = (startDate, endDate) => {
    //     var currDate = moment(startDate).startOf('day')
    //     var lastDate = moment(endDate).startOf('day')
    //     var dates = []

    //     dates.push(moment(currDate.clone().toDate()).format('YYYY-MM-DD'))
    //     while (currDate.add(1, 'months').diff(lastDate) <= 0) {
    //         dates.push(moment(currDate.clone().toDate()).format('YYYY-MM-DD'))
    //     }

    //     return dates
    // }

    const consolidateScheduleData = (schedule_data) => {
        var schedule_data_copy = ImmutableJS.fromJS(schedule_data).toJS()
        var consolidatedScheduleData = []

        // console.log("schedule_data_copy.schedules")
        // console.log(schedule_data_copy.schedules)
        schedule_data_copy.schedules.forEach(schedule => {
            // if(!schedule.client_deleted) {
            // REAL: must not be embedded
            if (globals.v2.SCHEDULE.REPEAT_TYPE_TO_DISPLAY.includes(schedule.repeat_type)) {
                // console.log(schedule.repeat_type)
                schedule.section = "schedules"
                schedule.section_original = schedule.section
                consolidatedScheduleData.push(schedule)
            }
            // }
        })
        // console.log("consolidatedScheduleData")
        // console.log(consolidatedScheduleData)

        // Real: no dayoff
        // schedule_data_copy.non_working_days.personal.forEach(schedule => {
        //     // if(!schedule.client_deleted) {
        //     schedule.section = "personal"
        //     schedule.section_original = schedule.section
        //     consolidatedScheduleData.push(schedule)
        //     // }
        // })
        return consolidatedScheduleData
    }

    const processScheduleData = (propsScheduleData) => {
        var scheduleData = consolidateScheduleData(propsScheduleData)

        var scheduleDates = []
        var localScopeExpandedScheduleDays = []
        // console.log("scheduleData")
        // console.log(scheduleData)
        scheduleData.forEach(schedule => {
            // console.log(schedule)
            if (schedule.type === "custom" || (schedule.type === "recurring" && schedule.repeat_type === "every_day")) { // non recuring
                // console.log("enumerateDaysBetweenDates(schedule.date_from, schedule.date_to")
                scheduleDates = enumerateDaysBetweenDates(schedule.date_from, schedule.date_to)
                // } else if (schedule.type === "recurring" && schedule.repeat_type === "every_week") {
                //     scheduleDates = enumerateDaysOfWeeksBetweenDates(schedule.date_from, schedule.date_to, schedule.days)
                // } else if (schedule.type === "recurring" && schedule.repeat_type === "every_year") {
                //     scheduleDates = enumerateDaysOfYearsBetweenDates(schedule.date_from, schedule.date_to)
                // } else if (schedule.type === "recurring" && schedule.repeat_type === "every_n_date") {
                //     scheduleDates = enumerateDaysOfMonthsBetweenDates(schedule.date_from, schedule.date_to)
            } else if (schedule.type === "recurring" && schedule.repeat_type === "every_n_week") {
                scheduleDates = helper.generateDates(schedule.repeat_interval, moment(schedule.date_from, "YYYY-MM-DD"), moment(schedule.date_to, "YYYY-MM-DD"), schedule.days)
                //  + " 0:00:00 AM"
                //  h:mm:ss a

                // console.log("schedule")
                // console.log(schedule)

                // console.log("scheduleDates recurring && every_n_week")
                // console.log(scheduleDates)
            }

            scheduleDates.forEach(date => {
                var immuSchedule = ImmutableJS.fromJS(schedule)
                var newImmuSchedule = immuSchedule.toJS()
                // to have unique id for reactjs element
                newImmuSchedule.immuKey = uuid.v4()
                // will use later in calendar UI
                Object.assign(newImmuSchedule, {
                    original_date: {
                        date_from: schedule.date_from,
                        date_to: schedule.date_to
                    }
                })
                newImmuSchedule.date_from = date
                newImmuSchedule.date_to = date

                localScopeExpandedScheduleDays = localScopeExpandedScheduleDays.concat(newImmuSchedule)
            })
        })

        // sorting by date and time
        // Real: not yet in use
        // localScopeExpandedScheduleDays = this.sortSchedules(localScopeExpandedScheduleDays)

        // trim down and remove client_deleted entries
        localScopeExpandedScheduleDays = localScopeExpandedScheduleDays.filter(schedule => {
            return !schedule.client_deleted
        })

        setExpandedScheduleDays(localScopeExpandedScheduleDays)
    }

    useEffect(() => {
        if (scheduleData.schedules) {
            console.log("scheduleData")
            console.log(scheduleData)
            processScheduleData(scheduleData)
        }
    }, [scheduleData])

    return (
        <>
            <div className={classes.root}>
                <main className={classes.content}>
                    <div className={classNames(classes.mainContainer)}>
                        <ApplicationBar />

                        <div className={classes.titleContainer}>
                            <Grid
                                container
                                alignItems="center"
                                justify="space-between"
                            >
                                <div>
                                    <Typography className={classes.title}>Work Schedule</Typography>
                                </div>
                                <Button
                                    color="primary"
                                    className={classes.todayButton}
                                    onClick={viewToday}
                                >Today</Button>
                            </Grid>
                        </div>

                        <Grid
                            container
                            alignItems="center"
                            justify="center"
                            className={classes.periodicSwitch}
                            onClick={shiftPeriodic}
                        >
                            <Typography className={classNames(classes.periodicLabel, periodic === "weekly" && "active")}>Weekly</Typography>
                            <Typography className={classNames(classes.periodicLabel, periodic === "monthly" && "active")}>Monthly</Typography>
                        </Grid>

                        <SwipeableViews
                            className={classes.swipeableContent}
                            axis={'x'}
                            index={periodic === "weekly" ? 0 : 1}
                            onChangeIndex={handleSwipeChangeIndex}
                            animateHeight={periodic === "weekly" ? true : false}
                        >
                            <Weekly
                                history={history}
                                weeklyCalendarUUID={weeklyCalendarViewUUID}
                                activeStartDate={activeStartDate}
                                expandedScheduleDays={expandedScheduleDays}
                            />
                            <Monthly
                                history={history}
                                calendarUUID={calendarViewUUID} // needed to refresh calendar on set activeStartDate=today
                                activeStartDate={activeStartDate}
                                onSetActiveStartDate={setActiveStartDate}
                                expandedScheduleDays={expandedScheduleDays}
                            />
                        </SwipeableViews>
                    </div>

                    <div className={classes.btnAddScheduleContainer}>
                        <Button
                            color="primary"
                            className={classes.btnAddSchedule}
                            onClick={goToAddWorkSchedule}
                        >Add Schedule</Button>
                    </div>

                    <SimpleBottomNavigation />
                </main>
            </div>
        </>
    )
}

const WorkSchedule = withCookies(
    connect(mapStateToProps, mapDispatchToProps)(
        withStyles(styles, { withTheme: true })(WorkScheduleFunctionClass)
    )
)
export default WorkSchedule
