import React, {Component} from 'react';
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles';

import styles from "./styles.js";
// import { ReactComponent as PrintSVG } from 'components/common/mobile/CustomOfflineSVG/print.svg';
// import { ReactComponent as PrintDisabledSVG } from 'components/common/mobile/CustomOfflineSVG/print_disabled.svg';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
//import withMobileDialog from '@material-ui/core/withMobileDialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
 

// 
// import { makeStyles } from '@material-ui/core/styles/';
// import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import Collapse from '@material-ui/core/Collapse';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import DraftsIcon from '@material-ui/icons/Drafts';
// import SendIcon from '@material-ui/icons/Send';
// import ExpandLess from '@material-ui/icons/ExpandLess';
// import ExpandMore from '@material-ui/icons/ExpandMore';
// import StarBorder from '@material-ui/icons/StarBorder';
import DescriptionIcon from '@material-ui/icons/Description';


// ===


const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing.unit * 2,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing.unit,
      top: theme.spacing.unit,
      color: theme.palette.grey[500],
    },
  }))(props => {
    const { children, classes, onClose } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  


class PrintLabelDialog extends Component {
  
 
constructor(props) {
    super(props);
    // console.log("PrintLabelDialog constructor....")
    this.state = {
        ...this.state,
        displayPrintLabels: false,
        labels : []
    }
  } // constructor 
  
    showDocumentList = (labels) => {
        this.setState({...this.state, labels: labels }, ()=>{
            this.setDisplayPrintLabelsDialog(true)
        })
    }

    componentDidMount() {
        if(typeof this.props.showDocumentList === "function") {
            // console.log("Setting showDocumentList ")
            this.props.showDocumentList(this.showDocumentList)
           
        }
    }
  
    componentDidUpdate(prevProps, prevState) {
     //   console.log("componentDidUpdate")
    }

    handleClosePrintDialog =()=>{
        this.setDisplayPrintLabelsDialog(false)
    }

    handleSubmitSkipReason =()=>{

    }

    setDisplayPrintLabelsDialog=(value)=>{
        this.setState({...this.state, displayPrintLabels: value })
    }


  render() {
    const {classes} = this.props; 
    const labels = this.state.labels
    const onClick = this.props.onClick
    return(<div> 

                <Dialog
                id="skip-patient-reason-dialog"
                fullScreen={false}
                classes={{
                  paper: classes.dialogPaper
                }}
                open={this.state.displayPrintLabels}
                // onClose={this.handleCloseSkipReasonDialog}
                // onEnter={this.handleOnShowDialog}
                aria-labelledby="responsive-dialog-title"
                >
                <DialogTitle id="responsive-dialog-title" onClose={this.handleClosePrintDialog}>Print Documents</DialogTitle>
                <DialogContent>
                    <br/>

                  <List
                      component="nav"
                      aria-labelledby="nested-list-subheader"
                      // subheader={
                      //     <ListSubheader component="div" id="nested-list-subheader">
                      //     Documents
                      //     </ListSubheader>
                      // }
                      className={classes.printLabelsListContainer}
                  >
                
                  {labels.map(function(name, index) {
                      console.log("labels")
                      //console.log(labels)
                      return(      
                      <ListItem
                          key={index}
                          button
                          className={classes.printLabelList}
                          onClick={() => {onClick(labels[index])}} 
                      >
                      <ListItemIcon>
                          <DescriptionIcon />
                          </ListItemIcon>
                          <ListItemText className={classes.printLabelListItemText} primary={labels[index].filename} />
                      </ListItem>
                      )
                    })}
                    
                  
                  {/* <ListItem button>
                      <ListItemIcon>
                      <DraftsIcon />
                      </ListItemIcon>
                      <ListItemText primary="Drafts" />
                  </ListItem> */}
                  
                  </List>


                </DialogContent>
                <DialogActions>
                  {/* <Button onClick={this.handleCloseSkipReasonDialog} className={classNames(classes.signatureButton)} variant="contained" color="default">Close</Button> */}
                  <Button onClick={this.handleClosePrintDialog} className={classNames(classes.signatureButton)} variant="contained" color="secondary">Close</Button>
                </DialogActions>
              </Dialog>
              </div>
        )

  }

}

export default (withStyles(styles)(PrintLabelDialog))