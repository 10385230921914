import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import ApplicationBar from 'components/common/mobile/ApplicationBar';
import SimpleBottomNavigation from 'components/common/mobile/SimpleBottomNavigation';
import { connect } from "react-redux";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// import IconButton from '@material-ui/core/IconButton';
// import EditIcon from '@material-ui/icons/Edit';
// import SettingsIcon from '@material-ui/icons/Settings'; 
import Checkbox from '@material-ui/core/Checkbox';
import styles from "./styles.js";
import { Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import Calendar from 'react-calendar/dist/entry.nostyle';
// import $ from 'jquery';
import ImmutableJS from "immutable";
import uuid from "uuid";

import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';

import { logEvent} from "js/actions/index";
import * as helper from 'components/common/Helper.js';

const mapDispatchToProps = dispatch => {
    return {
        logEvent: event => dispatch(logEvent(event))
    }
}

const mapStateToProps = state => {
    return {
        drawData: state.drawData,
        scheduleData: state.scheduleData

    };
};

// for truehealth_demo comment //

const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing.unit * 2,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing.unit,
      top: theme.spacing.unit,
      color: theme.palette.grey[500],
    },
  }))(props => {
    const { children, classes, onClose } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});

class PhlebSchedulesV2Class extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        const { cookies } = this.props;

        // list of days from schedule rules
        this.scheduleRules = [];
        // this.expandedScheduleDays = [];
        this.schedulesRelatedToSelectedDay = [];
        this.activeStartDate = moment(new Date()).valueOf();

        var showPastDates = false;
        if(cookies.get('show_past_dates')) {
            showPastDates = cookies.get('show_past_dates') === "true" ? true :false
        }

        this.state = {
            ...this.state,
            // currentActiveStartDate: moment(new Date()).valueOf(),
            calendarUUID: uuid.v4(),
            editScheduleDialogOpened: false,
            selectedCalendarDate: new Date(),
            dateValue: new Date(),
            expandedScheduleDays: [],
            selectedView: cookies.get('selected_calendar_view') ? cookies.get('selected_calendar_view') : "calendar", // ["list", "schedule"]
            showPastDates: showPastDates, // ["list", "schedule"]
            scheduleListDisplayLimit: 500,
        }
    }

    sortSchedules = (entries) => {
        return entries.sort(function(a, b) {
            if (a.date_from !== "" && a.date_from === b.date_from) {
                // if date is same, sort by time
                return moment(new Date(a.date_from + " " + a.time_from)) < moment(new Date(b.date_from + " " + b.time_from)) ? 1 : -1;
            }
            return moment(new Date(a.date_from)) < moment(new Date(b.date_from)) ? 1 : -1;
        });
    }

    updateScheduleData(propsScheduleData) {
        var scheduleData = this.consolidateScheduleData(propsScheduleData);

        // console.log(propsScheduleData)
        var enumerateDaysBetweenDates = function(startDate, endDate) {
            var dates = [];

            var currDate = moment(startDate).startOf('day');
            var lastDate = moment(endDate).startOf('day');

            if(startDate !== "") dates.push(startDate);
            while(currDate.add(1, 'days').diff(lastDate) <= 0) {
                dates.push(moment(currDate.clone().toDate()).format('YYYY-MM-DD'));
            }
            // if(endDate !== "" && endDate !== startDate) dates.push(endDate);

            return dates;
        };

        var enumerateDaysOfWeeksBetweenDates = function(startDate, endDate, daysOfWeek) {
            let start = moment(startDate);
            let end = moment(endDate);
            var dow = daysOfWeek;
            var nosOfDay = {Sunday:0,Monday:1,Tuesday:2,Wednesday:3,Thursday:4,Friday:5,Saturday:6};
            var dates = [];          

            dow.forEach((item) => {
                var current = start.clone(); 

                if( moment(current.day(nosOfDay[item]).clone().toDate()).isSameOrAfter(start) ) {
                    dates.push(moment(current.day(nosOfDay[item]).clone().toDate()).format('YYYY-MM-DD'));
                }

                while (current.day(7 + nosOfDay[item]).isSameOrBefore(end)) {
                    dates.push(moment(current.clone().toDate()).format('YYYY-MM-DD'));
                }
            });
            
            return dates;
        };
        
        var enumerateDaysOfYearsBetweenDates = function(startDate, endDate) {
            var currDate = moment(startDate).startOf('day');
            var lastDate = moment(endDate).startOf('day');
            var dates = [];        

            dates.push(moment(currDate.clone().toDate()).format('YYYY-MM-DD'));
            while(currDate.add(1, 'years').diff(lastDate) <= 0) {
                dates.push(moment(currDate.clone().toDate()).format('YYYY-MM-DD'));
            }

            return dates;
        };
        
        var enumerateDaysOfMonthsBetweenDates = function(startDate, endDate) {
            var currDate = moment(startDate).startOf('day');
            var lastDate = moment(endDate).startOf('day');
            var dates = [];        

            dates.push(moment(currDate.clone().toDate()).format('YYYY-MM-DD'));
            while(currDate.add(1, 'months').diff(lastDate) <= 0) {
                dates.push(moment(currDate.clone().toDate()).format('YYYY-MM-DD'));
            }

            return dates;
        };

        // var enumerateDaysOfMonthsWeekBetweenDates = function(startDate, endDate, weekNumber) {
        //     var currDate = moment(startDate).startOf('day');
        //     var lastDate = moment(endDate).startOf('day');
        //     var dates = [];     
        //     var weekNumber = Math.ceil(currDate.date() / 7);
        //     var noOfDay = currDate.day();
            
        //     dates.push(moment(currDate.clone().toDate()).format('YYYY-MM-DD'));
        //     while(currDate.add(1, 'months').diff(lastDate) <= 0) {
        //         var myMonth = moment(currDate).startOf('month');
        //         var firstDayOfWeek = myMonth.clone().weekday(noOfDay);  
                
        //         if( firstDayOfWeek.month() !== myMonth.month() ) {
        //             weekNumber++;
        //         }
        //         dates.push(firstDayOfWeek.add(weekNumber-1, 'weeks').format('YYYY-MM-DD'));
        //     }
        //     return dates;
        // };

        // var enumerateDaysEveryNWeekBetweenDates = function(startDate, endDate, weekNumber) {
        //     // console.log("enumerateDaysEveryNWeekBetweenDates");
        //     // var currDate = moment(startDate).startOf('day');
        //     // var lastDate = moment(endDate).startOf('day');
        //     // console.log("Start Date: " + currDate.format("MM-DD-YYYY"))
        //     // console.log("End Date: " + lastDate.format("MM-DD-YYYY"))
        //     // var dates = []; 
        //     // while(currDate.add(1, 'months').diff(lastDate) <= 0) {
        //     //     var currMonth = moment(currDate).startOf('month');
        //     //     console.log("month of " + currDate + ": " + currMonth)
        //     // }
        //     // return dates;    
        //     return [];
        // };

        var scheduleDates = [];
        var localScopeExpandedScheduleDays = [];

        scheduleData.forEach(schedule => {
            if(schedule.type === "custom" || (schedule.type === "recurring" && schedule.repeat_type === "every_day")) { // non recuring
                scheduleDates = enumerateDaysBetweenDates(schedule.date_from, schedule.date_to);
                // console.log(schedule.type === "custom" || (schedule.type === "recurring" && schedule.repeat_type === "every_day")")
                // console.log(scheduleDates)
            } else if(schedule.type === "recurring" && schedule.repeat_type === "every_week") {
                scheduleDates = enumerateDaysOfWeeksBetweenDates(schedule.date_from, schedule.date_to, schedule.days);
                // console.log("schedule.type === recurring' && schedule.repeat_type === 'every_week'")
                // console.log(scheduleDates)
            } else if(schedule.type === "recurring" && schedule.repeat_type === "every_year") {
                scheduleDates = enumerateDaysOfYearsBetweenDates(schedule.date_from, schedule.date_to);
                // console.log("schedule.type === recurring' && schedule.repeat_type === 'every_year'")
                // console.log(scheduleDates)
            } else if(schedule.type === "recurring" && schedule.repeat_type === "every_n_date") {
                scheduleDates = enumerateDaysOfMonthsBetweenDates(schedule.date_from, schedule.date_to);
                // console.log("schedule.type === recurring' && schedule.repeat_type === 'every_n_date'")
                // console.log(scheduleDates)
            } else if(schedule.type === "recurring" && schedule.repeat_type === "every_n_week") {
                // scheduleDates = enumerateDaysEveryNWeekBetweenDates(schedule.date_from, schedule.date_to, schedule.repeat_interval);
                console.log("schedule.date_from")
                // console.log(schedule.date_from)
                console.log(moment(schedule.date_from + " 8:00:00 AM","YYYY-MM-DD h:mm:ss a") )
                scheduleDates = helper.generateDates(schedule.repeat_interval, moment(schedule.date_from + " 8:00:00 AM","YYYY-MM-DD h:mm:ss a"),  moment(schedule.date_to + " 8:00:00 AM","YYYY-MM-DD h:mm:ss a"), schedule.days);
                // console.log("schedule.type === recurring' && schedule.repeat_type === 'every_n_week'")
                // console.log(scheduleDates)
            };

            // console.log(scheduleDates)
            scheduleDates.forEach(date => {
                var immuSchedule = ImmutableJS.fromJS(schedule);
                var newImmuSchedule = immuSchedule.toJS();
                // to have unique id for reactjs element
                newImmuSchedule.immuKey = uuid.v4();
                // will use later in calendar UI
                Object.assign(newImmuSchedule, {
                    original_date: {
                        date_from: schedule.date_from,
                        date_to: schedule.date_to
                    }
                });
                newImmuSchedule.date_from = date;
                newImmuSchedule.date_to = date;
                
                localScopeExpandedScheduleDays = localScopeExpandedScheduleDays.concat(newImmuSchedule);
            });
        });

        // sorting by date and time
        localScopeExpandedScheduleDays = this.sortSchedules(localScopeExpandedScheduleDays);

        // trim down and remove client_deleted entries
        localScopeExpandedScheduleDays = localScopeExpandedScheduleDays.map(schedule => {
            // return (schedule.client_deleted === false) ? schedule : ("");
            return schedule;
        });

        // remove entries with empty values
        localScopeExpandedScheduleDays = [...new Set(localScopeExpandedScheduleDays)];

        this.setState({
            ...this.state, 
            expandedScheduleDays: localScopeExpandedScheduleDays,            
            calendarUUID: uuid.v4(), 
            // activeStartDate: this.state.currentActiveStartDate
        });
    }

    componentWillMount() {
        // console.log('PhlebSchedulesV2Class componentWillMount');
        this.updateScheduleData(this.props.scheduleData);
        // NOTE: this.expandedScheduleDays when props is updated(issue here is calendar data is not updating)
    }

    componentDidMount() {
        this.props.logEvent({module: "schedules", action: "visit_schedules_page", description: "Visited Schedules Page"})
    }

    componentDidUpdate(previousProps) {
        // var temp = "PhlebSchedulesV2Class"

        if(previousProps.scheduleData !== this.props.scheduleData) {
            // temp = temp + " HAVE UPDATE = TRUE"
            // console.log("previous Props")
            // console.log(previousProps.scheduleData)
            // console.log("this.state.currentActiveStartDate")
            // console.log(this.state.currentActiveStartDate)
            // console.log("updated Props")
            // console.log(this.props.scheduleData)

            this.updateScheduleData(this.props.scheduleData);
        } else {
            // temp = temp + " HAVE UPDATE = FALSE";
        }
        // console.log(temp)
    }

    goToAddEditSchedule = (id, date=null) => {
        // console.log(id)
        // console.log(date)
        var selected_date = (date) ? date : this.state.selectedCalendarDate;
        this.goToSite("/add_edit_schedule/" + id + "/" + moment(selected_date).format('MMMM-D-YYYY'));
    };

    handleSwitchView = (switchType) => {
        const { cookies } = this.props;
        cookies.set('selected_calendar_view', switchType, { path: '/'});
        this.setState({...this.state, selectedView: switchType});
    }

    goToSite(url) {
        this.props.history.push(url);
    }

    presetDays() {
        return [
            {key: "sunday", code: "S", label: "Sunday" },
            {key: "monday", code: "M", label: "Monday" },
            {key: "tuesday", code: "T", label: "Tuesday" },
            {key: "wednesday", code: "W", label: "Wednesday" },
            {key: "thursday", code: "T", label: "Thursday" },
            {key: "friday", code: "F", label: "Friday" },
            {key: "saturday", code: "S", label: "Saturday" }
        ];
    }

    calendarToday = () => {
        this.activeStartDate = moment(new Date()).valueOf();

        this.setState({
            ...this.state, 
            calendarUUID: uuid.v4()
        });
    }

    // just circles for now, no connected on date range
    customizedCalendarTileContent = ({date, view}) => {
        var calendar_date = moment(date).format("YYYY-MM-DD");
        const halfWayOfMonth = moment(date).format('DD');

        // console.log("customizedCalendarTileContent")
        // console.log(this.state.expandedScheduleDays)

        // temp solution, get the current focus month by selecting Month-15(not using 1 coz it may have multiple 1 in Month view)
        if(parseInt(halfWayOfMonth) === 15) {
            this.activeStartDate = calendar_date.valueOf();
        }

        // check if day is found in the schedule
        var activeDays = this.state.expandedScheduleDays.filter(item => {
            return item.date_from === calendar_date;
        });

        var content = "";
        var fullOrange = false;

        if(activeDays.length > 0) {

            // console.log(activeDays);
            var schedule_section = [];
            // var belongsToRecurring = false;
            // var isRecurringFirstDate = false;
            // var isRecurringLastDate = false;

            // // will be used for date circle background css
            activeDays.forEach(scheduleEntry => {
                // // to check if date has recurring AND OR custom type
                schedule_section.push(scheduleEntry.section);

                if(scheduleEntry.section === "personal" && scheduleEntry.time_from === '00:00:00' && scheduleEntry.time_to === '23:59:59') {
                    fullOrange = true;
                }

                // // as of now it will support connected date range of recurring schedules
                // if(scheduleEntry.original_date.date_from === scheduleEntry.original_date.date_to) { // non-range

                // } else {
                //     if(scheduleEntry.type === "custom" || (scheduleEntry.type === "recurring" && scheduleEntry.repeat_value === "everyday")) {
                //         belongsToRecurring = true;
    
                //         // will occur only if the date is range
                //         if(scheduleEntry.original_date.date_from !== scheduleEntry.original_date.date_to) {
                //             if(scheduleEntry.date_from === scheduleEntry.original_date.date_from) {
                //                 isRecurringFirstDate = true;
                //             }
                //             if(scheduleEntry.date_to === scheduleEntry.original_date.date_to) {
                //                 isRecurringLastDate = true;
                //             }
                //         }
                //     }
                // }
            });

            // remove empty values
            var unique_schedule_type = [...new Set(schedule_section)];
            var circle_schedule_class = "";
            var bg_schedule_class = "";

            // css for date circle
            // console.log(unique_schedule_type);
            if(fullOrange) {
                circle_schedule_class = " custom-calendar-date-tile-personal";
            } else {
                if(unique_schedule_type.length > 1) {
                    circle_schedule_class = " custom-calendar-date-tile-both";
                } else {
                    if(schedule_section.length > 1) {
                        circle_schedule_class = (unique_schedule_type[0] === "schedules") ? " custom-calendar-date-tile-both-schedules" : " custom-calendar-date-tile-both-personal";
                    } else {
                        circle_schedule_class = (unique_schedule_type[0] === "schedules") ? " custom-calendar-date-tile-schedules" : " custom-calendar-date-tile-personal";
                    }
                }
            }

            // css for date circle background
            // if(belongsToRecurring) {
            //     if(isRecurringFirstDate) {
            //         bg_schedule_class = (unique_schedule_type[0] === "schedules") ? " custom-calendar-date-tile-bg-first-date-recurring-schedules" : " custom-calendar-date-tile-bg-first-date-recurring-personal";
            //     } else if(isRecurringLastDate) {
            //         bg_schedule_class = (unique_schedule_type[0] === "schedules") ? " custom-calendar-date-tile-bg-last-date-recurring-schedules" : " custom-calendar-date-tile-bg-last-date-recurring-personal";
            //     } else {
            //         bg_schedule_class = (unique_schedule_type[0] === "schedules") ? " custom-calendar-date-tile-bg-belongs-to-recurring-schedules" : " custom-calendar-date-tile-bg-belongs-to-recurring-personal"
            //     }
            // }

            content = <div className={bg_schedule_class}>
                        <div className={"custom-calendar-date-tile" + circle_schedule_class}>
                            {date.getDate()}
                        </div>
                    </div>;
        } else {
            content = <div className="custom-calendar-date-tile">{date.getDate()}</div>;
        }

        return view === 'month' && content;
    }

    tiggerEditScheduleDialogOpened = (selectedDate) => {
        this.setState({...this.state, editScheduleDialogOpened: !this.state.editScheduleDialogOpened, selectedCalendarDate: selectedDate});
    };

    onClickCalendarDay = (value) => {
        this.getRelatedRulesToDate(value);
        if(this.schedulesRelatedToSelectedDay.length === 0) {
            this.goToAddEditSchedule(0, value);
        } else {
            this.tiggerEditScheduleDialogOpened(value);
        }
    }

    getRelatedRulesToDate = (selectedDate) => {
        var schedules = this.state.expandedScheduleDays;

        var filteredRules = [];
        schedules.forEach(data => {
            if((moment(selectedDate).isBetween(moment(data.date_from), moment(data.date_to), null, '[]'))) {
                filteredRules.push(data);
            }
        });

        this.schedulesRelatedToSelectedDay = filteredRules;
    }

    consolidateScheduleData = (schedule_data) => {
        var schedule_data_copy = ImmutableJS.fromJS(schedule_data).toJS();
        var consolidatedScheduleData = [];

        schedule_data_copy.schedules.forEach(schedule => {
            // if(!schedule.client_deleted) {
                schedule.section = "schedules";
                schedule.section_original =  schedule.section;
                consolidatedScheduleData.push(schedule);
            // }
        });

        schedule_data_copy.non_working_days.personal.forEach(schedule => {
            // if(!schedule.client_deleted) {
                schedule.section = "personal";
                schedule.section_original =  schedule.section;
                consolidatedScheduleData.push(schedule);
            // }
        });
        // console.log(consolidatedScheduleData);
        return consolidatedScheduleData;
    }

    // same with AddEditSchedule, should be placed globally
    // loop until date_to
    // push Day of Week if not yet added
    // end loop if 7 Days is completed or date_to is done
    iterateDaysBetweenDates = (date_from, date_to) => {
        var enabledDaysOfWeek = [];
        var a = moment(date_from);
        var b = moment(date_to);

        for (var m = moment(a); m.isSameOrBefore(b); m.add(1, 'days')) {
            if(!enabledDaysOfWeek.includes(m.format('dddd'))) {
                enabledDaysOfWeek.push(m.format('dddd'));
            }

            // console.log(m.format('MM-DD-YYYY') + " = " + m.format('dddd'));
            if(enabledDaysOfWeek.length === 7) break;
        }

        // console.log("date_from: " + date_from + " -- date_to: " + date_to);
        // console.log("enabledDaysOfWeek")
        // console.log(enabledDaysOfWeek)
        return enabledDaysOfWeek;
    }
    
    gotoEditScheduleSettings = () => {
        this.goToSite('/schedule_settings');
    }

    handleShowPastDatesCheckboxChange = () => event => {
        const { cookies } = this.props;
        cookies.set('show_past_dates', event.target.checked, { path: '/'});
        this.setState({...this.state, showPastDates: event.target.checked});
    }

    render() {
        const { classes } = this.props;
        var activeYear = moment(this.activeStartDate).format("YYYY");
        var activeMonth = moment(this.activeStartDate).format("MM");
        // console.log(this.props.scheduleData);
        // SMTWTHFS
        // console.log("this.presetDays()")
        // console.log(this.presetDays())
        const SelectedScheduleDays = (schedule) => (
            <div>
                {/* <Typography variant="caption" style={{"display": "inline"}}>{schedule.id.substr(0, 5)}...{schedule.id.substr(schedule.id.length - 6, 5)}</Typography><br/> */}
                {
                    this.presetDays().map(function(item, key) {
                    // merge current selected Day of week and Day produced by date range when no Day of week is selected
                    var merge_days = schedule.days;
                    // console.log(schedule)
                    // console.log("schedule.type: " + schedule.type)
                    if(schedule.type === "custom" || schedule.repeat_type === "every_day") {
                        merge_days = [...new Set([...schedule.days ,...this.iterateDaysBetweenDates(schedule.date_from, schedule.date_to)])];
                    }
                    var day_exists = merge_days.map(x => x.toLowerCase()).indexOf(item.key);
                    
                    return <Typography key={key} className={classNames(
                        classes.labelDay,
                        (day_exists !== -1 && schedule.section === "schedules") ? classes.labelDayBlueSelected : "",
                        (day_exists !== -1 && schedule.section === "personal") ? classes.labelDayOrangeSelected : ""
                    )}>{item.code}</Typography>;
                }, this)}
            </div>
        );

        const Schedule = ({schedule}) => (
            <ListItem
                id={schedule.id}
                onClick={() => {this.goToAddEditSchedule(schedule.id);}}
                button
                className={classNames(classes.list, (schedule.section === "schedules") ? classes.workdayScheduleList : classes.timeOffScheduleList )}
            >
                <ListItemText
                    classes={{primary: (schedule.section === "schedules") ? classes.scheduleLabelRecuring : classes.scheduleLabelTimeOff }}
                    primary={moment(schedule.date_to).format('dddd, MMM Do, YYYY')}
                />
                <ListItemSecondaryAction className={classes.scheduleListRightSection}>
                    <Typography
                        onClick={() => {this.goToAddEditSchedule(schedule.id);}}
                        className={classNames((schedule.section === "schedules") ? classes.scheduleLabelRecuring : classes.scheduleLabelTimeOff, classes.withMarginRight)}>
                        {moment(schedule.time_from, "hh:mm:ss").format('LT') + ' - ' + moment(schedule.time_to, "hh:mm:ss").format('LT')}
                    </Typography>
                </ListItemSecondaryAction>
            </ListItem>
        );

        const ScheduleRule = ({schedule, isPastDate}) => (
            <ListItem
                id={schedule.id}
                onClick={() => {this.goToAddEditSchedule(schedule.id);}}
                button
                className={classNames(classes.list, (schedule.section === "schedules") ? classes.recurringScheduleListRule : classes.timeOffScheduleListRule )}
            >
                <ListItemText
                    classes={{primary: classes.scheduleLabel}}
                    // primary={moment(schedule.date_to).format('dddd, MMM Do, YYYY')}
                    children={SelectedScheduleDays(schedule)}
                />
                <ListItemSecondaryAction className={classes.scheduleListRightSection}>
                    { isPastDate && // schedule.locked
                        <Icon className={classes.lockIcon}>lock</Icon>
                    }
                    {/* { schedule.client_deleted &&
                        <Icon className={classes.forDeletionIcon}>delete</Icon>
                    } */}
                    <Typography
                        onClick={() => {this.goToAddEditSchedule(schedule.id);}}
                        className={classNames((schedule.section === "schedules") ? classes.scheduleLabelRecuring : classes.scheduleLabelTimeOffRule, classes.withMarginRight)}>
                        {moment(schedule.time_from, "hh:mm:ss").format('LT') + ' - ' + moment(schedule.time_to, "hh:mm:ss").format('LT')}<br />
                        {moment(schedule.original_date ? schedule.original_date.date_from : schedule.date_from).format('M/D/YY') + ' - ' + moment(schedule.original_date ? schedule.original_date.date_to : schedule.date_to).format('M/D/YY')}
                    </Typography>
                </ListItemSecondaryAction>
            </ListItem>
        );

        var shedulesList = [];
        var schedulesRelatedToSelectedDayList = [];
        var consolidatedScheduleRules = [];
        var sortedScheduleRules = [];
        var scheduleRules = [];

        // temporary solution so that data will not be processed evertime we change view
        // solution should be, processing of data is outside render
        if(this.state.selectedView === "list") {
            // console.log("this.state.selectedView === 'list'")
            // console.log("this.expandedScheduleDays")

            // group by date_from
            var consolidateWithDate = this.state.expandedScheduleDays.reduce(function (r, a) {
                r[a.date_from] = r[a.date_from] || [];
                r[a.date_from].push(a);
                return r;
            }, Object.create(null));
        
            // grouped used to compare overlapping dates
            var processedScheduleList = [];
            
            for (var key in consolidateWithDate) {
                var schedule = consolidateWithDate[key];
                if(schedule.length > 1) {
                    // console.log("compare values here...")
                    // eslint-disable-next-line no-loop-func
                    schedule.forEach(value => {
                        var shouldBeDisplayed = true;

                        // if leave and all day, matic will be displayed
                        if(value.section === "personal" && value.time_from === '00:00:00' && value.time_to === '23:59:59') {
                            // shouldBeDisplayed = true;
                        } else {
                            // loop again to compare to all if schedule will be displayed
                            schedule.forEach(value2 => {
                                if(value.id !== value2.id) {
                                    if(value2.section === "personal" && value2.time_from === '00:00:00' && value2.time_to === '23:59:59') {
                                        shouldBeDisplayed = false;
                                    } else {
                                        // check overlap time here, if inside time range of personal schedule, dont display
                                        // console.log("intial value: " + value.section + " > " + value.date_from + " > " + value.time_from + " === " + value.time_to + " value to compare: " + value2.section + " > " + value2.date_from + " > " + value2.time_from + " === " + value2.time_to)
                                    }
                                }
                            })
                        }

                        // if(value.client_deleted === false && shouldBeDisplayed) {
                        if(shouldBeDisplayed) {
                            // returnValue = <Schedule key={value.immuKey} schedule={value} />;
                            processedScheduleList.push(value);
                        }
                    })
                } else {
                    var data = schedule[0];
                    // if(data.client_deleted === false) {
                        // returnValue = <Schedule key={data.immuKey} schedule={data} />;
                        processedScheduleList.push(data)
                    // }
                }
            }

            shedulesList = Object.values(processedScheduleList).filter((item, idx) => idx < this.state.scheduleListDisplayLimit).map(schedule => {
                var data = "";
                // console.log(schedule)
                if(this.state.showPastDates) {
                    data = <Schedule key={schedule.immuKey} schedule={schedule} />;
                } else {
                    if(moment().isBefore(schedule.date_from) || moment(schedule.date_from).isSame(moment(), 'day')) {
                        data = <Schedule key={schedule.immuKey} schedule={schedule} />;
                    }
                }

                return data;
            });
            // console.log("slc " + shedulesList.length)
        }

        if(this.state.selectedView === "calendar") {
            // console.log("this.state.selectedView === 'calendar'")  
            schedulesRelatedToSelectedDayList = this.schedulesRelatedToSelectedDay.map(schedule => {
                // return (schedule.client_deleted === false) ? (<ScheduleRule key={schedule.id} schedule={schedule} />) : ("");
                var date_now = moment().format("YYYY-MM-DD");
                var date_to = schedule.original_date.date_to;
                var is_past_date = moment(date_to, "YYYY-MM-DD").isBefore(date_now);
                // console.log(schedule)
                // console.log(date_now + " - " + date_to)
                // console.log("is past date: " + is_past_date)
                return <ScheduleRule key={schedule.id} schedule={schedule} isPastDate={is_past_date} />;
            });
        }

        if(this.state.selectedView === "rules") {
            consolidatedScheduleRules = this.consolidateScheduleData(this.props.scheduleData);
            sortedScheduleRules = this.sortSchedules(consolidatedScheduleRules);
            scheduleRules =  sortedScheduleRules.map(schedule => {
                var date_now = moment().format("YYYY-MM-DD");
                var date_to = schedule.date_to;
                var is_past_date = moment(date_to, "YYYY-MM-DD").isBefore(date_now);
                // console.log(date_to)
                // console.log(date_now + " - " + date_to)
                // console.log("is past date: " + is_past_date)
                return <ScheduleRule key={schedule.id} schedule={schedule} isPastDate={is_past_date} />;
            });
        }

        // var distanceValue = 0;
        // var unit =  "mi";
        
        // try {
        //     distanceValue = this.props.scheduleData.settings.distance_covered.value;
        //     unit =   this.props.scheduleData.settings.distance_covered.unit;
        // } catch(e) {}

        return(
            <div className={classes.root}>
                <main className={classes.content}>
                    <ApplicationBar isInfoHidden={true} />
                    <div className={classNames(classes.schedulesMainContainer)}>
                        <div>
                            <List classes={{root: classNames(classes.listUL, classes.listULMainHeader)}}>
                                <ListItem>
                                    <ListItemText
                                        classes={{
                                            primary: classes.schedulesMainHeader
                                        }}
                                        primary="Work Schedule"
                                    />
                                </ListItem>
                                {/* <ListItem classes={{root: classes.root_distanceCovered}}>
                                    <ListItemText
                                       
                                        classes={{
                                            
                                        }}
                                        children = {<div><Typography
                                            className={classes.distanceCovered}
                                        >Maximum travel radius <b> {distanceValue + ' ' + unit} </b>
                                        <EditIcon
                                            onClick={this.gotoEditScheduleSettings} 
                                            style={{color:"black", width: "16px", height: "16px"}} //#42a5f5
                                    /></Typography>
                                        </div>}
                                    />
                                </ListItem> */}
                            </List>
                        </div>

                        <List classes={{}}>
                            <ListItem className={classes.topNavButtonRightGutter}>
                                <Button
                                    // onClick={() => this.handleSwitchView("list")}
                                    variant="contained"
                                    classes={{
                                        root: classNames(
                                            classes.scheduleWhiteButtonLeftMargin,
                                            classes.topNavButton,
                                            classes.switchViewButton,
                                            classes.switchViewButtonLeft,
                                            classes.switchViewButtonSelected),
                                        label: classes.topNavButtonLabel
                                    }}>Working Hours</Button>
                                <Button
                                    onClick={() => this.goToSite("/future_orders")}
                                    variant="contained"
                                    classes={{
                                        root: classNames(
                                            classes.topNavButton,
                                            classes.switchViewButton,
                                            classes.switchViewButtonRight),
                                        label: classes.topNavButtonLabel
                                    }}>Upcoming Orders</Button>
                            </ListItem>
                            <ListItem>
                                <Button
                                    onClick={() => this.goToAddEditSchedule(0)}
                                    variant="contained"
                                    color="default"
                                    classes={{
                                        root: classNames(classes.scheduleWhiteButton),
                                        label: classNames(classes.scheduleWhiteButtonLabel)
                                    }}><Icon className={classes.scheduleWhiteButtonIcon}>add</Icon> New Hours</Button>
                                {(this.state.selectedView === "calendar") &&
                                <Button
                                    onClick={() => this.calendarToday()}
                                    variant="contained"
                                    color="default"
                                    classes={{
                                        root: classNames(classes.scheduleWhiteButton, classes.scheduleWhiteButtonLeftMargin),
                                        label: classNames(classes.scheduleWhiteButtonLabel)
                                    }}>Today</Button>
                                }
                                <ListItemSecondaryAction className={classes.switchViewButtonSecondary}>
                                    <Button
                                        onClick={() => this.handleSwitchView("list")}
                                        variant="contained"
                                        classes={{
                                            root: classNames(
                                                classes.switchViewButton,
                                                classes.switchViewButtonLeft,
                                                (this.state.selectedView === "list" ? classes.switchViewButtonSelected : "")),
                                            label: classes.switchViewButtonLabel
                                        }}><Icon>reorder</Icon></Button>
                                    <Button
                                        onClick={() => this.handleSwitchView("calendar")}
                                        variant="contained"
                                        classes={{
                                            root: classNames(
                                                classes.switchViewButton,
                                                // classes.switchViewButtonLeft,
                                                (this.state.selectedView === "calendar" ? classes.switchViewButtonSelected : "")),
                                            label: classes.switchViewButtonLabel
                                        }}><Icon>event</Icon></Button>
                                    <Button
                                        onClick={() => this.handleSwitchView("rules")}
                                        variant="contained"
                                        classes={{
                                            root: classNames(
                                                classes.switchViewButton,
                                                classes.switchViewButtonRight,
                                                (this.state.selectedView === "rules" ? classes.switchViewButtonSelected : "")),
                                            label: classes.switchViewButtonLabel
                                        }}><Icon>repeat</Icon></Button>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>

                        <div style={{"display": this.state.selectedView === "list" ? "block" : "none"}}>
                            <div className={classNames(classes.schedulesContainer)}>
                                <div className={classes.schedulesContent}>                                    
                                    <List component="nav">
                                        <ListItem className={classes.checkboxListContainer}>
                                            <Checkbox
                                                id="show-past-dates-chkbx"
                                                className={classes.checkboxListChkbx}
                                                checked={this.state.showPastDates}
                                                onChange={this.handleShowPastDatesCheckboxChange()}
                                                // value="isPatientNameVerified"
                                            /> 
                                            <label htmlFor="show-past-dates-chkbx" className={classes.distanceCovered}>Show Past Dates</label>
                                        </ListItem>
                                        <ListItem
                                            className={classNames(classes.list, classes.listHeader)}>
                                            <ListItemText
                                                classes={{primary: classes.listHeaderLabel}}
                                                primary={"Date"}
                                            />
                                            <ListItemSecondaryAction>
                                                <Typography
                                                    className={classNames(classes.listHeaderRightLabel)}>
                                                    Time
                                                </Typography>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        {(shedulesList.filter(function (el) {return el !== "";}).length > 0) &&
                                            shedulesList
                                        }
                                    </List>
                                        
                                    {(shedulesList.filter(function (el) {return el !== "";}).length === 0) &&
                                        <Grid
                                            container
                                            className={classes.schedulesGrid}
                                            spacing={16}
                                            alignItems="center"
                                            direction="row"
                                            justify="center"
                                        >
                                            <Grid item>
                                                <Typography
                                                    className={classes.placeholder}
                                                    align="center"
                                                    variant="caption"
                                                    gutterBottom
                                                >
                                                    Enable "Show Past Dates"/Add schedule to see them here
                                                </Typography>

                                            </Grid>
                                        </Grid>
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{"display": this.state.selectedView === "rules" ? "block" : "none"}}>
                            <div className={classNames(classes.schedulesContainer)}>
                                <div className={classes.schedulesContent}>
                                    {(scheduleRules.filter(function (el) {return el !== "";}).length)
                                        ?
                                        <List component="nav">
                                            <ListItem
                                                className={classNames(classes.list, classes.listHeader)}>
                                                <ListItemText
                                                    classes={{primary: classes.listHeaderLabel}}
                                                    primary={"Rule"}
                                                />
                                                <ListItemSecondaryAction>
                                                    <Typography
                                                        className={classNames(classes.listHeaderRightLabel)}>
                                                        Time
                                                    </Typography>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            {scheduleRules}
                                        </List>
                                        :
                                        <Grid
                                            container
                                            className={classes.schedulesGrid}
                                            spacing={16}
                                            alignItems="center"
                                            direction="row"
                                            justify="center"
                                        >
                                            <Grid item>
                                                <Typography
                                                    className={classes.placeholder}
                                                    align="center"
                                                    variant="caption"
                                                    gutterBottom
                                                >
                                                    Add schedule to see them here
                                                </Typography>

                                            </Grid>
                                        </Grid>
                                    }
                                </div>
                            </div>
                        </div>

                        <div style={{"display": this.state.selectedView === "calendar" ? "block" : "none"}}>
                            <div className={classNames(classes.schedulesContainer)}>
                                <br/>
                                <Calendar
                                    key={this.state.calendarUUID}
                                    // onChange={this.onDateChange}
                                    // formatShortWeekday={(locale, date) => this.formatCalendarDate(date, 'dd')}
                                    // showNavigation={true}
                                    // onactiveDaysChange ({ activeStartDate, view }) => alert('Changed view to: ', activeStartDate, view)
                                    // value={uniqueCalendarDays}
                                    activeStartDate={new Date(activeYear, activeMonth - 1, 1)}
                                    calendarType="US"
                                    view="month"
                                    className="modified-calendar"
                                    tileContent={this.customizedCalendarTileContent}
                                    onClickDay={this.onClickCalendarDay}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classes.toolbar} />
                    <div className={classes.toolbar} />
                </main>
                <SimpleBottomNavigation />

                <Dialog
                    id="edit-schedule-dialog"
                    fullScreen={false}
                    classes={{
                        paper: classes.dialogPaper
                    }}
                    open={this.state.editScheduleDialogOpened}
                    aria-labelledby="responsive-dialog-title"
                    >
                    <DialogTitle id="responsive-dialog-title" onClose={() => {this.tiggerEditScheduleDialogOpened()}}>{moment(this.state.selectedCalendarDate).format('dddd, MMMM D, YYYY')}</DialogTitle>
                    <DialogContent classes={{root: classes.dialogPaperRulesContainer}}>
                        <br/>
                        <Button
                            onClick={() => this.goToAddEditSchedule(0)}
                            variant="contained"
                            color="default"
                            size="large"
                            fullWidth
                            classes={{
                                root: classNames(classes.scheduleWhiteButton, classes.addScheduleButton),
                                label: classNames(classes.scheduleWhiteButtonLabel)
                            }}
                            // className={classNames(classes.blueButton)}
                        ><Icon className={classes.scheduleWhiteButtonIcon}>add</Icon> Add New Schedule</Button>
                        <Typography variant="caption" classes={{root: classes.rulesNote}}>Rules belongs to selected date:</Typography>

                        <List component="nav">
                            {schedulesRelatedToSelectedDayList}
                        </List>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

PhlebSchedulesV2Class.propTypes = {
    classes: PropTypes.object.isRequired,
};

const PhlebSchedules = withCookies(connect(mapStateToProps,mapDispatchToProps)(withMobileDialog()(withStyles(styles)(PhlebSchedulesV2Class))));
export default PhlebSchedules;