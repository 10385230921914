import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { Typography } from '@material-ui/core';

const styles = theme => ({
  root: {
    padding: 0,
  },
  head: {
    padding: 0,
  },
  tableHeader: {
    height: 'inherit !important',
  },
  tableCellHeader: {
    padding: `${theme.spacing.unit * 1.4}px ${theme.spacing.unit}px !important`,
    backgroundColor: "#68CBEF",
    color: "#fff !important",
    fontSize: '14px !important',
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
  sortLabel: {
    color: "#fff !important",
    fontSize: "14px !important"
  }
});

class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
      this.props.onRequestSort(event, property);
    };

    render() {
      const { classes, row_headers, order, orderBy } = this.props;

      return (
        <TableHead className={classes.tableHeader}>
          <TableRow className={classes.tableHeader}>
            {row_headers.map(row => {
              return (
                <React.Fragment key={row.id}>
                  { row.sortingEnabled
                  ?
                    <TableCell
                      className={classNames(classes.tableCell, classes.nowrap, classes.tableCellHeader)}
                      key={row.id}
                      numeric={row.numeric}
                      sortDirection={orderBy === row.id ? order : false}
                    >
                      <Tooltip
                        title="Sort"
                        placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                        enterDelay={300}
                      >
                      {orderBy === row.id ?
                        <TableSortLabel
                          className={classNames(classes.sortLabel)}
                          active={orderBy === row.id}
                          direction={order}
                          onClick={this.createSortHandler(row.id)}
                        >
                          {row.label}
                        </TableSortLabel>
                      :
                        <Typography 
                          className={classes.sortLabel}
                          onClick={this.createSortHandler(row.id)}
                        >
                          {row.label}
                        </Typography>
                      }
                      </Tooltip>
                    </TableCell>
                  :
                    <TableCell
                      className={classNames(classes.tableCell, classes.nowrap, classes.tableCellHeader)}
                      key={row.id}>
                      {row.label}
                    </TableCell>
                  }
                </React.Fragment>
              );
            }, this)}
          </TableRow>
        </TableHead>
      );
    }
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  export default withStyles(styles)(EnhancedTableHead);