const styles = theme => ({
    // general
    root: {
    },
    content: {
        flexGrow: 1,
    },
    mainContainer: {
        padding: "15px 20px",
        marginBottom: "60px"
    },
    // appointment details header
    apptDetailsHeaderContainer: {
        minHeight: "60px",
        borderBottom: "1px solid #ececec",
        marginLeft: "-20px",
        marginRight: "-20px"
    },
    closeApptDetailsButton: {
        paddingLeft: "20px !important",
    },
    currentStepLabelPlaceholder: {
        width: "77px"
    },
    // total orders component
    totalOrdersContainer: {
        backgroundColor: "#ecfff8",
        margin: "20px 0px 10px 0px",
        padding: "5px 0px 5px 15px",
        borderRadius: "5px",
    },
    orderPercentage: {
        fontSize: "18px !important",
        color: "#00f4a4 !important",
        backgroundColor: "white",
        // fontWeight: 600,
        borderTopLeftRadius: "15px",
        borderBottomLeftRadius: "15px",
        padding: "5px 12px 5px 30px",
    },
    totalOrdersLabel: {
        fontSize: "14px !important"
    },
    // appointment details container
    apptDetailsContainer: {
        borderRadius: "5px",
        border: "1px solid #fbfbfb",
        boxShadow: "2px 2px #f0f0f0",
        marginBottom: "10px",
        backgroundColor: "#f8f8f8",
        padding: "15px",
        // padding: "15px 20px",
        // paddingRight: "10px",
    },
    apptDateTime: {
        fontSize: "14px !important",
        fontWeight: "bold",
        display: "inline-block",
        marginTop: "5px"
    },
    orderDetailsLabel: {
        fontSize: "14px !important",
        fontWeight: 500
    },
    orderAddress: {
        fontSize: "13px !important",
    },
    apptDetailsButton: {
        display: "block",
        width: "100%",
        backgroundColor: "white",
        borderRadius: "5px",
        fontSize: "16px",
        padding: "8px 16px",
        boxShadow: "2px 2px #f0f0f0",
        "&:focus": {
            backgroundColor: "white",
        }
    },
    apptDetailsButtonIcon: {
        width: 24,
        height: 24,
        marginRight: "10px",
        color: "#69CEF1"
    },
    // orders, sort/filter section
    filterContainer: {
        display: "flex",
        marginTop: "30px",
        marginBottom: "20px",
    },
    filterField: {
        height: "30px",
        flexGrow: 1,
        backgroundColor: "white",
        border: "2px solid #f3f3f3",
        borderRadius: "50px",
        padding: "6px 20px 5px",
        marginRight: "5px"
    },
    sortButton: {
        height: "45px",
        minWidth: "82px",
        backgroundColor: "white",
        border: "2px solid #f3f3f3",
        borderRadius: "50px",
        padding: "5px 15px"
    },
    // orders list
    ordersContainer: {
    },
    orderListItem: {
        padding: "20px",
        borderRadius: "5px",
        border: "1px solid #fbfbfb",
        boxShadow: "2px 2px #f0f0f0",
        marginBottom: "10px",
    },
    
    orderNameRemaining: {
        fontSize: "15px !important",
        fontWeight: "bold",
        color: "black"
    },
    orderTestRemaining: {
        fontSize: "13px !important",
        color: "black",
    },
    
    orderNameCompleted: {
        fontSize: "15px !important",
        //fontWeight: "bold",
        color: "darkgray !important",
    },
    orderTestCompleted: {
        fontSize: "13px !important",
        color: "darkgray !important" ,
    },

    orderNameRefused: {
        fontSize: "15px !important",
        //fontWeight: "bold",
        color: "darkgray  !important",
        textDecoration: "line-through"
    },
    orderTestRefused: {
        fontSize: "13px !important",
        color: "darkgray  !important",
        textDecoration: "line-through"
    },

    orderStartButton: {
        height: "35px",
        padding: "5px 20px",
        borderRadius: "50px",
        fontSize: "13px !important",
        backgroundColor: "#ffdc51",
        color: "black",
        fontWeight: 500,
        "&:focus": {
            backgroundColor: "#ffdc51",
        }
    },
    orderCompletedButton: {
        height: "35px",
        padding: "5px 20px",
        borderRadius: "50px",
        fontSize: "13px !important",
        backgroundColor: "#ECFFF8",
        color: "#00F4B8",
        fontWeight: 500,
        "&:focus": {
            backgroundColor: "#ECFFF8",
        }
    },
    orderRefusedButton: {
        height: "35px",
        padding: "5px 20px",
        borderRadius: "50px",
        fontSize: "13px !important",
        backgroundColor: "#FFECF1",
        color: "#FF5285",
        fontWeight: 500,
        "&:focus": {
            backgroundColor: "#FFECF1",
        }
    },
    bottomButton: {
        position: 'absolute',
        backgroundColor: "#f7dd50",
        bottom: 15,
        right:10,
        borderRadius: "5px",
        fontSize: "16px",
        padding: "8px 16px",
        boxShadow: "2px 2px #f0f0f0",
        marginRight: "10px",
        "&:focus": {
            backgroundColor: "#f7dd50",
        }
    }
    ,
    bottomContainer: {
        position: 'absolute',
        bottom: 0,
        //padding: "10px",
        height: "70px",
        width: "100%",
        margin: "0px !important",
        borderTop: "1px solid #ececec",
     
    }
})

export default styles