import React, { Component } from 'react';
import Main from '../Main';
import './App.css';
import { BrowserRouter } from 'react-router-dom'; 
import '@progress/kendo-theme-default/dist/all.css';
import { withOneTabEnforcer } from "react-one-tab-enforcer"
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import 'typeface-roboto';
import NoMultiTabError from 'components/common/mobile/NoMultiTabError';

const globalTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
      primary: {
          light: '#58a5f0',
          main: '#0277bd',
          dark: '#004c8c',
          contrastText: '#ffffff',
      },
      secondary: {
          light: '#80d6ff',
          main: '#42a5f5',
          dark: '#0077c2',
          contrastText: '#ffffff',
      },
    }
});

class App extends Component {
  
  constructor(props) {
      super(props)

      this.checkGPSInterval = 3000;
      //this.innerRef = React.createRef();
  }
   
  componentDidMount(){
    // this.checkGPSTimerHandler();
    // this.screenLockTimer = setInterval(this.checkGPSTimerHandler, this.checkGPSInterval); 
  }  
  componentWillUnmount(){

    //clearTimeout(this.checkGPSInterval);
  }
  checkGPSTimerHandler=()=>{
    // console.log("Tik tok")
    // this.innerRef.current && this.innerRef.current.getLocation();
  }  

  render() {
    
    return (
      <MuiThemeProvider theme={globalTheme}>
        <div className="App">
        <BrowserRouter>
          <Main  {...this.props}  />
        </BrowserRouter>
        </div>
      </MuiThemeProvider>
    );
  }
}
const DifferentWarningComponent = () => {return(<NoMultiTabError />)}
export default withOneTabEnforcer({appName: "PhlebApp", OnlyOneTabComponent: DifferentWarningComponent})(App)
//export default App;
