import * as globals from 'components/common/GlobalConstants'

const styles = theme => ({
    root: {
    },
    content: {
        flexGrow: 1,
    },
    mainContainer: {
        padding: "15px 20px",
        marginBottom: "60px"
    },
    // must be consistent to all page titles
    titleContainer: {
        padding: "6px 20px 11px 20px",
        marginLeft: "-20px",
        marginRight: "-20px",
        borderBottom: "1px solid #E6E6E6",
    },
    title: {
        fontSize: "20px !important",
    },
    todayButton: {
        textTransform: 'capitalize',
        padding: "8px 15px",
        fontSize: "12px !important",
        backgroundColor: "white",
        color: "black",
        fontWeight: 500,
        border: "1px solid #E6E6E6",
        borderRadius: "4px",
        "&:focus": { backgroundColor: "white !important" },
        "&:active": { backgroundColor: "white !important" },
        "&:hover": { backgroundColor: "white !important" },
        "&:disabled": { color: "#ccc !important" },
    },
    periodicSwitch: {
        margin: "auto",
        marginTop: 10,
        border: "1px solid " + globals.v2.COLORS.GRAY,
        borderRadius: "25px",
        padding: "5px",
        // width: "-moz-fit-content",
        width: "fit-content"
    },
    periodicLabel: {
        width: "61px",
        textAlign: "center",
        fontSize: "12px !important",
        padding: "8px 16px",
        borderRadius: "25px",
        "&.active": { 
            backgroundColor: globals.v2.COLORS.BLUE + " !important",
            color: "white !important"
        },
    },
    btnAddScheduleContainer: {
        position: "fixed",
        bottom: "80px",
        right: "0px",
        zIndex: "1"
    },
    btnAddSchedule: {
        fontSize: "14px",
        textTransform: "capitalize",
        color: "black !important",
        backgroundColor: globals.v2.COLORS.YELLOW + " !important",
        padding: "10px 16px",
        borderRadius: "5px",
        marginRight: "15px",
        marginBottom: "10px",
        width: "135px",
        boxShadow: "0px 3px 6px #0000001A",
        "&:active": {
            backgroundColor: globals.v2.COLORS.YELLOW + " !important",
        }
    },
})

export default styles