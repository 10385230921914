import {Component} from 'react';
//import PropTypes from 'prop-types';
//import { withStyles } from '@material-ui/core/styles';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
//import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'; 
//import Login from "components/Login";
//import PouchDB from 'pouchdb-browser';
import { connect } from "react-redux";
import { setScreenLockStatus, logEvent,setForceLogout,doLogout } from "js/actions/index";
//import  Encryption from 'components/common/Encryption';
import * as globals from 'components/common/GlobalConstants';
import * as helper from 'components/common/Helper.js'

const mapDispatchToProps = dispatch => {
    return {
      //setupNewDB: credentials => dispatch(setupNewDB(credentials)),
      setScreenLockStatus: status => dispatch(setScreenLockStatus(status)),
      logEvent: event => dispatch(logEvent(event)),
      setForceLogout: value => dispatch(setForceLogout(value)),
      doLogout: value => dispatch(doLogout(value)),
       
    };
};


const mapStateToProps = state => {
    return { 
            
              isLoggedIn: state.isLoggedIn, 
              isScreenLocked : state.isScreenLocked,
              drawData: state.drawData, 
              scheduleData: state.scheduleData,
              screenSessionStarted: state.screenSessionStarted,
              forceLogout: state.forceLogout,
              isGoingToLogout: state.isGoingToLogout,
          }
  };
// const styles = theme => ({
//     root: {
//         // display: 'flex',
//     },
// });

class Logout extends Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    // getCredentials =()=> {
    //     const { cookies } = this.props

    //     var cred = cookies.get('credentials') || ''
        
    //     if(cred === "") return ""

    //     var security = new Encryption()
    //     var decryptedCred = JSON.parse(security.decrypt(cred))
    //     return decryptedCred
    //     //console.log("THE RESULT " +  security.decrypt(secret));

    //   }

    componentDidMount =() => {
        ///console.log("LOGOUT componentDidMount " + this.props.isGoingToLogout  )
        if(this.props.isGoingToLogout === false) {
            this.processLogout()
        }
    }

    componentDidUpdate =() => {
       // console.log("LOGOUT componentDidUpdate")

       // this.processLogout()
    }

    processLogout =()=> {
        console.log("Preparing for logout...")
        const cookies = new Cookies();
        //const { cookies } = this.props
        this.props.doLogout(true)
        var cred = helper.getCredentials()
        var database = cred.database
        if(database === undefined ) {
            console.log("processLogout cancelled.. database undeinfed")
            return
        }
        //var propsReason = (this.props.reason !== undefined) ? this.props.reason : "" 
        var queryStringReason = (this.props.match.params.reason !== undefined) ? this.props.match.params.reason : "" 
                
        this.props.logEvent({
            user: cred.username,
            module: "logout",
            action: queryStringReason,
            description: "User logged out"
        })

        if(globals.DEBUG_ALLOWED) {console.log("LOGOUT: this.deleteDatabase(database)")}
        console.log("LOGOUT: this.deleteDatabase(database)")
        helper.deleteDatabase(database, ()=>{
            
            if(globals.DEBUG_ALLOWED) {console.log("LOGOUT: cookies.remove('credentials')")}
            console.log("LOGOUT: cookies.remove('credentials')")
            cookies.remove('credentials')
    
            if(globals.DEBUG_ALLOWED) {console.log("LOGOUT: cookies.remove('selected_site')")}
            console.log("LOGOUT: cookies.remove('selected_site')")
            cookies.remove('selected_site')
    
            if(globals.DEBUG_ALLOWED) { 
                console.log("Removed credentials in cookies.");
            }
            console.log("Removed credentials in cookies.");
            //this.props.setScreenLockStatus(false)
            //this.props.setForceLogout(false)
            console.log("done!")
            //this.props.history.push("/login")
            window.location = "/login" // This was intentionally implement to refresh the PouchDB every login. //
        })
       
    }

    // deleteDatabase = (databse, chainCallback) => {
        
    //     if(globals.DEBUG_ALLOWED) {
    //         console.log("Deleting database : " + databse);  
    //     }

    //     try {
    //         new PouchDB(databse).destroy().then(function () {
    //             if(globals.DEBUG_ALLOWED) {
    //                 console.log("Database was destroyed sucessfully : " + databse);  
    //             }
    //             chainCallback()
               
    //         }).catch(function (err) {
    //             console.log("Failed to destroy database : " +  databse);
    //             chainCallback()
    //         });
    //     } catch(exception) {
    //         // just ignore the error    
    //         console.log("Failed to destroy database : " +  databse);
    //         console.log(exception)
    //     }
    // }

    render = () => {
        //console.log("Logout : render()");
        //const { classes } = this.props;
       // this.props.setScreenLockStatus(false)
       // console.log("window.Redirect = '/' ")
        //window.location.href = "/"
        // return(
        //     <div>
        //         {/* <Typography>Force Logout</Typography> */}
        //         {/* <BrowserRouter>
        //                 <Switch>
        //                     <Route path="/login"  render={(props) => <Login {...props}/>}  />
        //                     <Redirect to="/login" />
        //                 </Switch>
        //             </BrowserRouter> */}
        //     </div>
        // );
        return null
    }
}

Logout.propTypes = {
   // classes: PropTypes.object.isRequired,
};

export default withCookies(connect(mapStateToProps, mapDispatchToProps) (Logout));
