import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import { withCookies } from 'react-cookie'

const styles = theme => ({
    fixedBottom: {
        background: "#FFFFFF", //globals.LIGHT_BLUE_GRADIENT_BG,
        position: 'fixed',
        bottom: 0,
        left: 0,
        display: 'flex',
        width: "100%",
        height: 70,
        zIndex: 1200
    },
    action: {
        maxWidth: "100%",
    },
    wrapper: {
        paddingLeft: 4,
        paddingRight: 4,
        borderTop: "1px solid #e0e0e0",
        borderRight: "1px solid #e0e0e0",
        paddingBottom: 25
    },
    wrapperLast: {
        paddingLeft: 4,
        paddingRight: 4,
        border: "none",
        borderTop: "1px solid #e0e0e0",
        paddingBottom: 25
    },
    label: {
        fontSize: "14px !important",
        color: "#717171",
        border: "none !important",
        paddingTop: 5,
    },
    icon: {
        width: 18,
        height: 18,
    },
    selected: {
        color: "#69CEF1 !important",
        borderTop: "3px solid #69CEF1",
        // fontWeight: "bold",
        // paddingTop: "10px !important",
    }
})

const SimpleBottomNavigation = (props) => {
    const { classes } = props

    var path_name = window.location.pathname.split('/')[1]
    var tab_value = 0
    path_name = path_name ? path_name : 'appointments'

    switch (path_name) {
        case 'appointments':
            tab_value = 0
            break
        case 'map':
            tab_value = 1
            break
        case 'work-schedule':
            tab_value = 2
            break
        default:
            tab_value = null
            break
    }

    return (
        <BottomNavigation
            value={tab_value}
            showLabels
            className={classNames(classes.fixedBottom)}
        >
            <BottomNavigationAction
                classes={{
                    root: classes.wrapper,
                    label: classes.label,
                    selected: classes.selected
                }}
                className={classNames(classes.action)}
                component={Link}
                to="/appointments/today"
                label="Appointments"
            />

            <BottomNavigationAction
                classes={{
                    root: classes.wrapper,
                    label: classes.label,
                    selected: classes.selected
                }}
                className={classNames(classes.action)}
                component={Link}
                to="/map"
                label="Map"
            />
            <BottomNavigationAction
                classes={{
                    root: classes.wrapperLast,
                    label: classes.label,
                    selected: classes.selected
                }}
                className={classNames(classes.action)}
                component={Link}
                to="/work-schedule"
                label="Schedule"
            />
        </BottomNavigation>
    )
}

export default withCookies(withStyles(styles)(SimpleBottomNavigation))