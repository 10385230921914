import * as globals from 'components/common/GlobalConstants';

const styles = theme => ({
    root: {
        // display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
    },
    overrideBlue: {
        background: globals.BLUE_GRADIENT_BG,
    },
    headerInfo: {
        paddingTop: "15px",
        paddingLeft: "15px",
        paddingRight: "15px",
    },
    scheduleContainer: {
        padding: "15px",
    },
    list: {
      border: "1px solid rgba(0, 0, 0, 0.16)",
      marginBottom: theme.spacing.unit,
      fontSize: "12px !important",
    },
    label: {
        fontSize: "14px !important",
        color: "#707070",
    },
    buttons: {
        fontSize: "14px !important",
        textTransform: "none",
        border: "none",
        borderRadius: 0,
        padding: "6px 16px"
    },
    blueButton: {
        float: "right",
        color: "white !important",
        backgroundColor: "#68CBEF !important",
    },
    textField: {
        width: 45,
        fontSize: 16
    },
    customApplicationBarHeader: {
        padding: "7px 17px",
        marginLeft: "-15px",
        marginRight: "-15px"
    },
    listULMainHeader: {
        marginTop: 25
    },
    schedulesMainHeader: {
        color: "#707070 !important",
        fontSize: '24px !important',
        fontWeight: 'bold',
        padding: "0px !important",
        margin: "0px !important"
    },
    schedulesMainContainer: {
        margin: 15,
    },
    listUL: {
        paddingTop: 0,
        paddingBottom: 0
    },
    settingsContent: {
        paddingLeft: 16,
        paddingRight: 16,
    },
});

export default styles;