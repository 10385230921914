import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { withCookies } from 'react-cookie';

const styles = theme => ({
    fixedBottom: {
      background: "#FFFFFF", //globals.LIGHT_BLUE_GRADIENT_BG,
      position: 'fixed',
      bottom: 0,
      left: 0,
      display: 'flex',
      width: "100%",
      height: 80,
      zIndex: 1200,
    },
    action: {
      maxWidth: "100%",
    },
    wrapper: {
      borderTop: "1px solid #e0e0e0",
      borderRight: "1px solid #e0e0e0",
      paddingTop: 16,
      paddingBottom: 12,
    },
    wrapperLast: {
      border: "none",
      borderTop: "1px solid #e0e0e0",
      paddingTop: 16,
      paddingBottom: 12,
    },
    label: {
      fontSize: "14px !important",
      border: "none !important",
      paddingTop: 5,
    },
    icon: {
      width: 30,
      height: 30,
    },
    selected: {
      color: "#69CEF1 !important",     
      borderTop: "4px solid #69CEF1",
      fontWeight: "bold",
    }
});

class SimpleBottomNavigation extends React.Component {

  render() {
    const { classes } = this.props;

    const path_name = (window.location.pathname.split('/')[1]);
    var tab_value = 0;

    const { cookies } = this.props;
    var selectedSite = "/site/" + cookies.get('selected_site') || '';
    var disabledDrawList = cookies.get('selected_site')  === undefined ? true: false;
    // console.log("disabledDrawList");
    // console.log(disabledDrawList + " " + selectedSite); 
    
    switch(path_name) {
      case 'dashboard':
        tab_value = 0;
        break;
      case 'sites':
        tab_value = 1;
        break;
      case "site":
      // case 'patient':
        tab_value = 2;
        break;
      case 'settings':
        tab_value = 3;
        break;
      default:
        tab_value = null;
        break;
    }

    // console.log("tab_value: " + tab_value);

    return (
        <BottomNavigation
          value={tab_value}
          // onChange={this.handleChange}
          showLabels
          className={classNames(classes.fixedBottom)}
        >
          <BottomNavigationAction
            classes={{
              root: classes.wrapper,
              label: classes.label,
              selected: classes.selected
            }}
            className={classNames(classes.action)} 
            component={Link} 
            to="/dashboard" 
            label="Dashboard" 
            icon={<img 
              alt="dashboard icon" 
              className={classes.icon} 
              src={tab_value === 0 ? "/images/dashboard_selected.svg" : "/images/dashboard.svg"} />} />

          <BottomNavigationAction 
            classes={{
              root: classes.wrapper,
              label: classes.label,
              selected: classes.selected
            }}
            className={classNames(classes.action)} 
            component={Link} 
            to="/sites" 
            label="Sites" 
            icon={<img 
              alt="sites icon" 
              className={classes.icon} 
              src={tab_value === 1 ? "/images/sites_selected.svg" : "/images/sites.svg"} />} />

          <BottomNavigationAction 
            classes={{
              root: classes.wrapper,
              label: classes.label,
              selected: classes.selected
            }} 
            disabled={disabledDrawList} 
            className={classNames(classes.action)} 
            component={Link} 
            to={selectedSite} 
            label="Draw List" 
            icon={<img 
              alt="Draw List" 
              className={classes.icon} 
              src={tab_value === 2 ? "/images/draw_list_selected.svg" : "/images/draw_list.svg"} />} />

            <BottomNavigationAction 
              classes={{
                root: classes.wrapperLast,
                label: classes.label,
                selected: classes.selected
              }}
              className={classNames(classes.action)} 
              component={Link} 
              to="/settings" 
              label="Settings" 
              icon={<img 
                alt="settings icon" 
                className={classes.icon} 
                src={tab_value === 3 ? "/images/settings_selected.svg" : "/images/settings.svg"} />} />
        </BottomNavigation>

        
    );
  }
}

SimpleBottomNavigation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withCookies(withStyles(styles)(SimpleBottomNavigation));