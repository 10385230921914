import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import styles from "./styles.js";

import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
// import * as globals from 'components/common/GlobalConstants';

import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';
// import Table from '@material-ui/core/Table';
// import { TableBody } from '@material-ui/core';
// import TableCell from '@material-ui/core/TableCell';
// import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import $ from 'jquery';

import { connect } from "react-redux";
// import { ReactComponent as CallSVG } from 'components/common/mobile/CustomOfflineSVG/call.svg';
// import { ReactComponent as SitesGrayFillSVG } from 'components/common/mobile/CustomOfflineSVG/sites_gray_fill.svg';

import IconButton from '@material-ui/core/IconButton'
import CallIcon from '@material-ui/icons/Call'
// import RoomIcon from '@material-ui/icons/Room'
import NavigationIcon from '@mui/icons-material/Navigation'
import Grid from '@material-ui/core/Grid'
// import * as helper from 'components/common/Helper.js'

const mapDispatchToProps = dispatch => {
    return {
        // updatePatientDrawStatus: drawItem => dispatch(updatePatientDrawStatus(drawItem))
    };
};

const mapStateToProps = state => {
    return {
        // drawData: state.drawData 
    }
};

class SwiperSliderComponentClass extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            enableSavingCookie: false
        };
    }

    goToSite(url) {
        // console.log(url)
        this.props.history.push(url);
    }

    navigate(lat, lng, name) {
        // If it's an iPhone..
        if ((navigator.platform.indexOf("iPhone") !== -1) || (navigator.platform.indexOf("iPod") !== -1)) {
            function iOSversion() {
                if (/iP(hone|od|ad)/.test(navigator.platform)) {
                    // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
                    var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
                    return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
                }
            }
            var ver = iOSversion() || [0];
            var protocol = "";
            if (ver[0] >= 6) {
                protocol = 'maps://';
            } else {
                protocol = 'http://';

            }
            window.location = protocol + 'maps.apple.com/maps?q=' + name; //  + '&amp;ll=' + lat + ',' + lng + ',16z'
            //   window.location = protocol + 'maps.apple.com/maps?daddr=' + lat + ',' + lng + '&amp;ll=';
        }
        else {
            window.open('http://maps.google.com/maps?q=' + name); //  + '&amp;ll=' + lat + ',' + lng + ',16z'
            //   window.open('http://maps.google.com?daddr=' + lat + ',' + lng + '&amp;ll=');
        }
    }

    render() {
        const { sitesData, initialActiveSlide, cookies, onSlideChange } = this.props;
        // var UUID = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,a=>(a^Math.random()*16>>a/4).toString(16));
        const SiteItem = (id, data, index) => {
            const { classes } = this.props;
            // const siteDone = data.siteDone;
            // const siteTotalDrawItems = data.siteTotalDrawItems;
            // const isApproved = data.isApproved;
            // const forFinalApproval = data.forFinalApproval;
            const city = data.site.city !== undefined && data.site.city !== "" ? data.site.city + " " : "";
            const city_state_zipcode = city + [data.site.state, data.site.zipcode].filter(function (el) { return el; }).join(", ");
            // const address = [data.site.address, data.site.address_line_2].filter(function (el) { return el; }).join(", ");
            const map_link = [data.site.address, data.site.address_line_2, city_state_zipcode].filter(function (el) { return el; }).join(", ");

            const address = data.display_address ? data.display_address : [data.site.address, data.site.state, data.site.zipcode].join(", ")
            var patients_counter_per_site = "";
            // const site_type = data.site_type

            // if(site_type  === globals.ORGANIZATION) {
            //     patients_counter_per_site = <Typography className={classes.patientCounterPerSite}>{data.siteDone}/{data.siteTotalDrawItems} Draws</Typography>;
            // } else  if(site_type  === globals.HOUSEHOLD) {
            //     patients_counter_per_site = <Typography className={classes.patientCounterPerSite}>{data.siteDone}/{data.siteTotalDrawItems} Draws</Typography>;
            // } else  if(site_type  === globals.INDIVIDUAL) {

            // }
            // const site_type = data.service_name

            // console.log("map ============== data")
            // console.log(data)

            return (
                <>
                    {/* <Table id={id}>
                        <TableBody>
                            <TableRow
                                key={data.site.id}
                                className={classNames(classes.sitesTableRow)}> */}
                    {/* <TableCell
                                className={classNames(classes.tableCell, classes.sitesTableCell)}
                            > */}
                    {/* <div className={classNames(classes.siteContainer, (siteDone === siteTotalDrawItems && isApproved) ? classes.siteRowCompleted : '')}> */}
                    {/* <div className={classNames(classes.content, (siteDone === siteTotalDrawItems && isApproved) ? classes.siteRowCompleted : '')}> */}
                    <div
                        className={classNames(classes.content)}
                        onClick={() => this.goToSite(data.link)}
                    >
                        {/* {((site_type === globals.HOUSEHOLD ||
                                    site_type === globals.ORGANIZATION) && forFinalApproval) &&
                                    <Typography className={classes.forFinalApproval}>For Final Approval</Typography>
                                }
                                 */}
                        <div className={classes.siteInfo}>
                            <Grid
                                container
                                justify="space-between"
                                alignItems="center"
                            >
                                <div>
                                    <Typography
                                        className={classes.siteName}
                                        variant="body1">
                                        {/* { data.site_order + '. ' + data.site.name}  */}
                                        {/* { (index + 1) + '. ' + data.site.name}  */}
                                        {data.visit_contact ? data.visit_contact : ""}
                                    </Typography>
                                </div>
                                <Typography className={classNames("orders")}>{data.siteDone}/{data.siteTotalDrawItems} Order{data.siteTotalDrawItems !== 1 ? "s" : ""}</Typography>
                            </Grid>
                            <Typography
                                className={classes.collectionTime}>
                                {moment(data.collection_time, "hh:mm:ss").format('hh:mm a')}
                            </Typography>
                            {/* <Typography className={classes.siteType}>{site_type}</Typography> */}
                            {patients_counter_per_site}
                            <Typography
                                className={classes.siteAddress}>
                                {address}
                            </Typography>
                            {/* {data.site.address_line_2 !== "" &&
                                            <Typography
                                                className={classes.siteAddress}>
                                                {data.site.address_line_2}
                                            </Typography>
                                        } 
                            <Typography
                                className={classes.siteAddress}>
                                {city_state_zipcode}
                            </Typography>*/}
                        </div>
                        <div className={classes.ordersButtonContainer}>
                            <IconButton
                                aria-label="Call"
                                classes={{
                                    root: classes.ordersButton
                                }}
                                onClick={(e) => {
                                    window.location.href = 'tel:' + data.site.phone_number
                                    e.stopPropagation()
                                }}>
                                <CallIcon className={classNames(classes.ordersButtonIcon)}></CallIcon> Call
                            </IconButton>
                            <IconButton
                                aria-label="Map"
                                className={classes.ordersButton}
                                onClick={(e) => {
                                    this.navigate(data.site.latitude, data.site.longitude, map_link)
                                    e.stopPropagation()
                                }}>
                                <NavigationIcon className={classNames(classes.ordersButtonIcon, classes.navigationIcon)}></NavigationIcon> Navigate
                            </IconButton>
                        </div>
                        {/* <div className={classes.optionsContainer}>
                            <CallSVG className={classNames(classes.optionsIcon)} onClick={() => window.location.href = 'tel:' + data.site.phone_number}></CallSVG>
                            <SitesGrayFillSVG onClick={() => this.navigate(data.site.latitude, data.site.longitude, map_link)} className={classNames(classes.optionsIcon)} ></SitesGrayFillSVG>
                        </div> */}
                    </div>
                    {/* </TableCell> */}
                    {/* </TableRow>
                        </TableBody>
                    </Table> */}
                </>
            )
        };

        var SwiperSlideItem = sitesData.map(function (data, index) {
            return <SwiperSlide key={data.site_order}>{SiteItem(data.site_order, data, index)}</SwiperSlide>
        });

        const adjustedWidth = $(".swiper-container").width() - 40; //  - 28
        const currentActiveSlide = initialActiveSlide ? parseInt(initialActiveSlide) : 0;
        // console.log("before render: enableSavingCookie=" + this.state.enableSavingCookie)

        return (
            <Swiper
                // key={UUID}
                // className={classes.container}
                initialSlide={currentActiveSlide}
                spaceBetween={0}
                slidesPerView={1}
                onSlideChange={(swiper) => { // cannot use this one coz it is triggered on initial loading
                    if (this.state.enableSavingCookie) {
                        // console.log("onSlideChange after Touching only: " + swiper.activeIndex)
                        cookies.set('swiper_active_index', swiper.activeIndex)
                        onSlideChange(swiper)
                    }
                }}
                onTouchStart={(swiper, event) => {
                    if (!this.state.enableSavingCookie) {
                        // console.log('onTouchStart')
                        // console.log(swiper)
                        // console.log(event)
                        this.setState({ ...this.state, enableSavingCookie: true });
                    }
                }}
                onSwiper={(swiper) => {
                    // console.log('onSwiper: ' + swiper.activeIndex)
                    this.props.loadSwiperRefToParent(swiper);
                }}
                width={adjustedWidth}
            >
                {SwiperSlideItem}
            </Swiper>
        );
    }
}

SwiperSliderComponentClass.propTypes = {
    sitesData: PropTypes.array.isRequired,
    loadSwiperRefToParent: PropTypes.func.isRequired,
};

const SwiperSliderComponent = withRouter(withCookies(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SwiperSliderComponentClass))))
export default SwiperSliderComponent;
