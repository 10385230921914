import {
    SETUP_NEW_DB,
    UPDATE_PATIENT_DRAW,
    LOAD_DATA_FROM_POUCHDB,
    SET_LOGGED_IN_STATUS,
    SET_SCREEN_LOCK_STATUS,
    SET_SCREEN_SESSION_STARTED,
    MP_SIGN,
    // VERIFY_PATIENT_NAME,
    // VERIFY_PATIENT_DOB,
    UPDATE_PATIENT_ROOM_NUMBER,
    NEW_STAT_ARRIVED,
    UPDATE_SITE_ORDERING,
    ADD_SCHEDULE,
    UPDATE_SCHEDULES,
    DELETE_SCHEDULE,
    SCHEDULES_DATA_ARRIVED,
    UPDATE_SCHEDULE_SETTINGS,
    DRAW_REQUEST_ARRIVED,
    DRAW_REQUEST_DOCUMENTS_ARRIVED,
    DRAW_REQUEST_NOTES_ARRIVED,
    WORKFLOW_DATA_ARRIVED,
    ACCEPT_DRAW_REQUEST,
    UPDATE_SERVICED_ZIPCODES,
    DELETE_NOTE,
    SAVE_NOTE,
    FUTURE_ORDERS_ARRIVED,
    SET_ONLINE_STATUS,
    SAVE_DRAW_REQUEST_DOCUMENT,
    DELETE_DRAW_REQUEST_DOCUMENT,
    UPDATE_WORKFLOW_VALUE,
    SET_SYNC_STATUS,
    LOG_EVENT,
    DELETE_LOGS,
    SET_FORCE_LOGOUT,
    CLEAR_ALL_DATA,
    DO_LOGOUT,
    UPDATE_LOGS,
    NEW_APP_VERSION,
    SET_CAMERA_AVAILABLE,
    GEO_LOCATION_DATA_ARRIVED,
    ADD_GEO_LOCATION,
    CREATE_SITE_WORKLOW_DEFAULT_VALUES,
    SET_SELECTED_SCHEDULE_VIEW,
    START_APPOINTMENT,
    SET_PATIENTS_DOESNT_EXIST_IN_APPT,
    SET_PATIENTS_DOESNT_EXIST_IN_APPT_INDICATOR,
    SET_ADDED_EXISTING_PATIENT_IN_APPT,
    SET_ADD_EXISTING_PATIENT_IN_APPT_INDICATOR,
    SET_PATIENT_ID_BEING_ADDED
} from "../constants/action-types"

import ImmutableJS from "immutable"
import DB from "../DB.js"
import moment from 'moment-timezone'
import uuid from "uuid"
import * as globals from 'components/common/GlobalConstants'
//import ReactDOM from 'react-dom'
//import {DeviceUUID} from 'device-uuid'
import * as helper from 'components/common/Helper.js'
import jsonDiff from 'json-diff'
// import { map } from "jquery"
// import {addGeoLocation} from "../actions"
const initialState = {
    db: new DB({}),
    drawData: {},
    scheduleData: {},
    drawRequests: {},
    drawRequestDocumentsData: {},
    drawRequestNotesData: [],
    workflowData: {},
    futureOrders: {},
    geoLocationData: {},
    isLoggedIn: false,
    isGoingToLogout: false,
    isScreenLocked: true,
    screenSessionStarted: null,
    onlineStatus: true,
    dbCredentials: "",
    syncStatus: globals.UNSYNCED,
    logs: loadLogs(),
    userProfile: { user: "no user yet" },
    forceLogout: false,
    newAppVersion: false,
    isCameraAvailable: true,
    selectedScheduleView: "monthly",
    getPatientsListDoesntExistsInAppointmentIndicator: { active: false },
    patientsListDoesntExistsInAppointment: [],
    addExistingPatientInAppointmentIndicator: { active: false },
    addedExistingPatientInAppointment: [],
    patientIdBeingAdded: null
}

function loadLogs() {
    console.log("Loading logs...")
    var logs
    try {
        //console.log(helper.getLogs())
        logs = JSON.parse(helper.getLogs() !== null ? helper.getLogs() : "{}")
    } catch (e) {
        console.log("Invalid data log format.")
        logs = {}
    }

    if (globals.DEBUG_ALLOWED) {
        console.log("Parsed logs:")
        console.log(logs)
    }

    //return logs
    //if(Array.isArray(logs)) {
    //  logs = logs[0]  // Just in case the value is [{}], extract the object.
    //}

    // If still an array then give up, return empty object {}
    if (Array.isArray(logs)) {
        helper.saveLogs({})
        return {}
    }

    // Filter out invalid log entry 
    var index = 0
    var log = {}
    //var goodLogs = []
    var newLogs = {}
    while (index < Object.keys(logs).length) {

        log = logs[Object.keys(logs)[index]]


        if (globals.DEBUG_ALLOWED) {
            console.log("To process: " + Object.keys(logs)[index])
        }

        try {

            if (
                typeof log.log_id === 'undefined' ||
                typeof log.log_date_utc === 'undefined' ||
                typeof log.device_id === 'undefined' ||
                typeof log.user === 'undefined' ||
                typeof log.db === 'undefined' ||
                typeof log.module === 'undefined' ||
                typeof log.action === 'undefined' ||
                typeof log.description === 'undefined' ||
                typeof log.connection === 'undefined' ||
                typeof log.recorded === 'undefined') {
                // Delete invalid log 
                console.log("Detected log defect (if)" + index)
                console.log(Object.keys(logs)[index])
                console.log(log)
            } else {
                //goodLogs.push(Object.keys(logs)[index])
                newLogs[Object.keys(logs)[index]] = log
                // if(globals.DEBUG_ALLOWED) {
                //   console.log("No error on " + index)
                // }
                // Just continue... log is ok
            }
        } catch (e) {
            console.log(e)
            console.log("Detected log defect (catch) " + index)
            delete (logs[Object.keys(logs)[index]])
        }

        index++
    }



    helper.saveLogs(newLogs)

    if (globals.DEBUG_ALLOWED) {
        console.log("Valid log data")
        console.log(logs)
        console.log("Return logs")
        console.log(newLogs)
    }

    //return {}


    return newLogs
} // load logs

function getWorkflowDataDefaultValues(drawlist_site_id, drawData) {
    console.log("drawData")
    console.log(drawData)

    var site_workflow = drawData.draw_list.filter(drawList => {
        return (drawList.id === drawlist_site_id)
    })

    console.log("site_workflow " + drawlist_site_id)
    console.log(site_workflow)

    var metadata = site_workflow[0].workflow.metadata
    console.log("metadata")
    console.log(Object.keys(metadata))
    var metaKeys = Object.keys(metadata)
    var data_type = ['string', 'boolean', 'json']
    var workflow_data = {}

    for (var i = 0; i < metaKeys.length; ++i) {
        //console.log(metadata[metaKeys[i]])
        var metadata_item = metadata[metaKeys[i]]
        if (data_type.includes(metadata_item.data_type)) {

            if (metadata_item.data_type === 'string') {
                workflow_data[metaKeys[i]] = ""
            } else if (metadata_item.data_type === 'boolean') {
                workflow_data[metaKeys[i]] = false
            } else if (metadata_item.data_type === 'json') {
                workflow_data[metaKeys[i]] = {}
            }

            console.log("workflow_data")
            console.log(workflow_data)
        }
    }



    return workflow_data
}

function getDiffData(currentData, modifiedData) {
    if (globals.ADD_DIFF_DATA_IN_LOGS) {
        return {
            _id: modifiedData._id,
            _rev: modifiedData._rev,
            diff_values: jsonDiff.diff(currentData, modifiedData)
        }
    } else {
        return null
    }
}


function logGPSLocation(data, state) {
    if (helper.isEmptyObject(state.geoLocationData)) {
        console.log("error: geoLocationData doc empty")
        return
    }

    try {
        data.id = "temp_" + uuid.v4()
        var iGeoLocationData = ImmutableJS.fromJS(state.geoLocationData)

        var geoLog = iGeoLocationData.get("geolocation").concat(data)
        var added_with_new_geo_log = iGeoLocationData.set("geolocation", geoLog)
        // console.log("geoLocationData", added_with_new_geo_log.toJS())
        state.db.updateData(added_with_new_geo_log.toJS())
        state.geoLocationData = added_with_new_geo_log.toJS()
    } catch (e) {
        console.log("Accessing GEO Location document error.", e)
    }
}

function logIt({ gps_log, drawlist_site_id, patient_draw_id, state, user, module, action, description, doc_data = null, entry_id = "", module_id = "", draw_request_id = "" }) {
    const logTime = moment().utc().format('L') + " " + moment().utc().format('LTS')
    const local_logTime = moment().format('L') + " " + moment().format('LTS')
    // if(state.logs.length > globals.LOG_SIZE) {
    //   state.logs.shift()
    // }

    if (gps_log) {
        var logData = {
            client_timestamp: moment().unix(),
            client_timezone: moment.tz.guess(true) || "America/New_York",
            latitude: window.$location.latitude ? `` + window.$location.latitude : "",
            longitude: window.$location.longitude ? `` + window.$location.longitude : "",
            heading: window.$location.heading ? `` + window.$location.heading : "",
            metadata: {
                action: action,
                description: description,
                drawlist_site_id: drawlist_site_id,
                patient_draw_id: patient_draw_id,
            }
        }

        if (logData.latitude === "" && logData.longitude === "") {
            console.log("!gps_log: empty latitude and longitude")
        } else {
            // console.log("gps_log")
            // console.log(logData)
            logGPSLocation(logData, state)
        }
    }

    var username = ""
    var db = ""
    // var _rev = ""
    // var _id = ""
    // var diff_values = {}
    //var docData = doc_data !== undefined ? doc_data : null


    if (globals.ADD_DIFF_DATA_IN_LOGS === false) {
        doc_data = null
    }

    // This is to make sure that the username is available even just right after login //
    if (user === undefined) {
        user = helper.getCredentials().username
    }

    // Getting the username and db name 
    try {
        username = user.split("_")[0]
        //db = user.split("_")[1] || ""
        db = helper.getCredentials().database
    } catch (e) {
        username = user
        db = user
    }
    var logSequence = helper.getLogNumber(db)
    // console.log("logit username: " + username)

    // if (globals.DEBUG_ALLOWED) {
    //     console.log("Logs lenght: " + Object.keys(state.logs).length + " " + globals.LOG_SIZE)
    // }

    while (Object.keys(state.logs).length >= globals.LOG_SIZE) {
        if (globals.DEBUG_ALLOWED) {
            console.log("Removing log:")
            console.log(state.logs[Object.keys(state.logs)[0]])
        }
        delete state.logs[Object.keys(state.logs)[0]]
    }

    var new_log_id = uuid.v4()
    var device_id = helper.getDeviceId()

    var log_timezone = moment.tz.guess(true) || "America/New_York"
    var log_timestamp = moment().unix()

    var useragent = navigator.userAgent

    var newLog = {
        seq: logSequence,
        log_id: new_log_id,
        log_timezone: log_timezone,
        log_timestamp: log_timestamp,
        useragent: useragent,
        log_date_local: local_logTime,
        log_date_utc: logTime,
        device_id: device_id,
        user: username,
        db: db,
        module: module,
        action: action,
        description: description,
        recorded: false,
        doc_data: doc_data,
        entry_id: entry_id,
        module_id: module_id,
        connection: state.onlineStatus,
        draw_request_id: draw_request_id,
        latitude: window.$location.latitude,
        longitude: window.$location.longitude
    }

    // if (globals.DEBUG_ALLOWED) {
    //     console.log("LOG ITEM TO LOG")
    //     console.log(newLog)
    // }

    state.logs[new_log_id] = newLog

    //console.log("state.logs")
    //console.log(state.logs)
    //localStorage.setItem("logs", JSON.stringify(state.logs))
    helper.saveLogs(state.logs)
}

// function saveLogs(logs) {
//   if(globals.DEBUG_ALLOWED) {
//     console.log("Saving logs")
//     console.log(logs)
//   }
//   localStorage.setItem("logs", JSON.stringify(logs))
// }

function deleteLogs() {
    localStorage.setItem("logs", JSON.stringify({}))
}

// function enclose(value) {
//   return "[" + value +"]"
// }

// function desc(a, b, orderBy) {
//     if (b[orderBy] < a[orderBy]) {
//         return -1
//     }
//     if (b[orderBy] > a[orderBy]) {
//         return 1
//     }
//     return 0
// }

// function stableSort(array, cmp) {
//     const stabilizedThis = array.map((el, index) => [el, index])
//     stabilizedThis.sort((a, b) => {
//         const order = cmp(a[0], b[0])
//         if (order !== 0) return order
//         return a[1] - b[1]
//     })
//     return stabilizedThis.map(el => el[0])
// }

// function getSorting(order, orderBy) {
//     return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
// }

const rootReducer = (state = initialState, action) => {
    const iDrawData = ImmutableJS.fromJS(state.drawData)
    const iSchedules = ImmutableJS.fromJS(state.scheduleData)
    const iDrawRequests = ImmutableJS.fromJS(state.drawRequests)
    const iDrawRequestDocumentsData = ImmutableJS.fromJS(state.drawRequestDocumentsData)
    const iDrawRequestNotesData = ImmutableJS.fromJS(state.drawRequestNotesData)
    const iWorkflowData = ImmutableJS.fromJS(state.workflowData)

    //   const iGeoLocationData = ImmutableJS.fromJS(state.geoLocationData)

    var type // "m" = medical practioner or "p" phlebotomist
    var draw_list_id
    var patient_list_id
    var patient_draw_id
    var status
    var notes
    var room_number
    var schedule
    var indexDrawList
    var updatedDrawData
    var updatedDrawRequestDocumentData
    var updatedDrawRequestNoteData
    var updatedPatient

    var isPatientNameVerified
    // var dob_verified
    var workflow_key
    var workflow_value
    var workflow_values

    // undefined moment timezone
    // This happens when the host environment (browser, etc.) implements the API for ECMA-402 
    // but returns the wrong type of time zone in the result. If you are seeing this with Moment, 
    // you can probably also get this result without Moment with the following:
    // Intl.DateTimeFormat().resolvedOptions().timeZone

    // Presently, Moment assumes that if this API exists, 
    // that it either provides a valid IANA time zone identifier or returns undefined. 
    // However, some environments return things like CET.

    // an optional boolean argument "ignoreCache"
    // If set to true, the cache will be ignored and overwritten with the new value.
    var timezone = moment.tz.guess(true) || "America/New_York"
    var unixEpoch = moment().unix() // in seconds // moment().tz(timezone).unix()

    var index
    var section
    var section_original
    var schedules
    var added_with_new_schedule_data

    var indexPatientList
    var notePayload
    var documentPayload
    var patientId
    // var iNotes
    // var iDocuments
    // var indexNote
    // var indexDocument
    //var cred
    //var tempDB
    var index_draw_req_doc
    var drawRequestDocumentType = ""
    var new_patient_draw
    var docData
    var temp = ""
    var site_type = ""


    switch (action.type) {

        case SETUP_NEW_DB:
            var disp = state.db.getLoadDataDispatcher()

            var newDB = new DB(action.payload)
            newDB.setLoadDataDispatcher(disp)

            if (globals.DEBUG_ALLOWED) {
                console.log("DB Credentials")
                console.log(action.payload)
            }

            return { ...state, db: newDB, dbCredentials: action.payload }

        case SET_ONLINE_STATUS:
            // check if user is not yet logged in
            var user = state.dbCredentials.username || ""
            logIt({ state: state, user: user, module: "connectivity-status", action: "online", description: action.payload })

            return { ...state, onlineStatus: action.payload }

        case SET_FORCE_LOGOUT:
            return { ...state, forceLogout: action.payload }

        case NEW_APP_VERSION:
            if (globals.UPDATE_APP_AUTOMATICALLY) {
                console.log("Executing auto update..")
                helper.updateApp()
            }
            return { ...state, newAppVersion: action.payload }

        case SET_CAMERA_AVAILABLE:

            if (globals.DEBUG_ALLOWED) {
                console.log("CAMERA AVAILABLE")
                console.log(action.payload)
            }

            return { ...state, isCameraAvailable: action.payload }

        case DO_LOGOUT:
            // if(action.payload.reason) {

            //   }
            return { ...state, isGoingToLogout: true }

        case SET_SYNC_STATUS:
            return { ...state, syncStatus: action.payload }

        case UPDATE_LOGS:
            helper.saveLogs(action.payload)
            return { ...state, logs: action.payload }

        case CLEAR_ALL_DATA:

            console.log("Clearing all data.")
            return {
                ...state,
                drawData: {},
                scheduleData: {},
                drawRequests: {},
                drawRequestDocumentsData: {},
                futureOrders: {},
                geoLocationData: {}
            }


        case LOG_EVENT:
            var logEvent = action.payload

            // if (globals.DEBUG_ALLOWED) {
            //     console.log("logEvent")
            //     console.log(logEvent)
            // }

            var log_user = logEvent.user || state.dbCredentials.username
            log_user = log_user || ""

            var gps_log = logEvent.gps_log ? logEvent.gps_log : false
            var drawlist_site_id = logEvent.drawlist_site_id ? logEvent.drawlist_site_id : ""
            var log_patient_draw_id = logEvent.patient_draw_id ? logEvent.patient_draw_id : ""

            logIt({
                gps_log: gps_log,
                drawlist_site_id: drawlist_site_id,
                patient_draw_id: log_patient_draw_id,
                state: state, user: log_user, module: logEvent.module, action: logEvent.action, description: logEvent.description, entry_id: logEvent.entry_id, module_id: logEvent.module_id
            })

            return { ...state }

        case DELETE_LOGS:
            deleteLogs()
            return { ...state, logs: {} }

        case UPDATE_PATIENT_DRAW:
            console.log("UPDATE_PATIENT_DRAW")
            console.log(action.payload)
            type = action.payload.type // "m" = medical practioner or "p" phlebotomist
            draw_list_id = action.payload.siteId
            patient_list_id = action.payload.patientId
            patient_draw_id = action.payload.patientDrawId
            status = action.payload.status
            notes = action.payload.notes

            var notesAttribute = ""
            // console.log("action.payload")
            // console.log(action.payload)
            if (type === "p") {
                notesAttribute = "status_notes_p"
            } else if (type === "m") {
                notesAttribute = "status_notes_p" //  "status_notes_m" original value
            } else if (type === "r") {
                notesAttribute = "status_notes_p"
            } else {

            }

            if (status === "refused") {
                // notes = ""
                status = "unable"
                notes = "refused"
            } else if (status === "refused_other_reason") {
                // status = "refused"
                status = "unable"
            } else if (status === "refused_hard_stick") {
                // status = "refused"
                status = "unable"
                notes = "hard stick"
            }

            // // early logging of unable here // 
            // if(status === "unable") {
            //   logIt({state: state, user: state.dbCredentials.username, module: "patient", action: status, description: "Unable to draw: " + notes, entry_id: patient_list_id})
            // }

            indexDrawList =
                iDrawData.get('draw_list')
                    .findIndex(dl => dl.get('id') === draw_list_id)


            indexPatientList =
                iDrawData.getIn(['draw_list', indexDrawList, 'patient_list'])
                    .findIndex(pl => pl.get('id') === patient_list_id)

            // console.log("SITE_TYPE in REDUCER")
            site_type = iDrawData.getIn(['draw_list', indexDrawList, 'site_type'])
            // console.log("site_type", site_type)
            // console.log("draw_list_id", draw_list_id)
            var siteId = iDrawData.getIn(['draw_list', indexDrawList, 'id'])
            // console.log("id", siteId)
            // console.log("patient_draw_id", patient_draw_id)

            var indexPatientDraw =
                iDrawData.getIn(['draw_list', indexDrawList, 'patient_list', indexPatientList, 'patient_draw'])
                    .findIndex(di => di.get('id') === patient_draw_id)

            if (status === "done") { // if done signature should be added 

                if (action.payload.withSignature !== undefined && action.payload.withSignature === true) { // If there is signature data
                    new_patient_draw = iDrawData.getIn(
                        ["draw_list", indexDrawList, "patient_list", indexPatientList, "patient_draw", indexPatientDraw])
                        .setIn(["status"], status)
                        .setIn(["client_app_timezone"], timezone)
                        .setIn(["client_app_timestamp"], unixEpoch)
                        .setIn([notesAttribute], notes)

                        .setIn(["draw_completion_sign_off", "sof_client_timezone"], timezone)
                        .setIn(["draw_completion_sign_off", "sof_client_timestamp"], unixEpoch)
                        .setIn(["draw_completion_sign_off", "sof_signatory_type"], action.payload.signature.sof_signatory_type)
                        .setIn(["draw_completion_sign_off", "sof_first_name"], action.payload.signature.sof_first_name)
                        .setIn(["draw_completion_sign_off", "sof_last_name"], action.payload.signature.sof_last_name)
                        .setIn(["draw_completion_sign_off", "sof_sign_off"], action.payload.signature.sof_sign_off)
                        .setIn(["draw_completion_sign_off", "sof_sign_off_metadata"], action.payload.signature.sof_sign_off_metadata)
                } else { // if NO signature 
                    new_patient_draw = iDrawData.getIn(
                        ["draw_list", indexDrawList, "patient_list", indexPatientList, "patient_draw", indexPatientDraw])
                        .setIn(["status"], status)
                        .setIn(["client_app_timezone"], timezone)
                        .setIn(["client_app_timestamp"], unixEpoch)
                        .setIn([notesAttribute], notes)
                } // if(action.payload.withSignature !== undefined && action.payload.withSignature == true) {

            } else {
                new_patient_draw = iDrawData.getIn(
                    ["draw_list", indexDrawList, "patient_list", indexPatientList, "patient_draw", indexPatientDraw])
                    .setIn(["status"], status)
                    .setIn(["client_app_timezone"], timezone)
                    .setIn(["client_app_timestamp"], unixEpoch)
                    .setIn([notesAttribute], notes)
            }


            updatedDrawData = iDrawData.setIn(
                ["draw_list", indexDrawList, "patient_list", indexPatientList, "patient_draw", indexPatientDraw],
                new_patient_draw)

            // reset
            if (type === "r") {

                new_patient_draw = updatedDrawData.getIn(
                    ["draw_list", indexDrawList, "patient_list", indexPatientList, "patient_draw", indexPatientDraw])
                    .setIn(["draw_source"], "")
                    .setIn(["draw_notes"], "")
                    .setIn(["draw_date"], null)
                    // .setIn(["client_app_timezone"], timezone)
                    // .setIn(["client_app_timestamp"], unixEpoch)
                    // hh/org initial
                    .setIn(["draw_sign_on", "son_first_name"], "")
                    .setIn(["draw_sign_on", "son_last_name"], "")
                    .setIn(["draw_sign_on", "son_sign_on"], null)
                    .setIn(["draw_sign_on", "son_signatory_type"], null)
                    .setIn(["draw_sign_on", "son_sign_on_metadata"], null)
                    .setIn(["draw_sign_on", "son_client_timezone"], null)
                    .setIn(["draw_sign_on", "son_client_timestamp"], null)
                    // hh/org final
                    .setIn(["draw_sign_off", "sof_first_name"], "")
                    .setIn(["draw_sign_off", "sof_last_name"], "")
                    .setIn(["draw_sign_off", "sof_sign_off"], null)
                    .setIn(["draw_sign_off", "sof_signatory_type"], null)
                    .setIn(["draw_sign_off", "sof_sign_off_metadata"], null)
                    .setIn(["draw_sign_off", "sof_client_timezone"], null)
                    .setIn(["draw_sign_off", "sof_client_timestamp"], null)
                    // individual
                    .setIn(["draw_completion_sign_off", "sof_client_timezone"], null)
                    .setIn(["draw_completion_sign_off", "sof_client_timestamp"], null)
                    .setIn(["draw_completion_sign_off", "sof_first_name"], "")
                    .setIn(["draw_completion_sign_off", "sof_last_name"], "")
                    .setIn(["draw_completion_sign_off", "sof_sign_off"], null)
                    .setIn(["draw_completion_sign_off", "sof_sign_off_metadata"], null)
                    .setIn(["draw_completion_sign_off", "sof_signatory_type"], null)

                updatedDrawData = updatedDrawData.setIn(
                    ["draw_list", indexDrawList, "patient_list", indexPatientList, "patient_draw", indexPatientDraw],
                    new_patient_draw)

                // Update site_status to ""

                updatedDrawData = updatedDrawData.setIn(["draw_list", indexDrawList, "site_status"], "")

                // console.log("UPDATE_PATIENT_DRAW site status=reset")
                // console.log(updatedDrawData.toJS())

                // docData = {
                //   _id : updatedDrawData.toJS()._id,
                //   _rev : updatedDrawData.toJS()._rev,
                //   diff_values : jsonDiff.diff( state.drawData, updatedDrawData.toJS())
                // }
                docData = getDiffData(state.drawData, updatedDrawData.toJS())
                //doc_data : docData

                logIt({
                    gps_log: true,
                    drawlist_site_id: siteId,
                    patient_draw_id: patient_draw_id,
                    state: state, user: state.dbCredentials.username, module: "patient", action: "reset", description: "Patient reset status", doc_data: docData, entry_id: patient_list_id
                })

            } else if (status === "done") {
                new_patient_draw = updatedDrawData.getIn(
                    ["draw_list", indexDrawList, "patient_list", indexPatientList, "patient_draw", indexPatientDraw])
                    // .setIn(["client_app_timezone"], timezone)
                    // .setIn(["client_app_timestamp"], unixEpoch)
                    .setIn(["draw_source"], action.payload.draw_source)
                    .setIn(["draw_notes"], "")
                    .setIn(["draw_date"], unixEpoch)

                updatedDrawData = updatedDrawData.setIn(
                    ["draw_list", indexDrawList, "patient_list", indexPatientList, "patient_draw", indexPatientDraw],
                    new_patient_draw)

                    
                console.log("UPDATE_PATIENT_DRAW patient status=done")
                console.log(updatedDrawData.toJS())

                // console.log("Document Id:")
                // console.log(updatedDrawData.toJS()._id)
                // console.log("Revision :")
                // console.log(updatedDrawData.toJS()._rev)

                // console.log("DIFF data:")
                // console.log(jsonDiff.diff(updatedDrawData.toJS(), state.drawData))

                // docData = {
                //   _id : updatedDrawData.toJS()._id,
                //   _rev : updatedDrawData.toJS()._rev,
                //   diff_values : jsonDiff.diff( state.drawData, updatedDrawData.toJS())
                // }
                if (site_type === "individual") {
                    updatedDrawData = updatedDrawData.setIn(["draw_list", indexDrawList, "site_status"], "completed")

                    // console.log("UPDATE_PATIENT_DRAW invividual site status=completed")
                    // console.log(updatedDrawData.toJS())
                }
                docData = getDiffData(state.drawData, updatedDrawData.toJS())
                logIt({
                    gps_log: true,
                    drawlist_site_id: siteId,
                    patient_draw_id: patient_draw_id,
                    state: state, user: state.dbCredentials.username, module: "patient", action: "done", description: "Patient draw complete", doc_data: docData, entry_id: patient_list_id
                })

                //logIt(state, "patient", "done", patient_list_id)   
            } else if (status === "skipped" || status === "unable") {
                if (site_type === "individual") {
                    updatedDrawData = updatedDrawData.setIn(["draw_list", indexDrawList, "site_status"], "completed")
                    
                    // console.log("UPDATE_PATIENT_DRAW invividual patient status=completed")
                    // console.log(updatedDrawData.toJS())
                }
                docData = getDiffData(state.drawData, updatedDrawData.toJS())
                logIt({
                    gps_log: true,
                    drawlist_site_id: siteId,
                    patient_draw_id: patient_draw_id,
                    state: state, user: state.dbCredentials.username, module: "patient", action: status, description: status + ": " + notes, doc_data: docData, entry_id: patient_list_id
                })
            }

            if (status === "done" || status === "skipped" || status === "unable" || type === "r") {
                // disable bubbleSort that modifies site_order value
                // use bubbleSort for UI only
                // updatedDrawData = bubbleSortSites(updatedDrawData, draw_list_id)
            }

            if (status === "done" || status === "skipped" || status === "unable") {
                console.log("This is for setting end timestamp")
                var drawCompleted = 0
                var drawCount = updatedDrawData.getIn(["draw_list", indexDrawList]).toJS().patient_list.length
                console.log("No of draws", drawCount)

                updatedDrawData.getIn(["draw_list", indexDrawList]).toJS().patient_list.map(patient => {
                    var drawStatus = patient.patient_draw[0].status
                    if (drawStatus !== "remaining") {
                        drawCompleted++
                    }
                    console.log(patient.patient.first_name, patient.patient_draw[0].status)
                })

                var end_timestamp = updatedDrawData.getIn(["draw_list", indexDrawList, "end_timestamp"])

                if (drawCompleted === drawCount && !end_timestamp) {
                    // console.log("Setting end_timestamp..")
                    updatedDrawData = updatedDrawData.setIn(["draw_list", indexDrawList, "end_timestamp"], unixEpoch)
                        .setIn(["draw_list", indexDrawList, "site_status"], "completed")
                        .setIn(["draw_list", indexDrawList, "client_app_timezone"], timezone)

                        
                    console.log("drawCompleted === drawCount && !end_timestamp")
                    console.log("drawCompleted=", drawCompleted)
                    console.log("drawCount=", drawCount)
                    console.log("!end_timestamp=", !end_timestamp)
                    console.log("UPDATE_PATIENT_DRAW site status=completed before db.updateData")
                    console.log(updatedDrawData.toJS())
                }

            }

            state.db.updateData(updatedDrawData.toJS())
            return { ...state, drawData: updatedDrawData.toJS() }

            // return { ...state }

        case MP_SIGN:
            type = action.payload.type // "initial" or "final"
            draw_list_id = action.payload.siteId
            var first_name = action.payload.first_name
            var last_name = action.payload.last_name
            var signature = action.payload.signature
            var signatory_type = action.payload.signatory_type
            var meta_data = action.payload.meta_data

            indexDrawList = iDrawData.get('draw_list').findIndex(dl => dl.get('id') === draw_list_id)

            site_type = iDrawData.getIn(['draw_list', indexDrawList, 'site_type'])

            if (type === "initial") {
                updatedPatient = iDrawData.getIn(["draw_list", indexDrawList, "patient_list"]).map(i =>
                    i.setIn(["patient_draw", 0, "draw_sign_on", "son_first_name"], first_name)
                        .setIn(["patient_draw", 0, "draw_sign_on", "son_last_name"], last_name)
                        .setIn(["patient_draw", 0, "draw_sign_on", "son_sign_on"], signature)
                        .setIn(["patient_draw", 0, "draw_sign_on", "son_signatory_type"], signatory_type)
                        .setIn(["patient_draw", 0, "draw_sign_on", "son_sign_on_metadata"], meta_data)
                        .setIn(["patient_draw", 0, "draw_sign_on", "son_client_timezone"], timezone)
                        .setIn(["patient_draw", 0, "draw_sign_on", "son_client_timestamp"], unixEpoch)
                        .setIn(["patient_draw", 0, "client_app_timezone"], timezone)
                        .setIn(["patient_draw", 0, "client_app_timestamp"], unixEpoch)
                )
                updatedDrawData = iDrawData.setIn(["draw_list", indexDrawList, "patient_list"], updatedPatient)
            } else if (type === "final") {

                updatedPatient = iDrawData.getIn(["draw_list", indexDrawList, "patient_list"]).map(i => {

                    return i.setIn(["patient_draw", 0, "draw_sign_off", "sof_first_name"], first_name)
                        .setIn(["patient_draw", 0, "draw_sign_off", "sof_last_name"], last_name)
                        .setIn(["patient_draw", 0, "draw_sign_off", "sof_sign_off"], signature)
                        .setIn(["patient_draw", 0, "draw_sign_off", "sof_signatory_type"], signatory_type)
                        .setIn(["patient_draw", 0, "draw_sign_off", "sof_sign_off_metadata"], meta_data)
                        .setIn(["patient_draw", 0, "draw_sign_off", "sof_client_timezone"], timezone)
                        .setIn(["patient_draw", 0, "draw_sign_off", "sof_client_timestamp"], unixEpoch)
                        .setIn(["patient_draw", 0, "client_app_timezone"], timezone)
                        .setIn(["patient_draw", 0, "client_app_timestamp"], unixEpoch)


                })
                updatedDrawData = iDrawData.setIn(["draw_list", indexDrawList, "patient_list"], updatedPatient)

                // updatedDrawData = bubbleSortSites(updatedDrawData, draw_list_id)
            } else {
                //"Invalid type value : [" + type + "] valid type 'initial' or 'final'"
            }

            if (type === "final") {
                updatedDrawData = updatedDrawData.setIn(["draw_list", indexDrawList, "site_status"], "completed")
            }

            docData = getDiffData(state.drawData, updatedDrawData.toJS())
            logIt({
                gps_log: true, state: state,
                user: state.dbCredentials.username, module: "site-" + site_type,
                action: "mp_sign",
                description: helper.capitalize(action.payload.type) + " approval",
                doc_data: docData,
                module_id: draw_list_id,
                drawlist_site_id: draw_list_id,
                patient_draw_id: ""
            })

            if (globals.DEBUG_ALLOWED) {
                console.log("drawlist_site_id", draw_list_id)
                console.log("updatedDrawData.toJS()", updatedDrawData.toJS())
            }

            state.db.updateData(updatedDrawData.toJS())

            return { ...state, drawData: updatedDrawData.toJS() }

        case SAVE_NOTE:
            notePayload = action.payload
            patientId = notePayload.patientId
            var note_id = notePayload.note.id
            notePayload.note.drawlist_patient_id = patientId
            notePayload.note.drawlist_site_id = notePayload.siteId
            notePayload.note.client_deleted = false
            notePayload.note.client_timestamp = unixEpoch
            notePayload.note.client_timezone = timezone

            console.log("REDUX SAVE_NOTE:", notePayload)

            if (notePayload.noteOperation === "edit") {
                index_draw_req_doc = iDrawRequestNotesData.get('draw_request_notes')
                    .findIndex(dl => dl.get('id') === note_id)

                updatedDrawRequestNoteData = iDrawRequestNotesData.setIn(["draw_request_notes", index_draw_req_doc], notePayload.note)

            } else {
                notePayload.note.id = "temp_" + uuid.v4()
                var addedRequestNoteData = iDrawRequestNotesData.get('draw_request_notes').concat(notePayload.note)
                updatedDrawRequestNoteData = iDrawRequestNotesData.set("draw_request_notes", addedRequestNoteData)
            }

            docData = getDiffData(state.updatedDrawRequestNoteData, updatedDrawRequestNoteData.toJS())

            logIt({
                gps_log: true,
                drawlist_site_id: notePayload.siteId,
                patient_draw_id: notePayload.patientId,
                state: state, user: state.dbCredentials.username, module: "notes", action: notePayload.noteOperation + "_note", description: helper.capitalize(notePayload.noteOperation) + " note", doc_data: docData, module_id: notePayload.note.id
            })

            console.log("REDUX updatedDrawRequestNoteData", updatedDrawRequestNoteData.toJS())
            state.db.updateData(updatedDrawRequestNoteData.toJS())
            return { ...state, updatedDrawRequestNoteData: updatedDrawRequestNoteData.toJS() }

        case DELETE_NOTE:
            notePayload = action.payload
            console.log("notePayload", notePayload)
            patientId = notePayload.patientId
            notePayload.note.client_deleted = true
            notePayload.note.client_timestamp = unixEpoch
            notePayload.note.client_timezone = timezone

            index_draw_req_doc = iDrawRequestNotesData.get('draw_request_notes')
                .findIndex(dl => dl.get('id') === notePayload.note.id)

            updatedDrawRequestNoteData = iDrawRequestNotesData.setIn(["draw_request_notes", index_draw_req_doc], notePayload.note)

            docData = getDiffData(state.updatedDrawRequestNoteData, updatedDrawRequestNoteData.toJS())

            logIt({
                gps_log: true,
                drawlist_site_id: notePayload.siteId,
                patient_draw_id: notePayload.patientId,
                state: state, user: state.dbCredentials.username, module: "notes", action: "delete_note", description: "Note deleted", doc_data: docData, module_id: notePayload.note.id
            })

            state.db.updateData(updatedDrawRequestNoteData.toJS())

            return { ...state, updatedDrawRequestNoteData: updatedDrawRequestNoteData.toJS() }

        // Draw request documents

        case SAVE_DRAW_REQUEST_DOCUMENT:
            documentPayload = action.payload

            if (documentPayload.type !== undefined) {
                drawRequestDocumentType = documentPayload.type
            } else {
                drawRequestDocumentType = "draw_request_document"
            }

            delete (documentPayload.type)

            var document_id = documentPayload.document.id
            var site_id = documentPayload.siteId
            var patient_id = documentPayload.patientId

            documentPayload.document.drawlist_site_id = site_id
            documentPayload.document.drawlist_patient_id = patient_id
            documentPayload.document.client_deleted = false
            documentPayload.document.client_timestamp = unixEpoch
            documentPayload.document.client_timezone = timezone

            if (documentPayload.target === "patient_draw") {

                if (documentPayload.documentOperation === "edit") {

                    index_draw_req_doc = iDrawRequestDocumentsData.get('draw_request_documents')
                        .findIndex(dl => dl.get('id') === document_id)

                    updatedDrawRequestDocumentData = iDrawRequestDocumentsData.setIn(["draw_request_documents", index_draw_req_doc], documentPayload.document)
                    temp = "edit"
                } else {
                    documentPayload.document.id = "temp_" + uuid.v4()
                    var addedRequestDocumentData = iDrawRequestDocumentsData.get('draw_request_documents').concat(documentPayload.document)
                    updatedDrawRequestDocumentData = iDrawRequestDocumentsData.set("draw_request_documents", addedRequestDocumentData)
                    temp = "create"

                    if (drawRequestDocumentType === 'barcode') {
                        temp = "scan_barcode"
                    }

                    if (drawRequestDocumentType === 'photo') {
                        temp = "capture_photo"
                    }
                }
            }

            draw_list_id = site_id// documentPayload.siteId
            indexDrawList = iDrawData.get('draw_list').findIndex(dl => dl.get('id') === draw_list_id)
            patient_draw_id = iDrawData.getIn(["draw_list", indexDrawList, "patient_list", indexPatientList, "patient_draw", 0, "id"])
            docData = getDiffData(state.drawRequestDocumentsData, updatedDrawRequestDocumentData.toJS())

            logIt({
                gps_log: true,
                drawlist_site_id: siteId,
                patient_draw_id: patient_draw_id,
                state: state,
                user: state.dbCredentials.username,
                module: "draw-request-document",
                action: temp,
                description: helper.capitalize(temp),
                doc_data: docData,
                module_id: documentPayload.document.id
            })

            state.db.updateData(updatedDrawRequestDocumentData.toJS())
            return { ...state, drawRequestDocumentsData: updatedDrawRequestDocumentData.toJS() }

        case DELETE_DRAW_REQUEST_DOCUMENT:

            documentPayload = action.payload
            patientId = documentPayload.patientId
            documentPayload.document.client_deleted = true

            draw_list_id = action.payload.siteId
            console.log("DELETE_DRAW_REQUEST_DOCUMENT " + documentPayload.type)

            if (documentPayload.type !== undefined) {
                drawRequestDocumentType = documentPayload.type
            } else {
                drawRequestDocumentType = "draw_request_document"
            }

            delete (documentPayload.type)


            if (documentPayload.target === "patient_draw") {
                // iDocuments = iDrawData.getIn(["draw_list", indexDrawList, "patient_list", indexPatientList, "patient_draw", 0, "draw_request_documents"])
                // if not doc_type = offline_upload

                index_draw_req_doc = iDrawRequestDocumentsData.get('draw_request_documents')
                    .findIndex(dl => dl.get('id') === documentPayload.document.id)

                if (documentPayload.document.doc_type !== "offline_upload") {
                    // indexDocument = iDocuments.findIndex(dl => dl.get('id') === documentPayload.document.id)
                    updatedDrawRequestDocumentData = iDrawRequestDocumentsData.setIn(["draw_request_documents", index_draw_req_doc], documentPayload.document)
                } else {
                    // indexDocument = iDocuments.findIndex(dl => dl.get('id') === documentPayload.document.id)
                    updatedDrawRequestDocumentData = iDrawRequestDocumentsData.deleteIn(["draw_request_documents", index_draw_req_doc])
                }
            }

            temp = "delete"

            if (drawRequestDocumentType === 'barcode') {
                temp = "delete_barcode"
            }

            if (drawRequestDocumentType === 'photo') {
                temp = "delete_photo"
            }
            patient_draw_id = iDrawData.getIn(["draw_list", indexDrawList, "patient_list", indexPatientList, "patient_draw", 0, "id"])
            docData = getDiffData(state.drawRequestDocumentsData, updatedDrawRequestDocumentData.toJS())
            logIt({
                gps_log: true,
                drawlist_site_id: draw_list_id,
                patient_draw_id: patient_draw_id,
                state: state,
                user: state.dbCredentials.username,
                module: "draw-request-document",
                action: temp,
                description: helper.capitalize(temp),
                doc_data: docData,
                module_id: documentPayload.document.id
            })

            //logIt(state,"draw request document", "deleted",  documentPayload.document.id) 
            state.db.updateData(updatedDrawRequestDocumentData.toJS())
            return { ...state, drawRequestDocumentsData: updatedDrawRequestDocumentData.toJS() }

        case UPDATE_WORKFLOW_VALUE:
            draw_list_id = action.payload.siteId
            patient_list_id = action.payload.patientId
            patient_draw_id = action.payload.patientDrawId
            workflow_key = action.payload.workflow_key
            workflow_value = action.payload.workflow_value
            workflow_values = action.payload.workflow_values

            var index_workflow = iWorkflowData.get('workflow')
                .findIndex(dl => dl.get('drawlist_patient_id') === patient_list_id)

            var updatedWorkflowData = null

            if (workflow_key) {
                updatedWorkflowData = iWorkflowData.setIn(["workflow", index_workflow, "workflow_data", workflow_key], workflow_value)
                    .setIn(["workflow", index_workflow, "client_app_timestamp"], unixEpoch)
                    .setIn(["workflow", index_workflow, "client_app_timezone"], timezone)
            }

            if (workflow_values) {
                var temp_iWorkflowData = iWorkflowData
                workflow_values.forEach(val => {
                    updatedWorkflowData =
                        temp_iWorkflowData.setIn(["workflow", index_workflow, "workflow_data", val.key], val.value)
                            .setIn(["workflow", index_workflow, "client_app_timestamp"], unixEpoch)
                            .setIn(["workflow", index_workflow, "client_app_timezone"], timezone)
                    temp_iWorkflowData = updatedWorkflowData
                })

            }

            console.log(updatedWorkflowData.toJS())

            docData = getDiffData(state.workflowData, updatedWorkflowData.toJS())
            logIt({
                state: state, user: state.dbCredentials.username, module: "patient", action: workflow_key, description: workflow_key + " " + isPatientNameVerified, doc_data: docData, entry_id: patient_list_id
            })

            state.db.updateData(updatedWorkflowData.toJS())
            return { ...state, workflowData: updatedWorkflowData.toJS() }

        case UPDATE_PATIENT_ROOM_NUMBER:

            draw_list_id = action.payload.siteId
            patient_list_id = action.payload.patientId
            room_number = action.payload.roomNumber

            indexDrawList =
                iDrawData.get('draw_list')
                    .findIndex(dl => dl.get('id') === draw_list_id)

            var indexPatientList3 =
                iDrawData.getIn(['draw_list', indexDrawList, 'patient_list'])
                    .findIndex(pl => pl.get('id') === patient_list_id)

            var new_patient_draw3 = iDrawData.getIn(
                ["draw_list", indexDrawList, "patient_list", indexPatientList3])
                .setIn(["patient_room_number"], room_number)


            //logIt(state,"patient", "Updated patient room number", room_number) 
            updatedDrawData = iDrawData.setIn(
                ["draw_list", indexDrawList, "patient_list", indexPatientList3],
                new_patient_draw3)

            // docData = {
            //   _id : updatedDrawData.toJS()._id,
            //   _rev : updatedDrawData.toJS()._rev,
            //   diff_values : jsonDiff.diff( state.drawData, updatedDrawData.toJS())
            // }
            docData = getDiffData(state.drawData, updatedDrawData.toJS())
            logIt({ state: state, user: state.dbCredentials.username, module: "patient", action: "update_patient_room_number", description: "Updated patient room number to " + room_number, doc_data: docData, entry_id: patient_list_id })

            state.db.updateData(updatedDrawData.toJS())
            return { ...state, drawData: updatedDrawData.toJS() }

        case LOAD_DATA_FROM_POUCHDB:
            return { ...state, drawData: action.payload }

        case SCHEDULES_DATA_ARRIVED:
            return { ...state, scheduleData: action.payload }

        case DRAW_REQUEST_ARRIVED:
            return { ...state, drawRequests: action.payload }

        case DRAW_REQUEST_DOCUMENTS_ARRIVED:
            return { ...state, drawRequestDocumentsData: action.payload }

        case DRAW_REQUEST_NOTES_ARRIVED:
            return { ...state, drawRequestNotesData: action.payload }

        case WORKFLOW_DATA_ARRIVED:
            return { ...state, workflowData: action.payload }

        case CREATE_SITE_WORKLOW_DEFAULT_VALUES:
            console.log("CREATE_SITE_WORKLOW_DEFAULT_VALUESsss")
            console.log(action.payload.site)
            var siteWithPatients = action.payload.site
            drawlist_site_id = action.payload.site.id
            // var drawlist_patient_id = ""
            patient_draw_id = ""
            // var ctr = 0

            if (state.workflowData.workflow === undefined) {
                return { ...state }
            }

            var workflow_data = getWorkflowDataDefaultValues(drawlist_site_id, state.drawData)


            const patientInWorkflow = iWorkflowData.get("workflow").map(i => {
                return i.get("drawlist_patient_id")
            })

            var workflows = iWorkflowData.get("workflow")
            var needToUpdateDBandWorkflowData = false

            siteWithPatients.patient_list.forEach(patient => {
                //  console.log(patient)
                //console.log(patientInWorkflow.toJS().includes(patient.id))

                if (!patientInWorkflow.toJS().includes(patient.id)) {

                    workflows = workflows.concat({
                        "id": uuid.v4(),
                        "drawlist_site_id": drawlist_site_id,
                        "drawlist_patient_id": patient.id,
                        "patient_draw_id": patient.patient_draw[0].id,
                        "workflow_data": workflow_data,
                        "client_app_timestamp": "",
                        "client_app_timezone": ""
                    })

                    needToUpdateDBandWorkflowData = true
                }

            })
            var new_iWorkflowData = iWorkflowData.set("workflow", workflows)
            // return { ...state, workflowData: action.payload }
            // Loop site patients 
            // Ask if patients is not included in the workflowEntry then
            // concat it
            if (needToUpdateDBandWorkflowData) {
                console.log(new_iWorkflowData.toJS())
                state.db.updateData(new_iWorkflowData.toJS())
                return { ...state, workflowData: new_iWorkflowData.toJS() }
            } else {
                console.log("nothing to update")
                return { ...state }
            }

        case FUTURE_ORDERS_ARRIVED:
            return { ...state, futureOrders: action.payload }

        case GEO_LOCATION_DATA_ARRIVED:
            //console.log("GEO_LOCATION_DATA_ARRIVED",action.payload )
            return { ...state, geoLocationData: action.payload }

        case ADD_GEO_LOCATION:
            // implement adding of geolocation data here or hook to logs // 
            return { ...state, geoLocationData: action.payload }

        case NEW_STAT_ARRIVED:
            var statData = action.payload
            var needToSave = false
            var statCount = 0
            for (var i = 0; i < statData.ns.length; i++) {
                var returnData
                returnData = updateDrawList(action, state, statData.ns[i].drawlistsite_id, statData.ns[i].patient_list_id)
                if (returnData.needToSave) {
                    needToSave = true
                    statCount++
                }
                state.drawData = returnData.updatedData.toJS()
            }

            if (needToSave) {
                state.db.updateData(state.drawData)
            }
            // here 
            logIt({ state: state, user: state.dbCredentials.username, module: "new-stat", action: "new_stat_arrived", description: "New STAT arrived:: " + statCount })

            //logIt(state,"New STAT", "New STAT arrived", statCount)

            return { ...state } //, drawData: updatedDrawData.toJS()}

        case UPDATE_SITE_ORDERING:
            updatedDrawData = iDrawData.setIn(["draw_list"], action.payload.draw_list)
            state.db.updateData(updatedDrawData.toJS())
            return { ...state, drawData: updatedDrawData.toJS() }

        case SET_LOGGED_IN_STATUS:
            return { ...state, isLoggedIn: action.payload }

        case SET_SCREEN_LOCK_STATUS:
            return { ...state, screenSessionStarted: Date.now(), isScreenLocked: action.payload }

        case SET_SCREEN_SESSION_STARTED:
            return { ...state, screenSessionStarted: action.payload }

        case UPDATE_SCHEDULE_SETTINGS:
            var schedule_settings = action.payload

            schedule_settings.client_timestamp = unixEpoch
            schedule_settings.client_timezone = timezone

            added_with_new_schedule_data = iSchedules.set("settings", schedule_settings)

            docData = getDiffData(state.drawData, added_with_new_schedule_data.toJS())
            logIt({ state: state, user: state.dbCredentials.username, module: "schedules", action: "update_schedule", description: "Schedules settings updated", doc_data: docData })

            state.db.updateData(added_with_new_schedule_data.toJS())

            return { ...state, scheduleData: added_with_new_schedule_data.toJS() }

        case UPDATE_SERVICED_ZIPCODES:
            var serviced_zipcodes = action.payload

            added_with_new_schedule_data = iSchedules.set("serviced_zipcodes", serviced_zipcodes)

            docData = getDiffData(state.drawData, added_with_new_schedule_data.toJS())
            logIt({ state: state, user: state.dbCredentials.username, module: "service-area", action: "update_service_area", description: "Service Area updated", doc_data: docData })
            state.db.updateData(added_with_new_schedule_data.toJS())
            //logIt(state,"Service Area", "Service Area updated", "")
            return { ...state, scheduleData: added_with_new_schedule_data.toJS() }

        case ADD_SCHEDULE:
            schedule = action.payload

            schedule.id = "temp_" + uuid.v4()

            section = schedule.section
            delete (schedule.section)
            delete (schedule.all_day)
            delete (schedule.section_original)
            schedule.client_timestamp = unixEpoch
            schedule.client_timezone = timezone

            if (section === 'schedules') {
                schedules = iSchedules.get("schedules").concat(schedule)
                added_with_new_schedule_data = iSchedules.set("schedules", schedules)
                //logIt({state: state, user: state.dbCredentials.username, module: "Schedules", action: "Added working schedule", description:  ""})

                //logIt(state,"Schedules", "Added working schedule", "")
                temp = "Added working schedule"
            }

            if (section === 'personal') {
                schedules = iSchedules.getIn(['non_working_days', 'personal']).concat(schedule)
                added_with_new_schedule_data = iSchedules.setIn(["non_working_days", "personal"], schedules)
                //logIt({state: state, user: state.dbCredentials.username, module: "Schedules", action: "Added non-working(personal) schedule", description:  ""})

                //logIt(state,"Schedules", "Added non-working(personal) schedule", "")
                temp = "Added non-working(personal) schedule"
            }

            docData = getDiffData(state.drawData, added_with_new_schedule_data.toJS())
            logIt({ state: state, user: state.dbCredentials.username, module: "schedules", action: "add_schedule", description: temp, doc_data: docData, module_id: schedule.id })

            state.db.updateData(added_with_new_schedule_data.toJS())
            return { ...state, scheduleData: added_with_new_schedule_data.toJS() }


        case UPDATE_SCHEDULES:
            schedule = action.payload

            var updated_schedule_data
            section = schedule.section
            section_original = schedule.section_original
            schedule.client_timestamp = unixEpoch
            schedule.client_timezone = timezone
            delete (schedule.section)
            delete (schedule.all_day)
            delete (schedule.section_original)

            if (section_original === section) {

                // Just do the regular updates since data will stay either in schedules or personal //
                if (section === 'schedules') {
                    index = iSchedules.get('schedules').findIndex(dl => dl.get('id') === schedule.id)
                    updated_schedule_data = iSchedules.setIn(["schedules", index], schedule)
                    //logIt({state: state, user: state.dbCredentials.username, module: "Schedules", action: "Schedule updated", description:   schedule.id})
                    temp = "Schedule updated"
                    //logIt(state,"Schedules", "Schedule updated", schedule.id)
                }

                if (section === 'personal') {
                    index = iSchedules.getIn(['non_working_days', 'personal']).findIndex(dl => dl.get('id') === schedule.id)
                    updated_schedule_data = iSchedules.setIn(["non_working_days", "personal", index], schedule)
                    //logIt({state: state, user: state.dbCredentials.username, module: "Schedules", action: "Updated non-working(personal) schedule", description:   schedule.id})
                    temp = "Updated non-working(personal) schedule"
                    //logIt(state,"Schedules", "Updated non-working(personal) schedule", schedule.id)
                }

            } else {

                // Frist remove the original entry
                if (section_original === 'schedules') {
                    index = iSchedules.get('schedules').findIndex(dl => dl.get('id') === schedule.id)
                    updated_schedule_data = iSchedules.deleteIn(["schedules", index])

                } else if (section_original === 'personal') {
                    index = iSchedules.getIn(['non_working_days', 'personal']).findIndex(dl => dl.get('id') === schedule.id)
                    updated_schedule_data = iSchedules.deleteIn(["non_working_days", "personal", index])
                }

                // Then add the updated item to the new location
                if (section === 'schedules') {
                    schedules = updated_schedule_data.get("schedules").concat(schedule)
                    updated_schedule_data = updated_schedule_data.set("schedules", schedules)
                }

                if (section === 'personal') {
                    schedules = updated_schedule_data.getIn(['non_working_days', 'personal']).concat(schedule)
                    updated_schedule_data = updated_schedule_data.setIn(["non_working_days", "personal"], schedules)
                }
            }

            // docData = {
            //   _id : updated_schedule_data.toJS()._id,
            //   _rev : updated_schedule_data.toJS()._rev,
            //   diff_values : jsonDiff.diff( state.scheduleData, updated_schedule_data.toJS())
            // }
            docData = getDiffData(state.drawData, updated_schedule_data.toJS())
            logIt({ state: state, user: state.dbCredentials.username, module: "schedules", action: "update_schedule", description: temp, doc_data: docData, module_id: schedule.id })

            state.db.updateData(updated_schedule_data.toJS())

            return { ...state, scheduleData: updated_schedule_data.toJS() }

        case DELETE_SCHEDULE:
            schedule = action.payload

            var deleted_schedule
            var schedule_data_with_deleted
            section = schedule.section
            schedule.client_timestamp = unixEpoch
            schedule.client_timezone = timezone
            delete (schedule.section)

            if (section === 'schedules') {
                index = iSchedules.get('schedules').findIndex(dl => dl.get('id') === schedule.id)
                deleted_schedule = iSchedules.getIn(["schedules", index]).set("client_deleted", true)
                schedule_data_with_deleted = iSchedules.setIn(["schedules", index], deleted_schedule)
                //logIt({state: state, user: state.dbCredentials.username, module: "Schedules", action: "Deleted schedule", description:   schedule.id})
                temp = "Deleted schedule"
                //logIt(state,"Schedules", "Deleted schedule", schedule.id)
            }

            if (section === 'personal') {
                index = iSchedules.getIn(['non_working_days', 'personal']).findIndex(dl => dl.get('id') === schedule.id)
                deleted_schedule = iSchedules.getIn(["non_working_days", "personal", index]).set("client_deleted", true)
                schedule_data_with_deleted = iSchedules.setIn(["non_working_days", "personal", index], deleted_schedule)
                //logIt({state: state, user: state.dbCredentials.username, module: "Schedules", action: "Deleted non-working(personal) schedule", description:   schedule.id})
                temp = "Deleted non-working(personal) schedule"
                //logIt(state,"Schedules", "Deleted non-working(personal) schedule", schedule.id)
            }

            docData = getDiffData(state.drawData, schedule_data_with_deleted.toJS())
            logIt({ state: state, user: state.dbCredentials.username, module: "schedules", action: "delete_schedule", description: temp, doc_data: docData, module_id: schedule.id })

            state.db.updateData(schedule_data_with_deleted.toJS())
            return { ...state, scheduleData: schedule_data_with_deleted.toJS() }

        case ACCEPT_DRAW_REQUEST:
            var draw_requests = action.payload
            var updatedDrawRequests = iDrawRequests.set("draw_request", draw_requests)

            docData = getDiffData(state.drawData, updatedDrawRequests.toJS())
            logIt({ state: state, user: state.dbCredentials.username, module: "draw-request", action: "accept_draw_request", description: "Accepted draw request", doc_data: docData, draw_request_id: draw_requests.id })

            state.db.updateData(updatedDrawRequests.toJS())

            return { ...state, drawRequests: updatedDrawRequests.toJS() }

        case SET_SELECTED_SCHEDULE_VIEW:
            return { ...state, selectedScheduleView: action.payload }

        case START_APPOINTMENT:
            var payload = action.payload

            indexDrawList = iDrawData.get('draw_list').findIndex(dl => dl.get('id') === payload.siteId)
            var start_timestamp = iDrawData.getIn(["draw_list", indexDrawList, "start_timestamp"])
            console.log("start_timestamp", start_timestamp)

            if (start_timestamp) {
                console.log("start_timestamp already set")
                return { ...state }
            }
            console.log("Setting start_timestamp..")
            updatedDrawData = iDrawData.setIn(["draw_list", indexDrawList, "start_timestamp"], unixEpoch)
                .setIn(["draw_list", indexDrawList, "client_app_timezone"], timezone)

            // "start_timestamp": 1644321862,  
            // "end_timestamp": 1657532381,    
            // "client_app_timezone": ""       

            // updatedWorkflowData = iWorkflowData.setIn(["workflow", index_workflow, "workflow_data", workflow_key], workflow_value)
            // .setIn(["workflow", index_workflow, "client_app_timestamp"], unixEpoch)
            // .setIn(["workflow", index_workflow, "client_app_timezone"], timezone)

            console.log("START APPOINTMENT")
            console.log(payload, indexDrawList)
            console.log("start_timestamp", start_timestamp)
            state.db.updateData(updatedDrawData.toJS())

            return { ...state, drawData: updatedDrawData.toJS() }

        case SET_PATIENTS_DOESNT_EXIST_IN_APPT_INDICATOR:
            return { ...state, getPatientsListDoesntExistsInAppointmentIndicator: action.payload }

        case SET_PATIENTS_DOESNT_EXIST_IN_APPT:
            return { ...state, patientsListDoesntExistsInAppointment: action.payload }

        case SET_ADDED_EXISTING_PATIENT_IN_APPT:
            return { ...state, addedExistingPatientInAppointment: action.payload }

        case SET_ADD_EXISTING_PATIENT_IN_APPT_INDICATOR:
            return { ...state, addExistingPatientInAppointmentIndicator: action.payload }

        case SET_PATIENT_ID_BEING_ADDED:
            return { ...state, patientIdBeingAdded: action.payload }

        default:
            return state
    }
}

function updateDrawList(action, state, draw_list_id, patient_list_id) {
    const iDrawData = ImmutableJS.fromJS(state.drawData)

    var indexDrawList = iDrawData.get('draw_list').findIndex(dl => dl.get('id') === draw_list_id)

    var indexPatientList4 = iDrawData.getIn(['draw_list', indexDrawList, 'patient_list'])
        .findIndex(pl => pl.get('id') === patient_list_id)

    var urgentCurrentValue = iDrawData.getIn(
        ["draw_list", indexDrawList, "patient_list", indexPatientList4])
        .getIn(["urgent"])
    var needToSave = false

    if (urgentCurrentValue === false) {
        needToSave = true
    }

    var new_patient_draw = iDrawData.getIn(
        ["draw_list", indexDrawList, "patient_list", indexPatientList4]
    ).setIn(["urgent"], true).merge({ "newstat": true })


    var updatedDrawData = iDrawData.setIn(
        ["draw_list", indexDrawList, "patient_list", indexPatientList4],
        new_patient_draw)

    return { "needToSave": needToSave, "updatedData": updatedDrawData }
}

// function bubbleSortSites(updatedDrawData, draw_list_id) {
//     // NEED to move updating of routes on every status change, check if all patient draw are not remaining
//     // just to make sure site are ordered before updating site ordering of done site
//     const sortedSites = stableSort(updatedDrawData.getIn(["draw_list"]).toJS(), getSorting("asc", "site_order")).map(site => {
//         return site
//     })
//     var proceedBubleSort = false
//     var doneSites = []
//     var currentSite = []
//     var remainingSites = []

//     // explode sites: done, being work on, remaining sites
//     sortedSites.forEach(function (site) {
//         var stats = {
//             siteDone: 0,
//             siteTotalDrawItems: 0,
//             siteApproved: true
//         }

//         site.patient_list.map(patient => {
//             patient.patient_draw.map(drawItem => {
//                 if (drawItem.status === "done" || drawItem.status === "completed") {
//                     stats.siteDone++
//                 }
//                 if (drawItem.status === "skipped" || drawItem.status === "refused" || drawItem.status === "unable") {
//                     stats.siteDone++
//                     // if((drawItem.status === "refused" || drawItem.status === "unable") && drawItem.draw_sign_off.sof_first_name === "") {
//                     //   // if status in[refused,unable] it needs approval first
//                     //   // no need for approval now
//                     //   stats.siteApproved = false
//                     // }
//                 }

//                 stats.siteTotalDrawItems++
//                 return ''
//             })
//             return ''
//         })

//         if (site.id === draw_list_id) {
//             currentSite.push(site)

//             if (stats.siteApproved) {
//                 proceedBubleSort = true
//             }
//         } else {
//             if (stats.siteDone === stats.siteTotalDrawItems && stats.siteApproved) {
//                 doneSites.push(site)
//             } else {
//                 remainingSites.push(site)
//             }
//         }
//     })

//     /**************************************** UPDATE SITE ORDERING HERE */
//     // will not bubble sort if there is a need for Final Approval
//     if (proceedBubleSort) {
//         var mergedSites = doneSites.concat(currentSite.concat(remainingSites))
//         var ctr = 1
//         mergedSites = mergedSites.map(site => {
//             site.site_order = ctr
//             ctr++
//             return site
//         })
//         return updatedDrawData.setIn(["draw_list"], ImmutableJS.fromJS(mergedSites))
//     } else {
//         return updatedDrawData
//     }
//     /**************************************** END UPDATE SITE ORDERING */

// }

export default rootReducer
