const styles = theme => ({
    root: {
    },
    content: {
        flexGrow: 1,
    },
    mainContainer: {
        padding: "15px 20px",
        marginBottom: "60px"
        // paddingLeft: "30px",
        // paddingRight: "30px",
    },
    // top navigation
    topNavContainer: {
        padding: "11px 0px"
    },
    topNavLink: {
        fontSize: "20px",
        marginRight: "25px",
        paddingBottom: "5px",
        color: "#AFAFAF !important"
    },
    activeTopNav: {
        color: "#333333 !important",
        borderBottom: "3px solid #69CEF1",
    },
})

export default styles