// import React, {Component} from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import ImmutableJS from "immutable";
// import EllipsisText from "react-ellipsis-text";

import Typography from '@material-ui/core/Typography';

import ConfirmDialog from 'components/common/mobile/ConfirmDialog';
// import NotesIcon from '@material-ui/icons/Notes';
// import DeleteIcon from '@material-ui/icons/Delete';
// import AddIcon from '@material-ui/icons/Add';
import $ from 'jquery';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Button from '@material-ui/core/Button';
// import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';

import styles from "./styles.js";

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import * as globals from 'components/common/GlobalConstants';
import classNames from 'classnames';
//import { geolocated } from "react-geolocated";
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import { connect } from "react-redux";
import * as helper from 'components/common/Helper.js';

import {
    updatePatientDrawStatus,
    verifyPatientName,
    verifyPatientDoB,
    saveNote,
    deleteNote
} from "js/actions/index";

const EDIT = "edit";
const PREVIEW = "preview";
const NEW = "new";

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const mapDispatchToProps = dispatch => {
    return {
        updatePatientDrawStatus: drawItem => dispatch(updatePatientDrawStatus(drawItem)),
        verifyPatientName: verifiedPatientNameData => dispatch(verifyPatientName(verifiedPatientNameData)),
        verifyPatientDoB: verifiedPatientDoBData => dispatch(verifyPatientDoB(verifiedPatientDoBData)),
        saveNote: note => dispatch(saveNote(note)),
        deleteNote: note => dispatch(deleteNote(note)),
    };
};

const mapStateToProps = state => {
    return { drawData: state.drawData }
};

class NotesClass extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {

        super(props);
        const { cookies } = this.props;
        var maxChars = cookies.get('max_chars') || 0

        if (isNaN(maxChars)) {
            maxChars = 0
        }

        this.state = {
            ...this.state,
            siteId: "",
            patientId: "",
            // readOnly : false,
            dialogOpenedPatientRefused: false,
            dialogOpenedNotes: false,
            noteOperation: NEW,
            selectedNote: {
                id: "",
                message: "",
            },
            setDeleteNotesDialog: false,
            confirmDialogMessage: "",
            confirmDialogTitle: "",
            maxChars: (maxChars > 0) ? maxChars : globals.DEFAULT_NOTES_MAX_CHARACTERS,
            fieldDataCapture: props.fieldDataCapture ? props.fieldDataCapture : false
        };
        this.selectedPatientDraw = undefined;
        this.drawSiteValue = "right_arm";
        this.modifiedDrawSite = false;
        // console.log("(max_chars > 0) ? max_chars : globals.DEFAULT_NOTES_MAX_CHARACTERS")
        // console.log((maxChars > 0) ? maxChars : globals.DEFAULT_NOTES_MAX_CHARACTERS)

        //console.log("Notes: constructor");
        //this.getLocation()

        //     this.setNotesDialogVisibility = this.setNotesDialogVisibility.bind(this)
        //this.focusTextInput = this.focusTextInput.bind(this)
    }



    getLocation = () => {
        console.log("getLocation")
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.showPosition);
            console.log("Getting current GPS position...");

        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    }

    showPosition = (position) => {
        //console.log(position)
        //Main.latitude = position.coords.latitude;
        //Main.longitude = position.coords.longitude;
        //Main.hasLocation = true;
    }

    componentDidMount() {
        //console.log("Notes Didmount")
        // if (navigator.geolocation) {
        //     //navigator.geolocation.getCurrentPosition(this.showPosition);
        //     console.log("Getting current GPS position...");
        //     navigator.geolocation.getCurrentPosition(function(position) {
        //         console.log("Latitude is :", position.coords.latitude);
        //         console.log("Longitude is :", position.coords.longitude);
        //     });
        // } else { 
        //    console.log("Geolocation is not supported by this browser.");
        // }

        if (typeof this.props.greet === "function") {
            this.props.greet(this.greet)
        }

        this.props.noteCaptureRef.current = {
            openNote: this.openNote,
            addNewNote: this.addNewNote,
            removeNote: this.removeNote
        }
        // if (typeof this.props.openNote === "function") {
        //     this.props.openNote(this.openNote)
        // }

        // if (typeof this.props.addNewNote === "function") {
        //     this.props.addNewNote(this.addNewNote)
        // }

        // if (typeof this.props.removeNote === "function") {
        //     this.props.removeNote(this.removeNote)
        // }
    }

    addNewNote = () => {
        this.setNotesDialogVisibility(true, NEW)
    }

    componentWillMount() {
        // console.log('PatientClass: componentWillMount.'); 

        this.setState({
            ...this.state,
            patientId: this.props.patientId,
            siteId: this.props.siteId,
            // readOnly: this.props.readOnly 
        });
    }

    handleOpenDialog = event => {
        this.setState({ ...this.state, dialogOpenedPatientRefused: true });
    };

    setDeleteNotesDialogHandler = (actionHandler, postActionHandler, title, message, actionText, chainedFunction) => {
        // if(chainedFunction === null) chainedFunction = ()=>{};
        this.setState({
            ...this.state,
            actionHandler: actionHandler,
            postActionHandler: postActionHandler,
            confirmDialogMessage: message,
            confirmDialogTitle: title,
            confirmDialogActionText: actionText,
        }, chainedFunction);
    }

    setDeleteNoteDialogVisible = (value) => {
        //console.log("setDeleteNoteDialogVisible " + value);
        this.setState({ ...this.state, setDeleteNotesDialog: value });
    }

    setNotesDialogVisibility = (value, noteOperation = NEW) => {
        //console.log("setNotesDialogVisibility value " + value + " Operation " + noteOperation)
        var selectedNote = this.state.selectedNote;
        if (noteOperation === NEW) {
            selectedNote = {
                "id": "",
                "client_timezone": "",
                "author_type": "",
                "message": "",
                "client_deleted": false,
                "client_timestamp": ""
            }
        }

        // console.log("setNotesDialogVisibility");
        // console.log(selectedNote);
        this.setState({
            ...this.state,
            dialogOpenedNotes: value,
            noteOperation: noteOperation,
            selectedNote: selectedNote
        });
    };

    saveNote = (operation) => {
        const creds = helper.getCredentials();
        const isAuthor = creds.phleb_id === this.state.selectedNote.author;
        if (this.state.noteOperation === EDIT && (this.props.readOnly || !isAuthor)) {
            return
        }

        var message = $('#note_field').val();
        message = message.replace(/(<([^>]+)>)/ig, "");

        var note = ImmutableJS.fromJS(this.state.selectedNote).toJS();
        note.author = creds.phleb_id;
        note.message = message;
        // note.heading = window.$location.heading !== null ? window.$location.heading : ""
        // note.latitude = window.$location.latitude
        // note.longitude = window.$location.longitude
        note.gps_location = {
            latitude: window.$location.latitude,
            longitude: window.$location.longitude,
            heading: window.$location.heading !== null ? window.$location.heading : ""
        }

        note.metadata = {
            field_data_capture: this.state.fieldDataCapture
        }

        if (message === "") return;

        this.setState({ ...this.state, selectedNote: note });

        const notePayload = {
            "noteOperation": this.state.noteOperation,
            "siteId": this.props.siteId,
            "patientId": this.props.patientId,
            "patientDrawId": this.props.patientDrawId,
            "target": this.props.target,
            "note": note
        };

        this.props.saveNote(notePayload);
        this.setNotesDialogVisibility(false);
    }

    deleteNote = (note) => {
        const creds = helper.getCredentials();
        const selectedNote = note ? note : this.state.selectedNote;
        const isAuthor = creds.phleb_id === selectedNote.author;
        if (this.props.readOnly || !isAuthor) {
            return
        }

        this.props.deleteNote({
            "noteOperation": this.state.noteOperation,
            "siteId": this.state.siteId,
            "patientId": this.state.patientId,
            "patientDrawId": this.props.patientDrawId,
            "target": this.props.target,
            "note": selectedNote,
        });
        this.setNotesDialogVisibility(false);
    }

    removeNote = (note) => {
        const creds = helper.getCredentials();
        const selectedNote = note ? note : this.state.selectedNote;
        const isAuthor = creds.phleb_id === selectedNote.author;
        // console.log("removeNote")
        // console.log(selectedNote)
        // console.log("this.props.readOnly: " + this.props.readOnly)
        // console.log("isAuthor: " + isAuthor)
        if (this.props.readOnly || !isAuthor) {
            return
        }

        this.setDeleteNotesDialogHandler(
            () => this.deleteNote(selectedNote),
            () => {
                this.setDeleteNoteDialogVisible(false)
                this.setNotesDialogVisibility(false, EDIT)
            }
            ,
            "Delete Confirmation",
            "Confirm to delete this note?",
            "Delete",
            () => this.setDeleteNoteDialogVisible(true),
        )
    }

    openNote = (noteId) => {
        var iNotes = ImmutableJS.fromJS(this.props.notes);
        //console.log(noteId);
        var noteIndex = iNotes.findIndex(id => id.get('id') === noteId);
        var selectedNote = iNotes.getIn([noteIndex]).toJS();
        //console.log(iNotes.getIn([noteIndex]).toJS());

        this.setState({ ...this.state, selectedNote: selectedNote }, () => {
            this.setNotesDialogVisibility(true, PREVIEW)
        });
    };

    greet = () => {
        console.log("Take OVER!!!")
    }

    render() {
        const { classes, readOnly } = this.props;
        const { noteOperation, selectedNote } = this.state;
        // var addNewElementType = addNew !== undefined ? addNew : "link";
        const creds = helper.getCredentials();
        const isAuthor = creds.phleb_id === selectedNote.author;
        // console.log("reds.phleb_id: " + creds.phleb_id)   
        // console.log("selectedNote") 
        // console.log(selectedNote) 
        // console.log("isAuthor: " + isAuthor) 
        // console.log("props isReadOnly: " + readOnly)

        if (globals.DEBUG_ALLOWED) {
            if (window.$location) {
                console.log(window.$location)
            }
        }

        // var notes = "";
        // if(this.props.notes) {
        //     notes = this.props.notes.map(note => {
        //         if(!(note.client_deleted !== undefined && note.client_deleted === true)) {
        //             return <ListItem 
        //                     key={Math.random()}
        //                     disableGutters={true}
        //                     className={classes.requisitionList} 
        //                     aria-label="Notes"
        //                     onClick={()=>this.openNote(note.id)}
        //                     >
        //                     {addNewElementType==="link" && <NotesIcon className={classes.notesIcon}></NotesIcon>} <EllipsisText text={note.message.split("\n")[0]} length={30} />

        //                     <ListItemSecondaryAction onClick={()=>this.openNote(note.id)}>
        //                         <Icon classes={{root: classes.editIcon}}>edit</Icon>
        //                     </ListItemSecondaryAction>
        //                 </ListItem>;
        //         }
        //         return null;
        //     });

        //     notes = notes.filter(function (el) {
        //         return el != null;
        //     });

        //     // if(notes.length === 0) {
        //     //     notes = <ListItem 
        //     //         key={Math.random()}
        //     //         disableGutters={true}
        //     //         className={classNames(classes.requisitionList, classes.noNote)} 
        //     //         aria-label="Notes"
        //     //         >
        //     //         {addNewElementType==="link" && <NotesIcon className={classes.notesIcon}></NotesIcon>} No Notes                
        //     //     </ListItem>;
        //     // } 
        // }

        // var addNewNoteLink = <div key={Math.random()}>
        //     <Typography 
        //         onClick={()=>{
        //             // if(!this.state.readOnly) {
        //             //     this.setNotesDialogVisibility(true, NEW)
        //             // }
        //             this.setNotesDialogVisibility(true, NEW)
        //         }}
        //         color="primary" 
        //         //className={classNames(classes.attachmentButton, (readOnly) ? classes.disableAddNote : '')} 
        //         className={classNames(classes.attachmentButton)} 
        //         aria-label="Add New Note">
        //         <AddIcon className={classes.notesIcon}></AddIcon> ADD NOTE
        //     </Typography>
        // </div>;

        // var addNewNoteButton =
        //     <Button 
        //         onClick={()=>{
        //             // if(!readOnly) {
        //             //     this.setNotesDialogVisibility(true, NEW)
        //             // }
        //             this.setNotesDialogVisibility(true, NEW)
        //         }}
        //         variant="contained"
        //         color="secondary"
        //         className={classes.btnAddPhoto}
        //         disabled={readOnly}>
        //             <NotesIcon className={classes.notesIcon}></NotesIcon> ADD NOTE
        //     </Button>

        const textFieldDisabled = noteOperation !== NEW && (noteOperation === PREVIEW || readOnly || !isAuthor)

        return (
            <div className={classes.root}>
                {/* {addNewElementType==="button" &&
                    addNewNoteButton}
                    
                <List className={classes.listContainer}>
                    {notes}
                </List>

                {addNewElementType==="link" &&
                    addNewNoteLink} */}

                <Dialog
                    id="notes-dialog"
                    fullScreen={false}
                    classes={{
                        paper: classes.dialogPaperForNotes
                    }}
                    open={this.state.dialogOpenedNotes}
                    // onEnter={this.handleOnShowDialogPatientRefused}
                    aria-labelledby="notes-title">
                    <DialogTitle
                        id="notes-title"
                        classes={{
                            root: classes.dialogTitleContainer
                        }}
                    ><Typography className={classes.title}>{noteOperation === NEW ? "Add a note" : "Note"}</Typography></DialogTitle>
                    <DialogContent
                        classes={{
                            root: classes.dialogContentContainer
                        }}
                    >
                        <TextField
                            id="note_field"
                            type="text"
                            variant="outlined"
                            fullWidth={true}
                            disabled={textFieldDisabled}
                            InputProps={{
                                maxLength: this.state.maxChars,
                                classes: {
                                    notchedOutline: classes.drawPatientRemarksField,
                                    root: classNames(classes.notesField, textFieldDisabled ? classes.notesFieldDisabled : "")
                                },
                            }}
                            rows={10}
                            multiline={true}
                            // onChange={event => {
                            //    // const { value } = event.target;
                            //    // var selectedNote = ImmutableJS.fromJS(selectedNote).toJS();
                            //   //  selectedNote.message = value; 
                            //    ////console.log(selectedNote)
                            //    // this.setState({ selectedNote  : selectedNote });
                            // }}
                            defaultValue={selectedNote.message}
                        />
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        {(noteOperation === PREVIEW || noteOperation === EDIT) && !readOnly && isAuthor &&
                            <Typography
                                className={classNames(classes.deleteText)}
                                onClick={() => {
                                    this.removeNote()
                                }}
                            >
                                Delete
                            </Typography>
                            // <DeleteIcon
                            //     //variant="contained"
                            //     color="secondary"
                            //     className={classNames(readOnly || !isAuthor ? classes.disabledDeleteIcon : classes.deleteIcon)}
                            //     fontSize="large"
                            //     //onClick = {this.deleteNote}
                            //     onClick={() => { this.removeNote() }}
                            // // disabled={this.state.readOnly}
                            // ></DeleteIcon>
                        }

                        <Button
                            className={classes.btnClose}
                            onClick={() => this.setNotesDialogVisibility(false, this.state.noteOperation)}
                            color="default">Cancel</Button>

                        {noteOperation === NEW &&
                            <Button
                                color="secondary"
                                className={classes.btnSave}
                                onClick={this.saveNote}
                            >Done</Button>
                        }

                        {!readOnly && isAuthor &&
                            <>
                                {noteOperation === PREVIEW &&
                                    <Button
                                        color="secondary"
                                        className={classes.btnSave}
                                        onClick={() => {
                                            this.setState({ ...this.state, noteOperation: EDIT })
                                        }}
                                    >Edit</Button>
                                }
                                {noteOperation === EDIT &&
                                    <Button
                                        color="secondary"
                                        className={classes.btnSave}
                                        onClick={this.saveNote}
                                        disabled={noteOperation === EDIT && (readOnly || !isAuthor)}
                                    >Done</Button>
                                }
                            </>
                        }

                    </DialogActions>
                </Dialog>

                <ConfirmDialog
                    type="warning"
                    visible={this.state.setDeleteNotesDialog}
                    title={this.state.confirmDialogTitle}
                    message={this.state.confirmDialogMessage}
                    actionText={this.state.confirmDialogActionText}
                    actionHandler={this.state.actionHandler}
                    postActionHandler={this.state.postActionHandler}
                    onClose={() => {
                        //console.log("ConfirmDialog On close")
                        setTimeout(() => {
                            this.setDeleteNoteDialogVisible(false);
                        }, 100);

                    }}
                />

            </div>
        );

    }
}

NotesClass.propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    theme: PropTypes.object.isRequired,
};

const Notes2 =

    withCookies(
        connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(withMobileDialog()
            (withStyles(styles, { withTheme: true })
                (NotesClass)
            ))
    )
export default Notes2;
