import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';

const styles = theme => ({
    snackbar: {
        "& span": {
            color: "white !important"
        }
    },
    warning: {
        boxShadow: "none !important",
        backgroundColor: amber[700],
    },
    success: {
        boxShadow: "none !important",
        backgroundColor: green[600],
    },
    error: {
        boxShadow: "none !important",
        backgroundColor: theme.palette.error.dark,
    },
    danger: {
        boxShadow: "none !important",
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        boxShadow: "none !important",
        backgroundColor: theme.palette.primary.dark,
    }
});

class SnackBarPopup extends React.Component {
    render() {
        const { classes, message, open, onClose, variant } = this.props;

        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                disableWindowBlurListener={true}
                autoHideDuration={3000}
                open={open}
                onClose={onClose}
            >
                <SnackbarContent
                    className={classNames(classes.snackbar, classes[variant])}
                    aria-describedby='snackbar'
                    message={<span id="snackbar">{message}</span>} />
            </Snackbar>
        );
    }
}

SnackBarPopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    message: PropTypes.node,
    variant: PropTypes.oneOf(['default', 'success', 'warning', 'error', 'danger', 'info']).isRequired,
};

export default withStyles(styles)(SnackBarPopup);