import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
// import classNames from 'classnames'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const PastFunctionClass = (props) => {

    return (
        <div>
            Past
        </div>
    )
}

const Past = withCookies(
    connect(mapStateToProps)(
        withStyles(styles, { withTheme: true })(PastFunctionClass)
    )
)
export default Past
