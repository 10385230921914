import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import classNames from 'classnames';
import ApplicationBar from 'components/common/mobile/ApplicationBar';
import SimpleBottomNavigation from 'components/common/mobile/SimpleBottomNavigation';
import { connect } from "react-redux";

import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
// import TextField from '@material-ui/core/TextField';
import styles from "./styles.js";
import { InputBase } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import uuid from "uuid";
import ConfirmDialog from 'components/common/mobile/ConfirmDialog';
import Typography from '@material-ui/core/Typography';

import {
    updateServicedZipcodes,
    logEvent
    } from "js/actions";

import ImmutableJS from "immutable";

const mapStateToProps = state => {
  return { 
        drawData: state.drawData,
        scheduleData: state.scheduleData,
        onlineStatus : state.onlineStatus
    };
};
const mapDispatchToProps = dispatch => {
    return {
        updateServicedZipcodes: serviced_zipcodes => dispatch(updateServicedZipcodes(serviced_zipcodes)),
        logEvent: event => dispatch(logEvent(event))
    };
};

class WorkTerritoryClass extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            showDeleteZipcodeDialog: false,
            confirmDialogTitle: "",
            confirmDialogMessage: "",
            confirmDialogActionText: "",
            actionHandler: "",
            postActionHandler: "",
            zip_code_value: ""
        };
    }

    componentWillMount() {
        // console.log('WorkTerritoryClass did mount.');
        // console.log(this.props.scheduleData);
        this.loadData();
    }

    componentDidMount() {
        this.props.logEvent({module: "service-area", action: "visit_service_area", description: "Visited Service Area Page"})
    }

    componentDidUpdate(prevProps) {
        if(prevProps.scheduleData.serviced_zipcodes !== this.props.scheduleData.serviced_zipcodes){
          this.loadData();
        }
    }

    loadData = () => {
        var serviced_zipcodes = [];
        
        if(this.props.scheduleData.serviced_zipcodes !== undefined) {
            serviced_zipcodes = ImmutableJS.fromJS(this.props.scheduleData.serviced_zipcodes).toJS();
        }

        // console.log(serviced_zipcodes);
        
        this.setState({...this.state,
            // zip_code_value: "",
            zip_codes: serviced_zipcodes
        }); 
    }

    goTo(url) {
        this.props.history.push(url);
    }

    handleChangeZipCode = (event) => {
        // console.log("handleChangeZipCode");
        this.setState({
            ...this.state,
            zip_code_value: event.target.value
        });
    }
    
    isNumeric =(value) =>{
        return !isNaN(value)
    }
    
    validateZipCode = (elementValue) => {
        var zipCodePattern = /^\d{5}$|^\d{5}-\d{4}$/;
         return zipCodePattern.test(elementValue);
    }
    
    getUnique = (array) => {
        var uniqueArray = [];
        
        // Loop through array values
        for(var i=0; i < array.length; i++){
            if(uniqueArray.indexOf(array[i]) === -1) {
                uniqueArray.push(array[i]);
            }
        }
        return uniqueArray;
    }

    addZipCode = () => {
        if(!this.props.onlineStatus) {
            return;
        }

        if(this.state.zip_code_value.trim() === "") return;
        
        var arr = this.state.zip_codes;
        
        var zipCodeRawText = this.state.zip_code_value;
        var _new_zipCodeRawText = "";
        //if (zipCodeRawText.indexOf('\n') > -1){
            _new_zipCodeRawText = zipCodeRawText.replace(/[\n\r]/g, ',');
            console.log(_new_zipCodeRawText);
        //} 
 
        if (_new_zipCodeRawText.indexOf(',') > -1) {
        
            _new_zipCodeRawText.split(',').forEach((item)=>{
        
                var trimmedItem = item.trim();
        
                if( trimmedItem !== "" ) {
                    //console.log("this.validateZipCode(trimmedItem)");
                    //console.log(trimmedItem + " - " + this.validateZipCode(trimmedItem));

                    if(this.validateZipCode(trimmedItem)) {
                        arr.push(trimmedItem);
                    }
               }
            });
 
        } else {

            if(this.validateZipCode(_new_zipCodeRawText.trim())) {
                arr.push(_new_zipCodeRawText.trim());
            }
        }

        // console.log("arr start");
        // console.log(arr);
        var filteredArray = this.getUnique(arr);
        // console.log("arr after");
        // console.log(filteredArray);
 
        this.setState({
            ...this.state,
            zip_codes: filteredArray,
            zip_code_value: ""
        }, this.onUpdateServicedZipcodes(filteredArray));
       
    }

    removeZipCode = (zip_code) => {
        if(!this.props.onlineStatus) {
            console.log("Cannot remove zipcode when offline.")
            return;
        }

        var arr = this.state.zip_codes;
        var index = arr.indexOf(zip_code);
 
        if (index > -1) {
            arr.splice(index, 1);
        }

        // this.setState({
        //     ...this.state,
        //     zip_codes: arr
        // },this.onUpdateServicedZipcodes(arr));
        this.onUpdateServicedZipcodes(arr);
    }

    onUpdateServicedZipcodes = (serviced_zipcodes) => {
        //var serviced_zipcodes = this.props.scheduleData.serviced_zipcodes;
        //console.log("onUpdateServicedZipcodes");
        // console.log(serviced_zipcodes);
        //var serviced_zipcodes = this.state.zip_codes;
       // console.log(serviced_zipcodes);
        this.props.updateServicedZipcodes(serviced_zipcodes);
    }

    setShowDeleteZipcodeDialog = (value) => {
        if(!this.props.onlineStatus) {
            console.log("Cannot remove zipcode when offline.")
            return;
        }
        this.setState({ ...this.state, showDeleteZipcodeDialog: value});
    }

    setDeleteZipcodeDialogHandler = (actionHandler, postActionHandler, title, message, actionText, chainedFunction) => {
        this.setState(
            {
                ...this.state, 
                actionHandler: actionHandler, 
                postActionHandler : postActionHandler, 
                confirmDialogMessage: message,
                confirmDialogTitle: title,
                confirmDialogActionText: actionText,
            }, 
            chainedFunction
        );
    }

    render() {   
        const { classes } = this.props;

        var odd = false;
        var zipCodes = this.state.zip_codes.map(function(zip_code) {
            odd = !odd;
            return <ListItem
                key={uuid.v4()}
                classes={{
                    root: classNames(odd ? "" : classes.listOdd, classes.listContent)
                }}>
                {zip_code}
                <ListItemSecondaryAction>
                    <Icon
                        disabled={!this.props.onlineStatus}
                        onClick={() => {
                            this.setDeleteZipcodeDialogHandler(
                                () => this.removeZipCode(zip_code), 
                                () => { this.setShowDeleteZipcodeDialog(false) },
                                "Delete Confirmation",
                                "Confirm to delete this Zipcode?",
                                "Delete",
                                () => { this.setShowDeleteZipcodeDialog(true) }, 
                            )
                        }}
                        classes={{root: classes.clearButton}}
                    >clear</Icon>
                </ListItemSecondaryAction>
            </ListItem>;
        }.bind(this));

        return(
            <div className={classes.root}>
                <main className={classes.content}>
                    <ApplicationBar isInfoHidden={true} />
                    <div className={classNames(classes.schedulesMainContainer)}>
                        <div>
                            <List classes={{root: classNames(classes.listUL, classes.listULMainHeader)}}>
                                <ListItem>
                                    <ListItemText
                                        classes={{
                                            root: classes.listItemRoot,
                                            primary: classes.schedulesMainHeader,
                                            secondary: classes.workTerritoryNote
                                        }}
                                        primary="Service Area"
                                        secondary="Enter the ZIP codes in which you are available to work."
                                    />
                                </ListItem>
                            </List>
                        </div>
                        { !this.props.onlineStatus && 
                        <div>
                            <Typography className={classes.onlineOnlyWarning}>
                                Editing of Service Area when OFF-LINE is NOT allowed. 
                            </Typography>
                        </div>
                        }
                        <div className={classNames(classes.settingsContent)}>
                            <List component="nav">
                                <ListItem 
                                    classes={{
                                        root: classes.textFieldContainer
                                    }}>
  
                                    <InputBase
                                        multiline={true}
                                        disableunderline="true"
                                        id="standard-uncontrolled"
                                        fullWidth={true}
                                        rows={5}
                                        placeholder="Begin typing ZIP code..."
                                        value={this.state.zip_code_value}
                                        classes= {{
                                            root: classes.workTerritoryRoot,
                                            input: classes.workTerritoryInput
                                        }}
                                        onChange={this.handleChangeZipCode}
                                        disabled={!this.props.onlineStatus}
                                    />
                                </ListItem>
                                <ListItemSecondaryAction>
                                    <Button
                                        onClick={this.addZipCode}
                                        disabled={!this.props.onlineStatus}
                                        variant="contained"
                                        color="secondary"
                                        className={classNames(classes.buttons, classes.blueButton)}>
                                    <Icon classes={{root: classes.addbutton}}>add</Icon></Button>
                                </ListItemSecondaryAction>
                            </List>
                            
                            <Paper className={classes.listPaper}>
                                <List classes={{root: classes.listRoot}} component="nav">
                                    <ListItem 
                                        classes={{
                                            root: classes.listHeader
                                        }}>
                                        Your ZIP Codes
                                    </ListItem>
                                    {zipCodes}
                                </List>
                            </Paper>
                        </div>
                    </div>
                    <div className={classes.toolbar} />
                    <div className={classes.toolbar} />
                </main>
                <SimpleBottomNavigation />
                
                <ConfirmDialog 
                    visible = {this.state.showDeleteZipcodeDialog} 
                    title = {this.state.confirmDialogTitle} 
                    message = {this.state.confirmDialogMessage} 
                    actionText= {this.state.confirmDialogActionText} 
                    actionHandler={this.state.actionHandler} 
                    postActionHandler={this.state.postActionHandler} 
                    onClose={() => {                         
                        setTimeout(() => {
                            this.setShowDeleteZipcodeDialog(false);
                        }, 100);
                    } }
                />
            </div>
        );
    }
}

WorkTerritoryClass.propTypes = {
    classes: PropTypes.object.isRequired,
};

const WorkTerritory = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WorkTerritoryClass));
export default WorkTerritory;
