import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'

import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import classNames from 'classnames'

import QRCode from "react-qr-code"

import { FormControl } from '@material-ui/core'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { DialogTitle } from 'components/common/Helper.js'

import InputBase from '@material-ui/core/InputBase'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import IconButton from '@material-ui/core/IconButton'

import moment from 'moment'

import uuid from "uuid"
import { PDC_PATIENT_FORM_LINK } from 'components/common/GlobalConstants'

import { getPatientsDoesntExistsInAppointment } from "js/actions/index"

const mapStateToProps = state => {
    return {
        drawData: state.drawData,
        patientIdBeingAdded: state.patientIdBeingAdded,
        patientsListDoesntExistsInAppointment: state.patientsListDoesntExistsInAppointment,
        getPatientsListDoesntExistsInAppointmentIndicator: state.getPatientsListDoesntExistsInAppointmentIndicator,
        addExistingPatientInAppointmentIndicator: state.addExistingPatientInAppointmentIndicator,
        onlineStatus: state.onlineStatus,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPatientsDoesntExistsInAppointment: hashed_id => dispatch(getPatientsDoesntExistsInAppointment(hashed_id)),
    }
}

const OrderFilterFunctionClass = (props) => {
    const {
        drawData,
        classes,
        searchText,
        setSearchText,
        sort,
        setSort,
        onAddToList,
        // onRemoveFromList,
        newlyAddedPatients,
        match,
        patientsListDoesntExistsInAppointment,
        // getPatientsListDoesntExistsInAppointmentIndicator,
        getPatientsDoesntExistsInAppointment,
        addExistingPatientInAppointmentIndicator,
        patientIdBeingAdded,
        onlineStatus
    } = props
    const { appointment_id } = match.params
    var patient_form_link = ""
    var theSite = null
    var patientsId = []

    // console.log("onlineStatus")
    // console.log(onlineStatus)

    const [addPatientOptionsDialogOpen, setAddPatientOptionsDialogOpen] = useState(false)
    const [scanQrCodeDialogOpen, setScanQrCodeDialogOpen] = useState(false)
    const [searchPatientDialogOpen, setSearchPatientDialogOpen] = useState(false)
    const [searchValue, setSearchValue,] = useState("")

    const goToAddNewPatient = () => {
        window.open(patient_form_link, '_blank', 'noreferrer')
    }

    const handleChange = (event) => {
        setSearchValue(event.target.value)
    }

    const searchResults = patientsListDoesntExistsInAppointment.filter((order) => {
        const name = order.first_name + " " + order.last_name
        return name.toLowerCase().includes(searchValue.toLowerCase())
    })

    if (drawData.draw_list !== undefined) {
        theSite = drawData.draw_list.find(function (site) {
            return site.id === appointment_id
        })
        patient_form_link = PDC_PATIENT_FORM_LINK + "&appointment_id=" + theSite.hashed_id

        console.log("group", theSite)
        patientsId = theSite.patient_list.map(patient => patient.id)
        console.log("patientsId", patientsId)
        console.log("newlyAddedPatients", newlyAddedPatients)
    }

    const hashedIdFeatureAvailable = theSite && theSite.hashed_id

    return (
        <>
            <div className={classNames(classes.filterContainer)}>
                <InputBase
                    className={classes.filterField}
                    placeholder="Search by name"
                    inputProps={{ 'aria-label': 'naked' }}
                    value={searchText}
                    onChange={(event) => {
                        setSearchText(event.target.value)
                    }}
                />
                <Button
                    className={classes.sortButton}
                    onClick={() => {
                        setSort(sort === "asc" ? "desc" : "asc")
                    }}
                >
                    A-Z {sort === "asc" ? <ArrowDropDownIcon className={classes.arrowSortIcon} /> : <ArrowDropUpIcon className={classes.arrowSortIcon} />}
                </Button>

                <IconButton aria-label="Add new patient" className={classes.addButton} onClick={() => { setAddPatientOptionsDialogOpen(true) }}>
                    <img src="/images/add_patient_circle.svg" title="Add new patient" className={classes.addButtonIcon}></img>
                </IconButton>
            </div>

            <Dialog
                id="options-dialog"
                open={addPatientOptionsDialogOpen && !scanQrCodeDialogOpen && !searchPatientDialogOpen}
                classes={{ paperScrollPaper: classes.addDataOptionsDialogContainer }}
                disableBackdropClick={true}
            >
                <DialogContent
                    className={classes.addDataOptionsDialogContent}
                    onClose={() => { setAddPatientOptionsDialogOpen(false) }}
                    aria-labelledby="options-dialog-title"
                    id="options-dialog"
                >
                    <Button
                        classes={{
                            root: classes.btnCaptureData,
                            label: classes.btnCaptureDataLabel
                        }}
                        onClick={() => {
                            if (theSite && theSite.hashed_id) {
                                getPatientsDoesntExistsInAppointment(theSite.hashed_id)
                            }
                            setSearchPatientDialogOpen(true)
                        }}> Add Existing Patients </Button>
                    <Button
                        classes={{
                            root: classes.btnCaptureData,
                            label: classes.btnCaptureDataLabel
                        }}
                        onClick={() => {
                            setScanQrCodeDialogOpen(true)
                        }}> Add New Patient </Button>
                    <Button className={classes.btnCloseAddData} onClick={() => { setAddPatientOptionsDialogOpen(false) }}> Close </Button>
                </DialogContent>
            </Dialog>

            <Dialog
                id="search-patient-dialog"
                open={searchPatientDialogOpen}
                onClose={() => { setSearchPatientDialogOpen(false) }}
                aria-labelledby="search-patient-dialog-title"
                disableBackdropClick={true}
                classes={{ paper: classes.cancelDialogBox }}
            >
                <DialogTitle
                    id="search-patient-dialog-title"
                    classes={{
                        root: classes.dialogTitleContainer
                    }}
                >
                    <Typography className={classes.title}>Search and select patient to add</Typography>
                </DialogTitle>
                {/* <p>Dispatcher Active: {getPatientsListDoesntExistsInAppointmentIndicator.active ? "Fetching..." : "-------"}</p>
                <p>Data Dispatched: {JSON.stringify(patientsListDoesntExistsInAppointment)}</p> */}

                <DialogContent className={classNames(classes.searchDialogContent)}>
                    {onlineStatus
                        ?
                        <>
                            {hashedIdFeatureAvailable
                                ?
                                <>
                                    <div className={classNames(classes.searchContainer)}>
                                        <InputBase
                                            type='search'
                                            className={classes.searchPatientField}
                                            placeholder="Search by name"
                                            inputProps={{ 'aria-label': 'naked' }}
                                            value={searchValue}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className={classes.searchResultContainer}>
                                        {(searchValue !== "" && searchResults.length > 0) &&
                                            searchResults.map((patient, index) => {
                                                return (
                                                    <Grid
                                                        key={uuid.v4()}
                                                        container
                                                        justify="space-between"
                                                        alignItems="center"
                                                        className={classes.searchResultItems}
                                                    >
                                                        <div>
                                                            <span className={classes.searchItemName}>{patient.first_name} {patient.last_name}</span>
                                                            <span className={classes.searchItemDoB}>{moment(patient.birth_date, "YYYY-MM-DD").format("DD/MM/YYYY")}</span>
                                                        </div>
                                                        <div className={classes.searchItemButtonContainer}>
                                                            {newlyAddedPatients.indexOf(patient.id) > -1 || patientsId.indexOf(patient.id) > -1
                                                                ?
                                                                <Button
                                                                    color="secondary"
                                                                    // className={classes.searchItemBtnRemoveResult}
                                                                    className={classes.searchItemBtnAddedResult}
                                                                // onClick={() => { onRemoveFromList(patient.id) }}
                                                                >Added</Button>
                                                                :
                                                                <Button
                                                                    color="secondary"
                                                                    className={classes.searchItemBtnAddResult}
                                                                    disabled={addExistingPatientInAppointmentIndicator.active}
                                                                    onClick={() => { onAddToList(theSite.hashed_id, patient.id) }}
                                                                >{patientIdBeingAdded && patientIdBeingAdded === patient.id ? "Adding..." : "Add"}</Button>
                                                            }
                                                        </div>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </div>
                                </>
                                :
                                <div className={classes.searchResultContainer}>
                                    <Typography className={classes.warningText}>Unable to use this feature. Group hashed_id={typeof theSite.hashed_id}</Typography>
                                </div>
                            }
                        </>
                        :
                        <div className={classes.searchResultContainer}>
                            <Typography className={classes.warningText}>Must be online to use this feature.</Typography>
                        </div>
                    }

                </DialogContent>

                <DialogActions className={classes.dialogActions}>
                    <Button
                        className={classes.btnClose}
                        onClick={() => setSearchPatientDialogOpen(false)}
                        color="default">Cancel</Button>
                    <Button
                        color="secondary"
                        className={classes.btnSave}
                        onClick={() => { setSearchPatientDialogOpen(false) }}
                    >Done</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                id="scan-qr-dialog"
                open={scanQrCodeDialogOpen}
                onClose={() => { setScanQrCodeDialogOpen(false) }}
                aria-labelledby="scan-qr-dialog-title"
                classes={{ paper: classes.cancelDialogBox }}
            >
                <DialogTitle
                    id="scan-qr-dialog-title"
                    classes={{
                        root: classes.dialogTitleContainer
                    }}
                >
                    <Typography className={classes.title}>Scan the QR Code</Typography>
                </DialogTitle>

                {hashedIdFeatureAvailable
                    ?
                    <>
                        <DialogContent>
                            <FormControl className={classNames(classes.qrCodeBox)}>
                                <QRCode value={patient_form_link} size={250} />
                                {/* <QRCode value={"This feature is not yet available."} size={250} /> */}
                            </FormControl>

                            <DialogContentText className={classes.dialogText}>
                                Ask the patient to scan the code and fill out the patient registration form
                            </DialogContentText>
                        </DialogContent>

                        <DialogContent className={classNames(classes.buttonPosition)}>
                            <Button
                                classes={{ root: classes.addBtn, label: classes.addBtnLabel }}
                                onClick={() => {
                                    goToAddNewPatient()
                                }}
                            // disabled
                            > Add Patient Manually </Button>
                        </DialogContent>
                    </>
                    :
                    <DialogContent>
                        <div className={classes.searchResultContainer}>
                            <Typography className={classes.warningText}>Unable to use this feature. Group hashed_id={typeof theSite.hashed_id}</Typography>
                        </div>
                    </DialogContent>
                }
            </Dialog>
        </>
    )
}

const OrderFilter = withRouter(
    withCookies(
        connect(mapStateToProps, mapDispatchToProps)(
            withStyles(styles, { withTheme: true })(OrderFilterFunctionClass)
        )
    )
)
export default OrderFilter
