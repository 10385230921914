import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import { connect } from "react-redux";
//import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import UpdateIcon from '@material-ui/icons/Update';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import $ from 'jquery';

import ApplicationBar from 'components/common/mobile/ApplicationBar';
import SimpleBottomNavigation from 'components/common/mobile/SimpleBottomNavigation';
import * as globals from 'components/common/GlobalConstants';
import MiniLoadingSpinner from "components/common/mobile/MiniLoadingSpinner.js";

import  Encryption from 'components/common/Encryption';

import * as helper from 'components/common/Helper.js'

import { ReactComponent as DrawRequestOutlineSVG } from 'components/common/mobile/CustomOfflineSVG/draw_request_outline.svg';

const mapStateToProps = state => {
    return { 
        drawData: state.drawData,
        newAppVersion: state.newAppVersion
    };
};

const styles = theme => ({
    root: {
        // display: 'flex',
        paddingTop: "15px !important",
        paddingLeft: "10px !important",
        paddingRight: "10px !important",
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
    },
    list: {
      border: "1px solid rgba(0, 0, 0, 0.16)",
      marginBottom: theme.spacing.unit,
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px`,
      fontSize: "32px !important",
    //   whiteSpace : "nowrap"
    },
    icon: {
        fontSize: "38px !important",
    },
    imgIcon: {
        width: 38,
        height: 38
    },
    label: {
        fontSize: ".8em !important",
        color: "#707070",
    },
    version_label: {
        color: "#ff8d00 !important", // "#f31700 !important",
        fontSize: "16px !important",
    },
    buttons: {
        // margin: theme.spacing.unit * 2,
        // marginLeft: 0,
        textTransform: 'none',
        borderRadius: 0,
    },
    dialogPaper: {
      minWidth: "90%",
      margin: 10, 
    },
    settingsContent: {
        padding: "0px 16px"
    },
    UL: {
        marginTop: -10,
        padding: 0
    },
    miniDialogPaper: {
      maxHeight: "35%",
      minHeight: "35%",
      minWidth: "90%",
      margin: 10,
    }
});

class SettingsClass extends Component {
    static propTypes = {
      cookies: instanceOf(Cookies).isRequired
    };
  
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            anchorEl: null,
            aboutDialogOpened: false,
            upToDateDialogOpened: false,
            changePasswordDialogOpened: false,
            changePasswordLoadingDialogOpened: false,
            changePasswordMessage: '',
        };
    }

    componentDidMount() {
        // console.log("componentDidMount SettingsClass")
    }

    handleChangePassword = () => {
        this.setState({ ...this.state, changePasswordLoadingDialogOpened: true });

        var security = new Encryption();
        var decryptedCred = JSON.parse(security.decrypt(this.props.allCookies.credentials))

        var phleb = decryptedCred.phleb_id;
        var token = decryptedCred.token;
        var user_data = {user_id: phleb};

        var request = $.ajax({
                beforeSend: function(xhr, settings) {
                    xhr.setRequestHeader('Authorization','Token ' + token);
                },
                url: globals.SERVER + "/api/v1/password/reset/",
                method: "POST",
                data: user_data
            });

        request.done(function( response ) {
            this.showPasswordResetMessage(response.message);
        }.bind(this));

        request.fail(function( jqXHR, textStatus ) {
            setTimeout(() => {
                
                try{
                    this.showPasswordResetMessage(jqXHR.responseJSON.message);
                }catch(err){
                    this.showPasswordResetMessage('Failed to get response from TeloPoint at this time.');
                }

            }, 2000);
            
        }.bind(this));

    };


    showPasswordResetMessage(message){
        this.setState({
            ...this.state,
            aboutDialogOpened: false,
            changePasswordDialogOpened: true,
            changePasswordLoadingDialogOpened: false,
            changePasswordMessage: message
        });
    };
      
    handleProfileSettings = () => {
        // window.location.href = '/test';
    };

    handleOpenDialog = () => {
        this.setState({ 
            ...this.state,
            aboutDialogOpened: true 
        });
    };
  
    handleCloseDialog = () => {
        this.setState({ 
            ...this.state,
            aboutDialogOpened: false,
            changePasswordDialogOpened: false,
            upToDateDialogOpened: false,
        });
    };
      
    handleLogoutClick(e) {
        this.props.history.push("/logout/user");
    };

    handleUpdateClick(e) {
        console.log("Updating...")
        console.log("{ action: 'skipWaiting' }")
 
        if ('newServiceWorker' in navigator) {
            helper.updateApp()
        } else {
            this.setState({ 
                ...this.state,
                upToDateDialogOpened: true 
            });
        }
    }

    goTo(url) {
        this.props.history.push(url);
    }
    
    render() {   
        const { classes, newAppVersion } = this.props;

        return(
            <div className={classes.root}>
                <main className={classes.content}>
                    <ApplicationBar isInfoHidden={true} />
                    <br/>
                    <br/>

                    <div className={classNames(classes.settingsContent)}>
                        <List classes={{root: classes.UL}}>
                            <ListItem 
                                onClick={() => {this.goTo("/appointments/today");}}
                                button className={classNames(classes.list)}>
                                <ListItemIcon>
                                    <DrawRequestOutlineSVG className={classes.imgIcon}></DrawRequestOutlineSVG>
                                </ListItemIcon>
                                <ListItemText classes={{primary: classes.label}} primary="IV Infusion" />
                            </ListItem>
                            <ListItem 
                                onClick={() => {this.goTo("/draw_request");}}
                                button className={classNames(classes.list)}>
                                <ListItemIcon>
                                    <DrawRequestOutlineSVG className={classes.imgIcon}></DrawRequestOutlineSVG>
                                </ListItemIcon>
                                <ListItemText classes={{primary: classes.label}} primary="Draw Request" />
                            </ListItem>

                            <ListItem 
                                onClick={() => {this.goTo("/future_orders");}}
                                button className={classNames(classes.list)}>
                                <ListItemIcon>
                                <Icon className={classes.icon}>layers</Icon>
                                </ListItemIcon>
                                <ListItemText classes={{primary: classes.label}} primary="Future Orders" />
                            </ListItem>
                            
                            {/* <ListItem 
                                onClick={() => {this.goTo("/gps_lock");}}
                                button className={classNames(classes.list)}>
                                <ListItemIcon>
                                <Icon className={classes.icon}>layers</Icon>
                                </ListItemIcon>
                                <ListItemText classes={{primary: classes.label}} primary="GPS Lock testpage" />
                            </ListItem> */}

                            <ListItem 
                                onClick={() => {this.goTo("/lock_screen");}}
                                button className={classNames(classes.list)}>
                                <ListItemIcon>
                                <Icon className={classes.icon}>lock</Icon>
                                </ListItemIcon>
                                <ListItemText classes={{primary: classes.label}} primary="Lock Screen" />
                            </ListItem>

                            <ListItem 
                                onClick={() => {this.goTo("/work_territory");}}
                                button 
                                className={classNames(classes.list)}>
                            
                                <ListItemIcon>
                                    <Icon className={classes.icon}>my_location</Icon>
                                </ListItemIcon>
                                <ListItemText classes={{primary: classes.label}} primary="Service Area" />
                            </ListItem>

                            <ListItem 
                                onClick={() => {this.handleChangePassword();}}
                                button className={classNames(classes.list)}>
                                <ListItemIcon>
                                    <Icon className={classes.icon}>lock</Icon>
                                </ListItemIcon>
                                <ListItemText classes={{primary: classes.label}} primary="Change Password" />
                            </ListItem>

                            <ListItem 
                                onClick={() => {this.handleUpdateClick()}}
                                button className={classNames(classes.list)}>
                                <ListItemIcon>
                                    <UpdateIcon className={classes.icon}></UpdateIcon>
                                </ListItemIcon>
                                <ListItemText classes={{
                                    primary: classes.label,
                                    secondary: classes.version_label
                                }} primary="Update" secondary={newAppVersion ? "New version available!" : ""} />
                            </ListItem>

                            <ListItem 
                                onClick={() => {this.handleOpenDialog();}}
                                button 
                                className={classNames(classes.list)}>
                            
                                <ListItemIcon>
                                    <Icon className={classes.icon}>info</Icon>
                                </ListItemIcon>
                                <ListItemText classes={{primary: classes.label}} primary="About" />
                            </ListItem>
                            {globals.ALLOW_USER_TO_VIEW_LOGS &&
                            <ListItem 
                                onClick={() => {this.goTo("/logs");}}
                                button 
                                className={classNames(classes.list)}>

                                <ListItemIcon>
                                    <Icon className={classes.icon}>description</Icon>
                                </ListItemIcon>
                                <ListItemText classes={{primary: classes.label}} primary="Logs" />
                            </ListItem>
                            }
                            <ListItem 
                                onClick={this.handleLogoutClick.bind(this)}
                                button 
                                className={classNames(classes.list)}>
                                <ListItemIcon>
                                    <Icon className={classes.icon}>exit_to_app</Icon>
                                </ListItemIcon>
                                <ListItemText classes={{primary: classes.label}} primary="Logout" />
                            </ListItem>
                        </List>
                    </div>
 
                    <Dialog
                        fullScreen={false}
                        classes={{
                            paper: classes.dialogPaper
                        }}
                        open={this.state.aboutDialogOpened}
                        onClose={this.handleCloseDialog}
                        aria-labelledby="responsive-dialog-title"
                        >
                        <DialogTitle id="responsive-dialog-title">{globals.ABOUT_TITLE}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                            {globals.ABOUT_CONTENT} <br />
                            Version : {globals.APP_VERSION}<br />
                            Code : {helper.getCodeVersion()}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseDialog} className={classes.buttons} variant="contained">Close</Button>
                        </DialogActions>
                    </Dialog>
                    
                    <Dialog
                        fullScreen={false}
                        classes={{
                            paper: classes.dialogPaper
                        }}
                        open={this.state.upToDateDialogOpened}
                        onClose={this.handleCloseDialog}
                        aria-labelledby="responsive-dialog-title"
                        >
                        <DialogContent>
                            <DialogContentText>App is already up-to-date.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseDialog} className={classes.buttons} variant="contained">Close</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        fullScreen={false}
                        classes={{
                            paper: classes.miniDialogPaper
                        }}
                        open={this.state.changePasswordLoadingDialogOpened}
                        onClose={this.handleCloseDialog}
                        aria-labelledby="responsive-dialog-title"
                        >
                        <DialogTitle id="responsive-dialog-title">Change Password</DialogTitle>
                        <DialogContent>
                            <MiniLoadingSpinner />
                        </DialogContent>
                    </Dialog>

                    <Dialog
                        fullScreen={false}
                        classes={{
                            paper: classes.miniDialogPaper
                        }}
                        open={this.state.changePasswordDialogOpened}
                        onClose={this.handleCloseDialog}
                        aria-labelledby="responsive-dialog-title"
                        >
                        <DialogTitle id="responsive-dialog-title">Change Password</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                { this.state.changePasswordMessage }
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseDialog} className={classes.buttons} variant="contained">Close</Button>
                        </DialogActions>
                    </Dialog>

                    <div className={classes.toolbar} />
                    <div className={classes.toolbar} />
                </main>
                <SimpleBottomNavigation />
            </div>
        );
    }
}

SettingsClass.propTypes = {
    classes: PropTypes.object.isRequired,
};

const Settings = connect(mapStateToProps)(
    withCookies(withMobileDialog() (
        withStyles(styles)(SettingsClass))
    )
);

export default Settings;
