import React, { useState } from 'react'

import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
import classNames from 'classnames'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import ApplicationBar from 'components/common/workflow/ApplicationBar'
// import SimpleBottomNavigation from 'components/common/iv-infusion/SimpleBottomNavigation'

import ApptDetailsHeader from './ApptDetailsHeader'
import TotalOrders from './TotalOrders'
import ApptDetails from './ApptDetails'
import OrderFilter from './OrderFilter'
import OrderList from './OrderList'

import ImmutableJS from "immutable"
import uuid from "uuid"

import { addExistingPatientInAppointment } from "js/actions/index"

const mapStateToProps = state => {
    return {
        drawData: state.drawData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addExistingPatientInAppointment: (hashed_id, payload) => dispatch(addExistingPatientInAppointment(hashed_id, payload)),
    }
}

const AppointmentDetailsFunctionClass = (props) => {
    const { classes, addExistingPatientInAppointment } = props

    const [searchText, setSearchText] = useState("")
    const [sort, setSort] = useState("asc")
    const [newlyAddedPatients, setNewlyAddedPatients] = useState([])

    const addPatientToList = (patient_id) => {
        setNewlyAddedPatients([...newlyAddedPatients, patient_id])
    }

    // const removePatientFromList = (patient_id) => {
    //     const immuPatients = ImmutableJS.fromJS(newlyAddedPatients).toJS()
    //     const index = immuPatients.indexOf(patient_id)
    //     immuPatients.splice(index, 1)
    //     setNewlyAddedPatients(immuPatients)
    // }

    return (
        <>
            <div className={classes.root}>
                <main className={classes.content}>
                    <div className={classNames(classes.mainContainer)}>
                        <ApplicationBar />

                        <ApptDetailsHeader />
                        <TotalOrders />
                        <ApptDetails />

                        <OrderFilter
                            searchText={searchText}
                            setSearchText={setSearchText}
                            sort={sort}
                            setSort={setSort}
                            newlyAddedPatients={newlyAddedPatients}
                            onAddToList={(hashed_id, patient_id) => {
                                addExistingPatientInAppointment(hashed_id, patient_id).then(() => {
                                    // must have: after submit, when document is not yet reloaded
                                    // we need to state that patient is newly added
                                    addPatientToList(patient_id)
                                })
                            }}
                        // onRemoveFromList={(patient_id) => {
                        //     removePatientFromList(patient_id)
                        // }}
                        />

                        <OrderList
                            key={uuid.v4()}
                            sort={sort}
                            searchText={searchText}
                        // newlyAddedPatients={patientsListDoesntExistsInAppointment.length > 0 ? addExistingPatientsData.data.filter(patient_id => {
                        //     return newlyAddedPatients.includes(patient_id.id)
                        // }) : []}
                        />
                    </div>
                </main>
            </div>
        </>
    )
}

const AppointmentDetails = withCookies(
    connect(mapStateToProps, mapDispatchToProps)(
        withStyles(styles, { withTheme: true })(AppointmentDetailsFunctionClass)
    )
)
export default AppointmentDetails
