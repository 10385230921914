import React, { useRef, useState } from 'react'
import classNames from 'classnames'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent
} from '@material-ui/core'

import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import CheckIcon from '@material-ui/icons/Check'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import {
    FormControl,
    TextField,
    Select,
    MenuItem
} from '@material-ui/core'

import uuid from "uuid"
import * as globals from 'components/common/GlobalConstants'

export const PatientInformationVerificationList = (props) => {
    const {
        isOrderRemaining,
        classes,
        data,
        newArray,
        setData,
        onChangeData
    } = props

    return (
        <>
            {
                data.map((info, index) => {
                    return (
                        <div
                            key={uuid.v4()}
                            className={classes.verifyPatientInfoContainer}
                            onClick={() => {
                                if (isOrderRemaining) {
                                    newArray[index].verified = !info.verified
                                    setData(newArray)
                                    onChangeData(newArray)
                                }
                            }}
                        >
                            <Grid
                                container
                                justify="flex-start"
                                alignItems="center"
                            >
                                <div className={classes.verifyPatientInfoCheckboxContainer}>
                                    {info.verified
                                        ?
                                        <Avatar className={classes.verifyPatientInfoCheckedIcon}><CheckIcon className={classes.verifyPatientCheckIcon} /></Avatar>
                                        :
                                        <Avatar className={classes.verifyPatientInfoUnCheckedIcon}></Avatar>
                                    }
                                </div>
                                <div>
                                    <Typography className={classNames(classes.verifyPatientInfoLabel, info.verified ? "verified" : "")}>{info.label}</Typography>
                                    <Typography className={classNames(classes.verifyPatientInfoLabelValue, info.verified ? "verified" : "")}>{info.value}</Typography>
                                </div>
                            </Grid>
                        </div>
                    )
                })
            }
        </>
    )
}

export const UnableToPerformOrderDialog = (props) => {
    const { classes, open, onCancel, onConfirm } = props
    const [reason, setReason] = useState("")
    const otherReasonTextField = useRef(null)

    return (
        <Dialog
            id="unable-to-perform-dialog"
            fullScreen={false}
            open={open}
            maxWidth={"md"}
            classes={{
                paperScrollPaper: classes.dialogContainer
            }}
        >
            <DialogContent className={classes.dialogContent}>
                <div>
                    <Grid
                        container
                        alignItems="center"
                    >
                        <Avatar className={classes.unableToPerformAvatar}>
                            <PriorityHighIcon className={classes.unableToPerformIcon} />
                        </Avatar>
                        <Typography className={classes.unableToPerformTitle}>Unable to Perform Order</Typography>
                    </Grid>
                    <Typography className={classes.unableToPerformMessage}>Please select the reason why you are unable to perform this order.</Typography>
                    <FormControl variant="outlined" className={classes.unableToPerformReasonFormControl}>
                        <Select
                            id="select"
                            displayEmpty
                            onChange={(event) => {
                                setReason(event.target.value)
                            }}
                            className={classes.unableToPerformReasonSelect}
                            disableUnderline
                            value={reason ? reason : ""}
                        >
                            <MenuItem value="">
                                <Typography className={classes.unableToPerformReasonMenuItemPlaceholder}>Select reason</Typography>
                            </MenuItem>
                            {globals.v2.UNABLE_TO_PERFORM_REASONS.map((reason) => {
                                return <MenuItem key={uuid.v4()} value={reason.key}>{reason.value}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    {reason === "refused_other_reason" &&
                        <TextField
                            inputRef={otherReasonTextField}
                            // hidden={!this.state.isPatientRefusesUnableToDraw}
                            id="patient-unable-to-draw-reason"
                            label="Please specify reason here..."
                            multiline
                            rows="4"
                            margin="normal"
                            // defaultValue={this.selectedPatientDraw.status_notes_p}
                            fullWidth
                        />
                    }
                </div>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button onClick={onCancel} className={classNames(classes.btnClose)} color="primary">Cancel</Button>
                <Button
                    onClick={() => {
                        console.log("otherReasonTextField")
                        console.log(otherReasonTextField)
                        onConfirm({
                            status: reason,
                            notes: reason === "refused_other_reason" ? otherReasonTextField.current.value : ""
                        })
                    }}
                    className={classNames(classes.btnConfirm)}
                    color="primary"
                    disabled={reason === ""}
                >Confirm</Button>
            </DialogActions>
        </Dialog>
    )
}