import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

// import classNames from 'classnames';
// import ApplicationBar from 'components/common/mobile/ApplicationBar';
// import SimpleBottomNavigation from 'components/common/mobile/SimpleBottomNavigation';
import { connect } from "react-redux";
import { withCookies } from 'react-cookie';
// import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Login from "components/Login";
import Logout from "components/Main/mobile/Logout";
import * as helper from 'components/common/Helper.js'
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// // import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// import ListItemText from '@material-ui/core/ListItemText';
// import TextField from '@material-ui/core/TextField';
import styles from "./styles.js";
// import { InputBase } from '@material-ui/core';
// import Paper from '@material-ui/core/Paper';

import PinKeyPad from '../PinKeyPad';
//import  Encryption from 'components/common/Encryption';
//import ImmutableJS from "immutable";
import { setScreenLockStatus, logEvent } from "js/actions/index";

import ConfirmDialog from 'components/common/mobile/ConfirmDialog';

const mapDispatchToProps = dispatch => {
    return {
        //setupNewDB: credentials => dispatch(setupNewDB(credentials)),
        setScreenLockStatus: status => dispatch(setScreenLockStatus(status)),
        logEvent: event => dispatch(logEvent(event)),
    }
}

const mapStateToProps = state => {
    return {
        db: state.db,
        onlineStatus: state.onlineStatus,
        drawRequests: state.drawRequests,
        syncStatus: state.syncStatus
    }
}

class UnlockScreenClass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            pincode: "",
            keyPadMessage: "",
            keyPadInstruction: "Enter your PIN code to unlock",
            setDeleteNotesDialog: false,
            confirmDialogMessage: "",
            confirmDialogTitle: "",
            module: "unlock-screen"
        }

        this.isLogout = false

    }

    setDeleteNotesDialogHandler = (actionHandler, postActionHandler, title, message, actionText, chainedFunction) => {
        // if(chainedFunction === null) chainedFunction = ()=>{};
        this.setState(
            {
                ...this.state,
                actionHandler: actionHandler,
                postActionHandler: postActionHandler,
                confirmDialogMessage: message,
                confirmDialogTitle: title,
                confirmDialogActionText: actionText,
            },
            chainedFunction
        );
    }

    setDeleteNoteDialogVisible = (value) => {
        //console.log("setDeleteNoteDialogVisible " + value);
        this.setState({ ...this.state, setDeleteNotesDialog: value });
    }

    onChangePinCode = (value) => {
        this.setState({
            ...this.state,
            pincode: value,
            keyPadMessage: ""
        });
    }

    onCompletePinCode = (value) => {
        var message = "";
        //const { cookies } = this.props;

        var cred = helper.getCredentials()

        var pincode = cred.pincode !== undefined ? cred.pincode : "";

        if (value !== pincode) {
            message = "Wrong PIN code!"

            this.props.logEvent({
                user: cred.username,
                module: this.state.module,
                action: "wrong_pin",
                description: "Entered wrong PIN code"
            });
        } else {
            //this.props.db.sync()
            message = "Correct PIN code!";

            this.props.logEvent({
                user: cred.username,
                module: this.state.module,
                action: "confirm",
                description: "Entered correct PIN code"
            });

            //set Redux isScreenLocked to false

            //this.onRefresh();
            //this.props.onRefresh()
            ////console.log( "this.props")
            ////console.log( this.props)
            // this.setState({ state: this.state });
            //this.setState({...this.state})
            //console.log(message)
            //this.goTo("/sites")
            this.props.setScreenLockStatus(false)
            this.goTo("/appointments/today")
            // setTimeout(()=>{
            //     this.props.setScreenLockStatus(false)
            //     this.goTo("/appointments/today")
            // },300)

        }

        //console.log(message)
        this.setState({
            ...this.state,
            pincode: value,
            keyPadMessage: message
        });
        //console.log("Complete : " + value)
        //console.log("Complete :" + message)
    }

    goTo = (url) => {
        this.props.history.push(url)
    }

    logout() {
        //this.props.setScreenLockStatus(false)
        // this.goTo("/logout")
        console.log("Going to logout")
        var cred = helper.getCredentials();

        this.props.logEvent({
            user: cred.username,
            module: this.state.module,
            action: "logout",
            description: "Execute log out"
        });
        this.isLogout = true
        //    this.setState({...this.state, isLogout : true ,confirmDialogMessage: "falasdfasdse"},()=>{
        //     console.log("Logout value : " + this.state.isLogout)
        //    // this.isLogout = true
        //    });
    }

    render() {
        const { classes } = this.props
        const { pincode } = this.state
        //console.log(this.state)
        //console.log("pincode")
        //console.log(pincode)
        var cred = helper.getCredentials()
        //console.log("HELLO render HERE [" + this.isLogout+"]")
        if (cred === "") {
            console.log("Logout in unlock screen.")
            return (
                <div>
                    <BrowserRouter>
                        <Switch>
                            <Route path="/login" render={(props) => <Login {...props} />} />
                            <Redirect to="/login" />
                        </Switch>
                    </BrowserRouter>
                </div>
            )
        }
        if (this.isLogout === true) {
            //console.log("if(this.isLogout === true)")
            return (
                <div>
                    <BrowserRouter>
                        <Switch>
                            <Route path="/logout/:reason" render={(props) => <Logout {...props} />} />
                            <Redirect to="/logout/user" />
                        </Switch>
                    </BrowserRouter>
                </div>
            )
        }
        return (
            <div className={classes.root}>
                <PinKeyPad
                    digitCount={4}
                    value={pincode}
                    onChange={this.onChangePinCode}
                    onComplete={this.onCompletePinCode}
                    keyPadMessage={this.state.keyPadMessage}
                    keyPadInstruction={this.state.keyPadInstruction}
                />
             
                <Typography
                    // onClick={this.logout} 
                    onClick={() => {
                        this.setDeleteNotesDialogHandler(
                            () => this.logout(),
                            () => {
                                this.setDeleteNoteDialogVisible(false)
                                // this.setNotesDialogVisibility(false,EDIT)
                            }
                            ,
                            "Logout Confirmation",
                            "Are you sure you want to logout?",
                            "Logout",
                            () => this.setDeleteNoteDialogVisible(true),
                        );

                    }}
                    className={classes.logout}>Logout</Typography>




                <br />
                <br />
                <ConfirmDialog
                    visible={this.state.setDeleteNotesDialog}
                    title={this.state.confirmDialogTitle}
                    message={this.state.confirmDialogMessage}
                    actionText={this.state.confirmDialogActionText}
                    actionHandler={this.state.actionHandler}
                    postActionHandler={this.state.postActionHandler}
                    onClose={() => {
                        //console.log("ConfirmDialog On close")
                        setTimeout(() => {
                            this.setDeleteNoteDialogVisible(false);
                        }, 100);

                    }}
                />
            </div>
        );
    }
}

UnlockScreenClass.propTypes = {
    classes: PropTypes.object.isRequired,
};

const UnlockScreen = withCookies(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UnlockScreenClass)));
export default UnlockScreen;