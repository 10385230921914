import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import CheckIcon from '@material-ui/icons/Check'

// import Grid from '@material-ui/core/Grid'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent
} from '@material-ui/core'

import * as globals from 'components/common/GlobalConstants'

const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const styles = theme => ({
    dialogContainer: {
        margin: "20px",
        borderRadius: "10px"
    },
    dialogContent: {
        padding: "20px !important"
    },
    dialogActions: {
        padding: "20px !important",
        margin: "0px",
        justifyContent: "center !important"
    },
    btnClose: {
        textTransform: 'capitalize',
        padding: "8px 20px",
        fontSize: "16px !important",
        backgroundColor: globals.v2.COLORS.GRAY,
        color: "black",
        fontWeight: 500,
        "&:focus": { backgroundColor: globals.v2.COLORS.GRAY + " !important" },
        "&:active": { backgroundColor: globals.v2.COLORS.GRAY + " !important" },
        "&:hover": { backgroundColor: globals.v2.COLORS.GRAY + " !important" },
        "&:disabled": { backgroundColor: globals.v2.COLORS.GRAY_DISABLED + " !important" },
    },
    btnConfirm: {
        textTransform: 'capitalize',
        padding: "8px 20px",
        fontSize: "16px !important",
        backgroundColor: globals.v2.COLORS.YELLOW,
        color: "black",
        fontWeight: 500,
        "&:focus": { backgroundColor: globals.v2.COLORS.YELLOW + " !important" },
        "&:active": { backgroundColor: globals.v2.COLORS.YELLOW + " !important" },
        "&:hover": { backgroundColor: globals.v2.COLORS.YELLOW + " !important" },
        "&:disabled": { backgroundColor: globals.v2.COLORS.YELLOW_DISABLED + " !important" },
    },
    confirmCompletionAvatar: {
        backgroundColor: globals.v2.COLORS.BLUE,
        width: "40px",
        height: "40px",
        marginRight: "10px",
        "& svg": {
            fill: "white"
        }
    },
    confirmCompletionIcon: {
        fontSize: "28px"
    },
    confirmCompletionMessage: {
        marginLeft: "50px",
        fontSize: "14px !important"
    },
    confirmTitle: {
        fontSize: "16px !important",
        fontWeight: 600
    },
})

const ConfirmDialogFunctionClass = (props) => {
    const {
        classes,
        open = false,
        onOk,
        message
    } = props

    return (
        <Dialog
            id="confirm-dialog"
            fullScreen={false}
            open={open}
            maxWidth={"md"}
            classes={{
                paperScrollPaper: classes.dialogContainer
            }}
        >
            <DialogContent className={classes.dialogContent}>
                <Grid
                    container
                    alignItems="center"
                >
                    <Avatar className={classes.confirmCompletionAvatar}>
                        <CheckIcon className={classes.confirmCompletionIcon} />
                    </Avatar>
                    <Typography className={classes.confirmTitle}>Request Sent.</Typography>
                </Grid>
                <Typography className={classes.confirmCompletionMessage}>{message}</Typography>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button onClick={onOk} className={classNames(classes.btnConfirm)} color="primary">Ok</Button>
            </DialogActions>
        </Dialog>
    )
}

const ConfirmDialog = withRouter(
    withCookies(
        connect(mapStateToProps)(
            withStyles(styles, { withTheme: true })(ConfirmDialogFunctionClass)
        )
    )
)
export default ConfirmDialog
