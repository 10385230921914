import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing.unit * 2,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing.unit,
      top: theme.spacing.unit,
      color: theme.palette.grey[500],
    },
  }))(props => {
    const { children, classes, onClose } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});

const styles = theme => ({
    // dialogPaper: {
    //     minWidth: "90%",
    //     margin: 10, // 18
    // },
    buttons: {
        // margin: theme.spacing.unit * 2,
        marginLeft: 0,
        // backgroundColor: "#68CBEF",
        textTransform: 'none',
        borderRadius: 0,
    },
});

class CommonDialogClass extends Component {
    componentWillMount() {
        const { title, content, buttons, eventHandler, visible, onClose } = this.props.data;
    
        this.setState({...this.state, 
                        visible : visible, 
                        title: title, 
                        content : content,
                        buttons : buttons, 
                        eventHandler : eventHandler,
                        onClose : onClose
                    });
    }

    componentDidMount() {

    }
    
    internalEventHandler = (value) => {
        this.state.eventHandler(value);
        this.props.data.visible = false;
        this.setState({...this.state});
    };

    render() {   
        const { classes } = this.props;
        
        return(
            <Dialog
                open={this.props.data.visible}
                fullScreen={false}
                classes={{
                    paper: classes.dialogPaper
                }}
                // onClose={()=>this.internalEventHandler("")}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" onClose={()=>this.internalEventHandler("")}>{this.state.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <br/>
                        {this.state.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {this.state.buttons.map(button => 
                        <Button 
                            key={button} 
                            onClick={() => this.internalEventHandler(button)} 
                            variant="contained"
                            // color={button === "Cancel"? "default": "secondary"} // need to have improved version here
                            color="secondary" // cancel is already at the title 
                            className={classes.buttons}
                        >{button}</Button>
                    )}
                </DialogActions> 
            </Dialog>

        );
    }
}

CommonDialogClass.propTypes = {
    classes: PropTypes.object.isRequired,
};

const CommonDialog = CommonDialogClass;
export default withStyles(styles)(CommonDialog);