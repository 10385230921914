import * as globals from 'components/common/GlobalConstants'

const styles = theme => ({
    // general
    root: {
    },
    content: {
        flexGrow: 1,
    },
    mainContainer: {
        padding: "15px 20px",
        marginBottom: "60px"
    },
    // appointment details header
    apptDetailsHeaderContainer: {
        minHeight: "60px",
        borderBottom: "1px solid #ececec",
        marginLeft: "-20px",
        marginRight: "-20px"
    },
    closeApptDetailsButton: {
        paddingLeft: "20px !important",
    },
    currentStepLabelPlaceholder: {
        width: "77px"
    },
    // total orders component
    totalOrdersContainer: {
        backgroundColor: "#ecfff8",
        margin: "20px 0px 10px 0px",
        padding: "5px 0px 5px 15px",
        borderRadius: "5px",
    },
    orderPercentage: {
        fontSize: "18px !important",
        color: "#00f4a4 !important",
        backgroundColor: "white",
        fontWeight: 600,
        borderTopLeftRadius: "15px",
        borderBottomLeftRadius: "15px",
        padding: "5px 12px 5px 30px",
    },
    totalOrdersLabel: {
        fontSize: "14px !important"
    },
    // appointment details container
    apptDetailsContainer: {
        borderRadius: "4px",
        // border: "1px solid #fbfbfb",
        // boxShadow: "2px 2px #f0f0f0",
        marginBottom: "10px",
        backgroundColor: "#f8f8f8",
        padding: "15px",
        // padding: "15px 20px",
        // paddingRight: "10px",
    },
    apptDateTime: {
        fontSize: "12px !important",
        fontWeight: 600,
        display: "inline-block",
        marginTop: "5px"
    },
    orderDetailsLabel: {
        fontSize: "12px !important",
        fontWeight: 500
    },
    orderAddress: {
        fontSize: "12px !important",
    },
    apptDetailsButton: {
        display: "block",
        width: "100%",
        backgroundColor: "white",
        borderRadius: "5px",
        fontSize: "14px",
        padding: "8px 16px",
        boxShadow: "0px 1px 3px #0000000D",
        "&:focus": {
            backgroundColor: "white",
        }
    },
    apptDetailsButtonIcon: {
        width: 24,
        height: 24,
        marginRight: "10px",
        fill: globals.v2.COLORS.BLUE
    },
    // orders, sort/filter section
    filterContainer: {
        display: "flex",
        marginTop: "30px",
        marginBottom: "20px",
    },

    filterField: {
        width: 500,
        fontSize: "14px",
        height: "30px",
        flexGrow: 1,
        backgroundColor: "white",
        border: "1px solid #f3f3f3",
        borderRadius: "22px",
        padding: "6px 20px 5px",
        marginRight: "5px",
    },

    searchContainer: {
        width: "100%",
        display: "flex",
    },
    sortButton: {
        height: "45px",
        minWidth: "75px",
        backgroundColor: "white",
        border: "1px solid #f3f3f3",
        borderRadius: "22px",
        paddingLeft: "15px",
        marginRight: "5px"
    },
    arrowSortIcon: {
        marginRight: "-5px"
    },
    addButton: {
        height: "45px",
        width: "45px",
        // minWidth: "86px",
        backgroundColor: "white",
        border: "1px solid #f3f3f3",
        borderRadius: "50%",
    },
    addButtonIcon: {
        width: 28,
        height: 28,
        fill: globals.v2.COLORS.BLUE
    },
    // orders list
    ordersContainer: {
    },
    orderListItem: {
        padding: "19px",
        borderRadius: "4px",
        boxShadow: "0px 1px 3px #0000001A",
        marginBottom: "10px",
    },

    orderNameRemaining: {
        fontSize: "14px !important",
        fontWeight: 600,
        color: "black"
    },
    orderTestRemaining: {
        fontSize: "12px !important",
        color: "black",
    },
    orderNameCompleted: {
        fontSize: "14px !important",
        fontWeight: 600,
        color: "darkgray !important",
    },
    orderTestCompleted: {
        fontSize: "12px !important",
        color: "darkgray !important",
    },
    orderNameRefused: {
        fontSize: "14px !important",
        fontWeight: 600,
        color: "darkgray !important",
        textDecoration: "line-through"
    },
    orderTestRefused: {
        fontSize: "12px !important",
        color: "darkgray !important",
        textDecoration: "line-through"
    },
    orderStartButton: {
        height: "35px",
        padding: "5px 20px",
        borderRadius: "15px",
        fontSize: "12px !important",
        backgroundColor: "#ffdc51",
        color: "black",
        fontWeight: 500,
        textTransform: 'capitalize',
        "&:focus": {
            backgroundColor: "#ffdc51",
        },
        "& span": {

        }
    },
    orderCompletedButton: {
        height: "35px",
        padding: "5px 20px",
        borderRadius: "15px",
        fontSize: "12px !important",
        backgroundColor: "#69F1B21A",
        fontWeight: 500,
        textTransform: 'capitalize',
        "&:focus": {
            backgroundColor: "#69F1B21A",
        },
        "& span": {
            color: "#69F1B2 !important",
        }
    },
    orderRefusedButton: {
        height: "35px",
        padding: "5px 20px",
        borderRadius: "15px",
        fontSize: "12px !important",
        backgroundColor: "#F24A7E1A",
        fontWeight: 500,
        textTransform: 'capitalize',
        "&:focus": {
            backgroundColor: "#F24A7E1A",
        },
        "& span": {
            color: "#F24A7E !important"
        }
    },

    patientDetailsContainer: {
        margin: "10px",
        padding: "10px"
    },

    addDataOptionsDialogContainer: {
        backgroundColor: "transparent",
        boxShadow: "none",
        position: "absolute",
        bottom: "80px",
        margin: "0px",
        width: "252px"
    },

    addDataOptionsDialogContent: {
        padding: "0px !important",
        textAlign: "center",
    },

    btnCaptureDataLabel: {
        color: "#00BDFF !important",
    },

    btnCaptureData: {
        fontSize: "14px",
        textTransform: "none",
        color: "black !important",
        backgroundColor: "white !important",
        border: "1px solid #E6E6E6",
        padding: "8px 20px",
        borderRadius: "4px",
        marginBottom: "10px",
        width: "252px",
        "&:active": {
            backgroundColor: "white !important",
        }
    },

    btnCloseAddData: {
        fontSize: "14px",
        textTransform: "none",
        color: "black !important",
        backgroundColor: "#F7F7F7 !important",
        border: "1px solid #F7F7F7",
        padding: "8px 20px",
        borderRadius: "4px",
        marginBottom: "10px",
        width: "252px",
        boxShadow: "2px 2px #f0f0f0",
        "&:active": {
            backgroundColor: "#F7F7F7 !important",
        }
    },

    cancelDialogBox: {
        minWidth: 300,
        margin: "20px",
        borderRadius: "10px"
    },

    qrCodeBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #efefef",
        padding: "20px",
    },

    dialogText: {
        textAlign: "center",
        paddingTop: "20px"
    },

    buttonPosition: {
        padding: "0px 0px 20px 0px !important",
        textAlign: "center",
    },

    addBtn: {
        fontSize: "14px",
        textTransform: "none",
        backgroundColor: "#ebf5fa !important",
        border: "1px solid #E6E6E6",
        padding: "8px 0px 8px 0px",
        borderRadius: "5px",
        marginBottom: "10px",
        width: "200px",
        "&:active": {
            backgroundColor: "white !important",
        }
    },

    addBtnLabel: {
        color: "#00BDFF !important",
    },

    cancelButton: {
        textTransform: 'capitalize',
        padding: "10px 20px",
        fontSize: "14px !important",
        backgroundColor: "#f7f7f7",
        color: "#333333 !important",
        fontWeight: 500,
        "&:focus": { backgroundColor: "#f7f7f7 !important" },
        "&:active": { backgroundColor: "#f7f7f7 !important" },
        "&:hover": { backgroundColor: "#f7f7f7 !important" },
        "&:disabled": { backgroundColor: "#f7f7f7 !important" },
    },

    title: {
        textAlign: "center",
        fontWeight: "600",
        fontSize: "16px"
    },
    dialogTitleContainer: {
        borderBottom: "none"
    },
    dialogActions: {
        padding: "16px 10px !important",
        margin: "0px",
        justifyContent: "space-between",
        borderTop: "1px solid #0000001A"
    },
    btnClose: {
        margin: "0px",
        textTransform: 'capitalize',
        padding: "8px 20px",
        fontSize: "14px !important",
        backgroundColor: globals.v2.COLORS.GRAY,
        color: "black",
        fontWeight: 500,
        marginRight: "0px",
        "&:focus": { backgroundColor: globals.v2.COLORS.GRAY + " !important" },
        "&:active": { backgroundColor: globals.v2.COLORS.GRAY + " !important" },
        "&:hover": { backgroundColor: globals.v2.COLORS.GRAY + " !important" },
        "&:disabled": { backgroundColor: globals.v2.COLORS.GRAY_DISABLED + " !important" },
    },
    btnSave: {
        textTransform: 'capitalize',
        padding: "8px 20px",
        fontSize: "14px !important",
        backgroundColor: globals.v2.COLORS.YELLOW,
        color: "black",
        fontWeight: 500,
        marginLeft: "10px",
        marginRight: "0px",
        "&:focus": { backgroundColor: globals.v2.COLORS.YELLOW + " !important" },
        "&:active": { backgroundColor: globals.v2.COLORS.YELLOW + " !important" },
        "&:hover": { backgroundColor: globals.v2.COLORS.YELLOW + " !important" },
        "&:disabled": { backgroundColor: globals.v2.COLORS.YELLOW_DISABLED + " !important" },
    },

    searchPatientField: {
        width: 500,
        maxWidth: "100%",
        padding: "6px 20px 5px",
        border: "1px solid #E6E6E6",
        borderRadius: "22px",
        outline: "none",
        flexGrow: 1,
        marginRight: "5px",
    },
    searchDialogContent: {
        padding: "16px",
        paddingTop: "0px",
        borderRadius: "10px",
    },
    searchResultContainer: {
        marginTop: "20px",
        overflow: "auto",
        height: "50vh",
    },
    searchResultItems: {
        padding: "19px",
        boxShadow: "0px 1px 3px #0000001a",
        borderRadius: "4px",
        marginBottom: "10px"
    },
    searchItemName: {
        display: "block",
        fontSize: "14px",
        fontWeight: "600"
    },
    searchItemDoB: {
        display: "block",
        fontSize: "12px",
        fontWeight: "400"
    },
    searchItemBtnAddResult: {
        minWidth: "85px",
        textTransform: 'capitalize',
        padding: "6px 16px",
        fontSize: "12px !important",
        fontWeight: 500,
        backgroundColor: globals.v2.COLORS.YELLOW,
        color: "black",
        "&:focus": { backgroundColor: globals.v2.COLORS.YELLOW + " !important" },
        "&:active": { backgroundColor: globals.v2.COLORS.YELLOW + " !important" },
        "&:hover": { backgroundColor: globals.v2.COLORS.YELLOW + " !important" },
        "&:disabled": { backgroundColor: globals.v2.COLORS.YELLOW_DISABLED + " !important" },
        borderRadius: "22px",
    },
    searchItemBtnRemoveResult: {
        minWidth: "85px",
        textTransform: 'capitalize',
        padding: "6px 16px",
        fontSize: "12px !important",
        fontWeight: 500,
        backgroundColor: "none",
        "& span": {
            color: globals.v2.COLORS.RED + " !important",
        }
    },
    searchItemBtnAddedResult: {
        minWidth: "85px",
        textTransform: 'capitalize',
        padding: "6px 16px",
        fontSize: "12px !important",
        fontWeight: 500,
        backgroundColor: "none",
        "& span": {
            color: globals.v2.COLORS.GREEN + " !important",
        }
    },
    warningText: {
        fontSize: "14px !important",
        color: globals.v2.COLORS.RED + " !important"
    }
})

export default styles