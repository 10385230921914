import React from 'react';
import classNames from 'classnames';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
//import Icon from '@material-ui/core/Icon';

import ListItemIcon from '@material-ui/core/ListItemIcon';

import moment from 'moment-timezone';
import { Typography } from '@material-ui/core';
import * as globals from 'components/common/GlobalConstants';

import { ReactComponent as PrintSVG } from 'components/common/mobile/CustomOfflineSVG/print.svg';
import { ReactComponent as PrintDisabledSVG } from 'components/common/mobile/CustomOfflineSVG/print_disabled.svg';

const goTo = (props, futureOrderDate, siteOrder) => {
  props.history.push("/future_order_site_details/" + futureOrderDate+ "/" + siteOrder + "/list");
  // console.log("/future_order_site_details/" + futureOrderDate+ "/" + siteOrder + "/list");
}

const FutureOrdersList = (props) => {
  
  const {classes, futureOrder} = props;
  // console.log("FutureOrderDrawList: futureOder");
  // console.log(futureOrder);
  // console.log(futureOrder)

  const patientList = futureOrder.draw_list.map(drawItem => {
      //console.log("drawItem");
      // console.log(drawItem.patient_list[0].patient_draw);
      //var DrawListDetails = "";
      // console.log(drawItem)
      var labels = drawItem.patient_list[0].patient_draw[0].labels;
      var printLabelsEnabled = false;
      // var gender ="";
      var address1 = "";
      // var address2 = ""; // was removed 
      var state_zipcode = "";
      var secondaryText = "";
      var city = "";
      var age = 0;

      if(labels && labels.length > 0) {
        printLabelsEnabled = true;
      }
      if(drawItem.site_type === globals.INDIVIDUAL) {
        var patient = drawItem.patient_list[0].patient;
        address1 = patient.address;
        // address2 = patient.address_line_2;
        city = patient.city !== undefined && patient.city !== "" ? patient.city + " ": "";
        state_zipcode = city + [patient.state, patient.zipcode].filter(function(el) { return el; }).join( ", " );
        // state_zipcode =  [patient.city, patient.state, patient.zipcode].join( ", " );
        //  gender = drawItem.patient_list[0].patient.gender === "male" ? "Male" : "Female";
         
        age = moment().diff(drawItem.patient_list[0].patient.birth_date, 'years');
        
        secondaryText = "Age " + age;

        if( drawItem.patient_list[0].urgent === true) {
          secondaryText = secondaryText + ", Urgent"
        }

        if( drawItem.patient_list[0].fasting === true) {
          secondaryText = secondaryText + ", Fasting"
        }

        // DrawListDetails = drawItem.patient_list[0].patient_draw.map(draw => {         
        //   return(
        //     <ListItem className={classes.drawItem} key={Math.random()}>
        //       <ListItemIcon>
        //       <img alt="Draw Request" className={classes.imgIcon} src="/images/draw_request_outline.svg" />
        //       </ListItemIcon>
        //       <ListItemText style={{"margin": 0, "padding": 0}} primary={draw.name} />
        //     </ListItem>
        //       );
        // });

      } else {
        address1 = drawItem.site.address;
        // address2 = drawItem.site.address_line_2;
        state_zipcode = [drawItem.site.city, drawItem.site.state, drawItem.site.zipcode].join(", ");
        secondaryText = "Tap to show details";
      }
      //console.log(drawItem)
       return(     
          <div className={classes.patientListItems} key={Math.random()}>           
            <ListItem key={address1}
              className={classes.futureOrderDrawList}
              onClick={(drawItem.site_type === globals.HOUSEHOLD || drawItem.site_type === globals.ORGANIZATION) 
                ? goTo.bind(null, props, futureOrder.collection_date, drawItem.site_order) 
                : null}
              >
              {/* <Icon className={classes.icon}>{drawItem.site_type === "individual" ? "person" : "home"}</Icon> */}
              <ListItemText
                classes={{
                  primary: classes.futureOrdersListPrimary,
                  secondary: classes.futureOrdersListSecondary
                }} 
                //primary={drawItem.site_order + ". " + toTitleCase(drawItem.site_type)}
                //secondary = {drawItem.patient_list[0].patient.address} 
                
                //onClick={onItemClick.bind(null, item)}
                //primary={moment(item,'YYYY-MM-DD').format( "MMMM D, YYYY")}
                //primary={drawItem.site_order + ". " + toTitleCase(drawItem.site_type)}
                
                primary = {moment(drawItem.collection_time, "hh:mm:ss").format('hh:mm a')} 
              // secondary =  {drawItem.patient_list[0].patient.address +"<br > test"} 
                secondary={ 
                    <span>
                      <Typography component="span" className={classNames(classes.futureOrdersListSecondary)}>{address1}</Typography>    
                      <Typography component="span" className={classNames(classes.futureOrdersListSecondary)}>{state_zipcode}</Typography>
                      <Typography component="span" className={classNames(classes.futureOrdersListSecondary, (drawItem.site_type !== globals.INDIVIDUAL) ? classes.tapToShowDetails : '')}>{secondaryText}</Typography>
                    </span>
                }
              />
              {(drawItem.site_type === globals.INDIVIDUAL) &&
                <ListItemIcon className={classes.printListCont}>
                  <button 
                    disabled={!printLabelsEnabled}
                    onClick={() => {props.onPrintLabel(labels)}} 
                    className={classes.printIconCont}> {/* button or a */}
                    {!printLabelsEnabled
                    ?
                    <PrintDisabledSVG className={classNames(classes.printIcon)}></PrintDisabledSVG>
                    :
                    <PrintSVG className={classNames(classes.printIcon)}></PrintSVG>
                    }
                    {/* <img alt="TeloPoint Print Icon" className={classNames(classes.printIcon)} src={(!printLabelsEnabled) ? "/images/print_disabled.svg" : "/images/print.svg"} /> */}
                  </button>
                </ListItemIcon>
              }
            </ListItem>
            {/* {DrawListDetails} */}
            {/* {"Age " + secondaryText} */}
          </div>
        );
   });
   
  return patientList;
}

export default FutureOrdersList;
