
import * as globals from 'components/common/GlobalConstants';
const styles = theme => ({
    grow: {
        flexGrow: 1,
    },    
    toolbarPlaceHolder: {
        minHeight: 43
    },
    overrideBlue: {
        background: globals.LIGHT_BLUE_GRADIENT_BG,
        color: "#01579B",
    },
    // titleIcon: {
    //     lineHeight: 1.25,
        // fontSize: 50,
    // },
    pageTitle: {
        fontSize: '24px !important',
        marginTop: '0px !important',
        // fontSize: '50px !important',
        fontWeight: 'bold',
        // margin: theme.spacing.unit * 2.5,
        // marginTop: theme.spacing.unit * 3.5,
        marginBottom: "5px",
    },
    channelName: {
        fontSize: '18px !important',
        marginTop: '0px !important',
        marginBottom: '0px !important',
        // fontSize: '50px !important',
        fontWeight: 'bold',
        // margin: theme.spacing.unit * 2.5,
        // marginTop: theme.spacing.unit * 3.5,
        // marginBottom: theme.spacing.unit * 4.5,
    },
    dateSection: {
        // marginLeft: theme.spacing.unit * 2.5,
        // marginRight: theme.spacing.unit * 2.5,
        // marginTop: theme.spacing.unit,
        display: 'flex', 
        justifyContent: 'flex-end', 
        alignItems: 'center',
        textShadow: '1px 1px 2px #fff',
    },
    datetimeLabel: {
        display: "inline",
        fontSize: "14px !important",
    },
    gpsIcon: {
        // marginRight: 10,
        verticalAlign: "middle",
        padding: "12px 8px",
        // paddingRight: 0,
        marginTop: -3,
        fontSize: 22,
        marginRight: -6,
    },
    online: {
        color: "#64DD17"
    },
    offline: {
        color: "#CFD8DC"
    },
    appBar: {
        minHeight: 50,
        height: 50,
        background: "#FFFFFF", //globals.LIGHT_BLUE_GRADIENT_BG,
        color: "#707070",
    },
    logo: {
        // height: 22,
        width: 102,
        marginTop: 8,
    },
    notification_icon: {
        width: 19,
        height: 19,
    },
    notification_icon_cont: {
        right: -8,
        padding: "12px 8px",
    },
    notification_icon_cont_not_selected: {
        color: '#7E7E7E !important',
        // paddingLeft: 5,
        // paddingRight: 0,
    },
    notification_icon_cont_selected: {
        color: '#68CBEF !important',
        // paddingLeft: 5,
        // paddingRight: 0,
    },
    notificationBadge: {
        top: 2,
        right: 2,
        // The border color match the background color.
        border: `2px solid ${
            '#fff'
            // theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
        }`,
    },
    sync_badge: {
        top: 12,
        right: -6,
    },
    sync_badge_content_green: {
        color: '#64DD17 !important',
        width: 20,
        height: 20,
        // border: `2px red ${'#fff'}`
    },
    sync_badge_content_red: {
        color: '#f31700 !important',
        width: 20,
        height: 20,
        // border: `2px red ${'#fff'}`
    },
    sync_icon_cont: {
        right: -8,
        padding: "12px 8px",
        marginRight: 8
        // paddingRight: 5,
    },
    sync_icon_synched: {
        color: '#64DD17'
    },
    sync_icon_up: {
        color: '#64DD17'
    },
    sync_icon_down: {
        color: '#64DD17'
    },
    sync_icon_unsynched: {
        color: '#ffaa60'
    },
    sync_icon_error: {
        color: '#f31700'
    },
    settings_icon: {
        width: 20,
        height: 20,
    },
    settings_icon_cont: {
        right: -8,
        padding: "12px 8px",
        // paddingLeft: 15,
        // paddingRight: 0,
    },
    toolbarGutters: {
        paddingRight: 15,
        paddingLeft: 15,
    },
    backButton: {
        paddingLeft: 0,
    },
    noHoverBG: {
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    alertIcon: {
        width: 18,
        height: 18
    },
    mainButtonWhiteBorderedSM: {
        fontSize: 16,
        padding: '0px 15px',
        backgroundColor: "white",
        textTransform: 'none',
        color: "#696969",
        borderRadius: '100px',
        boxShadow: "none",
        border: "2px solid #696969",
        float: "right"
    },
    yellowPaperBG: {
        background: "#FFFBE7",
        margin: "20px 0px",
        padding: 20
    },
    printButton: {
        '& svg': {            
            width: 16,
            height: 16,
            marginRight: 10
        },
        '& :disabled': {
            border: "2px solid #bdbdbd"
        }
    }
});

export default styles;