import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

// import ImmutableJS from "immutable";
// import EllipsisText from "react-ellipsis-text";
// import { AddAPhoto } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';

import ConfirmDialog from 'components/common/mobile/ConfirmDialog';
// import NotesIcon from '@material-ui/icons/Notes';
// import DeleteIcon from '@material-ui/icons/Delete';
// import AddIcon from '@material-ui/icons/Add';
// import $ from 'jquery';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Button from '@material-ui/core/Button';
import moment from 'moment-timezone';

// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
// import { InputBase } from '@material-ui/core';
// import Icon from '@material-ui/core/Icon';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// import TextField from '@material-ui/core/TextField';
import * as globals from 'components/common/GlobalConstants';
import styles from "./styles.js";

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';

//import {BrowserMultiFormatReader } from '@zxing/library'; // BrowserQRCodeReader

import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';

import { connect } from "react-redux";

//import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import ImagePreview from './ImagePreview';
//import Iframe from 'react-iframe'
import CustomWebcam2 from 'components/common/mobile/CustomWebcam2'
//import  CustomWebcam  from 'components/common/mobile/CustomWebcam';
//import $ from 'jquery';
import CameraPermissionError from 'components/common/mobile/CameraPermissionErrorComponent'

import {
    updatePatientDrawStatus,
    verifyPatientName,
    verifyPatientDoB,
    saveNote,
    deleteNote,
    saveDrawRequestDocument,
    deleteDrawRequestDocument,
    logEvent
} from "js/actions";

// import CustomBackdrop from 'components/common/mobile/CustomBackdrop';
// import MDSpinner from "react-md-spinner";

const EDIT = "edit"
const NEW = "new"
const PREVIEW = "preview"

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const mapDispatchToProps = dispatch => {
    return {
        updatePatientDrawStatus: drawItem => dispatch(updatePatientDrawStatus(drawItem)),
        verifyPatientName: verifiedPatientNameData => dispatch(verifyPatientName(verifiedPatientNameData)),
        verifyPatientDoB: verifiedPatientDoBData => dispatch(verifyPatientDoB(verifiedPatientDoBData)),
        saveNote: note => dispatch(saveNote(note)),
        deleteNote: note => dispatch(deleteNote(note)),
        saveDrawRequestDocument: document => dispatch(saveDrawRequestDocument(document)),
        deleteDrawRequestDocument: document => dispatch(deleteDrawRequestDocument(document)),
        logEvent: event => dispatch(logEvent(event))
    };
};

const mapStateToProps = state => {
    return {
        onlineStatus: state.onlineStatus,
        drawData: state.drawData,
        isCameraAvailable: state.isCameraAvailable
    }
};

class PhotoCaptureClass extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        //const { cookies } = this.props;

        this.state = {
            ...this.state,
            siteId: "",
            patientId: "",
            dialogOpenedPatientRefused: false,
            dialogOpenedNotes: false,
            noteOperation: NEW,
            selectedNote: {
                id: "",
                message: "",
            },
            setDeleteNotesDialog: false,
            confirmDialogMessage: "",
            confirmDialogTitle: "",
            barcode: "",
            btnScanDisabled: false,
            barcodeValue: "",
            errorOnCamera: false,
            dataUri: "",
            openedAttachmentDialog: false,
            attachmentSource: "",
            showCamera: true,
            offlineDialogOpened: false,
            documentRequestId: "",
            documentRequestCategory: "",
            fieldDataCapture: props.fieldDataCapture ? props.fieldDataCapture : false
        }

        this.selectedPatientDraw = undefined;
        this.drawSiteValue = "right_arm";
        this.modifiedDrawSite = false;

        //this.codeReader = new BrowserMultiFormatReader()
        //console.log("PatientClass: constructor");

        // this.customBackdropModal = null;
    }

    componentWillMount() {
        // console.log("componentWillMount")
        this.setState({
            ...this.state,
            patientId: this.props.patientId,
            siteId: this.props.siteId,
            showCamera: true,
            readOnly: this.props.readOnly
        });
    }
    componentWillUnmount() {
        // try {
        //     localstream.getTracks()[0].stop()
        // } catch(e) {
        //     console.log(e)
        // }
        //MediaStream.stop()
    }

    componentDidMount() {
        // console.log("componentDidMount")
        this.props.photoCaptureRef.current = {
            addNewPhoto: this.addNewPhoto,
            deletePhoto: this.deletePhoto
        }

        // if (typeof this.props.addNewPhoto === "function") {
        //     this.props.addNewPhoto(this.addNewPhoto)
        // }

        // if (typeof this.props.deletePhoto === "function") {
        //     this.props.deletePhoto(this.deletePhoto)
        // }

        if (typeof this.props.isReadOnly === "function") {
            this.props.isReadOnly(this.isReadOnly)
        }
    }

    handleOpenDialog = event => {
        this.setState({ ...this.state, dialogOpenedPatientRefused: true });
    };

    setDeleteNotesDialogHandler = (actionHandler, postActionHandler, title, message, actionText, chainedFunction) => {
        // if(chainedFunction === null) chainedFunction = ()=>{};
        this.setState(
            {
                ...this.state,
                actionHandler: actionHandler,
                postActionHandler: postActionHandler,
                confirmDialogMessage: message,
                confirmDialogTitle: title,
                confirmDialogActionText: actionText,
            },
            chainedFunction
        );
    }

    setDeleteNoteDialogVisible = (value) => {
        //console.log("setDeleteNoteDialogVisible " + value);
        this.setState({ ...this.state, setDeleteNotesDialog: value });
    }

    handleChangeBarcode = (event) => {
        this.setState({ ...this.state, barcode: event.target.value })
    }

    //  acceptBarcode = () =>{
    //     this.addPhoto()
    // }

    // deleteNote = (operation)=>{

    //     this.props.deleteNote({
    //         "noteOperation" : this.state.noteOperation,
    //         "siteId": this.state.siteId ,
    //         "patientId":  this.state.patientId, 
    //         "patientDrawId": this.props.patientDrawId,
    //         "target" : this.props.target,            
    //         "note": this.state.selectedNote,
    //     });
    //     this.photoCaptureDialogVisibility(false);
    //  }

    // openNote =  (noteId) => {
    //     var iNotes = ImmutableJS.fromJS(this.props.notes);
    //    //console.log(noteId);
    //     var noteIndex =  iNotes.findIndex(id => id.get('id') === noteId);
    //    //console.log(iNotes.getIn([noteIndex]).toJS());
    //     this.setState({...this.state, selectedNote:  iNotes.getIn([noteIndex]).toJS() },()=>{
    //         this.photoCaptureDialogVisibility(true, EDIT)
    //     });
    // };

    updateDrawRequestDocument = (payload) => {
        this.props.saveDrawRequestDocument(payload)
    }

    deleteDrawRequestDocument = (payload) => {
        this.props.deleteDrawRequestDocument(payload)
    }

    setDeleteRequisitionDialogHandler = (actionHandler, postActionHandler, title, message, actionText, chainedFunction) => {
        // if(chainedFunction === null) chainedFunction = ()=>{};
        this.setState(
            {
                ...this.state,
                actionHandler: actionHandler,
                postActionHandler: postActionHandler,
                confirmDialogMessage: message,
                confirmDialogTitle: title,
                confirmDialogActionText: actionText,
            },
            chainedFunction
        );
    }

    setDeleteNoteDialogVisible = (value) => {
        //console.log("setDeleteNoteDialogVisible " + value);
        this.setState({ ...this.state, setDeleteNotesDialog: value });
    }

    removeDrawRequestDocument = (document) => {
        if (this.props.readOnly) {
            return
        }
        const siteId = this.state.siteId
        const patientId = this.state.patientId

        var payload = {
            siteId: siteId,
            patientId: patientId,
            target: "patient_draw",
            document: document,
            type: "photo"
        }
        console.log(payload)

        this.setState({ ...this.state, barcode: "" },
            this.deleteDrawRequestDocument(payload)
        )

    }

    addPhoto = () => {

        if (this.state.dataUri === "" || this.state.dataUri === null) return

        const siteId = this.state.siteId
        const patientId = this.state.patientId

        if (globals.DEBUG_ALLOWED) {
            console.log("siteId")
            console.log(siteId)
            console.log("patientId")
            console.log(patientId)
            console.log("this.state.dataUri")
            console.log(this.state.dataUri)
        }

        let imageData = this.state.dataUri
        //var t = moment(new Date()).format("YYYY-MM-DD h:mm:ss A");
        var timeTaken = moment(new Date()).format("M-D-YY-h:mm:ss-A");
        // console.log("this.state")
        // console.log(this.state)

        var document =
        {
            "id": this.state.documentRequestId,
            "filename": "Photo-" + timeTaken + ".jpg",
            "path": "Photo",
            "doc_type": "offline_upload",       // Potential values: "" | "metadata" | "offline_upload" | "offline_uploaded",
            "metadata": {
                "image": {
                    "category": this.state.documentRequestCategory,             // Potential values: "Patient No Show" | "Patient Identification"
                    "data": imageData,
                    "filename": "Photo-" + timeTaken + ".jpg",   // Original filename
                    "resize_conf": {
                        "imageType": "IMAGE_TYPES.JPG",
                        "imageCompression": .5,
                        "isImageMirror": false,
                        "sizeFactor": 0.5
                    }
                },
                "field_data_capture": this.state.fieldDataCapture
            }
        }
        var payload = {
            siteId: siteId,
            patientId: patientId,
            type: "photo",
            target: "patient_draw",
            document: document
        }

        // console.log("Photo Capture 2 payload")
        // console.log(payload)

        if (this.state.documentRequestId !== "" || this.state.noteOperation === EDIT) {
            payload.documentOperation = "edit"
        }

        if (globals.DEBUG_ALLOWED) {
            console.log("this.state.documentRequestId")
            console.log(this.state.documentRequestId)
            console.log("this.state.noteOperation")
            console.log(this.state.noteOperation)
            console.log("addPhoto payload")
            console.log(payload)
        }

        this.setState({ ...this.state, dataUri: "", showCamera: false }, () => {
            this.updateDrawRequestDocument(payload)
            this.photoCaptureDialogVisibility(false);

            // trigger backdrop that will close after 5/8secs
            // this.customBackdropModal.show();
        })
    }

    photoCaptureDialogVisibility = (value, noteOperation = NEW) => {

        //console.log("setNotesDialogVisibility value " + value + " Operation " + noteOperation)

        // if(value === false){
        //     this.stopScanning()
        //  } else {
        //     this.startScanning()
        //  }

        var selectedNote = this.state.selectedNote;

        if (noteOperation === NEW) {
            selectedNote = {
                "id": "",
                "client_timezone": "",
                "author_type": "",
                "message": "",
                "client_deleted": "false",
                "client_timestamp": ""
            }
            // this.state.errorOnCamera
            this.setState({
                ...this.state, noteOperation: noteOperation, errorOnCamera: false,
                selectedNote: selectedNote, showCamera: value, dialogOpenedNotes: value, barcodeValue: "",
                dataUri: ""
            });

        }

        if (noteOperation === PREVIEW || noteOperation === EDIT) {

            this.setState({
                ...this.state,
                noteOperation: noteOperation,
                selectedNote: selectedNote,
                dialogOpenedNotes: value, barcodeValue: ""
            });

        }

    };

    previewPhoto = (dataUri) => {
        console.log('preview photo');
        this.setState({ ...this.state, dataUri: dataUri }, () => {
            this.photoCaptureDialogVisibility(true, PREVIEW)
        });

    }

    startScanning = () => {
        // console.log("Listing listVideoInputDevices")
        // //var deviceId = "";

        // this.codeReader.listVideoInputDevices().then(videoInputDevices => 
        //     {

        //     }
        // ).catch(err => console.error(err))

        this.setState({ ...this.state, btnScanDisabled: true })
    }

    stopScanning = () => {
        this.setState({ ...this.state, btnScanDisabled: false })
        //this.codeReader.reset())
    }

    /* Camera Handlers */

    handleTakePhoto = (dataUri) => {

        // Do stuff with the photo...
        setTimeout(() => {
            this.setState({ ...this.state, dataUri: dataUri })
            // console.log('takePhoto');
            // console.log(dataUri);
        }, 500);

        //   this.setState({...this.state, dataUri: dataUri })
        //   console.log('takePhoto');
        //   console.log(dataUri);

    }

    handleTakePhotoAnimationDone = (dataUri) => {
        // Do stuff with the photo...
        console.log('takePhoto');
    }

    handleCameraError = (error) => {
        console.log('handleCameraError', error);



        this.setState({ ...this.state, errorOnCamera: true }, () => {

            this.props.logEvent({
                module: "Photo_Capure",
                action: "load_camera",
                description: error.toString()
                //module_id: siteId
            })
        })
    }

    handleCameraStart = (stream) => {
        console.log('handleCameraStart');
    }

    handleCameraStop = () => {
        console.log('handleCameraStop');
    }

    previewUsingIframe = (url) => {
        // console.log('previewUsingIframe')
        console.log("Preview this URL using iframe : " + url)
        this.setState({ ...this.state, attachmentSource: url, openedAttachmentDialog: true });
    }

    previewDataUriFromImageUrl = (url) => {
        if (this.props.onlineStatus === false) {
            this.setState({
                ...this.state,
                offlineDialogOpened: true
            });
        } else {
            // console.log("Preview this URL using iframe : " + url)

            this.getBase64FromImage(
                url,
                function (base64) {
                    // document.getElementById("img").src = base64;
                    // console.log("base64 image loaded");
                    // console.log(base64);
                    this.setState({ ...this.state, attachmentSource: base64, openedAttachmentDialog: true });
                }.bind(this),
                function (error) {
                    console.log(error);
                }
            );
        }
    }

    getBase64FromImage = (url, onSuccess, onError) => {
        var xhr = new XMLHttpRequest();

        xhr.responseType = "arraybuffer";
        xhr.open("GET", url);

        xhr.onload = function () {
            var base64, binary, bytes, mediaType;

            bytes = new Uint8Array(xhr.response);
            //NOTE String.fromCharCode.apply(String, ...
            //may cause "Maximum call stack size exceeded"
            binary = [].map.call(bytes, function (byte) {
                return String.fromCharCode(byte);
            }).join('');
            mediaType = xhr.getResponseHeader('content-type');
            base64 = [
                'data:',
                mediaType ? mediaType + ';' : '',
                'base64,',
                btoa(binary)
            ].join('');
            onSuccess(base64);
        };
        xhr.onerror = onError;
        xhr.send();
    }

    closeAttachmentDialog = () => {
        this.setState({ ...this.state, openedAttachmentDialog: false });
    }

    handleCloseOfflineDialog = () => {
        this.setState({
            ...this.state,
            offlineDialogOpened: false
        });
    };

    addNewPhoto = ({ id, category, dataUri }) => {
        var documentRequestId = id ? id : ""
        var documentRequestCategory = category ? category : "Patient Identification" // default
        var dataUriForEdit = dataUri ? dataUri : ""
        // console.log("documentRequestId")
        // console.log(documentRequestId)
        // console.log("documentRequestCategory")
        // console.log(documentRequestCategory)

        this.setState({
            ...this.state,
            documentRequestId: documentRequestId,
            documentRequestCategory: documentRequestCategory,
            dataUri: dataUriForEdit
        }, function () {
            this.photoCaptureDialogVisibility(true, dataUriForEdit === "" ? NEW : EDIT)
        });
    }

    isReadOnly = () => {
        return this.props.readOnly
    }

    deletePhoto = (photo) => {
        // console.log("deletePhoto")
        // console.log(photo)
        if (photo === undefined) {
            console.log("Unable to delete Req Doc")
            console.log("selectedReqDoc", photo)
        }

        if (this.props.readOnly) {
            return
        }
        this.setDeleteRequisitionDialogHandler(
            () => this.removeDrawRequestDocument(photo),
            () => {
                this.setDeleteNoteDialogVisible(false)
                this.photoCaptureDialogVisibility(false)
            }
            ,
            "Delete Confirmation",
            "Confirm to delete this photo?",
            "Delete",
            () => {
                this.setDeleteNoteDialogVisible(true)
            },
        )
    }

    render() {
        const { classes, draw_request_documents } = this.props;
        const { documentRequestId } = this.state;
        // console.log("PhotoCapture 2 this.state")
        // console.log(this.state)
        // var requisitionIds = "";

        // console.log(this.state.documentRequestId)
        // console.log("draw_request_documents")
        // console.log(draw_request_documents.draw_request_documents)

        var selectedDrawReqDoc = undefined
        if (draw_request_documents.draw_request_documents) {
            selectedDrawReqDoc = draw_request_documents.draw_request_documents.find(function (draw_req_doc) {
                return draw_req_doc.id === documentRequestId;
            })
        }
        // if(this.props.draw_request_documents) {
        //     requisitionIds = this.props.draw_request_documents.map((doc) => {
        //        // if(doc.doc_type !== undefined && doc.doc_type==="metadata" 
        //        //   && doc.client_deleted !== undefined  && doc.client_deleted !== "true"  ) {
        //        if(doc.doc_type !== undefined && doc.metadata.image !== undefined 
        //           && doc.client_deleted !== undefined  && doc.client_deleted !== true 
        //           && doc.doc_type === "offline_upload" ) {

        //         return(
        //                 <ListItem 
        //                     key={Math.random()}
        //                     disableGutters={true}
        //                     className={classes.requisitionList} 
        //                     aria-label="Requisitions"
        //                     onClick={()=>{this.previewPhoto(doc.metadata.image.data) }}
        //                     >
        //                     {doc.filename}                           
        //                     <ListItemSecondaryAction>
        //                     <Icon 
        //                         //onClick={() => this.removeDrawRequestDocument(doc)}
        //                         onClick = {() =>{this.deletePhoto(doc)}} 
        //                         disabled={this.props.readOnly}
        //                         classes={{root: classes.clearButton}}
        //                     >clear</Icon>
        //                     </ListItemSecondaryAction>
        //                 </ListItem>
        //             );
        //         } else if(doc.doc_type !== undefined && doc.filename !== undefined 
        //              && doc.client_deleted !== undefined  && doc.client_deleted !== true && 
        //               doc.doc_type === "offline_uploaded" && doc.path !== undefined
        //              ) {

        //                 var photoUrl = (globals.USE_PAMP_COUCHDB_SERVER) ? doc.path : globals.SERVER + doc.path;
        //                 return(
        //                     <ListItem 
        //                         key={Math.random()}
        //                         disableGutters={true}
        //                         className={classes.requisitionList} 
        //                         aria-label="Requisitions"
        //                         onClick={()=>{this.previewDataUriFromImageUrl(photoUrl)}}
        //                         >
        //                         {doc.filename} 

        //                     </ListItem>
        //                 )

        //         }  else if(doc.path !== undefined && doc.path !== ""
        //             ) {
        //             var photoUrl2 = (globals.USE_PAMP_COUCHDB_SERVER) ? doc.path : globals.SERVER + doc.path;
        //             return(
        //                 <ListItem 
        //                     key={Math.random()}
        //                     disableGutters={true}
        //                     className={classes.requisitionList} 
        //                     aria-label="Requisitions"
        //                     onClick={()=>{this.previewDataUriFromImageUrl(photoUrl2)}}
        //                     >
        //                     {doc.filename} 

        //                 </ListItem>
        //             )

        //         } else {
        //             return null // Add to eliminate warning 
        //         }         
        //     });

        //     requisitionIds = requisitionIds.filter(function (el) {
        //         return el != null;
        //     });

        //     // if(requisitionIds.length === 0) {
        //     //     requisitionIds = <ListItem 
        //     //         key={Math.random()}
        //     //         disableGutters={true}
        //     //         className={classNames(classes.requisitionList, classes.noPhoto)} 
        //     //         aria-label="Photos"
        //     //         >
        //     //         No Photos                
        //     //     </ListItem>;
        //     // } 
        // }

        // console.log("this.state.dataUri", "this.props.readOnly")
        // console.log(this.state.dataUri === "", this.props.readOnly)

        return (
            <div>
                {/* <Button
                    onClick={()=>this.photoCaptureDialogVisibility(true,NEW)}
                    variant="contained"
                    color="secondary"
                    className={classes.btnAddPhoto}
                    disabled={this.props.readOnly}
                    >
                    <Icon className={classes.btnAddPhotoIcon}>photo_camera</Icon> Capture Photo
                </Button>
                <List className={classes.listContainer}>
                    {requisitionIds}
                </List> */}

                <Dialog
                    id="notes-dialog"
                    fullScreen={true}
                    classes={{
                        paper: classes.dialogPaperForNotes
                    }}
                    open={this.state.dialogOpenedNotes}
                    // onClose={this.handleCloseDialogPatientRefused}
                    onEnter={this.handleOnShowDialogPatientRefused}
                    aria-labelledby="notes-title">

                    {/* <DialogTitle id="notes-title" onClose={() => this.photoCaptureDialogVisibility(false)}>Photo Capture</DialogTitle> */}
                    <DialogContent className={classes.photoCaptureDialogContent}>
                        <IconButton
                            className={classes.closeCameraButton}
                            aria-label="close-dialog"
                            onClick={() => this.photoCaptureDialogVisibility(false)}
                            color="secondary"
                        >
                            <CloseIcon
                                className={classes.closeIcon}
                            />
                        </IconButton>
                        <div id="photo-capture-webcam-container">
                            {this.state.errorOnCamera ?
                                <CameraPermissionError />
                                : (
                                    this.state.dataUri === "" ?
                                        this.state.showCamera &&
                                        <CustomWebcam2
                                            onCameraError={(error) => { this.handleCameraError(error) }}
                                            onTakePhoto={(dataUri) => { this.handleTakePhoto(dataUri); }}
                                            width={window.innerWidth}
                                            height={window.innerHeight} // 36 h and 20 pad for ok cancel button cont height

                                        // onTakePhotoAnimationDone = { (dataUri) => { handleTakePhotoAnimationDone(dataUri); } }
                                        // onCameraError = { (error) => { this.handleCameraError(error); } }
                                        // idealFacingMode = {FACING_MODES.ENVIRONMENT}
                                        // // idealResolution = {{width: 640, height: 480}}
                                        // imageType = {IMAGE_TYPES.JPG}
                                        // imageCompression = {.5}
                                        // //  isMaxResolution = {false}f
                                        // isImageMirror = {false}
                                        // //isSilentMode = {false}
                                        // //  isDisplayStartCameraError = {true}
                                        // // isFullscreen = {true}
                                        // sizeFactor = {0.5}
                                        // //  onCameraStart = { (stream) => { handleCameraStart(stream); } }
                                        // onCameraStop = { () => { this.handleCameraStop(); } }
                                        />

                                        :
                                        <ImagePreview
                                            dataUri={this.state.dataUri}
                                            // isFullscreen={true}
                                            width={window.innerWidth}
                                            height={window.innerHeight - 65} // 36 h and 20 pad for ok cancel button cont height
                                        />
                                )}
                        </div>
                    </DialogContent>
                    <DialogActions className={classes.photoCaptureDialogActions}>
                        {/* {this.state.noteOperation !== EDIT && this.state.dataUri !== "" && this.state.noteOperation !== PREVIEW &&
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classNames(classes.buttons, classes.btnUsePhoto)}
                                onClick={this.addPhoto}
                            >Use photo</Button>
                        } */}
                        {(this.state.noteOperation === NEW && this.state.dataUri !== "" && this.props.canForceAdd) &&
                            <>
                                <Button
                                    variant="contained"
                                    className={classNames(classes.buttons, classes.btnTakeAgain)}
                                    onClick={() => {
                                        this.addNewPhoto({
                                            id: this.state.documentRequestId,
                                            category: this.state.documentRequestCategory,
                                            dataUri: ""
                                        })
                                    }}
                                    color="default">Take again</Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classNames(classes.buttons, classes.btnUsePhoto)}
                                    onClick={this.addPhoto}
                                >Use photo</Button>
                            </>
                        }

                        {!this.props.readOnly &&
                            <>
                                {this.state.dataUri !== "" &&
                                    <>
                                        {this.state.noteOperation === NEW
                                            ?
                                            <>
                                                <Button
                                                    variant="contained"
                                                    className={classNames(classes.buttons, classes.btnTakeAgain)}
                                                    onClick={() => {
                                                        this.addNewPhoto({
                                                            id: this.state.documentRequestId,
                                                            category: this.state.documentRequestCategory,
                                                            dataUri: ""
                                                        })
                                                    }}
                                                    color="default">Take again</Button>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    className={classNames(classes.buttons, classes.btnUsePhoto)}
                                                    onClick={this.addPhoto}
                                                >Use photo</Button>
                                            </>
                                            :
                                            <>
                                                <Button
                                                    variant="contained"
                                                    disabled={this.state.noteOperation === PREVIEW && this.state.noteOperation !== EDIT}
                                                    className={classNames(classes.buttons, classes.btnDelete)}
                                                    onClick={() => this.deletePhoto(selectedDrawReqDoc)}
                                                    color="default">Delete</Button>

                                                {globals.ALLOW_EXISTING_PHOTO_RETAKE &&
                                                    <Button
                                                        variant="contained"
                                                        disabled={
                                                            (this.state.noteOperation === PREVIEW &&
                                                                this.state.noteOperation !== EDIT) ||
                                                            !globals.ALLOW_EXISTING_PHOTO_RETAKE
                                                        }
                                                        className={classNames(classes.buttons, classes.btnRetake)}
                                                        onClick={() => {
                                                            this.addNewPhoto({
                                                                id: this.state.documentRequestId,
                                                                category: this.state.documentRequestCategory,
                                                                dataUri: ""
                                                            })
                                                        }}
                                                        color="default">Re-take</Button>
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                        {/* <Button
                            variant="contained"
                            className={classes.buttons}
                            onClick={() => this.photoCaptureDialogVisibility(false)}
                            color="default">Cancel</Button> */}
                    </DialogActions>
                </Dialog>

                <Dialog
                    id="attachment-dialog"
                    fullScreen={true}
                    open={this.state.openedAttachmentDialog}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogContent className={classes.pdfDialogContainer}>
                        <img src={this.state.attachmentSource} alt="pdf" className={classes.imageViewer} />
                        <Button onClick={this.closeAttachmentDialog} className={classNames(classes.btnClosePDF)} variant="contained" color="default">x</Button>
                        {/* <Iframe 
                            url={this.state.attachmentSource}
                            width="100%"
                            height="100%"
                            id="attachment-iframe"
                            className="myClassname"
                            frameBorder="0"
                            display="initial"
                            position="absolute"
                            loading="Loading attachment..." /> */}
                    </DialogContent>
                </Dialog>

                <ConfirmDialog
                    type="warning"
                    visible={this.state.setDeleteNotesDialog}
                    title={this.state.confirmDialogTitle}
                    message={this.state.confirmDialogMessage}
                    actionText={this.state.confirmDialogActionText}
                    actionHandler={this.state.actionHandler}
                    postActionHandler={this.state.postActionHandler}
                    onClose={() => {
                        //console.log("ConfirmDialog On close")
                        setTimeout(() => {
                            this.setDeleteNoteDialogVisible(false);
                        }, 100);

                    }}
                />

                <Dialog
                    fullScreen={false}
                    classes={{
                        paper: classes.dialogPaper
                    }}
                    open={this.state.offlineDialogOpened}
                    onClose={this.handleCloseOfflineDialog}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">Cannot load Attachment</DialogTitle>
                    <DialogContent>
                        <br />
                        <Typography>Must be online to view attachments.</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseOfflineDialog} className={classes.buttons} variant="contained">Close</Button>
                    </DialogActions>
                </Dialog>

                {/* <CustomBackdrop
                    onRef={customBackdropModal => this.customBackdropModal = customBackdropModal}
                    interval={globals.BACKDROP_BLOCKING_MODAL_SHOW_INTERVAL}
                    content={
                        <div style={{ textAlign: 'center' }}>
                            <MDSpinner size={50} />
                            <Typography style={{ paddingTop: 20 }}>Processing captured image...</Typography>
                        </div>
                    }
                /> */}
            </div >
        );

    }
}

PhotoCaptureClass.propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    theme: PropTypes.object.isRequired,
};

const PhotoCapture = withCookies(connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles, { withTheme: true })(PhotoCaptureClass))));

export default PhotoCapture;
