import React, { useState, useEffect } from 'react'

import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
import classNames from 'classnames'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import ApplicationBar from 'components/common/workflow/ApplicationBar'

import ApptOrderHeader from './ApptOrderHeader'
import StepDescription from './StepDescription'
import Step1 from './default/Step1'
import Step2 from './default/Step2'
import Step3 from './default/Step3'
import ConfirmCompletionDialog from './ConfirmCompletionDialog'
import moment from 'moment-timezone';
import Stepper from './Stepper'

import {
    updateWorkflowValue,
    updatePatientDrawStatus
} from "js/actions/index"

const mapDispatchToProps = dispatch => {
    return {
        updateWorkflowValue: value => dispatch(updateWorkflowValue(value)),
        updatePatientDrawStatus: value => dispatch(updatePatientDrawStatus(value)),
    }
}

const mapStateToProps = state => {
    return {
        drawData: state.drawData,
        workflowData: state.workflowData
    }
}

const DefaultWorkflowOrderFunctionClass = (props) => {
    const {
        drawData,
        workflowData,
        classes,
        history,
        match,
        updateWorkflowValue,
        updatePatientDrawStatus
    } = props
    const appointment_id = match.params.appointment_id  // site_id
    const order_id = match.params.order_id              // patient_id
    const workflow_type = match.url.split('/')[4]       // workflow_type

    var patient_draw_id = 0

    // START LOAD patient order data and patient workflow data
    var order = {
        patient: {
            last_name: "",
            first_name: "",
            birth_date: ""
        }
    }
    var patientWorkflowData = null
    var isOrderRemaining = true

    if (drawData.draw_list !== undefined) {
        const theSite = drawData.draw_list.find(function (site) {
            return site.id === appointment_id
        })
        // console.log("theSite")
        // console.log(theSite)

        order = theSite.patient_list.find(function (patient) {
            return patient.id === order_id
        })

        if (order.patient_draw[0].status !== "remaining") {
            isOrderRemaining = false
        }

        patient_draw_id = order.patient_draw[0].id
        if (workflowData.workflow !== undefined) {
            patientWorkflowData = workflowData.workflow.filter(function (workflow) {
                return workflow.drawlist_site_id === appointment_id &&
                    workflow.drawlist_patient_id === order_id &&
                    workflow.patient_draw_id === patient_draw_id
            })
        }
    }
    // END LOAD patient order data and patient workflow data
    console.log("patientWorkflowData")
    console.log(patientWorkflowData)

    const step = match.params.step
    // const [btnBackDisabled, setBtnBackDisabled] = useState(false)
    const btnBackDisabled = false
    const [btnNextDisabled, setBtnNextDisabled] = useState(true)

    const onBack = (step) => {
        history.push("/appointments/today/" + appointment_id + "/" + workflow_type + "/" + order_id + "/" + step)
    }

    const onNext = (step) => {
        history.push("/appointments/today/" + appointment_id + "/" + workflow_type + "/" + order_id + "/" + step)
    }

    // processing of data
    const [step1Data, setStep1Data] = useState(null)
    // const [step2Data, setStep2Data] = useState(null)
    const step2Data = null
    const [step3Data, setStep3Data] = useState(null)

    const executeUpdateWorkflowValues = (wf_values) => {
        const payload = {
            "siteId": appointment_id,
            "patientId": order_id,
            "patientDrawId": patient_draw_id,
            "workflow_values": wf_values
        }
        updateWorkflowValue(payload)
    }

    // Verify patient information
    const saveStep1Data = () => {
        console.log("saveStep1Data")
        console.log(step1Data)

        if (step1Data && isOrderRemaining) {
            const name = step1Data.find(x => x.key === "name_verified")
            const dob = step1Data.find(x => x.key === "dob_verified")

            executeUpdateWorkflowValues([
                { key: "name_verified", value: name.verified },
                { key: "dob_verified", value: dob.verified },
            ])
        }
    }

    // Field data capture
    const saveStep2Data = () => {
        console.log("saveStep2Data")
        console.log(step2Data)
    }

    // Signature
    const saveStep3Data = () => {
        console.log("step3Data")
        console.log(step3Data)

        if (step3Data && isOrderRemaining) {
            var timezone = moment.tz.guess(true) || "America/New_York"
            var unixEpoch = moment().unix();

            console.log("order.patient_draw")
            console.log(order)

            const payload = {
                "first_name": order.patient.first_name, // We assume patient name but better confirm it. 
                "last_name": order.patient.last_name,  // 
                "signatory_type": "patient", // ? 
                "sign_off": step3Data.signature,
                "sign_off_metadata": step3Data.metadata,
                "client_timezone": timezone,
                "client_timestamp": unixEpoch
            }

            executeUpdateWorkflowValues([
                { key: "sign_off", value: payload },
            ])
        }
    }

    useEffect(() => {
        saveStep3Data()
    }, [step3Data])

    // ** DEFAULT VALUES
    // btnNextLabel: Next
    // btnBackVisible: true
    // btnNextVisible: true
    const steps = [
        {
            description: "Verify Patient Information",
            btnBackVisible: false,
            btnNextLabel: "Verify",
            onClickBtnNext: () => {
                onNext(2)
                setBtnNextDisabled(false) // Field data capture not required
                if (isOrderRemaining) {
                    saveStep1Data()
                }
            },
            content: <Step1
                isOrderRemaining={isOrderRemaining}
                onSetBtnNextDisabled={(val) => setBtnNextDisabled(val)}
                onChangeData={(data) => setStep1Data(data)}
                order={order}
                patientWorkflowData={patientWorkflowData}
                onConfirmUnableToPerformOrder={(data) => confirmUnableToPerformOrder(data)}
            />
        },
        {
            description: "Field data capture (optional)",
            onClickBtnBack: () => { onBack(1) },
            onClickBtnNext: () => {
                onNext(3)
                setBtnNextDisabled(true)
                saveStep2Data()
            },
            content: <Step2
                isOrderRemaining={isOrderRemaining}
                patientWorkflowData={patientWorkflowData}
                onSetBtnNextDisabled={(val) => setBtnNextDisabled(val)}
                siteId={appointment_id}
                patientId={order_id}
                patientDrawId={patient_draw_id}
            />
        },
        {
            description: "Get patient's signature",
            btnNextLabel: isOrderRemaining ? "Complete" : "Close",
            onClickBtnBack: () => {
                onBack(2)
                // executeUpdateWorkflowValues([
                //     { key: "sign_off", value: {} },
                // ])
            },
            onClickBtnNext: () => {
                if (isOrderRemaining) {
                    setConfirmCompletionDialogOpen(true)
                } else {
                    goBackToOrdersList()
                }
            },
            content: <Step3
                isOrderRemaining={isOrderRemaining}
                patientWorkflowData={patientWorkflowData}
                onSetBtnNextDisabled={(val) => setBtnNextDisabled(val)}
                // on every edit
                onChangeData={(data) => {
                    setStep3Data(data)
                    // saveStep3Data() // useEffect
                }}
                // save if done editing
                onAcceptSignature={() => {
                    // saveStep3Data()
                }}
                // save empty sign directly
                onClearSignature={() => {
                    executeUpdateWorkflowValues([
                        { key: "sign_off", value: {} },
                    ])
                }}
            />
        },
    ]

    const confirmDrawCompletion = () => {
        // set done draw here
        const payload = {
            "siteId": appointment_id,
            "patientId": order_id,
            "patientDrawId": patient_draw_id,
            "type": "p",
            "status": "done",
            "notes": "",
            "draw_source": "right_arm",
            "withSignature": false,
        }
        updatePatientDrawStatus(payload)

        history.push("/appointments/today/" + appointment_id)
    }

    const confirmUnableToPerformOrder = (data) => {
        const payload = {
            "siteId": appointment_id,
            "patientId": order_id,
            "patientDrawId": patient_draw_id,
            "type": "p",
            "status": data.status,
            "notes": data.notes
        }
        updatePatientDrawStatus(payload)

        history.push("/appointments/today/" + appointment_id)
    }

    const goBackToOrdersList = () => {
        history.push("/appointments/today/" + appointment_id)
    }

    const [confirmCompletionDialogOpen, setConfirmCompletionDialogOpen] = useState(false)

    return (
        <>
            <div className={classes.root}>
                <main className={classes.content}>
                    <div className={classNames(classes.mainContainer)}>
                        <ApplicationBar />

                        <ApptOrderHeader step={step} />
                        <StepDescription step={step} description={steps[step - 1].description} />

                        {steps[step - 1].content}

                        <Stepper
                            step={step}

                            isBtnBackDisabled={btnBackDisabled}
                            isBtnBackVisible={steps[step - 1].btnBackVisible}
                            btnBackLabel={steps[step - 1].btnBackLabel}
                            onClickBtnBack={steps[step - 1].onClickBtnBack}

                            isBtnNextDisabled={btnNextDisabled}
                            isBtnNextVisible={steps[step - 1].btnNextVisible}
                            btnNextLabel={steps[step - 1].btnNextLabel}
                            onClickBtnNext={steps[step - 1].onClickBtnNext}
                        />

                        {/* modals */}
                        <ConfirmCompletionDialog
                            open={confirmCompletionDialogOpen}
                            onClose={() => setConfirmCompletionDialogOpen(false)}
                            onConfirm={() => confirmDrawCompletion()}
                        />
                    </div>
                </main>
            </div>
        </>
    )
}

const DefaultWorkflowOrder = withCookies(
    connect(mapStateToProps, mapDispatchToProps)(
        withStyles(styles, { withTheme: true })(DefaultWorkflowOrderFunctionClass)
    )
)
export default DefaultWorkflowOrder
