import React from 'react';
import PropTypes from 'prop-types';

import './styles/imagePreview.css';

export const ImagePreview = ({ dataUri, width, height }) => { // isFullscreen
  // let classNameFullscreen = isFullscreen ? 'demo-image-preview-fullscreen' : '';
  const cont_width = width ? width : '100%';
  const cont_height = height ? height : '100%';

  return (
    <div 
      className={'demo-image-preview'} //  + classNameFullscreen
      style={{
        width: cont_width,
        height: cont_height,
        position: 'relative'
      }}
    >
      <img 
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: 'auto'
        }}
        src={dataUri} alt="Captured patient face" />
    </div>
  );
};

ImagePreview.propTypes = {
  dataUri: PropTypes.string,
  isFullscreen: PropTypes.bool
};

export default ImagePreview;