import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Login from "components/Login";

import MobileRouter from "./router/MobileRouter";
import TabletRouter from "./router/TabletRouter";
import * as globals from 'components/common/GlobalConstants';

import { connect } from "react-redux";
import {
    uploadLogs, doLogout, setForceLogout, logEvent, loadSchedules, setupNewDB, loadData, loadStat, loadDrawRequests, loadDrawRequestDocumentsData, loadDrawRequestNotesData, loadWorkflowData,
    loadFutureOrders, setScreenLockStatus, setSyncStatus, setNewAppVersion, loadGeoLocationData
} from "js/actions/index"

import PouchDB from 'pouchdb-browser';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
// import { isMobileOnly } from "react-device-detect";
import $ from 'jquery';
import ReactTimeout from 'react-timeout'
import LoadingSpinner from "components/common/mobile/LoadingSpinner.js";
//import RequestTimeoutScreen from 'components/common/mobile/RequestTimeoutScreen';
// import NoDrawListScreen from  'components/common/mobile/NoDrawListScreen';
import LowDiskSpaceScreen from 'components/common/mobile/LowDiskSpaceScreen'
//import Logout from 'components/Logout';
import Logout from "components/Main/mobile/Logout"
import moment from 'moment-timezone'
// import CryptoJS from 'crypto-js';

// import {geolocated} from 'react-geolocated'

import UnlockScreen from './mobile/PinCode/UnlockScreen'
import CreatePinCode from './mobile/PinCode/CreatePinCode'
import { SCREEN_LOCK_TIMEOUT } from 'components/common/GlobalConstants'
import Encryption from 'components/common/Encryption'
import * as helper from 'components/common/Helper.js'

import GPSLock from "./mobile/GPSLock";


//import NoSleep from 'nosleep.js'

//const SomeComponent = withRouter(props => <Main {...props}/>);

//const encodeBase64 = utils.encodeBase64

//const decodeBase64 = utils.decodeBase64

// let commitHash = require('child_process')
//   .execSync('git rev-parse --short HEAD')
//   .toString();
//import GitInfo from 'react-git-info/macro';


const mapDispatchToProps = dispatch => {
    return {
        loadData: labData => dispatch(loadData(labData)),
        setupNewDB: credentials => dispatch(setupNewDB(credentials)),
        loadStat: stat => dispatch(loadStat(stat)),
        loadSchedules: schedules => dispatch(loadSchedules(schedules)),
        loadDrawRequests: draw_requests_data => dispatch(loadDrawRequests(draw_requests_data)),
        loadDrawRequestDocumentsData: draw_request_documents => dispatch(loadDrawRequestDocumentsData(draw_request_documents)),
        loadDrawRequestNotesData: draw_request_documents => dispatch(loadDrawRequestNotesData(draw_request_documents)),
        loadWorkflowData: workflow_document => dispatch(loadWorkflowData(workflow_document)),
        loadFutureOrders: future_orders_data => dispatch(loadFutureOrders(future_orders_data)),
        setScreenLockStatus: status => dispatch(setScreenLockStatus(status)),
        setSyncStatus: status => dispatch(setSyncStatus(status)),
        logEvent: event => dispatch(logEvent(event)),
        setForceLogout: value => dispatch(setForceLogout(value)),
        doLogout: value => dispatch(doLogout(value)),
        uploadLogs: () => dispatch(uploadLogs()),
        setNewAppVersion: (value) => dispatch(setNewAppVersion(value)),
        loadGeoLocationData: geo_location_data => dispatch(loadGeoLocationData(geo_location_data)),


    }
}

const mapStateToProps = state => {
    return {
        db: state.db,
        dbCredentials: state.dbCredentials,
        isLoggedIn: state.isLoggedIn,
        isScreenLocked: state.isScreenLocked,
        drawData: state.drawData,
        scheduleData: state.scheduleData,
        screenSessionStarted: state.screenSessionStarted,
        forceLogout: state.forceLogout,
        isGoingToLogout: state.isGoingToLogout,

    }
};

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
    },
});

class MainClass extends Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    static longitude = 0.0;
    static latitude = 0.0;
    static hasLocation = false;
    static heading = 0;
    static drawlist_id = "";

    constructor(props) {
        super(props);
        this.fetchDrawDataTimer = null;
        this.fetchDrawDataTimeout = 30000; // 30 seconds
        this.fetchScheduleDataTimer = null;
        this.fetchScheduleDataTimeout = 30000; // 30 seconds

        this.sendDataTimer = null;
        this.sendDataInterval = 4000;// 60000; // 30 seconds 10000; // 10secs 

        this.loginTimerTimout = 1000;

        this.screenLockTimer = null;
        this.screenLockInterval = 60000; // 60000-1minute; 300k-5minutes // per minute 
        this.checkGPSInterval = 1000;
        this.watchdogTimerLogin = null;

        this.state = {
            ...this.state,
            timeoutDrawData: false,
            timeoutScheduleData: false,
            forceLogout: false
        }

        this.props.db.setLoadDataDispatcher(this.props.loadData)
        this.props.db.setStatDispatcher(this.props.loadStat)
        this.props.db.setSchedulesDispatcher(this.props.loadSchedules)
        this.props.db.setDrawRequestsDispatcher(this.props.loadDrawRequests)
        this.props.db.setdrawRequestDocumentsDataDispatcher(this.props.loadDrawRequestDocumentsData)
        this.props.db.setdrawRequestNotesDataDispatcher(this.props.loadDrawRequestNotesData)
        this.props.db.setWorkflowDataDispatcher(this.props.loadWorkflowData)
        this.props.db.setFutureOrdersDispatcher(this.props.loadFutureOrders)
        this.props.db.setSyncStatusDispatcher(this.props.setSyncStatus)
        this.props.db.setUploadLogsDispatcher(this.props.uploadLogs)
        this.props.db.setGeoLocationDataDispatcher(this.props.loadGeoLocationData)
        this.props.db.setLogEventDispatcher(this.props.logEvent)
        this.props.db.setCookies(this.props.cookies)
        navigator.setNewAppVersion = this.props.setNewAppVersion
        //this.noSleep = new NoSleep()

        // var data = "This is the secret message";

        // // Encrypt
        // var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), 'secret');

        // // Decrypt
        // var bytes  = CryptoJS.AES.decrypt(ciphertext.toString(), 'secret');
        // var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        ////console.log(ciphertext.toString());
        ////console.log(decryptedData);

        //var security = new Encryption();
        //var secret = security.encrypt("Hello")
        //console.log("THE RESULT " +  security.decrypt(secret));
        console.log("Instance: " + globals.INSTANCE);
        console.log("Clincian app version: " + globals.APP_VERSION);
        console.log("ReactJS version: " + React.version);
        console.log("SVR: " + ((globals.USE_PAMP_COUCHDB_SERVER) ? "[PAMP SVR]" : "MAIN"));
        //console.log(getVersion.plugins())
        //const gitInfo = GitInfo();
        console.log("Code: " + helper.getCodeVersion())
        //console.log("Branch: " + helper.getGitBranch())
        console.log("CNAME: " + globals.CODE_NAME)
        console.log("Data encryted: " + globals.ENCRYPT_DATA)
        console.log("Timezone: " + moment.tz.guess(true))
        console.log("OS: " + helper.getMobileOperatingSystem())

        console.log("OS: " + navigator.appVersion)
        console.log("isGeolocationAvailable: " + this.props.isGeolocationAvailable)
        console.log("isGeolocationEnabled: " + this.props.isGeolocationEnabled)



        //    var userDays = ["Sunday", "Monday", "Tuesday","Wednesday","Thursday","Friday","Saturday"]

        //    var theOutput = helper.generateDates(2, new Date("03/3/2021"), new Date("03/31/2022"), userDays)
        //    console.log(theOutput)

        //    console.log(gitInfo.branch);
        //    console.log(gitInfo.tags);
        //    console.log(gitInfo.commit.date);
        //    console.log(gitInfo.commit.hash);
        //    console.log(gitInfo.commit.message);
        //    console.log(gitInfo.commit.shortHash);
        //helper.uploadLogs()
        window.count = 0
        window.$location.allowed = true

    }

    isLoginExpired = (loginDateTime, currentDateTime) => {
        var start = moment(loginDateTime);
        var midNight = moment(start.format('MM/DD/YYYY') + ' 11:59:59 pm', 'MM/DD/YYYY hh:mm:ss a');
        //var midNight = moment(start.format('MM/DD/YYYY') + ' 07:19:00 pm','MM/DD/YYYY hh:mm:ss a');
        var end = moment(currentDateTime, 'MM/DD/YYYY HH:mm:ss');
        var diff = end.diff(midNight, 'seconds');
        //    console.log("diff")
        //    console.log( (diff) + " === " + (diff>=0))
        //    console.log("end")
        //    console.log(end._i)
        return (diff >= 0) ? true : false;
    }

    // componentWillMount() {
    //     // this.isLoggedIn = false;
    //     // if(this.props.dbCredentials === ""){ 
    //     //     this.connectToDB()
    //     // }

    // }

    connectToDB() {
        var cred = this.getCredentials()

        if (cred !== "") {
            this.props.setupNewDB(cred);
            // console.log("this.props.setupNewDB(cred); ")
            this.isLoggedIn = true;
        } else {
            this.isLoggedIn = false;
        }
    }

    componentDidUpdate() {

        if (globals.DEBUG_ALLOWED) {
            //  console.log("Main componentDidUpdate")
            //  console.log(window.$location)
            //  console.log(this.props.coords)
        }

        if (this.props.coords !== null) {
            // console.log("GPS set update.")
            //window.$location.latitude = this.props.coords.latitude
            //window.$location.longitude = this.props.coords.longitude
            //window.$location.heading = this.props.coords.heading

            if (window.$location.allowed === false) {
                window.$location.allowed = true
                this.forceUpdate()
            }

            // console.log(window.$location.latitude)

        }
    }

    componentDidMount() {
        //console.log("Main componentDidMount")
        //helper.activateCamera()
        this.setState({ ...this.state, timeoutDrawData: false, timeoutScheduleData: false });

        this.isLoggedIn = false;
        if (this.props.dbCredentials === "") {
            this.connectToDB()
        }

        // temporary comment out sendding of GPS location through ajax --Real
        this.sendDataTimerHandler();
        this.sendDataTimer = this.props.setInterval(this.sendDataTimerHandler, this.sendDataInterval);

        this.screenLockTimerHandler();
        this.screenLockTimer = this.props.setInterval(this.screenLockTimerHandler, this.screenLockInterval);

        this.checkGPSTimerHandler();
        this.screenLockTimer = this.props.setInterval(this.checkGPSTimerHandler, this.checkGPSInterval);

        this.watchdogTimerLoginHandler();
        this.watchdogTimerLogin = this.props.setInterval(this.watchdogTimerLoginHandler, this.loginTimerTimout);

        // // var webkitAlpha;
        // if (window.DeviceOrientationEvent) {
        //     window.addEventListener('deviceorientation', function(event) {
        //         var alpha;
        //         //Check for iOS property
        //         if (event.webkitCompassHeading) {
        //             alpha = event.webkitCompassHeading;
        //             //Rotation is reversed for iOS
        //            ////console.log("IOS " + alpha);
        //             Main.heading = alpha;
        //         }
        //         //non iOS
        //         else {
        //             alpha = event.alpha;
        //             // webkitAlpha = alpha;
        //             // if (!window.chrome) {
        //             //     //Assume Android stock (this is crude, but good enough for our example) and apply offset
        //             //     webkitAlpha = alpha - 270;
        //             // }
        //         }

        //         if (!alpha || isNaN(alpha)) {
        //             ////console.log("Compass not supported.")
        //         } else {
        //             Main.heading = alpha;
        //           // //console.log("alpha " + alpha);
        //         }
        //         ////console.log("webkitAlpha" + webkitAlpha);

        //     }, false);
        // } else {
        //     ////console.log("Compass not supported.");
        // }

        //    var returnFunction =  helper.readLocation(
        //         this.watchPosition_UpdateLocation, 
        //         this.watchPosition_GetError, 
        //         this.watchPosition_Get_Accuracy
        //     ) 
        //     console.log("watchPosition reference: " + returnFunction)

        //this.getLocation()
    }

    //// GPS watchPosition callbacks //
    watchPosition_UpdateLocation = (param) => {
        console.log("watchPosition_UpdateLocation")
        console.log(param)
    }

    watchPosition_GetError = (param) => {
        console.log("watchPosition_GetError")
        console.log(param)
    }

    watchPosition_Get_Accuracy = (param) => {
        console.log("watchPosition_Get_Accuracy " + param)
    }
    //// GPS watchPosition callbacks //



    getCurLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.showCurPosition, this.showCurError,
                {
                    enableHighAccuracy: true
                })
        } else {
            console.log("Geolocation is not supported by this browser.")
        }
    }

    showCurPosition = (position) => {
        console.log("Latitude: " + position.coords.latitude +
            "/rLongitude: " + position.coords.longitude)

        if (window.$location.allowed === false) {
            console.log("Refresing... GPS ON")
            window.$location.allowed = true
            this.forceUpdate()
        }
    }

    showCurError = (error) => {
        console.log(error)
        switch (error.code) {
            case error.PERMISSION_DENIED:

                if (window.$location.allowed === true) {
                    console.log("Refresing... GPS OFF")
                    window.$location.allowed = false
                    this.forceUpdate()
                }
                console.log("User denied the request for Geolocation.")
                break
            case error.POSITION_UNAVAILABLE:
                console.log("Location information is unavailable.")
                break
            case error.TIMEOUT:
                console.log("The request to get user location timed out.")
                break
            case error.UNKNOWN_ERROR:
                console.log("An unknown error occurred.")
                break
            default:
                break
        }
    }

    componentWillUnmount() {
        this.props.clearTimeout(this.sendDataTimer);
        this.props.clearTimeout(this.watchdogTimerLogin);
        this.props.clearTimeout(this.screenLockTimer);
        this.props.clearTimeout(this.checkGPSInterval);
    }

    setTimeIsUpDrawData = () => {
        this.setState({ ...this.state, timeoutDrawData: true });
    }

    setTimeIsUpScheduleData = () => {
        this.setState({ ...this.state, timeoutScheduleData: true });
    }

    deleteDatabase = (databse) => {
        try {
            new PouchDB(databse).destroy().then(function () {
                console.log("Database was destroyed sucessfully : " + databse);
            }).catch(function (err) {
                console.log("Failed to destroy database : " + databse);
            });
        } catch (exception) {
            // just ignore the error    
        }
    }

    watchdogTimerLoginHandler = () => {

        var cred = this.getCredentials()
        if (cred !== '' && this.props.forceLogout !== true) {
            if (cred.loginDateTime !== undefined) {
                if (this.isLoginExpired(cred.loginDateTime, new Date())) {
                    console.log("isLoginExpired TRUE")
                    clearInterval(this.watchdogTimerLogin)
                    this.props.setForceLogout(true)

                }
            }
        }
    }

    sendDataTimerHandler = () => {

        if (this.isLoggedIn) {
            if (global.DEBUG_ALLOWED) {
                console.log("window.$location.allowed", window.$location)
            }
            //this.getLocation();

            // if(!this.props.isGeolocationAvailable) {
            //     ////console.log("Your browser does not support Geolocation")
            // } else {
            //     ////console.log("Your browser supports Geolocation")
            //     ////console.log(this.props)
            //     if(!this.props.isGeolocationEnabled) {
            //         ////console.log("Geolocation is not enabled");
            //     } else {
            //         ////console.log("Geolocation ENABLED..");
            //         ////console.log(this.props.coords);
            //     }
            // }

            // call localhost to get GPS and Compas info
            // in the success callback call sendData 
        }
    }

    screenLockTimerHandler = () => {
        // console.log("screenLockTimerHandler")
        //this.getCurLocation()
        if (this.isLoggedIn && !this.props.isScreenLocked) {
            if (this.isTimeToLockScreen()) {
                this.props.setScreenLockStatus(true)
            }
        }
    }

    checkGPSTimerHandler = () => {
        //this.getLocation()
        return
        // console.log("checkGPSTimerHandler")
        // console.log("checkGPSTimerHandler isGeolocationAvailable: " + this.props.isGeolocationAvailable)
        // console.log("checkGPSTimerHandler isGeolocationEnabled: " + this.props.isGeolocationEnabled)
        // // mon
        //this.getCurLocation()
        // if( this.isLoggedIn && !this.props.isScreenLocked) {
        //     if(this.isTimeToLockScreen()) {
        //         this.props.setScreenLockStatus(true)
        //     }
        // }

    }

    // getLocation = () => {
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition(this.showPosition);
    //     } else { 
    //       //console.log("Geolocation is not supported by this browser.");
    //     }
    // }

    showPosition = (position) => {
        Main.latitude = position.coords.latitude;
        Main.longitude = position.coords.longitude;
        Main.hasLocation = true;
        //console.log(position)
        return
        //   const { cookies } = this.props;
        //   cookies.set('myCurrentLocation', {latitude:position.coords.latitude, longitue:position.coords.longitude });

        //   //console.log("Latitude:test " + position.coords.latitude + 
        //     "<br>Longitude: " + position.coords.longitude);

        // var token = "";

        // var cred = this.getCredentials()
        // token = cred.token;
        // var phleb_id = cred.phleb_id;
        // var timezone = moment.tz.guess();
        // var data = {
        // 	"phleb_id": phleb_id,
        // 	"latitude": position.coords.latitude,
        // 	"longitude": position.coords.longitude,
        //     "heading": Main.heading,
        //     "drawlist_id" : Main.drawlist_id,
        // 	"client_timestamp": Date.now(),
        // 	"client_timezone": timezone
        // }

        // this.sendGPSData(data,token);
    }

    getCredentials = () => {
        var cred;
        const { cookies } = this.props
        try {
            cred = cookies.get('credentials') || ''

            if (cred === "") return ""

            var security = new Encryption()
            var decryptedCred = JSON.parse(security.decrypt(cred))
            return decryptedCred
        } catch (e) {
            cookies.remove('credentials')
            return ""
        }
    }

    sendGPSData = (data, token) => {
        var today = new Date();
        var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        ////console.log("Sending GPS inpormation to the command center.");
        data.datetime = date;
        ////console.log(data);

        $.ajax({
            beforeSend: function (xhr, settings) {
                xhr.setRequestHeader('Authorization', 'Token ' + token);
            },
            url: globals.SERVER + "/api/v1/phleb/coordinates/",
            // url: "https://telopoint.org:7005/kapi/v1/client_gps",
            method: "POST",
            data: data,
            success: function (response) {
                ////console.log(response);
            },
            error: function (response) {
                ////console.log(response);
            },
        });

        // var url = '';
        // // url = "https://dev.telopoint.com:8989/api/v1/phleb/coordinates/";
        // url = 'http://telopoint.org:8888/api/v1/client_gps';
        // data.datetime = date;
        // var ajaxRequest = $.ajax({
        //     url: url,
        //     method: "POST",
        //     data: {data: data}
        // });

        // sendGPSData = (data,token) => {

        //   //console.log("Sending GPS inpormation to the command center.");
        //   //console.log(data);
        //     var ajaxRequest = $.ajax({
        //         // beforeSend: function(xhr, settings) {
        //         //      xhr.setRequestHeader('Authorization','Token ' + token);  
        //         // },
        //         url: "https://dev.telopoint.com:8989/api/v1/phleb/coordinates/",
        //         method: "POST",
        //         data: data
        //     });

        // ajaxRequest.done(function( response ) {
        //    //console.log("Successfully sent GPS information.");
        //    //console.log(response);
        // });

        // ajaxRequest.fail(function( jqXHR, textStatus ) {
        //    //console.log("Error in sending GPS information.");
        //    //console.log(jqXHR);
        //    //console.log(textStatus);
        // });
    }

    logOut = () => {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/login" render={(props) => <Login {...props} />} />
                    <Route path="/logout" render={(props) => <Logout {...props} />} />
                </Switch>
            </BrowserRouter>);
    }

    static isSiteComplete = (site) => {
        var complete = true;
        site.forEach(function (patient) {
            if (patient.patient_draw[0].status === "remaining") {
                complete = false;
            }
            ////console.log(patient.patient_draw[0].status);
        });
        return complete;
    }

    isTimeToLockScreen = () => {
        var started = moment(this.props.screenSessionStarted)
        var currentTime = moment(Date.now())
        //console.log("Min " + currentTime.diff(started, "minutes"))
        //console.log("Seconds " + currentTime.diff(started, "seconds"))
        //console.log("Lock time count " + currentTime.diff(started, "minutes") + " > " + SCREEN_LOCK_TIMEOUT)
        if (currentTime.diff(started, "minutes") >= SCREEN_LOCK_TIMEOUT) {
            //console.log("Locking NOW " + currentTime.diff(started, "minutes"))
            return true
        }

        return false
    }
    onRefresh = () => {
        console.log("Refreshing... ")
        this.setState({ ...this.state })
    }
    //  getsLocation=()=>{
    //      console.log("getLocation")
    //     navigator.geolocation.getCurrentPosition(function (pos) {
    //         var lat = pos.coords.latitude;
    //         var lng = pos.coords.longitude;
    //         if (lat == null) {
    //             console.log("GPS not activated!");
    //         } else {
    //             console.log("Latitude: "+ lat + " , Longitude: " + lng );
    //         }
    //     });
    // }

    render() {

        // console.log("!!!!!!!!!! NEED TO REMOVE THIS LINE
        // JUST TO BYPASS RELOAD PIN PAGE")
        // return (<MobileRouter />)

        if (globals.DEBUG_ALLOWED) {
            console.log("== MAIN ===")
        }

        // if (globals.DEBUG_ALLOWED) {
        //     console.log("RENDER isGeolocationAvailable: " + this.props.isGeolocationAvailable)
        //     console.log("RENDER isGeolocationEnabled: " + this.props.isGeolocationEnabled)
        //     console.log("window.$location.latitude:" + window.$location.latitude)
        //     console.log("window.$location.longitude:" + window.$location.longitude)
        // }

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then(registration => {
                if (registration.waiting) {
                    console.log("NEW APP RELEASE PLEASE CLICK UPDATE IN SETTINGS MENU.");
                    navigator.newServiceWorker = registration.waiting;
                    this.props.setNewAppVersion(true)
                }
            })
        }

        var drawData;
        var scheduleData;
        const { cookies } = this.props;


        var cred = this.getCredentials()
        var pincode = cred.pincode

        //console.log("MAIN render()")
        //console.log(this.props)
        //console.log("cred")
        //console.log(cred)
        //console.log("pincode")
        //console.log(pincode)


        this.isLoggedIn = cred !== "" ? true : false

        try {
            drawData = JSON.parse(JSON.stringify(this.props.drawData));
        } catch (e) {
            drawData = {};
        }

        try {
            scheduleData = JSON.parse(JSON.stringify(this.props.scheduleData));
        } catch (e) {
            scheduleData = {};
        }

        if (!this.isLoggedIn && !this.props.isGoingToLogout) {
            //console.log("if(!this.isLoggedIn && !this.isGoingToLogout)")
            //console.log( this.isLoggedIn  + " " + this.props.isGoingToLogout)

            return (
                <div>
                    <BrowserRouter>
                        <Switch>
                            <Route path="/login" render={(props) => <Login {...props} />} />
                            <Redirect to="/login" />
                        </Switch>
                    </BrowserRouter>
                </div>
            );
        }

        // if(this.state.forceLogout === true) {
        //    console.log("  if(this.state.forceLogout === true) {")
        //    this.setState({...this.state, forceLogout:false},
        //         ()=>{
        //             return(
        //                 <div>
        //                     <BrowserRouter>
        //                         <Switch>
        //                             <Route path="/logout"  render={(props) => <Logout reason="forced" {...props}/>}  />
        //                             <Redirect to="/logout" />
        //                         </Switch>
        //                     </BrowserRouter>
        //                 </div>
        //             ) 
        //         }
        //     )
        // }

        if (this.props.forceLogout === true) {
            console.log("this.props.forceLogout === true")
            //this.props.setForceLogout(false)

            return (
                <div>
                    <BrowserRouter>
                        <Switch>
                            <Route path="/logout/:reason" render={(props) => <Logout {...props} />} />
                            <Redirect to="/logout/forced" />
                        </Switch>
                    </BrowserRouter>
                </div>
            )

        }

        if (pincode === "") {
            return (
                <div>
                    <BrowserRouter>
                        <Switch>
                            <Route path="/create_pin_code" render={(props) => <CreatePinCode {...props} />} />
                            <Redirect to="/create_pin_code" />
                        </Switch>
                    </BrowserRouter>
                </div>
            );

        } else {

            if (this.props.isScreenLocked && this.props.forceLogout !== true) {
                return (
                    // <UnlockScreen onRefresh={this.onRefresh} {...this.props}/>
                    <div>
                        <BrowserRouter>
                            <Switch>
                                <Route path="/unlock_screen" render={(props) => <UnlockScreen {...props} />} />
                                <Redirect to="/unlock_screen" />
                            </Switch>
                        </BrowserRouter>
                    </div>
                );
            }
        }

        if (window.$location.allowed === false) {
            return (
                // <UnlockScreen onRefresh={this.onRefresh} {...this.props}/>
                <div>
                    <BrowserRouter>
                        <Switch>
                            <Route path="/gps_lock" render={(props) => <GPSLock {...props} />} />
                            <Redirect to="/gps_lock" />
                        </Switch>
                    </BrowserRouter>
                </div>
            );
        }

        if (this.isTimeToLockScreen()) {
            this.props.setScreenLockStatus(true)
        }

        if (drawData.hasOwnProperty('quota_exceeded')) {
            this.props.clearTimeout(this.fetchDrawDataTimer);
            this.fetchDrawDataTimer = null;
            return (
                <div>
                    {this.logOut()}
                    <LowDiskSpaceScreen />
                </div>
            );
        };

        if (this.props.dbCredentials === "") {
            this.connectToDB()
        }

        if (scheduleData.schedules === undefined) {
            if (this.fetchScheduleDataTimer == null) {
                this.fetchScheduleDataTimer = this.props.setTimeout(this.setTimeIsUpScheduleData, this.fetchScheduleDataTimeout)
            }
            return (
                <div>
                    {this.logOut()}
                    <LoadingSpinner />
                </div>

            );
        }

        this.props.clearTimeout(this.fetchDrawDataTimer);
        this.fetchDrawDataTimer = null;

        this.props.clearTimeout(this.fetchScheduleDataTimer);
        this.fetchScheduleDataTimer = null;

        var selectedSite = cookies.get('selected_site') || '';
        if (selectedSite === "") {
            ////console.log("Wala pang naka select na site");
            var BreakException = {};

            try {
                drawData.draw_list.forEach(function (site) {
                    var siteComplete = Main.isSiteComplete(site.patient_list);
                    ////console.log("Site " + site.id + " " +  siteComplete);
                    if (siteComplete === false) {
                        //cookies.set('selected_site',  site.id, { path: '/'}); // , { path: '/', maxAge: 57600 }
                        throw BreakException;
                    }
                });
            } catch (e) {
                ////console.log("Just exiting the loop for the iste");
            }
        } else {
            ////console.log("Meron na naka select na site");
        }

        try {
            Main.drawlist_id = drawData.id;
        } catch (e) {

        }
        // console.log("Routing to MobileRouter")
        if (true) { //  if(isMobileOnly) { // Raymond: temporary just for the up coming big demo
            return (<MobileRouter />);
        } else {
            return (<TabletRouter />);
        }


    }
}

MainClass.propTypes = {
    classes: PropTypes.object.isRequired,
};

const Main = withRouter(ReactTimeout(withCookies(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MainClass)))));
// export default Main;
// export default geolocated({
//     positionOptions: {
//         enableHighAccuracy: true,
//         maximumAge: 0,
//         timeout: Infinity,
//     },
//     watchPosition: true,
//     userDecisionTimeout: 1000, // 30secs
//     isOptimisticGeolocationEnabled: false
// })(Main);

// export default geolocated({
//     positionOptions: {
//         enableHighAccuracy: true,
//         maximumAge: 2000,
//         timeout: Infinity,
//     },
//     watchPosition: true,
//     userDecisionTimeout: null,
//     suppressLocationOnMount: false,
//     geolocationProvider: navigator.geolocation,
//     isOptimisticGeolocationEnabled: false
// })(Main);
// export default geolocated({
//     positionOptions: {
//         enableHighAccuracy: false,
//     },
//     userDecisionTimeout: 5000,
//     isOptimisticGeolocationEnabled:true
// })(Main);
export default Main
