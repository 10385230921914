
import * as globals from 'components/common/GlobalConstants';
const styles = theme => ({
    root: {
        // display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
    },
    overrideBlue: {
        background: globals.BLUE_GRADIENT_BG,
    },
    calendar: {
        border: "0px solid black",
    },
    customApplicationBarHeader: {
        padding: "7px 17px",
        marginLeft: "-15px",
        marginRight: "-15px"
    },
    schedulesMainContainer: {
        //borderRadius: 10,
        margin: 15,
       // boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 6px 30px 0 rgba(0, 0, 0, 0.11)',
    },
    switchViewContainer: {
        padding: "0px 16px",
        position: "relative"
    },
    listULMainHeader: {
        marginTop: 25
    },
    schedulesMainHeader: {
        color: "#707070 !important",
        fontSize: '24px !important',
        fontWeight: 'bold',
        // padding: "15px",
        // marginTop: 25
    },
    schedulesHeader: {
        color: "#707070 !important",
        fontFamily: "Helvetica, Arial, sans-serif !important",
        fontWeight: "bold !important"
    },
    schedulesContent: {
        paddingLeft: 16,
        paddingRight: 16,
        // height: 417,
        // marginTop: 40,
    },
    dialogPaper: {
        minWidth: "260px",
        maxHeight: "100%",
        margin: "5x !important", // 18
    },
    dialogPaperTimeContainer: {
        padding: 0,
        overflow: "hidden"
    },
    buttons: {
        textTransform: 'none',
        borderRadius: 0,
    },
    schedulesContainer: {
       // borderTop: "1px solid #DCDCDC",
        // padding: "7px 17px",
        verticalAlign: "middle"
    },
    listUL: {
        paddingTop: 0
    },
    timeContainer: {
        textAlign: 'center',
        // paddingRight: 5,
        width: "45%"
    },
    displayTime: {
        //border: "1px solid black",
        padding: "5px 17px",
        // marginBottom: 5,
        fontSize: '14px !important',
        minHeight: "20px",
        maxHeight: "20px",
        // minWidth: "95px",
        // maxWidth: "95px",
        border: "1px solid #EFEFEF",
        backgroundColor: "#EFEFEF",
    },
    labels: {
        marginTop: "15px",
        color: "#777777",
    },
    labelTo: {
        color: "#777777",
        width: "10%",
        textAlign: "center"
        // margin: 10,
        // marginLeft: 5,
    },
    disabledLabels: {
        color: "#dadada"
    },
    floatRight: {
        float: "right"
    },
    switchViewButton: {
        backgroundColor: "white",
        color: "#777777",
        borderRadius: 0,
        padding: "3px 10px",
        minWidth: 48,
        textTransform: 'none',
       // right: "-10px",
        // '&:not([class*="fabBlue"]):not([class*="fabOrange"]):hover':{
        //     backgroundColor: "#e0e0e0 !important",
        // }
    },
    whiteButton: {
        backgroundColor: "white",
        color: "#777777",
    },
    disabledWhiteButton: {
        backgroundColor: "rgba(247, 247, 247, 0.68) !important;",
    },
    // switchViewButtonBlueSelected: {
    //     backgroundColor: "#68CBEF !important",
    //     color: "white !important",
    // },
    // switchViewButtonOrangeSelected: {
    //     backgroundColor: "#FFAA60 !important",
    //     color: "white !important",
    // },
    switchViewButtonLeft: {
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
    },
    switchViewButtonRight: {
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
    },
    switchViewButtonLabel: {
        // width: 15
    },

    // presetDaysContainer: {
    //     fontSize: "12px !important",
    //     marginLeft: 7
    // },

    presetDaysContainer: {
        textAlign: "center",
        padding: "10px 10px",
        marginBottom: 2
    },
    fab: {
        color: "#707070",
        fontWeight: "normal",
        fontSize: "14px !important",
        background: "none",
        paddingTop: 0,
        paddingRight: 0,
        width: 32,
        height: 32,
        boxShadow: "none",
        display: "table-cell",
        verticalAlign: "middle",
        borderRadius: "50%"
    },
    fabDisabled: {
        color: "#dadada"
    },
    fabBlue: {
        color: "white !important",
        backgroundColor: "#68CBEF !important",
    },
    fabOrange: {
        color: "white !important",
        backgroundColor: "#FFAA60 !important",
    },
    fabBlueDisabled: {
        color: "white !important",
        backgroundColor: "#d9f5ff !important",
    },
    fabOrangeDisabled: {
        color: "white !important",
        backgroundColor: "#ffefe0 !important",
    },
    marginRight: {
        marginRight: 10
    },
    allDayCheckbox: {
        padding: "3px 8px 6px 12px",
        // marginBottom: 2
    },
    repeatButton: {
        marginBottom: 30
    },
    checkboxCheckedBlue: {
        color: "#68CBEF"
    },
    checkboxCheckedOrange: {
        color: "#FFAA60"
    },
    noScheduleSettingsNote: {
        margin: 15,
        marginTop: 35,
        textAlign: 'center'
    },
    onlineOnlyWarning: {
        color: "red !important",
        textAlign: "center",
        padding: "0px 10px",
        marginBottom: 20
    },
    deleteScheduleText: {
        textAlign: "center"
    },
    deleteScheduleWarningText: {
        marginTop: 10,
    },
    deleteScheduleWarningIcon: {
        color: "orange",
        position: "relative",
        top: "4px"
    },
    lockIcon: {
        position: "absolute",
        right: "16px",
        top: "3px",
        // marginLeft: "-20px",
        // marginTop: "10px",
        // fontSize: "14px",
        color: "#707070"
    },
    everyNthWeekField: {
        margin: "0px 5px",
        width: "60px",
        fontSize: 14,
        '& input': {
            textAlign: "center",
            padding: "7px 0px !important"
        }
    },
    timePickerContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    allDayFormGroup: {
        display: 'block'
    },
    timePickerPanel: {
        '& input': {
            fontSize: "14px !important",
            padding: "5px",
        },
        '& ul': {
            fontSize: "14px !important",
        },
        '& ul > li': {
            padding: "5px 0 5px 16px !important",
            height: "unset !important"
        }
    },
    timeHasError: {
        fontSize: "14px !important",
        color: "red !important",
        marginTop: 5
    },
    timePickerField: {
        border: "1px solid #EFEFEF",
        '& input': {
            textAlign: "center !important",
            fontSize: "14px !important",
            border: "none !important",
            borderRadius: "0px !important",
            backgroundColor: "#EFEFEF",
            padding: "6px 17px",
        }
    },
    timePickerFieldError: {
        border: "1px solid red",
    }
});

export default styles;