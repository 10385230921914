import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
import classNames from 'classnames'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import ApplicationBar from 'components/common/workflow/ApplicationBar'

import DetailsHeader from './DetailsHeader'
import NewPatientForm from './NewPatientForm'

const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const NewPatientFunctionClass = (props) => {
    const { classes } = props

    return (
        <>
            <div className={classes.root}>
                <main className={classes.content}>
                    <div className={classNames(classes.mainContainer)}>
                        <ApplicationBar />

                        <DetailsHeader />
                        <NewPatientForm />
                    </div>
                </main>
                 
            </div>
        </>
    )
}

const NewPatient = withCookies(
    connect(mapStateToProps)(
        withStyles(styles, { withTheme: true })(NewPatientFunctionClass)
    )
)
export default NewPatient
