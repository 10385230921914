import { secretbox } from "tweetnacl";


class Encryption {

    constructor () {
        this.nacl = require('tweetnacl')
        this.utils = require('tweetnacl-util')
        this.nonce = this.nacl.randomBytes(24)
        this.secretKey = Buffer.from('_THIS_IS_MY_32_CHARS_SECRET_KEY_', 'utf8')
    }

    encrypt =(value)=> {
        const secretData = Buffer.from(value, 'utf8')
        const encrypted = this.nacl.secretbox(secretData, this.nonce, this.secretKey)
        const result = `${this.utils.encodeBase64(this.nonce)}:${this.utils.encodeBase64(encrypted)}`
        return result;
    }

    decrypt =(value) =>{
        const original = this._decrypt(value, this.secretKey);
        return original; 
    }

    _decrypt = (messageWithNonce, key) => {
        try {
        var arrMessage = messageWithNonce.split(":");
        var n = this.utils.decodeBase64(arrMessage[0]);
        var m = this.utils.decodeBase64(arrMessage[1]);
        
        const decrypted = secretbox.open(m, n, key);
        if (!decrypted) {
          throw new Error("Could not decrypt message");
        }
        const base64DecryptedMessage = this.utils.encodeUTF8(decrypted);
        return base64DecryptedMessage;
        } catch(e) {
            return ""
        }
    }
}

export default Encryption