import React from 'react';
import PropTypes from 'prop-types';
import './styles/whiteFlash.css';

export const WhiteFlash = ({ isShowWhiteFlash, width, height }) => {
  const flashDoTransition = isShowWhiteFlash ? 'do-transition' : '';
  const flashClasses = `${flashDoTransition} normal`;
  const computedWidth = width ? width : 300;
  const computedHeight = height ? height : 300;

  return (
    <div id="white-flash" className={flashClasses} style={{width: computedWidth, height: computedHeight}}></div>
  );
};

WhiteFlash.propTypes = {
  isShowWhiteFlash: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired
};

export default WhiteFlash;
