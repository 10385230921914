import {
    SETUP_NEW_DB,
    UPDATE_DRAW_ITEM,
    LOAD_DATA_FROM_POUCHDB,
    SET_LOGGED_IN_STATUS,
    SET_SCREEN_LOCK_STATUS,
    SET_SCREEN_SESSION_STARTED,
    MP_SIGN,
    UPDATE_PATIENT_DRAW,
    VERIFY_PATIENT_NAME,
    VERIFY_PATIENT_DOB,
    UPDATE_PATIENT_ROOM_NUMBER,
    NEW_STAT_ARRIVED,
    UPDATE_SITE_ORDERING,
    ADD_SCHEDULE,
    UPDATE_SCHEDULES,
    DELETE_SCHEDULE,
    SCHEDULES_DATA_ARRIVED,
    UPDATE_SCHEDULE_SETTINGS,
    ACCEPT_DRAW_REQUEST,
    DRAW_REQUEST_ARRIVED,
    DRAW_REQUEST_DOCUMENTS_ARRIVED,
    DRAW_REQUEST_NOTES_ARRIVED,
    WORKFLOW_DATA_ARRIVED,
    CREATE_SITE_WORKLOW_DEFAULT_VALUES,
    UPDATE_WORKFLOW_VALUE,
    UPDATE_SERVICED_ZIPCODES,
    DELETE_NOTE,
    SAVE_NOTE,
    FUTURE_ORDERS_ARRIVED,
    SET_ONLINE_STATUS,
    SAVE_DRAW_REQUEST_DOCUMENT,
    DELETE_DRAW_REQUEST_DOCUMENT,
    SET_SYNC_STATUS,
    LOG_EVENT,
    DELETE_LOGS,
    SET_FORCE_LOGOUT,
    CLEAR_ALL_DATA,
    DO_LOGOUT,
    UPDATE_LOGS,
    NEW_APP_VERSION,
    SET_CAMERA_AVAILABLE,
    GEO_LOCATION_DATA_ARRIVED,
    ADD_GEO_LOCATION,
    SET_SELECTED_SCHEDULE_VIEW,
    START_APPOINTMENT,
    SET_PATIENTS_DOESNT_EXIST_IN_APPT,
    SET_PATIENTS_DOESNT_EXIST_IN_APPT_INDICATOR,
    SET_ADDED_EXISTING_PATIENT_IN_APPT,
    SET_ADD_EXISTING_PATIENT_IN_APPT_INDICATOR,
    SET_PATIENT_ID_BEING_ADDED
} from "../constants/action-types"
import * as helper from 'components/common/Helper.js'
import * as globals from 'components/common/GlobalConstants'
import axios from 'axios'
import moment from 'moment-timezone'
// export const addArticle = article => ({ type: ADD_ARTICLE, payload: article })
// export const deleteArticle = article => ({ type: DELETE_ARTICLE, payload: article})

export const updateDrawItemStatus = drawItem => ({ type: UPDATE_DRAW_ITEM, payload: drawItem })
export const loadData = drawData => ({ type: LOAD_DATA_FROM_POUCHDB, payload: drawData })
export const setupNewDB = credentials => ({ type: SETUP_NEW_DB, payload: credentials })
export const setLoggedInStatus = status => ({ type: SET_LOGGED_IN_STATUS, payload: status })
export const setScreenLockStatus = status => ({ type: SET_SCREEN_LOCK_STATUS, payload: status })
export const setScreenSessionStarted = time => ({ type: SET_SCREEN_SESSION_STARTED, payload: time })

export const updatePatientDrawStatus = drawItem => ({ type: UPDATE_PATIENT_DRAW, payload: drawItem })
export const mpSign = signData => ({ type: MP_SIGN, payload: signData })
export const verifyPatientName = verifiedPatientNameData => ({ type: VERIFY_PATIENT_NAME, payload: verifiedPatientNameData })
export const verifyPatientDoB = verifiedPatientDoBData => ({ type: VERIFY_PATIENT_DOB, payload: verifiedPatientDoBData })

export const updatePatientRoomNumber = patient => ({ type: UPDATE_PATIENT_ROOM_NUMBER, payload: patient })

export const loadStat = stat => ({ type: NEW_STAT_ARRIVED, payload: stat })

export const loadSchedules = schedules => ({ type: SCHEDULES_DATA_ARRIVED, payload: schedules })

export const updateSiteOrdering = drawList => ({ type: UPDATE_SITE_ORDERING, payload: drawList })

export const addSchedule = schedule => ({ type: ADD_SCHEDULE, payload: schedule })

export const updateSchedules = schedule => ({ type: UPDATE_SCHEDULES, payload: schedule })

export const deleteSchedule = schedule => ({ type: DELETE_SCHEDULE, payload: schedule })

export const updateScheduleSettings = schedule_settings => ({ type: UPDATE_SCHEDULE_SETTINGS, payload: schedule_settings })

export const acceptDrawRequest = draw_request => ({ type: ACCEPT_DRAW_REQUEST, payload: draw_request })

export const loadDrawRequests = draw_requests_data => ({ type: DRAW_REQUEST_ARRIVED, payload: draw_requests_data })

export const loadDrawRequestDocumentsData = draw_request_documents => ({ type: DRAW_REQUEST_DOCUMENTS_ARRIVED, payload: draw_request_documents })

export const loadDrawRequestNotesData = draw_request_notes => ({ type: DRAW_REQUEST_NOTES_ARRIVED, payload: draw_request_notes })

export const loadWorkflowData = workflow_data => ({ type: WORKFLOW_DATA_ARRIVED, payload: workflow_data })
export const createSiteWorkflowDefaultValues = site => ({ type: CREATE_SITE_WORKLOW_DEFAULT_VALUES, payload: site })
export const updateWorkflowValue = value => ({ type: UPDATE_WORKFLOW_VALUE, payload: value })

export const loadFutureOrders = future_orders_data => ({ type: FUTURE_ORDERS_ARRIVED, payload: future_orders_data })

export const loadGeoLocationData = geoLocationData => ({ type: GEO_LOCATION_DATA_ARRIVED, payload: geoLocationData })

export const addGeoLocation = geoLocationData => ({ type: ADD_GEO_LOCATION, payload: geoLocationData })

export const updateServicedZipcodes = serviced_zipcodes => ({ type: UPDATE_SERVICED_ZIPCODES, payload: serviced_zipcodes })

export const saveNote = note => ({ type: SAVE_NOTE, payload: note })

export const deleteNote = note => ({ type: DELETE_NOTE, payload: note })

export const saveDrawRequestDocument = document => ({ type: SAVE_DRAW_REQUEST_DOCUMENT, payload: document })

export const deleteDrawRequestDocument = document => ({ type: DELETE_DRAW_REQUEST_DOCUMENT, payload: document })

export const setOnlineStatus = value => ({ type: SET_ONLINE_STATUS, payload: value })

export const setSyncStatus = value => ({ type: SET_SYNC_STATUS, payload: value })

export const logEvent = value => ({ type: LOG_EVENT, payload: value })

export const deleteLogs = value => ({ type: DELETE_LOGS, payload: value })

export const setForceLogout = value => ({ type: SET_FORCE_LOGOUT, payload: value })

export const clearAllData = value => ({ type: CLEAR_ALL_DATA, payload: value })

export const doLogout = logoutInfo => ({ type: DO_LOGOUT, payload: logoutInfo })

export const updateLogs = logs => ({ type: UPDATE_LOGS, payload: logs })

export const setNewAppVersion = value => ({ type: NEW_APP_VERSION, payload: value })

export const setCameraAvailable = value => ({ type: SET_CAMERA_AVAILABLE, payload: value })

export const setSelectedScheduleView = value => ({ type: SET_SELECTED_SCHEDULE_VIEW, payload: value })

export const startAppointment = value => ({ type: START_APPOINTMENT, payload: value })

export const setPatientsDoesntExistsInAppointment = value => ({ type: SET_PATIENTS_DOESNT_EXIST_IN_APPT, payload: value })
export const setPatientsDoesntExistsInAppointmentIndicator = value => ({ type: SET_PATIENTS_DOESNT_EXIST_IN_APPT_INDICATOR, payload: value })

export const setAddedExistingPatientInAppointment = value => ({ type: SET_ADDED_EXISTING_PATIENT_IN_APPT, payload: value })
export const setAddExistingPatientInAppointmentIndicator = value => ({ type: SET_ADD_EXISTING_PATIENT_IN_APPT_INDICATOR, payload: value })
export const setPatientBeingAdded = value => ({ type: SET_PATIENT_ID_BEING_ADDED, payload: value })

export const getPatientsDoesntExistsInAppointment = (hashed_id) => {
    const creds = helper.getCredentials()
    return (dispatch) => {
        dispatch(setPatientsDoesntExistsInAppointmentIndicator({ active: true }))
        return axios.get(globals.API_SERVER + '/cln/api/v2/appointments/' + hashed_id + '/patients/?np=true', {
            headers: {
                "Accept": "application/json",
                "Authorization": "Token " + creds.token
            },
            crossdomain: true
        }).then(function (response) {
            console.log("getPatientsDoesntExistsInAppointment success response")
            console.log(response)
            dispatch(setPatientsDoesntExistsInAppointment(response.data.data))
        }).catch(function (error) {
            console.log("getPatientsDoesntExistsInAppointment error")
            console.log(error)
        }).finally(function () {
            dispatch(setPatientsDoesntExistsInAppointmentIndicator({ active: false }))
        })
    }
}

export const addExistingPatientInAppointment = (hashed_id, patient_id) => {
    // sample payload
    // {
    //     "patients": [
    //         {
    //             "id": "ce5a34a1-b0a0-46b9-8264-e9b19b7e567b"
    //         },
    //         {
    //             "id": "59d94b50-e7b3-46f0-af7f-f1b5b8105f7b"
    //         }
    //     ]
    // }
    const payload = {
        "patients": [
            { "id": patient_id }
        ]
    }

    const creds = helper.getCredentials()
    return (dispatch) => {
        dispatch(setPatientBeingAdded(patient_id))
        dispatch(setAddExistingPatientInAppointmentIndicator({ active: true }))

        return axios.post(globals.API_SERVER + '/cln/api/v2/appointments/' + hashed_id + '/orders/', payload, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Token " + creds.token
            },
            crossdomain: true
        }).then(function (response) {
            console.log("addExistingPatientInAppointment success response")
            console.log(response)
            dispatch(setAddedExistingPatientInAppointment(response.data.data))
        }).catch(function (error) {
            console.log("addExistingPatientInAppointment error")
            console.log(error)
        }).finally(function () {
            dispatch(setPatientBeingAdded(null))
            dispatch(setAddExistingPatientInAppointmentIndicator({ active: false }))
        })
    }
}

//--------------- UPLOAD LOGS -----------------//
// export const uploadLogs = (logs) => {
//     // console.log("changePassword")
//     // console.log(data)
//     return (dispatch) => {
//        // dispatch(setAccountInfoNotification(true))
//         return axios.post(API_SERVER + '/api/v1/change_password', logs, {
//             "headers": {
//                 "Accept": "application/json",
//                 "APIToken": Cookies.get('USER_TOKEN')
//             },
//         }).then(function(response) {
//             dispatch(setAccountInfoNotification(response))
//         })
//         .catch(function(error) {
//             console.log(error)
//             dispatch(setAccountInfoNotification(error))
//         })
//     }
// }

export const uploadLogs = () => {

    return (dispatch) => {
        // console.log("Uploading logs..")
        var logs = helper.getLogs("logs")
        var log_timezone = moment.tz.guess(true) || "America/New_York"
        var log_timestamp = moment().unix()
        try {

            logs = JSON.parse(logs)
            // console.log("logs")

            var logsToUpload = {}

            // Filter recorded logs, only unrecord to be submitted //    
            Object.keys(logs).forEach((item) => {
                if (logs[item].recorded === false) {
                    logsToUpload[item] = logs[item]
                }
            })

            // console.log(logsToUpload)
            var headers = {}
            if (globals.USE_PAMP_COUCHDB_SERVER) {
                headers = {
                    "Accept": "application/json",
                    "username": "rphleb",
                    "client-app-version": globals.APP_VERSION,
                    "client-timezone": log_timezone,
                    "client-timestamp": log_timestamp
                }
            } else {
                headers = {
                    "Accept": "application/json",
                    "client-app-version": globals.APP_VERSION, // - version of the app
                    "client-timezone": log_timezone,   // - timezone of the browser
                    "client-timestamp": log_timestamp  // - unix timestamp at the time of submission attempt. always refresh this to time it is 
                }
            }
            axios.post(globals.LOG_SERVER, logsToUpload, {
                "headers": headers,
            }).then(function (response) {
                // console.log(response.data.data)
                // console.log(response.data.data.length)
                var logs = helper.getLogs("logs")

                try {
                    logs = JSON.parse(logs)
                } catch (e) {
                    console.log("Something wrong with log data.")
                    console.log(e)
                }

                response.data.data.forEach((log_id) => {
                    // console.log("for each KEY " + log_id)
                    if (logs[log_id] !== undefined) {
                        logs[log_id].recorded = true
                        // console.log("SET " + log_id)
                    }

                })

                dispatch(updateLogs(logs))

            })
                .catch(function (error) {
                    console.log(error)
                })

        } catch (e) {
            console.log(e)
        }
    }

} // uploadLogs 



export const isEmptyObject = obj => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false
    }
    return true
}
