import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
// import classNames from 'classnames'

import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import * as globals from 'components/common/GlobalConstants'
import {
    startAppointment,
} from "js/actions/index"

const mapDispatchToProps = dispatch => {
    return {
        startAppointment: value => dispatch(startAppointment(value)),
    }
}

const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const OrderListItemFunctionClass = (props) => {
    const { classes, order, workflow_type, history, match, startAppointment } = props
    const appointment_id = match.params.appointment_id

    var selected_workflow_type = globals.v2.ALLOWED_WORKFLOW_TYPE.find(obj => {
        return obj.value === workflow_type
    })

    if (selected_workflow_type === undefined) {
        selected_workflow_type = globals.v2.ALLOWED_WORKFLOW_TYPE[0]
    }

    const startApptOrder = () => {
        const payload = {
            "siteId": appointment_id
        }
        startAppointment(payload)
        history.push("/appointments/today/" + appointment_id + "/" + selected_workflow_type.key + "/" + order.id + "/1")
    }

    // const tests = ["IV Infusion", "COVID Test", "COVID Vaccine"]
    // const selected_test = tests[Math.floor(Math.random() * tests.length)]
    // const [startButtonLabel, setStartButtonLabel ] = useState("Start")    
    // const [startButtonColor, setStartButtonColor ] = useState("")    
    const displayStates = {
        "remaining": {
            label: "Start",
            startButtonStyle: classes.orderStartButton,
            orderNameStyle: classes.orderNameRemaining,
            orderTestStyle: classes.orderTestRemaining
        }, "done": {
            label: "Completed",
            startButtonStyle: classes.orderCompletedButton,
            orderNameStyle: classes.orderNameCompleted,
            orderTestStyle: classes.orderTestCompleted
        }, "unable": {
            label: "Unable",
            startButtonStyle: classes.orderRefusedButton,
            orderNameStyle: classes.orderNameRefused,
            orderTestStyle: classes.orderTestRefused
        }
    }
    // useEffect(() => {
    //     var status = order.patient_draw[0].status
    //     if(order){
    //         console.log("HEREEEEEE")
    //         console.log(order)
    //         if(status === "remaining"){
    //             setStartButtonLabel("Start")
    //             setStartButtonColor(classes.orderStartButton)

    //         } else if(status === "done") {
    //             setStartButtonLabel("Completed")
    //             setStartButtonColor(classes.orderCompletedButton)

    //         } else if(status === "unable"){
    //             setStartButtonLabel("Refusedss")
    //             setStartButtonColor(classes.orderRefusedButton)

    //         } 
    //     }
    // },[order])

    // console.log("workflow_type")
    // console.log(workflow_type)

    return (
        <>
            <div className={classes.orderListItem} onClick={() => { startApptOrder() }}>
                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                >
                    <div>
                        <Typography className={displayStates[order.patient_draw[0].status].orderNameStyle}>{order.patient.first_name + " " + order.patient.last_name}</Typography>
                        <Typography className={displayStates[order.patient_draw[0].status].orderTestStyle}>{selected_workflow_type.value}</Typography>
                    </div>
                    <Button
                        aria-label="start"
                        className={displayStates[order.patient_draw[0].status].startButtonStyle}
                    >{displayStates[order.patient_draw[0].status].label}</Button>
                </Grid>
            </div>
        </>
    )
}

const OrderListItem = withRouter(
    withCookies(
        connect(mapStateToProps, mapDispatchToProps)(
            withStyles(styles, { withTheme: true })(OrderListItemFunctionClass)
        )
    )
)
export default OrderListItem
