import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { connect } from "react-redux";
import $ from 'jquery';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
import ReactTimeout from 'react-timeout'
import GoogleMapComponent from './GoogleMapComponent';
import styles from './styles.js';

import ApplicationBar from 'components/common/tablet/ApplicationBar';
import SimpleBottomNavigation from 'components/common/tablet/SimpleBottomNavigation';
import LoadingSpinner from "components/common/tablet/LoadingSpinner.js";
import RequestTimeoutScreen from 'components/common/tablet/RequestTimeoutScreen';
import NoDrawListScreen from  'components/common/tablet/NoDrawListScreen';
const mapStateToProps = state => {
  return { drawData: state.drawData};
};

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class DashboardClass extends Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            ...this.state, 
            timeout : false,
            milesDrivenPercentage: 0,
            googleMapKey: this.generateID(),
        }
        this.interval = null;
        this.previousSiteOrdering = [];
    }

    componentDidMount() {
        console.log('Dashboard did mount.');
        this.setState({...this.state, timeout : false });
    }
    
    componentWillMount() {
        console.log('Dashboard will mount.');      
    }

    componentDidUpdate() {
        console.log("dashboard componentDidUpdate");
        
        const drawData = JSON.parse(JSON.stringify(this.props.drawData));
        if(drawData.draw_list !== undefined) {
    
            // console.log("previous site ordering:");
            // console.log(this.previousSiteOrdering);
        
            // console.log("current site ordering:");
            const current_site_ordering = drawData.draw_list.map(site => {
                return {"site_name": site.site.name, "order": site.site_order};
            });
            
            console.log(current_site_ordering);
            if(JSON.stringify(this.previousSiteOrdering) !== JSON.stringify(current_site_ordering)) {
                this.setState({googleMapKey: this.generateID()});
                // console.log("reinitialize map.");
            } else {
                // console.log("do not reinitialize map.");
            }
            this.previousSiteOrdering = current_site_ordering;
        }
    }

    componentWillReceiveProps() {
        // console.log("dashboard componentWillReceiveProps");
        
        // const drawData = JSON.parse(JSON.stringify(this.props.drawData));
        // if(drawData.draw_list !== undefined) {
    
        //     console.log("previous site ordering:");
        //     console.log(this.previousSiteOrdering);
        
        //     console.log("current site ordering:");
        //     const current_site_ordering = drawData.draw_list.map(site => {
        //         return site.site_order;
        //     });
            
        //     console.log(current_site_ordering);
            // if(JSON.stringify(this.previousSiteOrdering) !== JSON.stringify(current_site_ordering)) {
                // this.setState({googleMapKey: this.generateID()});
                // console.log("reinitialize map.");
            // } else {
            //     console.log("do not reinitialize map.");
            // }
        //     this.previousSiteOrdering = current_site_ordering;
        // }
    }

    goToSite(url) {
        this.props.history.push(url);
    }

    refreshPage(){
        document.location= "/dashboard";        
    }

    setTimeIsUp = () => {
        console.log("TIME IS UP");
        this.setState({...this.state, timeout : true });
    }

    handleMilesDrivenPercentageCBHandler = (responseData) => {
        // console.log(responseData);
        $("#miles-driven-container").text(this.round(responseData.metersDriven, 1) + "/" + this.round(responseData.totalMetersDriven, 1));
        this.setState({...this.state, milesDrivenPercentage: responseData.percentage });
    }
    
    round(value, precision) {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }
    
    generateID() {
        return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
    }

    render() {   
        const drawData = JSON.parse(JSON.stringify(this.props.drawData));
        const { classes } = this.props;
        console.log(
            "Render with data : "
        );
        console.log(drawData);
  
        if(drawData.hasOwnProperty('no_draw_list')) {
            this.props.clearTimeout(this.interval);
            this.interval = null;
          return(<NoDrawListScreen />);
        };

        if(this.state.timeout===true) {
            console.log("TIME IS UP RETURN");
            return(
                <RequestTimeoutScreen />
            );
        }
 
        if($.isEmptyObject(drawData.draw_list)) {
            if(this.interval == null) {
                console.log("setTimeIsUp");
                this.interval = this.props.setTimeout(this.setTimeIsUp, 30000)
            }        
            return(<LoadingSpinner></LoadingSpinner>);
        }
        
        this.props.clearTimeout(this.interval);
        this.interval = null;

        // console.log("drawData from Dashboard:");
        // console.log(drawData);

        
        // const { classes, cookies } = this.props;
        // const creds = cookies.get('credentials');
        const phlebData = drawData.user
        const display_name = phlebData.first_name;

        var alertsListItemsData = [];
        
        var totalSitesDone = 0;
        var totalSites = 0;
        var totalDrawsDone = 0; // whole site, including all status
        var totalDrawItems = 0; // whole site, completed and deferred
        
        const GridListTileComponent = ({data, stats, site_has_stat}) => (
            <GridListTile 
                onClick={() => this.goToSite(`/site/${data.id}`)}
                classes={{
                    root: classes.gridListTileRoot, // need to add this, el style is missing when separated from the GridList render()
                    tile: (site_has_stat) ? classes.gridListTileWithStat : classes.gridListTile}}>
                <img alt="" src={ site_has_stat ? "/images/gridlist_placeholder_with_stat.png" : "/images/gridlist_placeholder.png" } />
                <GridListTileBar
                    title={data.site.name}
                    titlePosition="top"
                    actionIcon={ ((stats.siteDone === stats.siteTotalDrawItems) && stats.siteApproved) &&
                        <Icon className={classes.checkCircle}>check_circle</Icon>
                    }
                    classes={{
                    root: classes.titleBar,
                    title: classNames(classes.title, site_has_stat ? classes.titleWithStat : ''),
                    }}
                />
                <Typography
                    className={classNames(classes.gridListDraws, site_has_stat ? classes.gridListDrawsWithStat : '')}>
                    {stats.siteTotalDrawItems} {stats.siteTotalDrawItems === 1 ? "Draw" : "Draws"}</Typography>
            </GridListTile>
        );

        // *********************** GOOGLE MAP PLOTTING OF ADDRESSES STARTS HERE
        var mapComponentID = this.generateID();
        var zoom = 12;
        // phlebs home address
        // marked as home/test tube icon for now
        var start = {
            lat: parseFloat(phlebData.latitude),
            lng: parseFloat(phlebData.longitude)
        };

        // will be marked as star(temporarily)
        // but should have option on what icon it would be
        var end = {
            lat: parseFloat(drawData.lab_information.latitude),
            lng: parseFloat(drawData.lab_information.longitude)
        };

        // will not be used since preserveViewport=false
        var center = start;

        // phleb's car location, for now it will be placed in the
        // middle of source(solid lineSymbol) and destination(dotted/dashed)
        var way_points = [];
        var way_points_options = [];

        var site_ctr = 0;
        var phlebs_loc_plotted = false;
        const mysites = stableSort(drawData.draw_list, getSorting("asc", "site_order")).map(site => {
            // overview section
            var stats = {
                siteItemCompleted: 0,
                siteItemDeferred: 0,
                siteItemPending: 0,
                siteDone: 0,
                siteTotalDrawItems: 0,
                siteApproved: true,
            };

            var site_has_stat = false;
            
            site.patient_list.map(patient => {
                patient.patient_draw.map(drawItem => {
                    if(drawItem.status === "done" || drawItem.status === "completed") {
                        // totalItemCompleted++;
                        stats.siteItemCompleted++;
                        totalDrawsDone++;
                        stats.siteDone++;
                    }
                    if(drawItem.status === "skipped" || drawItem.status === "refused" || drawItem.status === "unable") {
                        // totalItemDeferred++;
                        stats.siteItemDeferred++;
                        totalDrawsDone++;
                        stats.siteDone++;
                        
                        if((drawItem.status === "refused" || drawItem.status === "unable") && drawItem.draw_sign_off.sof_first_name === "") {
                            // if status in[refused,unable] it needs approval first
                            stats.siteApproved = false;
                        }
                    }
                    if(drawItem.status === "pending" || drawItem.status === "" || drawItem.status==="remaining") {
                        // totalItemPending++;
                        stats.siteItemPending++;
                    }

                    stats.siteTotalDrawItems++;
                    totalDrawItems++;
                    return '';
                });

                if(patient.urgent  && patient.patient_draw[0].status === "remaining") {
                    site_has_stat = true;
                    alertsListItemsData.push({'site_id': site.id, 'site': site.site, 'patient': patient.patient});
                }
                
                // This is a patch
                if(patient.urgent) {
                    site_has_stat = true;
                }

                return'';
            });

            totalSitesDone = totalSitesDone + ((stats.siteDone === stats.siteTotalDrawItems) ? 1 : 0);
            totalSites++;
            site_ctr++;

            // phlebs location
            if(// (stats.siteDone > 0) &&
                (stats.siteDone < stats.siteTotalDrawItems) && 
                !phlebs_loc_plotted) {

                phlebs_loc_plotted = true;
                // console.log("site.site_order: " + site.site_order);
                way_points.push({
                    location: {
                        lat: parseFloat(site.site.latitude), 
                        lng: parseFloat(site.site.longitude)
                    },
                    stopover: true,
                })
                way_points_options.push(
                    {
                        labelFonts: {
                            text: "0",
                            color: 'black',
                            fontSize: "12px",
                            fontWeight: "bold"
                        },
                        type: "car"
                    }, // !important, it will determine the linesymbol if dashed or dotted
                );
            }

            // map section
            // if(site_ctr === drawData.draw_list.length) {
            //     // last site
            //     end = {
            //         lat: parseFloat(site.site.latitude),
            //         lng: parseFloat(site.site.longitude)
            //     }
            // }

            way_points.push({
                location: {
                    lat: parseFloat(site.site.latitude), 
                    lng: parseFloat(site.site.longitude)
                },
                stopover: true,
            });

            var site_status = "not_visited";
            var markerLabelColor = 'white';
            if(stats.siteDone > 0 && stats.siteDone < stats.siteTotalDrawItems) {
                site_status = "active";
                markerLabelColor = "#66CAEE";
            } else if(stats.siteDone === stats.siteTotalDrawItems) {
                // event if 100% completed but not yet approved
                if(stats.siteApproved) {
                    site_status = "completed";
                    markerLabelColor = "#12D003";
                } else {
                    site_status = "active";
                    markerLabelColor = "#66CAEE";
                }
            } else if(site_has_stat) {
                site_status = "with_stat";
                markerLabelColor = "#FEDC4F";
            }

            // drawData.draw_list.length + 1 to include lab_location to countings
            way_points_options.push({ 
                type: "site", // site_ctr === (drawData.draw_list.length + 1) ? "star" : 
                icon: site_status,
                labelFonts: {
                    text: site.site_order.toString(),
                    color: markerLabelColor,
                    fontSize: "16px",
                    fontWeight: "bold"
                }
            });

            if(site_ctr === drawData.draw_list.length) {
                // we will add lab_location as last way_point
                // to add its miles driven to computation
                way_points.push({
                    location: {
                        lat: parseFloat(drawData.lab_information.latitude),
                        lng: parseFloat(drawData.lab_information.longitude)
                    },
                    stopover: true,
                });
                way_points_options.push(
                    {
                        labelFonts: {
                            text: "x",
                            color: 'white',
                            fontSize: "12px",
                            fontWeight: "bold"
                        },
                        type: "star"
                    }, // !important, it will determine the linesymbol if dashed or dotted
                );
            }

            return(<GridListTileComponent site_has_stat={site_has_stat} key={site.id} data={site} stats={stats} />);
        });

        const alertsListItems = $.map(alertsListItemsData, function({site_id, site, patient}) {
            return(
                <ListItem 
                    key={patient.id} 
                    className={classes.alertsItem}
                    onClick={() => this.goToSite(`/site/${site_id}`)}>

                    <Icon className={classes.alertsIcon}>error_outline</Icon>
                    <ListItemText
                        primary={site.name + " - " + patient.last_name + ", " + patient.first_name}
                        // secondary="Marleov Zoldik"
                        classes={{
                            primary: classes.alertsPrimaryText,
                            secondary: classes.alertsSecondaryText,
                        }}
                    />
                </ListItem>);
        }.bind(this));

        var sitesCompletedPercentage = Math.round(totalSitesDone/totalSites * 100);
        var drawsCompletedPercentage = Math.round(totalDrawsDone/totalDrawItems * 100);

        // console.log("way_points_options............................");
        // console.log(way_points_options);
        
        return(
            <div className={classes.root}>
                <main className={classes.content}>
                    <ApplicationBar />
                    <div className={classes.margins}>
                        <Paper className={classNames(classes.paperUI, classes.overviewSection, classes.paddings)} elevation={5}>
                            <Typography variant="h3" className={classNames(classes.overviewTitle)}>
                                {display_name}'s Overview - Tablet
                            </Typography>
                            <Grid 
                                className={classes.circularProgressContainer} 
                                container>
                                <Grid item sm={4}>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                        >
                                        <Grid item xs>
                                            <div className={classes.wrapper}>
                                                <Fab className={classes.fab}>
                                                    {/* <Icon className={classes.icon}>assignment_ind</Icon> */}
                                                    <img alt="draws icon" className={classes.overviewIcons} src="/images/test_tube_outline.svg" />
                                                    <CircularProgress size={88} thickness={3.4} variant="static" value={drawsCompletedPercentage} className={classes.fabProgress} />
                                                </Fab>
                                            </div>
                                        </Grid>
                                        <Grid item xs className={classes.labelContainer}>
                                            <Typography className={classNames(classes.progressDetails, classes.progressLabel)} variant="body2">Draws Completed</Typography>
                                            <Typography className={classNames(classes.progressDetails, classes.progressNumbers)} variant="h6">{totalDrawsDone}/{totalDrawItems}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm={4}>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                        >
                                        <Grid item xs>
                                            <div className={classes.wrapper}>
                                                <Fab className={classes.fab}>
                                                    {/* <Icon className={classes.icon}>place</Icon> */}
                                                    <img alt="sites icon" className={classes.overviewIcons} src="/images/sites_outline.svg" />
                                                    <CircularProgress size={88} thickness={3.4} variant="static" value={sitesCompletedPercentage} className={classes.fabProgress} />
                                                </Fab>
                                            </div>
                                        </Grid>
                                        <Grid item xs className={classes.labelContainer}>
                                            <Typography className={classNames(classes.progressDetails, classes.progressLabel)} variant="body2">Sites Completed</Typography>
                                            <Typography className={classes.progressDetails} variant="h6">{totalSitesDone}/{totalSites}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm={4}>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                        >
                                        <Grid item xs>
                                            <div className={classes.wrapper}>
                                                <Fab className={classes.fab} >
                                                    {/* <Icon className={classes.icon}>directions_car</Icon> */}
                                                    <img alt="car icon" className={classes.overviewIcons} src="/images/car_outline.svg" />
                                                    <CircularProgress size={88} thickness={3.4} variant="static" value={this.state.milesDrivenPercentage} className={classes.fabProgress} />
                                                </Fab>
                                            </div>
                                        </Grid>
                                        <Grid item xs className={classes.labelContainer}>
                                            <Typography className={classNames(classes.progressDetails, classes.progressLabel)} variant="body2">Miles Driven</Typography>
                                            <Typography id="miles-driven-container" className={classes.progressDetails} variant="h6">0.0/0.0</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <div className={classes.siteListRoot}>
                                <GridList 
                                    className={classes.gridList}>
                                    {mysites}
                                </GridList>
                            </div>
                        </Paper>

                        <Paper className={classNames(classes.paperUI, classes.mapSection)} elevation={5}>
                            <GoogleMapComponent
                                key={this.state.googleMapKey}
                                id={mapComponentID}
                                zoom={zoom}
                                center={center}
                                start={start}
                                end={end}
                                wayPoints={way_points}
                                wayPointsOptions={way_points_options}
                                milesDrivenPercentageResponseHandler={this.handleMilesDrivenPercentageCBHandler}>
                            </GoogleMapComponent>
                        </Paper>

                        <Paper className={classNames(classes.paperUI, classes.alertSection, classes.paddings)} elevation={5}>
                            <Typography variant="h4" className={classNames(classes.alertsTitle)}>
                                <Badge badgeContent={alertsListItemsData.length} classes={{ badge: classes.alertBadge }}>
                                    New STAT Requests
                                </Badge>
                            </Typography>
                            <List>
                                {alertsListItems}
                            </List>
                        </Paper>
                    </div>

                    <div className={classes.toolbar} />
                    <div className={classes.toolbar} />
                </main>
                <SimpleBottomNavigation />
            </div>
        );
    }
}

DashboardClass.propTypes = {
    classes: PropTypes.object.isRequired
};

const Dashboard = ReactTimeout(withCookies(connect(mapStateToProps)(withStyles(styles)(DashboardClass))));
export default Dashboard;