import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'

const styles = theme => ({
    dateSection: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        textShadow: '1px 1px 2px #fff',
    },
    datetimeLabel: {
        display: "inline",
        fontSize: "14px !important",
        fontWeight: 600,
    },
})

const CurrentDateFunctionClass = (props) => {
    const { classes } = props
    // const currentDate = new Date(),
    //     display_day = currentDate.getDay(),
    //     day = currentDate.getDate(),
    //     month = currentDate.getMonth() + 1,
    //     year = currentDate.getFullYear()

    // const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"]
    // const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

    // const dateDisplay = days[display_day] + ", " + months[month - 1] + " " + day + ", " + year // + " " + hours + ":" + minutes + " " + suffix)

    const currentDate = moment(new Date()).format("dddd, MMM DD, YYYY")
    return (
        <div className={classes.dateSection}>
            <Typography
                className={classes.datetimeLabel}
                constraint="body1">{currentDate}</Typography>
        </div>
    )
}

const CurrentDate = withStyles(styles, { withTheme: true })(CurrentDateFunctionClass)
export default CurrentDate


