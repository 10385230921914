import React, { useState } from 'react';

import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
// import Card from '@material-ui/core/Card';
// import CardMedia from '@material-ui/core/CardMedia';
// import CardActionArea from '@material-ui/core/CardActionArea';

import { withStyles } from '@material-ui/core/styles';
import styles from "./styles.js";
import { Typography } from '@material-ui/core';
import classNames from 'classnames';

//import { ReactComponent as TeloPointLogoSVG } from 'components/common/mobile/CustomOfflineSVG/TeloPoint.svg';
import { ReactComponent as BackspaceIcon } from 'components/common/mobile/CustomOfflineSVG/backspace.svg';

const PinKeyPad = (props) => {
    //const classes = 
    const [newValue, setNewValue] = useState("");
    const [pointer, setPointer] = useState(0);
    const {
        classes, digitCount,
        value, onChange,
        onComplete,
        keyPadMessage,
        keyPadInstruction,
        showResetLink,
        resetLinkText,
        onResetLink

    } = props;

    if (value.trim() !== newValue.trim()) {
        setNewValue(value.trim())
        setPointer(value.length - 1)
    }

    const onClickkeyNumericHandler = (value) => {
        var newVal = newValue + value

        if (newVal.length > digitCount) return;
        var newPointer = pointer
        if (newPointer < 0) newPointer = 0
        newPointer++
        setNewValue(newVal)
        setPointer(newPointer)

        if (digitCount === newVal.length) {
            //console.log("onClickkeyNumericHandler-----");
            //console.log(newVal);
            onComplete(newVal)
        } else {
            onChange(newVal)
        }

        //console.log("newPointer")
        //console.log(newPointer)
    }

    const onClickkeyTextHandler = (value) => {
        var newPointer = pointer - 1;
        if (newPointer < 0) newPointer = 0

        var newVal = newValue.substr(0, newPointer)
        //console.log("newPointer")
        //console.log(newPointer)
        setNewValue(newVal)
        setPointer(newPointer)
        onChange(newVal)
    }

    var display = []
    var classDisp = classes.keyDisplayOff

    for (var i = 0; i < digitCount; i++) {

        if (value[i] !== undefined && value[i] !== " ") {
            classDisp = classes.keyDisplayOn
        } else {
            classDisp = classes.keyDisplayOff
        }

        display.push(
            <Grid item xs key={Math.random()}>
                <div className={showResetLink && value.length == digitCount ? classes.digitBoxRed : classes.digitBox}>
                    <Typography className={classDisp}></Typography>
                </div>
            </Grid>
        )
    }

    return (
        <div className={classes.mainContainer}>
            <div className={classes.logoContainer}>
                <br />
                <Grid item xs={12}>
                    <Typography className={classes.keyPadTitle}>Easy Login PIN</Typography>
                </Grid>
                {/* <Grid item xs={12}>
                <Card className={classes.card}>
                    <CardActionArea>
                        <CardMedia
                        component="img"
                        alt=""
                        className={classes.teloPointLogo}
                        image="/images/TeloPoint.svg"
                        title=""
                        />
                    </CardActionArea>
                </Card>
            </Grid>*/}
            </div>
            <Typography className={classes.keyPadInstruction}>{keyPadInstruction}</Typography>
            <br />
            <div>
                <Grid
                    container
                    spacing={8}
                    align="center"
                    alignItems="center"
                    justify="center"
                    className={classes.keyDisplayContainer}
                >
                    {/* <div className={classes.displayContainer}> */}
                    {/* <Grid item xs={4}> */}
                    {display}
                    {/* </Grid> */}
                    {/* </div> */}
                </Grid>

            </div>

            <Typography className={classes.keyPadMessage}>{keyPadMessage}</Typography>

            {showResetLink && value.length == digitCount &&
                <Typography onClick={onResetLink} className={classes.resetLink}>{resetLinkText}</Typography>
            }

            <br />
            <div className={classes.divider}>
                <div className={classes.keysContainer}>
                    {/* 1 2 3 */}
                    <Grid container spacing={8}>
                        <Grid item xs={4}>
                            <Typography onClick={onClickkeyNumericHandler.bind(null, 1)} className={classNames(classes.keyNumeric, classes.ripple)}>1</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography onClick={onClickkeyNumericHandler.bind(null, 2)} className={classNames(classes.keyNumeric, classes.ripple)}>2</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography onClick={onClickkeyNumericHandler.bind(null, 3)} className={classNames(classes.keyNumeric, classes.ripple)}>3</Typography>
                        </Grid>
                    </Grid>
                    {/* 4 5 6 */}
                    {/* <br /> */}
                    <Grid container spacing={8}>
                        <Grid item xs={4}>
                            <Typography onClick={onClickkeyNumericHandler.bind(null, 4)} className={classes.keyNumeric}>4</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography onClick={onClickkeyNumericHandler.bind(null, 5)} className={classes.keyNumeric}>5</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography onClick={onClickkeyNumericHandler.bind(null, 6)} className={classes.keyNumeric}>6</Typography>
                        </Grid>
                    </Grid>
                    {/* <br /> */}
                    {/* 7 8 9 */}
                    <Grid container spacing={8}>
                        <Grid item xs={4}>
                            <Typography onClick={onClickkeyNumericHandler.bind(null, 7)} className={classes.keyNumeric}>7</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography onClick={onClickkeyNumericHandler.bind(null, 8)} className={classes.keyNumeric}>8</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography onClick={onClickkeyNumericHandler.bind(null, 9)} className={classes.keyNumeric}>9</Typography>
                        </Grid>
                    </Grid>
                    {/* <br /> */}
                    {/* 7 8 9 */}
                    <Grid container spacing={8}>
                        <Grid item xs={4}>
                            {/* <Typography className={classes.keyText}>{"CLEAR"}</Typography> */}
                        </Grid>
                        <Grid item xs={4}>
                            <Typography onClick={onClickkeyNumericHandler.bind(null, 0)} className={classes.keyNumeric}>{"0"}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography onClick={onClickkeyTextHandler.bind(null, "backspace")} variant="button" className={classes.keyText}>
                                <BackspaceIcon className={classes.keyIcon} >backspace</BackspaceIcon></Typography>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}

export default withStyles(styles)(PinKeyPad);