import React, {Component} from 'react'; 
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'; 

import  Dashboard from "../tablet/Dashboard";
import TestPage from 'components/common/experiment/TestPage'
import Notifications from "../tablet/Notifications";
import Logout from "../tablet/Logout";
import Sites from "../tablet/Sites";
import Site from "../tablet/Sites/Site";
import Patient from "../tablet/Patients/Patient";
import Settings from "../tablet/Settings";

class TabletRouter extends Component {
         
    render() {

            return(
                <div>
                     <BrowserRouter>
                        <Switch>
                            <Route path="/test/" render={(props) => <TestPage {...props} />} />
                            <Route path="/dashboard/" render={(props) => <Dashboard {...props} />} />
                            <Route path="/sites/" render={(props) => <Sites {...props} />} />
                            <Route path="/site/:siteId" render={(props) => ( <Site {...props} /> )} />
                            <Route path="/patient/:patientId/:siteId" render={(props) => <Patient {...props} />} />
                            <Route path="/notifications/" render={(props) => <Notifications {...props} />} />
                            <Route path="/settings/" render={(props) => <Settings {...props} />} />
                            <Route path="/logout"  render={(props) => <Logout {...props}/>}  />
                            <Redirect to="/dashboard" />
                        </Switch>
                    </BrowserRouter>
                 </div>
            );
            
    }
}

export default TabletRouter;