import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import $ from 'jquery';

import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import ApplicationBar from 'components/common/mobile/ApplicationBar';
import SimpleBottomNavigation from 'components/common/mobile/SimpleBottomNavigation';
import { connect } from "react-redux";
import LinearProgress from '@material-ui/core/LinearProgress';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import styles from "./styles.js";

import ImmutableJS from "immutable";
import EnhancedTableHead from "components/common/mobile/EnhancedTableHead.js";
import NoDrawListScreen from 'components/common/mobile/NoDrawListScreen.js';
import CurrentDate from 'components/common/mobile/CurrentDate.js';

import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { 
    updateSiteOrdering,
} from "js/actions/index";

const mapDispatchToProps = dispatch => {
    return {
        updateSiteOrdering: drawList => dispatch(updateSiteOrdering(drawList)),
    };
};

const mapStateToProps = state => {
    return { drawData: state.drawData, isLoggedIn: state.isLoggedIn};
};

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class EditRoutesClass extends Component {  
    constructor(props) {
      super(props);

      this.isSorting = false;
      
      this.state = {
        ...this.state,  
        drawList: ImmutableJS.fromJS(this.props.drawData.draw_list).toJS(),
        sites: [],
        order: "asc", // cookies.get('sitesOrder') ? cookies.get('sitesOrder') : 'asc',
        orderBy: "site_order", // cookies.get('sitesOrderBy') ? cookies.get('sitesOrderBy') : 'site_order',
        filteredBy: "filter_all", // cookies.get('selectedSitesFilter') ? cookies.get('selectedSitesFilter') : "filter_all",
      }
    }

    componentDidMount() {
        console.log('Edit Routes did mount.');

        this.convertProgressBarTransformIntoWidth();
    }

    // also used when page refreshed
    componentDidUpdate(previousProps) {
        console.log("componentDidUpdate")
        var temp = ""

        if(previousProps.drawData !== this.props.drawData) {
            temp = " HAVE UPDATE = TRUE"
            this.convertProgressBarTransformIntoWidth();
            // console.log("previous Props")
            // console.log(previousProps.drawData)
            // console.log("this.state.currentActiveStartDate")
            // console.log(this.state.currentActiveStartDate)
            // console.log("updated Props")
            // console.log(this.props.drawData)

            // this.updateScheduleData(this.props.drawData);
        } else {
            temp = " HAVE UPDATE = FALSE";
        }
        console.log("EditRoutes Class " + temp)
    }

    shouldComponentUpdate(nextProps, nextState) {
        var continueUpdate = true;
        if(this.isSorting) {
            console.log("isSorting='" + this.isSorting + "', do not update component")
            continueUpdate = false;
        } else {
            console.log("isSorting='" + this.isSorting + "', update component")
        }

        return continueUpdate;
    }

    convertProgressBarTransformIntoWidth() {
        // force reactjs to use percentage width instead of scalex to support border radius        
        var percentage_cont = $("#overall-progress");
        var percentage_el = $("#overall-progress div:first-child");
        var percentage = percentage_cont.attr("aria-valuenow");

        percentage_el.css({
            'transform': 'none',
            'width': percentage + "%",
        });
    }

    handleRequestSort = (event, property) => {
        console.log("handleRequestSort of EnhancedTabledHead is triggered...");
    };

    goToSite(url) {
        this.props.history.push(url);
    }

    onSortStart = ({node, index, collection, isKeySorting}, event) => {
        console.log("onSortStart node")
        console.log(node)
        this.isSorting = true;
    }

    onSortEnd = ({oldIndex, newIndex, collection, isKeySorting}, event) => {
        console.log("onSortEnd node")
        this.isSorting = false;

        var site_ctr = 1;
        var newSitesOrdering = arrayMove(this.state.drawList, oldIndex, newIndex).map(site => {
            var immuSite = ImmutableJS.fromJS(site).toJS();

            immuSite.site_order = site_ctr;
            site_ctr++;
            return immuSite;
        });

        this.setState({...this.state, drawList: newSitesOrdering });
    };

    handleSaveRoutes = event => {
        const payload = {
            "draw_list": this.state.drawList
        };
        this.props.updateSiteOrdering(payload);

        this.goToSite("/sites");
    };

    render() {   
        const drawData = this.props.drawData;
        
        if(drawData.hasOwnProperty('no_draw_list')) {
            return(<NoDrawListScreen />);
        }

        console.log("this.isSorting: " + this.isSorting)
        
        const { classes } = this.props;
        const {order, orderBy} = this.state;        
        const display_name = drawData.user.first_name;
        var totalDrawItems = 0; // whole site, completed and deferred
        var totalDone = 0; // whole site, including all status

        const SortableItem = SortableElement(({item, LIclasses}) => {
            return(
                <li className={LIclasses}>
                    <div className={classes.badgesContainer}>
                        {item.hasFasting &&
                            <Typography
                            className={classNames(classes.badges, classes.FASTING)}
                            variant="button"></Typography>
                        }
                        {item.hasStatRequest &&
                            <Typography 
                            className={classNames(classes.badges, classes.STAT)}
                            variant="button"></Typography>
                        }
                        { (!item.hasFasting && !item.hasStatRequest ) && 
                            <span>&nbsp;</span>
                        }
                    </div>
                    <div className={classes.siteInfo}>
                        {/* site order and name */}
                        <Typography 
                            className={classes.siteName}
                            variant="body1">
                            {item.site_order}. {item.site.name}
                        </Typography>
                        {/* Address */}
                        <Typography
                            className={classes.siteAddress}>
                            {item.site.address}
                        </Typography>
                        
                        {/* Number of draws */}
                        {/* <Typography className={classes.siteDraws}>
                            {item.siteTotalDrawItems} {item.siteTotalDrawItems > 1 ? "draws" : "draw"} 
                        </Typography> */}
                    </div>
                    <div className={classes.optionsContainer}>
                        {!(item.siteDone === item.siteTotalDrawItems && item.isApproved) &&
                            <img alt="TeloPoint Drag Reorder Icon" className={classNames(classes.optionsIcon)} src="/images/drag_reorder.svg" />
                        }
                    </div>
                </li>
            );
        });

        const SortableList = SortableContainer(({drawList, ULclasses}) => {
            return (
                <ul className={ULclasses}>
                    {drawList.map((item, index) => (
                        <SortableItem
                            disabled={(item.siteDone === item.siteTotalDrawItems && item.isApproved) ? true : false}
                            key={item.site.id} 
                            index={index} 
                            item={item} 
                            LIclasses={classNames(classes.sortableList, (item.siteDone === item.siteTotalDrawItems && item.isApproved) ? classes.siteRowCompleted : '')} />
                    ))}
                </ul>
            );
        });

        // temp solution, it should be sorting on multi dimensional array
        const mySitesInjectedWithKeysForSorting = this.state.drawList.map(site => {
            var immuSite = ImmutableJS.fromJS(site).toJS();
           
            var stats = {
                siteDone: 0,
                siteTotalDrawItems: 0,
                siteApproved: true,
            };
            var site_has_stat = false;
            var site_has_fasting = false;
            site.patient_list.map(patient =>{
                patient.patient_draw.map(drawItem => {
                    if(drawItem.status === "done" || drawItem.status === "completed") {
                        stats.siteItemCompleted++;
                        totalDone++;
                        stats.siteDone++;
                    }
                    if(drawItem.status === "skipped" || drawItem.status === "refused" || drawItem.status === "unable") {
                        totalDone++;
                        stats.siteDone++;
                        
                        // if((drawItem.status === "refused" || drawItem.status === "unable") && drawItem.draw_sign_off.sof_first_name === "") {
                        //     // if status in[refused,unable] it needs approval first
                        //     stats.siteApproved = false;
                        // }
                    }
                    
                    if(patient.urgent) {
                        site_has_stat = true;
                    }

                    if(patient.fasting) {
                        site_has_fasting = true;
                    }

                    stats.siteTotalDrawItems++;
                    totalDrawItems++;
                    return '';
                });
                return'';
            }); 
            
            // will be used just for sorting
            // keys that was used under row_headers
            immuSite.site_name = immuSite.site.name;
            immuSite.address = immuSite.site.address;
            immuSite.status = (stats.siteDone === stats.siteTotalDrawItems) ? "Complete" : "";
            immuSite.no_of_draws = stats.siteTotalDrawItems;
            immuSite.siteDone = stats.siteDone;
            immuSite.siteTotalDrawItems = stats.siteTotalDrawItems;
            immuSite.hasStatRequest = site_has_stat;
            immuSite.hasFasting = site_has_fasting;
            immuSite.isApproved = stats.siteApproved;
            return immuSite;
        });

        const mysites = stableSort(mySitesInjectedWithKeysForSorting, getSorting(order, orderBy)).map(site => {
            return site;
        });

        var filteredMySites = mysites.filter(function (el) {
          return el !== "";
        });

        var percentage = Math.round(totalDone/totalDrawItems * 100);

        const row_headers = [
            { id: 'site_order', sortingEnabled: false, label: 'Ordering' },
            { id: 'site_name', sortingEnabled: false, label: 'Site' },
            { id: 'status', sortingEnabled: false, label: 'Status' },
            { id: 'no_of_draws', sortingEnabled: false, label: 'Draws' },
        ];

        return(
            <div className={classes.root}>
                <main className={classes.content}>

                    <div className={classNames(classes.headerInfo)}>
                         <ApplicationBar title={display_name + "'s Route - Edit"} />
                         <CurrentDate />
                        <div className={classNames(classes.progressContainer)}>

                            <Typography 
                                className={classNames(classes.allowanceTop, classes.overallProgress)}
                                align="left"
                                variant="body1">
                                Overall Progress
                            </Typography>

                            <LinearProgress 
                                id="overall-progress"
                                className={classNames(
                                    classes.progressBar,
                                )}
                                classes={{
                                    bar: classes.progressBarContainer,
                                    colorPrimary: classes.linearColorPrimary,
                                    barColorPrimary: classes.linearBarColorPrimary
                                }}
                                variant="determinate"
                                value={percentage}  />
                            
                            <Typography
                                style={{float: "left", marginLeft: "6px"}}
                                className={classes.progressPercentageText}>
                                {totalDone}/{totalDrawItems}
                            </Typography>
                            <Typography
                                align="right"
                                style={{marginRight: "6px"}}
                                className={classes.progressPercentageText}>
                                {percentage}% Complete</Typography>
                        </div>
                    </div>

                    <div className={classNames(classes.siteListContainer)}>
                        <Paper>                            
                            <Table className={classes.myTable}>
                                <TableHead>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell
                                            colSpan={3}
                                            className={classNames(classes.filterContainerTableCell)}>
                                            <div className={classNames(classes.filterContainer)}>
                                                <Button
                                                    disabled={true}
                                                    className={classNames(classes.filters)}>
                                                    Filter:</Button>
                                                <Button
                                                    id="filter_all"
                                                    className={classNames(classes.filters, this.state.filteredBy === "filter_all" ? classes.selectedFilter : "")}
                                                    >
                                                    All</Button>
                                                <Button
                                                    id="filter_completed"
                                                    className={classNames(classes.filters, this.state.filteredBy === "filter_completed" ? classes.selectedFilter : "")}
                                                    >
                                                    Completed</Button>
                                                <Button
                                                    id="filter_with_remaining"
                                                    className={classNames(classes.filters, this.state.filteredBy === "filter_with_remaining" ? classes.selectedFilter : "")}
                                                    >
                                                    Remaining</Button>
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={classNames(classes.lastTableCellHeader, classes.editRouteTableCell)}>
                                            <Button 
                                                id="edit_route_button"
                                                className={classNames(classes.editRoute)}
                                                onClick={this.handleSaveRoutes}>
                                                Save Route</Button>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            
                                <EnhancedTableHead
                                    row_headers={row_headers}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={this.handleRequestSort}
                                />
                                <TableBody className={`sites_list_tbody`}>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.sitesSortableCell} colSpan={4}>
                                            {filteredMySites.length === 0
                                            ?
                                                <Typography className={classNames(classes.tableCell, classes.centered)} variant="body2">No data to show.</Typography>
                                            : 
                                                <SortableList 
                                                    helperClass={classes.sortableHelperClass}
                                                    pressDelay={200}
                                                    ULclasses={classNames(classes.sortableUnordereList)}
                                                    drawList={filteredMySites}
                                                    onSortStart={this.onSortStart}
                                                    onSortEnd={this.onSortEnd} />
                                            }
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Paper>

                        <br/>
                        { totalDrawItems === totalDone &&
                            <div className={classNames(classes.centered)}>
                                <Typography className={classes.allSitesNote}>All Sites Completed!</Typography>
                                <Icon className={classes.greatJobIcon}>check_circle</Icon>
                            </div>
                        }
                        
                        <div className={classes.toolbar} />
                        <div className={classes.toolbar} />
                    </div>
                </main>
                <SimpleBottomNavigation />
            </div>
        );
    }
}

EditRoutesClass.propTypes = {
    classes: PropTypes.object.isRequired,
};

const EditRoutes = connect(mapStateToProps,mapDispatchToProps) (connect(mapStateToProps)(withStyles(styles)(EditRoutesClass)));
export default EditRoutes;
