import React, { useState, useEffect } from 'react'

import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

// https://github.com/wojtekmaj/react-calendar
import Calendar from 'react-calendar/dist/entry.nostyle'
import moment from 'moment-timezone'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import AddIcon from '@material-ui/icons/Add'

import WorkScheduleTimeOnSelectedDate from './WorkScheduleTimeOnSelectedDate'

const mapStateToProps = state => {
    return {
        drawData: state.drawData
    }
}

const MonthlyFunctionClass = (props) => {
    const {
        classes,
        calendarUUID,
        activeStartDate,
        onSetActiveStartDate,
        expandedScheduleDays,
        history
    } = props

    const currentDate = moment(new Date()).format("YYYY-MM-DD")
    const activeYear = moment(activeStartDate).format("YYYY")
    const activeMonth = moment(activeStartDate).format("MM")

    const [workScheduleTimeOnSelectedDate, setWorkScheduleTimeOnSelectedDate] = useState([])
    const [tappedDate, setTappedDate] = useState(currentDate)
    const [secondTappedDate, setSecondTappedDate] = useState("")

    const customizedCalendarTileContent = ({ date, view }) => {
        var calendar_date = moment(date).format("YYYY-MM-DD")

        // filter all schedule with same date as looped date
        var activeDays = expandedScheduleDays.filter(item => {
            // show not deleted only
            return item.date_from === calendar_date
        })

        var content = ""
        const is_current_date = calendar_date === currentDate ? " today" : ""
        const is_active = calendar_date === moment(activeStartDate).format("YYYY-MM-DD") ? " active" : ""
        const has_schedule = activeDays.length > 0 ? " has-schedule" : ""
        const has_schedule_past_date = activeDays.length > 0 && calendar_date < currentDate ? " has-schedule-past-date" : ""
        // const active_no_schedule = activeDays.length === 0 &&
        //     calendar_date >= currentDate &&
        //     calendar_date === moment(activeStartDate).format("YYYY-MM-DD")
        //     ? " active-no-schedule" : ""
        const is_second_tapped = tappedDate !== "" && secondTappedDate !== "" && tappedDate === secondTappedDate
        const is_active_second_tapped = is_second_tapped && secondTappedDate === calendar_date
        const active_second_tap = is_active_second_tapped ? " active-second-tap" : ""

        content = <div
            className={
                "custom-calendar-date-tile" +
                has_schedule +
                is_active +
                is_current_date +
                has_schedule_past_date +
                active_second_tap
            }
            onClick={() => {
                if (is_active_second_tapped) {
                    goToAddWorkSchedule(calendar_date)
                }
            }}
        >
            {is_active_second_tapped ? <AddIcon /> : date.getDate()}
        </div>

        return view === 'month' && content
    }

    const goToAddWorkSchedule = (date) => {
        // history.push("/work-schedule/manage/0/" + date)
        history.push("/work-schedule/manage/0/" + date + "/08:00")
    }

    useEffect(() => {
        var filteredByDate = expandedScheduleDays.filter(item => {
            return item.date_from === moment(activeStartDate).format("YYYY-MM-DD")
        })

        setWorkScheduleTimeOnSelectedDate(filteredByDate)
    }, [activeStartDate, expandedScheduleDays])

    return (
        <>
            <Calendar
                key={calendarUUID}
                defaultActiveStartDate={new Date(activeYear, activeMonth - 1, 1)}
                // activeStartDate={new Date(activeYear, activeMonth - 1, 1)}
                calendarType="US"
                view="month"
                className="modified-calendar-v2"
                tileContent={customizedCalendarTileContent}
                onClickDay={(date) => {
                    var calendar_date = moment(date).format("YYYY-MM-DD")
                    if (tappedDate === "") {
                        setTappedDate(calendar_date)
                    } else {
                        if (tappedDate === calendar_date && moment(calendar_date, "YYYY-MM-DD").isSameOrAfter(moment(currentDate, "YYYY-MM-DD"))) {
                            setSecondTappedDate(calendar_date)
                        } else {
                            setTappedDate(calendar_date)
                        }
                    }
                    onSetActiveStartDate(date)
                }}
                prevLabel={<ArrowLeftIcon />}
                nextLabel={<ArrowRightIcon />}
                formatShortWeekday={(locale, date) => {
                    return moment(date).format('ddd').substring(0, 1)
                }}
            />

            <WorkScheduleTimeOnSelectedDate
                classes={classes}
                selectedDate={activeStartDate}
                workScheduleTimeOnSelectedDate={workScheduleTimeOnSelectedDate}
                history={history}
            />
        </>
    )
}

const Monthly = withCookies(
    connect(mapStateToProps)(
        withStyles(styles, { withTheme: true })(MonthlyFunctionClass)
    )
)
export default Monthly
