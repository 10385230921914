import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
import classNames from 'classnames'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"
import moment from 'moment'
// import uuid from "uuid"

import Typography from '@material-ui/core/Typography'

import AppointmentListItem from './AppointmentListItem'

const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const AppointmentListFunctionClass = (props) => {
    const { drawData, classes } = props

    const [active, setActive] = useState(0)
    const [expanded, setExpanded] = useState("")

    // order by collection_time
    var ordered = []
    if (drawData.draw_list !== undefined) {
        ordered = drawData.draw_list.sort((a, b) => moment(a.collection_time, "HH:mm:ss").utc() - moment(b.collection_time, "HH:mm:ss").utc())

        // console.log("sort here")
        // console.log(ordered)
    }
    // ordered = [] // to simulate no appointments state
    // console.log("drawData.draw_list")
    // console.log(drawData.draw_list)

    useEffect(() => {
        if (ordered.length > 0) {
            setActive(ordered[0].id)
            
            if(expanded === "") {
                setExpanded("panel_" + ordered[0].id)
            }
        }
    }, [ordered])

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }

    return (
        <>
            <div className={classNames(classes.appointmentListContainer)}>
                <Typography className={classes.appointmentsLabel}>Appointments</Typography>

                {drawData.draw_list !== undefined && ordered.map((appt) => {
                    return (
                        <AppointmentListItem
                            key={appt.id}
                            appointment={appt}
                            active={active}
                            expanded={expanded}
                            handleChange={handleChange}
                        />
                    )
                })}
                { ordered.length === 0 && 
                <Typography className={classes.NoAppointmentsLabel}>You do not have any appointments scheduled 
                for today.</Typography>
                } 
            </div>
        </>
    )
}

const AppointmentList = withCookies(
    connect(mapStateToProps)(
        withStyles(styles, { withTheme: true })(AppointmentListFunctionClass)
    )
)
export default AppointmentList
