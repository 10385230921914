import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
// import classNames from 'classnames'

import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

// import uuid from "uuid"

import ImmutableJS from "immutable"

import OrderListItem from './OrderListItem'

const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const OrderListFunctionClass = (props) => {
    const { drawData, classes, match, sort, searchText, addedPatients } = props
    const { appointment_id } = match.params

    var workflow_type = "Phlebotomy"
    var orders = []
    if (drawData.draw_list !== undefined) {
        const theSite = drawData.draw_list.find(function (site) {
            return site.id === appointment_id
        })
        orders = theSite.patient_list

        if (theSite.workflow) {
            workflow_type = theSite.workflow.label
        }
    }

    // console.log("orders")
    // console.log(orders)
    // console.log("addedPatients")
    // console.log(addedPatients)

    // refresh orders data
    // orders.filter(function (e) { return !e.test_data })
    // const preservedOrders = ImmutableJS.fromJS(orders).toJS()
    // preservedOrders.filter((patient) => {
    //     console.log("patient.test_data", patient.test_data)
    //     return patient.test_data === undefined
    // })

    // add new
    // var newOrders = []
    // addedPatients.forEach((patient) => {
    //     // if (orders.indexOf(patient.id) > -1) {
    //     if (newOrders.filter(function (e) { return e.id === patient.id }).length === 0) {
    //         newOrders.push({
    //             "test_data": true,
    //             "id": patient.id,
    //             "drawlist_site": "6943b9d0-6ce9-4f1a-b043-7006205a12ae",
    //             "patient_room_number": "66",
    //             "urgent": false,
    //             "fasting": false,
    //             "patient": {
    //                 "notes": [
    //                     {
    //                         "client_timestamp": 1628064659,
    //                         "heading": "",
    //                         "client_deleted": false,
    //                         "message": "test",
    //                         "author": 0,
    //                         "latitude": 15.215496333333332,
    //                         "client_timezone": "Asia/Irkutsk",
    //                         "author_type": "",
    //                         "longitude": 120.66062316666665,
    //                         "id": "temp_57cd8fc7-d10b-401e-a0bf-d5fc86192356"
    //                     }
    //                 ],
    //                 "gender": patient.gender,
    //                 "zipcode": "60618",
    //                 "birth_date": patient.birth_date,
    //                 "state": "Illinois",
    //                 "address_line_2": "Address line 2",
    //                 "last_name": patient.last_name,
    //                 "address": "4030 N California Ave, Chicago",
    //                 "middle_name": "",
    //                 "first_name": patient.first_name,
    //                 "id": patient.id
    //             },
    //             "doctor": {
    //                 "id": "a8a728df-910b-46fa-b097-002d410abd59",
    //                 "first_name": "Johnna",
    //                 "last_name": "Dohr",
    //                 "address": "1666 W Pratt Blvd, Chicago"
    //             },
    //             "patient_draw": [
    //                 {
    //                     "urgent": false,
    //                     "status_notes_m": "",
    //                     "drawlistpatient": "d6ccc8c1-0bef-4c21-910b-fabf9ccc3b8b",
    //                     "client_app_timestamp": 1657789303,
    //                     "status_notes_p": "",
    //                     "draw_notes": "",
    //                     "draw_sign_on": {
    //                         "son_first_name": "",
    //                         "son_last_name": "",
    //                         "son_signatory_type": null,
    //                         "son_client_timezone": null,
    //                         "son_client_timestamp": null,
    //                         "son_sign_on": null,
    //                         "son_sign_on_metadata": null
    //                     },
    //                     "draw_sign_off": {
    //                         "sof_first_name": "",
    //                         "sof_last_name": "",
    //                         "sof_signatory_type": null,
    //                         "sof_client_timezone": null,
    //                         "sof_client_timestamp": null,
    //                         "sof_sign_off": null,
    //                         "sof_sign_off_metadata": null
    //                     },
    //                     "draw_date": 1657789303,
    //                     "client_app_timezone": "Asia/Taipei",
    //                     "name": "Blood",
    //                     "draw_test": [
    //                         {
    //                             "id": "dfc51a6f-e7cd-487d-860b-aa13c9348d4a",
    //                             "patient_draw": "c11e9fa0-0184-4a74-a4b5-d439973d697e",
    //                             "test": {
    //                                 "id": "9b41bd5b-d029-4423-81b5-63fc6a90b092",
    //                                 "name": "ABDOMINAL DISEASE PROFILE",
    //                                 "description": "CMP, CBC W/ DIFF, SED RATE, H-PYLORI, TSH, AMYLASE, LIPASE, HEPATITIS PANEL, MAGNESIUM, SGOT, SGPT, GGT, LIPID PANEL, URINALYSIS",
    //                                 "color": [
    //                                     {
    //                                         "T": "TIGER TOP"
    //                                     },
    //                                     {
    //                                         "L": "LAVENDER"
    //                                     },
    //                                     {
    //                                         "U": "URINE"
    //                                     }
    //                                 ]
    //                             }
    //                         },
    //                         {
    //                             "id": "0373f2e8-cbe6-459b-8500-6ebe0751924d",
    //                             "patient_draw": "c11e9fa0-0184-4a74-a4b5-d439973d697e",
    //                             "test": {
    //                                 "id": "9cee6944-0bbd-4f80-87ba-f557dfbb7058",
    //                                 "name": "ESR",
    //                                 "description": "ESR",
    //                                 "color": [
    //                                     {
    //                                         "L": "LAVENDER"
    //                                     }
    //                                 ]
    //                             }
    //                         },
    //                         {
    //                             "id": "f18aeadf-269c-4f7e-94b3-a68d362a1312",
    //                             "patient_draw": "c11e9fa0-0184-4a74-a4b5-d439973d697e",
    //                             "test": {
    //                                 "id": "b1cbac14-e34d-4d8d-82ab-c26ff3135b98",
    //                                 "name": "PTT ACTIVATED",
    //                                 "description": "PTT ACTIVATED",
    //                                 "color": [
    //                                     {
    //                                         "B": "BLUE"
    //                                     }
    //                                 ]
    //                             }
    //                         }
    //                     ],
    //                     "draw_request_documents": [
    //                         {
    //                             "id": "temp_5c8ff85b-d3ae-4fa9-8364-e006e1df0b26",
    //                             "filename": "Photo-8-4-21-4:12:50-PM.jpg",
    //                             "path": "Photo",
    //                             "doc_type": "offline_upload",
    //                             "client_deleted": false,
    //                             "client_timestamp": 1628064770,
    //                             "client_timezone": "Asia/Irkutsk",
    //                             "metadata": {
    //                                 "image": {
    //                                     "category": "Patient Identification",
    //                                     "data": "",
    //                                     "filename": "Photo-8-4-21-4:12:50-PM.jpg",
    //                                     "resize_conf": {
    //                                         "imageType": "IMAGE_TYPES.JPG",
    //                                         "imageCompression": 0.5,
    //                                         "isImageMirror": false,
    //                                         "sizeFactor": 0.5
    //                                     }
    //                                 }
    //                             }
    //                         },
    //                         {
    //                             "id": "temp_b0d5e1ec-980c-4a79-9e8d-657ff9231a52",
    //                             "filename": "",
    //                             "path": "",
    //                             "doc_type": "metadata",
    //                             "client_deleted": false,
    //                             "client_timestamp": 1628065034,
    //                             "client_timezone": "Asia/Irkutsk",
    //                             "metadata": {
    //                                 "requisition_code": "http://packaging.illinois.edu"
    //                             }
    //                         }
    //                     ],
    //                     "dob_verified": false,
    //                     "status": "remaining",
    //                     "draw_completion_sign_off": {
    //                         "sof_client_timezone": null,
    //                         "sof_client_timestamp": null,
    //                         "sof_signatory_type": null,
    //                         "sof_first_name": "",
    //                         "sof_last_name": "",
    //                         "sof_sign_off_metadata": null,
    //                         "sof_sign_off": null
    //                     },
    //                     "draw_source": "right_arm",
    //                     "id": "c11e9fa0-0184-4a74-a4b5-d439973d697e",
    //                     "name_verified": false,
    //                     "labels": []
    //                 }
    //             ]
    //         })
    //     }
    // })

    // console.log("old Orders")
    // console.log(orders)
    // console.log("newOrders")
    // console.log(newOrders)

    // var mergedOrders = orders.concat(newOrders)
    
    // console.log("mergedOrders")
    // console.log(mergedOrders)

    orders = orders.filter((order) => {
        const name = order.patient.first_name + " " + order.patient.last_name
        return name.toLowerCase().includes(searchText.toLowerCase())
    })

    orders = orders.sort(function (a, b) {
        const aname = a.patient.first_name + " " + a.patient.last_name
        const bname = b.patient.first_name + " " + b.patient.last_name
        return sort === "asc" ? aname.localeCompare(bname) : bname.localeCompare(aname)
    })

    return (
        <>
            <div className={classes.ordersContainer}>
                {orders.map((order) => {
                    return (
                        <OrderListItem key={order.id} order={order} workflow_type={workflow_type} />
                    )
                })}
            </div>
        </>
    )
}

const OrderList = withRouter(
    withCookies(
        connect(mapStateToProps)(
            withStyles(styles, { withTheme: true })(OrderListFunctionClass)
        )
    )
)
export default OrderList
