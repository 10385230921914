import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';

const styles = theme => ({
  root: {
    padding: 0,
  },
  head: {
    padding: 0,
  },
  tableRow: {
    height: '18px !important',
    width: "100%"
  },
  tableCellHeader: {
    width: "25%",
    padding: "4px 0px !important",
    paddingRight: "4px !important",
    backgroundColor: "white",//"#68CBEF",
    color: "#000 !important",
    fontSize: '10px !important',
    whiteSpace: 'nowrap', 
    textAlign: 'center',
    minHeight: '0',
  },
  lastTableCellHeader: {
    paddingRight: "0px !important",
  },
  sortLabel: {
    color: "white !important",
    fontSize: "10px !important",
  },
  sortLabelIcon: {
    marginTop: -3,
    marginBottom: -3
  },
  tableButton: {
    // padding: `${theme.spacing.unit * 1.4}px ${theme.spacing.unit}px !important`,
    padding: "4px 3px !important",
    // marginRight: "4px",
    backgroundColor: "#68CBEF !important",//"#68CBEF",
    color: "white !important",
    fontSize: '10px !important',
    whiteSpace: 'nowrap', 
    textAlign: 'center',
    width: "100%",
    borderRadius: 0,
    minHeight: 26,
    textTransform: "none"
  },
  disabled: {
    color: "#DCDCDC !important",
  }
});

class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
      this.props.onRequestSort(event, property);
    }

    disabledSortHandler = () => {
      console.log("EnhancedTableHead sorting disabled...");
    }

    render() {
      const { theadClassNames, classes, row_headers, order, orderBy } = this.props;
      var ctr = 0;

      return (
        <TableHead className={theadClassNames}>
          <TableRow className={classes.tableRow}>
            {
              row_headers.map(row => {
              ctr++;
              return (
                <React.Fragment key={row.id}>
                  <TableCell
                    className={
                      classNames(
                        classes.nowrap, 
                        classes.tableCellHeader, 
                        (ctr === row_headers.length) ? classes.lastTableCellHeader : ''
                      )
                    }
                    key={row.id}
                    numeric={row.numeric}
                    sortDirection={orderBy === row.id ? order : false}
                  >
                    <Button
                      className={classNames(classes.tableButton)}    
                      onClick={(row.sortingEnabled) ? this.createSortHandler(row.id) : this.disabledSortHandler}
                    >
                    {orderBy === row.id ?
                      <TableSortLabel
                        className={classNames(classes.sortLabel, (row.sortingEnabled) ? "" : classes.disabled)}
                        classes={{
                          icon: classes.sortLabelIcon
                        }}
                        active={orderBy === row.id}
                        direction={order}
                      >
                        {row.label}
                      </TableSortLabel>
                    :
                      <Typography className={classNames(classes.sortLabel, (row.sortingEnabled) ? "" : classes.disabled)}>
                        {row.label}
                      </Typography>
                    }
                    </Button>
                  </TableCell>
                </React.Fragment>
              );
            }, this)}
          </TableRow>
        </TableHead>
      );
    }
  }

  EnhancedTableHead.propTypes = {
    row_headers: PropTypes.array.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  export default withStyles(styles)(EnhancedTableHead);