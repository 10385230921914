import * as  globals from "components/common/GlobalConstants"

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    toolbarPlaceHolder: {
        minHeight: 43
    },
    overrideBlue: {
        background: globals.LIGHT_BLUE_GRADIENT_BG,
        color: "#01579B",
    },
    pageTitle: {
        fontSize: '24px !important',
        marginTop: '0px !important',
        fontWeight: 'bold',
        marginBottom: "5px",
    },
    channelName: {
        fontSize: '18px !important',
        marginTop: '0px !important',
        marginBottom: '0px !important',
        fontWeight: 'bold',
    },
    dateSection: {
        // marginLeft: theme.spacing.unit * 2.5,
        // marginRight: theme.spacing.unit * 2.5,
        // marginTop: theme.spacing.unit,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        textShadow: '1px 1px 2px #fff',
    },
    datetimeLabel: {
        display: "inline",
        fontSize: "14px !important",
    },
    wifiIcon: {
        // marginRight: 10,
        verticalAlign: "middle",
        padding: "12px 8px",
        // paddingRight: 0,
        marginTop: -3,
        fontSize: 25,
        marginRight: -18,
    },
    online: {
        color: "#64DD17"
    },
    offline: {
        color: "#CFD8DC"
    },
    notification_icon: {
        width: 19,
        height: 19,
    },
    notification_icon_cont: {
        right: -8,
        padding: "12px 8px",
    },
    notification_icon_cont_not_selected: {
        color: '#7E7E7E !important',
        // paddingLeft: 5,
        // paddingRight: 0,
    },
    notification_icon_cont_selected: {
        color: '#68CBEF !important',
        // paddingLeft: 5,
        // paddingRight: 0,
    },
    sync_badge: {
        top: 12,
        right: -6,
    },
    sync_badge_content_green: {
        color: '#64DD17 !important',
        width: 20,
        height: 20,
        // border: `2px red ${'#fff'}`
    },
    sync_badge_content_red: {
        color: '#f31700 !important',
        width: 20,
        height: 20,
        // border: `2px red ${'#fff'}`
    },
    sync_icon_cont: {
        right: -8,
        padding: "12px 8px",
        marginRight: 8
        // paddingRight: 5,
    },
    sync_icon_synched: {
        color: '#64DD17'
    },
    sync_icon_up: {
        color: '#64DD17'
    },
    sync_icon_down: {
        color: '#64DD17'
    },
    sync_icon_unsynched: {
        color: '#ffaa60'
    },
    sync_icon_error: {
        color: '#f31700'
    },
    backButton: {
        paddingLeft: 0,
    },
    noHoverBG: {
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    alertIcon: {
        width: 18,
        height: 18
    },
    mainButtonWhiteBorderedSM: {
        fontSize: 16,
        padding: '0px 15px',
        backgroundColor: "white",
        textTransform: 'none',
        color: "#696969",
        borderRadius: '100px',
        boxShadow: "none",
        border: "2px solid #696969",
        float: "right"
    },
    yellowPaperBG: {
        background: "#FFFBE7",
        margin: "20px 0px",
        padding: 20
    },
    printButton: {
        '& svg': {
            width: 16,
            height: 16,
            marginRight: 10
        },
        '& :disabled': {
            border: "2px solid #bdbdbd"
        }
    },
    dialogRoot: {
        marginTop: 50
    },
    blueButton: {
        width: 100,
        padding: "4px !important",
        fontWeight: "bold",
        color: "white !important",
        backgroundColor: "#68CBEF !important",
    },
    /** new */
    logo: {
        width: 120,
        marginTop: 8,
    },
    appBar: {
        minHeight: 50,
        height: 50,
        background: "#FFFFFF", //globals.LIGHT_BLUE_GRADIENT_BG,
        marginTop: "20px"
    },
    appBarPositionFixed: {
        boxShadow: "none"
    },
    appBarColorDefault: {
        backgroundColor: "white"
    },
    toolbarGutters: {
        paddingRight: 20,
        paddingLeft: 20,
    },
    callIconCont: {
        right: -8,
        padding: "12px 6px",
    },
    callIcon: {
        color: "black",
        fontSize: "26px"
        // width: 20,
        // height: 20
    },
    settingsIconCont: {
        right: -8,
        padding: "12px 6px",
    },
    settingsIcon: {
        color: "black",
        fontSize: "26px"
        // width: 20,
        // height: 20,
    },
    notificationIconCont: {
        right: -8,
        padding: "12px 6px",
    },
    notificationIcon: {
        color: "black !important",
        fontSize: "26px"
        // width: 20,
        // height: 20,
    },
    notificationBadge: {
        top: 4,
        right: 4,
        height: "10px",
        minWidth: "10px"
        // border: `2px solid #fff`,
    },
});

export default styles