import * as globals from 'components/common/GlobalConstants';
import grey from '@material-ui/core/colors/grey';

const styles = theme => ({
    root: {
        // display: 'flex',
        // fullWidth: true
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
    },
    headerInfo: {
      paddingTop: "15px",
      paddingLeft: "30px",
      paddingRight: "30px",
    },
    patientContainer: {
        paddingTop: "0px",
        paddingLeft: "30px",
        paddingRight: "30px",
    },
    list: {
        border: "1px solid rgba(0, 0, 0, 0.16)",
        marginBottom: theme.spacing.unit,
        fontSize: "12px !important",
      },
    overrideBlue: {
        background: globals.BLUE_GRADIENT_BG,
        color: "#fff",
    },
    overrideButtonBlue: {
        background: globals.LIGHT_BLUE_GRADIENT_BG,
        color: "#01579B",
    },
    avatar: {
        margin: 'auto',
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
        width: 100,
        height: 100,
        fontSize: 50,
    },
    grid_item: {
        padding: theme.spacing.unit,
    },
    margins: {
        // margin: theme.spacing.unit * 2.5,
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    warning_icon: {
        top: 50,
    },
    group: {
        margin: `${theme.spacing.unit}px 0`
    },
    swipeableTab: {
        backgroundColor: grey[50],
    },
    badge: {
        padding: `0 ${theme.spacing.unit * 2}px`,
    },
    progressBar: {
      height: 17,
    },
    // container: {
    //     display: 'flex',
    //     flexWrap: 'wrap',
    // },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 300,
    },
    table: {
        // marginTop: `${theme.spacing.unit * 2.5}px auto`,
    },
    tableRow: {
        border: 'none'
    },
    patientInfoGrid: {
        textAlign: "left",
        padding: theme.spacing.unit,
    },
    icon: {
        lineHeight: 1.2,
        fontSize: 20,
    },
    textCentered: {
        textAlign: "center"
    },
    STAT: {
        fontSize: '11px !important',
        color: "white",
        backgroundColor: '#68CBEF',
        display: 'inline',
        padding: `2px 8px`,
        borderRadius: 3,
        marginRight: 5,
    },
    FASTING: {
        fontSize: '11px !important',
        color: "white",
        backgroundColor: '#68CBEF',
        display: 'inline',
        padding: `2px 8px`,
        borderRadius: 3
    },
    drawPatientTitle: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    resetButton: {
        textTransform: 'none',
        fontSize: 14,
        borderRadius: 0,
        padding: '8px 12px',
    },
    statusNotes: {
        fontSize: '14px !important',
    },
    tabContainer: {
        // padding: `${theme.spacing.unit}px 0px`,
        padding: '0px  !important'
    },
    gridContainer: {
        marginTop: 38,
        marginBottom: 25,
    },
    patientIdentityText: {
        fontSize: '16px !important',
        color: "#707070 !important",
        fontWeight: 700,
        // padding: `${theme.spacing.unit}px 0px`,
    },
    patientBirthDate: {
        fontSize: '14px !important'
    },
    drawItemText: {
        fontSize: '12px !important',
        // fontWeight: 'bold',
        padding: `${theme.spacing.unit}px 0px`,
    },
    patientIdentityTab: {
        fontSize: '12px !important',
        fontWeight: 'bold'
        // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit}px`,
        // paddingLeft: '0px',
        // paddingRight: '0px',
    },
    patientIdentityBirthdateCellCont: {
        padding: '0px',
        border: "none",
    },
    patientIdentityLastCellCont: {
        paddingRight: `0px !important`,
    },
    tabLabel: {
        fontSize: '12px !important',
    },
    dialogPaper: {
        minWidth: "90%",
        margin: 10, // 18
    },
    dialogPaperForNotes:  {
        minWidth: "90%",
        // margin: 2, // 18

    },
    radioButton: {
      padding: "6px 12px",
    },
    patientAddress: {
        fontSize: '14px !important',
        // width: '50%',
        // float: 'left',
        // marginBottom: 20
    },
    pdfDialogContainer: {
      padding: "0px !important"
    },
    attachmentButton: {
        color : "#0277bd !important",
        padding: 0,
        fontSize: '14px !important',
        textAlign: 'left'
    },
    requisitionListCont: {
        listStyle: 'none',
    },
    requisitionList: {
        //color : "#0277bd !important",
        color : "gray !important",
        padding: "0 !important",
        marginTop: "10px",
        marginBottom: "15px",
        fontSize: '14px !important',
        textAlign: 'left',
    },
    attachmentIcon: {
        fontSize: '16px !important',
        marginRight: 5,
        // -ms-transform: rotate(20deg)
        // -webkit-transform: rotate(20deg)
        transform: "rotate(30deg)"
    }, 
    notesIcon: {
        fontSize: '16px !important',
        marginRight: 5,
        // -ms-transform: rotate(20deg)
        // -webkit-transform: rotate(20deg)
        //transform: "rotate(30deg)"
    },
    btnClosePDF: {
        zIndex: 999999,
        position: "absolute",
        right: 5,
        top: 5,
        padding: 0,
        minWidth: 30,
        textTransform: 'none'
    },
    drawPatientRemarksField: {
        border: "1px solid #E6E6E6 !important",
        margin: "0"
    },
    textFieldContainer: {
        margin: "0px !important",
        padding: "0px !important"
    },
    txtAddBarcode: {
        border: "1px solid gray"
    },
    btnAddBarcode: {
        backgroundColor: "#68CBEF",
        borderRadius: 0
    },
    switchViewButton: {
        backgroundColor: "white",
        color: "#777777",
        borderRadius: 0,
        padding: "2px 3px",
        minWidth: 40,
        paddingLeft: 10,
        paddingRight: 10,
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 2px 10px 0 rgba(0, 0, 0, 0.11)',
    },
    switchViewButtonLeft: {
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
    },
    switchViewButtonRight: {
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
    },
    switchViewButtonLabel: {
        textTransform: 'capitalize'
    },
    // revamp layout
    patientIdentityCellCont: {
        padding: "0px 20px",
        border: "none",
    },
    pleaseVerify: {
        fontWeight: 700,
        fontSize: "16px !important"
    },
    buttons: {
        marginLeft: 0,
        textTransform: 'none',
        borderRadius: 0,
    },
    mainButtonBlue: {
        fontSize: 18,
        padding: '5px 25px',
        backgroundColor: "#68CBEF",
        textTransform: 'none',
        color: "#fff",
        // margin: `${theme.spacing.unit * 2.5}px auto`,
        borderRadius: '100px',
        boxShadow: "none"
    },
    mainButtonRed: {
        fontSize: 18,
        padding: '5px 25px',
        backgroundColor: "#FF6969",
        textTransform: 'none',
        color: "#fff",
        // margin: `${theme.spacing.unit * 2.5}px auto`,
        borderRadius: '100px',
        boxShadow: "none"
    },
    mainButtonWhiteBorderedSM: {
        fontSize: 16,
        padding: '0px 15px',
        backgroundColor: "white",
        textTransform: 'none',
        color: "#696969",
        borderRadius: '100px',
        boxShadow: "none",
        border: "2px solid #696969"
    },
    yellowPaperBG: {
        background: "#FFFBE7",
        margin: "20px 0px",
        padding: 20
    },
    printButton: {
        '& svg': {            
            width: 16,
            height: 16,
            marginRight: 10
        },
        '& :disabled': {
            border: "2px solid #bdbdbd"
        }
    },
});

export default styles;
