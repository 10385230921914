import * as globals from 'components/common/GlobalConstants';
import grey from '@material-ui/core/colors/grey';

const styles = theme => ({
    root: {
        // display: 'flex',
        // fullWidth: true
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
    },
    a: {
        boxSizing: 'border-box',
        minWidth: '10em'
    },
    headerInfo: {
      paddingTop: "15px",
      paddingLeft: "15px",
      paddingRight: "15px",
    },
    patientContainer: {
        paddingTop: "15px",
        paddingLeft: "15px",
        paddingRight: "15px",
    },
    overrideBlue: {
        background: globals.BLUE_GRADIENT_BG,
        color: "#fff",
    },
    overrideButtonBlue: {
        background: globals.LIGHT_BLUE_GRADIENT_BG,
        color: "#01579B",
    },
    avatar: {
        margin: 'auto',
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
        width: 100,
        height: 100,
        fontSize: 50,
    },
    grid_item: {
        padding: theme.spacing.unit,
    },
    margins: {
        // margin: theme.spacing.unit * 2.5,
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    warning_icon: {
        top: 50,
    },
    group: {
        margin: `${theme.spacing.unit}px 0`
    },
    swipeableTab: {
        backgroundColor: grey[50],
    },
    badge: {
        padding: `0 ${theme.spacing.unit * 2}px`,
    },
    
    btnAddPhoto: {
        backgroundColor: "#68CBEF",
        borderRadius: 0,
        width: '100%',
        margin: '15px 0px',
        // display: 'flex',
        // margin: '0px auto'
    },
    btnAddPhotoIcon: {
        marginRight: 10
    },
    progressBar: {
      height: 17,
    },
    // container: {
    //     display: 'flex',
    //     flexWrap: 'wrap',
    // },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 300,
    },
    table: {
        marginTop: `${theme.spacing.unit * 2.5}px auto`,
    },
    tableRow: {
        border: 'none'
    },
    patientInfoGrid: {
        textAlign: "left",
        padding: theme.spacing.unit,
    },
    icon: {
        lineHeight: 1.2,
        fontSize: 20,
    },
    textCentered: {
        textAlign: "center"
    },
    yellowPaperBG: {
        background: "#FFFBE7",
    },
    STAT: {
        fontSize: '11px !important',
        color: 'white',
        backgroundColor: '#D20815',
        display: 'inline',
        padding: `2px 8px`,
        borderRadius: 3,
        marginRight: 5,
    },
    FASTING: {
        fontSize: '11px !important',
        color: 'white',
        backgroundColor: '#0000FF',
        display: 'inline',
        padding: `2px 8px`,
        borderRadius: 3
    },
    buttons: {
        // margin: theme.spacing.unit * 2,
        marginLeft: 0,
        // backgroundColor: "#68CBEF",
        textTransform: 'none',
        borderRadius: 0,
    },
    dialogActions: {
        padding: "20px 10px !important",
        margin: "0px"
    },
    btnClose: {
        textTransform: 'capitalize',
        padding: "8px 20px",
        fontSize: "14px !important",
        backgroundColor: globals.v2.COLORS.GRAY,
        color: "black",
        fontWeight: 500,
        marginRight: "0px",
        "&:focus": { backgroundColor: globals.v2.COLORS.GRAY + " !important" },
        "&:active": { backgroundColor: globals.v2.COLORS.GRAY + " !important" },
        "&:hover": { backgroundColor: globals.v2.COLORS.GRAY + " !important" },
        "&:disabled": { backgroundColor: globals.v2.COLORS.GRAY_DISABLED + " !important" },
    },
    btnSave: {
        textTransform: 'capitalize',
        padding: "8px 20px",
        fontSize: "14px !important",
        backgroundColor: globals.v2.COLORS.YELLOW,
        color: "black",
        fontWeight: 500,
        marginLeft: "10px",
        marginRight: "0px",
        "&:focus": { backgroundColor: globals.v2.COLORS.YELLOW + " !important" },
        "&:active": { backgroundColor: globals.v2.COLORS.YELLOW + " !important" },
        "&:hover": { backgroundColor: globals.v2.COLORS.YELLOW + " !important" },
        "&:disabled": { backgroundColor: globals.v2.COLORS.YELLOW_DISABLED + " !important" },
    },
    mainButton: {
        fontSize: 18,
        padding: '10px 15px',
        backgroundColor: "#68CBEF",
        textTransform: 'none',
        borderRadius: 0,
        display: "block",
        color: "#fff",
        margin: `${theme.spacing.unit * 2.5}px auto`,
    },
    drawPatientTitle: {
        fontSize: 14,
    },
    resetButton: {
        textTransform: 'none',
        fontSize: 14,
        borderRadius: 0,
        padding: '8px 12px',
    },
    statusNotes: {
        fontSize: '14px !important',
    },
    tabContainer: {
        // padding: `${theme.spacing.unit}px 0px`,
        padding: '0px  !important'
    },
    gridContainer: {
        marginTop: 43,
        marginBottom: 25,
    },
    patientIdentityText: {
        fontSize: '14px !important',
        padding: `${theme.spacing.unit}px 0px`,
    },
    drawItemText: {
        fontSize: '12px !important',
        // fontWeight: 'bold',
        padding: `${theme.spacing.unit}px 0px`,
    },
    patientIdentityTab: {
        fontSize: '12px !important',
        fontWeight: 'bold'
        // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit}px`,
        // paddingLeft: '0px',
        // paddingRight: '0px',
    },
    patientIdentityBirthdateCellCont: {
        padding: '0px',
        border: "none",
    },
    patientIdentityCellCont: {
        padding: `0px`,
        border: "none",
    },
    patientIdentityLastCellCont: {
        paddingRight: `0px !important`,
    },
    tabLabel: {
        fontSize: '12px !important',
    },
    dialogPaper: {
        minWidth: "90%",
        margin: 10, // 18
    },
    dialogPaperForNotes:  {
        minWidth: "90%",
        borderRadius: "8px"
    },
    radioButton: {
      padding: "6px 12px",
    },
    patientAddress: {
        fontSize: '12px !important',
        width: '50%',
        float: 'left'
    },
    pdfDialogContainer: {
      padding: "0px !important"
    },
    attachmentButton: {
        color : "#0277bd !important",
        padding: 0,
        fontSize: '14px !important',
        textAlign: 'left',
        marginBottom: "10px" 
    },
    attachmentIcon: {
        fontSize: '16px !important',
        marginRight: 5,
        // -ms-transform: rotate(20deg)
        // -webkit-transform: rotate(20deg)
        transform: "rotate(30deg)"
    }, 
    notesIcon: {
        fontSize: '16px !important',
        marginRight: 5,
        // -ms-transform: rotate(20deg)
        // -webkit-transform: rotate(20deg)
        //transform: "rotate(30deg)"
    },
    disableAddNote: {
        color: '#A6A6A6 !important'
    },
    btnClosePDF: {
        zIndex: 999999,
        position: "absolute",
        right: 5,
        top: 5,
        padding: 0,
        minWidth: 30,
        textTransform: 'none'
    },
    drawPatientRemarksField: {
        //border: "1px solid #E6E6E6 !important",
        border: "none !important",
        margin: "0",
        padding: "0 !important"
    },
    notesField: {
        fontSize: "14px",
        padding: "20px !important",
        border: "1px solid #E6E6E6",
        borderRadius: "4px"
    },
    notesFieldDisabled: {
        color: "#333333 !important",
        backgroundColor: "#F7F7F7"
    },
    deleteIcon: {
        marginRight: 'auto',
        color: 'red'
    },
    disabledDeleteIcon: {
        color: '#A6A6A6',
        marginRight: 'auto'
    },
    listContainer: {
        padding: "0 !important",
        margin: "0 !important"
    },
    requisitionList: {
        backgroundColor: 'rgb(247, 247, 247)',
        paddingLeft: 15,
        marginBottom: 5,
    },
    editIcon: {
        color: '#99999947',
        padding: 5,
        marginTop: 5,
    },
    noNote: {
        color: "#a9a9a9"
    },
    title: {
        textAlign: "center",
        fontWeight: "600",
        fontSize: "16px"
    },
    dialogTitleContainer: {
        borderBottom: "none"
    },
    dialogContentContainer: {
        padding: "0px 10px"
    },
    deleteText: {
        fontSize: "14px !important",
        fontWeight: 500,
        color: "#F24A7E !important",
        marginRight: 'auto',
        padding: "5px 16px"
    }
});

export default styles;