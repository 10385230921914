const styles = theme => ({
  dialogPaperSignature: {
    minWidth: "95%",
    margin: 5,
  },
  signatureDialogContentContainer: {
    padding: "15px !important"
  },
  signNote: {
    color: "#999",
    textAlign: "center"
  },
  signaturePadContainer: {
    height: "100%",
    border: "1px dashed #ccc",
    backgroundColor: "#F1F2F2"
  },
  signatureButton: {
    textTransform: 'none',
    borderRadius: 0,
  },
  floatLeft: {
    float: "left"
  },
});

export default styles