import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import $ from 'jquery';
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';
import { connect } from "react-redux";
import LinearProgress from '@material-ui/core/LinearProgress';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import styles from "./styles.js";

import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import ImmutableJS from "immutable";

import ApplicationBar from 'components/common/tablet/ApplicationBar';
import SimpleBottomNavigation from 'components/common/tablet/SimpleBottomNavigation';
import EnhancedTableHead from "components/common/tablet/EnhancedTableHead.js";
import NoDrawListScreen from 'components/common/tablet/NoDrawListScreen.js';
import CurrentDate from 'components/common/tablet/CurrentDate.js';

const mapStateToProps = state => {
  return { drawData: state.drawData, isLoggedIn: state.isLoggedIn};
};

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class SitesClass extends Component {
    static propTypes = {
      cookies: instanceOf(Cookies).isRequired
    };
  
    constructor(props) {
      super(props);
      const { cookies } = this.props;
      
      this.state = {
        ...this.state,  
        order: cookies.get('sitesOrder') ? cookies.get('sitesOrder') : 'asc',
        orderBy: cookies.get('sitesOrderBy') ? cookies.get('sitesOrderBy') : 'site_order',
        filteredBy: cookies.get('selectedSitesFilter') ? cookies.get('selectedSitesFilter') : "filter_all",
      }
    }

    componentDidMount() {
        console.log('Sites did mount.');

        this.convertProgressBarTransformIntoWidth();
    }

    // when page refreshed
    componentDidUpdate() {
        this.convertProgressBarTransformIntoWidth();
    }

    convertProgressBarTransformIntoWidth() {
        // force reactjs to use percentage width instead of scalex to support border radius        
        var percentage_cont = $("#overall-progress");
        var percentage_el = $("#overall-progress div:first-child");
        var percentage = percentage_cont.attr("aria-valuenow");

        percentage_el.css({
            'transform': 'none',
            'width': percentage + "%",
        });
    }
    
    handleChangeFilter = event => {
        const { cookies } = this.props;
        cookies.set('selectedSitesFilter', event.currentTarget.id, { path: '/'});

        this.setState({...this.state, filteredBy: event.currentTarget.id });
    };

    handleRequestSort = (event, property) => {
        const { cookies } = this.props;
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        cookies.set('sitesOrder', order, { path: '/'});
        cookies.set('sitesOrderBy', orderBy, { path: '/'});
        this.setState({...this.state, order, orderBy });
    };

    goToSite(url) {
      this.props.history.push(url);
    }
    
    render() {   
        const drawData = this.props.drawData; 
        
        if($.isEmptyObject(drawData.draw_list)) {
          return(<NoDrawListScreen />);
        }
        
        const { classes } = this.props;
        const {order, orderBy} = this.state;        
        const display_name = drawData.user.first_name;
        var totalDrawItems = 0; // whole site, completed and deferred
        var totalDone = 0; // whole site, including all status
        
        const SiteItem = ({data, siteDone, siteTotalDrawItems, isApproved}) => (
            <TableRow 
            key={data.site.id}>
                <TableCell 
                    onClick={() => this.goToSite(`/site/${data.id}`)}
                    className={classNames(classes.tableCell, classes.centered)} >
                    <Typography 
                        variant="body1">
                        {data.site_order}
                    </Typography>
                </TableCell>
                <TableCell 
                    onClick={() => this.goToSite(`/site/${data.id}`)}
                    className={classNames(classes.tableCell)} 
                    component="th"
                    scope="row">
                    <Typography 
                        variant="body1">
                        {data.site.name}
                    </Typography>
                </TableCell>
                <TableCell
                    onClick={() => this.goToSite(`/site/${data.id}`)}
                    className={classNames(classes.tableCell)}>
                    <Typography>{data.site.address}</Typography>
                </TableCell>
                <TableCell 
                    onClick={() => this.goToSite(`/site/${data.id}`)}
                    className={classNames(classes.tableCell, classes.centered)}> 
                    <Typography className={ classes.statusCompleted}>{(siteDone === siteTotalDrawItems && isApproved) ? "Complete" : ""}</Typography>
                </TableCell>
                <TableCell 
                    onClick={() => this.goToSite(`/site/${data.id}`)} 
                    className={classNames(classes.tableCell, classes.centered, classes.lastTableCell)}>
                    <Typography>{siteTotalDrawItems}</Typography>
                </TableCell>
                {/* <TableCell className={classNames(classes.tableCell)}></TableCell> */}
            </TableRow> 
        );

        // temp solution, it should be sorting on multi dimensional array
        const mySitesInjectedWithKeysForSorting = drawData.draw_list.map(site => {
            var immuSite = ImmutableJS.fromJS(site).toJS();
           
            var stats = {
                siteDone: 0,
                siteTotalDrawItems: 0,
                siteApproved: true,
            };
            
            site.patient_list.map(patient =>{
                patient.patient_draw.map(drawItem => {
                    if(drawItem.status === "done" || drawItem.status === "completed") {
                        stats.siteItemCompleted++;
                        totalDone++;
                        stats.siteDone++;
                    }
                    if(drawItem.status === "skipped" || drawItem.status === "refused" || drawItem.status === "unable") {
                        totalDone++;
                        stats.siteDone++;
                        
                        if((drawItem.status === "refused" || drawItem.status === "unable") && drawItem.draw_sign_off.sof_first_name === "") {
                            // if status in[refused,unable] it needs approval first
                            stats.siteApproved = false;
                        }
                    }

                    stats.siteTotalDrawItems++;
                    totalDrawItems++;
                    return '';
                });
                return'';
            }); 
            
            // will be used just for sorting
            // keys that was used under row_headers
            immuSite.site_name = immuSite.site.name;
            immuSite.address = immuSite.site.address;
            immuSite.status = (stats.siteDone === stats.siteTotalDrawItems) ? "Complete" : "";
            immuSite.no_of_draws = stats.siteTotalDrawItems;
            immuSite.siteDone = stats.siteDone;
            immuSite.siteTotalDrawItems = stats.siteTotalDrawItems;
            immuSite.isApproved = stats.siteApproved;

            return immuSite;
        });

        const mysites = stableSort(mySitesInjectedWithKeysForSorting, getSorting(order, orderBy)).map(site => {
            var el = <SiteItem key={site.id} data={site} siteDone={site.siteDone} isApproved={site.isApproved} siteTotalDrawItems={site.siteTotalDrawItems} />;
            var returnEl;
            if(this.state.filteredBy === "filter_all") {
                returnEl = true;
            } else if(this.state.filteredBy === "filter_completed" && site.siteDone === site.siteTotalDrawItems) {
                returnEl = true;
            } else if(this.state.filteredBy === "filter_with_remaining" && site.siteDone !== site.siteTotalDrawItems) {
                returnEl = true;
            }

            return((returnEl) ? el : "");
        });

        var filteredMySites = mysites.filter(function (el) {
          return el !== "";
        });

        var percentage = Math.round(totalDone/totalDrawItems * 100);

        const row_headers = [
            { id: 'site_order', numeric: false, sortingEnabled: true, label: 'Ordering' },
            { id: 'site_name', numeric: false, sortingEnabled: true, label: 'Site Name' },
            { id: 'address', numeric: false, sortingEnabled: true,  label: 'Address' },
            { id: 'status', numeric: false, sortingEnabled: true, label: 'Status' },
            { id: 'no_of_draws', numeric: false, sortingEnabled: true, label: '# of Draws' },
            // { id: 'padding', numeric: false, sortingEnabled: false, label: '' },
          ];

        return(
            <div className={classes.root}>
                <main className={classes.content}>
                    <ApplicationBar title={display_name + "'s Route"} />
                    <CurrentDate />
                    <div className={classNames(classes.margins)}>
                        <div className={classNames(classes.progressContainer)}>
                            <Typography 
                                className={classNames(classes.allowanceTop, classes.overallProgress)}
                                align="left"
                                variant="body1">
                                Overall Progress
                            </Typography>

                            <LinearProgress 
                                id="overall-progress"
                                className={classNames(
                                    classes.allowanceTopBottom, 
                                    classes.progressBar,
                                )}
                                classes={{
                                    bar: classes.progressBarContainer,
                                    colorPrimary: classes.linearColorPrimary,
                                    barColorPrimary: classes.linearBarColorPrimary //`" style="background-color: #12D003;border-radius: 40%;transform: none !important;width: ${percentage}%" class="`
                                }}
                                variant="determinate"
                                value={percentage}  />
                            
                            <Typography
                                style={{float: "left", marginLeft: "12px"}}>
                                {totalDone}/{totalDrawItems}
                            </Typography>
                            <Typography
                                align="right"
                                style={{marginRight: "12px"}}>
                                {percentage}% Complete</Typography>
                        </div>

                        <Paper>
                            <div className={classNames(classes.paddings)}>
                                <Button
                                disabled={true}
                                className={classNames(classes.filters)}>
                                Filter By:</Button>
                                <Button
                                id="filter_all"
                                className={classNames(classes.filters, this.state.filteredBy === "filter_all" ? classes.selectedFilter : "")}
                                onClick={this.handleChangeFilter}>
                                All</Button>
                                <Button
                                id="filter_completed"
                                className={classNames(classes.filters, this.state.filteredBy === "filter_completed" ? classes.selectedFilter : "")}
                                onClick={this.handleChangeFilter}>
                                Completed</Button>
                                <Button
                                id="filter_with_remaining"
                                className={classNames(classes.filters, this.state.filteredBy === "filter_with_remaining" ? classes.selectedFilter : "")}
                                onClick={this.handleChangeFilter}>
                                With Remaining Draws</Button>
                            </div>
                            <Divider light/>
                            <Table className={classes.myTable}>
                                <EnhancedTableHead
                                row_headers={row_headers}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={this.handleRequestSort}
                                />
                                <TableBody>
                                {filteredMySites.length === 0
                                ?
                                    <TableRow className={classes.tableRow}>
                                    <TableCell 
                                        className={classNames(classes.tableCell, classes.centered)} 
                                        colSpan={5}>No data to show.</TableCell>
                                    </TableRow>
                                : 
                                filteredMySites
                                }
                                </TableBody>
                            </Table>
                        </Paper>

                        {/* <StatsToolbar completed={totalItemCompleted} deferred={totalItemDeferred} remaining={totalItemPending} />
                        <Divider light />

                        <List>
                            {mysites}
                        </List> */}
                        <br/>
                        { totalDrawItems === totalDone &&
                            <div className={classNames(classes.centered)}>
                                <Typography className={classes.allSitesNote}>All Sites Completed!</Typography>
                                <Icon className={classes.greatJobIcon}>check_circle</Icon>
                            </div>
                        }
                        
                        <div className={classes.toolbar} />
                        <div className={classes.toolbar} />
                    </div>
                </main>
                <SimpleBottomNavigation />
            </div>
        );
    }
}

SitesClass.propTypes = {
    classes: PropTypes.object.isRequired,
};

const Sites = withCookies( connect(mapStateToProps)(withStyles(styles)(SitesClass)));
export default Sites;
