import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import classNames from 'classnames';
import ApplicationBar from 'components/common/mobile/ApplicationBar';
import SimpleBottomNavigation from 'components/common/mobile/SimpleBottomNavigation';
import { connect } from "react-redux";

import Icon from '@material-ui/core/Icon';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import styles from "./styles.js";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Typography from '@material-ui/core/Typography';

import { acceptDrawRequest } from "js/actions";

import ImmutableJS from "immutable";

import moment from 'moment-timezone';
import { WORK_STATUS_ACCEPTED, WORK_STATUS_REJECTED } from 'components/common/GlobalConstants.js';

const mapStateToProps = state => {
  return { 
      drawData: state.drawData,
      drawRequests: state.drawRequests
    };
};

const mapDispatchToProps = dispatch => {
    return {
        acceptDrawRequest: draw_request => dispatch(acceptDrawRequest(draw_request))
    };
};

class DrawRequestClass extends Component {

    componentDidMount() {
        // console.log('DrawRequestClass did mount.');

    }

    componentWillMount() {
        // console.log("componentWillMount");
        this.loadData();
    }
    
    componentDidUpdate(prevProps) {
        // console.log("componentDidUpdate");
        //this.loadData();
       if(prevProps.drawRequests !== this.props.drawRequests ) {
        // // console.log("prevProps.drawRequests !== this.props.drawRequests ");
        // // console.log(prevProps.drawRequests );
        // // console.log(this.props.drawRequests);
           this.loadData();
       }
    }

    loadData = () => {
        // console.log("loadData");
        // console.log(this.props.drawRequests);
        try {
            var drawRequests = ImmutableJS.fromJS(this.props.drawRequests.draw_request).toJS();

            this.setState({
                ...this.state, 
                drawRequests: drawRequests, 
            });
        } catch(e) {
            // console.log("Error on loadData()");
        }
    }

    approveDrawRequest = (approve, id) => {
        // This should applied with immutable
        var updated_draw_list = this.state.drawRequests.map(obj => {
            if(obj.id === id) {
                obj.work_status = (approve) ? WORK_STATUS_ACCEPTED : WORK_STATUS_REJECTED;
                //obj.draw_request.work_status = obj.work_status;
                obj.client_timestamp = moment().unix(); 
                obj.client_timezone = moment.tz.guess(true) || "America/New_York"; 
            }

            return obj;
        });

        // this.setState({
        //     ...this.state, 
        //     draw_list: updated_draw_list, 
        // });
        this.onUpdateDrawRequests(updated_draw_list);
    }

    onUpdateDrawRequests = (draw_requests)=> {
        // console.log("Before REDUX Update");
        // console.log(draw_requests);
        this.props.acceptDrawRequest(draw_requests);
    }

    render() {   
        const { classes } = this.props;
        // console.log("Render(): this.state.drawRequests");

        var draw_list = [];
        try {
            draw_list = (this.state.drawRequests === null || this.state.drawRequests === undefined) ? [] : this.state.drawRequests;
        } catch(e) {
            // return <Typography>No data yet</Typography>;
        }
        
        const SiteItem = ({data}) => (
            <TableRow 
                key={data.id}
                className={(data.work_status === undefined || data.work_status === "") ? "" : ((data.work_status === WORK_STATUS_ACCEPTED) ? classes.rowAccepted : classes.rowRejected)}>
                <TableCell className={classNames(classes.tableCell, classes.sitesTableCell)}>
                    <div className={classes.siteInfo}>
                        <Typography 
                            className={classNames(classes.siteName, (data.work_status === undefined || data.work_status === "") ? "" : ((data.work_status ===WORK_STATUS_ACCEPTED) ? classes.rowTextAccepted : classes.rowTextRejected))} 
                            variant="body1">
                            {moment(data.draw_request.collection_date, "MM-DD-YYYY").format("LLLL")}
                        </Typography>
                        <Typography className={classNames(classes.siteAddress, (data.work_status === undefined || data.work_status === "") ? "" : ((data.work_status ===WORK_STATUS_ACCEPTED) ? classes.rowTextAccepted : classes.rowTextRejected))}>
                            {data.draw_request.patient.address} 
                        </Typography>
                    </div>
                    {(data.work_status === undefined || data.work_status === "")
                    ?
                    <div className={classes.optionsParentContainer}>
                        <div
                            className={classes.optionsContainer}
                            onClick={() => this.approveDrawRequest(true, data.id)}
                        >
                            <Icon className={classNames(classes.optionsIcon, classes.acceptIcon)}>check</Icon>
                        </div>
                        <div
                            className={classes.optionsContainer}
                            onClick={() => this.approveDrawRequest(false, data.id)}
                        > 
                            <Icon className={classNames(classes.optionsIcon, classes.rejectIcon)}>close</Icon>
                        </div>
                    </div>
                    :
                    <div className={classes.optionsContainer}>
                        <Typography className={(data.work_status ===WORK_STATUS_ACCEPTED) ? classes.accepted : classes.rejected}>{data.work_status}</Typography>
                    </div>                    
                    }
                </TableCell>
            </TableRow> 
        );

        // console.log(draw_list);
        const draw_request = draw_list.map(data => {
            return <SiteItem key={data.id} data={data} />;
        });

        return(
            <div className={classes.root}>
                <main className={classes.content}>
                    <ApplicationBar isInfoHidden={true} />
                    <div className={classNames(classes.schedulesMainContainer)}>
                        <div>
                            <List classes={{root: classNames(classes.listUL, classes.listULMainHeader)}}>
                                <ListItem>
                                    <ListItemText
                                        classes={{
                                            root: classes.listItemRoot,
                                            primary: classes.schedulesMainHeader,
                                            secondary: classes.note
                                        }}
                                        primary="Draw Request"
                                        secondary="Please accept/reject the following Draw Request Items."
                                    />
                                </ListItem>
                            </List>
                        </div>
                        <div className={classNames(classes.settingsContent)}>                            
                            <Paper>
                                <Table className={classes.myTable}>
                                    <TableHead>
                                        <TableRow className={classes.tableRow}>
                                            <TableCell className={classNames(classes.tableCellHeader)}>
                                                <Typography className={classNames(classes.tableCellHeaderTitle)}>
                                                    Request Items
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {draw_request.length === 0
                                    ?
                                        <TableRow className={classes.tableRow}>
                                            <TableCell className={classes.sitesSortableCell}>
                                                <Typography className={classNames(classes.tableCell, classes.centered)} variant="body2">No Draw Request.</Typography>
                                            </TableCell>
                                        </TableRow>
                                    : 
                                    draw_request
                                    }
                                    </TableBody>
                                </Table>
                            </Paper>
                        </div>
                    </div>
                    <div className={classes.toolbar} />
                    <div className={classes.toolbar} />
                </main>
                <SimpleBottomNavigation />
            </div>
        );
    }
}

DrawRequestClass.propTypes = {
    classes: PropTypes.object.isRequired,
};

const ScheduleSettings = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DrawRequestClass));
export default ScheduleSettings;