import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import ApplicationBar from 'components/common/mobile/ApplicationBar';
import SimpleBottomNavigation from 'components/common/mobile/SimpleBottomNavigation';
import { connect } from "react-redux";

import Button from '@material-ui/core/Button';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Grid from '@material-ui/core/Grid';
import moment from 'moment-timezone';

// import TimeKeeper from 'react-timekeeper';
import ImmutableJS from "immutable";

import styles from "./styles.js";

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Calendar from 'react-calendar';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import SnackBarPopup from 'components/common/mobile/SnackBarPopup.js';
import uuid from "uuid";
import $ from 'jquery';
import ConfirmDialog from 'components/common/mobile/ConfirmDialog';
// import LoadingSpinner from "components/common/mobile/LoadingSpinner.js";
// import InputLabel from '@material-ui/core/InputLabel';
// import OtherRadio from 'components/common/controls/OtherRadio'
import TextField from '@material-ui/core/TextField';
// import TextField from '@material-ui/core/TextField';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

import {
    isEmptyObject,
    addSchedule,
    updateSchedules,
    deleteSchedule
} from "js/actions/index";
import { Icon } from '@material-ui/core';

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const mapStateToProps = state => {
    return {
        drawData: state.drawData,
        scheduleData: state.scheduleData,
        onlineStatus: state.onlineStatus
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addSchedule: schedule => dispatch(addSchedule(schedule)),
        updateSchedules: schedule => dispatch(updateSchedules(schedule)),
        deleteSchedule: schedule => dispatch(deleteSchedule(schedule)),
    };
};

const isEqual = require("react-fast-compare");
// var dateToInterval;

class AddEditSchedulesClass extends Component {
    constructor(props) {
        super(props);

        this.preset_days = {
            "everyday": { "label": "Every Day", "values": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"] },
            "weekdays": { "label": "Week Days", "values": ["Mon", "Tue", "Wed", "Thu", "Fri"] },
            "weekends": { "label": "Week Ends", "values": ["Sat", "Sun"] }
        }

        var now = moment(this.formatDate(Date.now()), "YYYY-MM-DD").format('YYYY-MM-DD')

        this.state = {
            ...this.state,
            now: now,
            editScheduleDaysDialogOpened: false,
            startTimeDialogOpened: false,
            endTimeDialogOpened: false,
            startDayDialogOpened: false,
            endDayDialogOpened: false,
            selectedSchedule: {},
            date_from: "",
            date_to: "",
            date_from_display: "",
            date_to_display: "",
            time_from: "06:00:00",
            time_to: "15:00:00",
            selected_days: [],
            selectedView: "workday", // ["workday", "timeOff"],
            presetDays: {},
            selectedPresetDay: "",
            presetDaysDialogOpened: false,
            allDay: false,
            time_from_temp: "06:00:00",
            time_to_temp: "15:00:00",
            repeatDialogOpened: false,
            repeatEnabled: false,
            repeatOptions: {},
            repeatEnabledValue: "every_day",
            snackbarOpened: false,
            snackbarMessage: "",
            snackbarVariant: "default",
            timeKeeperEndTimeKey: uuid.v4(),
            enabledDaysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            showDeleteScheduleDialog: false,
            confirmDeleteScheduleDialogTitle: "",
            confirmDeleteScheduleDialogMessage: "",
            confirmDeleteScheduleDialogActionText: "",
            deleteScheduleActionHandler: "",
            deleteSchedulePostActionHandler: "",
            timeHasError: false,
        }
    }

    // sort, lowercase, should be same order
    compareDaysOfWeek(preset_days, schedule_days) {
        preset_days = preset_days.sort();
        schedule_days = schedule_days.sort();
        // console.log("sorted preset_days");
        // console.log(preset_days);
        // console.log("sorted schedule_days");
        // console.log(schedule_days);

        if (preset_days.length !== schedule_days.length) return false;

        for (var i = preset_days.length; i--;) {
            if (preset_days[i].toLowerCase() !== schedule_days[i].toLowerCase())
                return false;
        }

        return true;
    }

    getSelectedPresetDaysKey(preset_days, schedule_days) {
        var selected_preset_days = "";

        for (var objectKey in preset_days) {
            var preset_days_entry = preset_days[objectKey].values;
            // Monday => Mon
            var schedule_days_short = schedule_days.map(item => item.substring(0, 3));

            if (this.compareDaysOfWeek(preset_days_entry, schedule_days_short)) {
                selected_preset_days = objectKey;
            }
        }

        return selected_preset_days;
    }

    consolidateScheduleData = (schedule_data) => {
        var schedule_data_copy = ImmutableJS.fromJS(schedule_data).toJS();
        var consolidatedScheduleData = [];

        schedule_data_copy.schedules.forEach(schedule => {
            // if(!schedule.client_deleted) {
            schedule.section = "schedules";
            schedule.section_original = schedule.section;
            consolidatedScheduleData.push(schedule);
            // }
        });

        schedule_data_copy.non_working_days.personal.forEach(schedule => {
            // if(!schedule.client_deleted) {
            schedule.section = "personal";
            schedule.section_original = schedule.section;
            consolidatedScheduleData.push(schedule);
            // }
        });
        // console.log(consolidatedScheduleData);
        return consolidatedScheduleData;
    }

    // loop until date_to
    // push Day of Week if not yet added
    // end loop if 7 Days is completed or date_to is done
    iterateDaysBetweenDates = (date_from, date_to) => {
        var enabledDaysOfWeek = [];
        var a = moment(date_from);
        var b = moment(date_to);

        for (var m = moment(a); m.isSameOrBefore(b); m.add(1, 'days')) {
            if (!enabledDaysOfWeek.includes(m.format('dddd'))) {
                enabledDaysOfWeek.push(m.format('dddd'));
            }

            // console.log(m.format('MM-DD-YYYY') + " = " + m.format('dddd'));
            if (enabledDaysOfWeek.length === 7) break;
        }

        // console.log("iterateDaysBetweenDates loop returned");
        return enabledDaysOfWeek;
    }

    onWeekRepeatIntervalChanged = (event) => {
        var val = event.target.value;
        val = val !== "" && val < 2 ? 2 : val;
        this.setState({ ...this.state, tempRepeatWeekIntervalValue: val })
    }

    componentDidUpdate(prevProps) {
        // console.log("componentDidUpdate()"); 
        // console.log("!isEqual( prevProps.scheduleData, this.props.scheduleData ) = " + (!isEqual( prevProps.scheduleData, this.props.scheduleData ))); 
        // console.log(this.props.scheduleData);
        // console.log("..."); 
        // console.log(); 
        if (!isEqual(prevProps.scheduleData, this.props.scheduleData)) {
            // console.log("loadData()"); 
            this.loadData();
        }
    }

    loadData = () => {
        var scheduleData = ImmutableJS.fromJS(this.props.scheduleData).toJS();

        const scheduleId = this.props.match.params.scheduleId;
        const selectedDate = this.props.match.params.date;
        var schedule = this.consolidateScheduleData(scheduleData).find(schedule => schedule.id === scheduleId);
        var isLocked = false;
        // var schedule_settings = scheduleData.settings;

        // if(scheduleData.settings) {
        //     console.log(scheduleData.settings.repeat_options);
        // }
        // console.log(this.props.match.params);
        // console.log(scheduleData);
        // console.log("schedule find from consolidateScheduleData");
        // console.log(schedule);
        // console.log("schedule.date_from")
        // console.log(schedule.date_from)
        console.log("params ScheduleId: " + scheduleId)
        console.log("params Date: " + selectedDate)
        console.log("selectedSchedule")
        console.log(schedule)

        if(parseInt(scheduleId) !== 0 && schedule === undefined) {
            console.log("REDIRECT: scheduleId modified after save...")
            this.props.history.push("/schedules");
        }

        if (schedule === undefined) {
            schedule = {
                "section": "schedules",
                "section_original": "schedules",
                "id": "0",
                "order": 0,
                "type": "recurring",
                "label": "",
                "date_from": moment(selectedDate, "MMMM D, YYYY").format('YYYY-MM-DD'), // new Date(),
                "date_to": moment(selectedDate, "MMMM D, YYYY").format('YYYY-MM-DD'), //moment(selectedDate, "MMMM D, YYYY").add(1, 'year').format('YYYY-MM-DD'), // moment(selectedDate, "MMMM D, YYYY").add(1, 'year').format('YYYY-MM-DD'), // new Date(),
                "all_day": false,
                "time_from": "06:00:00",
                "time_to": "15:00:00",
                "client_timezone": moment.tz.guess(true) || "America/New_York",
                "days": [moment(selectedDate, "MMMM-D-YYYY").format('dddd')],
                "repeat_type": "every_week",
                "repeat_interval": null,
                "client_deleted": false,
                "locked": false,
            };
        } else {
            var date_now = moment().format("YYYY-MM-DD");
            var date_to = schedule.date_to;
            var is_past_date = moment(date_to, "YYYY-MM-DD").isBefore(date_now);
            console.log(date_now + " - " + date_to)
            console.log("is past date: " + is_past_date)
            isLocked = is_past_date;
        }

        console.log("schedule")
        console.log(schedule)

        this.setState({
            ...this.state,
            selectedSchedule: schedule,
            selectedView: (schedule.section === "schedules") ? "workday" : "timeOff", // for display
            type: schedule.type,
            date_from: schedule.date_from,
            date_to: schedule.date_to,
            allDay: this.isAllDay(schedule.time_from, schedule.time_to),
            time_from: schedule.time_from,
            time_to: schedule.time_to,
            time_from_display: moment(schedule.time_from, "hh:mm:ss").format('LT'),
            time_to_display: moment(schedule.time_to, "hh:mm:ss").format('LT'),
            date_from_display: (schedule.date_from !== "") ? moment(schedule.date_from).format('ll') : "",
            date_to_display: (schedule.date_to !== "") ? moment(schedule.date_to).format('ll') : "",
            selected_days: schedule.days,
            presetDays: this.preset_days,
            selectedPresetDay: this.getSelectedPresetDaysKey(this.preset_days, schedule.days),
            repeatOptions: scheduleData.settings ? scheduleData.settings.repeat_options : [],
            repeatEnabled: (schedule.type === "recurring" && parseInt(schedule.id) != 0) ? true : false, // false by default if new schedule
            repeatEnabledValue: (schedule.repeat_type === "") ? "every_week" : schedule.repeat_type,
            tempRepeatEnabledValue: (schedule.repeat_type === "") ? "every_week" : schedule.repeat_type,
            weekRepeatInterval: (schedule.repeat_interval === null) ? 2: schedule.repeat_interval,
            tempRepeatWeekIntervalValue: (schedule.repeat_interval === null) ? 2: schedule.repeat_interval,
            enabledDaysOfWeek: this.iterateDaysBetweenDates(schedule.date_from, schedule.date_to), // define which Days of the Weeks are enabled based on date_from and date_to
            locked: isLocked,
            // hasOrders: schedule.locked ? schedule.locked : false, //: if the schedule has appointment, show warning when deleting
            client_deleted: schedule.client_deleted
        });
    }

    componentDidMount() {
        this.loadData();
    }

    handleOpenStartTimeDialog = () => {
        if (this.edittingNotAllowed()) {
            return
        }
        this.setState({ ...this.state, startTimeDialogOpened: true, time_from_temp: this.state.time_from });
    }

    handleCloseStartTimeDialog = () => {
        this.closeStartTimeDialog(false);
        //        this.setState({...this.state, startTimeDialogOpened: false });
    }

    closeStartTimeDialog = (revert) => {
        if (revert) {
            this.setState({
                ...this.state,
                startTimeDialogOpened: false
            });
        } else {
            // console.log("REVERTING " + this.state.time_to );
            this.setState({
                ...this.state,
                startTimeDialogOpened: false,
                time_from: this.state.time_from_temp,
                time_from_display: moment(this.state.time_from_temp, "kk:mm:ss").format('LT'),
            });
        }
    }

    updateStartTime = (newTime24Hrs) => {
        if (moment(newTime24Hrs, "kk:mm:ss").isSameOrAfter(moment(this.state.time_to, "kk:mm:ss"))) {
            this.setState({
                ...this.state,
                // time_to: moment(this.state.time_from, "kk:mm:ss").format('kk:mm:ss'),
                // time_to_display: moment(this.state.time_from, "kk:mm:ss").format('LT'),
                // snackbarOpened: true,
                // snackbarVariant: "warning",
                // snackbarMessage: "End time should be later than the start time."
                time_from: moment(newTime24Hrs, "kk:mm:ss").format('kk:mm:ss'),
                time_from_display: moment(newTime24Hrs, "kk:mm:ss").format('LT'),
                // time_to: moment(newTime24Hrs, "kk:mm:ss").format('kk:mm:ss'),
                // time_to_display: moment(newTime24Hrs, "kk:mm:ss").format('LT'),
                timeHasError: true,
            });
            // this.setState({
            //     ...this.state,
            //     time_from: moment(newTime24Hrs, "kk:mm:ss").format('kk:mm:ss'),
            //     time_from_display: moment(newTime24Hrs, "kk:mm:ss").format('LT'),
            //     time_to: moment(newTime24Hrs, "kk:mm:ss").format('kk:mm:ss'),
            //     time_to_display: moment(newTime24Hrs, "kk:mm:ss").format('LT'),
            //     //startTimeDialogOpened: false 
            // });
        } else {
            this.setState({
                ...this.state,
                time_from: moment(newTime24Hrs, "kk:mm:ss").format('kk:mm:ss'),
                time_from_display: moment(newTime24Hrs, "kk:mm:ss").format('LT'),
                timeHasError: false,
                // startTimeDialogOpened: false 
            });
        }
    }

    handleOpenStartDayDialog = () => {
        var date_from = moment(this.state.date_from, "YYYY-MM-DD").format('YYYY-MM-DD')
        if (this.edittingNotAllowed()) {
            console.log("Editting is now allowed")
            return
        }

        if (moment(date_from).isBefore(moment(this.state.now))) {
            console.log(moment(date_from))
            console.log(moment(this.state.now))
            console.log("Cannot be edited. Date-From is before current date.")
            return
        }

        this.setState({ ...this.state, startDayDialogOpened: true });
    }

    handleCloseStartDayDialog = () => {
        this.setState({ ...this.state, startDayDialogOpened: false });
    }

    handleOpenEndTimeDialog = () => {
        // console.log("SAVING " +this.state.time_to ); 
        if (this.edittingNotAllowed()) {
            return
        }
        this.setState({ ...this.state, endTimeDialogOpened: true, time_to_temp: this.state.time_to });
    }

    handleCloseEndTimeDialog = () => {
        this.closeEndTimeDialog(false);
        // this.setState({...this.state, endTimeDialogOpened: false });
    }

    closeEndTimeDialog = (revert) => {
        if (revert) {
            this.setState({
                ...this.state,
                endTimeDialogOpened: false
            });
        } else {
            // console.log("REVERTING " + this.state.time_to );
            this.setState({
                ...this.state,
                endTimeDialogOpened: false,
                time_to: this.state.time_to_temp,
                time_to_display: moment(this.state.time_to_temp, "kk:mm:ss").format('LT'),
            });
        }
    }

    updateEndTime = (newTime24Hrs) => {
        if (moment(newTime24Hrs, "kk:mm:ss").isSameOrBefore(moment(this.state.time_from, "kk:mm:ss"))) {
            // display warning and disable save button
            this.setState({
                ...this.state,
                // time_to: moment(this.state.time_from, "kk:mm:ss").format('kk:mm:ss'),
                // time_to_display: moment(this.state.time_from, "kk:mm:ss").format('LT'),
                // snackbarOpened: true,
                // snackbarVariant: "warning",
                // snackbarMessage: "End time should be later than the start time.",
                time_to: moment(newTime24Hrs, "kk:mm:ss").format('kk:mm:ss'),
                time_to_display: moment(newTime24Hrs, "kk:mm:ss").format('LT'),
                timeHasError: true
            });
        } else {
            this.setState({
                ...this.state,
                time_to: moment(newTime24Hrs, "kk:mm:ss").format('kk:mm:ss'),
                time_to_display: moment(newTime24Hrs, "kk:mm:ss").format('LT'),
                timeHasError: false,
            });
        }
    }

    handleOpenEndDayDialog = () => {
        //var date_to = moment(this.formatDate(this.state.date_to), "YYYY-MM-DD").format('YYYY-MM-DD')
        var date_to = moment(this.state.date_to, "YYYY-MM-DD").format('YYYY-MM-DD')
        // var now = moment(this.formatDate(Date.now()), "YYYY-MM-DD").format('YYYY-MM-DD')
        // console.log("handleOpenEndDayDialog")
        // console.log("Now " + now)
        // console.log("date_from " + date_to)

        if (this.edittingNotAllowed()) {
            return
        }

        // possibly we can remove this, falls to locked condition for past dates
        if (moment(date_to).isBefore(moment(this.state.now))) {
            return
        }

        this.setState({ ...this.state, endDayDialogOpened: true });
    }

    handleCloseEndDayDialog = () => {
        this.setState({ ...this.state, endDayDialogOpened: false });
    }

    presetWeekDays() {
        return [
            { key: "sunday", code: "S", presetCode: "Sun", label: "Sunday" },
            { key: "monday", code: "M", presetCode: "Mon", label: "Monday" },
            { key: "tuesday", code: "T", presetCode: "Tue", label: "Tuesday" },
            { key: "wednesday", code: "W", presetCode: "Wed", label: "Wednesday" },
            { key: "thursday", code: "T", presetCode: "Thu", label: "Thursday" },
            { key: "friday", code: "F", presetCode: "Fri", label: "Friday" },
            { key: "saturday", code: "S", presetCode: "Sat", label: "Saturday" }
        ];
    }

    goToSchedulePage() {
        this.props.history.push("/schedules");
    }

    updateSelectedDays = (day) => {
        var day_exists = this.state.selected_days.map(x => x.toLowerCase()).indexOf(day.key);

        var inArray = day_exists > -1;
        var current_selected_days = this.state.selected_days;
        if (inArray) {
            current_selected_days.splice(current_selected_days.indexOf(day.label), 1);
        } else {
            current_selected_days.push(day.label);
        }

        var selected_preset_day = this.getSelectedPresetDaysKey(this.state.presetDays, current_selected_days.map(item => item.substring(0, 3)));
        this.setState({ ...this.state, selected_days: current_selected_days, selectedPresetDay: selected_preset_day })
    }

    onUpdate = () => {
        var selectedSchedule = ImmutableJS.fromJS(this.state.selectedSchedule).toJS();
        selectedSchedule.section = (this.state.selectedView === "timeOff") ? "personal" : "schedules" // will be used to check if schedules or personal
        selectedSchedule.category = (this.state.selectedView === "timeOff") ? "leave" : "workDay"
        selectedSchedule.type = (this.state.repeatEnabled) ? "recurring" : "custom" // need to work on this

        selectedSchedule.date_from = moment(this.state.date_from).format('YYYY-MM-DD');//.format('L');
        selectedSchedule.date_to = moment(this.state.date_to).format('YYYY-MM-DD');//.format('L');
        //selectedSchedule.all_day =  this.state.allDay;
        selectedSchedule.time_from = this.state.time_from;
        selectedSchedule.time_to = this.state.time_to;
        // selectedSchedule.repeat_interval = this.state.weekRepeatInterval !== undefined ? this.state.weekRepeatInterval : 2
        selectedSchedule.repeat_interval = this.state.repeatEnabledValue === "every_n_week" ? this.state.weekRepeatInterval : null;
        // double check if current selected days of weeks are inrange of days in date_from and date_to
        var selected_days_in_range = this.state.selected_days.filter(day => {
            return this.state.enabledDaysOfWeek.includes(day);
        });
        selectedSchedule.days = selected_days_in_range;
        selectedSchedule.repeat_type = this.state.repeatEnabledValue;

        if (this.state.selectedSchedule.id === "0") { // New schedule 
            selectedSchedule.client_deleted = false;
            // prevent adding new schedule if behind today
            var date_from = moment(this.state.date_from, "YYYY-MM-DD").format('YYYY-MM-DD')
            if (this.edittingNotAllowed()) {
                return
            }
            // already blocked in UI, but should add popup warning
            // schedule cannot be added because date_from isBefore now
            if (moment(date_from).isBefore(moment(this.state.now))) {
                return
            }
            this.props.addSchedule(selectedSchedule);
        } else { // Update schedule 
            // console.log(selectedSchedule)
            this.props.updateSchedules(selectedSchedule);
        }
        // console.log(selectedSchedule);

        this.props.history.push("/schedules");
    }

    onDeleteSchedule = () => {
        var selectedSchedule = ImmutableJS.fromJS(this.state.selectedSchedule).toJS();
        this.props.deleteSchedule(selectedSchedule);
        this.props.history.push("/schedules");
    }

    handleChangeRepeatEnabled = (val) => {
        // console.log("handleChangeRepeatEnabled: ")
        // console.log(val)
        var newVal = !this.state.repeatEnabled;
        var date_to = "";
        // if(this.state.date_from === this.state.date_to) {
        if (newVal) {
            date_to = moment(this.state.date_from, "YYYY-MM-DD").add(1, 'year').format('YYYY-MM-DD');
            $("#date-to-typography").removeClass('withAnimation');
            void $("#date-to-typography").width();
            $("#date-to-typography").addClass('withAnimation');
            // } else {
            //     console.log("revert date_to > date_from or now inside this.state.date_from === this.state.date_to")
            //     date_to = moment(this.state.date_from, "YYYY-MM-DD").format('YYYY-MM-DD');
            // }
        } else {
            console.log("revert date_to > date_from or now ELSE if date_from <> date_to");
            var date_now = moment(Date.now()).format('YYYY-MM-DD');
            var date_from = moment(this.state.date_from, "YYYY-MM-DD").format('YYYY-MM-DD');
            // console.log("date_now: " + date_now);
            // console.log("date_from: " + date_from);

            date_to = moment(date_from).isBefore(moment(date_now)) ? date_now : date_from;
            $("#date-to-typography").removeClass('withAnimation');
            void $("#date-to-typography").width();
            $("#date-to-typography").addClass('withAnimation');
        }

        // dateToInterval = setTimeout(function() { 
        //     $("#date-to-typography").addClass('noAnimation');
        //     $("#date-to-typography").removeClass('withAnimation');
        // }, 4000);

        // reset to repeat everyday every ChangeRepeatEnabled
        // should be method

        // var selectedDays = this.state.presetDays["everyday"].values.map(day => {
        //     var filtered_day = this.presetWeekDays().find(o => o.presetCode === day);
        //     return filtered_day.label;
        // });

        this.setState({
            ...this.state,
            repeatEnabled: newVal,
            date_to: date_to,
            date_to_display: (date_to !== "") ? moment(date_to).format('ll') : "",
            enabledDaysOfWeek: this.iterateDaysBetweenDates(this.state.date_from, date_to),
            selected_days: [moment(this.state.date_from).format('dddd')],
            // selectedPresetDay: "everyday",
            // selected_days: selectedDays,
            // repeatEnabledValue: "every_day",
            // tempRepeatEnabledValue: "every_day",
        });
    }

    handleChangeRepeatOptions = event => {
        this.setState({
            ...this.state,
            tempRepeatEnabledValue: event.target.value,
            // repeatDialogOpened: !this.state.repeatDialogOpened
        });

        // this.setState({
        //     ...this.state, 
        //     repeatEnabledValue: event.target.value, 
        //     repeatDialogOpened: !this.state.repeatDialogOpened
        // });
    }

    // onWeekRepeatIntervalChange = (interval) => {
    //     this.setState({
    //         ...this.state, weekRepeatInterval: interval
    //     })
    //     console.log(interval)
    // }

    okChangeRepeatOptions = event => {
        var everydaySelectedDays = this.state.presetDays["everyday"].values.map(day => {
            var filtered_day = this.presetWeekDays().find(o => o.presetCode === day);
            return filtered_day.label;
        });

        this.setState({
            ...this.state,
            repeatEnabledValue: this.state.tempRepeatEnabledValue,
            weekRepeatInterval: this.state.tempRepeatWeekIntervalValue === "" ? 2 : this.state.tempRepeatWeekIntervalValue,
            tempRepeatWeekIntervalValue: this.state.tempRepeatWeekIntervalValue === "" ? 2 : this.state.tempRepeatWeekIntervalValue,
            repeatDialogOpened: false,
            selectedPresetDay: ["every_day"].includes(this.state.tempRepeatEnabledValue) ? "everyday" : "",                        
            selected_days: ["every_day"].includes(this.state.tempRepeatEnabledValue) ? everydaySelectedDays : [moment(this.state.date_from).format('dddd')],
        });

        // this.setState({
        //     ...this.state, 
        //     repeatEnabledValue: event.target.value, 
        //     repeatDialogOpened: !this.state.repeatDialogOpened
        // });
    }


    handleSwitchView = (switchType) => {
        this.setState({ ...this.state, selectedView: switchType });
    }

    formatDate = (date) => {
        // var d = new Date(date),
        //     month = '' + (d.getMonth() + 1),
        //     day = '' + d.getDate(),
        //     year = d.getFullYear();

        // if (month.length < 2) 
        //     month = '0' + month;
        // if (day.length < 2) 
        //     day = '0' + day;
        // console.log("[year, month, day].join('-')")
        // console.log([year, month, day].join('-'))
        // return [year, month, day].join('-');
        //console.log( "Converting this date '" + date + "' to moment(new Date(date)).format('YYYY-MM-DD')")
        //console.log(moment(new Date(date)).format('YYYY-MM-DD'))
        return moment(new Date(date)).format('YYYY-MM-DD');
    }

    onDateChangeFrom = (date) => {
        // console.log("moment(this.state.date_to).isBefore(moment(date))");
        // console.log("date_from: " + moment(date).format('ll'));
        // console.log("date_to: " + moment(this.state.date_to).format('ll'));
        // console.log(moment(this.state.date_to).isBefore(moment(date)));
        date = moment(date, "YYYY-MM-DD").format('YYYY-MM-DD')
        var now = moment(this.formatDate(Date.now()), "YYYY-MM-DD").format('YYYY-MM-DD')
        // console.log("onDateChangeFrom")
        // console.log(now)
        // console.log(date)

        // already block in Calendar UI
        if (moment(date).isBefore(moment(now))) {
            console.log("Cannot set Date From if before now")
            return
        }

        // throw error if date isAfter date_to
        // no need throw, date_to is force to be adjusted

        this.setState(
            {
                ...this.state,
                date_from: date,
                date_from_display: moment(date).format('ll'),
                startDayDialogOpened: false,
                enabledDaysOfWeek: this.iterateDaysBetweenDates(date, this.state.date_to),  
            },
            () => {
                // Adjust if date_from is after date_to
                if (moment(this.state.date_from).isAfter(moment(this.state.date_to))) {
                    // just to have available days for selection
                    var selectedDays = this.state.presetDays["everyday"].values.map(day => {
                        var filtered_day = this.presetWeekDays().find(o => o.presetCode === day);
                        return filtered_day.label;
                    });

                    this.setState({
                        ...this.state,
                        date_to: date,
                        date_to_display: moment(date).format('ll'),
                        selected_days: selectedDays, //[moment(date).format('dddd')],     
                        enabledDaysOfWeek: this.iterateDaysBetweenDates(date, date),
                    })
                }
            });

        // if(moment(this.state.date_to).isBefore(moment(date))) {
        //     this.setState({
        //         ...this.state, 
        //         date_from: date, 
        //         date_from_display: moment(date).format('ll'),
        //         date_to: date, 
        //         date_to_display: moment(date).format('ll'),
        //         startDayDialogOpened: false,
        //         enabledDaysOfWeek: this.iterateDaysBetweenDates(date, date)
        //     });
        // } else {
        //     this.setState({
        //         ...this.state, 
        //         date_from: date, 
        //         date_from_display: moment(date).format('ll'),
        //         startDayDialogOpened: false,
        //         enabledDaysOfWeek: this.iterateDaysBetweenDates(date, this.state.date_to)
        //     });
        // }
    }

    onDateChangeTo = (date) => {
        date = moment(date, "YYYY-MM-DD").format('YYYY-MM-DD')

        var date_now = moment(Date.now()).format('YYYY-MM-DD');
        var date_from = moment(this.state.date_from, "YYYY-MM-DD").format('YYYY-MM-DD');
        var min_date = moment(date_from).isBefore(moment(date_now)) ? date_now : date_from;

        // catch inside method, already blocked in UI
        if (moment(date).isBefore(moment(min_date))) {
            console.log("Cannot update date_to, date_to is before date_from: " + moment(date).format('YYYY-MM-DD'))
        } else {
            this.setState({
                ...this.state,
                date_to: date,
                date_to_display: moment(date).format('ll'),
                endDayDialogOpened: false,
                enabledDaysOfWeek: this.iterateDaysBetweenDates(this.state.date_from, date)
            });
        }
    }

    tiggerPresetsDialog = () => {
        this.setState({ ...this.state, presetDaysDialogOpened: !this.state.presetDaysDialogOpened });
    }

    tiggerRepeatDialog = (opened) => {
        if(opened) {
            this.setState({ ...this.state, repeatDialogOpened: true });
        } else {
            this.setState({
                ...this.state,
                repeatDialogOpened: false,            
                tempRepeatEnabledValue: this.state.repeatEnabledValue,
                tempRepeatWeekIntervalValue: this.state.weekRepeatInterval,
            });
        }
    }

    isAllDay = (time_from, time_to) => {
        // console.log("all day?");
        // console.log(time_from);
        // console.log(time_to);        
        // console.log((time_from === '00:00:00' && time_to === '23:59:59'));
        return (time_from === '00:00:00' && time_to === '23:59:59');
    }

    handleChangePresetDays = event => {
        // console.log("this.state.presetDays[event.target.value].values");
        // console.log(this.state.presetDays[event.target.value].values);
        // console.log("this.presetWeekDays()");
        // console.log(this.presetWeekDays());

        var selectedDays = this.state.presetDays[event.target.value].values.map(day => {
            var filtered_day = this.presetWeekDays().find(o => o.presetCode === day);
            return filtered_day.label;
        });

        this.setState({
            ...this.state,
            selectedPresetDay: event.target.value,
            presetDaysDialogOpened: !this.state.presetDaysDialogOpened,
            selected_days: selectedDays
        });
    }

    edittingNotAllowed = () => {
        return !this.props.onlineStatus || this.state.locked || this.state.client_deleted;
        // if(!this.props.onlineStatus || this.state.locked || this.state.client_deleted) { // STRICTLY OFFLINE editing of schedule is not allowed
        //     return true // edittingNotAllowed = true 
        // }

        // condition already in state.locked
        // return moment(this.state.date_from).isBefore(moment(this.state.now))
    }

    handleChangeAllDay = (val) => {

        // if(this.edittingNotAllowed()) {
        //     return
        // }

        var newVal = !this.state.allDay;
        var time_from_temp = "";
        var time_to_temp = "";
        var time_from = "";
        var time_to = "";
        var time_from_display = "";
        var time_to_display = "";

        if (newVal) {
            time_from_temp = this.state.time_from;
            time_to_temp = this.state.time_to;
            time_from = "00:00:00";
            time_to = "23:59:59";
            time_from_display = "00:00 AM";
            time_to_display = "12:59:59 PM";

        } else {
            time_from = this.state.time_from_temp;
            time_to = this.state.time_to_temp;
            time_from_display = moment(this.state.time_from_temp, "kk:mm:ss").format('LT');
            time_to_display = moment(this.state.time_to_temp, "kk:mm:ss").format('LT');
        }

        this.setState({
            ...this.state, allDay: newVal,
            time_from_temp: time_from_temp,
            time_to_temp: time_to_temp,
            time_from: time_from,
            time_to: time_to,
            time_from_display: time_from_display,
            time_to_display: time_to_display
        });
    }

    nth = (n) => {
        return n + ["st", "nd", "rd"][((n + 90) % 100 - 10) % 10 - 1] || n + "th"
    }

    getRepeatOptionsLabel = (defaultLabel, key, date) => {
        // console.log(defaultLabel + " " + key + " " +  date)
        var label = defaultLabel;

        if (key === "every_n_date") {
            label = "Every " + moment(date, "YYYY-MM-DD").format('Do') + " Day of the Month"
        } else if (key === "every_n_week") {
            //var weeknumber = Math.ceil(moment(date).date() / 7);
            var weeknumber = this.state.weekRepeatInterval

            //label = "Every  " + this.nth(weeknumber) + " " + moment(date, "YYYY-MM-DD").format('ddd') + " Week";//  " " + moment(date, "YYYY-MM-DD").format('dddd') +" of the Month";
            label = "Every  " + weeknumber + " Weeks" //  " " + moment(date, "YYYY-MM-DD").format('dddd') +" of the Month";
        } else if (key === "every_year") {
            label = "Annually on " + moment(date, "YYYY-MM-DD").format('MMM D')
        }

        return label
    }

    // for UI
    customizedCalendarTileContentFromDate = ({ date, view }) => {
        var calendar_date = moment(date).format("YYYY-MM-DD");
        var selectedDate = (this.state.startDayDialogOpened === true) ? this.state.date_from : this.state.date_to;
        selectedDate = moment(selectedDate).format("YYYY-MM-DD")
        var content = "";

        var now = moment(this.formatDate(Date.now()), "YYYY-MM-DD").format('YYYY-MM-DD')

        if (this.state.startDayDialogOpened === true && moment(date).isBefore(moment(now))) {
            content = <div className="custom-calendar-date-tile disabled">{date.getDate()}</div>;
        } else {
            if (calendar_date === selectedDate) {
                var circle_schedule_class = "";
                // css for date circle
                if (this.state.selectedView === "workday") {
                    circle_schedule_class = " custom-calendar-date-tile-schedules";
                } else if (this.state.selectedView === "timeOff") {
                    circle_schedule_class = " custom-calendar-date-tile-personal";
                }

                content = <div>
                    <div className={"custom-calendar-date-tile" + circle_schedule_class}>
                        {date.getDate()}
                    </div>
                </div>;
            } else {
                content = <div className="custom-calendar-date-tile">{date.getDate()}</div>;
            }
        }

        return view === 'month' && content;
    }

    // for UI
    customizedCalendarTileContentToDate = ({ date, view }) => {
        var calendar_date = moment(date).format("YYYY-MM-DD");
        var selectedDate = (this.state.startDayDialogOpened === true) ? this.state.date_from : this.state.date_to;
        selectedDate = moment(selectedDate).format("YYYY-MM-DD")
        var content = "";

        var date_now = moment(Date.now()).format('YYYY-MM-DD');
        var date_from = moment(this.state.date_from, "YYYY-MM-DD").format('YYYY-MM-DD');
        var min_date = moment(date_from).isBefore(moment(date_now)) ? date_now : date_from;

        // if(this.state.endDayDialogOpened === true && moment(date).isBefore(moment(this.state.date_from))) {
        if (this.state.endDayDialogOpened === true && moment(date).isBefore(moment(min_date))) {
            content = <div className="custom-calendar-date-tile disabled">{date.getDate()}</div>;
        } else {

            if (calendar_date === selectedDate) {
                var circle_schedule_class = "";
                // css for date circle
                if (this.state.selectedView === "workday") {
                    circle_schedule_class = " custom-calendar-date-tile-schedules";
                } else if (this.state.selectedView === "timeOff") {
                    circle_schedule_class = " custom-calendar-date-tile-personal";
                }

                content = <div>
                    <div className={"custom-calendar-date-tile" + circle_schedule_class}>
                        {date.getDate()}
                    </div>
                </div>;
            } else {
                content = <div className="custom-calendar-date-tile">{date.getDate()}</div>;
            }

        }

        return view === 'month' && content;
    }

    handleOpenSnackbar = (variant = "default", message) => {
        this.setState({ ...this.state, snackbarOpened: true, snackbarVariant: variant, snackbarMessage: message });
    }

    handleCloseSnackbar = () => {
        this.setState({ ...this.state, snackbarOpened: false });
    }

    setShowDeleteScheduleDialog = (value) => {
        if (!this.props.onlineStatus) {
            console.log("Editing of Schedule when OFF-LINE is NOT allowed.")
            return;
        }
        this.setState({ ...this.state, showDeleteScheduleDialog: value });
    }

    setDeleteScheduleDialogHandler = (actionHandler, postActionHandler, title, message, actionText, chainedFunction) => {
        this.setState(
            {
                ...this.state,
                deleteScheduleActionHandler: actionHandler,
                deleteSchedulePostActionHandler: postActionHandler,
                confirmDeleteScheduleDialogMessage: message,
                confirmDeleteScheduleDialogTitle: title,
                confirmDeleteScheduleDialogActionText: actionText,
            },
            chainedFunction
        );
    }

    render() {
        const { classes, scheduleData } = this.props;
        var hasScheduleSettings = true;
        var addOrEdit = this.state.selectedSchedule.id === "0" ? "New" : "Edit";
        var edittingNotAllowed = this.edittingNotAllowed();
        var hasOrders = this.state.selectedSchedule.locked;

        //const onlineStatus = this.props.onlineStatus

        if (!scheduleData.settings || isEmptyObject(scheduleData.settings)) {
            hasScheduleSettings = false;
        }
        // console.log("hasScheduleSettings");
        // console.log(hasScheduleSettings);
        // if(!scheduleData.settings) {
        // console.log("setttings");
        // console.log(scheduleData.settings);

        // return(<LoadingSpinner />);
        // }

        const SelectedScheduleDays = (schedule_days, enabledDaysOfWeek) => (
            <Grid
                className={classNames(classes.scheduleDaysContainer)}
                container
                direction="row"
                justify="center"
                alignItems="center">
                {this.presetWeekDays().map(function (item, key) {
                    // console.log(item.key);
                    // console.log(enabledDaysOfWeek);

                    var day_enabled = enabledDaysOfWeek.includes(item.label);
                    var day_exists = schedule_days.map(x => x.toLowerCase()).indexOf(item.key);
                    var selected_day = "";

                    // console.log("schedule_days");
                    // console.log(schedule_days);
                    // console.log("enabledDaysOfWeek");
                    // console.log(enabledDaysOfWeek);

                    // if day exists in schedule_days(selected) and not disabled(out of bounds/range)
                    if (day_exists !== -1 && day_enabled) {
                        // for enabled Days of Weeks
                        if (this.state.repeatEnabled && ["every_week","every_n_week"].includes(this.state.repeatEnabledValue)) {
                            selected_day = this.state.selectedView === "timeOff" ? classes.fabOrange : classes.fabBlue;
                        } else {
                            selected_day = this.state.selectedView === "timeOff" ? classes.fabOrangeDisabled : classes.fabBlueDisabled;
                        }
                    }

                    var enabled_fab = (this.state.repeatEnabled && ["every_week","every_n_week"].includes(this.state.repeatEnabledValue) && day_enabled) ? true : false;
                    if (edittingNotAllowed) {
                        enabled_fab = false
                    }

                    return <Grid key={key} className={classNames()} item xs>
                        <div
                            onClick={enabled_fab ? () => { this.updateSelectedDays(item) } : () => { }}
                            className={classNames(classes.fab, (!enabled_fab) ? classes.fabDisabled : "", selected_day)}
                        >{item.code}</div>
                    </Grid>;
                }.bind(this))}
            </Grid>
        );

        var preset_days_label = "";
        if (this.state.selected_days.length > 0) {
            if (this.state.selectedPresetDay === "") {
                preset_days_label = "(Custom)"
            } else {
                preset_days_label = "(" + this.preset_days[this.state.selectedPresetDay].label + ")"
            }
        }

        // console.log("hasOrders: " + hasOrders)

        return (
            <div className={classes.root}>
                <main className={classes.content}>
                    <ApplicationBar isInfoHidden={true} />
                    {hasScheduleSettings
                        ?
                        <div className={classNames(classes.schedulesMainContainer)}>
                            <div>
                                <List classes={{ root: classNames(classes.listUL, classes.listULMainHeader) }}>
                                    <ListItem>
                                        <ListItemText
                                            classes={{
                                                primary: classes.schedulesMainHeader
                                            }}
                                            primary={addOrEdit + " Work Schedule"}
                                        />
                                    </ListItem>
                                </List>
                            </div>
                            {!this.props.onlineStatus &&
                                <div>
                                    <Typography className={classes.onlineOnlyWarning}>
                                        Editing of Schedule when OFF-LINE is NOT allowed.
                            </Typography>
                                </div>
                            }
                            <div className={classNames(classes.schedulesContainer)}>
                                <div className={classNames(classes.switchViewContainer)}>
                                    <Button
                                        onClick={() => this.handleSwitchView('workday')}
                                        variant="contained"
                                        disabled={edittingNotAllowed}
                                        classes={{
                                            root: classNames(
                                                classes.switchViewButton,
                                                classes.switchViewButtonLeft,
                                                (this.state.selectedView === "workday" ? classes.fabBlue : "")),
                                            label: classes.switchViewButtonLabel
                                        }}>Workday</Button>
                                    <Button
                                        onClick={() => this.handleSwitchView('timeOff')}
                                        disabled={edittingNotAllowed}
                                        variant="contained"
                                        classes={{
                                            root: classNames(
                                                classes.switchViewButton,
                                                classes.switchViewButtonRight,
                                                (this.state.selectedView === "timeOff" ? classes.fabOrange : "")),
                                            label: classes.switchViewButtonLabel
                                        }}>Time Off</Button>
                                    {(this.state.locked || !this.props.onlineStatus) &&
                                        <Icon className={classes.lockIcon}>lock</Icon>
                                    }
                                </div>

                                <div className={classes.schedulesContent}>
                                    <Typography
                                        variant="caption"
                                        gutterBottom
                                        className={classNames(classes.labels)}
                                    >
                                        Day
                                </Typography>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="baseline"
                                    >
                                        <Grid item className={classes.timeContainer}>
                                            <Typography
                                                onClick={this.handleOpenStartDayDialog}
                                                disabled={edittingNotAllowed}
                                                className={classNames(classes.displayTime)}
                                            >
                                                {this.state.date_from_display}
                                            </Typography>
                                        </Grid>
                                        <Typography
                                            variant="caption"
                                            gutterBottom
                                            className={classNames(classes.labelTo)}
                                        >
                                            To
                                    </Typography>
                                        <Grid item className={classes.timeContainer}>
                                            <Typography
                                                id="date-to-typography"
                                                onClick={this.handleOpenEndDayDialog}
                                                disabled={edittingNotAllowed}


                                                className={classNames(classes.displayTime)}
                                            >
                                                {this.state.date_to_display}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Typography
                                        variant="caption"
                                        gutterBottom
                                        className={classNames(classes.labels)}
                                    >Time</Typography>

                                    {/* <FormGroup classes={{ root: classes.allDayFormGroup }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    classes={{ root: classNames(classes.allDayCheckbox) }}
                                                    checkedIcon={<CheckBoxIcon
                                                        className={this.state.selectedView === "timeOff" ? classes.checkboxCheckedOrange : classes.checkboxCheckedBlue}
                                                    />}
                                                    disabled={edittingNotAllowed}
                                                    checked={this.state.allDay}
                                                    onChange={this.handleChangeAllDay}
                                                />
                                            }
                                            label="All Day"
                                        />
                                    </FormGroup> */}
                                    {/* {(!this.state.allDay) && */}

                                        <Grid
                                            container
                                            direction="row"
                                            alignItems="baseline"
                                        >
                                            <Grid item className={classes.timeContainer}>
                                                <TimePicker
                                                    disabled={edittingNotAllowed}
                                                    allowEmpty={false}
                                                    showSecond={false}
                                                    use12Hours={true}
                                                    onChange={(momentTime) => {
                                                        var formatted24 = moment(momentTime, "hh:mm A").format("k:mm")
                                                        console.log(formatted24)
                                                        this.updateStartTime(formatted24)
                                                    }}
                                                    minuteStep={30}
                                                    // defaultValue={moment(this.state.time_from, "hh:mm A")}
                                                    value={moment(this.state.time_from, "hh:mm A")}
                                                    format={"hh:mm A"}
                                                    popupClassName={classes.timePickerPanel}
                                                    className={classNames(classes.timePickerField, this.state.timeHasError ? classes.timePickerFieldError: "")}
                                                />
                                            </Grid>
                                            <Typography
                                                variant="caption"
                                                gutterBottom
                                                className={classNames(classes.labelTo)}
                                            >To</Typography>
                                            <Grid item className={classes.timeContainer}>
                                                <TimePicker
                                                    disabled={edittingNotAllowed}
                                                    allowEmpty={false}
                                                    showSecond={false}
                                                    use12Hours={true}
                                                    onChange={(momentTime) => {
                                                        var formatted24 = moment(momentTime, "hh:mm A").format("k:mm")
                                                        console.log(formatted24)
                                                        this.updateEndTime(formatted24)
                                                    }}
                                                    minuteStep={30}
                                                    // defaultValue={moment(this.state.time_to, "hh:mm A")}
                                                    value={moment(this.state.time_to, "hh:mm A")}
                                                    format={"hh:mm A"}
                                                    placement={"bottomRight"}
                                                    popupClassName={classes.timePickerPanel}
                                                    className={classNames(classes.timePickerField, this.state.timeHasError ? classes.timePickerFieldError: "")}
                                                />
                                            </Grid>
                                        </Grid>
                                    {/* } */}

                                    {this.state.timeHasError &&
                                        <Typography className={classes.timeHasError}>* Start Time should be before End Time</Typography>
                                    }

                                    <Typography
                                        variant="caption"
                                        gutterBottom
                                        className={classNames(
                                            classes.labels,
                                            (this.state.repeatEnabled && ["every_week","every_n_week"].includes(this.state.repeatEnabledValue)) ? "" : classes.disabledLabels
                                        )}> Days of the Week </Typography>

                                    <Button
                                        onClick={() => this.tiggerPresetsDialog()}
                                        disabled={((this.state.repeatEnabled && ["every_week","every_n_week"].includes(this.state.repeatEnabledValue)) ? false : true) || edittingNotAllowed}
                                        variant="contained"
                                        size="small"
                                        color="default"
                                        fullWidth={true}
                                        classes={{
                                            disabled: (this.state.repeatEnabled && ["every_week","every_n_week"].includes(this.state.repeatEnabledValue)) ? "" : classes.disabledWhiteButton
                                        }}
                                        className={classNames(
                                            classes.whiteButton,
                                            classes.margins,
                                            classes.buttons,
                                            // classes.marginRight,
                                            // (this.state.selectedView === "timeOff" ? classes.fabOrange : classes.fabBlue)
                                        )}>Preset Days {preset_days_label}</Button>

                                    <div className={classes.presetDaysContainer}>
                                        {SelectedScheduleDays(this.state.selected_days, this.state.enabledDaysOfWeek)}
                                    </div>

                                    <FormGroup classes={{ root: classes.allDayFormGroup }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    classes={{ root: classNames(classes.allDayCheckbox) }}
                                                    checkedIcon={<CheckBoxIcon
                                                        className={this.state.selectedView === "timeOff" ? classes.checkboxCheckedOrange : classes.checkboxCheckedBlue}
                                                    />}
                                                    disabled={edittingNotAllowed}
                                                    checked={this.state.repeatEnabled}
                                                    onChange={this.handleChangeRepeatEnabled}
                                                    value="alamu" />
                                            }
                                            label="Repeat"
                                        />
                                    </FormGroup>

                                    <Button
                                        onClick={() => this.tiggerRepeatDialog(true)}
                                        disabled={edittingNotAllowed || !this.state.repeatEnabled}
                                        variant="contained"
                                        size="small"
                                        color="default"
                                        fullWidth={true}
                                        className={classNames(
                                            classes.repeatButton,
                                            classes.whiteButton,
                                            classes.margins,
                                            classes.buttons,
                                            // classes.marginRight,
                                        )}><span>{this.getRepeatOptionsLabel(this.state.repeatOptions[this.state.repeatEnabledValue], this.state.repeatEnabledValue, this.state.date_from)}</span></Button>

                                    <Button
                                        onClick={() => this.goToSchedulePage()}
                                        variant="contained"
                                        // size="large" 
                                        color="default"
                                        className={classNames(classes.margins, classes.buttons, classes.marginRight)}>
                                        Cancel</Button>
                                    {(this.state.selectedSchedule.id !== "0") &&
                                        <Button
                                            onClick={() => {
                                                this.setDeleteScheduleDialogHandler(
                                                    () => this.onDeleteSchedule(),
                                                    () => { this.setShowDeleteScheduleDialog(false) },
                                                    "Delete Work Schedule",
                                                    <div>
                                                        <Typography className={classes.deleteScheduleText}>You are about to delete this Work Schedule.</Typography>
                                                        {hasOrders &&
                                                            <Typography className={classes.deleteScheduleWarningText}><Icon className={classes.deleteScheduleWarningIcon}>error</Icon> There is an Order assigned on this day(s). Please re-assign or confirm that you are still the Provider to handle the Order.</Typography>
                                                        }
                                                    </div>,
                                                    "Confirm Delete",
                                                    () => { this.setShowDeleteScheduleDialog(true) },
                                                )
                                            }}
                                            disabled={edittingNotAllowed}
                                            variant="contained"
                                            // size="large"                               
                                            className={classNames(
                                                classes.margins,
                                                classes.buttons,
                                                (this.state.selectedView === "timeOff" ? classes.fabOrange : classes.fabBlue)
                                            )}>Delete</Button>
                                    }
                                    <Button
                                        onClick={this.onUpdate}
                                        disabled={edittingNotAllowed || this.state.timeHasError}
                                        variant="contained"
                                        // size="large"
                                        className={classNames(
                                            classes.buttons,
                                            classes.floatRight,
                                            (this.state.selectedView === "timeOff" ? classes.fabOrange : classes.fabBlue)
                                        )}>Save</Button>
                                </div>
                            </div>
                        </div>
                        :
                        <div className={classNames(classes.schedulesMainContainer)}>
                            <Typography className={classes.noScheduleSettingsNote} variant="overline">There is no available Schedule Settings. please contact the Administrator.</Typography>
                        </div>
                    }
                    <div className={classes.toolbar} />
                    <div className={classes.toolbar} />
                </main>
                <SimpleBottomNavigation />

                {hasScheduleSettings &&
                    <div>
                        
                        {/*
                        ** replaced UI
                        <Dialog
                            id="edit-start-time-dialog"
                            fullScreen={false}
                            classes={{
                                paper: classes.dialogPaper
                            }}
                            open={this.state.startTimeDialogOpened}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title" onClose={() => { this.handleCloseStartTimeDialog() }}>Start Time</DialogTitle>
                            <DialogContent classes={{ root: classes.dialogPaperTimeContainer }}>
                                <TimeKeeper
                                    time={this.state.time_from_display}
                                    config={{
                                        TIMEPICKER_BACKGROUND: 'white',
                                        DONE_BUTTON_COLOR: '#64c9f1',
                                        DONE_BUTTON_BORDER_COLOR: '#ededed'
                                    }}
                                    onChange={(newTime) => {
                                        this.updateStartTime(newTime.formatted24)
                                    }}
                                    onDoneClick={(newTime) => {
                                        this.closeStartTimeDialog(true);
                                    }}
                                    switchToMinuteOnHourSelect
                                />
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            id="edit-end-time-dialog"
                            fullScreen={false}
                            classes={{
                                paper: classes.dialogPaper
                            }}
                            open={this.state.endTimeDialogOpened}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title" onClose={() => { this.handleCloseEndTimeDialog() }}>End Time</DialogTitle>
                            <DialogContent
                                classes={{
                                    root: classes.dialogPaperTimeContainer
                                }}>
                                <TimeKeeper
                                    key={this.state.timeKeeperEndTimeKey}
                                    time={this.state.time_to_display}
                                    config={{
                                        TIMEPICKER_BACKGROUND: 'white',
                                        DONE_BUTTON_COLOR: '#64c9f1',
                                        DONE_BUTTON_BORDER_COLOR: '#ededed'
                                    }}

                                    onChange={(newTime) => {
                                        console.log(newTime)
                                        this.updateEndTime(newTime.formatted24);
                                    }}

                                    onDoneClick={(newTime) => {
                                        this.closeEndTimeDialog(true);
                                    }}
                                    switchToMinuteOnHourSelect
                                />
                            </DialogContent>
                        </Dialog> */}
                        <Dialog
                            id="edit-start-time-dialog"
                            fullScreen={false}
                            classes={{
                                paper: classes.dialogPaper
                            }}
                            open={this.state.startDayDialogOpened}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title" onClose={() => { this.handleCloseStartDayDialog() }}>Start Date</DialogTitle>
                            <DialogContent classes={{ root: classes.dialogPaperTimeContainer }}>
                                <Calendar
                                    onChange={this.onDateChangeFrom}
                                    activeStartDate={moment(this.state.date_from).toDate()}
                                    calendarType="US"
                                    view="month"
                                    className="modified-calendar"
                                    tileContent={this.customizedCalendarTileContentFromDate}
                                />
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            id="edit-end-time-dialog"
                            fullScreen={false}
                            classes={{
                                paper: classes.dialogPaper
                            }}
                            open={this.state.endDayDialogOpened}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title" onClose={() => { this.handleCloseEndDayDialog() }}>End Date</DialogTitle>
                            <DialogContent
                                classes={{
                                    root: classes.dialogPaperTimeContainer
                                }}>
                                <Calendar
                                    onChange={this.onDateChangeTo}
                                    activeStartDate={moment(this.state.date_to).toDate()}
                                    calendarType="US"
                                    view="month"
                                    className="modified-calendar"
                                    tileContent={this.customizedCalendarTileContentToDate}
                                />
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            id="preset-days-dialog"
                            fullScreen={false}
                            classes={{
                                paper: classes.dialogPaper
                            }}
                            open={this.state.presetDaysDialogOpened}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title" onClose={() => { this.tiggerPresetsDialog() }}>Preset Days</DialogTitle>
                            <DialogContent>
                                <FormControl
                                    fullWidth={true}
                                    component="fieldset">
                                    <RadioGroup
                                        aria-label="Please select an option."
                                        name="preset_days_option"
                                        className={classes.group}
                                        value={this.state.selectedPresetDay === "" ? "" : this.state.selectedPresetDay}
                                        onChange={this.handleChangePresetDays}
                                        id='preset_days_optionP'>
                                        {
                                            Object.keys(this.state.presetDays).map(function (key) {
                                                return <FormControlLabel key={key} value={key} control={<Radio className={classes.radioButton} />} label={this.state.presetDays[key].label} />
                                            }, this)
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            id="repeat-dialog"
                            fullScreen={false}
                            classes={{
                                paper: classes.dialogPaper
                            }}
                            open={this.state.repeatDialogOpened}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title" onClose={() => { this.tiggerRepeatDialog(false) }}>Repeat Options</DialogTitle>
                            <DialogContent>
                                <FormControl
                                    fullWidth={true}
                                    component="fieldset">
                                    <RadioGroup
                                        aria-label="Please select an option."
                                        name="repeat_option"
                                        className={classes.group}
                                        value={this.state.tempRepeatEnabledValue === "" ? "every_week" : this.state.tempRepeatEnabledValue}
                                        onChange={this.handleChangeRepeatOptions}
                                        id='repeat_option'>

                                        {/* <FormControlLabel value="custom" control={<Radio className={classes.radioButton} />} label="CUSTOM" /> */}
                                        {
                                            Object.keys(this.state.repeatOptions).map((key) => {
                                                //console.log("repeatOptions")
                                                //console.log(this.state.repeatOptions)
                                                var label = this.state.repeatOptions[key];

                                                label = this.getRepeatOptionsLabel(label, key, this.state.date_from);
                                                if (key === "every_n_week") {
                                                    //return  <FormControlLabel key={key} value={key} control={<Radio className={classes.radioButton} />} label={<InputLabel> Test <Input type="text"/></InputLabel> } /> 
                                                    // return <OtherRadio key={key} value={key} onWeekRepeatIntervalChange={this.onWeekRepeatIntervalChange}/>
                                                    return <FormControlLabel key={key} value={key} control={<Radio className={classes.radioButton} />} label={
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justify="flex-start"
                                                            alignItems="center">
                                                            <Grid item>Every</Grid>
                                                            <Grid item>
                                                                <TextField
                                                                    id="every-nth-number"
                                                                    type="number"
                                                                    InputLabelProps={{ 
                                                                        shrink: true,                                                            
                                                                    }}
                                                                    value={this.state.tempRepeatWeekIntervalValue}
                                                                    className={classes.everyNthWeekField}
                                                                    variant="outlined"
                                                                    disabled={this.state.tempRepeatEnabledValue !== "every_n_week"}
                                                                    onChange={this.onWeekRepeatIntervalChanged}
                                                                    placeholder="2"
                                                                />
                                                            </Grid>
                                                            <Grid>Weeks</Grid>
                                                        </Grid>
                                                    }
                                                    />
                                                } else {
                                                    return <FormControlLabel key={key} value={key} control={<Radio className={classes.radioButton} />} label={label} />
                                                }
                                            })
                                        }
                                    </RadioGroup>
                                    <Button
                                        onClick={this.okChangeRepeatOptions}
                                        disabled={edittingNotAllowed}
                                        variant="contained"
                                        // size="large"
                                        className={classNames(
                                            classes.buttons,
                                            classes.floatRight,
                                            (this.state.selectedView === "timeOff" ? classes.fabOrange : classes.fabBlue)
                                        )}>OK</Button>
                                </FormControl>

                            </DialogContent>
                        </Dialog>

                        <ConfirmDialog
                            visible={this.state.showDeleteScheduleDialog}
                            title={this.state.confirmDeleteScheduleDialogTitle}
                            message={this.state.confirmDeleteScheduleDialogMessage}
                            actionText={this.state.confirmDeleteScheduleDialogActionText}
                            actionHandler={this.state.deleteScheduleActionHandler}
                            postActionHandler={this.state.deleteSchedulePostActionHandler}
                            onClose={() => {
                                setTimeout(() => {
                                    this.setShowDeleteScheduleDialog(false);
                                }, 100);
                            }}
                        />

                        <SnackBarPopup
                            open={this.state.snackbarOpened}
                            onClose={this.handleCloseSnackbar}
                            variant={this.state.snackbarVariant}
                            message={<span id="snackbar">{this.state.snackbarMessage}</span>}
                        ></SnackBarPopup>
                    </div>
                }
            </div>
        );
    }
}

AddEditSchedulesClass.propTypes = {
    classes: PropTypes.object.isRequired,
};

const AddEditSchedule = connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles)(AddEditSchedulesClass)));
export default AddEditSchedule;