import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
import classNames from 'classnames'

import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
// import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import CallIcon from '@material-ui/icons/Call'
import RoomIcon from '@material-ui/icons/Room'
// import uuid from "uuid"
import moment from 'moment'
import * as helper from 'components/common/Helper.js'

const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const ApptDetailsFunctionClass = (props) => {
    const { drawData, classes, match } = props
    const { appointment_id } = match.params
    // init values
    var theSite = {
        collection_time: "",
        display_address: "",
        site: {
            name: "",
            address: "",
            state: "",
            zipcode: "",
            phone_number: "",
        }
    }

    if (drawData.draw_list !== undefined) {
        theSite = drawData.draw_list.find(function (site) {
            // appointment_id === site_id
            return site.id === appointment_id
        })
    }
    
    const address = theSite.display_address ? theSite.display_address : [theSite.site.address, theSite.site.state, theSite.site.zipcode].join(", ")
    const collection_time_date = [moment(theSite.collection_time, "HH:mm:ss").format("h:mm A"), moment(drawData.collection_date, "YY-MM-DD").format("MMM D, YYYY")].join(", ")
    const city = theSite.site.city !== undefined && theSite.site.city !== "" ? theSite.site.city + " ": "";        
    const city_state_zipcode = city + [theSite.site.state, theSite.site.zipcode].filter(function(el) { return el; }).join( ", " );
    const map_link = [theSite.site.address, theSite.site.address_line_2, city_state_zipcode].filter(function(el) { return el; }).join( ", " );
        
    return (
        <>
            <div className={classNames(classes.apptDetailsContainer)}>
                <Grid container>
                    <Grid item xs={8} zeroMinWidth style={{ paddingRight: "5px" }}>
                        {/* <Typography className={classes.orderDetailsLabel}>{theSite.site.name}</Typography> */}
                        <Typography className={classes.orderDetailsLabel}>Appointment Details</Typography>
                        <Typography className={classes.apptDateTime}>{collection_time_date}</Typography>
                        <Typography className={classes.orderAddress}>{address}</Typography>
                        <Typography className={classes.orderAddress}>{theSite.site.phone_number}</Typography>
                    </Grid>
                    <Grid item xs={4} zeroMinWidth>
                        <a href={"tel:" + theSite.site.phone_number}>
                            <IconButton aria-label="Call" className={classes.apptDetailsButton} style={{ marginBottom: "10px" }} onClick={() => { }}>
                                <CallIcon className={classNames(classes.apptDetailsButtonIcon)}></CallIcon> Call
                            </IconButton>
                        </a>
                        <IconButton aria-label="Map" className={classes.apptDetailsButton} onClick={() => { 
                              helper.navigate(theSite.site.latitude, theSite.site.longitude, map_link)
                        }}>
                            <RoomIcon className={classNames(classes.apptDetailsButtonIcon)}></RoomIcon> Map
                        </IconButton>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

const ApptDetails = withRouter(
    withCookies(
        connect(mapStateToProps)(
            withStyles(styles, { withTheme: true })(ApptDetailsFunctionClass)
        )
    )
)
export default ApptDetails
