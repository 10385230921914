import * as globals from 'components/common/GlobalConstants'

// steps styles
const styles = theme => ({
    // general
    root: {
    },
    // dialogPaper: {
    //     margin: "20px"
    // },
    // dialogTitle: {
    //     padding: "20px 20px 15px",
    //     textAlign: "center"
    // },
    // dialogContent: {
    //     padding: "0px 10px 5px",
    // },
    // dialogActionsContainer: {
    //     margin: "0px",
    //     padding: "15px 20px 20px",
    //     "& .dialog-actions-button": {
    //         padding: "8px 20px !important",
    //         marginRight: "0px",
    //         marginLeft: "10px"
    //     }
    // },
    photoCaptureDialogContent: {
        padding: "0px !important",
    },
    dialogContainer: {
        margin: "20px",
        borderRadius: "10px"
    },
    dialogContent: {
        padding: "20px !important"
    },
    dialogActions: {
        padding: "20px !important",
        margin: "0px"
    },
    unableToPerformTitle: {
        fontWeight: 600
    },
    btnClose: {
        textTransform: 'capitalize',
        padding: "8px 20px",
        fontSize: "14px !important",
        backgroundColor: globals.v2.COLORS.GRAY,
        color: "black",
        fontWeight: 500,
        "&:focus": { backgroundColor: globals.v2.COLORS.GRAY + " !important" },
        "&:active": { backgroundColor: globals.v2.COLORS.GRAY + " !important" },
        "&:hover": { backgroundColor: globals.v2.COLORS.GRAY + " !important" },
        "&:disabled": { 
            "& span": {
                color: globals.v2.COLORS.TEXT_DISABLED + " !important",
            },
            backgroundColor: globals.v2.COLORS.GRAY_DISABLED + " !important" 
        },
    },
    btnConfirm: {
        textTransform: 'capitalize',
        padding: "8px 20px",
        fontSize: "14px !important",
        backgroundColor: globals.v2.COLORS.YELLOW,
        color: "black",
        fontWeight: 500,
        "&:focus": { backgroundColor: globals.v2.COLORS.YELLOW + " !important" },
        "&:active": { backgroundColor: globals.v2.COLORS.YELLOW + " !important" },
        "&:hover": { backgroundColor: globals.v2.COLORS.YELLOW + " !important" },
        "&:disabled": { 
            "& span": {
                color: globals.v2.COLORS.TEXT_DISABLED + " !important",
            },
            backgroundColor: globals.v2.COLORS.YELLOW_DISABLED + " !important" 
        },
    },
    // step 1
    verifyPatientInfoContainer: {
        backgroundColor: "#f7f7f7",
        padding: "10px 15px",
        marginTop: "15px",
        borderRadius: "5px"
    },
    verifyPatientInfoCheckboxContainer: {
        marginRight: "15px"
    },
    verifyPatientInfoCheckedIcon: {
        backgroundColor: "#69CEF1",
        border: "1px solid #69CEF1",
        width: "30px",
        height: "30px",
        "& svg": {
            fill: "white !important"
        }
    },
    verifyPatientCheckIcon: {
        fontSize: "18px"
    },
    verifyPatientInfoUnCheckedIcon: {
        backgroundColor: "white",
        border: "1px solid #e6e6e6",
        width: "30px",
        height: "30px",
    },
    verifyPatientInfoLabel: {
        color: "black !important",
        fontSize: "12px !important",
        fontWeight: "400",
        "&.verified": {
            color: "#a8a9ad !important",
        }
    },
    verifyPatientInfoLabelValue: {
        color: "black !important",
        fontSize: "14px !important",
        fontWeight: 600,
        "&.verified": {
            color: "#9d9ea3 !important",
        }
    },
    unableToPerformOrderButtonContainer: {
        textAlign: "center",
        marginTop: "50px"
    },
    unableToPerformOrderButton: {
        color: globals.v2.COLORS.RED + " !important",
        fontSize: "14px !important"
    },
    unableToPerformReasonFormControl: {
        border: "1px solid #f3f3f3",
        borderRadius: "5px",
        width: "100%",
        marginTop: "20px"
    },
    unableToPerformReasonMenuItemPlaceholder: {
        color: "#ababab !important",
        fontSize: "16px !important"
    },
    unableToPerformReasonSelect: {
        padding: "10px 15px",
        border: "none !important"
    },
    unableToPerformAvatar: {
        backgroundColor: globals.v2.COLORS.RED,
        width: "40px",
        height: "40px",
        marginRight: "10px",
        "& svg": {
            fill: "white !important"
        }
    },
    unableToPerformIcon: {
        fontWeight: 600,
        fontSize: "28px"
    },
    unableToPerformMessage: {
        marginLeft: "50px",
        fontSize: "14px !important"
    },
    // step 2
    ivKitCapturedPhotoContainer: {
        marginTop: "20px",
    },
    ivKitCapturedPhoto: {
        borderRadius: "5px",
        width: "100%",
        height: "100%",
        objectFit: "cover"
    },
    // step 3
    venipunctureCapturedPhotoContainer: {
        marginTop: "20px",
    },
    venipunctureCapturedPhoto: {
        borderRadius: "5px",
        width: "100%",
        height: "100%",
        objectFit: "cover"
    },
    venipuctureSelectLabel: {
        marginTop: "15px",
        marginBottom: "5px",
        fontSize: "14px !important",
        fontWeight: "500"
    },
    venipuctureFormControl: {
        border: "1px solid #f3f3f3",
        borderRadius: "5px",
        width: "100%"
    },
    venipuctureSelect: {
        fontSize: "14px",
        padding: "10px 15px",
        border: "none !important"
    },
    venipuctureMenuItemPlaceholder: {
        color: "#ababab !important",
        fontSize: "16px !important"
    },
    // step 4
    infusionTimerContainer: {
        height: "300px"
    },
    infusionLabel: {
        color: "black !important",
        fontWeight: "500",
        fontSize: "16px !important"
    },
    infusionTimer: {
        color: "black !important",
        fontWeight: "400",
        fontSize: "60px !important",
        marginBottom: "30px"
    },
    btnInfusion: {
        width: "80%",
        padding: "10px",
        borderRadius: "5px",
        fontSize: "14px !important",
        backgroundColor: "#ffdc51 !important",
        color: "black",
        fontWeight: 500,
        "&:focus": {
            backgroundColor: "#ffdc51 !important",
        },
        "&:disabled": {
            backgroundColor: "#e6e6e6 !important",
        }
    },
    // step 5
    medicationPhotoContainer: {
        marginTop: "20px",
    },
    // also used in other steps pages
    tapHereContainer: {
        backgroundColor: "#f7f7f7",
        borderRadius: "5px",
    },
    tapHereIcon: {
        color: "#333333",
        width: "70px",
        height: "70px"
    },
    tapHereLabel: {
        fontSize: "16px !important"
    },
    medicationCapturedPhoto: {
        borderRadius: "5px",
        width: "100%",
        height: "100%",
        objectFit: "cover"
    },
    // step 6
    dataCaptureContainer: {
        marginTop: "20px",
    },
    noDataCaptureContainer: {
        height: "250px"
    },
    noDataCapturedLabel: {
        color: "#c1c1c1 !important",
        fontSize: "16px !important"
    },
    btnAddDataContainer: {
        position: "fixed",
        left: "0px",
        bottom: "80px",
        width: "100%"
        //right: "0px",
     
    },
    btnCaptureData: {
        fontSize: "14px",
        textTransform: "none",
        color: "black !important",
        backgroundColor: "white !important",
        border: "1px solid #E6E6E6",
        padding: "8px 20px",
        borderRadius: "4px",
        marginBottom: "10px",
        width: "252px",
        "&:active": {
            backgroundColor: "white !important",
        }
    },
    btnCaptureDataLabel: {
        color: "#00BDFF !important",
    },
    btnAddData: {
        border: "1px solid #E6E6E6",
        borderRadius: "4px",
    },
    btnCloseAddData: {
        fontSize: "14px",
        textTransform: "none",
        color: "black !important",
        backgroundColor: "#F7F7F7 !important",
        border: "1px solid #F7F7F7",
        padding: "8px 20px",
        borderRadius: "4px",
        marginBottom: "10px",
        width: "252px",
        boxShadow: "2px 2px #f0f0f0",
        "&:active": {
            backgroundColor: "#F7F7F7 !important",
        }
    },
    capturedDataContainer: {
        padding: "10px",
        border: "1px solid #E6E6E6",
        borderRadius: "4px",
        marginBottom: "15px"
    },
    capturedDataDateTimeLabel: {
        color: "#9A9BA0 !important",
        fontSize: "12px !important",
        marginBottom: "8px"
    },
    capturedDataMainNote: {
        color: "black !important",
        fontSize: "14px !important",
    },
    capturedDataSubNote: {
        fontSize: "12px !important",
    },
    capturedImageDiv: {
        width: "100px",
        height: "100px",
        marginRight: "10px"
    },
    capturedImage: {
        borderRadius: "5px",
        width: "100px",
        height: "100px",
        objectFit: "cover"
    },
    errorCameraMessageContainer: {
        position: "absolute",
        top: 0,
        width: "100%",
        textAlign: "center",
        marginTop: "10%",
    },
    errorCameraMessage: {
        color: "white !important"
    },
    // step 7
    signatureContainer: {
        marginTop: "20px",
        border: "1px solid #f3f3f3",
        borderRadius: "5px",
    },
    signBelowLabel: {
        fontSize: "16px !important",
        margin: "20px"
    },
    signatureImage: {
        width: "100%",
        height: "100%",
        objectFit: "cover"
    },
    signaturePadContainer: {
        height: "100% !important",
        // border: "1px dashed #ccc",
        // backgroundColor: "#F1F2F2 !important",
    },
    btnResetSignature: {
        position: "absolute",
        left: "10px",
        top: "-14px"
    },
    btnAcceptSignature: {
        position: "absolute",
        right: "10px",
        top: "-14px",
        color: "#69CEF1"
    },
    grayButton: {
        textTransform: 'capitalize',
        padding: "10px 20px",
        fontSize: "16px !important",
        backgroundColor: "#f7f7f7 !important",
        color: "black",
        fontWeight: 500,
        "&:focus": { backgroundColor: "#f7f7f7 !important" },
        "&:active": { backgroundColor: "#f7f7f7 !important" },
        "&:hover": { backgroundColor: "#f7f7f7 !important" },
        "&:disabled": { backgroundColor: "#f7f7f7 !important" },
    },
    yellowButton: {
        textTransform: 'capitalize',
        padding: "10px 20px",
        fontSize: "16px !important",
        backgroundColor: "#ffdc51 !important",
        color: "black",
        fontWeight: 500,
        "&:focus": { backgroundColor: "#ffdc51 !important" },
        "&:active": { backgroundColor: "#ffdc51 !important" },
        "&:hover": { backgroundColor: "#ffdc51 !important" },
        "&:disabled": { backgroundColor: "#e6e6e6 !important" },
    },
    addDataOptionsDialogContainer: {
        backgroundColor: "transparent",
        boxShadow: "none",
        position: "absolute",
        bottom: "80px",
        margin: "0px",
        width: "252px"
    },
    addDataOptionsDialogContent: {
        padding: "0px !important",
        textAlign: "center",
    }
})

export default styles