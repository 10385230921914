import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
// import classNames from 'classnames'

import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'

import { useStopwatch } from 'react-timer-hook'
import moment from 'moment'

const mapStateToProps = state => {
    return {
        drawData: state.drawData,
        workflowData: state.workflowData
    }
}

const Step4FunctionClass = (props) => {
    const {
        isOrderRemaining,
        workflowData,
        order,
        patientWorkflowData,
        classes,
        onSetBtnNextDisabled,
        onChangeData
    } = props

    const [initialEmpty, setInitialEmpty] = useState("")

    const zeroPad = (num, places) => String(num).padStart(places, '0')

    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        reset,
    } = useStopwatch({ autoStart: false })

    const initStart = moment().unix()
    const initEnd = moment().unix()
    const [data, setData] = useState({
        infusion_start_time: initStart,
        infusion_end_time: initEnd,
        infusion_total_time: moment.unix(initEnd).diff(moment.unix(initStart, 'milliseconds'))
    })
    const [startTime, setStartTime] = useState(initStart)
    const [endTime, setEndTime] = useState(initEnd)
    const [infusionDone, setInfunsionDone] = useState(true)

    useEffect(() => {
        // initial values from workflow data
        if (patientWorkflowData) {
            // means no value entered in workflow infusion time yet
            setInitialEmpty(patientWorkflowData[0].workflow_data.infusion_start_time === "")

            const start_time = patientWorkflowData[0].workflow_data.infusion_start_time === "" ? "0" : patientWorkflowData[0].workflow_data.infusion_start_time
            const end_time = patientWorkflowData[0].workflow_data.infusion_end_time === "" ? "0" : patientWorkflowData[0].workflow_data.infusion_end_time
            const infusion_total_time = patientWorkflowData[0].workflow_data.infusion_total_time === "" ? "0" : patientWorkflowData[0].workflow_data.infusion_total_time
            setData({
                infusion_start_time: parseInt(start_time),
                infusion_end_time: parseInt(end_time),
                infusion_total_time: parseInt(infusion_total_time)
            })
        }
    }, [order, workflowData.workflow, setData])

    useEffect(() => {
        const newData = {
            infusion_start_time: startTime,
            infusion_end_time: endTime,
            infusion_total_time: moment.unix(endTime).diff(moment.unix(startTime), 'milliseconds')
        }

        if (newData.infusion_total_time > 0 && 
            data.infusion_end_time !== newData.infusion_end_time) {
            console.log("data.infusion_end_time !== newData.infusion_end_time")
            console.log("data",data)
            console.log("newData",newData)
            updateData(newData)
            // setData(newData)
        }
    }, [startTime, endTime])

    const d = new Date(Date.UTC(0, 0, 0, 0, 0, 0, data.infusion_total_time))
    const time_parts = data.infusion_total_time ? [
        d.getUTCHours(),
        d.getUTCMinutes(),
        d.getUTCSeconds()
    ] : [0, 0, 0]

    const resetInfusionTime = () => {
        const start = moment().unix()
        const end = moment().unix()
        setData({
            infusion_start_time: start,
            infusion_end_time: end,
            infusion_total_time: moment.unix(end).diff(moment.unix(start, 'milliseconds'))
        })
    }

    const updateData = (newData) => {
        if (isOrderRemaining) {
            const parsedData = {
                infusion_start_time: newData.infusion_start_time.toString(),
                infusion_end_time: newData.infusion_end_time.toString(),
                infusion_total_time: newData.infusion_total_time.toString(),
            }
            console.log("updateData parsedData")
            console.log(parsedData)
            onChangeData(parsedData)
        }
    }

    useEffect(() => {
        if (isOrderRemaining) {
            // to enable next button going Step 5
            if (data.infusion_total_time !== 0) {
                onSetBtnNextDisabled(false)
            } else {
                onSetBtnNextDisabled(true)
            }

            // onChangeData({
            //     infusion_start_time: data.infusion_start_time.toString(),
            //     infusion_end_time: data.infusion_end_time.toString(),
            //     infusion_total_time: data.infusion_total_time.toString(),
            // })
        }
    }, [data, isOrderRemaining, onSetBtnNextDisabled])

    useEffect(() => {
        // force enable after done/unable
        if (!isOrderRemaining) {
            onSetBtnNextDisabled(false)
        }
    }, [isOrderRemaining, onSetBtnNextDisabled])

    // console.log("infusion data")
    // console.log(data)
    // console.log("infusion time")
    // console.log(hours, minutes, seconds)

    return (
        <>
            <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.infusionTimerContainer}
            >
                <div style={{ textAlign: "center" }}>
                    {infusionDone && !initialEmpty
                        ?
                        <>
                            <Typography className={classes.infusionLabel}>
                                Total Infusion Time
                            </Typography>
                            <Typography className={classes.infusionTimer}>
                                {zeroPad(time_parts[0], 2)} : {zeroPad(time_parts[1], 2)} : {zeroPad(time_parts[2], 2)}
                            </Typography>
                            {isOrderRemaining &&
                                <IconButton
                                    aria-label="reset"
                                    className={classes.btnInfusion}
                                    onClick={() => {
                                        if (isOrderRemaining) {
                                            reset()
                                            pause()
                                            // setInitialEmpty(true)
                                            setInfunsionDone(false)
                                            resetInfusionTime()
                                        }
                                    }}
                                >Reset</IconButton>
                            }
                        </>
                        :
                        <>
                            {isRunning
                                ?
                                <Typography className={classes.infusionLabel}>
                                    Infusion has started. <br />
                                    Tap Finish once completed.
                                </Typography>
                                :
                                <Typography className={classes.infusionLabel}>
                                    Hit START when starting infusion
                                </Typography>
                            }
                            <br />
                            <Typography className={classes.infusionTimer}>
                                {zeroPad(hours, 2)} : {zeroPad(minutes, 2)} : {zeroPad(seconds, 2)}
                            </Typography>
                            {(isRunning && isOrderRemaining)
                                ?
                                <IconButton
                                    aria-label="pause"
                                    className={classes.btnInfusion}
                                    onClick={() => {
                                        reset()
                                        pause()
                                        // setInitialEmpty(false)
                                        setInfunsionDone(true)
                                        setEndTime(moment().unix())
                                        // updateData()
                                    }}
                                >Finish Infusion</IconButton>
                                :
                                <IconButton
                                    aria-label="start"
                                    className={classes.btnInfusion}
                                    onClick={() => {
                                        start()
                                        setStartTime(moment().unix())
                                        setEndTime(moment().unix())
                                    }}
                                >Start Infusion</IconButton>
                            }
                        </>
                    }
                </div>
            </Grid>
        </>
    )
}

const Step4 = withRouter(
    withCookies(
        connect(mapStateToProps)(
            withStyles(styles, { withTheme: true })(Step4FunctionClass)
        )
    )
)
export default Step4
