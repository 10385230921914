const styles = theme => ({
    gridRoot: {
        flexGrow: 1,
    },
    colMargin: {
        marginTop: theme.spacing.unit * 1.2,
        marginBottom: theme.spacing.unit * 1.2,
    },
    loginButton: {
        marginTop: 30,
        textTransform: 'none',
        width: "100%",
        fontWeight: "bold",
        color: "black !important",
        backgroundColor: "#f7dd50 !important",
        borderRadius: "4px",
        fontSize: "14px",
        padding: "12px",
        boxShadow: "0px 0px 3px #f0f0f0",
        flexGrow: 1,
        "&:focus": {
            backgroundColor: "#f7dd50 !important",
        }

    },
    card: {
        boxShadow: 'none',
    },
    media: {
        objectFit: 'contain',
    },
    teloPointLogo: {
        marginBottom: 30,
        display: "flex",
        justifyContent: "center"
    },
    blueButton: {
        // width: 100,
        fontWeight: "bold",
        color: "white !important",
        backgroundColor: "#f7dd50 !important",
        borderRadius: "5px",
        fontSize: "16px",
        padding: "8px 16px",
        boxShadow: "2px 2px #f0f0f0",
        marginRight: "10px",
        "&:focus": {
            backgroundColor: "#f7dd50 !important",
        }

    },
    logo: {
        width: 180,
        marginTop: "100px !important",
        marginBottom: "20px !important",
        resizeMode: 'contain',
        alignSelf: 'center',

    },
    centerContainer: {
        display: "flex",
        justifyContent: "center",
        flexGrow: 1
    },
    mainContainer: {
        width: "100%",
        height: "100vh",
        backgroundColor: "black",
        backgroundImage: "url('/images/phlebbackground.png')",
        backgroundSize: "cover"
    },
    slogan: {
        width: "240px",
        textAlign: "center",
        fontSize: "18px !important",
        color: "white !important"
    },
    loginBox: {
        backgroundColor: "white",
        border: "1px solid lightgray",
        padding: "25px",
        borderRadius: "20px",
        margin: "20px",
        marginTop: "40px",
    }, bottomButton: {
        position: 'absolute',
        backgroundColor: "#f7dd50",
        bottom: 15,
        right: 10,
        borderRadius: "5px",
        fontSize: "16px",
        padding: "8px 16px",
        boxShadow: "2px 2px #f0f0f0",
        marginRight: "10px",
        "&:focus": {
            backgroundColor: "#f7dd50",
        }
    },
    forgotPasswordText: {
        // marginTop: "8px",
        color: "#61B4D1 !important",
        fontSize: "12px !important",
        textAlign: "right",
        marginTop: "-12px"
    },
    formControl: {
        border: "1px solid #E6E6E6",
        borderRadius: "5px",
        marginTop: "8px",
        marginBottom: "20px"
    },
    fullWidth: {
        width: "100%",
    },
    labels: {
        fontSize: "12px !important",
        fontWeight: "600"
    },
    field: {
        padding: "8px 15px",
        border: "none !important",
    },
    passwordField: {
        paddingRight: "0px !important",
        height: "32px"
    },
    passwordFieldIcon: {
        color: "#AFAFAF"
    }
    // fieldInput: {
    //     "&:focus": { backgroundColor: "white !important" },
    //     "&:-internal-autofill-selected": { backgroundColor: "white !important" },
    // }
});

export default styles