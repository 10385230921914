import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import $ from 'jquery';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import SignaturePad from 'react-signature-pad-wrapper';
import trimCanvas from 'trim-canvas';
import styles from "./styles.js"
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import * as helper from 'components/common/Helper.js';
// import * as globals from 'components/common/GlobalConstants';

// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
// import InputLabel from '@material-ui/core/InputLabel';

const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing.unit * 2,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing.unit,
      top: theme.spacing.unit,
      color: theme.palette.grey[500],
    },
  }))(props => {
    const { children, classes, onClose } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });


class ApprovalSignaturePadClass extends Component {

    constructor(props) {
      super(props);
      this.signaturePad = null;
      
      this.state = {
        step: 1,
        signatory_type: "nurse",
        first_name: this.props.firstName ? this.props.firstName : "",
        last_name: this.props.lastName ? this.props.lastName : "",
        first_nameHasError: false,
        last_nameHasError: false,
        signatureData: [],
        signatureType: this.props.signatureType ? this.props.signatureType : "initial",
        signatureDialogOpened : false,
        signatureClearButtonDisabled : true,
        ratio: 1,
        canvasWidth: 0,
        canvasHeight: 0,
        maxDrawWidth: 0,
        maxDrawHeight: 0
      }
    }

    static CANCEL = () => {
      return "cancel";
    }
    static SUBMIT = () =>{
      return "submit";
    }
    
    componentDidUpdate = (prevProps) => {
        // console.log("this.props.visible " + this.props.visible);
        // console.log("prevProps.visible " +  prevProps.visible );
        if(this.state.signatureDialogOpened !== this.props.visible) {
            this.setState({...this.state, step: 1, signatureDialogOpened: this.props.visible});
        }
    }

    updateSignpadDimensions = () => {  
      var dialog_content = $("#approval-signatory-dialog > div > div > div:nth-child(2)");
  
      var padding = parseInt(dialog_content.css("padding-left"));
      var computed_width = (dialog_content.outerWidth() - (padding * 2));
      var computed_height = ((computed_width * .75) - (padding * 2));

      // this needed for trimming canvas
      // var signature_canvas = this.signaturePad.canvas;
      // console.log(this.signaturePad.canvas)
      // signature_canvas.width = computed_width; 
      // signature_canvas.height = computed_height;
  
      // this needed for updating dimension and redrawing signature when chaing orientation
      var signature_canvas_jquery = $("#approval-signatory-pad-container > canvas"); 
      // console.log(signature_canvas_jquery)
      signature_canvas_jquery.css({
        width: computed_width + "px",
        height: computed_height + "px"
      });

      this.setState({
        ...this.state, 
        canvasWidth: computed_width, 
        canvasHeight: computed_height,
      });
  
      if(this.signaturePad) {  
        // comment out to preserve signature data, clear only if submitted
        // to catch if signature pad is displayed
        //   this.signaturePad.clear();
      }
    }

    getTrimmedCanvas = () => {
      // copy the canvas
      var canvas = this.signaturePad.canvas; 
      // console.log(canvas);
      var copy = document.createElement('canvas')
      copy.width = canvas.width;
      copy.height = canvas.height;
      copy.getContext('2d').drawImage(canvas, 0, 0)
      // then trim it
      return trimCanvas(copy)
    }
  
    handleSignatureDialogEntered = () => {
      window.addEventListener("resize", this.updateSignpadDimensions);
    }
  
    handleSignatureDialogExited = () => {
      this.setState({
        ...this.state, 
        signatureData: [], 
        signatureClearButtonDisabled : true 
      });
      window.removeEventListener("resize", this.updateSignpadDimensions);
    }
  
    handleCloseSignatureDialog = () => {
      this.props.onEvent({
        command: ApprovalSignaturePad.CANCEL(),
        first_name: "",
        last_name: "",
        signature: "",
        ratio: 1,
        canvasWidth: 0,
        canvasHeight: 0,
        maxDrawWidth: 0,
        maxDrawHeight: 0
      });
      this.setState({...this.state, signatureDialogOpened: false });
    };
    
    onTouchSignature = (data) => {
      var trimmed_canvas = this.getTrimmedCanvas();
      this.setState({
        ...this.state, 
        signatureData: this.signaturePad.toData(), 
        signatureClearButtonDisabled : false,
        maxDrawWidth: trimmed_canvas.width,
        maxDrawHeight: trimmed_canvas.height
      });
    };
    
    handleClearApprovalSignature = () => {
      this.signaturePad.clear();
      this.setState({
        ...this.state, 
        signatureData: [], 
        signatureClearButtonDisabled : true 
      });
    };
    
    handleSubmitApprovalSignature = event => {
      this.props.onEvent({
        command: ApprovalSignaturePad.SUBMIT(),
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        signatory_type: this.state.signatory_type,
        signature: this.state.signatureData,
        ratio: window.devicePixelRatio || 1,
        canvasWidth: this.state.canvasWidth,
        canvasHeight: this.state.canvasHeight,
        maxDrawWidth: this.state.maxDrawWidth,
        maxDrawHeight: this.state.maxDrawHeight
      });
    };

    handleChangeStep = (step) => {
      // check only on current step
      if(this.isFieldsUIWithError() && this.state.step ===1) {
        this.updateFieldsUIError();
        return;
      }
      
      this.setState({...this.state, step : step }, function() {
        // callback after signaturePad is loaded, then load the existing signature
        if(this.signaturePad && step === 2) {          
          this.updateSignpadDimensions();
          // console.log(this.state.signatureData)
          this.signaturePad.fromData(this.state.signatureData)
        }
      });
    }

    handleChangeSignatoryType = (event) => {
      const name = event.target.name;
      this.setState({ 
        ...this.state, 
        [name]: event.target.value,
      });
    }

    handleChangeName = (event) => {
      const name = event.target.name;
      // console.log(name + ": " + event.target.value);
      this.setState({...this.state, [name]: event.target.value, [name + "HasError"]: false });
    }

    isFieldsUIWithError = () => {
      return this.state.first_name === "" || this.state.last_name === "";
    }

    updateFieldsUIError = () => {
      this.setState({
        ...this.state,
        first_nameHasError: this.state.first_name === "",
        last_nameHasError: this.state.last_name === ""
      });
    }
    
    render() {
        const { signatureType, classes } = this.props;

        return(
          <Dialog
            id="approval-signatory-dialog"
            fullScreen={false}
            classes={{
              paper: classes.dialogPaperSignature
            }}
            open={this.state.signatureDialogOpened}
            onEntered={this.handleSignatureDialogEntered}
            onExited={this.handleSignatureDialogExited}
          >
            <DialogTitle onClose={this.handleCloseSignatureDialog}>{helper.capitalize(signatureType) + " Approval"}</DialogTitle>
            <DialogContent className={classes.signatureDialogContentContainer}>   
              {(this.state.step ===1) &&
              <div>
                <Typography className={classes.approvalMessage}>I acknowledge that I have reviewed and approved the {helper.capitalize(signatureType)} draw list for today:</Typography>
                {/* {(siteType === globals.ORGANIZATION) && 
                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="signatory-type-el">Signatory Type *</InputLabel>
                  <Select
                    native
                    value={this.state.signatory_type}
                    onChange={this.handleChangeSignatoryType}
                    inputProps={{
                      name: 'signatory_type',
                      id: 'signatory-type-el',
                    }}
                    required
                  >
                    <option value={"nurse"}>Nurse</option>
                    <option value={"doctor"}>Doctor</option>
                  </Select>
                </FormControl>
                } */}
                <TextField
                  required={true}
                  inputProps={{
                    name: 'first_name',
                    id: 'first_name-el',
                  }}
                  value={this.state.first_name}
                  onChange={this.handleChangeName}
                  id="signatory-first-name"
                  label="First Name"
                  margin="normal"
                  error={this.state.first_nameHasError}
                  helperText={this.state.first_nameHasError ? "Required." : ""}
                  fullWidth                      
                />
                <TextField
                  required={true}
                  inputProps={{
                    name: 'last_name',
                    id: 'last_name-el',
                  }}
                  value={this.state.last_name}
                  onChange={this.handleChangeName}
                  id="signatory-last-name"
                  label="Last Name"
                  margin="normal"
                  error={this.state.last_nameHasError}
                  helperText={this.state.last_nameHasError ? "Required." : ""}
                  fullWidth                      
                />
              </div>
              }
              {(this.state.step ===2) && 
              <div>
                <Typography variant="caption" className={classes.signNote}>Please sign below:</Typography>
                <div
                  id="approval-signatory-pad-container"
                  className={classes.signaturePadContainer}>
                  <SignaturePad 
                    id="approval-signatory-canvas"
                    redrawOnResize={true}
                    options={{
                      onEnd: this.onTouchSignature
                    }} 
                    ref={ref => this.signaturePad = ref} />
                </div>
              </div>
              }
            </DialogContent>
            
            {(this.state.step ===1) && 
            <DialogActions>
              <Button 
                onClick={() => this.handleChangeStep(2)} className={classNames(classes.signatureButton)} variant="contained" color="secondary">
                Continue
              </Button> 
            </DialogActions>
            }
            {(this.state.step ===2) &&
            <DialogActions>
              <Button
                onClick={() => this.handleChangeStep(1)}
                className={classNames(classes.signatureButton, classes.floatLeft)} variant="contained" color="default">
                  Back
              </Button>
              {/* <Button
                disabled={this.state.signatureClearButtonDisabled}
                onClick={this.handleClearApprovalSignature} className={classNames(classes.signatureButton)} variant="contained" color="default">
                  Clear
              </Button> */}
              <Button               
                disabled={this.state.signatureClearButtonDisabled}
                onClick={this.handleSubmitApprovalSignature} className={classNames(classes.signatureButton)} variant="contained" color="secondary">
                  Submit
              </Button> 
            </DialogActions>
            }            
          </Dialog>
          );
          
    }

}
ApprovalSignaturePadClass.propTypes = {
  onEvent :  PropTypes.func.isRequired 
}
const ApprovalSignaturePad =  withStyles(styles)(ApprovalSignaturePadClass);
export default ApprovalSignaturePad;
