import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import classNames from 'classnames';
import ApplicationBar from 'components/common/mobile/ApplicationBar';
import SimpleBottomNavigation from 'components/common/mobile/SimpleBottomNavigation';
import { connect } from "react-redux";

// import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
// import TextField from '@material-ui/core/TextField';
import styles from "./styles.js";
// import { InputBase } from '@material-ui/core';
// import Paper from '@material-ui/core/Paper';

import FutureOrdersList from './FutureOrdersList';

import {
    updateServicedZipcodes
    } from "js/actions";

//import ImmutableJS from "immutable";

const mapStateToProps = state => {
  return { 
     futureOrders: state.futureOrders,
     // scheduleData: state.scheduleData
    };
};
const mapDispatchToProps = dispatch => {
    return {
        updateServicedZipcodes: serviced_zipcodes => dispatch(updateServicedZipcodes(serviced_zipcodes))
    };
};

class FutureOrdersClass extends Component {

    // constructor(props){
    //     super(props);
    // }

    componentWillMount() {
    }

    componentDidUpdate(prevProps) {
    }

    loadData = () => {
    }

    goTo(url) {
        this.props.history.push(url);
    }

    onItemClickHandler = (item,greet) => {
        console.log(item);
       // console.log(greet);
        this.goTo(`/future_order_details/${item}/list`);
    }

    goToSite(url) {
        this.props.history.push(url);
    }

    render() {   
        const { classes } = this.props;
       // console.log(this.props.futureOrders);
        return(
            <div className={classes.root}>
                <main className={classes.content}>
                    <ApplicationBar isInfoHidden={true} />
                    <div className={classNames(classes.schedulesMainContainer)}>
                        <div>
                            <List classes={{root: classNames(classes.listUL, classes.listULMainHeader)}}>
                                <ListItem className={classNames(classes.listLI)}>
                                    <ListItemText
                                        classes={{
                                            primary: classes.schedulesMainHeader,
                                            // secondary: classes.workTerritoryNote
                                        }}
                                        primary="Work Schedule"
                                    />
                                </ListItem>
                            </List>
                        </div>

                        <List classes={{}}>
                            <ListItem className={classes.topNavButtonRightGutter}>
                                <Button
                                    onClick={() => this.goToSite("/schedules")}
                                    variant="contained"
                                    classes={{
                                        root: classNames(
                                            classes.scheduleWhiteButtonLeftMargin,
                                            classes.topNavButton,
                                            classes.switchViewButton,
                                            classes.switchViewButtonLeft),
                                        label: classes.topNavButtonLabel
                                    }}>Working Hours</Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    classes={{
                                        root: classNames(
                                            classes.topNavButton,
                                            classes.switchViewButton,
                                            classes.switchViewButtonRight,
                                            classes.switchViewButtonSelected),
                                        label: classes.topNavButtonLabel
                                    }}>Upcoming Orders</Button>
                            </ListItem>
                        </List>
                        
                        <div className={classes.content}>
                            <FutureOrdersList {...this.props} onItemClick={this.onItemClickHandler} />
                        </div>
                    </div>
                    <div className={classes.toolbar} />
                </main>
                <SimpleBottomNavigation />
            </div>
        );
    }
}

FutureOrdersClass.propTypes = {
    classes: PropTypes.object.isRequired,
};

const FutureOrders = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FutureOrdersClass));
export default FutureOrders;
