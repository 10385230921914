import React, { useState, useEffect } from 'react'

import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"
import classNames from 'classnames'

import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import {
    Typography,
    FormControl,
    Button
} from '@material-ui/core'

import moment from 'moment'
import * as globals from 'components/common/GlobalConstants'
import uuid from 'uuid'

import SelectDateDialog from './SelectDateDialog'
import SelectTimeDialog from './SelectTimeDialog'
import SelectWeekDialog from './SelectWeekDialog'

// import Calendar from 'react-calendar/dist/entry.nostyle'
// import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
// import ArrowRightIcon from '@material-ui/icons/ArrowRight'

const mapDispatchToProps = dispatch => { return {} }

const mapStateToProps = state => { return {} }

const FieldsFunctionClass = (props) => {
    const {
        // drawData,
        // history,
        match,
        classes,
        onChangeData,
        repeatOptions,
        selectedSchedule,
        editingNotAllowed,
        deletingNotAllowed
    } = props
    const { work_schedule_date } = match.params

    const workScheduleDate = moment(work_schedule_date, "YYYY-MM-DD")

    const [selectDateDialogOpen, setSelectDateDialogOpen] = useState(false)
    const [selectedDate, setSelectedDate] = useState(workScheduleDate.format("dddd, MMM DD, YYYY"))

    const [selectTimeFromDialogOpen, setSelectTimeFromDialogOpen] = useState(false)
    const [timeFrom, setTimeFrom] = useState("08:00 AM")

    const [selectTimeToDialogOpen, setSelectTimeToDialogOpen] = useState(false)
    const [timeTo, setTimeTo] = useState("08:30 AM")

    const [selectWeekDialogOpen, setSelectWeekDialogOpen] = useState(false)
    const [weekNumber, setWeekNumber] = useState(2)

    const [repeatType, setRepeatType] = useState("")
    const [specificDates, setSpecificDates] = useState([moment(new Date()).format("YYYY-MM-DD")])

    const [selectedDays, setSelectedDays] = useState([workScheduleDate.format("dddd").toLowerCase()])

    const [selectEndDateDialogOpen, setSelectEndDateDialogOpen] = useState(false)
    const [selectedEndDate, setSelectedEndDate] = useState(workScheduleDate.format("dddd, MMM DD, YYYY"))

    // const customizedCalendarTileContent = ({ date, view }) => {
    //     var calendar_date = moment(date).format("YYYY-MM-DD")

    //     var content = ""
    //     const is_included = specificDates.includes(calendar_date) ? " today" : ""

    //     content = <div
    //         className={
    //             "custom-calendar-date-tile" +
    //             is_included
    //         }
    //         onClick={() => {
    //             var newDates = [...specificDates]
    //             if (specificDates.includes(calendar_date)) {
    //                 newDates = newDates.filter(i => {
    //                     return i !== calendar_date
    //                 })
    //             } else {
    //                 newDates.push(calendar_date)
    //             }
    //             setSpecificDates(newDates)
    //         }}
    //     >
    //         {date.getDate()}
    //     </div>

    //     return view === 'month' && content
    // }

    const updateSelectedDaysOfWeek = (day_key) => {
        // console.log(day_key)
        // console.log(selectedDays)
        var newDays = [...selectedDays]
        if (selectedDays.includes(day_key)) {
            newDays = newDays.filter(i => {
                return i !== day_key
            })
        } else {
            newDays.push(day_key)
        }

        // must have atleast 1 day selected
        if (newDays.length === 0) {
            newDays.push(moment(selectedDate, "dddd, MMM DD, YYYY").format("dddd").toLowerCase())
        }
        setSelectedDays(newDays)
    }

    useEffect(() => {
        // if end is before start, set Days of Week = start date day
        if (moment(selectedEndDate, "dddd, MMM DD, YYYY").isBefore(moment(selectedDate, "dddd, MMM DD, YYYY"))) {
            setSelectedEndDate(selectedDate)
            setSelectedDays([moment(selectedDate, "dddd, MMM DD, YYYY").format("dddd").toLowerCase()])
        }
        // REAL: we need to check if Days of Week exists on date range?
        // in XD, altho date range is 1 day, all Days of Week are selected
    }, [selectedDate, selectedEndDate])

    useEffect(() => {
        if (moment(timeTo, "hh:mm A").isBefore(moment(timeFrom, "hh:mm A"))) {
            setTimeTo(timeFrom)
        }
    }, [timeFrom])

    useEffect(() => {
        if (moment(timeFrom, "hh:mm A").isAfter(moment(timeTo, "hh:mm A"))) {
            setTimeFrom(timeTo)
        }
    }, [timeTo])

    useEffect(() => {
        const data = {
            date_from: selectedDate,
            date_to: selectedEndDate,
            time_from: timeFrom,
            time_to: timeTo,
            repeat_type: repeatType,
            week_number: weekNumber,
            specific_dates: specificDates,
            selected_days: selectedDays
        }
        onChangeData(data)
    }, [selectedDate, timeFrom, timeTo, repeatType, weekNumber, specificDates, selectedDays, selectedEndDate])

    // for edit
    useEffect(() => {
        if (selectedSchedule) {
            console.log(selectedSchedule)
            setTimeFrom(moment(selectedSchedule.time_from, "HH:mm:ss").format("hh:mm A"))
            setTimeTo(moment(selectedSchedule.time_to, "HH:mm:ss").format("hh:mm A"))
            setRepeatType(selectedSchedule.repeat_type ? selectedSchedule.repeat_type : "")
            setWeekNumber(selectedSchedule.repeat_interval ? selectedSchedule.repeat_interval : 1)
            setSelectedDays(selectedSchedule.days ? selectedSchedule.days.map(day => moment(day, "dddd").format("dddd").toLowerCase()) : [moment().format("dddd").toLowerCase()])
            setSelectedEndDate(moment(selectedSchedule.date_to, "YYYY-MM-DD").format("dddd, MMM DD, YYYY"))
        }
    }, [selectedSchedule])

    return (
        <>
            <div className={classes.scheduleFieldsContainer}>
                <Typography className={classes.labels}>
                    Date
                </Typography>
                <FormControl
                    variant="outlined"
                    className={
                        classNames(classes.formControl, classes.fullWidth, editingNotAllowed ? classes.formControlDisabled : "")
                    }
                >
                    <Select
                        id="date-select"
                        onClick={() => {
                            if (!editingNotAllowed) {
                                setSelectDateDialogOpen(true)
                            }
                        }}
                        className={classes.selectField}
                        classes={{
                            selectMenu: classes.selectMenu
                        }}
                        disableUnderline
                        value={selectedDate}
                        readOnly
                        disabled={editingNotAllowed}
                    >
                        <MenuItem value={selectedDate}>
                            <Typography className={classes.selectFieldFont}>{selectedDate}</Typography>
                        </MenuItem>
                    </Select>
                </FormControl>

                <Typography className={classes.labels}>
                    Time
                </Typography>

                <Grid
                    container
                    alignItems="center"
                    justify="center"
                >
                    <Grid item xs style={{ paddingRight: 5 }}>
                        <FormControl
                            variant="outlined"
                            className={
                                classNames(classes.formControl, classes.fullWidth, editingNotAllowed ? classes.formControlDisabled : "")
                            }
                        >
                            <Select
                                id="time-from-select"
                                displayEmpty
                                onClick={() => {
                                    if (!editingNotAllowed) {
                                        setSelectTimeFromDialogOpen(true)
                                    }
                                }}
                                className={classes.selectField}
                                classes={{
                                    selectMenu: classes.selectMenu
                                }}
                                disableUnderline
                                value={timeFrom}
                                disabled={editingNotAllowed}
                                readOnly
                            >
                                <MenuItem value={timeFrom}>
                                    <Typography className={classNames(classes.selectFieldFont, timeFrom === "" ? classes.placeholder : "")}>{timeFrom === "" ? "Select" : timeFrom}</Typography>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs style={{ paddingLeft: 5 }}>
                        <FormControl
                            variant="outlined"
                            className={
                                classNames(classes.formControl, classes.fullWidth, editingNotAllowed ? classes.formControlDisabled : "")
                            }
                        >
                            <Select
                                id="time-to-select"
                                displayEmpty
                                onClick={() => {
                                    if (!editingNotAllowed) {
                                        setSelectTimeToDialogOpen(true)
                                    }
                                }}
                                className={classes.selectField}
                                classes={{
                                    selectMenu: classes.selectMenu
                                }}
                                disableUnderline
                                value={timeTo}
                                readOnly
                                disabled={editingNotAllowed}
                            >
                                <MenuItem value={timeTo}>
                                    <Typography className={classNames(classes.selectFieldFont, timeTo === "" ? classes.placeholder : "")}>{timeTo === "" ? "Select" : timeTo}</Typography>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Typography className={classes.labels}>
                    Repeat Schedule
                </Typography>

                <FormControl
                    variant="outlined"
                    className={
                        classNames(classes.formControl, classes.fullWidth, editingNotAllowed ? classes.formControlDisabled : "")
                    }
                    style={{ marginBottom: 0 }}
                >
                    <Select
                        id="repeat-schedule-select"
                        displayEmpty
                        onChange={(event) => {
                            if (!editingNotAllowed) {
                                setRepeatType(event.target.value)
                            }
                        }}
                        className={classes.selectField}
                        classes={{
                            selectMenu: classes.selectMenu
                        }}
                        disableUnderline
                        value={repeatType}
                        disabled={editingNotAllowed}
                    >
                        <MenuItem value="">
                            <Typography className={classNames(classes.selectFieldFont)}>Does not repeat</Typography>
                        </MenuItem>
                        {/* , classes.placeholder 
                        {globals.v2.SCHEDULE.REPEAT_SCHEDULE_OPTIONS.map((option) => {
                            return <MenuItem key={uuid.v4()} value={option.key}>
                                <Typography className={classes.selectFieldFont}>{option.value}</Typography>
                            </MenuItem>
                        })} */}
                        {Object.keys(repeatOptions).map((key) => {
                            if (globals.v2.SCHEDULE.ALLOWED_REPEAT_SCHEDULE_OPTIONS.includes(key)) {
                                return <MenuItem key={uuid.v4()} value={key}>
                                    <Typography className={classes.selectFieldFont}>{repeatOptions[key]}</Typography>
                                </MenuItem>
                            }
                        })}
                    </Select>
                </FormControl>

                {repeatType === "every_n_week" &&
                    <>
                        <Grid
                            container
                            alignItems="center"
                        >
                            <FormControl
                                variant="outlined"
                                className={
                                    classNames(classes.formControl, classes.weekSelectFormControl, editingNotAllowed ? classes.formControlDisabled : "")
                                }
                            >
                                <Select
                                    id="repeat-every-n-weeks-select"
                                    displayEmpty
                                    onClick={() => {
                                        if (!editingNotAllowed) {
                                            setSelectWeekDialogOpen(true)
                                        }
                                    }}
                                    className={classes.selectField}
                                    classes={{
                                        selectMenu: classes.selectMenu
                                    }}
                                    disableUnderline
                                    value={weekNumber}
                                    readOnly
                                    disabled={editingNotAllowed}
                                >
                                    <MenuItem value={weekNumber}>
                                        <Typography className={classNames(classes.selectFieldFont)}>{weekNumber}</Typography>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <Typography className={classes.weekNumberLabel}>{weekNumber === 1 ? "Week" : "Weeks"}</Typography>
                        </Grid>

                        <Typography
                            className={classes.labels}
                            style={{ marginBottom: 8 }}
                        >
                            Days of the Week
                        </Typography>

                        {globals.v2.SCHEDULE.DAYS_OF_WEEK.map((day, index) => {
                            const active = selectedDays.includes(day.key) ? classes.activeDayOfWeek : ""
                            return <Button
                                key={uuid.v4()}
                                color="primary"
                                className={classNames(classes.daysOfWeek, active)}
                                onClick={() => updateSelectedDaysOfWeek(day.key)}
                                disabled={editingNotAllowed}
                            >{day.label}</Button>
                        })}
                    </>
                }

                {(repeatType === "every_day" || repeatType === "every_n_week") &&
                    <>
                        <Typography
                            className={classes.labels}
                            style={{ marginTop: 19 }}
                        >
                            Ends on
                        </Typography>

                        <FormControl
                            variant="outlined"
                            className={
                                classNames(classes.formControl, classes.fullWidth, editingNotAllowed ? classes.formControlDisabled : "")
                            }
                            style={{ marginBottom: 0 }}
                        >
                            <Select
                                id="end-date-select"
                                onClick={() => {
                                    if (!editingNotAllowed) {
                                        setSelectEndDateDialogOpen(true)
                                    }
                                }}
                                className={classes.selectField}
                                classes={{
                                    selectMenu: classes.selectMenu
                                }}
                                disableUnderline
                                value={selectedEndDate}
                                readOnly
                                disabled={editingNotAllowed}
                            >
                                <MenuItem value={selectedEndDate}>
                                    <Typography className={classes.selectFieldFont}>{selectedEndDate}</Typography>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </>
                }

                {/* {repeatType === "every_day" &&
                    <>
                    
                        <Typography
                            className={classes.labels}
                            style={{ marginTop: 19 }}
                        >
                            Specific Dates (you may select multiple dates)
                        </Typography>

                        <Calendar
                            // key={calendarUUID}
                            activeStartDate={new Date()}
                            calendarType="US"
                            view="month"
                            className="modified-calendar-v2"
                            tileContent={customizedCalendarTileContent}
                            // onClickDay={onSetActiveStartDate}
                            prevLabel={<ArrowLeftIcon />}
                            nextLabel={<ArrowRightIcon />}
                            formatShortWeekday={(locale, date) => {
                                return moment(date).format('ddd').substring(0, 1)
                            }}
                        />
                    </>
                } */}
            </div>

            <SelectDateDialog
                initialSelectedDate={selectedDate}
                open={selectDateDialogOpen}
                minDateAllowed={moment()}
                onCancel={() => setSelectDateDialogOpen(false)}
                onSelect={(date) => {
                    setSelectedDate(moment(date).format("dddd, MMM DD, YYYY"))
                    setSelectDateDialogOpen(false)
                }}
            />

            <SelectTimeDialog
                initialSelectedTime={timeFrom}
                open={selectTimeFromDialogOpen}
                onCancel={() => setSelectTimeFromDialogOpen(false)}
                onSelect={(time) => {
                    setTimeFrom(time)
                    setSelectTimeFromDialogOpen(false)
                }}
            />

            <SelectTimeDialog
                initialSelectedTime={timeTo}
                open={selectTimeToDialogOpen}
                onCancel={() => setSelectTimeToDialogOpen(false)}
                onSelect={(time) => {
                    setTimeTo(time)
                    setSelectTimeToDialogOpen(false)
                }}
            />

            <SelectWeekDialog
                initialSelectedWeek={weekNumber}
                open={selectWeekDialogOpen}
                onCancel={() => setSelectWeekDialogOpen(false)}
                onSelect={(weekNumber) => {
                    setWeekNumber(weekNumber)
                    setSelectWeekDialogOpen(false)
                }}
            />

            <SelectDateDialog
                key={uuid.v4()}
                // initialSelectedDate={selectedDate}
                initialSelectedDate={moment(selectedEndDate, "dddd, MMM DD, YYYY").isBefore(moment(selectedDate, "dddd, MMM DD, YYYY")) ? selectedDate : selectedEndDate}
                open={selectEndDateDialogOpen}
                minDateAllowed={moment(selectedDate, "dddd, MMM DD, YYYY")} // .subtract(1, 'days')}
                onCancel={() => setSelectEndDateDialogOpen(false)}
                onSelect={(date) => {
                    setSelectedEndDate(moment(date).format("dddd, MMM DD, YYYY"))
                    setSelectEndDateDialogOpen(false)
                }}
            />
        </>
    )
}

const Fields = withCookies(
    connect(mapStateToProps, mapDispatchToProps)(
        withStyles(styles, { withTheme: true })(FieldsFunctionClass)
    )
)
export default Fields
