import * as globals from 'components/common/GlobalConstants';

const styles = theme => ({
    root: {
        // display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
    },
    overrideBlue: {
        background: globals.BLUE_GRADIENT_BG,
    },
    greatJobIcon: {
      fontSize: 80,
      margin: theme.spacing.unit * 2,
      color: "#12D003",
      border: "5px solid #12D003",
      borderRadius: "50%",
    },
    centered: {
      textAlign: 'center',
    },
    doneGrayshade: {
        color: "#bdbdbd",
    },
    avatarGrayshadeBG: {
        color: '#fafafa',
        backgroundColor: "#bdbdbd",
    },
    progressBar: {
        marginTop: 6.46,
        marginBottom: 5.65,
        borderRadius: 16,
        height: 14.89,
        boxShadow: '0 10px 15px 0 rgba(0, 0, 0, 0.15), 0 34px 60px 0 rgba(0, 0, 0, 0.11)',
    },
    progressPercentageText: {
        fontSize: "10px !important",
        // fontWeight: "bold !important"
    },
    allowanceTopBottom: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
    },
    allowanceTop: {
      marginTop: theme.spacing.unit * 3,
    },
    allowanceBottom: {
      marginBottom: theme.spacing.unit * 3,
    },
    overallProgress: {
        fontSize: '13px !important',
        // fontWeight: "600 !important",
    },
    progressContainer: {
        marginBottom: theme.spacing.unit * 2,
    },
    linearColorPrimary: {
        backgroundColor: "white",//"#f1f8e9",
    },
    //hijacked inline style, b'coz LinearProgress bar is using transform scalex, so border radius is a problem
    linearBarColorPrimary: {
        backgroundColor: "#12D003",
        borderRadius: 30,
    },
    allSitesNote: {
        fontSize: '28px !important',
        marginTop: theme.spacing.unit * 4.5
    },
    filters: {
        color: "#A9A9A9 !important",
        padding: "3px 3px !important",
        margin: "2px",
        marginRight: "10px",
        display: "inline",
        fontSize: "10px !important",
        minHeight: '0',
        minWidth: '0',
        fontWeight: "normal",
        textTransform: "none"
    },
    filterContainerTableCell: {
        width: "100%",
        height: "18px !important",
        padding: "0px"
    },
    filterContainer: {
        paddingLeft: 8,
        backgroundColor: "#707070",
        color: "white",
        marginRight: 4,
        // width: "73.5%",
        // float: "left"
    },
    // editRouteButtonContainer: {
    //     width: "19%", 
    //     float:"right", 
    //     backgroundColor: "#FBFBFB",
    //     textAlign: "center"
    // },
    editRouteTableCell: {
        backgroundColor: "#12D003",
        padding: 0,
    },
    editRoute: {
        color: "#fff !important",
        padding: "3px 3px !important",
        display: "inline",
        fontSize: "10px !important",
        minHeight: '0',
        minWidth: '0',
        fontWeight: "normal",
        textTransform: "none",
        textAlign: 'center',
        width: "100%",
        whiteSpace: 'nowrap',
    },
    selectedFilter: {
      color: "#fff",
      fontWeight: "bold"
    },
    hackAdjustment: {
      paddingLeft: 15,
    },
    tableRow: {
        height: '18px !important',
        width: "100%"
    },
    tableHeader: {
        height: '18px !important',
        width: "100%"
    },
    tableCellHeader: {
        padding: "4px 0px !important",
        paddingRight: "4px !important",
        backgroundColor: "white",//"#68CBEF",
        color: "#000 !important",
        fontSize: '10px !important',
        whiteSpace: 'nowrap', 
        textAlign: 'center',
        minHeight: '0',
    },
    lastTableCellHeader: {
        paddingRight: "0px !important",
    },
    nowrap: {
        whiteSpace: 'nowrap',
    },
    tableCell: {
        padding: 14,
    },
    sitesSortableCell: {
        padding: "0px !important"  
    },
    sitesTableCell: {
        paddingRight: "7px !important",
    },
    lastTableCell: {
      paddingRight: "14px !important",
    },
    statusCompleted: {
        fontWeight: "bold",
        color: "#12D003 !important",
    },
    myTable: {
        boxShadow: '0 10px 15px 0 rgba(0, 0, 0, 0.15), 0 34px 60px 0 rgba(0, 0, 0, 0.11)',
    },
    fullWidth: {
        width: "100%"
    },
    headerInfo: {
        paddingTop: "15px",
        paddingLeft: "15px",
        paddingRight: "15px",
    },
    siteListContainer: {
        padding: "15px",
    },
    siteName: {
        fontSize: "12px !important",
        fontWeight: 'bold',
        color: '#707070'
    },
    siteAddress: {
        fontSize: "11px !important",
    },
    siteStatus: {
        fontSize: "11px !important",
    },
    siteDraws: {
        fontSize: "11px !important",
        fontWeight: "bold",
        color: "#68CBEF !important"
    },
    checkCircle: {
        color: "#12D003",
        fontSize: 20,
        display: 'inline-block',
        verticalAlign: 'middle',
        marginLeft: 5,
    },
    withSTAT: {
        backgroundColor: '#FEDC4F'
    },
    badges: {
      fontSize: 10,
      padding: `${theme.spacing.unit * .2}px ${theme.spacing.unit * .2}px`,
      marginRight: "4px",
      marginTop: "2px",
      marginBottom: "3px",
      width: 8, 
      height: 8,
      border: "2px solid white",
      borderRadius: "50%",
    },
    STAT: {
        color: '#000',
      //  backgroundColor: '#FAD94E',#D20815
        backgroundColor: '#D20815',
        textTransform: 200
    },
    FASTING: {
        color: '#000',
        backgroundColor: '#0000FF',
    },
    siteRowCompleted: {
        backgroundColor: '#D0D0D0 !important',
        // textShadow: '.1px .6px #FAFAFA'
    },
    badgesContainer: {
        display: "table-cell",
        verticalAlign: "top",
        width: "15px", 
    },
    sitesTableRow: {
        cursor: "move",
        backgroundColor: "white",
        // display: "table",
        // width: "100%"
    },
    siteInfo: {
        display: "table-cell",
        verticalAlign: "top",
        paddingLeft: "5px",
        width: "1000px" // just a hack for now
    },
    optionsContainer: {
        display: "table-cell",
        verticalAlign: "middle",
        padding: "10px 10px"
    },
    optionsIcon: {
        width: 25,
        height: 25
    },
    dndPlaceholder: {
        backgroundColor: "#DCDCDC",
        height: 86
    },
    sortableUnordereList: {
        margin: 0,
        padding: 0,
        backgroundColor: "#f3f3f3"
    },
    sortableList:{
        listStyleType: "none",
        padding: 14,
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        backgroundColor: "white",
        // zIndex: 9999
    },
    sortableHelperClass: {
        border: "1px solid #ccc"
    }
});

export default styles;