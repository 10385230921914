import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'; 
import Login from "components/Login";
import PouchDB from 'pouchdb-browser';
const styles = theme => ({
    root: {
        paddingTop: "25px !important",
        paddingLeft: "40px !important",
        paddingRight: "40px !important",
        paddingBottom: "40px !important",
    },
});

class Logout extends Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    componentDidMount() {
        const { cookies } = this.props;
        var cred = cookies.get('credentials') || ''
        var database = cred.database;
        this.deleteDatabase(database);
        cookies.remove('credentials');
        cookies.remove('selected_site');

    }

    deleteDatabase = (databse) => {
        new PouchDB(databse).destroy().then(function () {
            console.log("Database was destroyed sucessfully : " + databse);
          }).catch(function (err) {
            console.log("Failed to destroy database : " +  databse);
          })
    }

    render() {
        const { classes } = this.props;
        
        return(
            <div className={classes.root}>
                {/* <Typography>Force Logout</Typography> */}
                <BrowserRouter>
                        <Switch>
                            <Route path="/login"  render={(props) => <Login {...props}/>}  />
                            <Redirect to="/login" />
                             </Switch>
                    </BrowserRouter>
            </div>
        );

    }
}

Logout.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withCookies(withStyles(styles)(Logout));