import React from 'react';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';

import Icon from '@material-ui/core/Icon';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import $ from 'jquery';

// import NotificationsIcon from '@material-ui/icons/Notifications';
import { Detector } from "react-detect-offline";

import * as globals from '../GlobalConstants';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },    
    toolbar: theme.mixins.toolbar,
    overrideBlue: {
        background: globals.LIGHT_BLUE_GRADIENT_BG,
        color: "#01579B",
    },
    titleIcon: {
        lineHeight: 1.25,
        fontSize: 50,
    },
    pageTitle: {
        fontSize: '50px !important',
        fontWeight: 'bold',
        // margin: theme.spacing.unit * 2.5,
        marginTop: theme.spacing.unit * 4.5,
        // marginBottom: theme.spacing.unit * 4.5,
    },
    dateSection: {
        // marginLeft: theme.spacing.unit * 2.5,
        // marginRight: theme.spacing.unit * 2.5,
        marginTop: theme.spacing.unit,
        display: 'flex', 
        justifyContent: 'flex-end', 
        alignItems: 'center',
        textShadow: '1px 1px 2px #fff',
    },
    wifiIcon: {
        // marginRight: 10,
        verticalAlign: "middle",
        padding: 12,
        // paddingRight: 0,
        marginTop: -3,
        fontSize: 44,
    },
    online: {
        color: "#64DD17"
    },
    offline: {
        color: "#CFD8DC"
    },
    appBar: {
        height: 80,
        background: "#FFFFFF", //globals.LIGHT_BLUE_GRADIENT_BG,
        color: "#707070",
    },
    logo: {
        // height: 36,
        width: 158,
        marginTop: 8,
    },
    notification_icon: {
        width: 30,
        height: 30,
    },
    notification_icon_cont: {
        // width: 15.78,
        // height: 15.78,
        paddingLeft: 10,
        paddingRight: 0,
    },
    icon: {
      width: 40,
      height: 40,
    },
    notificationBadge: {
        top: -3,
        right: -10,
        // The border color match the background color.
        border: `2px solid ${
            '#fff'
            // theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
        }`,
    },    
    toolbarGutters: {
        paddingRight: 40,
        paddingLeft: 40,
    },
});

class ApplicationBar extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        
        this.state = {
            // dateInterval: null,
            anchorEl: null,
            aboutDialogOpened: false,
        };
    }

    componentDidMount() {
        // clearInterval(this.state.dateInterval);
        //console.log("ApplicationBar componentDidMount");
        this.handleRealTimeDate();
        // var interval = setInterval(() =>{ this.handleRealTimeDate() }, 10000); // 10 secs  
        
        // this.setState({ ...this.state, dateInterval: interval });
    }

    componentWillUnmount() {
        // clearInterval(this.state.dateInterval);
    }

    handleRealTimeDate() {
        var currentDate = new Date(),
        display_day = currentDate.getDay(),
        day = currentDate.getDate(),
        month = currentDate.getMonth() + 1,
        year = currentDate.getFullYear();
        
        // var currentTime = new Date(),
        // hours = currentTime.getHours(),
        // minutes = currentTime.getMinutes();

        // if (minutes < 10) {
        //     minutes = "0" + minutes;
        // }

        // var suffix = "AM";
        // if (hours >= 12) {
        //     suffix = "PM";
        //     hours = hours - 12;
        // }
        // if (hours === 0) {
        //     hours = 12;
        // }
        // var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        // var days = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
        var days = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
        
        // console.log(days[display_day] + ", " + months[month - 1] + " " + day + ", " + year + " " + hours + ":" + minutes + " " + suffix);
        $("#datetime-holder").html(days[display_day] + ", " + months[month - 1] + " " + day + ", " + year);// + " " + hours + ":" + minutes + " " + suffix);
    };
    
    handleOpenDialog = () => {
        this.setState({ aboutDialogOpened: true });
    };
  
    handleCloseDialog = () => {
        this.setState({ aboutDialogOpened: false });
    };

    handleMenu = event => {
      this.setState({ anchorEl: event.currentTarget });
    };
  
    handleClose = () => {
      this.setState({ anchorEl: null });
    };

    handleBackButtonClick () {
        document.location.href = this.props.back;
    };
    
    // handleLogoutClick(e) {
    //     const { cookies } = this.props;

    //     // cookies.set('credentials', '', { path: '/' });
    //     cookies.remove('credentials');

    //     window.location.href = '/';
    // };
  
    render() {
        const { classes } = this.props;
        const path_name = (window.location.pathname.split('/')[1]);
        // const creds = cookies.get('credentials');
        // const display_name = creds.display_name;

        return (
            <div>
                <AppBar id="application_bar" position="fixed">
                    <Toolbar 
                        classes={{
                            gutters: classes.toolbarGutters,
                            regular: classes.appBar
                        }}>
                        {/* {this.props.back !== undefined &&
                            <Link to={this.props.back}>
                                <IconButton className={classes.menuButton} color="inherit" aria-label="Menu">
                                    <MenuIcon />
                                </IconButton>
                            </Link>
                        } */}
                        <Typography className={classes.grow} color="inherit">
                            {/* {this.props.icon !== undefined && <Icon className={classes.titleIcon}>{this.props.icon}</Icon> } */}
                            {/* {this.props.title} */}
                                <img alt="TeloPoint: Lab" className={classNames(classes.logo)} src="/images/TeloPoint.svg" />
                        </Typography>
                        <div>
                            <Detector
                                render={({ online }) => (
                                    <Icon className={classNames(classes.wifiIcon, online ? classes.online : classes.offline)}>{online ? "wifi" : "wifi_off"}</Icon>
                                )}
                            />
                            <IconButton 
                                aria-label="Notification"
                                component={Link} 
                                to="/notifications" 
                                className={classes.notification_icon_cont} 
                                >
                                <img 
                                    alt="notification icon" 
                                    className={classes.notification_icon} 
                                    src={path_name==="notifications"?"/images/alert_selected.svg":"/images/alert_gray_fill.svg"} />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                <div className={classes.toolbar} />
                
                {/* <div className={classes.dateSection}>
                    <Detector
                        render={({ online }) => (
                            <Icon className={classNames(classes.wifiIcon, online ? classes.online : classes.offline)}>{online ? "wifi" : "wifi_off"}</Icon>
                        )}
                    />
                    <Typography 
                        style={{display: "inline"}} 
                        id="datetime-holder" 
                        variant="body1"></Typography>
                </div> */}
                {/* <div className={classes.dateSection}>
                    <Typography variant="body1">Logged in as: {display_name}</Typography>
                </div> */}

                {/* {this.props.title !== undefined && <Typography className={classNames(classes.pageTitle)}>{this.props.icon !== undefined && <Icon className={classes.titleIcon} size="inherit">{this.props.icon}</Icon> } {this.props.title}</Typography>} */}
                {this.props.title !== undefined && <Typography className={classNames(classes.pageTitle)}>{this.props.title}</Typography>}
            </div>
        );
    }
}

ApplicationBar.propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired,
};

export default withCookies(withMobileDialog() (
    withStyles(styles)(ApplicationBar))
);



