import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import MyClass from './MyClass';
import classNames from 'classnames';
// import ImmutableJS from "immutable";
// import EllipsisText from "react-ellipsis-text";
// import { AddAPhoto } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';

import ConfirmDialog from 'components/common/mobile/ConfirmDialog';
// import NotesIcon from '@material-ui/icons/Notes';
// import DeleteIcon from '@material-ui/icons/Delete';
// import AddIcon from '@material-ui/icons/Add';
// import $ from 'jquery';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Button from '@material-ui/core/Button';
import moment from 'moment-timezone';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
// import { InputBase } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// import TextField from '@material-ui/core/TextField';
import * as globals from 'components/common/GlobalConstants';
import styles from "./styles.js";

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';

//import {BrowserMultiFormatReader } from '@zxing/library'; // BrowserQRCodeReader

import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';

import { connect } from "react-redux";
import * as helper from 'components/common/Helper.js';

//import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import ImagePreview from './ImagePreview';
// import Iframe from 'react-iframe';
// import { MobilePDFReader } from 'react-read-pdf';
import CustomWebcam2 from 'components/common/mobile/CustomWebcam2'
//import  CustomWebcam  from 'components/common/mobile/CustomWebcam';
//import $ from 'jquery';
import EllipsisText from "react-ellipsis-text";
import BarcodeReader2 from 'components/common/mobile/BarcodeReader2';
import PhotoCapture2 from 'components/common/mobile/PhotoCapture2';
import Notes2 from 'components/common/mobile/Notes2';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { ReactComponent as BarcodeSVG } from 'components/common/mobile/CustomOfflineSVG/barcode.svg';
import RootRef from '@material-ui/core/RootRef';
import Divider from '@material-ui/core/Divider';
//import PDFViewer from 'pdf-viewer-reactjs'
// import PDFViewer from 'mgr-pdf-viewer-react';
// @import url('bulma/css/bulma.css');
// @import url('material-design-icons/iconfont/material-icons.css');

import {
    updatePatientDrawStatus,
    verifyPatientName,
    verifyPatientDoB,
    saveNote,
    deleteNote,
    saveDrawRequestDocument,
    deleteDrawRequestDocument
} from "js/actions";

// const EDIT = "edit"
const NEW = "new"
const PREVIEW = "preview"

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const mapDispatchToProps = dispatch => {
    return {
        updatePatientDrawStatus: drawItem => dispatch(updatePatientDrawStatus(drawItem)),
        verifyPatientName: verifiedPatientNameData => dispatch(verifyPatientName(verifiedPatientNameData)),
        verifyPatientDoB: verifiedPatientDoBData => dispatch(verifyPatientDoB(verifiedPatientDoBData)),
        saveNote: note => dispatch(saveNote(note)),
        deleteNote: note => dispatch(deleteNote(note)),
        saveDrawRequestDocument: document => dispatch(saveDrawRequestDocument(document)),
        deleteDrawRequestDocument: document => dispatch(deleteDrawRequestDocument(document)),
    };
};

const mapStateToProps = state => {
    return {
        onlineStatus: state.onlineStatus,
        drawData: state.drawData
    }
};

class PhotoCaptureClass extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        //const { cookies } = this.props;

        this.state = {
            ...this.state,
            siteId: "",
            patientId: "",
            dialogOpenedPatientRefused: false,
            dialogOpenedNotes: false,
            noteOperation: NEW,
            selectedNote: {
                id: "",
                message: "",
            },
            setDeleteNotesDialog: false,
            confirmDialogMessage: "",
            confirmDialogTitle: "",
            barcode: "",
            btnScanDisabled: false,
            barcodeValue: "",
            errorOnCamera: false,
            dataUri: "",
            openedAttachmentDialog: false,
            attachmentSource: "",
            attachmentType: "image",
            showCamera: true,
            offlineDialogOpened: false,
            addNotePopperOpen: false,
            documentScale: 1
        }

        this.selectedPatientDraw = undefined;
        this.drawSiteValue = "right_arm";
        this.modifiedDrawSite = false;

        this.myRef = React.createRef();
        this.addNoteAnchorRef = React.createRef();
    }

    addNewNote = () => {
        //console.log(this.myRef.current)
        // this.myRef.current.Connect.greet()
        // this.refs.content.getWrappedInstance().save();
        // console.log(this.refs)
        // console.log(this.myRef.current.getWrappedInstance().getWrappedInstance().greet())
        this.greet()
    }


    componentWillMount() {
        // console.log('PatientClass: componentWillMount.');        

        this.setState({
            ...this.state,
            patientId: this.props.patientId,
            siteId: this.props.siteId,
            showCamera: true,
            // readOnly: this.props.readOnly
        });
    }
    componentWillUnmount() {
    }

    componentDidMount() {
        //console.log('PatientClass: componentDidMount.');  
        this.noteRef = {}
        this.photoCaptureRef = {}
        this.barcodeCaptureRef = {}
        this.photoCaptureRef.readOnly = false
    }

    handleOpenDialog = event => {
        this.setState({ ...this.state, dialogOpenedPatientRefused: true });
    };

    setDeleteNotesDialogHandler = (actionHandler, postActionHandler, title, message, actionText, chainedFunction) => {
        this.setState(
            {
                ...this.state,
                actionHandler: actionHandler,
                postActionHandler: postActionHandler,
                confirmDialogMessage: message,
                confirmDialogTitle: title,
                confirmDialogActionText: actionText,
            },
            chainedFunction
        );
    }

    setDeleteNoteDialogVisible = (value) => {
        this.setState({ ...this.state, setDeleteNotesDialog: value });
    }

    handleChangeBarcode = (event) => {
        this.setState({ ...this.state, barcode: event.target.value })
    }

    updateDrawRequestDocument = (payload) => {
        this.props.saveDrawRequestDocument(payload)
    }

    deleteDrawRequestDocument = (payload) => {
        this.props.deleteDrawRequestDocument(payload)
    }

    setDeleteRequisitionDialogHandler = (actionHandler, postActionHandler, title, message, actionText, chainedFunction) => {
        this.setState(
            {
                ...this.state,
                actionHandler: actionHandler,
                postActionHandler: postActionHandler,
                confirmDialogMessage: message,
                confirmDialogTitle: title,
                confirmDialogActionText: actionText,
            },
            chainedFunction
        );
    }

    setDeleteNoteDialogVisible = (value) => {
        this.setState({ ...this.state, setDeleteNotesDialog: value });
    }

    //    removeDrawRequestDocument = (document) => {
    //     if(this.state.readOnly) {
    //         return    
    //     }
    //     const siteId = this.state.siteId
    //     const patientId =  this.state.patientId

    //     var payload = {
    //         siteId: siteId, 
    //         patientId: patientId, 
    //         target : "patient_draw",
    //         document : document,
    //         type: "photo" 
    //     }
    //     console.log(payload)

    //     this.setState({...this.state, barcode: ""},
    //             this.deleteDrawRequestDocument(payload)
    //     )

    // }

    // addPhoto under PhotoCapture2 is used
    addPhoto = () => {
        if (this.state.dataUri === "") return

        const siteId = this.state.siteId
        const patientId = this.state.patientId
        if (globals.DEBUG_ALLOWED) {
            console.log("siteId")
            console.log(siteId)
            console.log("patientId")
            console.log(patientId)
            console.log("this.state.dataUri")
            console.log(this.state.dataUri)
        }

        let imageData = this.state.dataUri
        var timeTaken = moment(new Date()).format("M-D-YY-h:mm:ss-A");

        var document =
        {
            "id": "",
            "filename": "Photo-" + timeTaken + ".jpg",
            "path": "Photo",
            "doc_type": "offline_upload",       // Potential values: "" | "metadata" | "offline_upload" | "offline_uploaded"
            "metadata": {
                "image": {
                    "category": "Patient Identification",             // Potential values: "Patient No Show" | "Patient Identification"
                    "data": imageData,
                    "filename": "Photo-" + timeTaken + ".jpg",   // Original filename
                    "resize_conf": {
                        "imageType": "IMAGE_TYPES.JPG",
                        "imageCompression": .5,
                        "isImageMirror": false,
                        "sizeFactor": 0.5
                    },
                }
            }
        }
        var payload = {
            siteId: siteId,
            patientId: patientId,
            type: "photo",
            target: "patient_draw",
            document: document
        }
        if (globals.DEBUG_ALLOWED) {
            console.log("payload")
            console.log(payload)
        }

        this.setState({ ...this.state, dataUri: "", showCamera: false }, () => {
            this.updateDrawRequestDocument(payload)
            this.photoCaptureDialogVisibility(false);
        }
        )

    }

    photoCaptureDialogVisibility = (value, noteOperation = NEW) => {
        var selectedNote = this.state.selectedNote;

        if (noteOperation === NEW) {
            selectedNote = {
                "id": "",
                "client_timezone": "",
                "author_type": "",
                "message": "",
                "client_deleted": "false",
                "client_timestamp": ""
            }

            this.setState({
                ...this.state, noteOperation: noteOperation,
                selectedNote: selectedNote, showCamera: value, dialogOpenedNotes: value, barcodeValue: "",
                dataUri: ""
            });

        }

        if (noteOperation === PREVIEW) {
            this.setState({
                ...this.state, noteOperation: noteOperation,
                selectedNote: selectedNote, dialogOpenedNotes: value, barcodeValue: ""
            });
        }
    };

    previewPhoto = (dataUri) => {
        console.log('preview photo/item');
        console.log(dataUri);
        this.setState({ ...this.state, dataUri: dataUri }, () => {
            this.photoCaptureDialogVisibility(true, PREVIEW)
        });

    }

    startScanning = () => {
        this.setState({ ...this.state, btnScanDisabled: true })
    }

    stopScanning = () => {
        this.setState({ ...this.state, btnScanDisabled: false })
    }

    /* Camera Handlers */

    handleTakePhoto = (dataUri) => {

        // Do stuff with the photo...
        setTimeout(() => {
            this.setState({ ...this.state, dataUri: dataUri })
        }, 500);

    }

    handleTakePhotoAnimationDone = (dataUri) => {
        // Do stuff with the photo...
        console.log('takePhoto');
    }

    handleCameraError = (error) => {
        console.log('handleCameraError', error);
        this.setState({ ...this.state, errorOnCamera: true })
    }

    handleCameraStart = (stream) => {
        console.log('handleCameraStart');
    }

    handleCameraStop = () => {
        console.log('handleCameraStop');
    }

    previewUsingIframe = (url) => {
        console.log("Preview this URL using iframe : " + url)
        if (this.props.onlineStatus === false) {
            this.setState({
                ...this.state,
                offlineDialogOpened: true
            });
        } else {
            this.setState({ ...this.state, attachmentType: "pdf", attachmentSource: url, openedAttachmentDialog: true });
        }
    }

    previewDataUriFromImageUrl = (url) => {
        if (globals.DEBUG_ALLOWED) {
            console.log("previewDataUriFromImageUrl")
            console.log(url)
        }
        if (this.props.onlineStatus === false) {
            this.setState({
                ...this.state,
                offlineDialogOpened: true
            });
        } else {
            this.getBase64FromImage(
                url,
                function (base64) {
                    this.setState({ ...this.state, attachmentType: "image", attachmentSource: base64, openedAttachmentDialog: true });
                }.bind(this),
                function (error) {
                    console.log(error);
                }
            );
        }
    }

    getBase64FromImage = (url, onSuccess, onError) => {
        var xhr = new XMLHttpRequest();

        xhr.responseType = "arraybuffer";
        xhr.open("GET", url);

        xhr.onload = function () {
            var base64, binary, bytes, mediaType;

            bytes = new Uint8Array(xhr.response);
            binary = [].map.call(bytes, function (byte) {
                return String.fromCharCode(byte);
            }).join('');
            mediaType = xhr.getResponseHeader('content-type');
            base64 = [
                'data:',
                mediaType ? mediaType + ';' : '',
                'base64,',
                btoa(binary)
            ].join('');
            onSuccess(base64);
        };
        xhr.onerror = onError;
        xhr.send();
    }

    closeAttachmentDialog = () => {
        // console.log("triggered: closeAttachmentDialog")
        this.setState({ ...this.state, openedAttachmentDialog: false });
    }

    handleCloseOfflineDialog = () => {
        this.setState({
            ...this.state,
            offlineDialogOpened: false
        });
    };

    handleToggleAddNotePopper = () => {
        this.setState({ ...this.state, addNotePopperOpen: !this.state.addNotePopperOpen });
    }

    handleCloseAddNotePopper = (event) => {
        if (this.addNoteAnchorRef.current && this.addNoteAnchorRef.current.contains(event.target)) {
            return;
        }
        this.setState({ ...this.state, addNotePopperOpen: false });
    };

    documentScaleUp = () => {
        var documentScale = this.state.documentScale;
        documentScale += .2;
        if (documentScale > 4) {
            documentScale = 4
        }
        this.setState({ ...this.state, documentScale: documentScale });
    }

    documentScaleReset = () => {
        this.setState({ ...this.state, documentScale: 1 });
    }

    documentScaleDown = () => {
        var documentScale = this.state.documentScale;
        documentScale -= .2;
        if (documentScale < 1) {
            documentScale = 1;
        }
        this.setState({ ...this.state, documentScale: documentScale });
    }

    render() {
        const { classes } = this.props;
        const { documentScale } = this.state;
        var requisitionIds = "";
        var consolidatedNotes = []
        const isDrawDone = this.props.drawStatus && this.props.drawStatus === "done" ? true : false;
        const creds = helper.getCredentials();
        // console.log("isDrawDone: " + isDrawDone);

        if (this.props.draw_request_documents) {
            requisitionIds = this.props.draw_request_documents.map((doc) => {
                // console.log("Documents")
                // console.log(doc)
                // Photo with remove icon //
                if (doc.doc_type !== undefined && doc.metadata.image !== undefined
                    && doc.client_deleted !== undefined && doc.client_deleted !== true
                    && doc.doc_type === "offline_upload") {
                    consolidatedNotes.push({
                        type: "photo_upload",
                        item: doc
                    })
                    return ("");
                    // Photo WITHOUT remove icon //
                } else if (doc.doc_type !== undefined && doc.filename !== undefined
                    && doc.client_deleted !== undefined && doc.client_deleted !== true &&
                    doc.doc_type === "offline_uploaded" && doc.path !== undefined
                ) {
                    consolidatedNotes.push({
                        type: "photo_uploaded",
                        item: doc
                    })
                    return ("")
                } else if (doc.doc_type !== undefined && doc.doc_type === "metadata"
                    && doc.client_deleted !== undefined && doc.client_deleted !== true) {
                    consolidatedNotes.push({
                        type: "barcode",
                        item: doc
                    })
                    return ("")
                } else if (doc.path !== undefined && doc.path !== "") {
                    var ext = doc.path.split('.').pop();
                    var type = ["pdf", "jpg"].includes(ext) ? ext : "jpg";

                    consolidatedNotes.push({
                        type: type === "jpg" ? "photo_uploaded" : "pdf",
                        item: doc
                    })
                    return ("")
                } else {
                    return null // Add to eliminate warning 
                }
            });
        }

        if (this.props.notes) {
            this.props.notes.map(note => {
                if (!(note.client_deleted !== undefined && note.client_deleted === true)) {
                    consolidatedNotes.push({
                        type: "note",
                        item: note
                    })
                }
                return "";
            })
        }
        // console.log("CONSOLIDATED NOTES, PHOTOS, BARCODES and PDF")
        // console.log(consolidatedNotes)

        if (consolidatedNotes) {
            requisitionIds = consolidatedNotes.map((doc) => {
                // Photo with remove icon //
                var photoUrl = "";
                if (doc.type === "photo_upload") {
                    return (
                        <ListItem
                            key={Math.random()}
                            disableGutters={true}
                            className={classes.requisitionList}
                            aria-label="Requisitions"
                            onClick={() => { this.previewPhoto(doc.item.metadata.image.data) }}
                        >
                            <Icon>image</Icon>
                            <EllipsisText text={doc.item.filename.split("\n")[0]} length={27} />
                            {(!this.props.patientReadOnly && !isDrawDone) &&
                                <ListItemSecondaryAction>
                                    <Icon
                                        onClick={() => {
                                            this.photoCaptureRef.deletePhoto(doc.item)

                                        }}
                                        disabled={this.props.patientReadOnly}
                                        classes={{ root: classes.clearButton }}
                                    >clear</Icon>
                                </ListItemSecondaryAction>
                            }
                        </ListItem>
                    );
                    // Photo WITHOUT remove icon //
                } else if (doc.type === "photo_uploaded") {
                    photoUrl = (globals.USE_PAMP_COUCHDB_SERVER) ? doc.item.path : globals.SERVER + doc.item.path;
                    return (
                        <ListItem
                            key={Math.random()}
                            disableGutters={true}
                            className={classes.requisitionList}
                            aria-label="Requisitions"
                            onClick={() => { this.previewDataUriFromImageUrl(photoUrl) }}
                        >
                            <Icon>image</Icon>
                            <EllipsisText text={doc.item.filename.split("\n")[0]} length={27} />
                        </ListItem>
                    )
                } else if (doc.type === "barcode") {
                    photoUrl = (globals.USE_PAMP_COUCHDB_SERVER) ? doc.item.path : globals.SERVER + doc.item.path;
                    return (
                        <ListItem
                            key={Math.random()}
                            disableGutters={true}
                            className={classes.requisitionList}
                            aria-label="Requisitions"
                        // onClick={()=>{this.previewPhoto(doc.item.metadata.image.data) }}
                        >
                            <BarcodeSVG />
                            <EllipsisText text={doc.item.metadata.requisition_code.split("\n")[0]} length={27} />
                            {(!this.props.patientReadOnly && !isDrawDone) &&
                                <ListItemSecondaryAction>
                                    <Icon
                                        onClick={() => {
                                            this.barcodeCaptureRef.deleteBarcode(doc.item)

                                        }}

                                        classes={{ root: classes.clearButton }}
                                    >clear</Icon>
                                </ListItemSecondaryAction>
                            }
                        </ListItem>
                    )
                } else if (doc.type === "note") {
                    // console.log("Note: ")                          
                    // console.log("Phleb ID: " + creds.phleb_id)                          
                    // console.log(doc)                          
                    photoUrl = (globals.USE_PAMP_COUCHDB_SERVER) ? doc.item.path : globals.SERVER + doc.item.path;
                    return (
                        <ListItem
                            key={Math.random()}
                            disableGutters={true}
                            className={classes.requisitionList}
                            aria-label="Requisitions"
                            onClick={() => { this.noteRef.openNote(doc.item.id) }}
                        >
                            {/* { doc.item.metadata.requisition_code}  */}
                            <Icon>description</Icon>
                            <EllipsisText text={doc.item.message.split("\n")[0]} length={27} />

                            {(!this.props.patientReadOnly && !isDrawDone && creds.phleb_id === doc.item.author) &&
                                <ListItemSecondaryAction>
                                    <Icon
                                        onClick={() => {
                                            this.noteRef.removeNote(doc.item)
                                        }}
                                        disabled={this.props.patientReadOnly}
                                        classes={{ root: classes.clearButton }}
                                    >clear</Icon>
                                </ListItemSecondaryAction>
                            }
                        </ListItem>
                    )
                } else if (doc.type === "pdf") {
                    var pdfUrl = (globals.USE_PAMP_COUCHDB_SERVER) ? doc.item.path : globals.SERVER + doc.item.path;
                    return (
                        <ListItem
                            key={Math.random()}
                            disableGutters={true}
                            className={classes.requisitionList}
                            aria-label="Requisitions"
                            onClick={() => { this.previewUsingIframe(pdfUrl) }}
                        >
                            <Icon>picture_as_pdf</Icon>
                            <EllipsisText text={doc.item.filename.split("\n")[0]} length={27} />
                            {(!this.props.patientReadOnly && !isDrawDone && creds.phleb_id === doc.item.author) &&
                                <ListItemSecondaryAction>
                                    <Icon
                                        onClick={() => {
                                            this.photoCaptureRef.deletePhoto(doc.item)
                                        }}
                                        disabled={this.props.patientReadOnly}
                                        classes={{ root: classes.clearButton }}
                                    >clear</Icon>
                                </ListItemSecondaryAction>
                            }
                        </ListItem>
                    );
                } else if (doc.item.path !== undefined && doc.item.path !== "") {
                    var photoUrl2 = (globals.USE_PAMP_COUCHDB_SERVER) ? doc.item.path : globals.SERVER + doc.item.path;
                    return (
                        <ListItem
                            key={Math.random()}
                            disableGutters={true}
                            className={classes.requisitionList}
                            aria-label="Requisitions"
                            onClick={() => { this.previewDataUriFromImageUrl(photoUrl2) }}
                        >
                            <Icon>image</Icon>
                            <EllipsisText text={doc.item.filename.split("\n")[0]} length={27} />
                        </ListItem>
                    )
                } else {
                    return null // Add to eliminate warning 
                }
            });

            requisitionIds = requisitionIds.filter(function (el) {
                return el != null;
            });

            // if(requisitionIds.length === 0) {
            //     requisitionIds = <ListItem 
            //         key={Math.random()}
            //         disableGutters={true}
            //         className={classNames(classes.requisitionList, classes.noPhoto)} 
            //         aria-label="Photos"
            //         >
            //         No Photos                
            //     </ListItem>;
            // } 
        }
        // console.log("requisitionIds")
        // console.log(requisitionIds)
        var pdfURL = this.state.attachmentSource.toString()
        // console.log("pdfURL")
        // console.log(pdfURL)
        return (
            <div>
                <div className={classes.addButtonContainer}>
                    <RootRef rootRef={this.addNoteAnchorRef}>
                        <Button
                            // ref={this.addNoteAnchorRef}
                            // innerRef={node => this.addNoteAnchorRef = node}
                            aria-controls={this.state.addNotePopperOpen ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={() => { this.handleToggleAddNotePopper() }}
                            className={classNames(!this.state.addNotePopperOpen ? classes.mainButtonWhiteBorderedSM : classes.mainButtonGrayBorderedSM, classes.addNoteButton)}>
                            Capture
                            <Divider orientation="vertical" className={!this.state.addNotePopperOpen ? classes.addButtonDivider : classes.addButtonDividerActive} />
                            <Icon className={classes.addButtonArrowDown}>expand_more</Icon>
                        </Button>
                    </RootRef>
                </div>
                <Popper open={this.state.addNotePopperOpen} anchorEl={this.addNoteAnchorRef.current} role={undefined} transition>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper className={classes.popperPaper}>
                                <ClickAwayListener onClickAway={this.handleCloseAddNotePopper}>
                                    <MenuList className={classes.popperMenu} id="menu-list-grow">
                                        <MenuItem onClick={() => { this.barcodeCaptureRef.addNewBarcode() }}><BarcodeSVG /> Barcode</MenuItem>
                                        <MenuItem onClick={() => this.photoCaptureRef.addNewPhoto({})}><Icon>image</Icon> Photo</MenuItem>
                                        <MenuItem onClick={() => this.noteRef.addNewNote()}><Icon>description</Icon> Text</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>

                <PhotoCapture2
                    siteId={this.props.siteId}
                    patientId={this.props.patientId}
                    patientDrawId={this.props.patientDrawId}
                    notes={null}
                    //notes={patient[0].patient.notes} 
                    target="patient"
                    draw_request_documents={this.props.draw_request_documents}
                    addNewPhoto={m => this.photoCaptureRef.addNewPhoto = m}
                    deletePhoto={m => this.photoCaptureRef.deletePhoto = m}
                    readOnly={this.props.patientReadOnly}
                />

                <Notes2
                    ref="myref"
                    addNew="button"
                    siteId={this.props.siteId} patientId={this.props.patientId}
                    patientDrawId={this.props.patientDrawId}
                    notes={this.props.notes}
                    target="patient"
                    addNewNote={m => this.noteRef.addNewNote = m}
                    openNote={m => this.noteRef.openNote = m}
                    removeNote={m => this.noteRef.removeNote = m}
                    readOnly={this.props.patientReadOnly || isDrawDone}
                //greet={m=>this.greet = m}
                />

                <BarcodeReader2
                    siteId={this.props.siteId}
                    patientId={this.props.patientId}
                    patientDrawId={this.props.patientDrawId}
                    notes={null}
                    target="patient"
                    draw_request_documents={this.props.draw_request_documents}
                    title={this.props.barcodeTitle}
                    commandText={this.props.barocdeCommandText}
                    inputGuideText={this.props.barcodeInputGuideText}
                    readOnly={this.props.patientReadOnly}
                    deleteBarcode={m => this.barcodeCaptureRef.deleteBarcode = m}
                    addNewBarcode={m => this.barcodeCaptureRef.addNewBarcode = m}
                />

                <List className={classes.listContainer}>
                    {requisitionIds}
                </List>

                {/* for deletion, class should be loader only */}
                <Dialog
                    id="notes-dialog"
                    fullScreen={true}
                    classes={{
                        paper: classes.dialogPaperForNotes
                    }}
                    open={this.state.dialogOpenedNotes}
                    // onClose={this.handleCloseDialogPatientRefused}
                    onEnter={this.handleOnShowDialogPatientRefused}
                    aria-labelledby="notes-title">

                    {/* <DialogTitle id="notes-title" onClose={() => this.photoCaptureDialogVisibility(false)}>Photo Capture</DialogTitle> */}
                    <DialogContent className={classes.photoCaptureDialogContent}>
                        <div id="photo-capture-webcam-container">
                            {this.state.errorOnCamera ? (
                                <div style={{ "margin": "10%", "text-align": "center" }}>
                                    <Typography variant="h5" align="center">Failed to load Camera</Typography><br />
                                    <Typography>Please check the app if it has permission to use the camera.</Typography>
                                    <Typography>Or, if you are using an iOS device, please use Safari browser.</Typography>
                                </div>)
                                : (
                                    this.state.dataUri === "" ?
                                        this.state.showCamera &&
                                        <CustomWebcam2
                                            onTakePhoto={(dataUri) => { this.handleTakePhoto(dataUri); }}
                                            width={window.innerWidth}
                                            height={window.innerHeight - (36 + 20)} // 36 h and 20 pad for ok cancel button cont height

                                        />
                                        :
                                        <ImagePreview
                                            dataUri={this.state.dataUri}
                                            // isFullscreen={true}
                                            width={window.innerWidth}
                                            height={window.innerHeight - (36 + 20)} // 36 h and 20 pad for ok cancel button cont height
                                        />
                                )}
                        </div>
                    </DialogContent>
                    <DialogActions className={classes.photoCaptureDialogActions}>
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={this.state.dataUri === "" || this.state.noteOperation === PREVIEW}
                            className={classes.buttons}
                            onClick={this.addPhoto}
                        >Accept</Button>
                        {this.state.dataUri !== "" &&
                            <Button
                                variant="contained"
                                disabled={this.state.noteOperation === PREVIEW}
                                className={classes.buttons}
                                onClick={() => this.setState({ ...this.state, dataUri: "" })}
                                color="default">Retake</Button>
                        }
                        <Button
                            variant="contained"
                            className={classes.buttons}
                            onClick={() => this.photoCaptureDialogVisibility(false)}
                            color="default">Cancel</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    id="attachment-dialog"
                    fullScreen={true}
                    open={this.state.openedAttachmentDialog}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogContent className={classes.pdfDialogContainer}>
                        {/* <div className={classes.btnClosePDFContainer}> */}
                        {/* <Button onClick={()=>{
                                    console.log("Refreshing...")
                                   // this.iframeRef.reload()
                                   this.closeAttachmentDialog()
                                   setTimeout(()=>{
                                    this.setState({...this.state, openedAttachmentDialog:true})

                                   },300)
                                    

                                    }} >Refresh</Button> */}
                        {/* </div> */}
                        {this.state.attachmentType === "pdf"
                            ?
                            // <MobilePDFReader url={"https://www.adobe.com/support/products/enterprise/knowledgecenter/media/c4611_sample_explain.pdf"} />
                            // <div style={{overflow:'scroll',height:600}}>
                            // <PDFReader url={this.state.attachmentSource}/>
                            // helper.getMobileOperatingSystem() == "iOS" ?           
                            //     <iframe 
                            //         id="theFrame"
                            //         ref={this.iframeRef} 
                            //         //src={this.state.attachmentSource}
                            //         src={'https://docs.google.com/gview?url='+this.state.attachmentSource+'&embedded=true'}
                            //         width="100%"
                            //         height="100%"
                            //         id="attachment-iframe"
                            //         className={classes.imageViewer}
                            //         frameBorder="0"
                            //         //display="initial"
                            //         position="absolute"
                            //         onLoad={()=>{
                            //             console.log('Iframe loaded')
                            //         }}
                            //         loading="Loading attachment..." 
                            //         />
                            //     :
                            //         <iframe 
                            //         id="theFrame"
                            //         ref={this.iframeRef} 
                            //         src={this.state.attachmentSource}
                            //         //src={'https://docs.google.com/gview?url='+this.state.attachmentSource+'&embedded=true'}
                            //         width="100%"
                            //         height="100%"
                            //         id="attachment-iframe"
                            //         className={classes.imageViewer}
                            //         frameBorder="0"
                            //         //display="initial"
                            //         position="absolute"
                            //         onLoad={()=>{
                            //             console.log('Iframe loaded')
                            //         }}
                            //         loading="Loading attachment..." 
                            //         />
                            <>
                                PDF viewer currently not supported.
                                {/* <PDFViewer
                                    document={{
                                        url: pdfURL,
                                    }}
                                    scale={documentScale}
                                    loader={<Typography className={classes.mgrPdfViewerLoaderEl}>Loading Document...</Typography>}
                                    css={classes.mgrPdfViewer}
                                    navigation={{
                                        css: {
                                            wrapper: classes.mgrPdfViewerNavigation,
                                            previousPageBtn: classes.mgrPdfViewerNavigationPreviousBtn,
                                            nextPageBtn: classes.mgrPdfViewerNavigationNextPageBtn,
                                            pages: classes.mgrPdfViewerNavigationPages
                                        },
                                    }}
                                />

                                <div className={classes.zoomContainer}>
                                    <Icon className={classNames(classes.zoomIcons)} onClick={this.documentScaleDown}>zoom_out</Icon>
                                    <Icon className={classNames(classes.zoomIcons)} onClick={this.documentScaleReset}>youtube_searched_for</Icon>
                                    <Icon className={classNames(classes.zoomIcons)} onClick={this.documentScaleUp}>zoom_in</Icon>
                                </div> */}
                            </>
                            // <iframe 
                            //     id="theFrame"
                            //     ref={this.iframeRef} 
                            //     //src={this.state.attachmentSource}
                            //     //src={'https://docs.google.com/gview?url='+this.state.attachmentSource+'&embedded=true'}
                            //     src={'https://docs.google.com/viewer?embedded=true&url='+this.state.attachmentSource}
                            //     width="100%"
                            //     height="100%"
                            //     id="attachment-iframe"
                            //     className={classes.imageViewer}
                            //     frameBorder="0"
                            //     //display="initial"
                            //     position="absolute"
                            //     onLoad={()=>{
                            //         console.log('Iframe loaded')
                            //     }}
                            //     loading="Loading attachment..." 
                            //     />

                            :
                            <img src={this.state.attachmentSource} alt="pdf" className={classes.imageViewer} />
                        }
                        <Button onClick={() => this.closeAttachmentDialog()} className={classNames(classes.btnClosePDF)} variant="contained" color="default"><Icon>close</Icon></Button>
                    </DialogContent>
                </Dialog>

                <ConfirmDialog
                    visible={this.state.setDeleteNotesDialog}
                    title={this.state.confirmDialogTitle}
                    message={this.state.confirmDialogMessage}
                    actionText={this.state.confirmDialogActionText}
                    actionHandler={this.state.actionHandler}
                    postActionHandler={this.state.postActionHandler}
                    onClose={() => {
                        //console.log("ConfirmDialog On close")
                        setTimeout(() => {
                            this.setDeleteNoteDialogVisible(false);
                        }, 100);

                    }}
                />

                <Dialog
                    fullScreen={false}
                    classes={{
                        paper: classes.dialogPaper
                    }}
                    open={this.state.offlineDialogOpened}
                    onClose={this.handleCloseOfflineDialog}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">Cannot load Attachment</DialogTitle>
                    <DialogContent>
                        <br />
                        <Typography>Must be online to view attachments.</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseOfflineDialog} className={classes.buttons} variant="contained">Close</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );

    }
}

PhotoCaptureClass.propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    theme: PropTypes.object.isRequired,
};

const PhotoCapture = withCookies(connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles, { withTheme: true })(PhotoCaptureClass))));

export default PhotoCapture;
