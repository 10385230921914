const patientStyles = theme => ({
    // general
    root: {
    }, 

    //width of textfields
    fullWidth: {
        width: "100%",
    },
    
    mainContainer: {
        padding: "15px 20px",
        marginBottom: "70px"
    },
    patientDetailsContainer: {
        margin: "10px",
        padding: "10px"
    },

    cancelButton: {
        textTransform: 'capitalize',
        padding: "10px 20px",
        fontSize: "14px !important",
        backgroundColor: "#f7f7f7",
        color: "#333333 !important",
        fontWeight: 500,
        "&:focus": { backgroundColor: "#f7f7f7 !important" },
        "&:active": { backgroundColor: "#f7f7f7 !important" },
        "&:hover": { backgroundColor: "#f7f7f7 !important" },
        "&:disabled": { backgroundColor: "#f7f7f7 !important" },
    },
   
    addDataOptionsDialogContainer: {
        backgroundColor: "transparent",
        boxShadow: "none",
        position: "absolute",
        bottom: "80px",
        margin: "0px",
        width: "252px"
    },

    btnCaptureDataLabel: {
        color: "#00BDFF !important",
    },

    btnCaptureData: {
        fontSize: "14px",
        textTransform: "none",
        color: "black !important",
        backgroundColor: "white !important",
        border: "1px solid #E6E6E6",
        padding: "8px 20px",
        borderRadius: "4px",
        marginBottom: "10px",
        width: "252px",
        "&:active": {
            backgroundColor: "white !important",
        }
    },

    btnCloseAddData: {
        fontSize: "14px",
        textTransform: "none",
        color: "black !important",
        backgroundColor: "#F7F7F7 !important",
        border: "1px solid #F7F7F7",
        padding: "8px 20px",
        borderRadius: "4px",
        marginBottom: "10px",
        width: "252px",
        boxShadow: "2px 2px #f0f0f0",
        "&:active": {
            backgroundColor: "#F7F7F7 !important",
        }
    },

    addDataOptionsDialogContent: {
        padding: "0px !important",
        textAlign: "center",
    },

    btnAddData: {
        border: "1px solid #E6E6E6",
        borderRadius: "4px",
    },

})

export default patientStyles