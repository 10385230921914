import { BorderBottom } from '@material-ui/icons'
import * as globals from 'components/common/GlobalConstants'

const patientStyles = theme => ({
    // general
    root: {
    },

    content: {
        flexGrow: 1,
    },  

    //width of textfields
    fullWidth: {
        width: "100%",
    },

    TitleContainer:{
        borderBottom: "2px solid #efefef"
    },

    //for H# labels
    labels: {
        fontSize: "15px !important",
        fontWeight: 500
    },

    mainContainer: {
        padding: "15px 20px",
        marginBottom: "70px"
    },

    // appointment details container
    patientDetailsContainer: {
        margin: "20px 10px 0px 10px",
        padding: "5px 5px 0px 5px",
    },

    footerContainer: {
        position: "fixed",
        bottom: "0px",
        left: "0px",
        backgroundColor: "white",
        padding: "15px 20px",
        borderTop: "2px solid #efefef"
    },

    cancelButton: {
        textTransform: 'capitalize',
        padding: "10px 20px",
        fontSize: "14px !important",
        backgroundColor: "#f7f7f7",
        color: "#333333 !important",
        fontWeight: 500,
        "&:focus": { backgroundColor: "#f7f7f7 !important" },
        "&:active": { backgroundColor: "#f7f7f7 !important" },
        "&:hover": { backgroundColor: "#f7f7f7 !important" },
        "&:disabled": { backgroundColor: "#f7f7f7 !important" },
    },

    acceptButton: {
        textTransform: 'capitalize',
        padding: "10px 20px",
        fontSize: "14px !important",
        backgroundColor: globals.v2.COLORS.YELLOW + " !important",
        color: "#333333 !important",
        fontWeight: 500,
        "&:focus": { backgroundColor: "#ffdc51 !important" },
        "&:active": { backgroundColor: "#ffdc51 !important" },
        "&:hover": { backgroundColor: "#ffdc51 !important" },
        "&:disabled": { backgroundColor: "#e6e6e6 !important" },
    },

    cancelDialogBox: {
        minWidth: 300,
        margin: "20px",
        borderRadius: "10px"
    },

    closeApptDetailsButton: {
        paddingLeft: "20px !important",
    },
})

export default patientStyles