import React from 'react' // , { useState }
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import styles from "./styles.js"


import { ReactComponent as TeloPointLogoSVG } from 'components/common/mobile/CustomOfflineSVG/TeloPoint.svg'

const ChangePasswordDetails = (props) => {
    const { classes } = props
     return (
        <div>
            <div >
                <br />
                <Typography className={classes.aboutLabel}>
                To change password please click 'Continue' button below, 
                then if succeeded, a link to change your password will be sent to your 
                Email associated with your Clinician app account. 
                </Typography>
              </div>
                    
        </div>
    )
}

export default
    withMobileDialog()(
        withStyles(styles)
            (ChangePasswordDetails)
        
    )
