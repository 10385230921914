// import * as globals from 'components/common/GlobalConstants'

const styles = theme => ({
    // general
    root: {
    },
    content: {
        flexGrow: 1,
    },
    mainContainer: {
        padding: "15px 20px",
        marginBottom: "100px"
    },
    // appointment details header
    apptOrderHeaderContainer: {
        minHeight: "60px",
        borderBottom: "1px solid #ececec",
        marginLeft: "-20px",
        marginRight: "-20px"
    },
    closeApptOrderButton: {
        paddingLeft: "20px !important",
    },
    currentStepLabel: {
        padding: "0px 20px 0px 5px",
        fontSize: "14px !important"
    },
    percentageBar: {
        height: "5px",
        marginLeft: "-20px",
        marginRight: "-20px",
        backgroundColor: "#f7f7f7",
        borderTop: "1px solid #E6E6E6",
    },
    percentageBarColor: {
        backgroundColor: "#69CEF1"
    },
    // total orders component
    totalOrdersContainer: {
        backgroundColor: "#ecfff8",
        margin: "15px 0px",
        padding: "5px 0px 5px 20px",
        borderRadius: "5px",
    },
    orderPercentage: {
        fontSize: "22px !important",
        color: "#00f4a4 !important",
        backgroundColor: "white",
        fontWeight: 600,
        borderTopLeftRadius: "15px",
        borderBottomLeftRadius: "15px",
        padding: "5px 12px 5px 30px",
    },
    totalOrdersLabel: {
        fontSize: "16px !important"
    },
    // appointment details container
    apptDetailsContainer: {
        borderRadius: "5px",
        border: "1px solid #fbfbfb",
        boxShadow: "2px 2px #f0f0f0",
        marginBottom: "10px",
        backgroundColor: "#f8f8f8",
        padding: "15px 20px",
        paddingRight: "10px",
    },
    apptDateTime: {
        fontSize: "14px !important",
        fontWeight: "bold",
        display: "inline-block",
        marginTop: "5px"
    },
    orderDetailsLabel: {
        fontSize: "14px !important",
        fontWeight: 500
    },
    orderAddress: {
        fontSize: "13px !important",
    },
    apptDetailsButton: {
        display: "block",
        width: "100%",
        backgroundColor: "white",
        borderRadius: "5px",
        fontSize: "16px",
        padding: "8px 16px",
        boxShadow: "2px 2px #f0f0f0",
        "&:focus": {
            backgroundColor: "white",
        }
    },
    apptDetailsButtonIcon: {
        width: 24,
        height: 24,
        marginRight: "10px",
        color: "#69CEF1"
    },
    // orders, sort/filter section
    filterContainer: {
        display: "flex",
        marginTop: "30px",
        marginBottom: "20px",
    },
    filterField: {
        height: "30px",
        flexGrow: 1,
        backgroundColor: "white",
        border: "2px solid #f3f3f3",
        borderRadius: "50px",
        padding: "6px 20px 5px",
        marginRight: "5px"
    },
    sortButton: {
        height: "45px",
        minWidth: "82px",
        backgroundColor: "white",
        border: "2px solid #f3f3f3",
        borderRadius: "50px",
        padding: "5px 15px"
    },
    // orders list
    ordersContainer: {
    },
    orderListItem: {
        padding: "20px",
        borderRadius: "5px",
        border: "1px solid #fbfbfb",
        boxShadow: "2px 2px #f0f0f0",
        marginBottom: "10px",
    },
    orderName: {
        fontSize: "15px !important",
        fontWeight: "bold"
    },
    orderTest: {
        fontSize: "13px !important"
    },
    orderStartButton: {
        height: "35px",
        padding: "5px 20px",
        borderRadius: "50px",
        fontSize: "13px !important",
        backgroundColor: "#ffdc51",
        color: "black",
        fontWeight: 500,
        "&:focus": {
            backgroundColor: "#ffdc51",
        }
    },
    // steps description container
    descriptionStepContainer: {
        marginTop: "15px",
        marginBottom: "-18px"
    },
    descriptionStepLabel: {
        margin: "auto",
        width: "40px",
        height: "40px",
        backgroundColor: "#69CEF1",
        color: "white !important",
        fontWeight: 600,
        fontSize: "18px"
    },
    descriptionLabelContainer: {
        backgroundColor: "#69CEF11A",
        borderRadius: "5px",
        textAlign: "center",
        padding: "29px",
        marginBottom: "20px"
    },
    descriptionLabel: {
        fontWeight: "500",
        color: "black !important",
        fontSize: "16px !important"
    },
    // stepper container
    stepperContainer: {
        position: "fixed",
        bottom: "0px",
        left: "0px",
        backgroundColor: "white",
        padding: "15px 20px",
        borderTop: "1px solid #efefef"
    },
    stepperBackButton: {
        textTransform: 'capitalize',
        padding: "10px 20px",
        fontSize: "14px !important",
        backgroundColor: "#f7f7f7",
        color: "black",
        fontWeight: 500,
        "&:focus": { backgroundColor: "#f7f7f7 !important" },
        "&:active": { backgroundColor: "#f7f7f7 !important" },
        "&:hover": { backgroundColor: "#f7f7f7 !important" },
        "&:disabled": { backgroundColor: "#f7f7f7 !important" },
    },
    stepperNextButton: {
        textTransform: 'capitalize',
        padding: "10px 20px",
        fontSize: "14px !important",
        backgroundColor: "#ffdc51",
        color: "black",
        fontWeight: 500,
        "&:focus": { backgroundColor: "#ffdc51 !important" },
        "&:active": { backgroundColor: "#ffdc51 !important" },
        "&:hover": { backgroundColor: "#ffdc51 !important" },
        "&:disabled": { backgroundColor: "#e6e6e6 !important" },
    }
})

export default styles