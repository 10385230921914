import React, { useState, useLayoutEffect } from 'react'

import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"
import classNames from 'classnames'

import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import {
    Typography,
    FormControl,
    // Button
} from '@material-ui/core'

import moment from 'moment'
import * as helper from 'components/common/Helper.js'
// import * as globals from 'components/common/GlobalConstants'
import uuid from 'uuid'

const mapDispatchToProps = dispatch => { return {} }

const mapStateToProps = state => {
    return {
        scheduleData: state.scheduleData,
    }
}

const FieldsFunctionClass = (props) => {
    const {
        classes,
        scheduleData,
        workScheduleData
    } = props

    const [selectedDate, setSelectedDate] = useState(moment().format("dddd, MMM DD, YYYY"))
    const [timeFrom, setTimeFrom] = useState("08:00 AM")
    const [timeTo, setTimeTo] = useState("08:30 AM")
    const [repeatOptions, setRepeatOptions] = useState({"every_day": "Specific dates"})
    const [repeatType, setRepeatType] = useState(null)
    const [weekNumber, setWeekNumber] = useState(2)
    const [selectedEndDate, setSelectedEndDate] = useState(moment().format("dddd, MMM DD, YYYY"))
    const [days, setDays] = useState([])

    // console.log("repeatOptions")
    // console.log(repeatOptions)

    // const [selectWeekDialogOpen, setSelectWeekDialogOpen] = useState(false)
    // const [weekNumber, setWeekNumber] = useState(2)

    // const [repeatType, setRepeatType] = useState("")
    // const [specificDates, setSpecificDates] = useState([moment(new Date()).format("YYYY-MM-DD")])

    // const [selectedDays, setSelectedDays] = useState([workScheduleDate.format("dddd").toLowerCase()])

    // const [selectEndDateDialogOpen, setSelectEndDateDialogOpen] = useState(false)

    console.log(workScheduleData)

    useLayoutEffect(() => {
        if(workScheduleData) {
            setSelectedDate(moment(workScheduleData.date_from, "YYYY-MM-DD").format("dddd, MMM DD, YYYY"))
    
            setTimeFrom(moment(workScheduleData.time_from, "HH:mm:ss").format("hh:mm A"))
            setTimeTo(moment(workScheduleData.time_to, "HH:mm:ss").format("hh:mm A"))
    
            const repeatOptions = scheduleData.settings ? scheduleData.settings.repeat_options : []
            setRepeatOptions(repeatOptions)
            setRepeatType(workScheduleData.repeat_type)
    
            setWeekNumber(workScheduleData.repeat_interval)
            setSelectedEndDate(moment(workScheduleData.date_to, "YYYY-MM-DD").format("dddd, MMM DD, YYYY"))
            setDays(workScheduleData.days)
        }
    }, [workScheduleData, scheduleData])

    // console.log("repeatType")
    // console.log(repeatType)

    return (
        <>
            <div className={classes.scheduleFieldsContainer}>
                <Typography className={classes.labels}>
                    Date
                </Typography>
                <FormControl
                    variant="outlined"
                    className={classNames(classes.formControl, classes.fullWidth, classes.formControlDisabled)}
                >
                    <Select
                        id="date-select"
                        className={classes.selectField}
                        classes={{
                            selectMenu: classes.selectMenu
                        }}
                        disableUnderline
                        value={selectedDate}
                        disabled
                    >
                        <MenuItem value={selectedDate}>
                            <Typography className={classes.selectFieldFont}>{selectedDate}</Typography>
                        </MenuItem>
                    </Select>
                </FormControl>
                <Typography className={classes.labels}>
                    Time
                </Typography>

                <Grid
                    container
                    alignItems="center"
                    justify="center"
                >
                    <Grid item xs style={{ paddingRight: 5 }}>
                        <FormControl
                            variant="outlined"
                            className={classNames(classes.formControl, classes.fullWidth, classes.formControlDisabled)}
                        >
                            <Select
                                id="time-from-select"
                                displayEmpty
                                className={classes.selectField}
                                classes={{
                                    selectMenu: classes.selectMenu
                                }}
                                disableUnderline
                                value={timeFrom}
                                disabled
                            >
                                <MenuItem value={timeFrom}>
                                    <Typography className={classNames(classes.selectFieldFont, classes.placeholder)}>{timeFrom}</Typography>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs style={{ paddingLeft: 5 }}>
                        <FormControl
                            variant="outlined"
                            className={classNames(classes.formControl, classes.fullWidth, classes.formControlDisabled)}
                        >
                            <Select
                                id="time-to-select"
                                displayEmpty
                                className={classes.selectField}
                                classes={{
                                    selectMenu: classes.selectMenu
                                }}
                                disableUnderline
                                value={timeTo}
                                disabled
                            >
                                <MenuItem value={timeTo}>
                                    <Typography className={classNames(classes.selectFieldFont, classes.placeholder)}>{timeTo}</Typography>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Typography className={classes.labels}>
                    Repeat Schedule
                </Typography>

                <FormControl
                    variant="outlined"
                    className={classNames(classes.formControl, classes.fullWidth, classes.formControlDisabled)}
                    style={{ marginBottom: 0 }}
                >
                    <Select
                        id="repeat-schedule-select"
                        className={classes.selectField}
                        classes={{
                            selectMenu: classes.selectMenu
                        }}
                        disableUnderline
                        value={repeatType ? repeatOptions[repeatType] : "does_not_repeat"}
                        disabled
                    >
                        <MenuItem value={repeatType ? repeatOptions[repeatType] : "does_not_repeat"}>
                            <Typography className={classNames(classes.selectFieldFont, classes.placeholder)}>{repeatType ? repeatOptions[repeatType] : "Does not repeat"}</Typography>
                        </MenuItem>
                    </Select>
                </FormControl>
                {repeatType === "every_n_week" &&
                    <>
                        <Grid
                            container
                            alignItems="center"
                        >
                            <FormControl
                                variant="outlined"
                                className={classNames(classes.formControl, classes.weekSelectFormControl, classes.formControlDisabled)}
                            >
                                <Select
                                    id="repeat-every-n-weeks-select"
                                    displayEmpty
                                    className={classes.selectField}
                                    classes={{
                                        selectMenu: classes.selectMenu
                                    }}
                                    disableUnderline
                                    value={weekNumber}
                                    disabled
                                >
                                    <MenuItem value={weekNumber}>
                                        <Typography className={classNames(classes.selectFieldFont)}>{weekNumber}</Typography>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <Typography className={classes.weekNumberLabel}>{weekNumber === 1 ? "Week" : "Weeks"}</Typography>
                        </Grid>

                        <Typography
                            className={classes.labels}
                            style={{ marginBottom: 8 }}
                        >
                            Days of the Week
                        </Typography>

                        {days.map((day) => {
                            return <Typography
                                key={uuid.v4()}
                                color="primary"
                                className={classes.daysOfWeekLabel}
                            >{helper.capitalize(day)}</Typography>
                        })}
                    </>
                }

                {(repeatType === "every_day" || repeatType === "every_n_week") &&
                    <>
                        <Typography
                            className={classes.labels}
                            style={{ marginTop: 19 }}
                        >
                            Ends on
                        </Typography>

                        <FormControl
                            variant="outlined"
                            className={classNames(classes.formControl, classes.fullWidth, classes.formControlDisabled)}
                        >
                            <Select
                                id="end-date-select"
                                className={classes.selectField}
                                classes={{
                                    selectMenu: classes.selectMenu
                                }}
                                disableUnderline
                                value={selectedEndDate}
                                disabled
                            >
                                <MenuItem value={selectedEndDate}>
                                    <Typography className={classes.selectFieldFont}>{selectedEndDate}</Typography>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </>
                }
            </div>
        </>
    )
}

const Fields = withCookies(
    connect(mapStateToProps, mapDispatchToProps)(
        withStyles(styles, { withTheme: true })(FieldsFunctionClass)
    )
)
export default Fields
