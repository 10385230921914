import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import * as globals from 'components/common/GlobalConstants';
import amber from '@material-ui/core/colors/amber';

const styles = theme => ({
  root: {
    // display: 'flex',
    // paddingTop: "15px !important",
    // paddingLeft: "10px !important",
    // paddingRight: "10px !important",
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  // grow: {
  //   flexGrow: 1,
  // },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  margins: {
    margin: theme.spacing.unit * 2.5,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  table: {
      minWidth: 20,
  },
  badgeRefuse: {
    color: red[800],
  },
  colMargin: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  overrideBlue: {
    background: globals.BLUE_GRADIENT_BG,
    color: "#fff",
  },
  overridePink: {
    background: globals.PINK_GRADIENT_BG,
  },
  overrideLightBlue: {
    background: globals.LIGHT_BLUE_GRADIENT_BG,
    color: "#01579B",
  },
  badge: {
    top: -3,
    right: -30,
  },
  badgeGreen: {
    top: -3,
    right: -30,
    backgroundColor: green[700],
  },
  progressBar: {
    marginTop: 6.46,
    marginBottom: 5.65,
    borderRadius: 16,
    height: 18,
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
  },
  progressPercentageText: {
    fontSize: "14px !important",
      // fontWeight: "bold !important"
  },
  allowanceTopBottom: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  allowanceTop: {
    marginTop: theme.spacing.unit * 3,
  },
  allowanceBottom: {
    marginBottom: theme.spacing.unit * 3,
    color: "red"
  },
  approvalButton: {
    fontSize: 18,
    padding: 15,
    backgroundColor: "#68CBEF",
    textTransform: 'none',
    borderRadius: 0,
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  avatar: {
    margin: 'auto',
  },
  tableCell: {
    padding: 14, //theme.spacing.unit,
  },
  patientsTableCell: {
    padding: "0px !important",
    border: "none !important"
  },
  lastTableCell: {
    paddingRight: "8px !important",
  },
  btnTableCell: {
    paddingLeft: 0,
  },
  skipButton: {
    // background: "#FF5F5F",
  },
  skipButtonLabel: {
    // color: "white !important",
    fontSize: '14px !important',
    fontWeight: 'bold'
  },
  centered: {
    textAlign: 'center',
  },
  canvas: {
    width: '100%',
  },
  signatureButton: {
    //margin: theme.spacing.unit * 2,
    //marginLeft: 0,
    // backgroundColor: "#68CBEF",
    textTransform: 'none',
    borderRadius: 0,
  },
  nurseInitials : {
    fontSize: "4px !important",
    color: "red !important"
  },
  textRight: {
    textAlign: "right"
  },
  signaturePopover: {
    float: 'right',
    padding: theme.spacing.unit * 2,
  },
  resizeTextField: {
    fontSize: '30px'
  },
  patientDone: {
    color: '#333'
  },
  green: {
    color: "#64dd17",
  },
  greatJobIcon: {
    fontSize: 80,
    margin: theme.spacing.unit * 2,
    color: "#64dd17",
    border: "5px solid #64dd17",
    borderRadius: "50%",
  },
  rowDone: {
    color: "#bdbdbd",
  },
  badges: {
    fontSize: 10,
    padding: `${theme.spacing.unit * .2}px ${theme.spacing.unit * .2}px`,
    marginRight: "4px",
    marginTop: "2px",
    marginBottom: "3px",
    width: 8, 
    height: 8,
    border: "2px solid white",
    borderRadius: "50%",
  },
  STAT: {
      color: '#000',
    //  backgroundColor: '#FAD94E',#D20815
      backgroundColor: '#D20815',
      textTransform: 200
  },
  FASTING: {
      color: '#000',
      backgroundColor: '#0000FF',
  },
  icon: {
    lineHeight: 1.2,
    fontSize: '1rem',
  },
  hackAdjustment: {
    paddingLeft: 15,
  },
  paddings: {
    padding: theme.spacing.unit,
  },
  filters: {
    color: "#fff !important",
    padding: "2px",
    margin: "4px",
    display: "inline",
    fontSize: "14px !important",
    minHeight: '0',
    minWidth: '0',
    fontWeight: "normal",
    textTransform: "none"
  },
  filterContainer: {
    paddingLeft: 11.82,
    paddingRight: 11.82,
    backgroundColor: "#707070",
    color: "white"
  },
  selectedFilter: {
    color: "#fff",
    fontWeight: "bold"
  },
  numberOfDraws: {
    // fontFamily: "Helvetica",
    fontSize: '13px !important',
  },
  progressContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  linearColorPrimary: {
    backgroundColor: "white",//"#f1f8e9",
  },
  linearBarColorPrimary: {
    backgroundColor: "#12D003",
    borderRadius: 30,
  },
  buttons: {
    textTransform: 'none',
    borderRadius: 0,
  },
  bottomButtonsContainer: {
    // fontSize: '32px !important',
    marginTop: theme.spacing.unit * 4.5
  },
  doneStatus: {
    fontSize: '14px !important',
    color: "#0FD00F !important",
    fontWeight: 'bold'
  },
  deferredStatus: {
    fontSize: '14px !important',
    color: "#FF5F5F !important",
    fontWeight: 'bold'
  },
  obtainApprovalMessageContainer: {
    margin: '32px auto',
    marginBottom: 0,
    width: "80%",
  },
  obtainApprovalMessageRoot: {
    padding: '0px 12px'
  },
  obtainApprovalMessage: {
    fontSize: "14px !important",
    lineHeight: 1.2,
    color: "#707070",
  },
  obtainApprovalIcon: {
    fontSize: "40px !important",
    color: "#707070",
  },
  initialFinalApprovalText: {
    fontSize: "14px !important",
  },
  initialApprovalContainer: {
    marginTop: 25,
    marginBottom: 25,
  },
  finalApprovalContainer: {
    marginTop: 25,
    marginBottom: 25,
    // float: "right",
    // textAlign: "right",
  },
  tableRow: {
  //   boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 30px 0 rgba(0, 0, 0, 0.11)',
  //   marginBottom: 20,
  },
  myTable: {
    // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 30px 0 rgba(0, 0, 0, 0.11)',
  },
  headerInfo: {
    paddingTop: "15px",
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  // dialogPaper: {
  //   minWidth: "90%",
  //   margin: 10,
  // },
  dialogPaperSignature: {
    minWidth: "95%",
    margin: 5,
  },
  snackBar: {
    backgroundColor: amber[700],
  },
  radioButton: {
    padding: "6px 12px",
  },
  patientListContainer: {
    padding: "15px 30px",
  },
  siteAddress: {
    fontSize: '14px !important',
    // width: '50%',
    // float: 'left',
    // marginBottom: 20
  },
  patientName: {
    color: "#707070",
    fontSize: "14px !important",
    fontWeight: "normal"
  },
  patientRoomNumber: {
    fontSize: "11px !important",
    // backgroundColor: "#707070",
    // color: "white",
    // borderRadius: "none",
    // padding: "3px 10px",
  },
  approvalMessage: {
    textAlign: "center",
    fontSize: '14px !important',
  },
  siteRowCompleted: {
      backgroundColor: '#D0D0D0',
      // textShadow: '.1px .6px #FAFAFA'
  },
  badgePatientInfoCont: {
    display: "table-cell",
    verticalAlign: "middle",
    // padding: 14,
  },
  badgesContainer: {
    display: "table-cell",
    verticalAlign: "top",
    minWidth: 18
  },
  patientInfo: {
    display: "table-cell",
    verticalAlign: "top",
    paddingLeft: "5px",
    width: "1000px", // just a hack for now
  },
  optionsContainer: {
    display: "table-cell",
    verticalAlign: "middle",
    paddingRight: "8px !important",
    // padding: "0px 7px"
  },
  statusContainer: {
    width: 50,
  },
  optionsIcon: {
      width: 25,
      height: 25
  },
  editRoomNumberButtonLabel: {
    // color: "white !important",
    fontSize: '11px !important',
    fontWeight: 'bold'
  },
  btnEditRoomNumber: {
    // width: 50,
    // background: "#CCCCCC",
    // borderRight: "1px solid rgba(224, 224, 224, 1)"
  },
  textFieldRoomNumber: {
    fontSize: 30,
  },
  signatureDialogContentContainer: {
    padding: "15px !important"
  },
  signaturePadContainer: {
    height: "100%",
    border: "1px dashed #ccc",
    backgroundColor: "#F1F2F2"
  },
  signNote: {
    color: "#999",
    textAlign: "center"
  },
  searchPatientContainer: {
    display: 'flex',
    borderRadius: 0,
    marginBottom: 10
  },
  searchPatientContainerFocused: {
    margin: "10px 0px"
  },
  searchPatientIcon: {
    padding: "5px 4px"
  },
  searchPatientInput: {
    flex: 1,
    padding: "3px 0px",
    fontSize: 14
  },
  doneButton: {
    padding: "5px 4px"
  }, 
  divider: {
    height: 28,
    width: 1,
    margin: "0px 2px",
  },
  patientContainer: {
      boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
      marginBottom: 10,        
      paddingRight: "7px !important",
      padding: 14
  },
  filterDropdownContainer: {
    display: "inline-block",
    marginBottom: 10,
    marginRight: 10
  },
  sortDropdownContainer: {
    display: "inline-block",
    marginBottom: 10
  }
  ,
    printListCont: {
        marginRight: 0
    },
    printIconCont: {
        padding: 8,
        border: 'none',
        backgroundColor: 'white'
    },
    printIcon: {
        width: 20,
        height: 20,
    }
});

export default styles