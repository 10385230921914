import React ,{ useState, useEffect }from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
import classNames from 'classnames'

import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

// import CurrentDate from 'components/common/iv-infusion/CurrentDate'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

// import uuid from "uuid"

const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const TotalOrdersFunctionClass = (props) => {
    const { drawData, classes, match } = props
    const { appointment_id } = match.params
    const [drawsProcessedCount,  setDrawsProcessedCount] = useState(0)
    const [pateintListCount, setPateintListCount] = useState(0)
    // init values
    var theSite = {
        patient_list: []
    }

    if (drawData.draw_list !== undefined) {
        theSite = drawData.draw_list.find(function (site) {
            // appointment_id === site_id
            return site.id === appointment_id
        })
    }

    useEffect(() => {
        
        var selectedSite= {
            patient_list: []
        }

        if (drawData.draw_list !== undefined) {
            selectedSite = drawData.draw_list.find(function (site) {
                return site.id === appointment_id
            })
        }
        
        var count = 0
        selectedSite.patient_list.map(patient => {
            if(patient.patient_draw[0].status !== "remaining" ){
                count++
            }
            return ''
        })
        setPateintListCount(theSite.patient_list.length !== undefined ? theSite.patient_list.length : 0)
        setDrawsProcessedCount(count) 
    },[drawData, theSite, appointment_id])

    // Math.round((drawsProcessedCount / pateintListCount)*100)
    const orderPercentage = drawsProcessedCount > 0 ? Math.round((drawsProcessedCount/pateintListCount) * 100) : 0

    return (
        <>
            <div className={classes.totalOrdersContainer}>
                <Grid container justify="space-between">
                    <div>
                        <Grid
                            style={{ height: "100%" }}
                            container
                            alignItems="center">
                            <Typography className={classes.totalOrdersLabel}>Total Orders: {drawsProcessedCount}/{pateintListCount}</Typography>
                        </Grid>
                    </div>
                    <div>
                        <Grid
                            style={{ height: "100%" }}
                            container
                            alignItems="center">
                            <Typography className={classNames(classes.orderPercentage)}>{orderPercentage} %</Typography>
                        </Grid>
                    </div>
                </Grid>
            </div>
        </>
    )
}

const TotalOrders = withRouter(
    withCookies(
        connect(mapStateToProps)(
            withStyles(styles, { withTheme: true })(TotalOrdersFunctionClass)
        )
    )
)
export default TotalOrders
