import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import $ from 'jquery';
import { connect } from "react-redux";
import InputBase from '@material-ui/core/InputBase';
import PouchDB from 'pouchdb-browser';
// import Icon from '@material-ui/core/Icon';
// import Typography from '@material-ui/core/Typography';
// import MDSpinner from "react-md-spinner";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import * as globals from 'components/common/GlobalConstants';
import { setupNewDB, setLoggedInStatus, logEvent, clearAllData, setOnlineStatus } from "../../js/actions/index";
import { withCookies, Cookies } from 'react-cookie';
import styles from "./styles.js";

import { isMobileOnly } from "react-device-detect";
import MobileSnackBarPopup from 'components/common/mobile/SnackBarPopup.js';
import TabletSnackBarPopup from 'components/common/tablet/SnackBarPopup.js';
import moment from 'moment-timezone';

import { instanceOf } from 'prop-types';
import { Typography } from '@material-ui/core';
import Encryption from 'components/common/Encryption';
import GPSControl from 'components/common/controls/GPSControl';
// import { ReactComponent as TeloPointLogoSVG } from 'components/common/mobile/CustomOfflineSVG/TeloPoint.svg';

import { Detector } from "react-detect-offline";
import * as helper from 'components/common/Helper.js';
import { ReactComponent as TeloPointLogoSVG } from 'components/common/mobile/CustomOfflineSVG/TeloPointWhite.svg'

// import uuid from "uuid"

const mapDispatchToProps = dispatch => {
    return {
        setupNewDB: credentials => dispatch(setupNewDB(credentials)),
        setLoggedInStatus: status => dispatch(setLoggedInStatus(status)),
        logEvent: event => dispatch(logEvent(event)),
        clearAllData: event => dispatch(clearAllData(event)),
        setOnlineStatus: status => dispatch(setOnlineStatus(status)),
    };
};

const mapStateToProps = state => {
    return {
        drawData: state.drawData,
        onlineStatus: state.onlineStatus,
    }
};

class LoginClass extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            username: '',
            password: '',
            showPassword: false,
            // showResponse: false,
            // responseMessage: '',
            isLoading: false,
            snackbarOpened: false,
            snackbarMessage: "",
            snackbarVariant: "default",
            module: "login",
            accessLocationBlocked: false
        }
        this.checkGPSTimer = null;
        this.checkGPSInterval = 3000;
        this.innerRef = React.createRef();
        window.$location.allowed = true

    }

    getCredentials = () => {
        var cred;
        const { cookies } = this.props
        try {
            cred = cookies.get('credentials') || ''

            if (cred === "") return ""

            var security = new Encryption()
            var decryptedCred = JSON.parse(security.decrypt(cred))
            return decryptedCred
        } catch (e) {
            cookies.remove('credentials')
            return ""
        }
    }

    connectToDB() {
        var cred = this.getCredentials()

        if (cred !== "") {
            this.props.setupNewDB(cred);
            this.isLoggedIn = true;
        } else {
            this.isLoggedIn = false;
        }
    }

    enterDungeon() {
        this.connectToDB()
        //this.props.history.push('/dashboard');
        //window.location.href = '/sites';
        //this.props.history.push('/sites');
        this.props.history.push('/appointments/today')

        // this.props.history.push('/create_pin_code');
        // window.location.href = '/create_pin_code';
    }

    handleChange = prop => event => {
        if (prop === "username") {
            event.target.value = event.target.value.trim() // .toLowerCase()
        }

        this.setState({ [prop]: event.target.value });
    };

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    componentWillUnmount() {
        //console.log("Freeing timer instance..")
        clearTimeout(this.checkGPSTimer);
    }

    checkGPSTimerHandler = () => {
        // console.log("Tik tok")
        this.innerRef.current && this.innerRef.current.getLocation();
        //console.log("this.innerRef.current.isGeolocationEnabled: " + this.innerRef.current.state.isGeolocationEnabled )

    }

    getInitialCSRFToken = () => {
        // Do ajax GET to get initial csrf
        // On success:
        // -> save initial csrfToken to cookies
        // -> call getLoginCSRFTOken
        // On error: 
        // -> clear cookies 
        // -> clear session id
        // console.log("Getting initial CSRFToken");
        var tz = this.getClientTimezone();
        var ts = this.getClientTimestamp();

        var ajaxRequest = $.ajax({
            beforeSend: function (request) {
                request.setRequestHeader("x-tz", tz);
                request.setRequestHeader("x-ts", ts);
            },
            url: globals.SERVER + "/api-auth/login/",
            method: "GET"
        }).bind(this);

        ajaxRequest.done(function (response) {
            // console.log("Success in getting initial CSRFToken.");
            // console.log(response);
        });

        ajaxRequest.fail(function (jqXHR, textStatus) {
            // console.log("Error in getting initial CSRFToken.");
            // console.log(jqXHR);
        });

    }

    getClientTimezone() {
        return moment.tz.guess(true) || "America/New_York";
    }

    getClientTimestamp() {
        return moment().unix();
    }

    getLoginCSRFToken = (username, password) => {

        // Send credentials along with csrfToken
        // On success:
        // -> save csrfToken to cookies
        // -> save session id to denote successful login
        // On error: 
        // -> clear cookies 
        // -> clear session id
        var data = {
            username: username,
            password: password
        };

        // console.log("Logging in for sending GPS data.");
        var tz = this.getClientTimezone();
        var ts = this.getClientTimestamp();

        var ajaxRequest = $.ajax({
            beforeSend: function (request) {
                request.setRequestHeader("x-tz", tz);
                request.setRequestHeader("x-ts", ts);
            },
            url: globals.SERVER + "/api-auth/login/",
            method: "POST",
            data: data
        }).bind(this);

        ajaxRequest.done(function (response) {
            // console.log("Success in logging-in for GPS information.");
            // console.log(response);
        });

        ajaxRequest.fail(function (jqXHR, textStatus) {
            // console.log("Error in logging-in for GPS information.");
            // console.log(jqXHR);
        });
    }

    sendGPSInfo = () => {
        // if session id exist then
        // send data
        // else 
        //
    }

    undefinedToBlank = (value) => {
        if (value === undefined) {
            return ""
        }
        return value
    }

    handleSubmit(event) {
        event.preventDefault();
        if (window.$location.allowed === false) {
            this.props.logEvent({
                user: this.state.username,
                module: this.state.module,
                action: "login_gps_error",
                description: "GPS is disabled.",
            });
            this.setState({
                ...this.state,
                accessLocationBlocked: true
            });
            // this.handleOpenSnackbar("error", "Location service disabled. Please enable GPS to login.");
            return;
        }

        if (this.state.isLoading === true) {
            console.log("Can't execute! Login process is ongoing..")
            return
        }

        console.log("Logging in...")
        this.setState({
            ...this.state,
            isLoading: true
        });

        var tz = this.getClientTimezone();
        var ts = this.getClientTimestamp();

        if (globals.USE_PAMP_COUCHDB_SERVER) {
            console.log("Using pamp dev couch db...");
        }

        // console.log("username: " + this.state.username)
        if (!this.props.onlineStatus) {
            this.props.logEvent({
                user: this.state.username,
                module: this.state.module,
                action: "login_con_error",
                description: "Connection error."
            });
            this.handleOpenSnackbar("error", "Connection error.");
            return;
        }

        // const data = $(event.target).serializeArray()
        var latVal = window.$location.latitude ? window.$location.latitude : ""
        var longVal = window.$location.longitude ? window.$location.longitude : ""
        var headingVal = window.$location.heading ? window.$location.heading : ""

        // console.log($(event.target.username).val())
        // console.log($(event.target.password).val())

        var request = $.ajax({
            beforeSend: function (request) {
                request.setRequestHeader("x-tz", tz)
                request.setRequestHeader("x-ts", ts)
            },
            url: globals.SERVER + globals.APP_LOGIN,
            method: "POST",
            dataType: 'json',
            contentType: 'application/json',
            processData: false,
            data: JSON.stringify({
                email: $(event.target.username).val(),
                password: $(event.target.password).val(),
                geolocation: {
                    latitude: latVal,
                    longitude: longVal,
                    heading: headingVal
                }
            }),
        });

        request.done(function (response) {
            console.log("login response")          
            console.log(response)          
            var cred = {
                transfer_protocol: globals.COUCHDB_ACCESS_PROTOCOL,
                host: response._c._h,
                port: response._c._p,
                username: response._c._u,
                password: response._c._k,
                database: response._c._d,
                display_name: this.undefinedToBlank(response._i.firstname),
                first_name: this.undefinedToBlank(response._i.first_name),
                last_name: this.undefinedToBlank(response._i.last_name),
                email: this.undefinedToBlank(response._i.email),
                phone_number: this.undefinedToBlank(response._i.phone_number),
                address_line_1: this.undefinedToBlank(response._i.address_line_1),
                city: this.undefinedToBlank(response._i.city),
                state: this.undefinedToBlank(response._i.state),
                zipcode: this.undefinedToBlank(response._i.zipcode),
                token: response._t,
                phleb_id: response._u.id,
                loginDateTime: new Date(),
                pincode: ""
            };

            // i : {
            //     "first_name" : "",
            //     "last_name" : "",
            //     "email" : "",
            //     "phone_number" : "",
            //     "address_line_1" : "",
            //     "city" : "",
            //     "state" : "",
            //     "zipcode" : "",
            // }

            const { cookies } = this.props;
            var security = new Encryption();
            var encryptedCred = security.encrypt(JSON.stringify(cred))

            if (globals.DEBUG_ALLOWED) {
                console.log(cred)
            }

            cookies.set('credentials', encryptedCred, { path: '/', maxAge: 432000 }); // path: '/',

            var dbs = JSON.parse(localStorage.getItem("dbs") !== null ? localStorage.getItem("dbs") : "[]")
            // Log login event
            this.props.logEvent({
                user: cred.username,
                module: this.state.module,
                action: "login_success",
                description: "Successfully logged in. DB: " + cred.database
            })

            //var retainedItems = []
            // if(globals.DEBUG_ALLOWED) {
            console.log("Databases")
            console.log(dbs)
            // }
            dbs.forEach((item, index) => {
                if (globals.DEBUG_ALLOWED) {
                    console.log("Deleting database :" + item)
                }
                this.deleteDatabase(item)
            })

            dbs = []

            dbs.push(cred.database)
            localStorage.setItem("dbs", JSON.stringify(dbs))

            cookies.remove('pincode'); // path: '/', 
            cookies.remove('selected_site'); // path: '/', 

            setTimeout(() => {
                this.props.setLoggedInStatus(true);
                this.enterDungeon();
            }, 1000);

        }.bind(this));

        request.fail(function (jqXHR, textStatus, errorThrown) {
            if (globals.DEBUG_ALLOWED) {
                console.log(jqXHR)
            }
            this.setState({
                ...this.state,
                isLoading: false,
            });

            var notif_message = "";
            var log_description = "";
            if (jqXHR.readyState === 0) {
                notif_message = "Could not connect to the server.";
                log_description = notif_message;
            } else {
                notif_message = "Invalid credentials.";
                log_description = jqXHR.status + ": " + jqXHR.responseText;
            }

            this.props.logEvent({
                user: this.state.username,
                module: this.state.module,
                action: "login_error",
                description: log_description
            });

            this.handleOpenSnackbar("error", notif_message);
        }.bind(this));
    }

    handleOpenSnackbar = (variant = "default", message) => {
        this.setState({ ...this.state, snackbarOpened: true, snackbarVariant: variant, snackbarMessage: message });
    }

    handleCloseSnackbar = () => {
        this.setState({ ...this.state, snackbarOpened: false });
    }

    componentDidMount() {
        const { cookies } = this.props;
        var cred = cookies.get('credentials') || ""

        if (cred === "") {
            this.deleteAllDatabase()
        }
        // this.props.clearAllData(true)
        this.checkGPSTimerHandler();
        this.checkGPSTimer = setInterval(this.checkGPSTimerHandler, this.checkGPSInterval);
    }





    handleExecuteForgotPassword = (forgotPasswordLink) => {
        this.props.logEvent({
            user: "",
            module: this.state.module,
            action: "forgot_password",
            description: "Visit forgot password link."
        });

        window.location.href = forgotPasswordLink;
    }

    deleteDatabase = (databse) => {
        try {
            new PouchDB(databse).destroy().then(function () {
                if (globals.DEBUG_ALLOWED) {
                    console.log("Database was destroyed sucessfully : " + databse);
                }

            }).catch(function (err) {
                console.log("Failed to destroy database : " + databse);
            });
        } catch (exception) {
            // just ignore the error    
        }
    }

    deleteAllDatabase = (databse) => {
        // console.log("Find a way to delete all pouchdb databases.");
        // indexedDB.webkitGetDatabaseNames().onsuccess = (e) => {
        //     for(let i = 0; i < e.target.result.length; i++){
        //       let db = e.target.result[i];
        //       if(db.startsWith('_pouch_')){
        //         indexedDB.deleteDatabase(db);
        //       }
        //     }
        //   };
        // Pouch.resetAllDbs().then(function () {
        //     // console.log("allDbs store is now destroyed");
        //   }).catch(function (err) {
        //     // handle err
        //   });

    }


    // getLocation=()=> {
    //     if (navigator.geolocation) {
    //       navigator.geolocation.getCurrentPosition(this.showPosition, this.showError)
    //     } else { 
    //       console.log("Geolocation is not supported by this browser.")
    //     }
    //   }

    //   showPosition =(position)=> {
    //     console.log("Latitude: " + position.coords.latitude + 
    //     "/rLongitude: " + position.coords.longitude)
    //   }

    //   showError=(error)=> {
    //     switch(error.code) {
    //       case error.PERMISSION_DENIED:
    //         console.log("User denied the request for Geolocation.")
    //         break
    //       case error.POSITION_UNAVAILABLE:
    //         console.log("Location information is unavailable.")
    //         break
    //       case error.TIMEOUT:
    //         console.log("The request to get user location timed out.")
    //         break
    //       case error.UNKNOWN_ERROR:
    //         console.log("An unknown error occurred.")
    //         break
    //     }
    //   }


    render() {
        //this.getLocation()
        const { classes } = this.props;
        const forgotPasswordLink = globals.FORGOT_PASSWORD_LINK + "?redirect=" + window.location.href;
        
        return (
            <div className={classes.mainContainer}>

                <div className={classes.centerContainer}>
                    <TeloPointLogoSVG className={classes.logo} />
                </div>
                <div className={classes.centerContainer}>
                    <Typography className={classes.slogan}>Maximize care delivery and convenience.</Typography>
                </div>

                <Detector render={({ online }) => {
                    if (this.props.onlineStatus === false) {
                        if (online) {
                            console.log("You are now online.")
                            this.props.setOnlineStatus(true)
                        }
                    } else {
                        if (!online) {
                            console.log("You are now offline.")
                            this.props.setOnlineStatus(false)
                        }
                    }

                    console.log("You are currently " + (this.props.onlineStatus ? "ONLINE" : "OFFLINE") + ".")
                    return "";
                }} />
                <form onSubmit={this.handleSubmit} className={classes.formContainer}>
                    <div className={classes.centerContainer}>
                        <div className={classes.loginBox}>
                            <Typography className={classes.labels}>
                                Email
                            </Typography>
                            <FormControl variant="outlined" className={classNames(classes.formControl, classes.fullWidth)}>
                                <InputBase
                                    id="username"
                                    name="username"
                                    placeholder="Enter your email"
                                    value={this.state.username}
                                    onChange={this.handleChange('username')}
                                    required
                                    className={classes.field}
                                    inputProps={{
                                        autoCapitalize: 'none', // need to test on mobile device
                                        // autoComplete: uuid.v4()
                                    }}
                                />
                            </FormControl>
                            <Typography className={classes.labels}>
                                Password
                            </Typography>
                            <FormControl variant="outlined" className={classNames(classes.formControl, classes.fullWidth)}>
                                <InputBase
                                    id="password"
                                    name="password"
                                    placeholder="Enter your password"
                                    // type={this.state.showPassword ? 'text' : 'password'}
                                    value={this.state.password}
                                    className={classNames(classes.field, classes.passwordField)}
                                    onChange={this.handleChange('password')}
                                    required
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                className={classes.passwordFieldIcon}
                                                onClick={this.handleClickShowPassword}>
                                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    // Must have a way to disable auto fill, which cause small fonts on auto filled fields
                                    inputProps={{
                                        type: this.state.showPassword ? 'text' : 'password',
                                        // autoComplete: uuid.v4()
                                    }}
                                />
                            </FormControl>

                            <Typography onClick={() => this.handleExecuteForgotPassword(forgotPasswordLink)} className={classes.forgotPasswordText}>Forgot your password?</Typography>

                            <FormControl
                                fullWidth
                                className={classNames(classes.colMargin)}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    fullWidth
                                    disabled={this.state.isLoading}
                                    className={classNames(classes.loginButton)}>
                                    {this.state.isLoading ? 'Authenticating...' : 'Log In'}
                                </Button>
                            </FormControl>
                        </div>
                    </div>
                </form>

                {isMobileOnly ?
                    <MobileSnackBarPopup
                        open={this.state.snackbarOpened}
                        onClose={this.handleCloseSnackbar}
                        variant={this.state.snackbarVariant}
                        message={this.state.snackbarMessage}
                    ></MobileSnackBarPopup>
                    :
                    <TabletSnackBarPopup
                        open={this.state.snackbarOpened}
                        onClose={this.handleCloseSnackbar}
                        variant={this.state.snackbarVariant}
                        message={<span id="snackbar">{this.state.snackbarMessage}</span>}
                    ></TabletSnackBarPopup>
                }

                <GPSControl
                    ref={this.innerRef}
                    displayGPSIcon={false}
                    onGPSEvent={(data) => {
                        //console.log("this.innerRef.current.isGeolocationEnabled: " + this.innerRef.current.state.isGeolocationEnabled )
                        if (globals.DEBUG_ALLOWED) {
                            console.log("GPS data : ")
                            console.log(data);
                        }
                        if (data.isGeolocationEnabled === false) {

                            if (data.positionError !== undefined) {
                                // console.log("GPS disabled")
                                if (this.state.accessLocationBlocked !== true) {
                                    window.$location.allowed = false
                                    /**
                                     * This was commented out to allow the user to enter credentials first before
                                     * GPS detection. If uncommented, block of user with gps enabled will be immediate. 
                                     */
                                    //this.setState({ ...this.state, accessLocationBlocked: true})

                                }
                            }

                        } else if (data.isGeolocationEnabled) {
                            if (globals.DEBUG_ALLOWED) {
                                console.log("GPS enabled")
                            }

                            if (data.coords) {
                                window.$location.latitude = data.coords.latitude
                                window.$location.longitude = data.coords.longitude
                                window.$location.heading = data.coords.heading
                                window.$location.allowed = true
                            }

                            if (this.state.accessLocationBlocked !== false) {
                                if (globals.ENFORCE_GPS_BEFORE_LOGIN) {
                                    this.setState({ ...this.state, accessLocationBlocked: false })
                                }
                            }
                        }

                    }}
                    onPositionError={(error) => {
                        // console.log("GPS error : ")
                        // console.log(error)
                    }}
                />

                <Dialog
                    id="gps-access-blocked-dialog"
                    fullScreen={false}
                    classes={{
                        root: classes.dialogRoot
                    }}
                    open={this.state.accessLocationBlocked}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogContent>
                        {helper.getMobileOperatingSystem() === "iOS"
                            ?
                            <div>
                                <Typography style={{ color: 'red', fontSize: '20px', textAlign: 'center' }}>iPhone location services need to be enabled.</Typography>
                                <br />
                                <Typography style={{ color: 'black' }}>
                                    Open Settings, scroll down, tap "Privacy".<br />
                                    1) Tap "Location Services" and set to enabled<br />
                                    2) Scroll down to "Safari Websites" and set to "While using the APP".
                                </Typography>
                            </div>
                            :
                            <div>
                                <Typography style={{ color: 'red', fontSize: '20px', textAlign: 'center' }}>Location service is not enabled.</Typography>
                                <br />
                                <Typography style={{ color: 'black' }}>
                                    Please make sure that location service is enabled and permitted to this web browser.
                                </Typography>
                            </div>
                        }
                        <br />
                        <div style={{ textAlign: 'center' }}>
                            <Button
                                onClick={() => window.location = '/'}
                                variant="contained"
                                color="default"
                                size="large"
                                classes={{
                                    root: classNames(classes.blueButton),
                                    label: classNames(classes.scheduleWhiteButtonLabel)
                                }}
                            // className={classNames(classes.blueButton)}
                            >
                                {/* <Icon className={classes.scheduleWhiteButtonIcon}>refresh</Icon> */}
                                OK</Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

LoginClass.propTypes = {
    classes: PropTypes.object.isRequired,
};

const Login = withCookies(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoginClass)));
export default Login
