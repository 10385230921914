import React, { useLayoutEffect } from 'react'
// import classNames from 'classnames'

import Typography from '@material-ui/core/Typography'
import CameraAltIcon from '@material-ui/icons/CameraAlt'
import Grid from '@material-ui/core/Grid'
import $ from 'jquery'
import moment from 'moment-timezone'
// import testImage from './test-images/step2-iv-kit.PNG'
import * as globals from 'components/common/GlobalConstants'
export const TapHere = (props) => {
    const { classes, isOrderRemaining } = props

    // https://reactjs.org/docs/hooks-reference.html#uselayouteffect
    useLayoutEffect(() => {
        $("#tap-here-button").height($("#tap-here-button").width() * 1.25)
    }, [])

    return (
        <Grid
            id="tap-here-button"
            container
            justify="center"
            alignItems="center"
            className={classes.tapHereContainer}
        >
            <div style={{ textAlign: "center" }}>
                <CameraAltIcon className={classes.tapHereIcon} />
                <Typography className={classes.tapHereLabel}>
                    {isOrderRemaining ? "Tap here to take a photo": "No photo"}
                </Typography>
            </div>
        </Grid>
    )
}
export const CapturedPhotoDisplay = (props) => {
    const { classes, data } = props
    // console.log("data")
    // console.log(data)
    // const item = data.item
    // const date = moment.unix(item.client_timestamp).tz(item.client_timezone).format("MMM D, YYYY")
    // const time = moment.unix(item.client_timestamp).tz(item.client_timezone).format("hh:mma")
    // const filename = item.filename
    // const image = item.metadata.image.data

    const item = data.item

    var date = ""
    var time = ""

    try {
        date = moment.unix(item.client_timestamp).tz(item.client_timezone).format("MMM D, YYYY")
        time = moment.unix(item.client_timestamp).tz(item.client_timezone).format("hh:mma")
    } catch (e) {
        console.log("Error here")
        console.log(e)
    }

    const filename = item.filename
    var image = item.metadata.image.data

    if (item.doc_type === "offline_uploaded" && item.path !== undefined) {
        image = (globals.USE_PAMP_COUCHDB_SERVER) ? item.path : globals.SERVER + item.path
    }

    return (
        <div className={classes.capturedDataContainer}>
            <Grid
                container
                direction="row"
                justifycontent="flex-start"
                wrap="nowrap"
            >
                <div className={classes.capturedImageDiv}>
                    <img src={image} className={classes.capturedImage} alt={filename} />
                </div>
                <div>
                    <Typography className={classes.capturedDataDateTimeLabel}>{date} | {time}</Typography>
                    <Typography className={classes.capturedDataMainNote}>{filename}</Typography>
                </div>
            </Grid>
        </div>
    )
}
// export const CapturedPhoto = (props) => {
//     const { classes } = props

//     useLayoutEffect(() => {
//         $("#iv-kit-image-container").height($("#iv-kit-image-container").width() * 1.25)
//     }, [])

//     return (
//         <div id="iv-kit-image-container">
//             <img src={testImage} className={classes.ivKitCapturedPhoto} alt="test image only" />
//         </div>
//     )
// }