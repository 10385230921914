import  {Component} from 'react';
// import PropTypes from 'prop-types';
// import { withStyles } from '@material-ui/core/styles';

// import classNames from 'classnames';
// import ApplicationBar from 'components/common/mobile/ApplicationBar';
// import SimpleBottomNavigation from 'components/common/mobile/SimpleBottomNavigation';
import { connect } from "react-redux";
import { withCookies } from 'react-cookie';
// import Icon from '@material-ui/core/Icon';
// import Button from '@material-ui/core/Button';

// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// // import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// import ListItemText from '@material-ui/core/ListItemText';
// import TextField from '@material-ui/core/TextField';
//import styles from "./styles.js";
// import { InputBase } from '@material-ui/core';
// import Paper from '@material-ui/core/Paper';



//import ImmutableJS from "immutable";
import { setScreenLockStatus } from "js/actions";
const mapDispatchToProps = dispatch => {
    return {
      //setupNewDB: credentials => dispatch(setupNewDB(credentials)),
      setScreenLockStatus: status => dispatch(setScreenLockStatus(status)),
    };
};


class LockScreenClass extends Component {
    componentDidMount(){
        this.props.setScreenLockStatus(true)
    }
    
    render = () => {         
        return null;
    }
}

const LockScreen = withCookies(connect(null, mapDispatchToProps)(LockScreenClass));
export default LockScreen;