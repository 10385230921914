
const styles = theme => ({
    switchCameraButton: {
        position: "absolute",
        right: 18,
        top: 108,
        zIndex: 2
    },
    cameraIcon: {
        fontSize: "35px !important",
        fill: "white !important",
    },
    fabBorderContainer: {
        textAlign: "center",
        position: "absolute",
        width: "100%"
    },
    fabBorder: {
        margin: "auto",
        width: "60px",
        height: "60px",
        borderRadius: "50%",
        backgroundColor: "rgba(255,255,255,0.5)"
    },
    fabBorderDisabled: {
        margin: "auto",
        width: "60px",
        height: "60px",
        borderRadius: "50%",
        backgroundColor: "rgba(255,255,255,0.1)"
    }
})

export default styles