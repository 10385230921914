import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    gridRoot: {
        display: 'flex',
        padding: "15%",
        height: '90vh'
    },
    btnNoDrawlist: {
        marginRight: theme.spacing.unit,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
});

class RequestTimeoutScreenClass extends Component {

    refreshPage() {
        document.location.reload();      
    }
      
    handleLogoutClick(e) {
        window.location.href = '/logout';
    };

    render() {
        const { classes } = this.props;

        return(
            <Grid
                container
                className={classes.gridRoot}
                alignContent="center"
                direction="column"
                justify="center"
                alignItems="flex-start"
            >
                <Typography variant="h4">Request Timeout</Typography><br/>
                <Typography variant="subtitle1">Possible reasons:</Typography><br/>
                <Typography>- Phlebotomist has no drawlist for today.</Typography>
                <Typography>- Connection timeout, please check your connection and retry.</Typography>
                <br/>
                <Grid container>
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        className={classNames(classes.btnNoDrawlist)}
                        onClick={()=>this.refreshPage()}>Retry<Icon className={classNames(classes.rightIcon)}>refresh</Icon>
                    </Button>
                    <Button 
                        variant="contained" 
                        color="secondary"
                        className={classNames(classes.btnNoDrawlist)}
                        onClick={this.handleLogoutClick.bind(this)}>
                        <Icon className={classNames(classes.leftIcon)}>exit_to_app</Icon>Logout
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

const RequestTimeoutScreen = withStyles(styles)(RequestTimeoutScreenClass);
export default RequestTimeoutScreen;
