//import * as globals from 'components/common/GlobalConstants';

const styles = theme => ({

    root: {
        flexGrow: 1,
    },
    keyContainer: {
        padding: "10px",
        margin: "20px"
    },
    paper: {
      
        padding: "10px",
      //  textAlign: 'center',
        margin: "25px",
        color: theme.palette.text.secondary,
        border: "0"
    },

    keyNumeric: {
      
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        fontWeight: "bold",
        fontSize: "28px !important",
       // display: 'flex',
        alignItems: 'center',
        textAlign: "center !important",
        margin: "auto",
        padding: "4px",
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 2px 10px 0 rgba(0, 0, 0, 0.11)',
        boxSizing: "borderBox",
        backgroundColor: "#68CBEF",
        color: "white !important",
        "&:hover": {
            backgroundColor: "#68CBFF",
        }        

        // riffle
    },

    ripple: {
        // backgroundPosition: "center",
        // transition: "background 0.8s",
        // "&:hover": {
        //     background: "#47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%"
        // },
        // "&:active": {
        //     backgroundColor: "#6eb9f7",
        //     backgroundSize: "100%",
        //     transition: "background 0s",
        // }
    },
    
    keyText: {
        fontWeight: "bold",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        fontSize: "14px !important",
        display: 'flex',
        alignItems: 'center',
        textAlign: "center !important",
        margin: "auto",
        padding: "4px",
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 2px 10px 0 rgba(0, 0, 0, 0.11)',
        boxSizing: "borderBox",
        //backgroundColor: "#68CBEF",
        //backgroundColor: "#FFAA60", 
        backgroundColor: "#F3F3F3", 
        //backgroundColor: "#F1F3F4", 
        //border: "1px solid lightgray", //#F1F3F4
        color: "orange !important",
        "&:hover": {
            backgroundColor: "#F3F3F",
          }

    },
    keyPadMessage:{
        fontSize: "16px !important",
        textAlign: "center",
        width: "80% !important",
        margin: "auto",
        color: "#ff6961 !important",
        height: "15px"
    },
    gpsError:{
        fontSize: "22px !important",
        textAlign: "center",
        width: "80% !important",
        margin: "auto",
        color: "#ff6961 !important",
        height: "18px"
    },

    keyPadInstruction: {
        fontSize: "20px",
        textAlign: "center",
        width: "80%",
        margin: "auto",
        color: "lightgray"
    },

    keysContainer:{
  
            width: "80%",
            margin: "auto"
        
    },
    keyDisplayContainer: {
        width: "40%",
        margin: "auto"
    },

    keyDisplayOn: {
        borderRadius: "50%",
        width: "10px",
        height: "10px",
        fontWeight: "bold",
        fontSize: "20px !important",
       // display: 'flex',
        alignItems: 'center',
        textAlign: "center !important",
        margin: "auto",
        padding: "2px",
        //boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 2px 10px 0 rgba(0, 0, 0, 0.11)',
        boxSizing: "borderBox",
        //backgroundColor: "#68CBEF",
        backgroundColor: "gray",
        border: "1px solid gray",
        color: "white !important"

    },
    keyDisplayOff: {
        borderRadius: "50%",
        width: "10px",
        height: "10px",
        fontWeight: "bold",
        fontSize: "20px !important",
       // display: 'flex',
        alignItems: 'center',
        textAlign: "center !important",
        margin: "auto",
        padding: "2px",
        //boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 2px 10px 0 rgba(0, 0, 0, 0.11)',
        boxSizing: "borderBox",
        //backgroundColor: "#68CBEF",
       // backgroundColor: "lightgray",
        border: "1px solid gray",
        color: "white !important"

    },
    keyIcon: {
       
     margin: "auto"

    },

    formContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        textAlign: 'center',
        width: '60%',
      },
      colMargin: {
          marginTop: theme.spacing.unit,
          marginBottom: theme.spacing.unit,
      },
      loginButton: {
          fontSize: 18,
          marginTop: 30,
          padding: "13px 28px",
          backgroundColor: "#68CBEF",
          textTransform: 'none',
          borderRadius: 0,
      },
      card: {
          boxShadow: 'none',
      },
      media: {
          objectFit: 'contain',
      },
      teloPointLogo: {
          marginBottom: 30,
       
      },
      logoContainer:{
          width: "70%",
          margin: "auto"
      }
});


export default styles;