import * as globals from 'components/common/GlobalConstants';

const styles = theme => ({
    root: {
        // display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
    },
    overrideBlue: {
        background: globals.BLUE_GRADIENT_BG,
    },
    blueButton: {
        backgroundColor: "#68CBEF",
        textTransform: 'none',
        borderRadius: 0,
    },
    buttonLarge: {
        fontSize: 18,
        padding: 15,
        backgroundColor: "#68CBEF",
        textTransform: 'none',
        borderRadius: 0,
    },
    scheduleContainer: {
        padding: "15px",
    },
    list: {
    //   border: "1px solid rgba(0, 0, 0, 0.16)",
    //   marginLeft: "15px",
    //   marginRight: "15px",
      marginBottom: theme.spacing.unit,
      fontSize: "32px !important",
      padding: "5px 11px",      
      boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
    },
    listHeader: {
        padding: "3px 11px",    
        marginBottom: 12  
    },
    listHeaderLabel: {
        fontWeight: "bold",
        fontSize: "11px !important",
        color: "#858585 !important"
    },
    listHeaderRightLabel: {
        fontWeight: "bold",
        fontSize: "11px !important",
        minWidth: 130,
        color: "#858585 !important"
    },
    scheduleListRightSection: {
        minWidth: 130,
    },
    scheduleDateLabel: {
        fontSize: "12px !important",
        fontWeight: "bold",
        color: "#707070",
    },
    scheduleLabelRecuring: {
        fontSize: "12px !important",
        // color: "white !important",
        color: "#858585 !important"
    },
    scheduleLabelTimeOff: {
        fontSize: "12px !important",
        color: "white !important",
    },
    scheduleLabelTimeOffRule: {
        fontSize: "12px !important",
    },
    rightLabel: {
        // fontSize: "12px !important",
        // color: "white !important",
        // fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    },
    withMarginRight: {
        marginRight: "11px"
    },
    customApplicationBarHeader: {
        padding: "7px 17px",
        marginLeft: "-15px",
        marginRight: "-15px"
    },
    listULMainHeader: {
        marginTop: 25
    },
    schedulesMainHeader: {
        color: "#707070 !important",
        fontSize: '24px !important',
        fontWeight: 'bold',
         padding: "0px !important",
         margin: "0px !important"
    },
    distanceCovered: {
        color: "#707070 !important",
        fontSize: '14px !important',
        fontWeight: 'normal',
        
         
    },
    root_distanceCovered: {
 
         paddingTop: "0px !important",
         marginTop: "0px !important"
         
    },

    schedulesHeader: {
        color: "#707070 !important",
        fontFamily: "Helvetica, Arial, sans-serif !important",
        fontWeight: "bold !important"
    },
    schedulesMainContainer: {
        // borderRadius: 10,
        margin: 15,
        // boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 6px 30px 0 rgba(0, 0, 0, 0.11)',
    },
    schedulesContainer: {
        // borderTop: "1px solid #DCDCDC",
        // padding: "7px 17px",
        verticalAlign: "middle"
    },
    schedulesContent: {
        // border: "1px solid #DCDCDC",
        // display: "table-cell",
        // width: "100%",
        paddingLeft: 16,
        paddingRight: 16,
        // minHeight: 192,
        // maxHeight: 192,
        // overflowY: "scroll"
    },
    placeholder: {
        margin: 0,
        marginTop: "-25px",
        color: "#C6C6C6"
    },
    schedulesGrid: {
        height: 192
    },
    labelDay: {
        marginRight: 11,
        display: "inline",
        fontWeight: "normal",
        fontSize: "12px !important",
        color: "#E2E2E2 !important",//"#FAFAFA !important",
    },
    labelDayBlueSelected: {
        fontWeight: "bold",
        color: "#68CBEF !important"
    },
    labelDayOrangeSelected: {
        fontWeight: "bold",
        color: "#FFAA60 !important"
    },
    // labelDaySelected: {
    //     fontWeight: "bold",
        // color: "white !important"
    // },
    // labelDayBlue: {
    //     fontWeight: "bold",
    //     color: "#68CBEF !important"
    // },
    iconButton: {
        // fontWeight: "bold"
        padding: 6
    },
    listUL: {
        paddingTop: 0,
        paddingBottom: 0
    },
    topNavButton: {
        textTransform: 'none',
        padding: "6px 12px !important",
        width: "100%",
    },
    topNavButtonLabel: {
        fontSize: 16,
        whiteSpace: 'nowrap'
    },
    topNavButtonRightGutter: {
        paddingRight: 5
    },
    switchViewButton: {
        backgroundColor: "white",
        color: "#777777",
        borderRadius: 0,
        padding: "2px 3px",
        minWidth: 40,
        left: "-10px",
        // '&:not([class*="switchViewButtonSelected"]):hover':{
        //     backgroundColor: "#e0e0e0 !important",
        // }
    },
    switchViewButtonSelected: {
        backgroundColor: "#68CBEF !important",
        color: "white",
    },
    switchViewButtonLeft: {
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
    },
    switchViewButtonRight: {
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
    },
    switchViewButtonSecondary: {
        right: 5
    },
    scheduleWhiteButton: {
        borderRadius: 5,
        backgroundColor: "white",
        color: "#707070",
        padding: "3px 8px",
        textTransform: 'none',
    },
    scheduleWhiteButtonLabel: {
        fontSize: 12,
        fontWeight: "bold"
    },
    addScheduleButton: {
        padding: 7
    },
    scheduleWhiteButtonIcon: {
        fontSize: 18,
        marginTop: "-3px"
    },
    scheduleWhiteButtonLeftMargin: {
        marginLeft: 10
    },
    workdayScheduleList: {
        // backgroundColor: "#68CBEF",
        border: "1px solid #68CBEF",
    },
    timeOffScheduleList: {
        backgroundColor: "#FFAA60",
    },
    recurringScheduleListRule: {
        padding: "9px 11px",
        border: "none",
    },
    timeOffScheduleListRule: {
        padding: "9px 11px",
        border: "none",
    },
    dialogPaper: {
        minWidth: "90%",
        minHeight: "80%",
        // margin: 10, // 18
    },
    dialogPaperRulesContainer: {
        padding: "0px 12px"
    },
    rulesNote: {
        margin: "20px auto 10px auto",
        color: "#999",
        textAlign: "center"
    },
    allDayLabel: {
        padding: 0
    },
    checkboxListContainer: {
        padding: 0,
        paddingBottom: 10,
        justifyContent: 'flex-end'
    },
    checkboxListChkbx: {
        padding: 0,
        paddingRight: 5,
        paddingBottom: 0
    },
    lockIcon: {
        position: "absolute",
        marginLeft: "-20px",
        marginTop: "10px",
        fontSize: "14px",
        color: "#707070"
    },
    forDeletionIcon: {
        position: "absolute",
        marginLeft: "-22px",
        marginTop: "10px",
        fontSize: "18px",
        color: "red"
    }
});

export default styles;