import React from 'react'

import { withStyles } from '@material-ui/core/styles';
import patientStyles from './styles'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';



const mapDispatchToProps = dispatch => { return {} }

const mapStateToProps = state => {
    return {
        // onlineStatus: state.onlineStatus
    }
}

const FooterFunctionClass = (props) => {
    const {
        classes,
        onClickCancel,
        canContinueSave = false,
        onClickSave,
    } = props


    const [open, setOpen, history] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const backToSettings = () => {
        history.push("/workflow_settings")
    };

    return (
        <>
            <Grid container justify="space-between" className={classes.footerContainer}>
                <Button
                    className={classes.cancelButton}
                    onClick={handleClickOpen}
                    >Cancel
                </Button>
                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Cancel Confirmation</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                Are you sure you want to cancel? Your changes with be discarded.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                Cancel
                                </Button>
                                <Button onClick={backToSettings} color="primary">
                                Okay
                                </Button>
                            </DialogActions>
                    </Dialog>
                <Button
                    color="primary"
                    className={classes.acceptButton}
                    onClick={onClickSave}
                    // disabled={!canContinueSave}
                    >Submit
                </Button>
            </Grid>
        </>
    )
}

const Footer = withRouter (
withCookies(
    connect (mapStateToProps, mapDispatchToProps,withMobileDialog())(
        withStyles(patientStyles, { withTheme: true })(FooterFunctionClass)
        )
    )
)
export default Footer