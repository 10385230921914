import React, { Component } from 'react';
import PropTypes from 'prop-types';

import $ from 'jquery';
import SwiperSliderComponent from 'components/common/mobile/SwiperSliderComponent';

import { withStyles } from '@material-ui/core/styles';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';

import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import GpsFixedIcon from '@mui/icons-material/GpsFixed'

const styles = theme => ({
    fullHeight: {
        height: '100%'
    },
    zoomControlContainer: {
        position: "absolute",
        bottom: 272,
        right: 24
    },
    zoomButtons: {
        display: "block",
        backgroundColor: "#F8F8F8 !important",
        boxShadow: "0px 3px 6px #0000001A",
        borderRadius: 4,
        padding: 8,
        marginTop: 8,
        "&:active": { backgroundColor: "#F8F8F8 !important" },
        "&:focus": { backgroundColor: "#F8F8F8 !important" },
    }
});

class GoogleMapComponent extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.randID = this.generateID();
        this.showHomeMarker = true;
        this.showHomeMarkerLabel = true;
        this.map = null;
        this.mapEl = null;
        this.zoom = 12;
        this.home = null;
        this.start = null; // {lat: 41.782120, lng: -87.933825};
        this.end = null; // {lat: 41.819940, lng: -87.604880};
        this.waypts = null;
        this.wayptsOptions = null;
        this.totalMeters = 0;
        this.totalMetersDriven = 0;
        this.activeInfoWindow = null;

        this.state = {
            swiperChildRef: null
        };
    }

    triggerResponseHandler = (value) => {
        if (this.props.milesDrivenPercentageResponseHandler !== undefined) {
            this.props.milesDrivenPercentageResponseHandler(value);
        }
    }

    componentDidMount() {
        this.mapEl = $("#googleMap_" + this.randID);
        this.generateMapContainerDimension();
        this.initMap();
    }

    generateMapContainerDimension() {
        var width = this.mapEl.parent().width();
        var height = this.mapEl.parent().height();

        this.mapEl.css({
            width: width + "px",
            height: height + "px",
        });
    }

    initMap() {
        var mapProps = {
            center: this.start,
            zoom: this.zoom,
            streetViewControl: false,
            fullscreenControl: false,
            controlSize: 34,
            // panControl: true,
            zoomControl: true,
            // styles: [{"featureType":"all","elementType":"all","stylers":[{"saturation":"32"},{"lightness":"-3"},{"visibility":"on"},{"weight":"1.18"}]},{"featureType":"administrative","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"landscape","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"landscape.man_made","elementType":"all","stylers":[{"saturation":"-70"},{"lightness":"14"}]},{"featureType":"poi","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"saturation":"100"},{"lightness":"-14"}]},{"featureType":"water","elementType":"labels","stylers":[{"visibility":"off"},{"lightness":"12"}]}]
            styles: [{ "featureType": "road", "elementType": "labels", "stylers": [{ "visibility": "off" }] }]
        };
        // // console.log("#googleMap_" + this.randID);
        this.map = new window.google.maps.Map(this.mapEl.get(0), mapProps);

        var sitesHasOneLocationOnly = false;
        if (this.waypts.length > 1) {
            sitesHasOneLocationOnly = this.waypts.every((val, i, arr) => {
                return val.location.lat === arr[0].location.lat &&
                    val.location.lng === arr[0].location.lng;
            });
        }

        var directionsService = new window.google.maps.DirectionsService();
        var directionsDisplay = new window.google.maps.DirectionsRenderer({
            // preserve zoom if only 1 record
            // if false, it will re zoom to show all the current plotted points
            preserveViewport: this.waypts.length === 1 || sitesHasOneLocationOnly,
            suppressPolylines: true,
            suppressMarkers: true,
        });
        directionsDisplay.setMap(this.map);
        if (this.waypts.length > 0) {
            this.calculateAndDisplayRoute(directionsService, directionsDisplay);
        }

        // this.map.addListener('click', function() {    
        //     if(this.activeInfoWindow) {
        //         this.activeInfoWindow.close();
        //     }
        // }.bind(this));

        if (this.showHomeMarker) {
            this.addHomeMarker(this.home); // point A
        }
    }

    addHomeMarker(location) {
        var icon = {
            url: '/images/map/home.png',
            size: new window.google.maps.Size(46, 46),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(23, 23) // base pointer
        };

        // this.showHomeMarkerLabel ? 
        this.addCustomMarker(location, icon, {
            text: "-",          // NOTE: empty text will result to display [Object object]
            color: '#69CEF1',   // to hack, put - and make color same as background of home icon
            fontSize: "12px",
            fontWeight: "normal"
        })
    }

    addStarMarker(location, status = "not_visited", label) {
        var icon = {
            url: '/images/map/final_site_' + status + '.png?v=4',
            size: new window.google.maps.Size(31, 31),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(15, 15)
        };

        this.addCustomMarker(location, icon, label);
    }

    addSiteMarker(location, status = "not_visited", label, infoWindowObj = null) {
        var icon = {
            url: '/images/map/site_' + status + '.png?v=4',
            size: new window.google.maps.Size(46, 46),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(23, 23) // base pointer
        };

        this.addCustomMarker(location, icon, label, infoWindowObj);
    }

    addCarMarker(steps) {
        var mid_step = parseInt(steps.lat_lngs.length / 2);
        var location = steps.lat_lngs[mid_step];
        var next_step = steps.lat_lngs[mid_step + 1];

        var heading = window.google.maps.geometry.spherical.computeHeading(location, next_step);

        var marker = new window.google.maps.Marker({
            position: location,
            animation: window.google.maps.Animation.DROP,
            icon: {
                path: "M12.407,12.84h1.875c-.072.289-.144.649-.216.866A2.169,2.169,0,0,1,12.624,15c-.144.072-.216.144-.216.289,0,1.154.072,2.308.072,3.462a3.719,3.719,0,0,1-2.958,3.679,2,2,0,0,1-.649.072c-1.082,0-2.092,0-3.174.072A3.756,3.756,0,0,1,1.948,18.9c0-1.154,0-2.308-.072-3.535,0-.144-.072-.216-.216-.289A2.091,2.091,0,0,1,0,12.984H1.8V12.7c0-2.958-.072-5.843-.072-8.8A3.7,3.7,0,0,1,5.482.072c1.01,0,2.092,0,3.1-.072a3.719,3.719,0,0,1,3.679,2.958v.866c.072,2.885.072,5.771.144,8.728Zm-1.154,2.741c-.289-.938-.649-1.8-.938-2.669-1.082.072-2.164.216-3.246.216s-2.164-.072-3.246-.144c-.289.866-.577,1.8-.866,2.669C5.122,16.952,9.377,16.807,11.253,15.581ZM2.885,3.967c.289.866.577,1.731.866,2.525.072.144.144.216.289.216.649-.072,1.371-.144,2.02-.216a18.274,18.274,0,0,1,3.967.144c.144,0,.216,0,.289-.216.216-.721.433-1.371.649-2.02.072-.144.072-.361.144-.5C8.945,2.6,4.689,2.813,2.885,3.967Z",
                scaledSize: new window.google.maps.Size(17, 28),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(8, 14),
                fillColor: "#707070",
                fillOpacity: 1,
                rotation: heading + 180 //bearing + 180 // + 180 // just to flip image directing top
            },
            zIndex: Math.round(location.lat * -100000) << 5
        });
        marker.setMap(this.map);
    }

    addCustomMarker(location, icon = null, labelFont = {
        text: "",
        color: 'white',
        fontWeight: 'normal',
        fontSize: "12px",
        textAlign: "center"
    }, infoWindowObj = null) {
        var marker = new window.google.maps.Marker({
            position: location,
            animation: window.google.maps.Animation.DROP,
            icon: icon,
            label: {
                text: labelFont.text,
                color: labelFont.color,
                fontSize: labelFont.fontSize,
                fontWeight: labelFont.fontWeight,
                textAlign: labelFont.textAlign,
                className: 'gm-marker-label'
            },
            zIndex: Math.round(location.lat * -100000) << 5
        });
        // marker.metadata = {class: "google-map-markers"};
        marker.setMap(this.map);

        // if(infoWindowObj) {
        //     // console.log(infoWindowObj)
        //     var UUID = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,a=>(a^Math.random()*16>>a/4).toString(16));
        //     // var infoWindowContent = infoWindowObj.content ? infoWindowObj.content : '';
        //     // var infoText = '<span id="' + infoTextUUID + '">' + infoWindowContent + '</span>';
        //     // var isIndividual = infoWindowContent.toLowerCase() === 'i';
        //     let contentString = ReactDOMServer.renderToString(this.SiteItem(
        //         UUID,
        //         infoWindowObj.siteData
        //     ));

        //     var infowindow = new window.google.maps.InfoWindow({
        //         content: contentString,
        //         // pixelOffset: new window.google.maps.Size(isIndividual ? 28 : 35,32) // offset left, top
        //     });

        //     // // custom css of info window
        //     new window.google.maps.event.addListener(infowindow, 'domready', function() {
        //         //     // console.log("google.maps.event.addListener(infowindow")
        //         $("#" + UUID).closest(".gm-style-iw-t").find('button').hide();
        //         // App.css, add hidden class to toggle ::after content and hide it
        //         $("#" + UUID).closest(".gm-style-iw-t").addClass('hidden');
        //         //     $("#" + infoTextUUID).closest(".gm-style .gm-style-iw-c").css({
        //         //         'padding': '3px 6px',
        //         //         'border-radius': '6px',
        //         //         'background-color': '#fff5ca',
        //         //         'color': 'black',//labelFont.color,
        //         //         'font-weight': 'bold',
        //         //         'box-shadow': 'none',
        //         //         'font-size': '12px',
        //         //         'width': (isIndividual ? '25px' : '40px'),
        //         //         'text-align': 'center'
        //         //     });
        //         //     // $("#" + infoTextUUID).css({
        //         //     //     'color': infoWindowObj.font.color
        //         //     //  });
        //         //      $("#" + infoTextUUID).closest(".gm-style .gm-style-iw-d").css({
        //         //        'overflow': 'unset' 
        //         //     });
        //         $("#" + UUID).closest(".gm-style .gm-style-iw-d").css({
        //             'overflow': 'unset',
        //             'margin-bottom': '12px',
        //             'margin-right': '12px',
        //         });
        //         // }.bind(isIndividual));
        //     });
        //     // infowindow.open(this.map, marker);

        // exception for home marker
        if (labelFont.text !== "-") {
            marker.addListener('click', function () {
                var index = parseInt(labelFont.text);
                var cookies = this.props.cookies;
                cookies.set('swiper_active_index', index - 1);

                try {
                    const active_site = this.props.wayPointsOptions[index - 1].infoWindowObj.siteData.site
                    this.map.panTo({
                        lat: parseFloat(active_site.latitude),
                        lng: parseFloat(active_site.longitude)
                    })
                    this.state.swiperChildRef.slideTo(index - 1); // array index 0
                } catch (e) {
                    console.log("marker.addListener('click' error: ", e)
                }

                //         if(this.activeInfoWindow) {
                //             this.activeInfoWindow.close();
                //         }
                //         infowindow.open(this.map, marker);
                //         this.activeInfoWindow = infowindow;
            }.bind(this));
        }
        // }
    }

    // radians(n) {
    //     return n * (Math.PI / 180);
    // }

    // degrees(n) {
    //     return n * (180 / Math.PI);
    // }

    // getBearing(startLat,startLong,endLat,endLong) {
    //     startLat = this.radians(startLat);
    //     startLong = this.radians(startLong);
    //     endLat = this.radians(endLat);
    //     endLong = this.radians(endLong);

    //     var dLong = endLong - startLong;

    //     var dPhi = Math.log(Math.tan(endLat/2.0+Math.PI/4.0)/Math.tan(startLat/2.0+Math.PI/4.0));
    //     if (Math.abs(dLong) > Math.PI){
    //         if (dLong > 0.0)
    //             dLong = -(2.0 * Math.PI - dLong);
    //         else
    //             dLong = (2.0 * Math.PI + dLong);
    //     }

    //     return (this.degrees(Math.atan2(dLong, dPhi)) + 360.0) % 360.0;
    // }

    createPolyLine(pathSegment, strokeOptions = {
        type: "solid",
        color: "black",
        opacity: 1,
        weight: 1,
    }) {
        // // console.log(type);
        switch (strokeOptions.type) {
            case "dashed":
                var lineSymbol = {
                    path: 'M 0,-1 0,1',
                    strokeOpacity: strokeOptions.opacity,//1,
                    scale: strokeOptions.weight
                };

                new window.google.maps.Polyline({
                    path: pathSegment,
                    strokeColor: strokeOptions.color, //'#707070',
                    strokeOpacity: 0,
                    icons: [{
                        icon: lineSymbol,
                        offset: '0',
                        repeat: '20px'
                    }],
                    map: this.map
                });
                break;

            case "dotted":
                // ***** Dotted
                var borderDottedSymbol = {
                    path: window.google.maps.SymbolPath.CIRCLE,
                    fillOpacity: 1, // strokeOptions.opacity,//1,
                    fillColor: strokeOptions.color,
                    scale: strokeOptions.weight,
                    strokeColor: 'white',//'#707070',
                    strokeOpacity: 1,
                    strokeWeight: 2
                };
                // var dottedSymbol = {
                //     path: window.google.maps.SymbolPath.CIRCLE,
                //     fillOpacity: 1, // strokeOptions.opacity,//1,
                //     scale: 3, // strokeOptions.weight,
                //     fillColor: strokeOptions.color
                // };

                new window.google.maps.Polyline({
                    path: pathSegment,
                    fillOpacity: 0,
                    strokeOpacity: 0,
                    icons: [{
                        icon: borderDottedSymbol,
                        offset: '0',
                        repeat: '10px', //'13px'
                    }
                        // ,{
                        //     icon: dottedSymbol,
                        //     offset: '0',
                        //     repeat: '10px', //'13px'
                        // }
                    ],
                    map: this.map
                });
                // var border = new window.google.maps.Polyline({
                //     path: pathSegment,
                //     strokeColor: 'black', // border color
                //     strokeOpacity: 1.0,
                //     strokeWeight: 7 // You can change the border weight here
                // });

                // var line = new window.google.maps.Polyline({
                //     path: pathSegment,
                //     strokeColor: 'green',
                //     strokeOpacity: 1.0,
                //     strokeWeight: 4
                // });
                // border.setMap(this.map)
                // line.setMap(this.map)
                break;
            case "solid":
                // ***** Solid Line
                new window.google.maps.Polyline({
                    path: pathSegment,
                    strokeColor: strokeOptions.color,//'#707070',
                    strokeOpacity: strokeOptions.opacity,//1,
                    strokeWeight: strokeOptions.weight,
                    map: this.map
                });
                break;
            default:
                // console.log("invalid path segment type");
                break;
        }
    }

    calculateAndDisplayRoute(directionsService, directionsDisplay) {
        this.totalMeters = 0;
        this.totalMetersDriven = 0;

        directionsService.route({
            origin: this.start,
            destination: this.end,
            waypoints: this.waypts,
            optimizeWaypoints: false,
            travelMode: 'DRIVING'
        }, function (response, status) {
            if (status === 'OK') {
                directionsDisplay.setDirections(response);
                // // console.log(response);

                // var bounds = new window.google.maps.LatLngBounds();
                var route = response.routes[0];
                var legs = route.legs;
                var solid_lineSymbol = true;
                for (var i = 0; i < legs.length; i++) {
                    // var routeSegment = i + 1;
                    // var summaryPanel = '';
                    // summaryPanel += 'Route Segment: ' + routeSegment + ': ';
                    // summaryPanel += route.legs[i].start_address + ' to ';
                    // summaryPanel += route.legs[i].end_address + '..... distance: ';
                    // summaryPanel += route.legs[i].distance.text;
                    // // console.log(summaryPanel);
                    if (this.wayptsOptions[i] === undefined) {
                        // console.log("Google Map operation was cancelled...(Don't worry everything is gonna be fine)");
                        return;
                    }
                    // catch undefined destination on last site
                    if (i < legs.length - 1) {
                        switch (this.wayptsOptions[i].type) {
                            case 'car':
                                // // console.log("calculating midpoint for car location...");
                                // this.addCarMarker(this.waypts[i].location);
                                break;
                            case 'site':
                                this.addSiteMarker(this.waypts[i].location, this.wayptsOptions[i].icon, this.wayptsOptions[i].labelFonts, this.wayptsOptions[i].infoWindowObj);
                                break;
                            case 'star':
                                // this.addStarMarker(this.waypts[i].location, this.wayptsOptions[i].icon, this.wayptsOptions[i].labelFonts);
                                break;
                            default:
                                // console.log("invalid marker type");
                                break;
                        }

                        this.totalMeters += legs[i].distance.value;
                        var mid_distance = legs[i].distance.value / 2;
                        var steps = legs[i].steps;
                        var current_step_distance = 0;
                        var phlebs_loc_plotted = false;

                        for (var j = 0; j < steps.length; j++) {
                            current_step_distance += steps[j].distance.value;
                            var nextSegment = steps[j].path;

                            // if phleb loc && next site is not active OR if it is last site
                            if (this.wayptsOptions[i].type === 'car' && this.wayptsOptions[i + 1].icon !== 'active') {
                                // && (['site','star'].includes(this.wayptsOptions[i + 1].type)) // removed because there is no site of lab location
                                // console.log("this.wayptsOptions[i].type: " + this.wayptsOptions[i].type)
                                // console.log("this.wayptsOptions[i + 1].icon: " + this.wayptsOptions[i + 1].icon)

                                // ********** ACCURATE MID DISTANCE of step                                
                                // console.log("all: " + nextSegment.length);
                                // console.log(beforePlot)
                                // console.log(afterPlot)
                                // console.log("beforePlot: " + beforePlot.length);
                                // console.log("afterPlot: " + afterPlot.length);

                                if (current_step_distance > mid_distance && !phlebs_loc_plotted) {

                                    phlebs_loc_plotted = true;
                                    this.addCarMarker(steps[j]);

                                    // split segment in half, draw solid line before car and dashed line after car
                                    // need two copies, to overlap the middle record
                                    // e.g.: first 1,2,3, 3,4,5
                                    var nextSegmentCopy1 = nextSegment.map((x) => x);
                                    var nextSegmentCopy2 = nextSegment.map((x) => x);
                                    var half_length = Math.ceil(nextSegmentCopy1.length / 2);
                                    var beforePlot = nextSegmentCopy1.splice(0, half_length + 1);
                                    var afterPlot = nextSegmentCopy2.splice(-half_length);

                                    // console.log(beforePlot)
                                    // console.log(afterPlot)

                                    // console.log("phlebs loc not plotted: solid before lot")
                                    this.createSegmetLine("solid", beforePlot);

                                    // console.log("phlebs loc plotted: dashed after lot")
                                    this.createSegmetLine("dotted", afterPlot);
                                } else {
                                    if (!phlebs_loc_plotted) {
                                        this.totalMetersDriven += steps[j].distance.value;
                                        this.createSegmetLine("solid", nextSegment);
                                    } else {
                                        this.createSegmetLine("dotted", nextSegment);
                                    }
                                }
                            } else {

                                // after the phlebs loc is plotted, path line should be dashed
                                if (solid_lineSymbol) {
                                    // console.log("not car and !ACTIVE: solid")
                                    this.totalMetersDriven += steps[j].distance.value;
                                    this.createSegmetLine("solid", nextSegment);
                                } else {
                                    // console.log("not car and !ACTIVE: dashed")
                                    this.createSegmetLine("dotted", nextSegment);
                                }
                            }
                        }

                        // when phlebs location found, change path line symbol
                        if (this.wayptsOptions[i].type === "car") {
                            solid_lineSymbol = false;
                        }
                    }
                }
            } else {
                // console.log('Directions request failed due to ' + status);
            }

            var meters = this.totalMeters / 1609.34;
            var metersDriven = this.totalMetersDriven / 1609.34;

            this.triggerResponseHandler({
                "metersDriven": metersDriven,
                "totalMetersDriven": meters,
                "percentage": Math.round(metersDriven / meters * 100)
            });
        }.bind(this));
    }

    createSegmetLine(type = "solid", nextSegment) {
        if (type === "solid") {
            this.createPolyLine(nextSegment, {
                type: "solid",
                color: "#2680EB", // '#707070',
                opacity: 1,//.8,
                weight: 2
            });
        } else {
            this.createPolyLine(nextSegment, {
                type: "dotted",
                color: "#2680EB", // '#707070',//'#000000',
                opacity: 1,
                weight: 2
            });
        }
    }

    generateID() {
        return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
    }

    loadSwiperRefToParent = (swiperRef) => {
        this.setState({ ...this.state, swiperChildRef: swiperRef });
    }

    onSlideChange = (slides) => {
        console.log("this.props.wayPointsOptions[slides]")
        console.log(this.props.wayPointsOptions[slides.activeIndex])
        try {
            const active_site = this.props.wayPointsOptions[slides.activeIndex].infoWindowObj.siteData.site
            this.map.panTo({
                lat: parseFloat(active_site.latitude),
                lng: parseFloat(active_site.longitude)
            })
        } catch (e) {
            console.log("onSlideChange error: ", e)
        }
    }

    render() {
        // console.log("GoogleMapComponent");
        // console.log(this.props);
        const { cookies, classes, showHomeMarker, showHomeMarkerLabel, zoom, center, home, start, end, wayPoints, wayPointsOptions } = this.props;
        this.showHomeMarker = showHomeMarker;
        this.showHomeMarkerLabel = showHomeMarkerLabel;
        this.zoom = zoom;
        this.center = center;
        this.home = home;
        this.start = start;
        this.end = end;
        this.waypts = wayPoints;
        this.wayptsOptions = wayPointsOptions;
        const active_slide_index = cookies.get('swiper_active_index') || 0;
        // console.log(wayPointsOptions[active_slide_index])
        // console.log(wayPointsOptions[active_slide_index])

        var swiper_slide_data = [];
        for (const wayPoint of wayPointsOptions) {
            if (wayPoint.infoWindowObj) {
                swiper_slide_data.push(wayPoint.infoWindowObj.siteData);
            }
        }

        // console.log("active_slide_index: " + active_slide_index)
        return (
            <div className={classes.fullHeight}>
                <div id={"googleMap_" + this.randID}></div>
                <div className={classes.zoomControlContainer}>
                    <IconButton
                        className={classes.zoomButtons}
                        aria-label="home-re-center"
                        onClick={() => this.map.panTo(this.home)}
                        color="secondary"
                    >
                        <GpsFixedIcon
                            className={classes.zoomButtonIcons}
                        />
                    </IconButton>
                    <IconButton
                        className={classes.zoomButtons}
                        aria-label="zoom-in"
                        onClick={() => {
                            // current zoom level
                            // optimize zooming, re declare zoom value after it has been modified
                            // by preserveViewport
                            var current_zoom = this.zoom
                            if(this.zoom !== this.map.getZoom()) {
                                current_zoom = this.map.getZoom()
                            }
                            // console.log("current zoom level: ", this.map.getZoom())
                            this.zoom = current_zoom + 1
                            this.map.setZoom(this.zoom)
                        }}
                        color="secondary"
                    >
                        <AddIcon
                            className={classes.zoomButtonIcons}
                        />
                    </IconButton>
                    <IconButton
                        className={classes.zoomButtons}
                        aria-label="zoom-out"
                        onClick={() => {
                            var current_zoom = this.zoom
                            if(this.zoom !== this.map.getZoom()) {
                                current_zoom = this.map.getZoom()
                            }
                            this.zoom = current_zoom - 1
                            this.map.setZoom(this.zoom)
                        }}
                        color="secondary"
                    >
                        <RemoveIcon
                            className={classes.zoomButtonIcons}
                        />
                    </IconButton>
                </div>
                <SwiperSliderComponent
                    sitesData={swiper_slide_data}
                    initialActiveSlide={active_slide_index}
                    loadSwiperRefToParent={this.loadSwiperRefToParent}
                    onSlideChange={this.onSlideChange}
                ></SwiperSliderComponent>
            </div>
        );
    }
}

GoogleMapComponent.propTypes = {
    showHomeMarker: PropTypes.bool.isRequired, // not in use
    center: PropTypes.object.isRequired,
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired,
    wayPoints: PropTypes.array.isRequired,
    wayPointsOptions: PropTypes.array.isRequired,
    zoom: PropTypes.number.isRequired,
    milesDrivenPercentageResponseHandler: PropTypes.func.isRequired,
    // style: PropTypes.object.isRequired,
};

export default withCookies(withStyles(styles)(GoogleMapComponent));