import * as globals from 'components/common/GlobalConstants';
import grey from '@material-ui/core/colors/grey';

const styles = theme => ({
    root: {
        // display: 'flex',
        // fullWidth: true
        margin: "0 !important",
        padding: "0 !important"
        
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
    },
    a: {
        boxSizing: 'border-box',
        minWidth: '10em'
    },
    headerInfo: {
      paddingTop: "15px",
      paddingLeft: "15px",
      paddingRight: "15px",
    },
    patientContainer: {
        paddingTop: "15px",
        paddingLeft: "15px",
        paddingRight: "15px",
    },
    overrideBlue: {
        background: globals.BLUE_GRADIENT_BG,
        color: "#fff",
    },
    overrideButtonBlue: {
        background: globals.LIGHT_BLUE_GRADIENT_BG,
        color: "#01579B",
    },
    avatar: {
        margin: 'auto',
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
        width: 100,
        height: 100,
        fontSize: 50,
    },
    grid_item: {
        padding: theme.spacing.unit,
    },
    margins: {
        // margin: theme.spacing.unit * 2.5,
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    warning_icon: {
        top: 50,
    },
    group: {
        margin: `${theme.spacing.unit}px 0`
    },
    swipeableTab: {
        backgroundColor: grey[50],
    },
    badge: {
        padding: `0 ${theme.spacing.unit * 2}px`,
    },
    progressBar: {
      height: 17,
    },
    // container: {
    //     display: 'flex',
    //     flexWrap: 'wrap',
    // },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 300,
    },
    table: {
        marginTop: `${theme.spacing.unit * 2.5}px auto`,
    },
    tableRow: {
        border: 'none'
    },
    patientInfoGrid: {
        textAlign: "left",
        padding: theme.spacing.unit,
    },
    icon: {
        lineHeight: 1.2,
        fontSize: 20,
    },
    textCentered: {
        textAlign: "center"
    },
    yellowPaperBG: {
        background: "#FFFBE7",
    },
    STAT: {
        fontSize: '11px !important',
        color: 'white',
        backgroundColor: '#D20815',
        display: 'inline',
        padding: `2px 8px`,
        borderRadius: 3,
        marginRight: 5,
    },
    FASTING: {
        fontSize: '11px !important',
        color: 'white',
        backgroundColor: '#0000FF',
        display: 'inline',
        padding: `2px 8px`,
        borderRadius: 3
    },
    buttons: {
        marginLeft: 4,
        textTransform: 'none',
        borderRadius: "4px",
        padding: "10px 16px",
        fontSize: "14px !important",
        backgroundColor: "white",
        color: "black",
        fontWeight: 600,
        "&:focus": { backgroundColor: "white !important" },
        "&:active": { backgroundColor: "white !important" },
        "&:hover": { backgroundColor: "white !important" },
        "&:disabled": { backgroundColor: "white !important" },
        "&:disabled span": { color: globals.v2.COLORS.TEXT_DISABLED + " !important" },
    },
    mainButton: {
        fontSize: 18,
        padding: '10px 15px',
        backgroundColor: "#68CBEF",
        textTransform: 'none',
        borderRadius: 0,
        display: "block",
        color: "#fff",
        margin: `${theme.spacing.unit * 2.5}px auto`,
    },
    drawPatientTitle: {
        fontSize: 14,
    },
    resetButton: {
        textTransform: 'none',
        fontSize: 14,
        borderRadius: 0,
        padding: '8px 12px',
    },
    statusNotes: {
        fontSize: '14px !important',
    },
    tabContainer: {
        // padding: `${theme.spacing.unit}px 0px`,
        padding: '0px  !important'
    },
    gridContainer: {
        marginTop: 43,
        marginBottom: 25,
    },
    patientIdentityText: {
        fontSize: '14px !important',
        padding: `${theme.spacing.unit}px 0px`,
    },
    drawItemText: {
        fontSize: '12px !important',
        // fontWeight: 'bold',
        padding: `${theme.spacing.unit}px 0px`,
    },
    patientIdentityTab: {
        fontSize: '12px !important',
        fontWeight: 'bold'
        // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit}px`,
        // paddingLeft: '0px',
        // paddingRight: '0px',
    },
    patientIdentityBirthdateCellCont: {
        padding: '0px',
        border: "none",
    },
    patientIdentityCellCont: {
        padding: `0px`,
        border: "none",
    },
    patientIdentityLastCellCont: {
        paddingRight: `0px !important`,
    },
    tabLabel: {
        fontSize: '12px !important',
    },
    dialogPaper: {
        minWidth: "90%",
        margin: 10, // 18
    },
    dialogPaperForNotes:  {
        minWidth: "90%",
        backgroundColor: "black"
        // margin: 2, // 18

    },
    radioButton: {
      padding: "6px 12px",
    },
    patientAddress: {
        fontSize: '12px !important',
        width: '50%',
        float: 'left'
    },
    attachmentButton: {
        color : "#0277bd !important",
        padding: 0,
        fontSize: '14px !important',
        textAlign: 'left',
        marginBottom: "10px" 
    },
    attachmentIcon: {
        fontSize: '16px !important',
        marginRight: 5,
        // -ms-transform: rotate(20deg)
        // -webkit-transform: rotate(20deg)
        transform: "rotate(30deg)"
    }, 
    notesIcon: {
        fontSize: '16px !important',
        marginRight: 5,
        // -ms-transform: rotate(20deg)
        // -webkit-transform: rotate(20deg)
        //transform: "rotate(30deg)"
    },
    btnClosePDF: {
        zIndex: 999999,
        position: "absolute",
        right: 5,
        top: 5,
        padding: 0,
        minWidth: 30,
        textTransform: 'none'
    },
    drawPatientRemarksField: {
        border: "1px solid #E6E6E6 !important",
        margin: "0"
    },
    txtAddBarcode: {
        border: "1px solid gray"
    },
    listContainer: {
        padding: "0 !important",
        margin: "0 !important"
    },
    btnAddPhoto: {
        backgroundColor: "#68CBEF",
        borderRadius: 0,
        width: '100%',
        margin: '15px 0px',
        // display: 'flex',
        // margin: '0px auto'
    },
    btnAddPhotoIcon: {
        marginRight: 10
    },
    barcodeValue: {
        fontSize: "18px !important"
    },
    requisitionList: {
        backgroundColor: 'rgb(247, 247, 247)',
        paddingLeft: 15,
        marginBottom: 5,
        fontSize: "14px !important"
    },
    clearButton: {
        color: '#9999998f',
        padding: 5,
        marginTop: 5,
    },
    photoCaptureDialogContent: {
        padding: '0px !important',
        overflow: "unset",
        flex: '1'
    },
    photoCaptureDialogActions: {
        // flex: '0 0 auto'
        backgroundColor: 'black',
        padding: '10px 6px',
        margin: '0px !important',
    },
    pdfDialogContainer: {
        position: 'relative',
        padding: "0px !important"
    },
    imageViewer: {
        width: "100%",
        position: "absolute",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        margin: "auto"
    },
    noPhoto: {
        color: "#a9a9a9"
    },
    // btnClosePDF: {
    //     zIndex: 999999,
    //     position: "absolute",
    //     right: 5,
    //     top: 5,
    //     padding: 0,
    //     minWidth: 30,
    //     textTransform: 'none'
    // },
    closeCameraButton: {
        position: "absolute",
        left: 18,
        top: 108,
        zIndex: 2
    },
    closeIcon: {
        fontSize: "35px !important",
        fill: "white !important",
    },
    btnUsePhoto: {
        position: "absolute",
        right: 16,
        bottom: 86,
        zIndex: 2
    },
    btnDelete: {
        position: "absolute",
        left: 16,
        bottom: 86,
        zIndex: 2,
        backgroundColor: "transparent",
        "&:focus": { backgroundColor: "transparent !important" },
        "&:active": { backgroundColor: "transparent !important" },
        "&:hover": { backgroundColor: "transparent !important" },
        "&:disabled": { backgroundColor: "transparent !important" },
        "& span": {
            color: "#E38080 !important"
        }
    },
    btnRetake: {
        position: "absolute",
        right: 16,
        bottom: 86,
        zIndex: 2,
        backgroundColor: "transparent",
        "&:focus": { backgroundColor: "transparent !important" },
        "&:active": { backgroundColor: "transparent !important" },
        "&:hover": { backgroundColor: "transparent !important" },
        "&:disabled": { backgroundColor: "transparent !important" },
        "& span": {
            color: "white !important"
        }
    },
    btnTakeAgain: {
        position: "absolute",
        left: 16,
        bottom: 86,
        zIndex: 2,
        backgroundColor: "transparent",
        "&:focus": { backgroundColor: "transparent !important" },
        "&:active": { backgroundColor: "transparent !important" },
        "&:hover": { backgroundColor: "transparent !important" },
        "&:disabled": { backgroundColor: "transparent !important" },
        "& span": {
            color: "white !important"
        }
    }
});

export default styles;
