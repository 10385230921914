import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
// import classNames from 'classnames'

import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'

const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const StepDescriptionFunctionClass = (props) => {
    const { classes, step, description } = props

    return (
        <>
            <div className={classes.descriptionStepContainer}>
                <Avatar className={classes.descriptionStepLabel}>{step}</Avatar>
            </div>
            <div className={classes.descriptionLabelContainer}>
                <Typography className={classes.descriptionLabel}>{description}</Typography>
            </div>
        </>
    )
}

const StepDescription = withRouter(
    withCookies(
        connect(mapStateToProps)(
            withStyles(styles, { withTheme: true })(StepDescriptionFunctionClass)
        )
    )
)
export default StepDescription
