import { createStore, applyMiddleware } from "redux";
import rootReducer from "../reducers/index";
// import PouchDB from 'pouchdb';
import thunk from 'redux-thunk';
var middleware = [thunk];


const store = createStore( rootReducer, applyMiddleware(...middleware));
//const store = createStore(rootReducer);

export default store;

// const database_name = 'lab';
// const db = new PouchDB(database_name, {auto_compaction: true});
// const remoteCouch = 'https://todo_user:secret_todo_user@dev.telopoint.org:6984/' + database_name;

// db.changes({
//         since: 'now',
//         live: true
//     })
//     .on('change', showData)
//     .on('error', function (err) {
//     console.log("err: db.changes...");
//     console.log(err);
// });

// function showData() {
//     db.allDocs({
//         include_docs: true, 
//         descending: true,
//         attachments: true
//     }, function(err, doc) {
//         if(err) {
//             console.log('showData error:');
//             console.log(err);
//         }
//         console.log(doc);
//     });
// }

// showData();

