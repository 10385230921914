import * as globals from 'components/common/GlobalConstants';

const styles = theme => ({
    root: {
        // display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
    },
    overrideBlue: {
        background: globals.BLUE_GRADIENT_BG,
    },
    greatJobIcon: {
      fontSize: 80,
      margin: theme.spacing.unit * 2,
      color: "#12D003",
      border: "5px solid #12D003",
      borderRadius: "50%",
    },
    centered: {
      textAlign: 'center',
    },
    doneGrayshade: {
        color: "#bdbdbd",
    },
    avatarGrayshadeBG: {
        color: '#fafafa',
        backgroundColor: "#bdbdbd",
    },
    progressBar: {
        marginTop: 6.46,
        marginBottom: 5.65,
        borderRadius: 16,
        height: 18,
        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
    },
    progressPercentageText: {
        fontSize: "14px !important",
        // fontWeight: "bold !important"
    },
    allowanceTopBottom: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
    },
    allowanceTop: {
      marginTop: theme.spacing.unit * 3,
    },
    allowanceBottom: {
      marginBottom: theme.spacing.unit * 3,
    },
    overallProgress: {
        fontSize: '16px !important',
        // fontWeight: "600 !important",
    },
    progressContainer: {
        marginBottom: 10,
    },
    linearColorPrimary: {
        backgroundColor: "white",//"#f1f8e9",
    },
    //hijacked inline style, b'coz LinearProgress bar is using transform scalex, so border radius is a problem
    linearBarColorPrimary: {
        backgroundColor: "#12D003",
        borderRadius: 30,
    },
    allSitesNote: {
        fontSize: '28px !important',
        marginTop: theme.spacing.unit * 4.5
    },
    filters: {
        color: "#fff !important",
        padding: "3px 3px !important",
        margin: "2px",
        // marginRight: "15px",
        // display: "inline",
        fontSize: "14px !important",
        minHeight: '0',
        minWidth: '0',
        fontWeight: "normal",
        textTransform: "none",
        // display: 'inline-block'
    },
    filterView: {  
        paddingLeft: '18px !important',
    },
    filterSelectionDiv: {
        display: 'inline-flex',
        width: '100%',
        justifyContent: 'space-around'
    },
    filterContainerTableCell: {
        width: "100%",
        height: "18px !important",
        padding: "0px !important",
        border: 'none !important'
    },
    filterContainer: {
        display: 'inline-flex',
        width: '100%',
        justifyContent: 'space-around',
        backgroundColor: "#707070",
        color: "white",
        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
        marginBottom: 16
        // marginRight: 4, 
        // width: "73.5%",
        // float: "left"
    },
    // editRouteButtonContainer: {
    //     width: "19%", 
    //     float:"right", 
    //     backgroundColor: "#FBFBFB",
    //     textAlign: "center"
    // },
    siteContainer: {
        position: "relative",
        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
        marginBottom: 16,        
        paddingRight: "7px !important",
        padding: 14
    },
    editRouteTableCell: {
        backgroundColor: "#fff",
        padding: 0,
    },
    editRoute: {
        color: "#707070 !important",
        padding: "3px 3px !important",
        display: "inline",
        fontSize: "10px !important",
        minHeight: '0',
        minWidth: '0',
        fontWeight: "normal",
        textTransform: "none",
        textAlign: 'center',
        width: "100%",
        whiteSpace: 'nowrap',
    },
    selectedFilter: {
      color: "#fff",
      fontWeight: "bold"
    },
    hackAdjustment: {
      paddingLeft: 15,
    },
    tableRow: {
        height: '18px !important',
        width: "100%"
    },
    tableHeader: {
        height: '18px !important',
        width: "100%"
    },
    tableCellHeader: {
        padding: "4px 0px !important",
        paddingRight: "4px !important",
        backgroundColor: "white",//"#68CBEF",
        color: "#000 !important",
        fontSize: '10px !important',
        whiteSpace: 'nowrap', 
        textAlign: 'center',
        minHeight: '0',
    },
    lastTableCellHeader: {
        paddingRight: "0px !important",
    },
    nowrap: {
        whiteSpace: 'nowrap',
    },
    tableCell: {
        padding: '0px !important',
    },
    sitesSortableCell: {
        padding: "0px !important"  
    },
    sitesTableCell: {
        border: 'none !important'
    },
    lastTableCell: {
      paddingRight: "14px !important",
    },
    statusCompleted: {
        fontWeight: "bold",
        color: "#12D003 !important",
    },
    myTable: {
        // boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
    },
    fullWidth: {
        width: "100%"
    },
    headerInfo: {
        paddingTop: "15px",
        paddingLeft: "30px",
        paddingRight: "30px",
    },
    siteListContainer: {
        padding: "15px 30px",
    },
    listItemRoot: {
        padding: "0px !important",
    },
    schedulesMainHeader: {
        color: "#707070 !important",
        fontSize: '24px !important',
        fontWeight: 'bold',
        padding: "0px !important",
        margin: "0px !important"
    },
    siteName: {
        fontSize: "18px !important",
        fontWeight: 'bold',
        color: '#707070'
    },
    siteAddress: {
        fontSize: "12px !important",
    },
    siteStatus: {
        fontSize: "12px !important",
    },
    siteDraws: {
        fontSize: "12px !important",
        fontWeight: "bold",
        color: "#68CBEF !important"
    },
    checkCircle: {
        color: "#12D003",
        fontSize: 20,
        display: 'inline-block',
        verticalAlign: 'middle',
        marginLeft: 5,
    },
    withSTAT: {
        backgroundColor: '#FEDC4F'
    },
    badges: {
        fontSize: 10,
        padding: `${theme.spacing.unit * .2}px ${theme.spacing.unit * .2}px`,
        marginRight: "4px",
        marginTop: "2px",
        marginBottom: "4px",
        width: 8, 
        height: 8,
        // border: "2px solid white",
        borderRadius: "50%",
    },
    STAT: {
        color: '#000',
      //  backgroundColor: '#FAD94E',#D20815
        backgroundColor: '#D20815',
        textTransform: 200
    },
    FASTING: {
        color: '#000',
        backgroundColor: '#0000FF',
    },
    siteRowCompleted: {
        backgroundColor: '#D0D0D0',
        // textShadow: '.1px .6px #FAFAFA'
    },
    badgesContainer: {
        display: "table-cell",
        verticalAlign: "top",
        minWidth: 18, 
    },
    sitesTableRow: {
        // display: "table",
        // width: "100%"
    },
    siteInfo: {
        display: "table-cell",
        verticalAlign: "top",
        paddingLeft: 5,
        paddingRight: 15,
        width: "100%" // just a hack for now
    },
    optionsContainer: {
        display: "table-cell",
        verticalAlign: "middle",
        padding: "10px 10px"
    },
    optionsIcon: {
        width: 25,
        height: 25
    },
    pdfRed: {
        color: "#ff6961"//"#ff0000"
    },
    siteType: {
        color: "white !important",
        padding: "2px 5px",
        display: "inline",
        fontSize: "11px !important",
        borderRadius: "3px",
        backgroundColor: "#69CEF1",
        textTransform: "capitalize"
    },
    patientCounterPerSite: {
        marginLeft: 7,
        fontSize: "12px !important",
        color: '#707070',
        display: 'inline',
        whiteSpace: 'nowrap'
    },
    fullHeight: {
        height: '100%'
    },
    forFinalApproval: {
        position: "absolute",
        bottom: 14,
        right: 14,
        fontSize: "12px !important"
    }
});

export default styles;