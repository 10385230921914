import React, { useState, useEffect } from 'react'

import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
import classNames from 'classnames'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import ApplicationBar from 'components/common/workflow/ApplicationBar'

import Typography from '@material-ui/core/Typography'
import Title from './Title'
import Fields from './Fields'
import Footer from './Footer'
import ConfirmDialog from 'components/common/mobile/ConfirmDialog'

import ImmutableJS from "immutable"
import * as globals from 'components/common/GlobalConstants'
// import moment from 'moment'

// import { } from "js/actions/index"

const mapDispatchToProps = dispatch => {
    return {}
}

const mapStateToProps = state => {
    return {
        // drawData: state.drawData,
        scheduleData: state.scheduleData,
        onlineStatus: state.onlineStatus
    }
}

const AddScheduleFunctionClass = (props) => {
    const {
        scheduleData,
        history,
        onlineStatus,
        classes,
        match,
    } = props

    const [workScheduleData, setWorkScheduleData] = useState(null)
    const { schedule_id } = match.params
    // const [canSave, setCanSave] = useState(true)

    const [showEditScheduleDialog, setShowEditScheduleDialog] = useState(false)
    const [confirmEditDialogAttr, setConfirmEditDialogAttr] = useState({
        editScheduleActionHandler: "",
        editSchedulePostActionHandler: "",
        confirmEditScheduleDialogTitle: "",
        confirmEditScheduleDialogMessage: "",
        confirmEditScheduleDialogActionCancelText: "",
        confirmEditScheduleDialogActionText: "",
    })

    const canSave = true

    const consolidateScheduleData = (schedule_data) => {
        var schedule_data_copy = ImmutableJS.fromJS(schedule_data).toJS()
        var consolidatedScheduleData = []

        schedule_data_copy.schedules.forEach(schedule => {
            // if (!schedule.client_deleted) {
            if (globals.v2.SCHEDULE.REPEAT_TYPE_TO_DISPLAY.includes(schedule.repeat_type)) {
                schedule.section = "schedules"
                schedule.section_original = schedule.section
                consolidatedScheduleData.push(schedule)
            }
            // }
        })

        return consolidatedScheduleData
    }

    useEffect(() => {
        if (scheduleData) {
            var localScheduleData = ImmutableJS.fromJS(scheduleData).toJS()

            const scheduleId = schedule_id
            var schedule = consolidateScheduleData(localScheduleData).find(schedule => schedule.id === scheduleId)

            setWorkScheduleData(schedule)
        }
    }, [scheduleData, schedule_id])

    const goBackToWorkSchedule = () => {
        history.push("/work-schedule")
    }

    const editWorkSchedule = () => {
        history.push("/work-schedule/manage/" + workScheduleData.id + "/" + workScheduleData.date_from)
    }

    return (
        <>
            <div className={classes.root}>
                <main className={classes.content}>
                    <div className={classNames(classes.mainContainer)}>
                        <ApplicationBar />

                        <Title workScheduleData={workScheduleData} />

                        {!onlineStatus &&
                            <Typography className={classes.onlineOnlyWarning}>
                                Editing of Schedule when OFF-LINE is NOT allowed.
                            </Typography>
                        }
                        {workScheduleData && workScheduleData.client_deleted &&
                            <Typography className={classes.onlineOnlyWarning}>
                                Schedule is client deleted.
                            </Typography>
                        }

                        <Fields
                            workScheduleData={workScheduleData}
                        />

                        <Footer
                            canContinueSave={canSave}
                            onClickCancel={goBackToWorkSchedule}
                            // onClickEdit={editWorkSchedule}
                            onClickEdit={() => {
                                setConfirmEditDialogAttr({
                                    editScheduleActionHandler: () => setShowEditScheduleDialog(false),
                                    editSchedulePostActionHandler: () => setShowEditScheduleDialog(false),
                                    confirmEditScheduleDialogTitle: "Change Work Schedule",
                                    confirmEditScheduleDialogMessage: <>
                                        A manager must approve your work schedule change. Please contact management to continue.
                                    </>,
                                    confirmEditScheduleDialogActionCancelText: "",
                                    confirmEditScheduleDialogActionText: "I Understand"
                                })
                                setShowEditScheduleDialog(true)
                            }}
                        />

                        <ConfirmDialog
                            type="warning"
                            visible={showEditScheduleDialog}
                            title={confirmEditDialogAttr.confirmEditScheduleDialogTitle}
                            message={confirmEditDialogAttr.confirmEditScheduleDialogMessage}
                            actionText={confirmEditDialogAttr.confirmEditScheduleDialogActionText}
                            actionCancelText={confirmEditDialogAttr.confirmEditScheduleDialogActionCancelText}
                            actionHandler={confirmEditDialogAttr.editScheduleActionHandler}
                            postActionHandler={confirmEditDialogAttr.editSchedulePostActionHandler}
                            onClose={() => {
                                setTimeout(() => {
                                    setShowEditScheduleDialog(false)
                                }, 100)
                            }}
                        />
                    </div>
                </main>
            </div >
        </>
    )
}

const AddSchedule = withCookies(
    connect(mapStateToProps, mapDispatchToProps)(
        withStyles(styles, { withTheme: true })(AddScheduleFunctionClass)
    )
)
export default AddSchedule
