import * as globals from 'components/common/GlobalConstants';

const styles = theme => ({
    root: {
        // display: 'flex',
        paddingTop: "25px !important",
        paddingLeft: "40px !important",
        paddingRight: "40px !important",
        paddingBottom: "40px !important",
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
    },
    overrideBlue: {
        background: globals.BLUE_GRADIENT_BG,
    },
    greatJobIcon: {
      fontSize: 80,
      margin: theme.spacing.unit * 2,
      color: "#12D003",
      border: "5px solid #12D003",
      borderRadius: "50%",
    },
    centered: {
      textAlign: 'center',
    },
    doneGrayshade: {
        color: "#bdbdbd",
    },
    avatarGrayshadeBG: {
        color: '#fafafa',
        backgroundColor: "#bdbdbd",
    },
    progressBar: {
        borderRadius: 20,
        height: 34,
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 30px 0 rgba(0, 0, 0, 0.11)',
    },
    allowanceTopBottom: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
    },
    allowanceTop: {
      marginTop: '47px', // theme.spacing.unit * 3,
    },
    allowanceBottom: {
      marginBottom: theme.spacing.unit * 3,
    },
    overallProgress: {
        fontFamily: "Helvetica",
        fontSize: '32px !important',
    },
    progressContainer: {
        marginBottom: theme.spacing.unit * 5.5,
    },
    linearColorPrimary: {
        backgroundColor: "white",//"#f1f8e9",
    },
    //hijacked inline style, b'coz LinearProgress bar is using transform scalex, so border radius is a problem
    linearBarColorPrimary: {
        backgroundColor: "#12D003",
        borderRadius: 30,
    },
    allSitesNote: {
        fontSize: '32px !important',
        marginTop: theme.spacing.unit * 4.5
    },
    filters: {
      color: "#656565c9",
    },
    selectedFilter: {
      color: "#0277bd",
    },
    hackAdjustment: {
      paddingLeft: 15,
    },
    tableRow: {
    //   boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 30px 0 rgba(0, 0, 0, 0.11)',
    //   marginBottom: 20,
    },
    nowrap: {
      whiteSpace: 'nowrap',
    },
    tableCell: {
      padding: 14,
    },
    lastTableCell: {
      paddingRight: "14px !important",
    },
    statusCompleted: {
        fontWeight: "bold",
        color: "#12D003 !important",
    },
    myTable: {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 30px 0 rgba(0, 0, 0, 0.11)',
    }
});

export default styles;