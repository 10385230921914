import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const mapDispatchToProps = dispatch => { return { } }

const mapStateToProps = state => { return { } }

const TitleFunctionClass = (props) => {
    const {
        // drawData,
        // history,
        classes,
        selectedSchedule
    } = props

    return (
        <>
            <div className={classes.titleContainer}>
                <Grid
                    container
                    alignItems="center"
                    justify="space-between"
                >
                    <div>
                        <Typography className={classes.title}>{selectedSchedule.id === "0" ? "Add" : "Edit"} Work Schedule</Typography>
                    </div>
                </Grid>
            </div>
        </>
    )
}

const Title = withCookies(
    connect(mapStateToProps, mapDispatchToProps)(
        withStyles(styles, { withTheme: true })(TitleFunctionClass)
    )
)
export default Title
