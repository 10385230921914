import React, {Component} from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import $ from 'jquery';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import { connect } from "react-redux";

import SignaturePad from 'react-signature-pad-wrapper';

import Icon from '@material-ui/core/Icon';

import styles from "./styles.js"
import { Divider } from '@material-ui/core';
import { updatePatientDrawStatus, updatePatientRoomNumber, mpSign } from "js/actions/index";

import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import ImmutableJS from "immutable";

import ApplicationBar from 'components/common/tablet/ApplicationBar';
import SimpleBottomNavigation from 'components/common/tablet/SimpleBottomNavigation';
import EnhancedTableHead from "components/common/tablet/EnhancedTableHead.js";
import NoDrawListScreen from 'components/common/tablet/NoDrawListScreen.js';
import CurrentDate from 'components/common/tablet/CurrentDate.js';
import SnackBarPopup from 'components/common/tablet/SnackBarPopup.js';

const mapDispatchToProps = dispatch => {
  return {
    updatePatientDrawStatus: drawItem => dispatch(updatePatientDrawStatus(drawItem)),
    mpSign: signData => dispatch(mpSign(signData)),
    updatePatientRoomNumber: patient => dispatch(updatePatientRoomNumber(patient)),
  };
};

const mapStateToProps = state => {
  return { drawData: state.drawData }
};

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class SiteClass extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  

  constructor(props) {
    super(props);
    const { cookies } = this.props;
    
    this.state = {
      ...this.state,
      siteId : "",
      selectedPatient: "",
      selectedSkipReason: "",
      initialApprovalDialogOpened: false,
      finalApprovalDialogOpened: false,
      skipReasonDialogOpened: false,
      // signatureWarningOpened: false,
      editRoomNumberDialogOpened: false,
      // signatureFinalWarningOpened: false,
      skipReasonWarningOpened: false,
      // initialApprovingNameEmpty: false,
      // finalApprovingNameEmpty: false,      
      order: cookies.get('drawsOrder') ? cookies.get('drawsOrder') : 'asc',
      orderBy: cookies.get('drawsOrderBy') ? cookies.get('drawsOrderBy') : 'patient_room_number',
      filteredBy: cookies.get('selectedFilter') ? cookies.get('selectedFilter') : "filter_all",
      patientRoomNumber: 1,
      snackbarOpened: false,
      snackbarMessage: "",
      snackbarVariant: "default",
    };
  }

  componentDidMount() {
    // console.log('Site did mount.');

    this.convertProgressBarTransformIntoWidth();
    const siteId = this.props.match.params.siteId;
    const { cookies } = this.props;
    //cookies.set('selected_site', siteId, { path: '/', maxAge: 57600 });
    cookies.set('selected_site', siteId, { path: '/'});
  }

  // when page refreshed
  componentDidUpdate() {
    this.convertProgressBarTransformIntoWidth();
  }

  componentWillMount() {
    this.setState({...this.state, 
                   siteId: this.props.match.params.siteId });
  };

  convertProgressBarTransformIntoWidth() {
    // force reactjs to use percentage width instead of scalex to support border radius
    var percentage_cont = $("#draw-progress");
    var percentage_el = $("#draw-progress div:first-child");
    var percentage = percentage_cont.attr("aria-valuenow");

    percentage_el.css({
      'transform': 'none',
      'width': percentage + "%",
    });
  }

  handleOpenInitialApprovalDialog = () => {
    this.setState({...this.state, initialApprovalDialogOpened: true }); // , signatureWarningOpened: false
  };

  handleCloseInitialApprovalDialog = () => {
    this.setState({...this.state, initialApprovalDialogOpened: false });
  };

  handleOpenFinalApprovalDialog = () => {
    this.setState({...this.state, finalApprovalDialogOpened: true }); // , signatureFinalWarningOpened: false
  };

  handleCloseFinalApprovalDialog = () => {
    this.setState({...this.state, finalApprovalDialogOpened: false });
  };

  handleOpenSkipReasonDialog(patient) {
    this.setState({...this.state, selectedPatient: patient, skipReasonDialogOpened: true, skipReasonWarningOpened: false });
  };

  handleCloseSkipReasonDialog = () => {
    this.setState({...this.state, skipReasonDialogOpened: false });
  };

  handleOpenEditRoomNumberDialog(patient) {
    this.setState({...this.state, selectedPatient: patient, patientRoomNumber: patient.patient_room_number, editRoomNumberDialogOpened: true });
  };

  selectPatientRoomNumber = () => {
    this.patientRoomNumberInput.focus();
    this.patientRoomNumberInput.select();
  };

  handleCloseEditRoomNumberDialog = () => {
    this.setState({...this.state, editRoomNumberDialogOpened: false });
  };

  handleChangePatientRoomNumber = () => event => {
    this.setState({...this.state, patientRoomNumber: event.target.value});
  }

  handleRequestSort = (event, property) => {
    const { cookies } = this.props;
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    cookies.set('drawsOrder', order, { path: '/'});
    cookies.set('drawsOrderBy', orderBy, { path: '/'});
    this.setState({...this.state, order, orderBy });
  };

  handleClearApprovalSignature = () => {
    // $("#name-of-initial-approving-nurse").val("");
    this.signaturePad.clear();
    
    // this.setState({
    //   ...this.state, 
    //   signatureWarningOpened: false,      
    //   initialApprovingNameEmpty: false
    // });
  };

  handleSubmitApprovalSignature = event => {
    var name = $("#name-of-initial-approving-nurse").val();

    if(name === "") {
      // this.setState({
      //   ...this.state, 
      //   initialApprovingNameEmpty: true
      // });

      this.handleOpenSnackbar("danger", "Name is Required.");
    } else {
      if(this.signaturePad.isEmpty()) {
        // this.setState({
        //   ...this.state, 
        //   signatureWarningOpened: true
        // });
        
        this.handleOpenSnackbar("danger", "Signature is Required.");
      } else {
        const payload = {
          "siteId": this.state.siteId ,
          "first_name": name, 
          "last_name": "",
          "type": "initial",
          //"signature": this.signaturePad.toDataURL()
          "signature": this.signaturePad.toData()
        };
    
        this.props.mpSign(payload);
        this.handleCloseInitialApprovalDialog();
        this.convertProgressBarTransformIntoWidth();
      }
    }
  };

  handleClearFinalApprovalSignature = () => {
    // $("#name-of-final-approving-nurse").val("");
    this.signaturePadFinal.clear();
    
    // this.setState({
    //   ...this.state, 
    //   signatureFinalWarningOpened: false,
    //   finalApprovingNameEmpty: false
    // });
  };

  handleSubmitFinalApprovalSignature = event => {
    var name = $("#name-of-final-approving-nurse").val();

    if(name === "") {
      // this.setState({
      //   ...this.state, 
      //   finalApprovingNameEmpty: true
      // });
      
      this.handleOpenSnackbar("danger", "Name is Required.");
    } else {
      if(this.signaturePadFinal.isEmpty()) {
        // this.setState({
        //   ...this.state, 
        //   signatureFinalWarningOpened: true
        // });

        this.handleOpenSnackbar("danger", "Signature is Required.");
      } else {
        const payload = {
          "siteId": this.state.siteId ,
          "first_name": name, 
          "last_name": "",
          "type": "final",
          "signature": this.signaturePadFinal.toData()
        };
    
        this.props.mpSign(payload);
        this.handleCloseFinalApprovalDialog();
      }
    }
  };

  handleChangeSkipReason = event => {
    this.setState({ ...this.state, selectedSkipReason: event.target.value, skipReasonWarningOpened: false });
  };

  handleSubmitSkipReason = event => {
    var skipped_reason_jQuery = $('#skip-reason-others').val();

    if(this.state.selectedSkipReason === "") {
      this.setState({ ...this.state, skipReasonWarningOpened: true});
    } else {
      if(this.state.selectedSkipReason === "others") {
        if(skipped_reason_jQuery === "") {
          this.setState({ ...this.state, skipReasonWarningOpened: true});
        } else {
          this.submitSkipReason(skipped_reason_jQuery);
        }
      } else {
        this.submitSkipReason(this.state.selectedSkipReason);
      }
    }
  };

  handleSubmitPatientRoomNumber = event => {
    var room_number = $('#patient-room-number').val();
    
    if(room_number === "" ) {
      this.handleOpenSnackbar("danger", "Invalid Room Number.");
    } else {
      const payload = {
        "siteId": this.state.siteId ,
        "patientId":  this.state.selectedPatient.id, 
        "roomNumber": room_number,
      };
  
      console.log('handleSubmitPatientRoomNumber');
      console.log(payload);
      this.props.updatePatientRoomNumber(payload);
      this.setState({...this.state, editRoomNumberDialogOpened: false});
    }
  };

  handleChangeFilter = event => {
    const { cookies } = this.props;
    cookies.set('selectedFilter', event.currentTarget.id, { path: '/'});

    this.setState({...this.state, filteredBy: event.currentTarget.id });
  };

  submitSkipReason(skip_reason) {
    const payload = {
      "siteId": this.state.siteId ,
      "patientId":  this.state.selectedPatient.id, 
      "patientDrawId": this.state.selectedPatient.patient_draw[0].id,
      "type": "m", 
      "status":  "skipped",
      "notes": skip_reason
    };

    // console.log('submitSkipReason');
    // console.log(payload);
    this.props.updatePatientDrawStatus(payload);
    this.setState({...this.state, skipReasonDialogOpened: false});
  }

  goToPatient(url) {
    this.props.history.push(url);
  }

  showNurseApprovalSnackBar = () => {
    this.handleOpenSnackbar("warning", "Needs nurse approval first...");
  }
  
  handleOpenSnackbar = (variant="default", message) => {
    this.setState({...this.state, snackbarOpened: true, snackbarVariant: variant, snackbarMessage: message});
  }
  
  handleCloseSnackbar = () => {
    this.setState({...this.state, snackbarOpened: false});
  }

  render() {
    const { classes } = this.props;
    const drawData = this.props.drawData;    
    const { selectedPatient, order, orderBy, skipReasonWarningOpened } = this.state;
    // const selectedSkipReason = selectedPatient.patient_draw[0].status_notes_m;
    var showFinalApprovalButton = false;
         
    if($.isEmptyObject(drawData.draw_list)) {
      return(<NoDrawListScreen />);
    }

    const testItemList = function(patient) {
      const patientDraw = patient.patient_draw[0].draw_test.map(function(drawItem, i) {
        var delimiter = (patient.patient_draw[0].draw_test.length - 1 > i) ? ", " : "";
        return drawItem.test.name + delimiter; //<Typography 
                  // className={classNames(patient.patient_draw[0].status !== "remaining" ? classes.rowDone : "")}
                  // variant="caption" 
                  //key={drawItem.test.id}>
                  //</Typography>
      });

      return patientDraw;
    };

    const PatientItem = ({patient, site}) => (
      <TableRow 
        key={patient.id}>
        <TableCell 
          onClick={() => {(patient.patient_draw[0].draw_sign_on.son_first_name !== "") ? 
                  this.goToPatient(`/patient/${patient.id}/${site.id}`) : 
                  this.showNurseApprovalSnackBar()}}
          className={classNames(classes.tableCell)} 
          component="th"
          scope="row">
            <Typography 
              variant="body1">
              {patient.patient.last_name + ", " + patient.patient.first_name + " " + patient.patient.middle_name}
            </Typography>
            { patient.urgent &&
              <Typography 
                className={classes.STAT}
                variant="button">STAT</Typography>
            }
        </TableCell>
        <TableCell 
          onClick={() => {(patient.patient_draw[0].draw_sign_on.son_first_name !== "") ? 
                  this.goToPatient(`/patient/${patient.id}/${site.id}`) :  
                  this.showNurseApprovalSnackBar()}}
          className={classNames(classes.tableCell, classes.centered)}>
            {(patient.fasting)
            ? 
            <Typography><Icon>check</Icon></Typography>
            :""}
          </TableCell>
        <TableCell 
          onClick={() => {(patient.patient_draw[0].draw_sign_on.son_first_name !== "") ? 
                  this.goToPatient(`/patient/${patient.id}/${site.id}`) :  
                  this.showNurseApprovalSnackBar()}}
          className={classNames(classes.tableCell)}>
            <Typography>{testItemList(patient)}</Typography>
        </TableCell>
        <TableCell 
          onClick={() => this.handleOpenEditRoomNumberDialog(patient)}
          // onClick={() => {(patient.patient_draw[0].draw_sign_on.son_first_name !== "") ? 
          //         this.goToPatient(`/patient/${patient.id}/${site.id}`) : 
          //         console.log('need approval first...')}}
          className={classNames(classes.tableCell, classes.centered, classes.btnRoomNumber)}>
            <Typography className={classes.lblRoomNumber} noWrap>
              {patient.patient_room_number} <Icon className={classes.icon}>edit</Icon>
            </Typography>
          </TableCell>
        <TableCell 
          onClick={() => {(patient.patient_draw[0].draw_sign_on.son_first_name === "" && 
                          patient.patient_draw[0].status === "remaining") ?
                  this.handleOpenSkipReasonDialog(patient) : 
                  console.log('already skipped OR can skip before approval only...')}} 
          className={
            classNames(classes.tableCell, classes.centered, classes.lastTableCell,
              (patient.patient_draw[0].draw_sign_on.son_first_name === "" && 
                patient.patient_draw[0].status === "remaining") && 
                classes.skipButton)}
        >
          { (patient.patient_draw[0].draw_sign_on.son_first_name === "" && patient.patient_draw[0].status === "remaining")
          ?
            (<Typography className={classes.skipButtonLabel}>Skip</Typography>)
          // (<Button onClick={this.handleOpenSkipReasonDialog(patient)} variant="contained" size="medium" color="secondary">Skip</Button>)
          :
            patient.patient_draw[0].status === "done"
            ?
            (<Typography className={classes.doneStatus}>Done</Typography>)
            :
            (<Typography className={classes.deferredStatus}>{
              ["remaining"].indexOf(patient.patient_draw[0].status) === -1
              ? 
                (patient.patient_draw[0].status === "refused") ? "Unable" : patient.patient_draw[0].status.charAt(0).toUpperCase() + patient.patient_draw[0].status.slice(1)
              : 
                ""
              }</Typography>)
          }
        </TableCell>
        {/* <TableCell className={classNames(classes.tableCell)}></TableCell> */}
      </TableRow>
    );

    const siteId = this.props.match.params.siteId;
    // save the siteid in the cookie here


    const theSite = drawData.draw_list.filter(function(site) {
       return(site.id === siteId); 
    });

    var patientDone = 0;
    var totalPatients = 0;
    const siteInfo = theSite[0].site;
    const siteAddress = siteInfo.address + ", " + siteInfo.state + " " + siteInfo.zipcode;
    const patients = theSite[0].patient_list;  

    const patientsInjectedWithKeysForSorting = stableSort(patients, getSorting(order, orderBy)).map(patient => {
      var immuPatient = ImmutableJS.fromJS(patient).toJS();
      
      var drawItem = patient.patient_draw[0];
      if(drawItem.status === "done" || drawItem.status === "completed") {
        patientDone++;
      }
      if(drawItem.status === "skipped" || drawItem.status === "refused" || drawItem.status === "unable") {;
        patientDone++;
      }

      totalPatients++;
      
      // will be used just for sorting
      // keys that was used under row_headers
      immuPatient.patient_name = immuPatient.patient.last_name + ", " + immuPatient.patient.first_name + " " + immuPatient.patient.middle_name;
      immuPatient.tests = immuPatient.patient_draw[0].draw_test[0].test.name;
      immuPatient.status = immuPatient.patient_draw[0].status;

      return immuPatient;
    });
    
    const myPatients = stableSort(patientsInjectedWithKeysForSorting, getSorting(order, orderBy)).map(patient => {      
      var drawItem = patient.patient_draw[0];
      var el = "";
      
      if(drawItem.status === "done" || drawItem.status === "completed") {
        if(["filter_all", "filter_completed"].indexOf(this.state.filteredBy) > -1) {
          el = <PatientItem key={patient.id} patient={patient} site={theSite[0]} />;
        }
      }

      // if(drawItem.draw_sign_off.sof_first_name === "") {
      //   showFinalApprovalButton = true;
      // }

      if(drawItem.status === "skipped" || drawItem.status === "refused" || drawItem.status === "unable") {
        if((drawItem.status === "refused" || drawItem.status === "unable") && drawItem.draw_sign_off.sof_first_name === "") {
          showFinalApprovalButton = true;
        }
        
        if(["filter_all", "filter_deferred"].indexOf(this.state.filteredBy) > -1) {
          el = <PatientItem key={patient.id} patient={patient} site={theSite[0]} />;
        }
      }
      if(drawItem.status === "pending" || drawItem.status === "" || drawItem.status==="remaining") {
        if(["filter_all", "filter_remaining"].indexOf(this.state.filteredBy) > -1) {
          el = <PatientItem key={patient.id} patient={patient} site={theSite[0]} />;
        }
      }

      return(el);
    });

    var filteredMyPatients = myPatients.filter(function (el) {
      return el !== "";
    });

    var percentage = Math.round(patientDone/totalPatients * 100);
    showFinalApprovalButton = (
      patients[0].patient_draw[0].draw_sign_on.son_first_name !== "" &&
      percentage === 100 && 
      showFinalApprovalButton) ? true : false;

    const row_headers = [
      // { id: 'patieht_avatar', numeric: false, sortingEnabled: false, label: '' },
      { id: 'patient_name', numeric: false, sortingEnabled: true, label: 'Patient Name' },
      { id: 'fasting', numeric: false, sortingEnabled: true, label: 'Fasting' },
      { id: 'tests', numeric: false, sortingEnabled: true, label: 'Tests' },
      { id: 'patient_room_number', numeric: false, sortingEnabled: true,  label: 'Room' },
      { id: 'status', numeric: false, sortingEnabled: true, label: 'Status' },
      // { id: 'padding', numeric: false, sortingEnabled: false, label: '' },
    ];

    const nurseNameInputProps = {
      className: classes.resizeTextField
    };

    return(
      <div className={classes.root}>
          <main className={classes.content}>
              <ApplicationBar back="/sites" icon="home" title={siteInfo.name} />

                <Typography className={classes.siteAddress}>{siteAddress}</Typography> 
                <CurrentDate textAlign="right" />

                { patients[0].patient_draw[0].draw_sign_on.son_first_name === "" &&
                  <ListItem 
                    className={classes.obtainApprovalMessageContainer}>
                    <Icon className={classes.obtainApprovalIcon}>error_outline</Icon>
                    <ListItemText
                      primary="Please obtain approval from facility nurse to begin draws."
                      classes={{
                        primary: classes.obtainApprovalMessage
                      }}
                    />
                  </ListItem>
                }

                { percentage === 100 && patients[0].patient_draw[0].draw_sign_off.sof_first_name !== "" && 
                  <div className={classes.finalApprovalContainer}>
                    <Typography>Name of approving nurse [Final]:</Typography>
                    <Typography>{patients[0].patient_draw[0].draw_sign_off.sof_first_name}</Typography>
                  </div>
                }

                { patients[0].patient_draw[0].draw_sign_on.son_first_name !== "" &&
                  <div className={classes.initialApprovalContainer}>
                    <Typography>Name of approving nurse [Initial]:</Typography>    
                    <Typography>{patients[0].patient_draw[0].draw_sign_on.son_first_name}</Typography>
                  </div>           
                }
                
                { patients[0].patient_draw[0].draw_sign_on.son_first_name !== "" &&
                  <div className={classNames(classes.progressContainer)}>
                    <Typography 
                      className={classNames(classes.allowanceTop, classes.numberOfDraws)}
                      align="left"
                      variant="body1">
                      Draw Progress
                    </Typography>

                    <LinearProgress 
                      id="draw-progress"
                      className={classNames(
                        classes.allowanceTopBottom, 
                        classes.progressBar,
                      )}
                      classes={{
                        colorPrimary: classes.linearColorPrimary,
                        barColorPrimary: classes.linearBarColorPrimary
                      }}
                      variant="determinate"
                      value={percentage} />
                    
                    <Typography
                      align="right">
                      {patientDone}/{totalPatients} ({percentage}% Complete)</Typography>
                  </div>
                }
                
                <Paper>
                  <div className={classNames(classes.paddings)}>
                    <Button
                      disabled={true}
                      className={classNames(classes.filters)}>
                      Filter By:</Button>
                    <Button
                      id="filter_all"
                      className={classNames(classes.filters, this.state.filteredBy === "filter_all" ? classes.selectedFilter : "")}
                      onClick={this.handleChangeFilter}>
                      All</Button>
                    <Button
                      id="filter_completed"
                      className={classNames(classes.filters, this.state.filteredBy === "filter_completed" ? classes.selectedFilter : "")}
                      onClick={this.handleChangeFilter}>
                      Completed</Button>
                    <Button
                      id="filter_deferred"
                      className={classNames(classes.filters, this.state.filteredBy === "filter_deferred" ? classes.selectedFilter : "")}
                      onClick={this.handleChangeFilter}>
                      Deferred</Button>
                    <Button
                      id="filter_remaining"
                      className={classNames(classes.filters, this.state.filteredBy === "filter_remaining" ? classes.selectedFilter : "")}
                      onClick={this.handleChangeFilter}>
                      Remaining</Button>
                  </div>
                  <Divider light/>
                  <Table className={classes.myTable}>
                    <EnhancedTableHead
                      row_headers={row_headers}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={this.handleRequestSort}
                    />
                    <TableBody>
                      {filteredMyPatients.length === 0
                      ?
                        <TableRow className={classes.tableRow}>
                          <TableCell 
                            className={classNames(classes.tableCell, classes.centered)} 
                            colSpan={5}>No data to show.</TableCell>
                        </TableRow>
                      : 
                      filteredMyPatients
                      }
                    </TableBody>
                  </Table>
                </Paper>
                
                <div
                  className={classNames(classes.centered, classes.bottomButtonsContainer)}>
                  { (patients[0].patient_draw[0].draw_sign_on.son_first_name === "" || patients[0].patient_draw[0].draw_sign_on.son_first_name === null) &&
                    <Button
                        onClick={() => {this.handleOpenInitialApprovalDialog();}}
                        variant="contained"
                        size="large" 
                        color="primary"
                        className={classNames(classes.allowanceTop, classes.approvalButton, classes.margins)}>
                          Initial Approval
                      </Button>
                  }

                  { showFinalApprovalButton &&
                    <Button
                        onClick={() => {this.handleOpenFinalApprovalDialog();}}
                        variant="contained"
                        size="large" 
                        color="primary"
                        className={classNames(classes.allowanceTop, classes.approvalButton, classes.margins)}>
                          Nurse Final Approval
                      </Button>
                  }

                  { (patients[0].patient_draw[0].draw_sign_on.son_first_name !== "" && percentage === 100 && !showFinalApprovalButton) &&
                    <div>
                      <Typography variant="h5">Site Draws Completed!</Typography>
                      {/* <Icon className={classes.greatJobIcon}>check_circle</Icon> */}
                      <Link to={'/sites'}>
                        <Button 
                          className={classNames(classes.allowanceTop, classes.approvalButton)}
                          variant="contained" 
                          color="primary">Done</Button></Link>
                    </div>
                  }
                </div>
              
              <div className={classes.toolbar} />
              <div className={classes.toolbar} />
          </main>
          <SimpleBottomNavigation />

          <Dialog
            id="initial-nurse-approval-dialog"
            fullScreen={false}
            open={this.state.initialApprovalDialogOpened}
            >
            <DialogContent>  
                <DialogContentText align="center">
                  Please sign below to acknowledge that you have reviewed and approved the INITIAL draw list today:
                </DialogContentText>
                <TextField
                  required={true}
                  className={classNames(classes.approvingNurseTextField, classes.allowanceBottom)}
                  inputProps={nurseNameInputProps}
                  id="name-of-initial-approving-nurse"
                  label="Name of Approving Nurse [Initial]"
                  margin="normal"
                  />
                {/* { this.state.initialApprovingNameEmpty && <Typography color="error">** Name is required.</Typography>} */}
                
                <Divider light={true} />
                <SignaturePad ref={ref => this.signaturePad = ref} />
                <Divider light={true} />
                <br />

                {/* { signatureWarningOpened && <Typography className={classes.signaturePopover} color="error">** Signature is required.</Typography>} */}
                <Button onClick={this.handleClearApprovalSignature} className={classNames(classes.signatureButton)} variant="contained" color="default">Clear</Button>
                <Button onClick={this.handleSubmitApprovalSignature} className={classNames(classes.signatureButton)} variant="contained" color="secondary">Submit</Button> 
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseInitialApprovalDialog} className={classNames(classes.buttons)} variant="contained" color="default">Close</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            id="final-nurse-approval-dialog"
            fullScreen={false}
            open={this.state.finalApprovalDialogOpened}
            >
            <DialogContent>  
                <DialogContentText align="center">
                  Please sign below to acknowledge that you have reviewed and approved the FINAL draw list today:
                </DialogContentText>
                <TextField
                  required={true}
                  className={classNames(classes.approvingNurseTextField, classes.allowanceBottom)}
                  inputProps={nurseNameInputProps}
                  id="name-of-final-approving-nurse"
                  label="Name of Approving Nurse [Final]"
                  margin="normal"
                  />
                {/* { this.state.finalApprovingNameEmpty && <Typography color="error">** Name is required.</Typography>} */}
                
                <Divider light={true} />
                <SignaturePad ref={ref => this.signaturePadFinal = ref} />
                <Divider light={true} />
                <br />

                {/* { signatureFinalWarningOpened && <Typography className={classes.signaturePopover} color="error">** Signature is required.</Typography>} */}
                <Button onClick={this.handleClearFinalApprovalSignature} className={classNames(classes.signatureButton)} variant="contained">Clear</Button>
                <Button onClick={this.handleSubmitFinalApprovalSignature} className={classNames(classes.signatureButton)} variant="contained" color="secondary">Submit</Button> 
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseFinalApprovalDialog} className={classNames(classes.buttons)} variant="contained">Close</Button>
            </DialogActions>
          </Dialog>
          
          <Dialog
            id="skip-patient-reason-dialog"
            fullScreen={false}
            open={this.state.skipReasonDialogOpened}
            // onClose={this.handleCloseSkipReasonDialog}
            // onEnter={this.handleOnShowDialog}
            aria-labelledby="responsive-dialog-title"
            >
            <DialogTitle id="responsive-dialog-title">Patient Skip Reason</DialogTitle>
            <DialogContent>
              {/* <FormControl component="fieldset"> */}
                <FormLabel component="legend" style={{color: "rgba(0, 0, 0, 0.87)"}}>
                  Please select an option for skipping patient "{selectedPatient !== "" && 
                    selectedPatient.patient.last_name + ", " + selectedPatient.patient.first_name + " " + selectedPatient.patient.middle_name}":
                  </FormLabel><br/>
                <RadioGroup
                    aria-label="Please select an option." 
                    name="skip_reason_option"
                    value={this.state.selectedSkipReason}
                    onChange={this.handleChangeSkipReason}
                >
                    <FormControlLabel value="ate_prior" control={<Radio className={classes.radioButton} />} label="Ate Prior" />
                    <FormControlLabel value="not_well" control={<Radio className={classes.radioButton} />} label="Not Well" />
                    <FormControlLabel value="visitors" control={<Radio  className={classes.radioButton}/>} label="Visitors" />
                    <FormControlLabel value="deceased" control={<Radio className={classes.radioButton} />} label="Deceased" />
                    <FormControlLabel value="others" control={<Radio className={classes.radioButton} />} label="Others" />
                </RadioGroup>
                { this.state.selectedSkipReason === "others" &&
                <TextField
                    id="skip-reason-others"
                    label="Please specify other reason..."
                    multiline
                    rows="4"
                    margin="normal"
                    className={classes.specifyReasonTextArea}
                    defaultValue={this.state.selectedSkipReason === "others" ? this.state.selectedPatient.patient_draw[0].status_notes_m : ""}
                    />
                }

                { skipReasonWarningOpened && <Typography className={classes.signaturePopover} color="error">** Skip Reason is required.</Typography>}
              {/* </FormControl> */}
              <Button onClick={this.handleSubmitSkipReason} className={classNames(classes.buttons)} variant="contained" color="secondary">Submit</Button>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseSkipReasonDialog} className={classNames(classes.buttons)} variant="contained">Close</Button>
            </DialogActions>
          </Dialog>
          
          <Dialog
            id="edit-patient-room-number-dialog"
            fullScreen={false}
            open={this.state.editRoomNumberDialogOpened}
            onEntered={this.selectPatientRoomNumber}
            aria-labelledby="responsive-dialog-title"
            >
            <DialogTitle id="responsive-dialog-title">Edit Patient Room Number</DialogTitle>
            <DialogContent>
              <FormControl component="fieldset">
                <FormLabel component="legend" style={{color: "rgba(0, 0, 0, 0.87)"}}>
                  Please enter/change Room Number of "{selectedPatient !== "" && 
                    selectedPatient.patient.last_name + ", " + selectedPatient.patient.first_name + " " + selectedPatient.patient.middle_name}":
                  </FormLabel>
                  <TextField
                    id="patient-room-number"
                    inputRef={input => { this.patientRoomNumberInput = input; }}
                    value={this.state.patientRoomNumber}
                    onChange={this.handleChangePatientRoomNumber()}
                    type="number"
                    InputProps={{
                      classes: {
                        input: classes.textFieldRoomNumber,
                      },
                    }}
                    margin="normal"
                    // autoFocus
                  />
              </FormControl>
              <Button onClick={this.handleSubmitPatientRoomNumber} className={classNames(classes.buttons)} variant="contained" color="secondary">Submit</Button>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseEditRoomNumberDialog} className={classNames(classes.buttons)} variant="contained">Close</Button>
            </DialogActions>
          </Dialog>

          <SnackBarPopup 
            open={this.state.snackbarOpened}
            onClose={this.handleCloseSnackbar}
            variant={this.state.snackbarVariant}
            message={<span id="snackbar">{this.state.snackbarMessage}</span>}
          ></SnackBarPopup>
      </div>
    );
  }
}

SiteClass.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
};

const Site =  withCookies( connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles)(SiteClass))));
export default Site;