// import * as globals from 'components/common/GlobalConstants'

const styles = theme => ({
    root: {
    },
    timeSlotsContainer: {
        marginBottom: "100px"
    },
    timeSlotDate: {
        fontSize: "12px !important",
        marginTop: "30px",
        marginBottom: "8px"
    },
    timeSlots: {
        backgroundColor: "#F0FAFE",
        borderRadius: "4px",
        padding: "14px 24px",
        fontSize: "15px !important",
        marginBottom: "22px"
    }
})

export default styles