import React, { useState, useEffect } from 'react'

import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
import classNames from 'classnames'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import ApplicationBar from 'components/common/workflow/ApplicationBar'

import Typography from '@material-ui/core/Typography'
import Title from './Title'
import Fields from './Fields'
import Footer from './Footer'
import ConfirmScheduleDialog from './ConfirmScheduleDialog'
import ConfirmDialog from 'components/common/mobile/ConfirmDialog'

import ImmutableJS from "immutable"
import moment from 'moment'

import * as helper from 'components/common/Helper.js'
import * as globals from 'components/common/GlobalConstants'

import {
    addSchedule,
    updateSchedules,
    deleteSchedule
} from "js/actions/index"

const mapDispatchToProps = dispatch => {
    return {
        addSchedule: schedule => dispatch(addSchedule(schedule)),
        updateSchedules: schedule => dispatch(updateSchedules(schedule)),
        deleteSchedule: schedule => dispatch(deleteSchedule(schedule)),
    }
}

const mapStateToProps = state => {
    return {
        // drawData: state.drawData,
        scheduleData: state.scheduleData,
        onlineStatus: state.onlineStatus
    }
}

const AddScheduleFunctionClass = (props) => {
    const {
        scheduleData,
        history,
        classes,
        match,
        onlineStatus,
        addSchedule,
        updateSchedules,
        deleteSchedule
    } = props

    const [workScheduleData, setWorkScheduleData] = useState([])
    const [selectedSchedule, setSelectedSchedule] = useState([])
    const [isLocked, setIsLocked] = useState(false)
    const [isLockedPastSchedules, setIsLockedPastSchedules] = useState(false)
    const { schedule_id, work_schedule_date, work_schedule_time  } = match.params
    const [confirmScheduleDialogOpen, setConfirmScheduleDialogOpen] = useState(false)
    const [canSave, setCanSave] = useState(true)
    const repeatOptions = scheduleData.settings ? scheduleData.settings.repeat_options : []

    const [showDeleteScheduleDialog, setShowDeleteScheduleDialog] = useState(false)
    const [confirmDeleteDialogAttr, setConfirmDeleteDialogAttr] = useState({
        deleteScheduleActionHandler: "",
        deleteSchedulePostActionHandler: "",
        confirmDeleteScheduleDialogTitle: "",
        confirmDeleteScheduleDialogMessage: "",
        confirmDeleteScheduleDialogActionCancelText: "",
        confirmDeleteScheduleDialogActionText: "",
    })

    // console.log("onlineStatus",onlineStatus)

    const editingNotAllowed = () => {
        return !onlineStatus || isLocked || isLockedPastSchedules || selectedSchedule.client_deleted
    }

    const deletingNotAllowed = () => {
        // isLocked=true - can be deleted but notify clinician
        return !onlineStatus || isLockedPastSchedules || selectedSchedule.client_deleted
    }

    const consolidateScheduleData = (schedule_data) => {
        var schedule_data_copy = ImmutableJS.fromJS(schedule_data).toJS()
        var consolidatedScheduleData = []

        // console.log("schedule_data")
        // console.log(schedule_data)

        schedule_data_copy.schedules.forEach(schedule => {
            // if (!schedule.client_deleted) {
            // REAL: must not be embedded
            if (globals.v2.SCHEDULE.REPEAT_TYPE_TO_DISPLAY.includes(schedule.repeat_type)) {
                schedule.section = "schedules"
                schedule.section_original = schedule.section
                consolidatedScheduleData.push(schedule)
            }
            // }
        })

        return consolidatedScheduleData
    }

    useEffect(() => {
        if (scheduleData) {
            var localScheduleData = ImmutableJS.fromJS(scheduleData).toJS()

            const scheduleId = schedule_id
            const selectedDate = work_schedule_date
            // will be used for edit
            var schedule = consolidateScheduleData(localScheduleData).find(schedule => schedule.id === scheduleId)

            if (schedule === undefined) {
                schedule = {
                    "section": "schedules",
                    "section_original": "schedules",
                    "id": "0",
                    "order": 0,
                    "type": "custom",
                    "label": "",
                    "date_from": moment(selectedDate, "YYYY-MM-DD"),
                    "date_to": moment(selectedDate, "YYYY-MM-DD"),
                    "all_day": false,
                    "time_from": moment(work_schedule_time, "HH:mm").format("HH:mm") + ":00", // "08:00:00",
                    "time_to": moment(work_schedule_time, "HH:mm").add(1, "hours").format("HH:mm") + ":00", // "08:30:00",
                    "client_timezone": moment.tz.guess(true) || "America/New_York",
                    "days": [moment(selectedDate, "YYYY-MM-DD").format('dddd')],
                    "repeat_type": null,
                    "repeat_interval": null,
                    "client_deleted": false,
                    "locked": false,
                    "locked_past_schedule": false
                }
            } else {
                // var date_now = moment().format("YYYY-MM-DD")
                // var date_to = schedule.date_to
                // var is_past_date = moment(date_to, "YYYY-MM-DD").isBefore(date_now)
                // isLocked = is_past_date
                setIsLocked(schedule.locked)
                setIsLockedPastSchedules(schedule.locked_past_schedule)
            }

            setSelectedSchedule(schedule)
        }
    }, [scheduleData])

    const goBackToWorkSchedule = () => {
        // console.log("scheduleData")
        // console.log(scheduleData)
        history.push("/work-schedule")
    }

    const saveWorkSchedule = () => {
        if (selectedSchedule.id === "0") { // New schedule 
            workScheduleData.client_deleted = false
            // prevent adding new schedule if behind today
            var date_from = moment(workScheduleData.date_from, "YYYY-MM-DD")
            if (editingNotAllowed()) {
                console.log("WARNING: editing not allowed...")
                return
            }
            // already blocked in UI, but should add popup warning
            // schedule cannot be added because date_from isBefore now
            if (date_from.isBefore(moment().format("YYYY-MM-DD"))) {
                // console.log("date from: ", date_from)
                // console.log("date now: ", moment())
                console.log("WARNING: schedule cannot be added because date_from isBefore now...")
                return
            }
            // console.log("addSchedule workScheduleData")
            // console.log(workScheduleData)
            addSchedule(workScheduleData)
        } else { // Update schedule 
            // console.log("updateSchedules workScheduleData")
            // console.log(workScheduleData)
            updateSchedules(workScheduleData)
        }
        setConfirmScheduleDialogOpen(false)
        goBackToWorkSchedule()
    }

    const onDeleteSchedule = () => {
        var immuSelectedSchedule = ImmutableJS.fromJS(selectedSchedule).toJS();
        deleteSchedule(immuSelectedSchedule)
        history.push("/work-schedule")
    }

    const prepareWorkScheduleData = (data) => {
        var processedSelectedSchedule = ImmutableJS.fromJS(selectedSchedule).toJS()
        processedSelectedSchedule.section = "schedules"
        processedSelectedSchedule.category = "workDay"
        processedSelectedSchedule.type = data.repeat_type ? "recurring" : "custom" // if repeat type == null, value is custom

        var date_from = moment(data.date_from, "dddd, MMM DD, YYYY").format('YYYY-MM-DD')
        var date_to = moment(data.date_to, "dddd, MMM DD, YYYY").format('YYYY-MM-DD')
        processedSelectedSchedule.date_from = date_from//.format('L')
        processedSelectedSchedule.date_to = data.repeat_type ? date_to : date_from//.format('L')
        processedSelectedSchedule.time_from = moment(data.time_from, "hh:mm A").format("HH:mm:ss")
        processedSelectedSchedule.time_to = moment(data.time_to, "hh:mm A").format("HH:mm:ss")
        processedSelectedSchedule.repeat_interval = data.repeat_type === "every_n_week" ? data.week_number : null

        var days = data.selected_days ? data.selected_days.map(day => { return helper.capitalize(day) }) : []
        // remove duplicate
        days = days.reduce(function (a, b) { if (a.indexOf(b) < 0) a.push(b); return a; }, [])

        processedSelectedSchedule.days = days
        processedSelectedSchedule.repeat_type = data.repeat_type === "" ? null : data.repeat_type

        setWorkScheduleData(processedSelectedSchedule)
    }

    // useEffect(() => {
    // REAL: options: null, every_day, every_n_week can be saved
    // var formCanBeSaved = true
    // if (workScheduleData.repeat_type === "") {
    //     formCanBeSaved = false
    // }
    // setCanSave(formCanBeSaved)
    // }, [workScheduleData])

    return (
        <>
            <div className={classes.root}>
                <main className={classes.content}>
                    <div className={classNames(classes.mainContainer)}>
                        <ApplicationBar />

                        <Title selectedSchedule={selectedSchedule} />

                        {!onlineStatus &&
                            <Typography className={classes.onlineOnlyWarning}>
                                {selectedSchedule.id === "0" ? "Add" : "Edit"}ing of Schedule when OFF-LINE is NOT allowed.
                            </Typography>
                        }
                        {selectedSchedule.client_deleted &&
                            <Typography className={classes.onlineOnlyWarning}>
                                Schedule is client deleted.
                            </Typography>
                        }
                        <Fields
                            match={match}
                            editingNotAllowed={editingNotAllowed()}
                            deletingNotAllowed={deletingNotAllowed()}
                            selectedSchedule={selectedSchedule}
                            onChangeData={prepareWorkScheduleData}
                            repeatOptions={repeatOptions}
                        />

                        {(!deletingNotAllowed() && selectedSchedule.id !== "0") &&
                            <Typography
                                className={classes.deleteScheduleBtn}
                                onClick={() => {
                                    setConfirmDeleteDialogAttr({
                                        deleteScheduleActionHandler: () => setShowDeleteScheduleDialog(false),
                                        deleteSchedulePostActionHandler: () => setShowDeleteScheduleDialog(false),
                                        confirmDeleteScheduleDialogTitle: "Change Work Schedule",
                                        confirmDeleteScheduleDialogMessage: <>
                                            A manager must approve your work schedule change. Please contact management to continue.
                                        </>,
                                        confirmDeleteScheduleDialogActionCancelText: "",
                                        confirmDeleteScheduleDialogActionText: "I Understand"
                                    })
                                    setShowDeleteScheduleDialog(true)
                                }}
                            >
                                Delete Schedule
                            </Typography>
                            // <Typography
                            //     className={classes.deleteScheduleBtn}
                            //     onClick={() => {
                            //         setConfirmDeleteDialogAttr({
                            //             deleteScheduleActionHandler: () => onDeleteSchedule(),
                            //             deleteSchedulePostActionHandler: () => { setShowDeleteScheduleDialog(false) },
                            //             confirmDeleteScheduleDialogTitle: "Confirm Delete",
                            //             confirmDeleteScheduleDialogMessage: <>
                            //                 You are about to delete this Work Schedule.
                            //                 {selectedSchedule.locked &&
                            //                     <>
                            //                         <br />
                            //                         <br />
                            //                         <Typography className={classes.deleteLockedScheduleWarningText}>Warning: </Typography>
                            //                         {"There is an Order assigned on this day(s). Please re-assign or confirm that you are still the Provider to handle the Order."}
                            //                         <br />
                            //                         <br />
                            //                     </>
                            //                 }
                            //                 Would you like to proceed?
                            //             </>,
                            //             confirmDeleteScheduleDialogActionCancelText: "No",
                            //             confirmDeleteScheduleDialogActionText: "Yes"
                            //         })
                            //         setShowDeleteScheduleDialog(true)
                            //     }}
                            // >
                            //     Delete Schedule
                            // </Typography>
                        }
                        <Footer
                            canContinueSave={canSave && !editingNotAllowed()}
                            onClickCancel={goBackToWorkSchedule}
                            onClickSave={() => { setConfirmScheduleDialogOpen(true) }}
                        />
                    </div>

                    <ConfirmScheduleDialog
                        open={confirmScheduleDialogOpen}
                        scheduleData={workScheduleData}
                        onEdit={() => { setConfirmScheduleDialogOpen(false) }}
                        onSave={() => {
                            saveWorkSchedule()
                        }}
                    />

                    <ConfirmDialog
                        type="warning"
                        visible={showDeleteScheduleDialog}
                        title={confirmDeleteDialogAttr.confirmDeleteScheduleDialogTitle}
                        message={confirmDeleteDialogAttr.confirmDeleteScheduleDialogMessage}
                        actionText={confirmDeleteDialogAttr.confirmDeleteScheduleDialogActionText}
                        actionCancelText={confirmDeleteDialogAttr.confirmDeleteScheduleDialogActionCancelText}
                        actionHandler={confirmDeleteDialogAttr.deleteScheduleActionHandler}
                        postActionHandler={confirmDeleteDialogAttr.deleteSchedulePostActionHandler}
                        onClose={() => {
                            setTimeout(() => {
                                setShowDeleteScheduleDialog(false)
                            }, 100)
                        }}
                    />
                </main>
            </div >
        </>
    )
}

const AddSchedule = withCookies(
    connect(mapStateToProps, mapDispatchToProps)(
        withStyles(styles, { withTheme: true })(AddScheduleFunctionClass)
    )
)
export default AddSchedule
