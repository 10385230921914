export const BLUE_GRADIENT_BG = 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)'
export const PINK_GRADIENT_BG = 'linear-gradient(45deg, #ec407a 30%, #f48fb1 90%)'
// export const BLUE_GRADIENT_BG = 'linear-gradient(45deg, #03a9f4 30%, #4fc3f7 90%)'
export const LIGHT_BLUE_GRADIENT_BG = 'linear-gradient(45deg, #e3f2fd 30%, #fafafa 90%)'

// LABELS  
export const ABOUT_TITLE = 'Telopoint.'
export const ABOUT_CONTENT = 'All rights reserved © 2023.'
export const FOOTER_TEXT = 'TeloPoint, All rights reserved © 2023'

// DEBUGGING
export const DEBUG_ALLOWED = false

export const USE_PAMP_COUCHDB_SERVER = false
export const COUCHDB_ACCESS_PROTOCOL = 'https' // http for local only, must be https in production/pamp server
export const POUCHDB_AUTO_RESOLVE_CONFLICT = false

// SYNC 
export const SYNC_STATUS_VISIBLE = true
export const ALLOW_USER_TO_FORCE_SYNC = true

// LOG
export const LOG_USER_ACTIVITY = true
export const ALLOW_USER_TO_VIEW_LOGS = true // Soon to be deprecated
export const DISPLAY_LOGS_PAGE = false

export const INSTANCE = process.env.REACT_APP_INSTANCE ? process.env.REACT_APP_INSTANCE : "app"

var PORTAL_DOMAIN = "https://app.telopoint.com"
var CC_DOMAIN = "https://cc.telopoint.com"

if (INSTANCE === "etd") {
    PORTAL_DOMAIN = "https://etd-app.telopoint.com"
    CC_DOMAIN = "https://etd-cc.telopoint.com"
} else if (INSTANCE === "demo") {
    PORTAL_DOMAIN = "https://demo-app.telopoint.com"
    CC_DOMAIN = "https://demo-cc.telopoint.com"
} else if (INSTANCE === "mpm") {
    PORTAL_DOMAIN = "https://mpm-app.telopoint.com"
    CC_DOMAIN = "https://mpm-cc.telopoint.com"
}

// HOST
export const PAMP_PHLEB_APP_SERVER = 'https://telopoint.org:7005'
//export const DEPLOYMENT_SERVER = "https://cc.demo.telopoint.com"
//export const DEPLOYMENT_SERVER = "https://cc.ms.mobilecare.app"
//export const DEPLOYMENT_SERVER = "https://cc.ms.demo.telopoint.com"

// https://app.telopoint.com/accounts/password_reset/

export const DEPLOYMENT_SERVER = CC_DOMAIN // "http://cc.telopoint.local"
export const SERVER = (USE_PAMP_COUCHDB_SERVER) ? PAMP_PHLEB_APP_SERVER : DEPLOYMENT_SERVER
export const APP_LOGIN = (USE_PAMP_COUCHDB_SERVER) ? "/api/v1/login" : "/api/v1/login/"
export const LOG_SERVER = (USE_PAMP_COUCHDB_SERVER) ? "https://plogs.telopoint.org/api/v1/logs" : SERVER + "/logs/api/v1/app/"

// export const SERVER = 'https://cc.dev.telopoint.com'
// export const FORGOT_PASSWORD_LINK = DEPLOYMENT_SERVER + "/password_reset/"
export const FORGOT_PASSWORD_LINK = PORTAL_DOMAIN + "/accounts/password_reset/"
export const CHANGE_PASSWORD_LINK = PORTAL_DOMAIN + "/api/v1/password/reset/"
export const API_SERVER = PORTAL_DOMAIN

// export const PDC_PATIENT_FORM_LINK = "http://localhost:3000/demo-req/registration?order_key=438b43fc-6e10-4677-9064-d8a2f07cd908"
var VAR_PDC_PATIENT_FORM_LINK = "https://pdc.telopoint.org/patient-form/registration?order_key=27eaa98a-5ad9-4433-97af-a4c6754f6af2"
if (INSTANCE === "etd") {
    VAR_PDC_PATIENT_FORM_LINK = "https://pdc.telopoint.org/tp2-patient-form/registration?order_key=6b638e03-2fa9-409a-b863-6fcbf3e1919d"
} else if (INSTANCE === "demo") {
    VAR_PDC_PATIENT_FORM_LINK = "https://pdc.telopoint.org/demo-patient-form/registration?order_key=28aadc3e-8444-44f0-87ac-1988bf0904c4"
} else if (INSTANCE === "mpm") {
    VAR_PDC_PATIENT_FORM_LINK = "https://pdc.telopoint.org/mpm-patient-form/registration?order_key=5620a44d-0322-4e99-8ed5-872fd53dd50b"
}
export const PDC_PATIENT_FORM_LINK = VAR_PDC_PATIENT_FORM_LINK

// GLOBAL CONSTANTS COMPONENTS
// Drawlist 
export const WORK_STATUS_ACCEPTED = 'ACCEPTED'
export const WORK_STATUS_REJECTED = 'REJECTED'

export const CHANNEL_DATA = {
    "https://my.mobilecare.app": { "name": "TeloPoint", "barcode": "", "title": "Requisitions", "commandText": "Scan Barcode", "inputGuideText": "Enter Barcode" },
    "https://ntd.telopoint.com": { "name": "NTD", "barcode": "", "title": "Requisitions", "commandText": "Scan Barcode", "inputGuideText": "Enter Barcode" },
    "https://immunecyte.telopoint.com": { "name": "ImmuneCyte", "barcode": "", "title": "Requisitions", "commandText": "Scan Barcode", "inputGuideText": "Enter Barcode" }
}

export const DEFAULT_CHANNEL_URL = "https://my.mobilecare.app"

export const SCREEN_LOCK_TIMEOUT = 30 // in minutes

export const APP_VERSION = "2.3.5.3"
export const CODE_NAME = "Workflow - MPM Instance."

// Software update //
export const OFFLINE_MODE_ENABLED = false
export const UPDATE_APP_AUTOMATICALLY = true
export const POOL_UPDATE_INTERVAL = 15000 // milisecods (15 secs)

// Sync status //
export const SYNC_ERROR = "sync_error"
export const SYNCED = "synced"
export const SYNCED_UP = "synced_up"
export const SYNCED_DOWN = "synced_down"
export const UNSYNCED = "unsynced"

// Notes //
export const DEFAULT_NOTES_MAX_CHARACTERS = 360
export const DEFAULT_BARCODE_COMMENT_MAX_CHARACTERS = 360

export const FORCE_SYNC_TIMEOUT_BEFORE_ANOTHER_TRIGGER = 10000

export const LOG_SIZE = 300

export const ENCRYPT_DATA = false

export const ENCRYPT_LOGS = true

export const UPLOAD_LOGS_TO_SERVER = true

export const ADD_DIFF_DATA_IN_LOGS = false

export const ALLOW_PATIENT_STATUS_RESET_ON_SITE_COMPLETED = false   // For debugging: true  // deployment: false

export const INCLUDE_APPROVAL_SIGNATURE_ON_PATIENT_STATUS_RESET = true // not yet in use

export const ENFORCE_GPS_BEFORE_LOGIN = true

export const ENFORCE_GPS_AFTER_LOGIN = true

export const DISPLAY_GPS_ICON_ON_APPLICATION_BAR = true

export const DELAY_AFTER_DONE_TO_SITE_PAGE = 1000 // ms

export const ORGANIZATION = "organization"
export const HOUSEHOLD = "household"
export const INDIVIDUAL = "individual"

export const BACKDROP_BLOCKING_MODAL_SHOW_INTERVAL = 8000 // ms // not in use

export const ALLOW_EXISTING_PHOTO_RETAKE = false

window.$location = {
    longitude: 0,
    latitude: 0,
    heading: ""
}  // global variable

export const v2 = {
    COLORS: {
        TEXT_DISABLED: "#AFAFAF",
        GRAY: "#F7F7F7",
        GRAY_DISABLED: "#E6E6E6",
        YELLOW: "#ffdc51",
        YELLOW_DISABLED: "#E6E6E6",
        RED: "#F24A7E",
        RED_DISABLED: "#E6E6E6",
        BLUE: "#69CEF1",
        BLUE_DISABLED: "#E6E6E6",
    },
    UNABLE_TO_PERFORM_REASONS: [
        { key: "refused", value: "Patient refused" },
        { key: "refused_hard_stick", value: "Hard Stick" },
        // { key: "refused_unavailable", value: "Patient is unavailable" },
        { key: "unable", value: "Patient is unavailable" }, // Modifired by Raymond, Aug 4, 2023
        { key: "refused_other_reason", value: "Other reason" },
    ],
    ALLOWED_WORKFLOW_TYPE: [
        { key: "default", value: "Phlebotomy", noOfSteps: 3 },
        { key: "iv-infusion", value: "IV Infusion", noOfSteps: 7 },
    ],
    SCHEDULE: {
        MININUM_START_TIME: "00:00",
        MAXIMUM_END_TIME: "23:30",
        ALLOWED_REPEAT_SCHEDULE_OPTIONS: ["every_day", "every_n_week"],
        REPEAT_TYPE_TO_DISPLAY: [
            "",             // Does not repeat
            null,           // Does not repeat
            "every_day",    // Specific Dates
            "every_n_week"  // Every _ weeks
        ],
        MINIMUM_WEEKS_ALLOWED: 1,
        MAXIMUM_WEEKS_ALLOWED: 100,
        DAYS_OF_WEEK: [
            { key: "monday", code: "M", shortName: "Mon", label: "Monday" },
            { key: "tuesday", code: "T", shortName: "Tue", label: "Tuesday" },
            { key: "wednesday", code: "W", shortName: "Wed", label: "Wednesday" },
            { key: "thursday", code: "T", shortName: "Thu", label: "Thursday" },
            { key: "friday", code: "F", shortName: "Fri", label: "Friday" },
            { key: "saturday", code: "S", shortName: "Sat", label: "Saturday" },
            { key: "sunday", code: "S", shortName: "Sun", label: "Sunday" }
        ]
    }
}