import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import Typography from '@material-ui/core/Typography'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

import uuid from 'uuid'
import Calendar from 'react-calendar/dist/entry.nostyle'
import moment from 'moment-timezone'

import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent
} from '@material-ui/core'

import * as globals from 'components/common/GlobalConstants'

const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const styles = theme => ({
    dialogContainer: {
        margin: "20px",
        borderRadius: "10px"
    },
    dialogTitleContainer: {
        padding: "15px !important",
        borderBottom: "1px solid #E6E6E6",
    },
    dialogTitle: {
        fontSize: "16px !important",
        fontWeight: "600",
        textAlign: "center"
    },
    dialogContent: {
        padding: "20px !important",
        paddingTop: "0px !important",
    },
    dialogActions: {
        padding: "20px !important",
        margin: "0px"
    },
    btnCancel: {
        textTransform: 'capitalize',
        padding: "8px 20px",
        fontSize: "15px !important",
        backgroundColor: globals.v2.COLORS.GRAY,
        color: "black",
        fontWeight: 500,
        "&:focus": { backgroundColor: globals.v2.COLORS.GRAY + " !important" },
        "&:active": { backgroundColor: globals.v2.COLORS.GRAY + " !important" },
        "&:hover": { backgroundColor: globals.v2.COLORS.GRAY + " !important" },
        "&:disabled": { backgroundColor: globals.v2.COLORS.GRAY_DISABLED + " !important" },
    },
    btnSelect: {
        textTransform: 'capitalize',
        padding: "8px 20px",
        fontSize: "15px !important",
        backgroundColor: globals.v2.COLORS.YELLOW,
        color: "black",
        fontWeight: 500,
        "&:focus": { backgroundColor: globals.v2.COLORS.YELLOW + " !important" },
        "&:active": { backgroundColor: globals.v2.COLORS.YELLOW + " !important" },
        "&:hover": { backgroundColor: globals.v2.COLORS.YELLOW + " !important" },
        "&:disabled": { backgroundColor: globals.v2.COLORS.YELLOW_DISABLED + " !important" },
        marginRight: "0px"
    },
    confirmCompletionAvatar: {
        backgroundColor: globals.v2.COLORS.BLUE,
        width: "40px",
        height: "40px",
        marginRight: "10px"
    },
    confirmCompletionIcon: {
        fontSize: "28px"
    },
    confirmCompletionMessage: {
        marginLeft: "50px",
        fontSize: "14px !important"
    },
})

const SelectDateDialogFunctionClass = (props) => {
    const {
        classes,
        open = false,
        initialSelectedDate,
        minDateAllowed=null,
        onCancel,
        onSelect
    } = props

    const [modalSelectedDate, setModalSelectedDate] = useState(moment(initialSelectedDate, "dddd, MMM DD YYYY"))
    const activeYear = moment(modalSelectedDate).format("YYYY")
    const activeMonth = moment(modalSelectedDate).format("MM")

    // console.log("minDateAllowed",minDateAllowed)
    // console.log("new Date(minDateAllowed)", new Date(minDateAllowed))

    const customizedCalendarTileContent = ({ date, view }) => {
        var calendar_date = moment(date).format("YYYY-MM-DD")

        var content = ""
        const is_active = calendar_date === moment(modalSelectedDate).format("YYYY-MM-DD") ? " active" : ""

        content = <div
            className={
                "custom-calendar-date-tile" +
                is_active
            }
        >
            {date.getDate()}
        </div>

        return view === 'month' && content
    }

    return (
        <Dialog
            id="select-date-dialog"
            fullScreen={false}
            open={open}
            maxWidth={"md"}
            classes={{
                paperScrollPaper: classes.dialogContainer
            }}
        >
            <DialogTitle className={classes.dialogTitleContainer}>
                <Typography className={classes.dialogTitle}>{moment(modalSelectedDate).format("dddd, MMM DD YYYY")}</Typography>
            </DialogTitle>
            <DialogContent className={classNames(classes.dialogContent, "modal-dialog-content")}>
                <Calendar
                    key={uuid.v4()}
                    activeStartDate={new Date(activeYear, activeMonth - 1, 1)}
                    calendarType="US"
                    view="month"
                    className="modified-calendar-v2"
                    tileContent={customizedCalendarTileContent}
                    onClickDay={setModalSelectedDate}
                    prevLabel={<ArrowLeftIcon />}
                    nextLabel={<ArrowRightIcon />}
                    minDate={minDateAllowed ? new Date(minDateAllowed) : null}
                    formatShortWeekday={(locale, date) => {
                        return moment(date).format('ddd').substring(0, 1)
                    }}
                />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button onClick={onCancel} className={classNames(classes.btnCancel)} color="primary">Cancel</Button>
                <Button onClick={() => onSelect(modalSelectedDate)} className={classNames(classes.btnSelect)} color="primary">Select</Button>
            </DialogActions>
        </Dialog>
    )
}

const SelectDateDialog = withRouter(
    withCookies(
        connect(mapStateToProps)(
            withStyles(styles, { withTheme: true })(SelectDateDialogFunctionClass)
        )
    )
)
export default SelectDateDialog
