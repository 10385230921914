import * as globals from 'components/common/GlobalConstants';

const styles = theme => ({
    root: {
        // display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
    },
    overrideBlue: {
        background: globals.BLUE_GRADIENT_BG,
    },
    headerInfo: {
        paddingTop: "15px",
        paddingLeft: "15px",
        paddingRight: "15px",
    },
    scheduleContainer: {
        padding: "15px",
    },
    list: {
      border: "1px solid rgba(0, 0, 0, 0.16)",
      marginBottom: theme.spacing.unit,
      fontSize: "12px !important",
    },
    label: {
        fontSize: "14px !important",
        color: "#707070",
    },
    centered: {
        textAlign: 'center',
    },
    buttons: {
        fontSize: "14px !important",
        textTransform: "none",
        border: "none",
        borderRadius: 0,
        padding: "6px 16px"
    },
    customApplicationBarHeader: {
        padding: "7px 17px",
        marginLeft: "-15px",
        marginRight: "-15px"
    },
    listULMainHeader: {
        marginTop: 25
    },
    schedulesMainHeader: {
        color: "#707070 !important",
        fontSize: '24px !important',
        fontWeight: 'bold',
        padding: "0px !important",
        margin: "0px !important"
    },
    schedulesMainContainer: {
        margin: 15,
    },
    listUL: {
        paddingTop: 0,
        paddingBottom: 0
    },
    settingsContent: {
        paddingLeft: 16,
        paddingRight: 16,
    },
    textFieldContainer: {
        padding: 0
    },
    listItemRoot: {
        padding: "0px !important",
        // border: "1px solid black",
    },
    workTerritoryRoot: {
        fontSize: 14,
        padding: "0px !important",
        border: "1px solid black !important",
        marginRight: 47
    },
    workTerritoryInput: {
        padding: "7px 10px !important",
    //     fontSize: 14,
    //     padding: "0px 10px !important",
    //     border: "1px solid black !important",
    //     borderRadius: "0px !important"
    },
    workTerritoryNote: {
        fontSize: "12px !important",
        margin: "10px 0px"
    },
    clearButton: {
        color: "#767676",
        marginRight: 5
    },
    addbutton: {
        fontWeight: "bold"
    },
    blueButton: {
        minWidth: 36,
        width: 36,
        right: -5,
        padding: "4px !important",
        fontWeight: "bold",
        color: "white !important",
        backgroundColor: "#68CBEF !important",
    },
    listPaper: {
        marginTop: 30
    },
    listRoot: {
        padding: 0
    },
    listOdd: {
        backgroundColor: "#F4F4F4"
    },
    listHeader: {
        fontSize: 10,
        color: "white !important",
        backgroundColor: "#68CBEF !important",
        padding: "4px 16px"
    },
    listContent: {
        color: "#767676",
        fontSize: 14,
        padding: "8px 16px"
    },
    optionsParentContainer: {
        display: "table-cell",
        verticalAlign: "middle",
    },
    optionsContainer: {
        display: "table-cell",
        verticalAlign: "middle",
        padding: "0px 10px"
    },
    optionsIcon: {
        width: 25,
        height: 25
    },
    tableRow: {
        height: '18px !important',
        width: "100%"
    },
    tableCell: {
        padding: 12,
    },
    sitesTableCell: {
        paddingRight: "7px !important",
    },
    tableCellHeader: {
        padding: "0px !important",
        backgroundColor: "white",
        color: "#000 !important",
        whiteSpace: 'nowrap',
    },
    tableCellHeaderTitle: {
        fontSize: '12px !important',
        color: "white !important",
        backgroundColor: "#68CBEF !important",
        padding: "4px 16px"
    },
    siteInfo: {
        display: "table-cell",
        verticalAlign: "top",
        paddingLeft: "5px",
        width: "1000px" // just a hack for now
    },
    siteName: {
        fontSize: "12px !important",
        fontWeight: 'bold',
        color: '#707070'
    },
    siteAddress: {
        fontSize: "11px !important",
    },
    note: {
        fontSize: "12px !important",
        margin: "10px 0px"
    },
    acceptIcon: {
        color: '#00c853'
    },
    rejectIcon: {
        color: '#e53935'
    },
    accepted: {
        color: '#00c853 !important'
    },
    rejected: {
        color: '#e53935 !important'
    },
    rowAccepted: {
        backgroundColor: "#F2FCF6"
    },
    rowRejected: {
        backgroundColor: "#FEF5F5"
    },
    rowTextAccepted: {
        color: "#C7C7C7 !important"
    },
    rowTextRejected: {
        color: "#C7C7C7 !important"
    }
});

export default styles;