import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
// import classNames from 'classnames'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import OrdersSummary from './OrdersSummary'
import AppointmentList from './AppointmentList'

const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const TodayFunctionClass = (props) => {

    return (
        <>
            <OrdersSummary />
            <AppointmentList />
        </>
    )
}

const Today = withCookies(
    connect(mapStateToProps)(
        withStyles(styles, { withTheme: true })(TodayFunctionClass)
    )
)
export default Today
