import React from 'react'
import { useState, useEffect } from 'react'
import * as globals from 'components/common/GlobalConstants'
import PouchDB from 'pouchdb-browser'
import { Cookies } from 'react-cookie';
import Encryption from 'components/common/Encryption';
import GitInfo from 'react-git-info/macro';
import $ from 'jquery';
import { DeviceUUID } from 'device-uuid'
import printJS from 'print-js';
import CryptoJS from 'crypto-js';
// import later from '@breejs/later';
import moment from 'moment-timezone';

import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

export const getCodeVersion = () => {
    const gitInfo = GitInfo();
    return gitInfo.commit.shortHash
}

export const getDeviceId = () => {
    return new DeviceUUID().get()
}

export const getGitBranch = () => {
    const gitInfo = GitInfo();
    return gitInfo.commit.branch
}

export const deleteDatabase = (databse, chainCallback) => {
    if (globals.DEBUG_ALLOWED) {

        console.log("HELPER Deleting database : " + databse);
    }

    try {
        new PouchDB(databse).destroy().then(function () {
            if (globals.DEBUG_ALLOWED) {
                console.log("Database was destroyed sucessfully : " + databse);
            }
            chainCallback()

        }).catch(function (err) {
            console.log("Failed to destroy database : " + databse);
            chainCallback()
        });
    } catch (exception) {
        // just ignore the error    
        console.log("Failed to destroy database : " + databse);
        console.log(exception)
    }
}

export const getCookieValue = (key) => {
    const cookies = new Cookies();
    return cookies.get(key) || ''
}

export const setCookieValue = (key, value) => {
    const cookies = new Cookies();
    try {
        cookies.set(key, value, { path: '/', maxAge: 432000 }); // path: '/',
    } catch (e) {
        return false
    }
    return true
}

export const getLocalStorageValue = (key) => {

    return localStorage.getItem(key)
}

export const setLocalStorageValue = (key, value) => {

    try {
        localStorage.setItem(key, value)
    } catch (e) {
        return false
    }
    return true
}

// export const updateApp = new Promise((resolve, reject) => {
//     console.log("Updating...")
//     console.log("{ action: 'skipWaiting' }")

//     if ('newServiceWorker' in navigator) {
//         navigator.newServiceWorker.postMessage({ type: 'SKIP_WAITING' })
//         window.location.reload();
//         resolve("Success")
//     } else {
//         reject("Nothing to unregister")
//     }
// })

export const updateApp = () => {
    console.log("Updating...")
    console.log("{ action: 'skipWaiting' }")

    if ('newServiceWorker' in navigator) {
        try {
            navigator.newServiceWorker.addEventListener("statechange", event => {
                if (event.target.state === "activated") {
                    console.log("Restarting the app...")
                    window.location.reload();
                }
            })
            navigator.newServiceWorker.postMessage({ type: 'SKIP_WAITING' })
            console.log("Success in SKIP_WAITING")
        } catch (e) {
            console.log(e)
            console.log("Failed to update the app..")
            window.location.reload();
        }


    } else {
        console.log("Nothing to unregister")
    }
}

export const getLogs = () => {
    var security = new Encryption()

    if (!globals.ENCRYPT_LOGS) {
        return localStorage.getItem("logs")
    }
    // getLogNumber()
    var logs = security.decrypt(localStorage.getItem("logs"))
    return logs

}

export const getLogNumber = (db) => {
    var dbHash_new = CryptoJS.MD5(db)
    var dbHash_old = localStorage.getItem("as") || ""

    // get number from localstorage and increase the saved number
    // if the number exceeded the limit number will be set to 1
    var count = localStorage.getItem("lc") || 0
    count++
    // console.log(dbHash_new + " <=> "  + dbHash_old)
    if (dbHash_new.toString() !== dbHash_old) {
        // console.log(typeof dbHash_new + " <=> " + typeof dbHash_old + " not equal")
        localStorage.setItem("as", dbHash_new)
        count = 1
    }

    localStorage.setItem("lc", count)

    // console.log("count")
    // console.log(count)
    return count
}

export const resetLogNumber = () => {
    // reset log number to 1 
    localStorage.setItem("lc", 0)
}

export const saveLogs = (logs) => {
    if (globals.DEBUG_ALLOWED) {
        //console.log("Saving logs")
        // var sorted_by = Object.values(logs).sort(function (a, b) {
        Object.values(logs).sort(function (a, b) {
            if (a.log_timestamp > b.log_timestamp) {
                return 1;
            } else if (a.log_timestamp < b.log_timestamp) {
                return -1;
            } else {
                return 0;
            }
        });
        //console.log(sorted_by)
    }
    if (!globals.ENCRYPT_LOGS) {
        localStorage.setItem("logs", JSON.stringify(logs))
    } else {
        var security = new Encryption();
        localStorage.setItem("logs", security.encrypt(JSON.stringify(logs)))
    }
}

export const getCredentials = () => {
    if (globals.DEBUG_ALLOWED) {
        //  console.log("HELPER getCredentials")
    }
    const cookies = new Cookies();
    var cred = cookies.get('credentials') || ''

    if (cred === "") return ""

    var security = new Encryption()
    var decryptedCred = JSON.parse(security.decrypt(cred))
    return decryptedCred
    //console.log("THE RESULT " +  security.decrypt(secret));

}

/**
* Determine the mobile operating system.
* This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
*
* @returns {String}
*/
export const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod|Mac|mac/.test(userAgent) && !window.MSStream) {
        //if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

export const siteHasInitialApproval = (site) => {
    // if (globals.DEBUG_ALLOWED) {
    //     console.log("site")
    //     console.log(site)
    // }


    var hasInitialSignature = false

    site.patient_list.forEach((patient) => {

        //console.log("son_first_name " + patient.patient_draw[0].draw_sign_on.son_first_name)

        if (patient.patient_draw[0].draw_sign_on.son_first_name !== "" &&
            patient.patient_draw[0].draw_sign_on.son_first_name !== null) {
            hasInitialSignature = true
        }

    })
    return hasInitialSignature
}

export const siteHasFinalApproval = (site) => {

    var hasFinalSignature = true

    site.patient_list.forEach((patient) => {

        //console.log("sof_first_name " + patient.patient_draw[0].draw_sign_off.sof_first_name)

        if (patient.patient_draw[0].draw_sign_off.sof_first_name === "" ||
            patient.patient_draw[0].draw_sign_off.sof_first_name === null) {
            hasFinalSignature = false
        }

    })
    return hasFinalSignature
}

export const getSiteInitialApprovalName = (site) => {
    // console.log("site")
    // console.log(site)
    var siteInitialApprovalName = ""

    site.patient_list.forEach((patient) => {

        // console.log("son_first_name " + patient.patient_draw[0].draw_sign_on.son_first_name)

        if (patient.patient_draw[0].draw_sign_on.son_first_name !== "" &&
            patient.patient_draw[0].draw_sign_on.son_first_name !== null) {
            siteInitialApprovalName = {
                'first_name': patient.patient_draw[0].draw_sign_on.son_first_name,
                'last_name': patient.patient_draw[0].draw_sign_on.son_last_name
            };
        }

    })
    return siteInitialApprovalName
}


export const getSiteFinalApprovalName = (site) => {
    // if (globals.DEBUG_ALLOWED) {
    //     console.log("site")
    //     console.log(site)
    // }

    var siteFinalApprovalName = ""

    site.patient_list.forEach((patient) => {

        // if (globals.DEBUG_ALLOWED) {
        //     console.log("sof_first_name " + patient.patient_draw[0].draw_sign_off.sof_first_name)
        // }

        if (patient.patient_draw[0].draw_sign_off.sof_first_name !== "" &&
            patient.patient_draw[0].draw_sign_off.sof_first_name !== null) {
            // siteFinalApprovalName = patient.patient_draw[0].draw_sign_off.sof_first_name + ' ' +  patient.patient_draw[0].draw_sign_off.sof_last_name
            siteFinalApprovalName = {
                'first_name': patient.patient_draw[0].draw_sign_off.sof_first_name,
                'last_name': patient.patient_draw[0].draw_sign_off.sof_last_name
            };
        }

    })
    return siteFinalApprovalName
}

export const siteComplete = (site) => {

    var allPatientProcessed = true

    site.patient_list.forEach((patient) => {

        //console.log("status " + patient.patient_draw[0].status)

        if (patient.patient_draw[0].status === "remaining") {
            allPatientProcessed = false
        }
    })
    return allPatientProcessed
}


export const keepKeyOrder = (obj) => {
    if (typeof obj === 'object' && !Array.isArray(obj)) {
        let transformKey = (k) => [k, keepKeyOrder(obj[k])];
        return Object.keys(obj).map(transformKey);
    } else {
        return obj;
    }
}

export const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const dateDesc = (a, b) => {
    var dateA = new Date(a);
    var dateB = new Date(b);
    // console.log("Date A: " + dateA)
    // console.log("Date B: " + dateB)

    if (dateA < dateB) {
        return -1;
    }
    if (dateA > dateB) {
        return 1;
    }
    return 0;
}

export const sortFutureOrdersObjectByKey = (obj, order = 'asc') => {
    var keys = [];
    var sorted_obj = {};

    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            keys.push(key);
        }
    }

    keys.sort(order === 'asc' ? (a, b) => dateDesc(a, b) : (a, b) => -dateDesc(a, b));

    // create new array based on Sorted Keys
    $.each(keys, function (i, key) {
        sorted_obj[key] = obj[key];
    });

    return sorted_obj;
}


export const findBackCamera = (cameras) => {
    const backCamera = cameras.find(element => element.label.toLowerCase().indexOf("back") !== -1)
    if (backCamera) {
        return backCamera.deviceId
    } else {
        return undefined
    }
}

export const printLabel = (label) => {
    console.log(label);
    try {
        //printJS({printtable: file_path.labels[0].path, })
        var fpath = globals.SERVER + label.path;
        printJS({ printable: fpath, type: 'pdf', showModal: true })

    } catch (e) {
        console.log(e)
    }
}


// export const generateDates =(nWeek, startDate, endDate, daysInWeek) =>{
// 	var mainOutput = []
// 	var sched = later.parse.text('every '+nWeek+' weeks');

// 	//var results = later.schedule(sched).next(1000,  moment(startDate).startOf('week'),  endDate);
// 	var results = _later2(nWeek,  moment(startDate).startOf('week'),  endDate);

// 	for (var i = 0; i < results.length; i++) {
// 	   var outputDays = _generateDays(new Date(results[i].toString()), startDate, endDate, daysInWeek)

// 	   if(outputDays.length > 0) {
// 			//console.log("May laman")
// 			//console.log(outputDays)

// 			mainOutput = mainOutput.concat(outputDays)
// 	   }
// 	}
//     console.log("mainOutput")
//     console.log([nWeek, startDate, endDate, daysInWeek])
//     console.log(mainOutput)
// 	return mainOutput
// }

// export const _generateDays = (referenceDate, startDate, endDate,  daysInWeek) => {

// 	//console.log("Date Ref" + referenceDate.toString())
// 	var daysOutPut = []
// 	var startOfWeek
// 	if(moment(referenceDate).startOf('week').isBefore(startDate)) {
// 		console.log("WOW BEFORE! " + moment(referenceDate).startOf('week').toString() + " " + startDate.toString())
// 		//startOfWeek = moment(startDate).format("YYYY-MM-DD");
// 		//startOfWeek = moment(startDate).format("YYYY-MM-DD HH:MM:SS");
// 		startOfWeek = moment(startDate).utc();

//         //console.log(startOfWeek)
//         //console.log(new Date(startOfWeek))
// 	} else {
// 		//startOfWeek = moment(referenceDate).startOf('week').format("YYYY-MM-DD HH:MM:SS");
// 		startOfWeek = moment(referenceDate).startOf('week');

// 	}

// 	var  endOfWeek

// 	if(moment(referenceDate).endOf('week').isAfter(endDate)) {
// 		console.log("WOW Is after! " + moment(referenceDate).startOf('week').toString() + " " + endDate.toString())
// 		//endOfWeek = moment(endDate).format("YYYY-MM-DD");
// 		endOfWeek = moment(endDate);
// 	} else {
// 		//endOfWeek = moment(referenceDate).endOf('week').format("YYYY-MM-DD");
// 		endOfWeek = moment(referenceDate).endOf('week');
// 	}

// 	var schedDays =  later.parse.text('every 1 day');
// 	//var results = later.schedule(schedDays).next(7, new Date(startOfWeek), new Date(endOfWeek));
// 	var results = _laterDay( new Date(startOfWeek), new Date(endOfWeek));



// 	for (var i = 0; i < results.length; i++) {
//         console.log("Day check")
//         console.log(results)


//         console.log(moment(new Date(results[i])).format("dddd").toString(), daysInWeek)

// 	  if(_checkDayIfSelected(moment(new Date(results[i])).format("dddd").toString(), daysInWeek) ){
// 		daysOutPut.push(moment(new Date(results[i])).format("YYYY-MM-DD").toString())
// 		//console.log(results[i].toLocaleString())
// 	  }
// 	}

// 	return daysOutPut
// }
// export const _checkDayIfSelected =(day, userDays)=> {
//  for (var i = 0; i < userDays.length; i++) {
// 	if(userDays[i] == day) {
// 		return true
// 	}	
//  }
//  return false 
// }


// export const  _later2 =(nWeek, startDate, endDate) => {
// 	const firstWeek = moment(startDate).startOf("week")

// 	var result = []
// 	var currentWeek = firstWeek
// 	do {
// 		console.log(currentWeek.toLocaleString())
// 		result.push(currentWeek.toLocaleString())
// 		currentWeek = currentWeek.add(nWeek, "weeks")

// 	}
// 	while (moment(endDate).isAfter(currentWeek));
// 	return result
// }

// export const _laterDay= (startDate,endDate) =>{

//     console.log(startDate.toString() + ' ' + endDate.toString())

// 	var result = []
// 	var currentDay =  moment(startDate)
// 	var count = 0
// 	do {
// 		count++;
// 		console.log(currentDay.toLocaleString())
// 		result.push(currentDay.toLocaleString())
// 		currentDay = currentDay.add(1, "day")

// 	}
// 	while (moment(currentDay).isBetween(startDate, endDate, null, '(]'));
// 	return result
// }


export const generateDates = (nWeek, startDate, endDate, daysInWeek) => {
    var mainOutput = []
    // console.log('every ' + nWeek + ' weeks')
    // var sched = later.parse.text('every '+nWeek+' week');

    //var results = later.schedule(sched).next(100,  startDate,  endDate);

    // console.log("mainOutput")
    // console.log(startDate, endDate, moment(startDate).isSame(endDate))
    if (moment(startDate).isSame(endDate)) {
        mainOutput = mainOutput.concat([startDate.format("YYYY-MM-DD").toString()])
    } else {
        var results = _later2(nWeek, startDate, endDate);
        for (var i = 0; i < results.length; i++) {
            // console.log({ "date": results[i], "start": startDate.toLocaleString(), "end": endDate })

            var outputDays = _generateDays(new Date(results[i].toLocaleString()), startDate, endDate, daysInWeek)

            if (outputDays.length > 0) {
                //console.log("May laman")
                //console.log(outputDays)

                mainOutput = mainOutput.concat(outputDays)
            }
        }
    }
    return mainOutput
}

export const _generateDays = (referenceDate, startDate, endDate, daysInWeek) => {

    //console.log("Date Ref" + referenceDate.toString())
    var daysOutPut = []
    var startOfWeek
    // console.log(referenceDate + ' ' + moment(referenceDate).startOf('week').toString() + '] [' + startDate + ']')
    if (moment(referenceDate).startOf('week').isBefore(startDate)) {
        // console.log("WOW BEFORE! " + moment(referenceDate).startOf('week').toString() + " " + startDate.toString())
        //startOfWeek = moment(startDate).format("YYYY-MM-DD HH:MM:SS");
        startOfWeek = moment(startDate).toString();
    } else {
        //startOfWeek = moment(referenceDate).startOf('week').format("YYYY-MM-DD HH:MM:SS");
        startOfWeek = moment(referenceDate).startOf('week');

    }

    var endOfWeek

    if (moment(referenceDate).endOf('week').isAfter(endDate)) {
        //console.log("WOW Is after! " + moment(referenceDate).startOf('week').toString() + " " + endDate.toString())
        endOfWeek = moment(endDate);
    } else {
        endOfWeek = moment(referenceDate).endOf('week');
    }
    // console.log(startOfWeek.toLocaleString() + ' ' + endOfWeek.toLocaleString())
    // var schedDays =  later.parse.text('every 1 days');
    //var results = later.schedule(schedDays).next(7, new Date(startOfWeek), new Date(endOfWeek));
    var results = _laterDay(new Date(startOfWeek), new Date(endOfWeek));

    for (var i = 0; i < results.length; i++) {
        if (_checkDayIfSelected(moment(new Date(results[i].toLocaleString())).format("dddd").toString(), daysInWeek)) {
            daysOutPut.push(moment(new Date(results[i].toLocaleString())).format("YYYY-MM-DD").toString())
            //console.log(results[i].toLocaleString())
        }
    }

    return daysOutPut
}

export const _checkDayIfSelected = (day, userDays) => {
    for (var i = 0; i < userDays.length; i++) {
        if (userDays[i] === day) {
            return true
        }
    }
    return false
}

export const _later2 = (nWeek, startDate, endDate) => {
    const firstWeek = moment(startDate, "YYYY-MM-DD").startOf("isoWeek")

    var result = []

    if (!nWeek) {
        return result
    }

    var currentWeek = firstWeek
    do {
        // console.log(currentWeek.toString())
        result.push(currentWeek.toString())
        currentWeek = currentWeek.add(nWeek, "weeks")

    }
    while (moment(endDate, "YYYY-MM-DD").isAfter(currentWeek));
    return result
}

export const _laterDay = (startDate, endDate) => {
    // console.log(startDate.toString() + ' ' + endDate.toString())

    var result = []
    var currentDay = moment(startDate)
    // var count = 0
    do {
        // count++;
        // console.log(currentDay.toLocaleString())
        result.push(currentDay.toLocaleString())
        currentDay = currentDay.add(1, "day")

    }
    while (moment(currentDay).isBetween(startDate, endDate, null, '(]'));
    return result
}

// export const  getLocation=()=> {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(showPosition, showError)
//     } else { 
//       x.innerHTML = "Geolocation is not supported by this browser."
//     }
// }

export const readLocation = (updateLocation, getError, getAccuracy) => {
    if (navigator.geolocation) {

        const geoId = navigator.geolocation.watchPosition(
            (position) => { // Param 1
                const lat = position.coords.latitude
                const lng = position.coords.longitude
                updateLocation({ lat, lng })
                getAccuracy(position.coords.accuracy)
                //console.log({ lat, lng }, position.coords.accuracy)
                if (position.coords.accuracy > 10) {
                    console.log("The GPS accuracy isn't good enough")
                }
            },
            (e) => { // Param 2
                getError(e)
            },
            { enableHighAccuracy: true, maximumAge: 2000, timeout: 5000 } // Param 3
        )

        //   return () => {
        //     console.log('Clear watch called')
        //     window.navigator.geolocation.clearWatch(geoId)
        //   }
        return geoId

    }

    return;
}

export const activateCamera = () => {
    navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
        // const devices = mediaDevices.filter(({ kind }) => kind === "videoinput")
        // console.log("Devices in listdevices, BarcodeReader2 - Android, Unknown")
        // console.log(devices)
        // this.setState({ ...this.state, devices: devices })
    }).catch((err) => {
        console.log("ERROR in Barcode2 camera - Android, Unknown")
        // if (!this.state.errorOnCamera) {
        //     this.handleCameraError(err)
        //     console.error("Device access checks failed(BarcodeReader2): ", err);
        // }
    })

}

export const isEmptyObject = (obj) => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}


export const navigate = (lat, lng, name) => {
    // If it's an iPhone..
    if ((navigator.platform.indexOf("iPhone") !== -1) || (navigator.platform.indexOf("iPod") !== -1)) {
        function iOSversion() {
            if (/iP(hone|od|ad)/.test(navigator.platform)) {
                // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
                var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
                return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
            }
        }
        var ver = iOSversion() || [0];
        var protocol = "";
        if (ver[0] >= 6) {
            protocol = 'maps://';
        } else {
            protocol = 'http://';

        }
        window.location = protocol + 'maps.apple.com/maps?q=' + name; //  + '&amp;ll=' + lat + ',' + lng + ',16z'
        //   window.location = protocol + 'maps.apple.com/maps?daddr=' + lat + ',' + lng + '&amp;ll=';
    }
    else {
        window.open('http://maps.google.com/maps?q=' + name); //  + '&amp;ll=' + lat + ',' + lng + ',16z'
        //   window.open('http://maps.google.com?daddr=' + lat + ',' + lng + '&amp;ll=');
    }
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});