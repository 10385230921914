import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import * as globals from 'components/common/GlobalConstants';

const styles = theme => ({
    root: {
        // display: 'flex',
        paddingTop: "25px !important",
        paddingLeft: "40px !important",
        paddingRight: "40px !important",
        paddingBottom: "40px !important",
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
    margins: {
      margin: theme.spacing.unit * 2.5,
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
    },
    table: {
       minWidth: 20,
    },
    badgeRefuse: {
      color: red[800],
    },
    colMargin: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
    },
    overrideBlue: {
      background: globals.BLUE_GRADIENT_BG,
      color: "#fff",
    },
    overridePink: {
      background: globals.PINK_GRADIENT_BG,
    },
    overrideLightBlue: {
      background: globals.LIGHT_BLUE_GRADIENT_BG,
      color: "#01579B",
    },
    badge: {
      top: -3,
      right: -30,
    },
    badgeGreen: {
      top: -3,
      right: -30,
      backgroundColor: green[700],
    },
    progressBar: {
      borderRadius: 20,
      height: 34,
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 30px 0 rgba(0, 0, 0, 0.11)',
    },
    allowanceTopBottom: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
    },
    allowanceTop: {
      marginTop: theme.spacing.unit * 3,
    },
    allowanceBottom: {
      marginBottom: theme.spacing.unit * 3,
    },
    approvalButton: {
      fontSize: 32,
      paddingTop: theme.spacing.unit * 1.8,
      paddingBottom: theme.spacing.unit * 1.8,
      backgroundColor: "#68CBEF",
      textTransform: 'none',
    },
    nowrap: {
      whiteSpace: 'nowrap',
    },
    avatar: {
      margin: 'auto', 
    },
    tableCell: {
      padding: 14, //theme.spacing.unit,
    },
    lastTableCell: {
      paddingRight: "14px !important",
    },
    btnTableCell: {
      paddingLeft: 0,
    },
    btnRoomNumber: {
      borderRight: "1px solid rgba(224, 224, 224, 1)",
      background: "#CCCCCC",
    },
    lblRoomNumber: {
      color: "white !important",
    },
    skipButton: {
      background: "#CCCCCC",
    },
    skipButtonLabel: {
      color: "white !important",
    },
    centered: {
      textAlign: 'center',
    },
    canvas: {
      width: '100%',
    },
    signatureButton: {
      margin: theme.spacing.unit * 2,
      marginLeft: 0,
    },
    textRight: {
      textAlign: "right"
    },
    signaturePopover: {
      float: 'right',
      padding: theme.spacing.unit * 2,
    },
    resizeTextField: {
      fontSize: '30px'
    },
    patientDone: {
      color: '#333'
    },
    green: {
      color: "#64dd17",
    },
    greatJobIcon: {
      fontSize: 80,
      margin: theme.spacing.unit * 2,
      color: "#64dd17",
      border: "5px solid #64dd17",
      borderRadius: "50%",
    },
    rowDone: {
      color: "#bdbdbd",
    },
    STAT: {
      color: '#000',
      backgroundColor: '#FAD94E',
      display: 'inline',
      padding: `${theme.spacing.unit * .5}px ${theme.spacing.unit}px`,
      borderRadius: 3
    },
    icon: {
      lineHeight: 1.2,
      fontSize: '1rem',
    },
    hackAdjustment: {
      paddingLeft: 15,
    },
    paddings: {
      padding: theme.spacing.unit,
    },
    filters: {
      color: "#656565c9",
    },
    selectedFilter: {
      color: "#0277bd",
    },
    numberOfDraws: {
      fontFamily: "Helvetica",
      fontSize: '32px !important',
    },
    progressContainer: {
      marginBottom: theme.spacing.unit * 5.5,
    },
    linearColorPrimary: {
      backgroundColor: "white",//"#f1f8e9",
    },
    linearBarColorPrimary: {
      backgroundColor: "#12D003",
      borderRadius: 30,
    },
    bottomButtonsContainer: {
      // fontSize: '32px !important',
      marginTop: theme.spacing.unit * 4.5
    },
    doneStatus: {
      color: "#0FD00F !important",
    },
    deferredStatus: {
      color: "#FF5F5F !important",
    },
    obtainApprovalMessageContainer: {
      margin: '53px auto',
      width: "80%",
    },
    obtainApprovalMessage: {
      fontSize: "32px !important",
      lineHeight: 1.2,
      color: "#707070",
    },
    obtainApprovalIcon: {
      fontSize: "80px !important",
      color: "#707070",
    },
    initialApprovalContainer: {
      marginTop: 43,
      marginBottom: 50,
    },
    finalApprovalContainer: {
      marginTop: 43,
      marginBottom: 50,
      float: "right",
      textAlign: "right",
    },
    siteAddress: {
      // fontSize: '12px !important',
      width: '50%',
      float: 'left'
    },
    tableRow: {
    //   boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 30px 0 rgba(0, 0, 0, 0.11)',
    //   marginBottom: 20,
    },
    myTable: {
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 30px 0 rgba(0, 0, 0, 0.11)',
    },
    textFieldRoomNumber: {
      fontSize: 50,
    },
    approvingNurseTextField: {
      display: "flex"
    },
    specifyReasonTextArea: {
      display: "flex"
    },
    buttons: {
      margin: theme.spacing.unit * 2,
      marginLeft: 0,
    },
    radioButton: {
      padding: "6px 12px",
    }
});

export default styles