import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import CheckIcon from '@material-ui/icons/Check'

// import Grid from '@material-ui/core/Grid'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent
} from '@material-ui/core'

import * as globals from 'components/common/GlobalConstants'
import * as helper from 'components/common/Helper.js'

import moment from 'moment'

const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const styles = theme => ({
    dialogContainer: {
        margin: "20px",
        borderRadius: "10px"
    },
    dialogTitle: {
        fontSize: "20px !important",
        fontWeight: "600"
    },
    dialogContent: {
        padding: "20px !important"
    },
    dialogActions: {
        padding: "20px !important",
        margin: "0px"
    },
    btnClose: {
        textTransform: 'capitalize',
        padding: "8px 20px",
        fontSize: "16px !important",
        backgroundColor: globals.v2.COLORS.GRAY,
        color: "black",
        fontWeight: 500,
        "&:focus": { backgroundColor: globals.v2.COLORS.GRAY + " !important" },
        "&:active": { backgroundColor: globals.v2.COLORS.GRAY + " !important" },
        "&:hover": { backgroundColor: globals.v2.COLORS.GRAY + " !important" },
        "&:disabled": { backgroundColor: globals.v2.COLORS.GRAY_DISABLED + " !important" },
    },
    btnConfirm: {
        textTransform: 'capitalize',
        padding: "8px 20px",
        fontSize: "16px !important",
        backgroundColor: globals.v2.COLORS.YELLOW,
        color: "black",
        fontWeight: 500,
        "&:focus": { backgroundColor: globals.v2.COLORS.YELLOW + " !important" },
        "&:active": { backgroundColor: globals.v2.COLORS.YELLOW + " !important" },
        "&:hover": { backgroundColor: globals.v2.COLORS.YELLOW + " !important" },
        "&:disabled": { backgroundColor: globals.v2.COLORS.YELLOW_DISABLED + " !important" },
    },
    confirmScheduleAvatar: {
        backgroundColor: globals.v2.COLORS.BLUE,
        width: "40px",
        height: "40px",
        marginRight: "10px",
        "& svg": {
            fill: "white"
        }
    },
    confirmScheduleIcon: {
        fontSize: "28px !important"
    },
    confirmScheduleMessage: {
        marginLeft: "50px",
        fontSize: "14px !important"
    },
    labels: {
        fontSize: "12px !important"
    },
    values: {
        fontSize: "14px !important",
    },
    bold: {
        fontWeight: "600"
    },
    spacingBottom: {
        marginBottom: "15px"
    }
})

const ConfirmScheduleDialogFunctionClass = (props) => {
    const {
        classes,
        open = false,
        scheduleData,
        onEdit,
        onSave
    } = props

    var looped_dates = []

    console.log("scheduleData")
    console.log(scheduleData)

    if (scheduleData.repeat_type === "every_day") {
        const start_date = moment(scheduleData.date_from, "YYYY-MM-DD").startOf('day')
        const end_date = moment(scheduleData.date_to, "YYYY-MM-DD").startOf('day')

        while (start_date.isSameOrBefore(end_date)) {
            looped_dates.push(start_date.clone().format("MMM DD, YYYY"))
            start_date.add(1, 'days')
        }
    }

    // console.log(scheduleData)

    return (
        <Dialog
            id="confirm-schedule-dialog"
            fullScreen={false}
            open={open}
            maxWidth={"md"}
            classes={{
                paperScrollPaper: classes.dialogContainer
            }}
        >
            <DialogContent className={classes.dialogContent}>
                <Grid
                    container
                    alignItems="center"
                >
                    <Avatar className={classes.confirmScheduleAvatar}>
                        <CheckIcon className={classes.confirmScheduleIcon} />
                    </Avatar>
                    <Typography className={classes.dialogTitle}>Confirm Schedule</Typography>
                </Grid>
                <div className={classes.confirmScheduleMessage}>
                    <Typography className={classes.labels}>Date:</Typography>
                    <Typography className={classNames(classes.values, classes.bold, classes.spacingBottom)}>{moment(scheduleData.date_from, "YYYY-MM-DD").format("dddd, MMM DD, YYYY")}</Typography>
                    <Typography className={classes.labels}>Time:</Typography>
                    <Typography className={classNames(classes.values, classes.bold , classes.spacingBottom)}>{moment(scheduleData.time_from, "HH:mm:ss").format("hh:mm A")} - {moment(scheduleData.time_to, "HH:mm:ss").format("hh:mm A")}</Typography>
                    <Typography className={classes.labels}>Repeats:</Typography>
                    {scheduleData.repeat_type === null &&
                        <Typography className={classNames(classes.values, classes.spacingBottom)}>Does not repeat</Typography>
                    }
                    {scheduleData.repeat_type === "every_day" &&
                        <>
                            {/* until <strong>{moment(scheduleData.date_to, "dddd, MMM DD YYYY").format("MMM DD, YYYY")}</strong> */}
                            <Typography className={classNames(classes.values, classes.spacingBottom)}>On the specific dates below:</Typography>

                            {looped_dates && looped_dates.map((day) => {
                                return <Typography key={day} className={classNames(classes.values, classes.bold)}>{day}</Typography>
                            })}
                        </>
                    }
                    {scheduleData.repeat_type === "every_n_week" &&
                        <>
                            <Typography className={classNames(classes.values, classes.spacingBottom)}>Every <strong>{scheduleData.repeat_interval} {scheduleData.repeat_interval === 1 ? "Week" : "Weeks"}</strong> until <strong>{moment(scheduleData.date_to, "YYYY-MM-DD").format("MMM DD, YYYY")}</strong> on the following days:</Typography>
                            {scheduleData.days && scheduleData.days.map((day) => {
                                return <Typography key={day} className={classNames(classes.values, classes.bold)}>{helper.capitalize(day)}</Typography>
                            })}
                        </>
                    }
                </div>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button onClick={onEdit} className={classNames(classes.btnClose)} color="primary">Edit</Button>
                <Button onClick={onSave} className={classNames(classes.btnConfirm)} color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    )
}

const ConfirmScheduleDialog = withRouter(
    withCookies(
        connect(mapStateToProps)(
            withStyles(styles, { withTheme: true })(ConfirmScheduleDialogFunctionClass)
        )
    )
)
export default ConfirmScheduleDialog
