import PouchDB from 'pouchdb-browser';
import * as pouchdbUpsert from 'pouchdb-upsert';
import  Encryption from 'components/common/Encryption';
import * as globals from 'components/common/GlobalConstants';
import moment from 'moment-timezone';
//import cryptopouch from 'crypto-pouch'
//import crypto from 'crypto'
//import simplecryptor from 'simple-cryptor-pouch'
import transformpouch from 'transform-pouch'
//import { FormHelperText } from '@material-ui/core';
//import * as helper from 'components/common/Helper.js'

// const encrypt =(text)=> {
//     var cipher = crypto.createCipher('aes-256-cbc', 'password');
//     var crypted = cipher.update(text, 'utf8', 'base64');
//     return crypted + cipher.final('base64');
//   }
  
//   const decrypt = (text)=> {
//     var decipher = crypto.createDecipher('aes-256-cbc', 'password');
//     var dec = decipher.update(text, 'base64', 'utf8');
//     return dec + decipher.final('utf8');
//   }

class DB {
    debugLevel = 2
    static dataDispatcher = undefined
    static statDispatcher = undefined
    static futureOrdersDispatcher = undefined
    static uploadLogsDispatcher = undefined
    static geoLocationDataDispatcher = undefined
    static drawRequestDocumentsDataDispatcher = undefined
    static drawRequestNotesDataDispatcher = undefined
    static workflowDataDataDispatcher = undefined

    static logEventDispatcher = undefined

    constructor(credentials) {

        if(Object.keys(credentials).length !== 0) {

            this.transfer_protocol = credentials.transfer_protocol;
            this.database = credentials.database;
            this.host = credentials.host;
            this.port = credentials.port;
            this.username = credentials.username;
            this.password = credentials.password;

           this.security = new Encryption()
            this.createAndConnectPouchDB();
           
        }

    }

    createAndConnectPouchDB =()=> {           
            console.log("Creating DB...")
            try{
               
                // this.db = new PouchDB(this.database, {
                //     ajax: {
                //       cache: false,
                //       timeout: 15000
                //     }
                //   });
            
                //this.db = new PouchDB(this.database,{auto_compaction: true});

                this.db = new PouchDB(this.database);

                if(globals.ENCRYPT_DATA) {

                    PouchDB.plugin(transformpouch)
                    this.db.transform({
                        incoming: (doc)=> {
                        Object.keys(doc).forEach( (field)=> {
                        
                            if (field !== '_id' && field !== '_rev' && field !== '_revisions') {
                                
                                var security = new Encryption()
                            
                                try {
                                    doc[field] = security.encrypt(JSON.stringify(doc[field]))
                                } catch (e) {
                                    if (global.DEBUG_ALLOWED) {
                                        console.log("Error in encrypting this. But this is normal if data is already a JSON ")
                                        console.log(doc[field])
                                    }
                                }
                            }
                        });
                        return doc;
                        },
                        outgoing:  (doc)=> {
                        Object.keys(doc).forEach( (field) =>{
                            if (field !== '_id' && field !== '_rev' && field !== '_revisions') {
                                try {
                                    doc[field] = JSON.parse(this.security.decrypt(doc[field]))
                                } catch(e) {
                                    if (global.DEBUG_ALLOWED) {
                                        console.log("Error in encrypting this. But this is normal if data is already a JSON ")
                                        console.log(doc[field])
                                    }
                                }
                            }
                        }); 
                        return doc;
                        }
                    })
                }

                this.db.setMaxListeners(20); 
                PouchDB.plugin(pouchdbUpsert);

            } catch(e){
                this.db = new PouchDB("temp"); 
                console.log(e)
            }


            this.remoteCouch = this.transfer_protocol + "://"+this.username+":"+this.password+"@"+this.host+":"+this.port+"/" + this.database;

            this.db.changes({
                since: 'now',  
                live: true
            }).on('change', () => {
                console.log("Data loaded from PouchDB " + moment().format('LTS'));
                this.loadDataToState();
            }).catch(err => {
                if(err.error) {
                    if(err.reason === "QuotaExceededError") {
                        this.callLoadDataDispatcher({"quota_exceeded": true});
                    }
                }
            });

            this.sync();
            this.loadDataToState();

    }

    getCredentials =()=> {

        var cred = DB.cookies.get('credentials') || ''
        
        if(cred === "") return ""

        var security = new Encryption()
        var decryptedCred = JSON.parse(security.decrypt(cred))
        return decryptedCred
      }

      deleteDatabase = (databse) => {
        
        if(globals.DEBUG_ALLOWED) {
            console.log("Deleting database : " + databse);  
        }

        try {
            new PouchDB(databse).destroy().then(function () {
                if(globals.DEBUG_ALLOWED) {
                    console.log("Database was destroyed sucessfully : " + databse);  
                }
               
            }).catch(function (err) {
                console.log("Failed to destroy database : " +  databse);
            });
        } catch(exception) {
            console.log("Failed to destroy database : " +  databse);
            console.log(exception)
        }
    }

    async updateData(data) {
        this.data = data
        console.log("Data update " + moment().format('LTS'));
        if(globals.DEBUG_ALLOWED) {
            console.log(data)
        }

        DB.cookies.set('sync_status', globals.UNSYNCED, { path: '/', maxAge: 57600 })

        await this.db.put(data).then((response) =>{
            this.callSetSyncStatus(globals.UNSYNCED)
          }).catch((err)=> {
            
            // if(globals.DEBUG_ALLOWED) {
            //     DB.cookies.set('sync_status', globals.SYNC_ERROR, { path: '/', maxAge: 57600 })
            // }
            
            
            this.callLogEventDispatcher({ 
                user:  this.username,
                module: "PouchDB",
                action: err.message,
                description: JSON.stringify(err) ,
            })


            console.log("DB Put error: ", err);
            
            if(!globals.POUCHDB_AUTO_RESOLVE_CONFLICT) { 
                return 
            }

            if(err.status === 409) {
                
                console.log("Data conflict detected. Resolving...")
                
                if(globals.DEBUG_ALLOWED) {
                    console.log("err.docId")
                    console.log(err.docId)
                }

                this.db.upsert(err.docId, function (doc) {
                    if (!doc.count) {
                      doc.count = 0;
                    }
                    doc.count++;
                    if(globals.DEBUG_ALLOWED) {
                        console.log(doc)
                        return doc;
                    }
                  }).then(function (res) {
                    console.log("Success resolving conflicts")
                  }).catch(function (err) {
                    console.log("Failed to solve conflicts")
                  });
            }
          });

    }

    async loadDataToState() {
        let labData = await this.db.allDocs({ include_docs: true});
        var drawData = null;
        //console.log("Data arrived " + moment().format('LTS'));

        if(globals.DEBUG_ALLOWED) {
            console.log(labData);
        }
        
        if(labData.rows === undefined) {
            drawData = {"no_draw_list":true};
            this.callLoadDataDispatcher(drawData);
            return;
        }

        var statData = undefined;
        var scheduleData = undefined;
        var drawRequests = undefined;
        var drawRequestDocumentsData = undefined;
        var drawRequestNotesData = undefined;
        var futureOrders = undefined;

        for(var ctr = 0 ; ctr< labData.rows.length; ctr++) {

            // FOR Future Orders
            if(labData.rows[ctr].doc.future_orders) {
                console.log("Future Orders document present")
                futureOrders = labData.rows[ctr].doc;
                this.callLoadFutureOrdersDispatcher(futureOrders);
            }

            // FOR NEW STAT REQUEST
            if(labData.rows[ctr].doc.ns && labData.rows[ctr].doc.done === undefined) {
                console.log("New Stat Request document present")
                statData = labData.rows[ctr].doc;
                this.callLoadStatDispatcher(statData);
            }

            // FOR NEW STAT REQUEST
            if(labData.rows[ctr].doc.schedules) {
                console.log("Schedules document present")
                scheduleData = labData.rows[ctr].doc;
                this.callLoadSchedulesDispatcher(scheduleData);
            }

            //  FOR THE DRAWLIST 
            if(labData.rows[ctr].doc.user) {
                drawData = labData.rows[ctr].doc;
                console.log("drawData")
                console.log(drawData)
                if(globals.USE_PAMP_COUCHDB_SERVER === true) { 
                    this.callLoadDataDispatcher(drawData)
                } else {
                   
                    if(drawData.d === this.database) { // It will filter the right drawlist and right DB
                        this.callLoadDataDispatcher(drawData)
                    } else {
                        console.log("Invalid document " + drawData.d )
                        //this.db.remove(labData.rows[ctr].doc) // 
                    }
                }
            }

            // Draw Request
            if(labData.rows[ctr].doc.draw_request) {
                console.log("[Main] DrawRequest document present")
                drawRequests = labData.rows[ctr].doc;
                this.callLoadDrawRequestsDispatcher(drawRequests);
            }

            // Draw Request Documents
            if(labData.rows[ctr].doc.draw_request_documents) {
                drawRequestDocumentsData = labData.rows[ctr].doc;
                console.log("DrawRequestdocuments document present")
                console.log(drawRequestDocumentsData)
                this.callLoadDrawRequestDocumentsDataDispatcher(drawRequestDocumentsData);
            }

            // Draw Request Notes
            if(labData.rows[ctr].doc.draw_request_notes) {
                drawRequestNotesData = labData.rows[ctr].doc;
                console.log("Draw Request Notes document present")
                this.callLoadDrawRequestNotesDataDispatcher(drawRequestNotesData);
            }

            // Workflow
            if(labData.rows[ctr].doc.workflow) {
                console.log("Workflow document present")
                drawRequestNotesData = labData.rows[ctr].doc
                
                console.log(drawRequestNotesData)
                this.callWorkflowDataDispatcher(drawRequestNotesData);
            }

            // FOR GEO LOCATION
            if (labData.rows[ctr].doc.geolocation) {
                console.log("Geolocation document present")
                if (labData.rows[ctr].doc.drawlist !== undefined && labData.rows[ctr].doc.drawlist !== "") {
                    //if (globals.DEBUG_ALLOWED) console.log("Geo location document arrived has drawlist.")
                    this.callGeoLocationDataDispatcher(labData.rows[ctr].doc)
                } else {
                    if (globals.DEBUG_ALLOWED) console.log("Geo location document arrived but doesn't have drawlist.")
                }
               // if (globals.DEBUG_ALLOWED) console.log(labData.rows[ctr].doc)
            }

        }
        return;

    }

    async sync() {
        console.log("Synching " + moment().format('LTS'))

        this.sync = await this.db.sync(this.remoteCouch, {
            live: true,
            retry: true,
        }).on('change',  (info) => {
            var sync_direction = ""
            var docsUpdated = []

            info.change.docs.forEach(
                (item)=>{ docsUpdated.push( {'docId' : item._id, '_rev' : item._rev})
            })

            this.callLogEventDispatcher({ 
                user:  this.username,
                module: "PouchDB",
                action: info.direction,
                description: JSON.stringify(docsUpdated) ,
            })

            if(info.direction === "push") {
                sync_direction = globals.SYNCED_UP
             }
            
            if(info.direction === "pull") {
                sync_direction = globals.SYNCED_DOWN
            }

            DB.cookies.set('sync_status', sync_direction, { path: '/', maxAge: 57600 }); 
            this.callSetSyncStatus(sync_direction)
            
            if(info.direction === "push") {
                if(globals.UPLOAD_LOGS_TO_SERVER) {
                    this.callUploadLogsDispatcher()
                } else {
                    console.log("Uploading of logs is disabled.")
                }
            }

            if(globals.DEBUG_ALLOWED) {
                console.log("On change triggered...");
                console.log(info.direction + " at " + info.change.start_time);
                console.log(info);
            }

        }).on('paused', function () {
            if(globals.DEBUG_ALLOWED) {
                console.log("replication paused (e.g. user went offline)");
            }
        }).on('active', () => {
            if(globals.DEBUG_ALLOWED) {
                console.log("replicate resumed (e.g. user went back online)");
            }
        }).on('denied', (info) => {            
            DB.cookies.set('sync_status', globals.SYNC_ERROR, { path: '/', maxAge: 57600 }) 
            
            this.callLogEventDispatcher({ 
                user:  this.username,
                module: "PouchDB",
                action: "pouch_denied",
                description: JSON.stringify(info) ,
            })

            this.callSetSyncStatus( globals.SYNC_ERROR)

            if(globals.DEBUG_ALLOWED) {
                console.log("a document failed to replicate, e.g. due to permissions");
                console.log(info);
            }
        }).on('complete', (info) => {
            if(globals.DEBUG_ALLOWED) {
                console.log('complete');
                console.log(info);
            }
        }).on('error',  (err) =>{
            DB.cookies.set('sync_status', globals.SYNC_ERROR, { path: '/', maxAge: 57600 })
            this.callSetSyncStatus( globals.SYNC_ERROR)

                console.log('error', err);

                //*** Force logout if DB user is no more valid ***// 
                if(err.status === 401 && err.error === "unauthorized" ){
                    this.callLogEventDispatcher({ 
                        user:  this.username,
                        module: "PouchDB",
                        action: "pouch_error",
                        description: JSON.stringify(err) ,
                    })

                    console.log("Force logout, DB user is no more valid.")
                    DB.cookies.remove('credentials');
                    DB.cookies.remove('selected_site');

                }
            
        });
    }

    async callLoadDataDispatcher(param){
        if(DB.dataDispatcher === undefined) {
            console.log("dataDispatcher is not available");
        } else {
            DB.dataDispatcher(param);
        }
    }

    async callLoadStatDispatcher(param){
        if(DB.statDispatcher === undefined) {
            console.log("statDispatcher is not available");
        } else {
            DB.statDispatcher(param); 
        }
    }

    async callLoadSchedulesDispatcher(param){
        if(DB.scheduleDispatcher === undefined) {
            console.log("scheduleDispatcher is not available");
        } else {
            DB.scheduleDispatcher(param); 
        }
    }

    async callLoadDrawRequestsDispatcher(param){
         if(DB.drawRequestsDispatcher === undefined) {
            console.log("drawRequestsDispatcher is not available");
        } else {
            DB.drawRequestsDispatcher(param); 
        }
    }

    async callLoadDrawRequestDocumentsDataDispatcher(param){
         if(DB.drawRequestDocumentsDataDispatcher === undefined) {
            console.log("drawRequestDocumentsDataDispatcher is not available");
        } else {
            // console.log("callLoadDrawRequestDocumentsDataDispatcher")
            // console.log(param)
            DB.drawRequestDocumentsDataDispatcher(param); 
        }
    }

    async callLoadDrawRequestNotesDataDispatcher(param){
        if(DB.drawRequestNotesDataDispatcher === undefined) {
           console.log("drawRequestNotesDataDispatcher is not available");
       } else {
           // console.log("callLoadDrawRequestDocumentsDataDispatcher")
           // console.log(param)
            //console.log("WOW NOTES DOCUMENT!!!!!",param)
            DB.drawRequestNotesDataDispatcher(param); 
       }
   }

   async callWorkflowDataDispatcher(param){
    if(DB.workflowDataDispatcher === undefined) {
       console.log("workflowDataDispatcher is not available");
   } else {
       // console.log("callLoadDrawRequestDocumentsDataDispatcher")
       // console.log(param)
        //console.log("Wow WORKFLOW DOCUMENT!!!!!",param)
        DB.workflowDataDispatcher(param); 
   }
}

    async callSetSyncStatus(param){
        if(DB.setSyncStatus === undefined) {
        } else {
            DB.setSyncStatus(param); 
        }
    }

    async callLoadFutureOrdersDispatcher(param){
        if(DB.futureOrdersDispatcher === undefined) {
            console.log("futureOrdersDispatcher is not available");
        } else {
            DB.futureOrdersDispatcher(param); 
        }
    }

    async callUploadLogsDispatcher(){
        if(DB.uploadLogsDispatcher === undefined) {
            console.log("uploadLogsDispatcher is not available");
        } else {
            DB.uploadLogsDispatcher(); 
        }
    }

    async callGeoLocationDataDispatcher(param){
        if(DB.geoLocationDataDispatcher === undefined) {
            console.log("geoLocationDataDispatcher is not available");
        } else {
            DB.geoLocationDataDispatcher(param)
        }
    }

    async callLogEventDispatcher(param) {
        if(DB.logEventDispatcher === undefined) {
            console.log("logEventDispatcher is not available")
        } else {
            DB.logEventDispatcher(param)
        }
    }


    getLoadDataDispatcher() {
       return DB.dataDispatcher;
    }

    setLoadDataDispatcher(param) {
        DB.dataDispatcher = param;
    }

    getStatDispatcher() {
        return DB.statDispatcher;
    }
 
    setStatDispatcher(param) {
         DB.statDispatcher = param;
    }

    getSchedulesDispatcher() {
        return DB.scheduleDispatcher;
    }
 
    setSchedulesDispatcher(param) {
         DB.scheduleDispatcher = param;
    }

    getDrawRequestsDispatcher() {
        return DB.drawRequestsDispatcher;
    }

    setDrawRequestsDispatcher(param) {
        DB.drawRequestsDispatcher = param;
    }

    getdrawRequestDocumentsDataDispatcher() {
        return DB.drawRequestDocumentsDataDispatcher;
    }

    setdrawRequestDocumentsDataDispatcher(param) {
        DB.drawRequestDocumentsDataDispatcher = param;
    }

    setdrawRequestNotesDataDispatcher(param) {
        DB.drawRequestNotesDataDispatcher = param;
    }

    setWorkflowDataDispatcher(param) {
        DB.workflowDataDispatcher = param
    }

    getWorkflowDataDispatcher() {
       return DB.workflowDataDispatcher
    }

    setSyncStatusDispatcher(param) {
        DB.setSyncStatus = param;
    }

    setCookies(param) {
        DB.cookies = param;
    }

    getCookies() {
        return DB.cookies;
    }

    getFutureOrdersDispatcher() {
        return DB.futureOrdersDispatcher;
    }

    setFutureOrdersDispatcher(param) {
        DB.futureOrdersDispatcher = param;
    }

    getUploadLogsDispatcher() {
        return DB.uploadLogsDispatcher;
     }
 
     setUploadLogsDispatcher(param) {
         DB.uploadLogsDispatcher = param;
     }

     getGeoLocationDataDispatcher() {
        return DB.geoLocationDataDispatcher;
     }
 
     setGeoLocationDataDispatcher(param) {
         DB.geoLocationDataDispatcher = param;
     }

     getLogEventDispatcher() {
        return DB.logEventDispatcher;
     }
 
     setLogEventDispatcher(param) {
         DB.logEventDispatcher = param;
     }

}

export default DB;