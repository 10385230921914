import React, {Component} from 'react';
// import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
import $ from 'jquery';

// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';

import Dialog from '@material-ui/core/Dialog';
//import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import withMobileDialog from '@material-ui/core/withMobileDialog';

// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormControl from '@material-ui/core/FormControl';
// import FormLabel from '@material-ui/core/FormLabel';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import ApplicationBar from 'components/common/mobile/ApplicationBar';
// import SimpleBottomNavigation from 'components/common/mobile/SimpleBottomNavigation';
// import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
// import { connect } from "react-redux";

// import EnhancedTableHead from "components/common/mobile/EnhancedTableHead.js";
import SignaturePad from 'react-signature-pad-wrapper';
import trimCanvas from 'trim-canvas';

// import Icon from '@material-ui/core/Icon';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import styles from "./styles.js"
// import { Divider } from '@material-ui/core';
// import { updatePatientDrawStatus, updatePatientRoomNumber, mpSign } from "js/actions/index";

// import { withCookies, Cookies } from 'react-cookie';
// import { instanceOf } from 'prop-types';
// import ImmutableJS from "immutable";
// import NoDrawListScreen from 'components/common/mobile/NoDrawListScreen.js';
// import CurrentDate from 'components/common/mobile/CurrentDate.js';
// import SnackBarPopup from 'components/common/mobile/SnackBarPopup.js';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing.unit * 2,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing.unit,
      top: theme.spacing.unit,
      color: theme.palette.grey[500],
    },
  }))(props => {
    const { children, classes, onClose } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });


class PatientSignaturePadClass extends Component {

    constructor(props) {
      super(props);
      this.signaturePad = null;
      
      this.state = {
        step: 1,
        signatoryType: "Patient",
        signatoryTypeHasError: false,
        firstName: "",
        firstNameHasError: false,
        lastName: "",
        lastNameHasError: false,
        signatureData: [],
        title: "Patient Signature",
        signatureDialogOpened : false,
        signatureClearButtonDisabled : true,
        ratio: 1,
        canvasWidth: 0,
        canvasHeight: 0,
        maxDrawWidth: 0,
        maxDrawHeight: 0
      }
    }

    static CANCEL = () => {
      return "cancel";
    }
    static SUBMIT = () =>{
      return "submit";
    }
    
    componentDidUpdate = (prevProps) => {
        // console.log("this.props.visible " + this.props.visible);
        // console.log("prevProps.visible " +  prevProps.visible );
        if(this.state.signatureDialogOpened !== this.props.visible) {
            this.setState({...this.state, step: 1, signatureDialogOpened: this.props.visible});
        }
    }

    updateSignpadDimensions = () => {  
      var dialog_content = $("#patient-signatory-dialog > div > div > div:nth-child(2)");
  
      var padding = parseInt(dialog_content.css("padding-left"));
      var computed_width = (dialog_content.outerWidth() - (padding * 2));
      var computed_height = ((computed_width * .75) - (padding * 2));

      // this needed for trimming canvas
      // var signature_canvas = this.signaturePad.canvas;
      // console.log(this.signaturePad.canvas)
      // signature_canvas.width = computed_width; 
      // signature_canvas.height = computed_height;
  
      // this needed for updating dimension and redrawing signature when chaing orientation
      var signature_canvas_jquery = $("#patient-signature-pad-container > canvas"); 
      // console.log(signature_canvas_jquery)
      signature_canvas_jquery.css({
        width: computed_width + "px",
        height: computed_height + "px"
      });

      this.setState({
        ...this.state, 
        canvasWidth: computed_width, 
        canvasHeight: computed_height,
      });
  
      if(this.signaturePad) {  
        // comment out to preserve signature data, clear only if submitted
        // to catch if signature pad is displayed
        //   this.signaturePad.clear();
      }
    }

    getTrimmedCanvas = () => {
      // copy the canvas
      var canvas = this.signaturePad.canvas; 
      // console.log(canvas);
      var copy = document.createElement('canvas')
      copy.width = canvas.width;
      copy.height = canvas.height;
      copy.getContext('2d').drawImage(canvas, 0, 0)
      // then trim it
      return trimCanvas(copy)
    }
  
    handleSignatureDialogEntered = () => {
      window.addEventListener("resize", this.updateSignpadDimensions);
    }
  
    handleSignatureDialogExited = () => {
      this.setState({
        ...this.state, 
        signatureData: [], 
        signatureClearButtonDisabled : true 
      });
      window.removeEventListener("resize", this.updateSignpadDimensions);
    }
  
    handleCloseSignatureDialog = () => {
      this.props.onEvent({
        command: PatientSignaturePad.CANCEL(),
        signatory_type: "",
        first_name: "",
        last_name: "",
        sign_off: "",
        ratio: 1,
        canvasWidth: 0,
        canvasHeight: 0,
        maxDrawWidth: 0,
        maxDrawHeight: 0
      });
      this.setState({...this.state, signatureDialogOpened: false });
    };
    
    onTouchSignature = (data) => {
      var trimmed_canvas = this.getTrimmedCanvas();
      this.setState({
        ...this.state, 
        signatureData: this.signaturePad.toData(), 
        signatureClearButtonDisabled : false,
        maxDrawWidth: trimmed_canvas.width,
        maxDrawHeight: trimmed_canvas.height
      });
    };
    
     handleClearApprovalSignature = () => {
      this.signaturePad.clear();
      this.setState({
        ...this.state, 
        signatureData: [], 
        signatureClearButtonDisabled : true 
      });
    };
    
    handleSubmitApprovalSignature = event => {
      this.props.onEvent({
        command: PatientSignaturePad.SUBMIT(),
        signatory_type: this.state.signatoryType,
        first_name: this.state.signatoryType === "Guardian" ? this.state.firstName : "",
        last_name: this.state.signatoryType === "Guardian" ? this.state.lastName : "",
        sign_off: this.state.signatureData,
        ratio: window.devicePixelRatio || 1,
        canvasWidth: this.state.canvasWidth,
        canvasHeight: this.state.canvasHeight,
        maxDrawWidth: this.state.maxDrawWidth,
        maxDrawHeight: this.state.maxDrawHeight
      });
    };

    handleChangeStep = (step) => {
      // check only on current step
      if(this.isFieldsUIWithError() && this.state.step ===1) {
        this.updateFieldsUIError();
        return;
      }
      
      this.setState({...this.state, step : step }, function() {
        // callback after signaturePad is loaded, then load the existing signature
        if(this.signaturePad && step === 2) {          
          this.updateSignpadDimensions();
          // console.log(this.state.signatureData)
          this.signaturePad.fromData(this.state.signatureData)
        }
      });
    }

    handleChangeSignatoryType = (event) => {
      const name = event.target.name;
      this.setState({ 
        ...this.state, 
        [name]: event.target.value, 
        signatoryTypeHasError: false,
        firstNameHasError: false,
        lastNameHasError: false,
      });
    }

    handleChangeName = (event) => {
      const name = event.target.name;
      this.setState({...this.state, [name]: event.target.value, [name + "HasError"]: false });
    }

    isFieldsUIWithError = () => {
      if(this.state.signatoryType ==="Guardian") {
        return this.state.signatoryType ==="" || 
        this.state.firstName ==="" ||
        this.state.lastName ==="";
      } else {
        return this.state.signatoryType ==="";
      }
    }

    updateFieldsUIError = () => {
      this.setState({
        ...this.state,
        signatoryTypeHasError: this.state.signatoryType ==="",
        firstNameHasError: this.state.firstName ==="",
        lastNameHasError: this.state.lastName ==="",
      });
    }
    
    render() {
        const { classes } = this.props;

        return(
          <Dialog
            id="patient-signatory-dialog"
            fullScreen={false}
            classes={{
              paper: classes.dialogPaperSignature
            }}
            open={this.state.signatureDialogOpened}
            onEntered={this.handleSignatureDialogEntered}
            onExited={this.handleSignatureDialogExited}
          >
            <DialogTitle onClose={this.handleCloseSignatureDialog}>{this.state.title}</DialogTitle>
            <DialogContent className={classes.signatureDialogContentContainer}>   
              {(this.state.step ===1) &&   
              <div>
                <Typography variant="caption" className={classes.signNote}>Select signatory type:</Typography>
                <FormControl 
                  fullWidth={true} 
                  error={this.state.signatoryTypeHasError}>
                  <InputLabel htmlFor="signatory-type-el">Signatory Type *</InputLabel>
                  <Select
                    native
                    value={this.state.signatoryType}
                    onChange={this.handleChangeSignatoryType}
                    inputProps={{
                      name: 'signatoryType',
                      id: 'signatory-type-el',
                    }}
                    required
                  >
                    <option value={"Patient"}>Patient</option>
                    <option value={"Guardian"}>Guardian</option>
                  </Select>
                  {this.state.signatoryTypeHasError && <FormHelperText>Required.</FormHelperText>}
                </FormControl>
                {(this.state.signatoryType ==="Guardian") &&
                  <div>
                    <TextField
                      required={true}
                      inputProps={{
                        name: 'firstName',
                        id: 'first-name-el',
                      }}
                      value={this.state.firstName}
                      onChange={this.handleChangeName}
                      id="signatory-first-name"
                      label="First Name"
                      margin="normal"
                      error={this.state.firstNameHasError}
                      helperText={this.state.firstNameHasError ? "Required." : ""}
                      fullWidth                      
                    />
                    <TextField
                      required={true}
                      inputProps={{
                        name: 'lastName',
                        id: 'last-name-el',
                      }}
                      value={this.state.lastName}
                      onChange={this.handleChangeName}
                      id="signatory-last-name"
                      label="Last Name"
                      margin="normal"
                      error={this.state.lastNameHasError}
                      helperText={this.state.lastNameHasError ? "Required." : ""}
                      fullWidth
                    />
                  </div>
                }
              </div>
              }
              {(this.state.step ===2) && 
              <div>
                <Typography variant="caption" className={classes.signNote}>Please sign below:</Typography>
                <div
                  id="patient-signature-pad-container"
                  className={classes.signaturePadContainer}>
                  <SignaturePad 
                    id="patient-signature-canvas"
                    redrawOnResize={true}
                    options={{
                      onEnd: this.onTouchSignature
                    }} 
                    ref={ref => this.signaturePad = ref} />
                </div>
              </div>
              }
            </DialogContent>
            
            {(this.state.step ===1) && 
            <DialogActions>
              <Button 
                onClick={() => this.handleChangeStep(2)} className={classNames(classes.signatureButton)} variant="contained" color="secondary">
                Continue
              </Button> 
            </DialogActions>
            }
            {(this.state.step ===2) &&
            <DialogActions>
              <Button
                onClick={() => this.handleChangeStep(1)}
                className={classNames(classes.signatureButton, classes.floatLeft)} variant="contained" color="default">
                  Back
              </Button>
              {/* <Button
                disabled={this.state.signatureClearButtonDisabled}
                onClick={this.handleClearApprovalSignature} className={classNames(classes.signatureButton)} variant="contained" color="default">
                  Clear
              </Button> */}
              <Button               
                disabled={this.state.signatureClearButtonDisabled}
                onClick={this.handleSubmitApprovalSignature} className={classNames(classes.signatureButton)} variant="contained" color="secondary">
                  Submit
              </Button> 
            </DialogActions>
            }            
          </Dialog>
          );
          
    }

}
PatientSignaturePadClass.propTypes = {
  onEvent :  PropTypes.func.isRequired 
}
const PatientSignaturePad =  withStyles(styles)(PatientSignaturePadClass);
export default PatientSignaturePad;
