// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './components/App';
// import * as serviceWorker from './serviceWorker';

// ReactDOM.render(<App />, document.getElementById('root'));

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
//import index from "./js/index"

import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import store from "./js/store/index";
import App from "./components/App";
import * as serviceWorker from './serviceWorker';
import { CookiesProvider } from 'react-cookie';
import * as globals from 'components/common/GlobalConstants';
render(
    <Provider store={store}>
        <CookiesProvider>
            <App />
        </CookiesProvider>
    </Provider>,
    document.getElementById("root")
);

// serviceWorker.register('service-worker.js').then(function (registration) {
//   var serviceWorker;
//   if (registration.installing) {
//       serviceWorker = registration.installing;
//      // document.querySelector('#kind').textContent = 'installing';
//       console.log('installing')
//   } else if (registration.waiting) {
//       serviceWorker = registration.waiting;
//       //document.querySelector('#kind').textContent = 'waiting';
//       console.log('waiting')
//   } else if (registration.active) {
//       serviceWorker = registration.active;
//       //document.querySelector('#kind').textContent = 'active';
//       console.log('active')
//   }
//   if (serviceWorker) {
//       // logState(serviceWorker.state);
//       serviceWorker.addEventListener('statechange', function (e) {
//           // logState(e.target.state);
//       });
//   }
// }).catch (function (error) {
//   // Something went wrong during registration. The service-worker.js file
//   // might be unavailable or contain a syntax error.
// });

// const updateServiceWorker = () => {
//   const registrationWaiting =  navigator.serviceWorker.waiting;
//   if (registrationWaiting) {
//     registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
//     registrationWaiting.addEventListener('statechange', e => {
//      // if (e.target.state === 'activated') {
//         window.location.reload();
//      // }
//     });
//   }
// };
if (globals.OFFLINE_MODE_ENABLED) {
    serviceWorker.register()
} else {
    serviceWorker.unregister()
}



//updateServiceWorker()
// serviceWorker.register('service-worker.js')
//   .then(function(registration) {
//  		// Listen for updates:
//     registration.addEventListener('updatefound', () => {
//       navigator.newWorker = registration.installing;
//       console.log("Update found..")
//       console.log(navigator.newWorker)
// 			// Listen for when the new worker is ready:
//       // navigator.newWorker.addEventListener('statechange', () => {
//       //   switch (navigator.newWorker.state) {
//       //     case 'installed':
//       //       if (navigator.serviceWorker.controller) {
//  			// 				// Display button:
//       //         //refreshButton.classList.remove('hidden');
//       //       }
//       //       break;
//       //   }
//       // });
//     });
//   });
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {

        // if(registration.waiting) {
        //   console.log("NEW APP RELEASE PLEASE CLICK UPDATE IN SETTINGS MENU.");
        //   navigator.newServiceWorker = registration.waiting;
        //   this.props.setNewAppVersion(true)
        // }

        setInterval(() => {
            console.log("Checking for updates....")
            registration.update().then(() => {
                // console.log("Success in getting updates.")
            }).catch(() => {
                //console.log("Unable to get updates from the server.")
            })
        }, globals.POOL_UPDATE_INTERVAL)

    })
}


