import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
// import classNames from 'classnames'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"
// import moment from 'moment'
// import uuid from "uuid"

// import Typography from '@material-ui/core/Typography'
import * as helper from 'components/common/Helper.js';
import UpcomingOrdersList from './UpcomingOrdersList'

const mapStateToProps = state => {
    return {
        drawData: state.drawData,
        futureOrders: state.futureOrders
    }
}

const UpcomingOrdersFunctionClass = (props) => {
    const { futureOrders } = props
    const [active, setActive] = useState(0)
    const [orderedFO, setOrderedFO] = useState([])

    console.log("FutureOrders")
    console.log(futureOrders)

    // order by collection_time

    // if (drawData.draw_list !== undefined) {
    //     ordered = drawData.draw_list.sort((a, b) => moment(a.collection_time, "HH:mm:ss").utc() - moment(b.collection_time, "HH:mm:ss").utc())
    // }

    useEffect(() => {
        // var noOfDays = 0
        // var totalAppointments = 0
        // var totalOrders = 0
        console.log("Future orders")
        console.log(futureOrders)
        var ordered = []
        if (futureOrders !== undefined) {
            var sorted_fo = helper.sortFutureOrdersObjectByKey(futureOrders.future_orders, 'asc');

            for (var date_key in sorted_fo) {
                // if (futureOrders.future_orders[date_key].draw_list[0] !== undefined) {
                //     noOfDays++
                //     totalAppointments++
                // }

                if (futureOrders.future_orders[date_key].draw_list.length > 0) {
                    ordered.push(futureOrders.future_orders[date_key])
                }

                // if (futureOrders.future_orders[date_key].draw_list[0] !== undefined &&
                //     futureOrders.future_orders[date_key].draw_list[0].patient_list !== undefined) {
                //     futureOrders.future_orders[date_key].draw_list[0].patient_list.forEach(patient => {
                //         totalOrders++
                //     })
                // }
            }
            // setTotalNumberOfDays(noOfDays) 
            // setTotalNumberOfAppointments(totalAppointments)
            // setTotalNumberOfOrders(totalOrders)
        }
        // console.log("ordered")
        // console.log(ordered)
        setOrderedFO(ordered)
    }, [futureOrders])


    useEffect(() => {
        if (orderedFO.length > 0) {
            setActive(orderedFO[0].id)
        }

    }, [orderedFO])

    const [expanded, setExpanded] = useState("")

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }


    return (
        <>
            {orderedFO.map((fo_item) => {
                console.log("draw_list =")
                console.log(fo_item)
                return (
                    <UpcomingOrdersList
                        key={fo_item.collection_date}
                        collection_date={fo_item.collection_date}
                        draw_list={fo_item.draw_list}
                        active={active}
                        expanded={expanded}
                        handleChange={handleChange}
                    />
                )
            })}

        </>
    )
}

const UpcomingOrders = withCookies(
    connect(mapStateToProps)(
        withStyles(styles, { withTheme: true })(UpcomingOrdersFunctionClass)
    )
)
export default UpcomingOrders
