import React from 'react';
import MDSpinner from "react-md-spinner";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

class LoadingSpinner extends React.Component{
    
    render() {
        return(
            <Grid
                container
                alignContent="center"
                direction="column"
                justify="center"
                alignItems="center"
                style={{height: '90vh'}}
                >
                <MDSpinner size={50} />
                <br/>
                <br/>
                <Typography variant="h6">
                Loading...
                </Typography>
            </Grid>
        );
    }
}

export default LoadingSpinner;