import * as globals from 'components/common/GlobalConstants'
//import grey from '@material-ui/core/colors/grey';

const styles = theme => ({
    root: {
        // display: 'flex',
        // fullWidth: true
    },
    dialogPaperForNotes: {
        minWidth: 300,
        margin: "20px",
        borderRadius: "10px"
    },
    content: {
        padding: "20px !important"
    },
    actions: {
        padding: "20px !important",
        margin: "0px"
    },
    actionButton: {
        display: "block",
        margin: 0,
        marginLeft: 12,
        boxShadow: "none",
        textTransform: 'capitalize',
        padding: "10px 16px",
        fontSize: "14px !important",
        backgroundColor: "#ffdc51",
        color: "black",
        fontWeight: 500,
        borderRadius: "4px",
        "&:focus": { backgroundColor: "#ffdc51 !important" },
        "&:active": { backgroundColor: "#ffdc51 !important" },
        "&:hover": { backgroundColor: "#ffdc51 !important" },
        "&:disabled": { backgroundColor: "#e6e6e6 !important" },
    },
    cancelButton: {
        backgroundColor: "#f7f7f7",
        color: "black",
        "&:focus": { backgroundColor: "#f7f7f7 !important" },
        "&:active": { backgroundColor: "#f7f7f7 !important" },
        "&:hover": { backgroundColor: "#f7f7f7 !important" },
        "&:disabled": { backgroundColor: "#f7f7f7 !important" },
    },
    confirmTitle: {
        fontSize: "20px !important",
        fontWeight: 600
    },
    confirmAvatar: {
        backgroundColor: globals.v2.COLORS.BLUE,
        width: "40px",
        height: "40px",
        marginRight: "10px",
        "& svg": {
            fill: "white"
        }
    },
    confirmIcon: {
        fontSize: "28px !important"
    },
    warningAvatar: {
        backgroundColor: globals.v2.COLORS.RED,
        width: "40px",
        height: "40px",
        marginRight: "10px",
        "& svg": {
            fill: "white"
        }
    },
    warningIcon: {
        fontSize: "28px !important"
    },
    confirmMessage: {
        marginLeft: "50px",
        fontSize: "14px !important"
    },
});

export default styles;
