import React from 'react' // , { useState }
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import styles from "./styles.js"


import { ReactComponent as TeloPointLogoSVG } from 'components/common/mobile/CustomOfflineSVG/TeloPoint.svg'

const LogsDetails = (props) => {
    const { classes } = props
     return (
        <div>
            <div >
                <br />
                <Typography className={classes.aboutLabel}>
                Your system log will be sent to Telopoint
                for analysis. The system log data will be 
                used to review activities, identify errors
                and improve your overall app experience. 
                </Typography>
              </div>
                    
        </div>
    )
}

export default
    withMobileDialog()(
        withStyles(styles)
            (LogsDetails)
        
    )
