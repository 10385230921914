import React, {useState} from 'react'

import { withStyles } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles';
import QRCodeStyles from './QRCodeStyles'

import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import classNames from 'classnames'

import QRCode from "react-qr-code";

import { FormControl, Button } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const QRCodeFunctionClass = (props) => {
    const { classes, history } = props

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
          setOpen(true);
        };
      
    const handleClose = () => {
          setOpen(false);
        };
    
    const NewPatient = () => {
        history.push("./NewPatient")
    };

    const [qrValue, setQrValue] = useState("");
    const handleChange = (event) => {
        setQrValue(event.target.value);
        
    };

    const [color, setColor] = useState("");
    const generateRandomColor = () => {
        const letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        setColor(color);
      };
      
    return (
        <>
        <div className={classes.patientDetailsContainer}>
        <FormControl variant="outlined" className={classNames(classes.fullWidth)}>
            <Button onClick={handleClickOpen} className={classes.cancelButton} > Scan QR Code </Button>
                <Dialog open={open} onClose={handleClose} aria-labelledby="confirm-title" id="confirm-dialog" disableBackdropClick={true} fullScreen={false}
                    classes = {{ paper: classes.cancelDialogBox}} maxWidth={"md"}
                    >
                        <DialogContentText id="form-dialog-title" className={classes.labels} variant="h4" >
                            Scan the QR Code
                        </DialogContentText>

                            <DialogContent className={classNames(classes.searchDialogBox)}>
                                <FormControl className={classNames(classes.qrCodeBox)}>
                                    {/* <QRCode value="https://chat.openai.com/chat" size={250}/> */}

                                    <QRCode value={qrValue} size= {250} fgColor={color}  />                                   
                            <br/>
                                    <input 
                                        type="search" 
                                        placeholder="Type to generate code" 
                                        onChange={handleChange} 
                                        value={qrValue} 
                                        className={classNames(classes.TextFieldStyle)} 
                                    />
                            <br/>
                                    <div>
                                        <button onClick={generateRandomColor}>Generate Random Color</button>
                                    </div>
                                </FormControl>

                                <DialogContentText className={classes.dialogText}>
                                    Ask the patient to scan the code and fill out the patient registration form
                                </DialogContentText>
                            </DialogContent>
        
                        <DialogContent className={classNames(classes.buttonPosition)}>
                            <Button classes={{root: classes.addBtn, label: classes.addBtnLabel}} onClick={NewPatient}> Add Patient Manually </Button>
                        </DialogContent>
                </Dialog>       
            </FormControl>
            </div>              
        </>
    )
}
const QRCodeDialog = withRouter (
withCookies(
    connect(mapStateToProps)(
        withStyles(QRCodeStyles, { withTheme: true })(QRCodeFunctionClass)
        )
    )
)
export default QRCodeDialog