import React, { useState, useEffect, useLayoutEffect } from 'react'

import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

import moment from 'moment'
import classNames from 'classnames'

import Typography from '@material-ui/core/Typography'
import uuid from 'uuid'
import AddIcon from '@material-ui/icons/Add'

import * as globals from 'components/common/GlobalConstants'
import * as helper from 'components/common/Helper.js'
// import $ from 'jquery'

const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const WeeklyFunctionClass = (props) => {
    const {
        classes,
        history,
        activeStartDate,
        weeklyCalendarUUID,
        expandedScheduleDays
    } = props

    const { height } = helper.useWindowDimensions()

    // all components on top and below of fullcalender weeely view
    const computed_height = height - (20 + 76 + 57 + 30 + 60 + 70) 
    // console.log("height", height)
    // console.log("computed_height", computed_height)

    const dateFormat = "YYYY-MM-DD"
    // const [localStartDate, setLocalStartDate] = useState(activeStartDate) // OPTIMIZATION: to get work schedule only on specific week
    const [workScheduleAsEvents, setWorkScheduleAsEvents] = useState([])

    const processEventsFromWorkSchedule = (schedules) => {
        var processedEvents = []
        schedules.forEach(schedule => {
            // console.log(schedule.date_from + 'T' + schedule.time_from + " -- " + schedule.date_to + 'T' + schedule.time_to)
            processedEvents.push({
                start: schedule.date_from + 'T' + schedule.time_from, // iso format e.g. '2022-05-27T08:00:00'
                end: schedule.date_to + 'T' + schedule.time_to,
                extendedProps: {
                    schedule: schedule,
                    isAddButton: false,
                    newSchedule: false
                }
            })
        })
        setWorkScheduleAsEvents(processedEvents)
    }

    // OPTIMIZATION
    // const getWeekDaysByWeekNumber = (weeknumber) => {
    //     var date = moment().isoWeek(weeknumber || 1).startOf("week"), weeklength = 7, result = [];
    //     while (weeklength--) {
    //         result.push(date.format(dateFormat));
    //         date.add(1, "day")
    //     }
    //     return result;
    // }

    useEffect(() => {
        // REAL: OPTIMIZATION to consider, instead of plotting all schedule in 1 call, filter by week dates and change on every switch of week
        // var today = moment(localStartDate).format(dateFormat)
        // var weekNumber = moment(today).isoWeek()
        // var datesInCurrentWeek = getWeekDaysByWeekNumber(weekNumber)

        // // get schedules of current week days
        // var filteredByDate = expandedScheduleDays.filter(item => {
        //     return datesInCurrentWeek.includes(item.date_from)
        // })
        // processEventsFromWorkSchedule(filteredByDate)

        processEventsFromWorkSchedule(expandedScheduleDays)
    }, [expandedScheduleDays])

    const previewWorkSchedule = (schedule) => {
        history.push("work-schedule/preview/" + schedule.id)
    }

    const addNewSchedule = (date, time) => {
        history.push("/work-schedule/manage/0/" + date + "/" + time)
    }

    const toggleAddButton = (date) => {
        const selected_date = moment(date, "YYYY-MM-DD HH").format("YYYY-MM-DD HH") + ":00"

        // check if clicked day doesn't have schedule
        const selected_date_events = workScheduleAsEvents.filter((event) => {
            // const event_date = moment(event.start, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm")
            return event.start === moment(date, "YYYY-MM-DD HH")
            // selected_date: do not use + ":00"
        })

        const can_be_added = selected_date_events.length === 0
        if (can_be_added) {
            var newProcessEvents = [...workScheduleAsEvents]
            // check if add btn existed and remove - to have only 1 add button
            var indexOfObject = newProcessEvents.findIndex(event => event.extendedProps.isAddButton)
            if (indexOfObject !== -1) {
                newProcessEvents.splice(indexOfObject, 1)
            }

            // add new button
            const date_start = selected_date
            const date_to = moment(selected_date, "YYYY-MM-DD HH:mm").add(1, "hours").format("YYYY-MM-DD HH:mm")
            const new_event = {
                // start: selected_date + 'T' + globals.v2.SCHEDULE.MININUM_START_TIME + ":00",
                // end: selected_date + 'T' + globals.v2.SCHEDULE.MAXIMUM_END_TIME + ":00",
                start: date_start,
                end: date_to,
                extendedProps: {
                    schedule: {
                        id: 0,
                        date_from: date_start,
                        date_to: date_to,
                    },
                    isAddButton: true,
                    newSchedule: true
                }
            }
            newProcessEvents.push(new_event)

            console.log("new_event")
            console.log(new_event)
            setWorkScheduleAsEvents(newProcessEvents)
        }
    }

    return (
        <>
            <FullCalendar
                key={weeklyCalendarUUID}
                initialDate={moment(activeStartDate).format(dateFormat)}
                plugins={[timeGridPlugin, interactionPlugin]}
                initialView="timeGridWeek"
                allDaySlot={false}
                headerToolbar={{
                    start: 'prev',
                    center: 'title',
                    end: 'next' // today
                }}
                height={computed_height + "px"}
                slotMinTime={globals.v2.SCHEDULE.MININUM_START_TIME + ":00"} // "08:00:00"
                // needs 24:00 to include 11:30 on the view
                // workaround: we need 10mins to show full grid of 12pm
                slotMaxTime="24:05:00" 
                nowIndicatorClassNames="fc-now"
                dayHeaderContent={(arg) => {
                    var today = moment(new Date()).format(dateFormat)
                    var contentDate = moment(arg.date).format(dateFormat)

                    return <Typography
                        className={classNames(classes.headerText, today === contentDate ? classes.headerToday : "")}
                    >{moment(arg.date).format("ddd")}<br />{moment(arg.date).format("D")}</Typography>
                }}
                slotLabelContent={(arg) => {
                    return <Typography className={classes.slotTimeLabel}>{moment(arg.date).format("h A")}</Typography>
                }}
                events={workScheduleAsEvents}
                eventTimeFormat={{ // like '14:30:00'
                    hour: '2-digit',
                    minute: '2-digit',
                    meridiem: true
                }}
                eventContent={(arg) => {
                    const extProps = arg.event._def.extendedProps
                    // break "08:00 AM - 04:30 PM" per word
                    return <div
                        className={classNames(classes.eventTextContainer, extProps.isAddButton ? classes.eventAddbutton : "")}
                    // **IMPORTANT/NOT USING THIS: touch dimension is larger than the actual div size, causing other days to be clickable when clicking event container
                    // onClick={() => {
                    //     console.log(extProps)
                    //     // if (extProps.newSchedule) {
                    //     //     addNewSchedule(extProps.schedule.date_from)
                    //     // } else {
                    //     //     previewWorkSchedule(extProps.schedule)
                    //     // }
                    // }}
                    >
                        {(extProps.isAddButton)
                            ? <AddIcon className={classes.addButtonIcon} />
                            : arg.timeText.split(' ').map((word) => <Typography key={uuid.v4()} className={classes.eventText}>{word}</Typography>)}
                    </div>
                }}
                dateClick={(info) => {
                    // console.log("info: ",info)
                    const selected_date = moment(info.date).format("YYYY-MM-DD HH") + ":00:00"
                    // console.log("selected_date: ", selected_date)
                    const indexOfObject = workScheduleAsEvents.findIndex(event => {
                        const sched = event.extendedProps.schedule
                        // console.log("selected_date")
                        // console.log(selected_date)
                        // console.log("event.extendedProps.schedule")
                        // console.log(event.extendedProps.schedule)
                        return moment(selected_date, "YYYY-MM-DD HH:mm:ss").isSameOrAfter(moment(sched.date_from + " " + sched.time_from, "YYYY-MM-DD HH:mm:ss")) &&
                            moment(selected_date, "YYYY-MM-DD HH:mm:ss").isBefore(moment(sched.date_to + " " + sched.time_to, "YYYY-MM-DD HH:mm:ss"))
                        // return selected_date === event.extendedProps.schedule.date_from
                    })
                    const dateClicked = workScheduleAsEvents[indexOfObject]

                    console.log("indexOfObject: ", indexOfObject)
                    console.log("dateClicked")
                    console.log(dateClicked)
                    // if schedule found, redirect to add/edit page
                    if (dateClicked) {
                        if (dateClicked.extendedProps.newSchedule) {
                            const schedule_from = moment(dateClicked.extendedProps.schedule.date_from, "YYYY-MM-DD HH:mm")
                            addNewSchedule(schedule_from.format("YYYY-MM-DD"), schedule_from.format("HH:mm"))
                        } else {
                            previewWorkSchedule(dateClicked.extendedProps.schedule)
                        }
                    } else {
                        const isPastDate = moment(selected_date).isBefore(moment())
                        if (isPastDate) {
                            console.log("Cannot add schedule from past date: " + selected_date)
                            return
                        }
                        toggleAddButton(info.date)
                    }
                }}
                // eventClick={(eventClickInfo) => {
                //     console.log("eventClickInfo")
                //     console.log(eventClickInfo)
                // }}
                // slotDuration={"00:30:00"}
                eventBorderColor="#F0FAFE"
                eventBackgroundColor="#F0FAFE"
                eventTextColor="#69CEF1"
            />
        </>
    )
}

const Weekly = withCookies(
    connect(mapStateToProps)(
        withStyles(styles, { withTheme: true })(WeeklyFunctionClass)
    )
)
export default Weekly
