import React from 'react';

// import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
// import Card from '@material-ui/core/Card';
// import CardMedia from '@material-ui/core/CardMedia';
// import CardActionArea from '@material-ui/core/CardActionArea';

import { withStyles } from '@material-ui/core/styles';
import styles from "./styles.js";
import { Typography } from '@material-ui/core';
import classNames from 'classnames';

import { ReactComponent as TeloPointLogoSVG } from 'components/common/mobile/CustomOfflineSVG/TeloPoint.svg';

const GPSLock = (props) => {
    //const classes = 
    // const [newValue, setNewValue] = useState("");
    // const [pointer, setPointer] = useState(0);
    // const {classes, digitCount, value, onChange, onComplete , keyPadMessage, keyPadInstruction} = props;
    const {classes, keyPadMessage} = props;
   
    return(        
        <div className={classes.keyContainer}>
          <div className={classes.logoContainer}>
            <Grid item xs={12}>
              <TeloPointLogoSVG className={classNames(classes.teloPointLogo)}></TeloPointLogoSVG>
            </Grid>                           

          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Typography className={classes.gpsError}>{"GPS is disabled!"}</Typography>
          <br />
          <br />
   
          <Typography className={classes.keyPadInstruction}>{"Please enable the GPS in your device."}</Typography>
          <br />
        <div>
 
        {/* <Grid container spacing={0} className={classes.keyDisplayContainer} > 
            {display}
        </Grid> */}
 
        </div>

        <br />
        <Typography className={classes.keyPadMessage}>{keyPadMessage}</Typography>
        <br />
        <br />
 
      </div>
    );
}

export default withStyles(styles) (GPSLock);