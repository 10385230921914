import React, { useState, useEffect, useRef } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
// import classNames from 'classnames'

import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { connect } from "react-redux"
import uuid from "uuid"

import PhotoCapture2 from 'components/common/mobile/PhotoCapture2'
import * as globals from 'components/common/GlobalConstants'
import {
    TapHere,
    CapturedPhotoDisplay,
    VenipunctureDropdown
} from './Step3Components.js'

const mapStateToProps = state => {
    return {
        drawData: state.drawData,
        workflowData: state.workflowData,
        drawRequestDocumentsData: state.drawRequestDocumentsData,
    }
}

const Step3FunctionClass = (props) => {
    const {
        isOrderRemaining,
        workflowData,
        patientWorkflowData,
        classes,
        onSetBtnNextDisabled,
        onChangeData,
        drawRequestDocumentsData,
        siteId,
        patientId,
        patientDrawId,
    } = props

    const photoCaptureRef = useRef(null)
    const patientReadOnly = false

    const venipunctureSiteLabel = "Venipuncture Site"

    var patientDocs = []
    var venipunctureSiteReqDoc = null
    if (drawRequestDocumentsData.draw_request_documents) {
        patientDocs = drawRequestDocumentsData.draw_request_documents.filter(function (draw_req_doc) {
            return draw_req_doc.drawlist_site_id === siteId && draw_req_doc.drawlist_patient_id === patientId
        })
    }
    // console.log("patientDocs")
    // console.log(patientDocs)

    // filter Venipuncture Site Category req doc
    // undefined if no result
    venipunctureSiteReqDoc = patientDocs.find(doc =>
        doc.metadata.image &&
        doc.metadata.image.category &&
        doc.client_deleted === false &&
        doc.metadata.image.category === venipunctureSiteLabel
    )

    // console.log("venipunctureSiteReqDoc")
    // console.log(venipunctureSiteReqDoc)

    // const [selectedVenipuncture, setSelectedVenipuncture] = useState("")
    // const [noCapturedPhoto, setNoCapturedPhoto] = useState(true)

    const [dataUri, setDataUri] = useState("") // load redux dataUri
    const [id, setID] = useState("") // load redux id
    const [data, setData] = useState({})

    useEffect(() => {

        var imgDoc = ""

        if (venipunctureSiteReqDoc) {
            if (venipunctureSiteReqDoc.doc_type === "offline_upload") {
                imgDoc = venipunctureSiteReqDoc.metadata.image.data
            } else if (venipunctureSiteReqDoc.doc_type === "offline_uploaded") {
                imgDoc = (globals.USE_PAMP_COUCHDB_SERVER) ? venipunctureSiteReqDoc.path : globals.SERVER + venipunctureSiteReqDoc.path
            }
        }

        // console.log("venipunctureSiteReqDoc")
        // console.log(venipunctureSiteReqDoc)
        setID(venipunctureSiteReqDoc ? venipunctureSiteReqDoc.id : "")
        setDataUri(venipunctureSiteReqDoc ? imgDoc : "")
    }, [venipunctureSiteReqDoc, setDataUri])

    useEffect(() => {
        setData({
            venipuncture_site: patientWorkflowData ? patientWorkflowData[0].workflow_data.venipuncture_site : "",
        })
    }, [workflowData.workflow, setData])
    // need: workflowData.workflow to listen when redux values changes from undefined to []
    // REAL: **Note: patientWorkflowData on useEffect will cause infinite loop

    const selectVenipuncture = (val) => {
        // condition 2: set false to enable next button
        var newObj = { ...data }
        newObj.venipuncture_site = val
        setData(newObj)
        onChangeData(newObj)
    }

    useEffect(() => {
        if (isOrderRemaining) {
            // to enable next button going Step 4
            if (data.venipuncture_site !== "" && dataUri !== "") {
                onSetBtnNextDisabled(false)
            } else {
                onSetBtnNextDisabled(true)
            }
        }

        // onChangeData(data)
    }, [data, dataUri, isOrderRemaining, onSetBtnNextDisabled])

    // console.log("Step3FunctionClass")
    // console.log(props)

    useEffect(() => {
        // force enable after done/unable
        if (!isOrderRemaining) {
            onSetBtnNextDisabled(false)
        }
    }, [isOrderRemaining, onSetBtnNextDisabled])

    useEffect(() => {
        // CALL YOUR API OR ASYNC FUNCTION HERE
        // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function
        return () => { photoCaptureRef.current = false }
    }, [])

    return (
        <>
            <VenipunctureDropdown isOrderRemaining={isOrderRemaining} selectedValue={data.venipuncture_site} classes={classes} selectVenipuncture={selectVenipuncture} />

            <div
                className={classes.venipunctureCapturedPhotoContainer}
            >
                {dataUri === "" || (typeof venipunctureSiteReqDoc === 'undefined' || venipunctureSiteReqDoc === null)
                    ?
                    <div
                        onClick={() => {
                            if (isOrderRemaining) {
                                photoCaptureRef.current.addNewPhoto({ id: id, category: venipunctureSiteLabel })
                            }
                        }}
                    >
                        <TapHere classes={classes} />
                    </div>
                    :
                    <div
                        onClick={() => {
                            photoCaptureRef.current.addNewPhoto({ id: id, category: venipunctureSiteLabel, dataUri: dataUri })
                        }}
                    >
                        <CapturedPhotoDisplay
                            key={uuid.v4()}
                            classes={classes}
                            data={{ item: venipunctureSiteReqDoc }}
                        />
                    </div>
                }
            </div>

            <PhotoCapture2
                // key={uuid.v4()} // needed to assign addNewPhoto to photoCaptureRef
                photoCaptureRef={photoCaptureRef}
                siteId={siteId}
                patientId={patientId}
                patientDrawId={patientDrawId}
                notes={null}
                target="patient"
                draw_request_documents={drawRequestDocumentsData}
                // addNewPhoto={m => photoCaptureRef.addNewPhoto = m}
                // deletePhoto={m => photoCaptureRef.deletePhoto = m}
                readOnly={patientReadOnly || !isOrderRemaining}
            />
        </>
    )
}

const Step3 = withRouter(
    withCookies(
        connect(mapStateToProps)(
            withStyles(styles, { withTheme: true })(Step3FunctionClass)
        )
    )
)
export default Step3
