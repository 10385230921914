import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
// import classNames from 'classnames'

import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import Typography from '@material-ui/core/Typography'
import {
    PatientInformationVerificationList,
    UnableToPerformOrderDialog
} from './Step1Components'

import moment from 'moment'

const mapStateToProps = state => {
    return {
        drawData: state.drawData,
        workflowData: state.workflowData
    }
}

const Step1FunctionClass = (props) => {
    const {
        isOrderRemaining,
        workflowData,
        classes,
        // below from AppointmentOrder index
        onSetBtnNextDisabled,
        onChangeData,
        order,
        patientWorkflowData,
        onConfirmUnableToPerformOrder
    } = props

    const [data, setData] = useState([])
    const [unableToPerformOrderDialogOpen, setUnableToPerformOrderDialogOpen] = useState(false)

    useEffect(() => {
        setData([
            {
                verified: patientWorkflowData ? patientWorkflowData[0].workflow_data.name_verified : false,
                key: "name_verified",
                label: "Name",
                value: order.patient.first_name + " " + order.patient.last_name
            },
            {
                verified: patientWorkflowData ? patientWorkflowData[0].workflow_data.dob_verified : false,
                key: "dob_verified",
                label: "Date of Birth",
                value: order.patient.birth_date ? moment(order.patient.birth_date, "YYYY-MM-DD").format("MMM D, YYYY") : "" //"Jan 20, 1990"
            },
        ])
    }, [order, workflowData.workflow, setData])
    // need: workflowData.workflow to listen when redux values changes from undefined to []

    var newArray = [...data]

    useEffect(() => {
        if(isOrderRemaining) {
            const notVerified = newArray.filter(info => !info.verified)
            onSetBtnNextDisabled(notVerified.length > 0)
        }
    }, [newArray, isOrderRemaining, onSetBtnNextDisabled])

    useEffect(() => {
        // force enable after done/unable
        if (!isOrderRemaining) {
            onSetBtnNextDisabled(false)
        }
    }, [isOrderRemaining, onSetBtnNextDisabled])

    return (
        <>
            <PatientInformationVerificationList
                classes={classes}
                newArray={newArray}
                data={data}
                isOrderRemaining={isOrderRemaining}
                setData={setData}
                onChangeData={onChangeData}
            />

            {isOrderRemaining &&
                <div className={classes.unableToPerformOrderButtonContainer}>
                    <Typography className={classes.unableToPerformOrderButton} onClick={() => setUnableToPerformOrderDialogOpen(true)}>Unable to Perform Order</Typography>
                </div>
            }

            <UnableToPerformOrderDialog
                classes={classes}
                open={unableToPerformOrderDialogOpen}
                onCancel={() => setUnableToPerformOrderDialogOpen(false)}
                onConfirm={(data) => onConfirmUnableToPerformOrder(data)}
            />
        </>
    )
}

const Step1 = withRouter(
    withCookies(
        connect(mapStateToProps)(
            withStyles(styles, { withTheme: true })(Step1FunctionClass)
        )
    )
)
export default Step1
