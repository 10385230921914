import React from 'react';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import $ from 'jquery';

import Badge from '@material-ui/core/Badge';
import SyncIcon from '@material-ui/icons/Sync';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { Detector } from "react-detect-offline";

import * as globals from 'components/common/GlobalConstants';
import styles from "./styles.js"

import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { connect } from "react-redux";
import { setOnlineStatus, logEvent, setNewAppVersion } from "js/actions/index";

import { ReactComponent as SettingsGrayFillSVG } from 'components/common/mobile/CustomOfflineSVG/settings_gray_fill.svg';
import { ReactComponent as SettingsSelectedSVG } from 'components/common/mobile/CustomOfflineSVG/settings_selected.svg';
import { ReactComponent as AlertSelectedSVG } from 'components/common/mobile/CustomOfflineSVG/alert_selected.svg';
import { ReactComponent as AlertGrayFillSVG } from 'components/common/mobile/CustomOfflineSVG/alert_gray_fill.svg';
import { ReactComponent as TeloPointLogoSVG } from 'components/common/mobile/CustomOfflineSVG/TeloPoint.svg';
import * as helper from 'components/common/Helper.js'
import Grid from '@material-ui/core/Grid';
import GPSControl from 'components/common/controls/GPSControl';
// import { Modal } from '@material-ui/core';
//import ClearCache from "react-clear-cache";
// //Commented out because it unused
// const SmallAvatar = withStyles((theme) => ({
//     root: {
//       width: 16,
//       height: 16,
//       border: `2px solid ${theme.palette.background.paper}`,
//     },
// }))(Avatar);

const mapDispatchToProps = dispatch => {
    return {
        setOnlineStatus: status => dispatch(setOnlineStatus(status)),
        logEvent: status => dispatch(logEvent(status)),
        setNewAppVersion: value => dispatch(setNewAppVersion(value))
    }
}

const mapStateToProps = state => {
    return {
        db: state.db,
        onlineStatus: state.onlineStatus,
        drawRequests: state.drawRequests,
        syncStatus: state.syncStatus,
        newAppVersion: state.newAppVersion
    }
}

class ApplicationBar extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        // console.log("ApplicationBar constructor");
        super(props);
        this.state = {
            // dateInterval: null,
            // totalNotificationItems: 0,
            anchorEl: null,
            aboutDialogOpened: false,
            syncIconDisabled: false,
            accessLocationBlocked: false
            // newSoftwareUpdates: false
        };

        //console.log("APPLICATION CONSTRUCTOR")
        this.checkGPSTimer = null;
        this.checkGPSInterval = 3000;
        this.innerRef = React.createRef();

    }

    // componentWillMount() {
    //     // console.log("ApplicationBar componentWillMount");

    // }


    componentDidMount() {
        // clearInterval(this.state.dateInterval);
        //console.log("ApplicationBar componentDidMount");
        this.handleRealTimeDate();
        // var interval = setInterval(() =>{ this.handleRealTimeDate() }, 10000); // 10 secs  

        // this.setState({ ...this.state, dateInterval: interval });
        this.checkGPSTimerHandler();
        this.checkGPSTimer = setInterval(this.checkGPSTimerHandler, this.checkGPSInterval);
    }

    componentWillUnmount() {
        // console.log("Freeing timer instance..")
        clearTimeout(this.checkGPSTimer);
    }

    checkGPSTimerHandler = () => {
        // console.log("Tik tok")

        /**Fetch GEOLocation */
        this.innerRef.current && this.innerRef.current.getLocation();
        /** */

        //console.log("this.innerRef.current.isGeolocationEnabled: " + this.innerRef.current.state.isGeolocationEnabled )

        // In blocking the screen due to GPS disabled 

        // if(this.innerRef.current.state.isGeolocationEnabled == false ){

        //     if(this.innerRef.current.state.positionError !== undefined) {
        //         console.log("GPS disabled")
        //         this.setState({ ...this.state, accessLocationBlocked: true})
        //     }
        // } else if(this.innerRef.current.state.isGeolocationEnabled){
        //     console.log("GPS enabled")
        //     this.setState({ ...this.state, accessLocationBlocked: false})
        // }


    }

    componentDidUpdate = (prevProps) => {
        // console.log("ApplicationBar componentDidUpdate");
        // console.log(this.state.totalNotificationItems);
        //if(prevProps.drawRequests !== this.props.drawRequests) {
        // this.loadData();
        //  }
    }

    // loadData = () => {
    //     console.log('loadDATA in application bar or new updates');
    //     // console.log(this.props.drawRequests);
    //      console.log("this.props.newAppVersion");
    //      console.log(this.props.newAppVersion);
    //     try {
    //         var count = 0;

    //         this.props.drawRequests.draw_request.map(obj => {
    //             if(obj.work_status === ''){
    //                 count++;
    //             }
    //             return true;
    //         });
    //         if(this.props.newAppVersion) {
    //             count++
    //             //count=1
    //         }
    //         // console.log(count + " <=> " + this.state.totalNotificationItems);
    //         if(count !== this.state.totalNotificationItems)  {
    //            this.setState({...this.state, totalNotificationItems: count });
    //         }

    //     } catch(e) {

    //     }
    // }

    handleRealTimeDate() {
        var currentDate = new Date(),
            display_day = currentDate.getDay(),
            day = currentDate.getDate(),
            month = currentDate.getMonth() + 1,
            year = currentDate.getFullYear();

        // var currentTime = new Date(),
        // hours = currentTime.getHours(),
        // minutes = currentTime.getMinutes();

        // if (minutes < 10) {
        //     minutes = "0" + minutes;
        // }

        // var suffix = "AM";
        // if (hours >= 12) {
        //     suffix = "PM";
        //     hours = hours - 12;
        // }
        // if (hours === 0) {
        //     hours = 12;
        // }
        // var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        // var days = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
        var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

        // console.log(days[display_day] + ", " + months[month - 1] + " " + day + ", " + year + " " + hours + ":" + minutes + " " + suffix);
        $("#datetime-holder").html(days[display_day] + ", " + months[month - 1] + " " + day + ", " + year);// + " " + hours + ":" + minutes + " " + suffix);
    };

    handleOpenDialog = () => {
        this.setState({ aboutDialogOpened: true });
    };

    handleCloseDialog = () => {
        this.setState({ aboutDialogOpened: false });
    };

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    forceSync = () => {

        if (!globals.ALLOW_USER_TO_FORCE_SYNC) {
            return
        }

        const { syncIconDisabled } = this.state;
        if (!syncIconDisabled) {
            console.log("Force Sync...");
            this.setState({ ...this.state, syncIconDisabled: true });
            //this.props.db.sync();
            this.props.db.createAndConnectPouchDB();

            window.setTimeout(function () {
                this.setState({
                    syncIconDisabled: false,
                });
                //console.log("syncIconDisabled: " + this.state.syncIconDisabled)
            }.bind(this), globals.FORCE_SYNC_TIMEOUT_BEFORE_ANOTHER_TRIGGER)
        } else {
            //console.log("syncIconDisabled: " + syncIconDisabled)
        }
    }

    // handleBackButtonClick () {
    //     document.location.href = this.props.back;
    // };

    // handleLogoutClick(e) {
    //     const { cookies } = this.props;

    //     cookies.set('credentials', '', { path: '/' });

    //     window.location.href = '/';
    // };

    render() {
        const { classes, isInfoHidden, newAppVersion } = this.props;
        //const  server_sync  =  this.props.syncStatus
        const path_name = (window.location.pathname.split('/')[1]);
        const info_hidden = isInfoHidden ? isInfoHidden : false;
        // const creds = cookies.get('credentials');
        // const display_name = creds.display_name;
        // console.log("applicationbar")

        const { cookies } = this.props
        const server_sync = cookies.get('sync_status') || globals.SYNCED;
        //console.log("SYNC STATUS " + server_sync)

        var sync_image_icon = '';
        var sync_icon_cont_class = '';
        if (server_sync === globals.SYNCED) {
            sync_icon_cont_class = classes.sync_icon_synched;
        } else if (server_sync === globals.UNSYNCED) {
            sync_icon_cont_class = classes.sync_icon_unsynched;
        } else if (server_sync === globals.SYNCED_UP) {
            sync_icon_cont_class = classes.sync_icon_up;
            sync_image_icon = <ArrowUpwardIcon className={classes.sync_badge_content_green} />;
        } else if (server_sync === globals.SYNCED_DOWN) {
            sync_icon_cont_class = classes.sync_icon_down;
            sync_image_icon = <ArrowDownwardIcon className={classes.sync_badge_content_green} />;
        } else if (server_sync === globals.SYNC_ERROR) {
            sync_icon_cont_class = classes.sync_icon_error;
            sync_image_icon = <PriorityHighIcon className={classes.sync_badge_content_red} />;
        }

        var totalNotificationItems = 0;

        if (newAppVersion) totalNotificationItems++

        try {
            this.props.drawRequests.draw_request.map(obj => {
                if (obj.work_status === '') {
                    totalNotificationItems++;
                }
                return true;
            });
        } catch (e) {
            // will throw map error because draw_request is undefined
        }

        // console.log("Detecting service worker")
        // if ('serviceWorker' in navigator) {
        //     navigator.serviceWorker.ready.then(registration => {
        //         if(registration.waiting) {
        //             console.log("NEW APP RELEASE PLEASE CLICK UPDATE IN SETTINGS MENU.");

        //             // if(this.state.newSoftwareUpdates === false) {
        //                 navigator.newServiceWorker = registration.waiting;

        //                 // if(globals.UPDATE_APP_AUTOMATICALLY){
        //                 //     helper.updateApp()
        //                 // }

        //                 this.props.setNewAppVersion(true)
        //                 // this.setState({...this.state, newSoftwareUpdates: true})
        //             // }
        //         }
        //     });   

        //     // navigator.serviceWorker.addEventListener('message', (message) => {
        //     //     console.log("message thrown");
        //     //     console.log(message);
        //     // });        
        // } else {
        //     console.log("No service worker")
        // }

        return (
            <div>
                <AppBar id="application_bar" position="fixed">
                    <Toolbar
                        classes={{
                            gutters: classes.toolbarGutters,
                            regular: classes.appBar
                        }}>
                        {/* {this.props.back !== undefined &&
                            <Link to={this.props.back}>
                                <IconButton className={classes.backButton} color="inherit" aria-label="Menu">
                                    <MenuIcon />
                                </IconButton>
                            </Link>
                        } */}
                        <div className={classes.grow}>
                            <TeloPointLogoSVG className={classNames(classes.logo)}></TeloPointLogoSVG>
                            {/* <img alt="TeloPoint" id="app_logo" className={classNames(classes.logo)} src="/images/TeloPoint.svg" /> */}
                        </div>
                        <div>
                            <GPSControl ref={this.innerRef} displayGPSIcon={globals.DISPLAY_GPS_ICON_ON_APPLICATION_BAR}
                                onGPSEvent={(data) => {
                                    //console.log("this.innerRef.current.isGeolocationEnabled: " + this.innerRef.current.state.isGeolocationEnabled )
                                    if (globals.DEBUG_ALLOWED) {
                                        // console.log("GPS data : ")
                                        // console.log(data)
                                    }

                                    // for review if we will use === or ==
                                    if (data.isGeolocationEnabled === false) {

                                        if (data.positionError !== undefined) {
                                            if (globals.DEBUG_ALLOWED) {
                                                console.log("GPS disabled")
                                                console.log(data)
                                            }

                                            if (this.state.accessLocationBlocked !== true)
                                                if (globals.ENFORCE_GPS_AFTER_LOGIN) {
                                                    this.setState({ ...this.state, accessLocationBlocked: true })
                                                }
                                        }

                                    } else if (data.isGeolocationEnabled) {

                                        //console.log("GPS enabled")

                                        if (data.coords) {
                                            window.$location.latitude = data.coords.latitude
                                            window.$location.longitude = data.coords.longitude
                                            window.$location.heading = data.coords.heading
                                        }

                                        if (this.state.accessLocationBlocked !== false) {
                                            this.setState({ ...this.state, accessLocationBlocked: false })
                                        }
                                    }

                                }}
                                onPositionError={(error) => {
                                    // console.log("GPS error : ")
                                    // console.log(error)
                                }}
                            />
                            <Detector render={({ online }) => {
                                if (this.props.onlineStatus === false) {
                                    if (online) {
                                        // console.log("WIFI = " + online)

                                        //this.props.db.sync()
                                        this.props.setOnlineStatus(true)
                                    }
                                } else {
                                    //console.log("WIFI = " + online)

                                    if (!online) this.props.setOnlineStatus(false)
                                }

                                return <Icon className={classNames(classes.wifiIcon, online ? classes.online : classes.offline)}>{online ? "wifi" : "wifi_off"}</Icon>
                            }

                            } />
                            {globals.SYNC_STATUS_VISIBLE &&
                                <IconButton
                                    onClick={this.forceSync}
                                    aria-label="Sync"
                                    className={classNames(classes.noHoverBG, classes.sync_icon_cont, sync_icon_cont_class)}
                                >
                                    <Badge
                                        overlap={"circle"}
                                        classes={{ badge: classes.sync_badge }}
                                        badgeContent={sync_image_icon}
                                    >
                                        <SyncIcon className={classes.icon} />
                                    </Badge>
                                </IconButton>
                            }
                            <IconButton
                                aria-label="Notification"
                                component={Link}
                                to="/notifications"
                                className={classNames(classes.notification_icon_cont, classes.noHoverBG, path_name === "notifications" ? classes.notification_icon_cont_selected : classes.notification_icon_cont_not_selected)}
                            >

                                <Badge
                                    classes={{ badge: classes.notificationBadge }}
                                    badgeContent={totalNotificationItems}
                                    //badgeContent={count}                                     
                                    color="error">
                                    {/* <NotificationsIcon className={classes.icon} />*/}
                                    {(path_name === "notifications")
                                        ?
                                        <AlertSelectedSVG className={classNames(classes.alertIcon)}></AlertSelectedSVG>
                                        // <img alt="TeloPoint Alert Icon" className={classNames(classes.alertIcon)} src="/images/alert_selected.svg" />
                                        :
                                        <AlertGrayFillSVG className={classNames(classes.alertIcon)}></AlertGrayFillSVG>
                                        // <img alt="TeloPoint Alert Icon" className={classNames(classes.alertIcon)} src="/images/alert_gray_fill.svg" />
                                    }
                                </Badge>

                                {/* <img 
                                    alt="notification icon" 
                                    className={classes.notification_icon} 
                                    src={path_name==="notifications"?"/images/alert_selected.svg":"/images/alert_gray_fill.svg"} /> */}
                            </IconButton>
                            <IconButton
                                aria-label="Settings"
                                component={Link}
                                to="/settings"
                                className={classNames(classes.noHoverBG, classes.settings_icon_cont)}
                            >
                                {path_name === "settings"
                                    ?
                                    <SettingsSelectedSVG className={classes.settings_icon}></SettingsSelectedSVG>
                                    :
                                    <SettingsGrayFillSVG className={classes.settings_icon}></SettingsGrayFillSVG>
                                }
                                {/* <img 
                                    alt="settings icon" 
                                    className={classes.settings_icon} 
                                    src={path_name==="settings"?"/images/settings_selected.svg":"/images/settings_gray_fill.svg"} /> */}
                            </IconButton>
                        </div>
                        <div>
                            {/* <ClearCache>
                                    {({ isLatestVersion, emptyCacheStorage }) => (
                                    <div>
                                        {!isLatestVersion && (
                                        <p>
                                            <a
                                            href="#"
                                            onClick={e => {
                                                e.preventDefault();
                                                emptyCacheStorage();
                                                
                                            }}
                                            > 
                                            Update version
                                            </a>
                                        </p>
                                        )}
                                    </div>
                                    )}
                                </ClearCache> */}
                            {/* {this.state.newSoftwareUpdates && 
                                 <div>
                                
                                 <p>
                                     <a
                                     href="#"
                                     onClick={e => {
                                         e.preventDefault();
                                         
                                         
                                     }}
                                     > New Version!</a>
                                 </p>
                                 
                             </div>
                                } */}
                        </div>
                    </Toolbar>
                </AppBar>
                <div className={classes.toolbarPlaceHolder} />
                {!info_hidden &&
                    <div>
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                            spacing={8}
                            style={{ marginTop: "15px" }}
                        >
                            <Grid item xs={7}>
                                {(this.props.channel !== undefined && this.props.title !== undefined) && <Typography className={classNames(classes.channelName)}>{this.props.channel.name}</Typography>}

                            </Grid>
                            <Grid item xs={2} style={{ textAlign: "buttom" }}>
                                {this.props.back !== undefined &&
                                    <Link to={this.props.back}>
                                        <Button
                                            className={classNames(classes.mainButtonWhiteBorderedSM, classes.printButton)}
                                            fullWidth={true}
                                            variant="contained">
                                            Back
                                        </Button>
                                    </Link>
                                }
                            </Grid>
                        </Grid>
                        {(this.props.title !== undefined) && <Typography className={classNames(classes.pageTitle)}>{this.props.title}</Typography>}
                    </div>
                }

                <Dialog
                    id="gps-access-blocked-dialog"
                    fullScreen={false}
                    classes={{
                        root: classes.dialogRoot
                    }}
                    open={this.state.accessLocationBlocked}
                    aria-labelledby="responsive-dialog-title"
                    BackdropProps={{ style: { marginTop: 50 } }} // temp here, find a way to access backdrop class
                >
                    <DialogContent>
                        {helper.getMobileOperatingSystem() === "iOS"
                            ?
                            <div>
                                <Typography style={{ color: 'red', fontSize: '20px', textAlign: 'center' }}>iPhone location services need to be enabled.</Typography>
                                <br />
                                <Typography style={{ color: 'black' }}>
                                    Open Settings, scroll down, tap "Privacy".<br />
                                    1) Tap "Location Services" and set to enabled<br />
                                    2) Scroll down to "Safari Websites" and set to "While using the APP".
                                </Typography>
                            </div>
                            :
                            <div>
                                <Typography style={{ color: 'red', fontSize: '20px', textAlign: 'center' }}>Location service is not enabled.</Typography>
                                <br />
                                <Typography style={{ color: 'black' }}>
                                    Please make sure that location service is enabled and permitted to this web browser.
                                </Typography>
                            </div>
                        }
                        <br />
                        <div style={{ textAlign: 'center' }}>
                            <Button
                                onClick={() => window.location = '/'}
                                variant="contained"
                                color="default"
                                size="large"
                                classes={{
                                    root: classNames(classes.blueButton),
                                    label: classNames(classes.scheduleWhiteButtonLabel)
                                }}
                            // className={classNames(classes.blueButton)}
                            >
                                {/* <Icon className={classes.scheduleWhiteButtonIcon}>refresh</Icon> */}
                                OK</Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

ApplicationBar.propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired,
};

export default withCookies(withMobileDialog()(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ApplicationBar))));