import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
import classNames from 'classnames'

import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import LinearProgress from '@material-ui/core/LinearProgress'

import * as globals from 'components/common/GlobalConstants'
// import uuid from "uuid"

const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const ApptOrderHeaderFunctionClass = (props) => {
    const { drawData, classes, history, match, step } = props
    const { appointment_id, order_id } = match.params
    const workflow_type = match.url.split('/')[4]
    
    var selected_workflow_type = globals.v2.ALLOWED_WORKFLOW_TYPE.find(obj => {
        return obj.key === workflow_type
    })

    if(selected_workflow_type === undefined) {
        selected_workflow_type = globals.v2.ALLOWED_WORKFLOW_TYPE[0]
    }

    var order = {
        patient: {
            last_name: "",
            first_name: "",
        }
    }

    if (drawData.draw_list !== undefined) {
        const theSite = drawData.draw_list.find(function (site) {
            return site.id === appointment_id
        })

        order = theSite.patient_list.find(function (patient) {
            return patient.id === order_id
        })
    }

    // console.log("selected_workflow_type")
    // console.log(selected_workflow_type)

    const noOfSteps = selected_workflow_type.noOfSteps
    const percentage = 100 / noOfSteps
    const progress = step * percentage

    const closeApptOrder = () => {
        history.push("/appointments/today/" + appointment_id)
    }

    return (
        <>
            <div className={classes.apptOrderHeaderContainer}>
                <Grid
                    container
                    alignItems="center"
                >
                    <Grid item xs={3}>
                        <IconButton aria-label="Close" className={classNames(classes.closeApptOrderButton)} onClick={() => closeApptOrder()}>
                            <CloseIcon className={classNames()}></CloseIcon>
                        </IconButton>
                    </Grid>
                    <Grid item xs={6} zeroMinWidth style={{ textAlign: "center" }}>
                        <Typography variant="h6">{order.patient.first_name} {order.patient.last_name}</Typography>
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: "right" }}>
                        <Typography className={classes.currentStepLabel}>{step} of {noOfSteps}</Typography>
                    </Grid>
                </Grid>
            </div>
            <LinearProgress
                variant="determinate"
                value={progress}
                classes={{
                    colorPrimary: classes.percentageBar,
                    barColorPrimary: classes.percentageBarColor
                }}
            />
        </>
    )
}

const ApptOrderHeader = withRouter(
    withCookies(
        connect(mapStateToProps)(
            withStyles(styles, { withTheme: true })(ApptOrderHeaderFunctionClass)
        )
    )
)
export default ApptOrderHeader
