import React, {useState, useEffect} from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
import classNames from 'classnames'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import CurrentDate from 'components/common/workflow/CurrentDate'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import uuid from "uuid"

const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const OrdersSummaryFunctionClass = (props) => {
    const { drawData, classes } = props
    const [totalDrawsProcessed,  setTotalDrawsProcessed] = useState(0)
    const [totalAppointmentsProcessed,  setTotalAppointmentsProcessed] = useState(0)
    const total_appointments = drawData.draw_list !== undefined ? drawData.draw_list.length : 0
    const [totalOrdersPercentage, setTotalOrderPercentage] = useState(0)
    var total_orders = 0

    if(drawData.draw_list !== undefined) {
        drawData.draw_list.forEach((draw, index) => {
            total_orders += draw.patient_list.length !== undefined ? draw.patient_list.length : 0
        })
    }

    useEffect(() => {
        var drawsCount = 0
        var appointmentCount = 0
        if(drawData.draw_list) {
            drawData.draw_list.map(sites =>{
                var siteCount = 0 

                sites.patient_list.map(patient => {

                    if(patient.patient_draw[0].status !== "remaining" ){
                        drawsCount++
                        siteCount++
                    }
                      if(sites.patient_list.length == siteCount){
                        appointmentCount++
                    }
                })

            }) 
        }
        
        setTotalDrawsProcessed(drawsCount)
        setTotalAppointmentsProcessed(appointmentCount)
    },[drawData])
    
    useEffect(() => {
        var percentage = totalDrawsProcessed > 0 ? Math.round((totalDrawsProcessed/total_orders) * 100) : 0
        setTotalOrderPercentage(percentage)

    },[totalDrawsProcessed] )

    return (
        <>
            <div className={classes.summaryContainer}>
                <Grid container justify="space-between">
                    <div>
                        <CurrentDate key={uuid.v4()} />

                        <Typography className={classes.orderCountLabel} style={{ paddingTop: "8px" }}>Appointments: {totalAppointmentsProcessed}/{total_appointments}</Typography>
                        <Typography className={classes.orderCountLabel}>Total Orders: {totalDrawsProcessed}/{total_orders}</Typography>
                    </div>
                    <div>
                        <Grid
                            style={{ height: "100%" }}
                            container
                            alignItems="center">
                            <Typography className={classNames(classes.summaryOrderPercentage)}>{totalOrdersPercentage}%</Typography>
                        </Grid>
                    </div>
                </Grid>
            </div>
        </>
    )
}

const OrdersSummary = withCookies(
    connect(mapStateToProps)(
        withStyles(styles, { withTheme: true })(OrdersSummaryFunctionClass)
    )
)
export default OrdersSummary
