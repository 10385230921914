const styles = theme => ({    
    controlsContainer: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        zIndex: 999999,
        textAlign: "center",
        backgroundColor: "white",
        borderTop: "1px solid whitesmoke"
    },
    buttons: {
        // padding: '6px 15px',
        minWidth: 60,
        textTransform: 'none',
        borderRadius: 0,
        display: "block",
        color: "#fff",
        backgroundColor: "#68CBEF !important",
    },
    disabledButton: {
        backgroundColor: "whitesmoke !important"
    },
    buttonIcon: {
        lineHeight: 1.4
    },
    pageNumbers: {
        margin: 0,
        display: "inline-block",
        // height: 40,
        // lineHeight: 2
        paddingTop: 7,
        paddingBottom: 6
    },
    leftButton: {
        float: "left"
    },
    rightButton: {
        float: "right"
    },
});

export default styles