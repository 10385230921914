import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
// import classNames from 'classnames'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import Typography from '@material-ui/core/Typography'
import  Encryption from 'components/common/Encryption';

const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const ProfileDetailsFunctionClass = (props) => {
    const { classes } = props

    var security = new Encryption();
    var decryptedCred = JSON.parse(security.decrypt(props.allCookies.credentials))
    // console.log("decryptedCred")
    // console.log(decryptedCred)
    // var phleb = decryptedCred.phleb_id;
    // var token = decryptedCred.token;
    
    var first_name = decryptedCred.first_name
	var last_name = decryptedCred.last_name
	var email = decryptedCred.email
	var phone_number = decryptedCred.phone_number
	var address_line_1 = decryptedCred.address_line_1
	var city = decryptedCred.city
	var state = decryptedCred.state
	var zipcode =  decryptedCred.zipcode

    return (
        <>
            <div className={classes.profileDetailsContainer}>
                <Typography className={classes.textLabel}>Name</Typography>
                <Typography className={classes.textValue}>{first_name} {last_name}</Typography>
                <Typography className={classes.textLabel}>Email</Typography>
                <Typography className={classes.textValue}>{email}</Typography>
                <Typography className={classes.textLabel}>Phone Number</Typography>
                <Typography className={classes.textValue}>{phone_number}</Typography>
                <Typography className={classes.textLabel}>Work Address</Typography>
                <Typography className={classes.textValue}>{address_line_1} {city} {state}, {zipcode}</Typography>
                <br />
                <Typography className={classes.textDescription}>Note:</Typography>
                <Typography className={classes.textDescription}>
                    If there's any information that needs to be changed or updated,
                    please contact your administrator.
                </Typography>
                
                
            </div>
        </>
    )
}

const ProfileDetails = withCookies(
    connect(mapStateToProps)(
        withStyles(styles, { withTheme: true })(ProfileDetailsFunctionClass)
    )
)
export default ProfileDetails
