import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import classNames from 'classnames';
import ApplicationBar from 'components/common/mobile/ApplicationBar';
import ErrorBoundary from 'components/common/ErrorBoundary';
import SimpleBottomNavigation from 'components/common/mobile/SimpleBottomNavigation';
import { connect } from "react-redux";

// import Icon from '@material-ui/core/Icon';
// import Button from '@material-ui/core/Button';

// import Button from '@material-ui/core/Button';
// import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
// import TextField from '@material-ui/core/TextField';
import styles from "./styles.js";
// import { InputBase, Typography } from '@material-ui/core';
// import Paper from '@material-ui/core/Paper';
import GoogleMapComponent from 'components/common/mobile/GoogleMapComponent';
import * as globals from 'components/common/GlobalConstants';

import FutureOrderDrawList from './FutureOrderDrawList';
import FutureOrdersScroller from './FutureOrdersScroller';
import FutureOrderViewToggle from './FutureOrderViewToggle';
import $ from 'jquery';
import printJS from 'print-js';
import {
    updateServicedZipcodes
    } from "js/actions";

// import ImmutableJS from "immutable";
const isEqual = require("react-fast-compare");

const mapStateToProps = state => {
  return { 
     futureOrders: state.futureOrders,
     // scheduleData: state.scheduleData
    };
};
const mapDispatchToProps = dispatch => {
    return {
        updateServicedZipcodes: serviced_zipcodes => dispatch(updateServicedZipcodes(serviced_zipcodes))
    };
};

class FutureOrdersClass extends Component {

     constructor(props){
         super(props);
         this.state = {
            ...this.state,
            view_type: 'list',
          //  timeout : false,
           // milesDrivenPercentage: 0,
        //    initialLoading: 1,
            googleMapKey: this.generateID(),
           // tabValue: 0,
            //order: cookies.get('sitesOrder') ? cookies.get('sitesOrder') : 'asc',
           // orderBy: cookies.get('sitesOrderBy') ? cookies.get('sitesOrderBy') : 'site_order',
           // marginBottomForLandscape: false,
        }
    }

    rerenderGoogleMap(viewType) {
        var view_type = viewType === undefined ? this.state.view_type : viewType;
        var gmap_cont = $('#GoogleMapContainer');
        var width = gmap_cont.width();
        var height = width;

        console.log("rerenderGoogleMap method: " + view_type);
        gmap_cont.css({
            // width: width+"px",
            height: view_type === "list" ? "1px" : height+"px",
        });

        this.setState({...this.state, view_type: view_type, googleMapKey: this.generateID()});
    }

    componentDidMount() {
        // not the solution, need to rerender to reposition numbers inside black circles
        var mql = window.matchMedia("(orientation: portrait)");
        
        // console.log("component Did Mount rerenderGoogleMap")
        // console.log("window.matchMedia:")
        // console.log(mql);

        this.rerenderGoogleMap();

        mql.addListener(function(m) {
            console.log("mql addListener")
            console.log("window.matchMedia:")
            console.log(m);

            this.rerenderGoogleMap();
        }.bind(this));

        // window.addEventListener("resize", function() {
        //     var mql = window.matchMedia("(orientation: portrait)");
        //     console.log("addEvenetListener resize rerenderGoogleMap")            
        //     console.log(mql)
        //     this.rerenderGoogleMap();
        // }.bind(this));
    }

    componentDidUpdate(prevProps) {
        // console.log("componentDidUpdate");
        // console.log(prevProps.match.params.futureOrderDate);
        // console.log(this.props.match.params.futureOrderDate);
        if(!isEqual(prevProps.match.params.futureOrderDate, this.props.match.params.futureOrderDate)) {
            console.log("component Did Update rerenderGoogleMap")
            this.rerenderGoogleMap();
        }
    }

    goTo = (url) => {
        this.props.history.push(url);
    }

    // getViewType = () => {
    //     // var view_type = this.props.match.params.viewType === undefined ? "list" : this.props.match.params.viewType;
    //     var view_type = this.state.view_type;
    //     console.log("getViewType: " + view_type)
    //     return view_type;
    // }

    // onItemClickHandler = (item,greet) => {
        // console.log("onItemClickHandler ------------------------------------------------------------------------------------------")
        // console.log(`/future_order_details/${item}/${this.getViewType()}`)
        // this.setState({...this.state, googleMapKey: this.generateID()});
        // this.goTo(`/future_order_details/${item}/${this.getViewType()}`);
    // }

    onPrintLabelHandler = (labels) => {
        console.log(labels);
        try{
            //printJS({printtable: file_path.labels[0].path, })
            var fpath = globals.SERVER + labels[0].path;
            printJS({printable: fpath, type:'pdf', showModal:true})
            
        } catch(e) {
            console.log(e)
        }
    }    

    handleSwitchView = (viewType) => {
        // console.log("handleSwitchView")
        // console.log(viewType)
        // this.goTo(`/future_order_details/${this.props.match.params.futureOrderDate}/${viewType}`);

        // this.setState({...this.state, view_type: viewType});

        console.log("handleSwitchView rerenderGoogleMap: " + viewType);
        this.rerenderGoogleMap(viewType);
    }

    generateID() {
        return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
    }

    handleMilesDrivenPercentageCBHandler = (responseData) => {

    }

    render() {   
        const { classes } = this.props;
        const futureOrderDate = this.props.match.params.futureOrderDate;
        // var view_type = this.props.match.params.viewType === undefined ? "list" : this.props.match.params.viewType;
        var view_type = this.state.view_type;
        const ORGANIZATION = globals.ORGANIZATION;
        const HOUSEHOLD = globals.HOUSEHOLD;
        const INDIVIDUAL = globals.INDIVIDUAL;

        // console.log("view_type");
        // console.log(view_type);

        var mapComponentID = this.generateID();
        var zoom = 12;
        var start = { lat: 40.7794799, lng: -73.9785617 }; // NYC
        // var start = { lat: 0.0, lng: 0.0 };
        var end = { lat: 0.0, lng: 0.0 };
        var center = start;
        var latLong = {};

        // phleb's car location, for now it will be placed in the
        // middle of source(solid lineSymbol) and destination(dotted/dashed)
        var way_points = [];
        var way_points_options = [];
        
        // console.log("this.props.futureOrders.future_orders[futureOrderDate].draw_list");
        // console.log(this.props.futureOrders.future_orders[futureOrderDate].draw_list);
 
        var ctr = 0;

        var futureOrder = this.props.futureOrders.future_orders[futureOrderDate];
        var latitude;
        var longitude;
        var showHomeMarker = false;
        var showHomeMarkerLabel = false;
        var phlebs_loc_plotted = false;

        if(globals.DEBUG_ALLOWED) {
            console.log("Future Orders:")
            console.log(futureOrder)
        }

        for( var drawListItemIndex in futureOrder.draw_list) { 
            // console.log("drawListItem");
            // console.log(this.props.futureOrders.future_orders[futureOrderDate].draw_list[drawListItemIndex]);
            
            var drawListItem =  futureOrder.draw_list[drawListItemIndex]
            var siteType = drawListItem.site_type;
           
            if(siteType === HOUSEHOLD || siteType === ORGANIZATION) {
                latitude = parseFloat(drawListItem.site.latitude);
                longitude = parseFloat(drawListItem.site.longitude);
            } else if(siteType === INDIVIDUAL) {
                latitude = parseFloat(drawListItem.patient_list[0].patient.latitude);
                longitude = parseFloat(drawListItem.patient_list[0].patient.longitude);
            } else {
                console.log("Invalid site_type" + siteType);
            }

            if(futureOrder.draw_list.length > 1 && !phlebs_loc_plotted) {
                phlebs_loc_plotted = true;
                way_points.push({
                    location: {
                        lat: latitude, 
                        lng: longitude
                    },
                    stopover: true,
                })
                way_points_options.push(
                    {
                        labelFonts: {
                            text: "0",
                            color: 'black',
                            fontSize: "12px",
                            fontWeight: "bold"
                        },
                        type: "car"
                    }, // !important, it will determine the linesymbol if dashed or dotted
                );
            }
            
            // console.log("LENGTH: " + futureOrder.draw_list.length)
            // console.log(drawListItem)
            if(ctr === 0) {
                if(futureOrder.draw_list.length > 1) {
                    start = {
                        lat: latitude, 
                        lng: longitude
                    };
                    center = start;
                }
            } else if((ctr + 1) === futureOrder.draw_list.length && futureOrder.draw_list.length > 1) {
                end = {
                    lat: latitude, 
                    lng: longitude
                };
            }
            
            latLong =  {
                location: {
                    lat: latitude, 
                    lng: longitude
                },
                stopover: true,
            }
            
            ctr++;

            if(futureOrder.draw_list.length > 1) {
                way_points.push(latLong);
                way_points_options.push({ 
                    type: "site", 
                    icon: "not_visited",
                    labelFonts: {
                        text: ctr.toString(),
                        color: '#747474',
                        fontSize: "16px",
                        fontWeight: "bold"
                    }
                });
            }
        }
        
        if(futureOrder.draw_list.length === 0) {
            showHomeMarker = true;
            showHomeMarkerLabel = true;
            // start = {lat: 40.7794799, lng: -73.9785617};
            // end = {lat: 40.7794799, lng: -73.9785617};
            // center = {lat: 40.7794799, lng: -73.9785617};
        } else if(futureOrder.draw_list.length === 1) {
            start = {lat: latitude, lng: longitude};
            end = {lat: latitude, lng: longitude};
            center = start;
            
            latLong =  {
                location: {
                    lat: latitude, 
                    lng: longitude
                },
                stopover: true,
            }

            way_points.push(latLong);
            way_points_options.push({ 
                type: "site", 
                icon: "not_visited",
                labelFonts: {
                    text: '1',
                    color: '#747474',
                    fontSize: "16px",
                    fontWeight: "bold"
                }
            });
        }
    
        // console.log("INSIDE RENDER -------------------");
        // console.log(way_points);
        // console.log(way_points_options);
        //var siteType = this.props.futureOrders.future_orders[futureOrderDate].draw_list[0].site_type;

        // console.log("siteType");
        // console.log(siteType);

        
        // console.log("futureOrderDate");
        // console.log(futureOrderDate);
        return(
            <div className={classes.root}>
                <main className={classes.content}>
                    <ApplicationBar isInfoHidden={true} />
                    <div className={classNames(classes.schedulesMainContainer)}>
                        <div>
                            <List classes={{root: classNames(classes.listUL, classes.listULMainHeader)}}>
                                <ListItem className={classNames(classes.listLI)}>
                                    <ListItemText
                                        classes={{
                                            root: classes.listItemRoot,
                                            primary: classes.schedulesMainHeader,
                                        }} 
                                        primary="Upcoming Order Details"
                                    />
                                </ListItem>
                            </List>
                        </div>
                        
                        <FutureOrderViewToggle {...this.props} handleGoTo={this.goTo} handleSwitchView={this.handleSwitchView} viewType={view_type}/>
                        <FutureOrdersScroller {...this.props} futureOrderDate={futureOrderDate} onItemClick={this.onItemClickHandler} viewType={view_type}/>

                        <div className={classes.futureOrdersListContainer} style={{"display": view_type === "list" ? "block" : "none"}}> 
                            {/* <Grid 
                                item
                                className={classes.printIconAllCont}>
                                <Button 
                                    // onClick={handleGoTo.bind(null, '/future_orders')}
                                    disabled
                                    variant="contained"
                                    classes={{
                                        root: classNames(
                                            classes.switchViewButton,
                                            classes.switchViewButtonLeft,
                                            classes.switchViewButtonRight
                                        ),
                                        label: classes.switchViewButtonLabel
                                    }}>
                                    <img alt="TeloPoint Print Icon" className={classNames(classes.printIcon, classes.printIconAll)} src="/images/print.svg" /> Print All
                                </Button>
                            </Grid>   */}
                            <FutureOrderDrawList {...this.props} futureOrderDate={futureOrderDate} futureOrder={futureOrder} onPrintLabel={this.onPrintLabelHandler} />
                        </div>
                        <div id="GoogleMapContainer" className={classes.googleMapContainer} style={{"visibility": view_type === "map" ? "visible" : "hidden"}}>
                            {/* <Typography>{this.state.googleMapKey}</Typography> */}
                            <ErrorBoundary>
                                <GoogleMapComponent
                                    key={this.state.googleMapKey}
                                    showHomeMarker={showHomeMarker}
                                    showHomeMarkerLabel={showHomeMarkerLabel}
                                    id={mapComponentID}
                                    zoom={zoom}
                                    center={center}
                                    start={start}
                                    end={end}
                                    wayPoints={way_points}
                                    wayPointsOptions={way_points_options}
                                    milesDrivenPercentageResponseHandler={this.handleMilesDrivenPercentageCBHandler}
                                    >
                                </GoogleMapComponent>
                            </ErrorBoundary>
                        </div>
                    </div>
                    <div className={classes.toolbar} />
                </main>
                <SimpleBottomNavigation />
            </div>
        );
    }
}

FutureOrdersClass.propTypes = {
    classes: PropTypes.object.isRequired,
};

const FutureOrders = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FutureOrdersClass));
export default FutureOrders;
