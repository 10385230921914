const styles = theme => ({
    // general
    // summary
    summaryContainer: {
        borderLeft: "5px solid #69CEF1",
        borderRadius: "5px",
        padding: "20px",
        paddingRight: "0px",
        paddingBottom: "24px",
        backgroundColor: "#69CEF11A",
        marginTop: "20px"
    },
    orderCountLabel: {
        fontSize: "12px !important",
    },
    summaryOrderPercentage: {
        fontSize: "18px !important",
        color: "#69CEF1 !important",
        backgroundColor: "white",
        borderTopLeftRadius: "50px",
        borderBottomLeftRadius: "50px",
        padding: "8px 12px 8px 20px",
        overflow: "hidden",
        fontWeight: 600
    },
    // appointment list
    appointmentListContainer: {
        marginTop: "25px"
    },
    appointmentsLabel: {
        fontSize: "12px !important",
        fontWeight: 500,
        marginBottom: "10px",
        // paddingBottom: "5px"
    },

    NoAppointmentsLabel: {
        fontSize: "12px !important",
        fontWeight: "normal",
        marginBottom: "10px",
        color: "darkgray !important"
        // paddingBottom: "5px"
    },

    // appointment list item
    apptListItemContainer: {
        padding: "0px 20px",
        paddingRight: "10px",
        borderRadius: "5px",
        border: "1px solid #fbfbfb",
        boxShadow: "0px 1px 3px #0000001A",
        marginBottom: "8px",
        '& .orders': {
            padding: "8px 16px",
            fontSize: "12px !important",
            borderRadius: "16px"
        },
        '&.active': {
            backgroundColor: "#f8f8f8",
            padding: "15px 20px",
            paddingRight: "10px",
            paddingTop: "0px",
            '& .orders': {
                color: "#69CEF1 !important",
                backgroundColor: "white",
                fontWeight: 600
            }
        },
        '&.inactive': {
            '& .orders': {
                color: "#9A9BA0 !important",
                backgroundColor: "#f7f7f7"
            }
        },
        '&.done': {
            backgroundColor: "#EDFBF2",
            '& .orders': {
                color: "#69F19D !important",
                backgroundColor: "white"
            }
        }
    },
    apptListItemPanelRoot: {
        position: "inherit"
    },
    apptListItemSummary: {
        padding: "0px",
    },
    apptListItemSummaryContent: {
        margin: "0px !important",
        '& > :last-child': {
            paddingRight: "0px"
        }
    },
    apptListItemSummaryActive :{
        marginBottom: "-15px"
    },
    apptListItemSummaryExpanded: {
        margin: "10px 0px !important",
        marginTop: "0px !important",
        paddingTop: "0px !important",
        paddingBottom: "20px !important",
    },
    apptListItemDetailsRoot: {
        display: "inherit !important",
        padding: "0px !important",
    },
    apptListItemTime: {
        fontWeight: 600,
        display: "inline-block"
    },
    apptListItemPreviousTime: {
        color: "#e2e2e2 !important",
        textDecoration: "line-through",
        display: "inline-block",
        marginLeft: "10px"
    },
    orderName: {
        fontSize: "14px !important",
        fontWeight: 500,
    },
    orderAddress: {
        fontSize: "12px !important",
    },
    activeButtonsContainer: {
        marginTop: "18px",
    },
    ordersButton: {
        backgroundColor: "white",
        borderRadius: "4px",
        fontSize: "16px",
        padding: "8px 16px",
        boxShadow: "0px 1px 3px #f0f0f0",
        marginRight: "10px",
        "&:focus": {
            backgroundColor: "white",
        }
    },
    ordersButtonIcon: {
        fill: "#69CEF1 !important",
        width: 24,
        height: 24,
        marginRight: "10px",
    },
    openOrderButton: {
        padding: "8px 12px 8px 20px",
        borderRadius: "0%"
    }
})

export default styles