import React, { useState } from 'react'

import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
import classNames from 'classnames'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"
// import Typography from '@material-ui/core/Typography'
import ApplicationBar from 'components/common/workflow/ApplicationBar'
import IconButton from '@material-ui/core/IconButton'
import ApptDetailsHeader from './ApptDetailsHeader'
import ChangePasswordDetails from './ChangePasswordDetails'
import $ from 'jquery'
import * as globals from 'components/common/GlobalConstants'

import Encryption from 'components/common/Encryption'
import ConfirmDialog from './ConfirmDialog'

// import * as helper from 'components/common/Helper.js'

const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const ChangePasswordFunctionClass = (props) => {
    const { classes } = props
    const [isSending, setIsSending] = useState(false)
    const [confirmDialogOpened, setConfirmDialogOpened] = useState(false)
    const [instructionText, setInstructionText] = useState("")

    const handleChangePassword = (props) => {
        //this.setState({ ...this.state, changePasswordLoadingDialogOpened: true });

        var security = new Encryption();
        var decryptedCred = JSON.parse(security.decrypt(props.allCookies.credentials))

        var phleb = decryptedCred.phleb_id;
        var token = decryptedCred.token;
        var user_data = { user_id: phleb };
        console.log({
            token: token,
            //url: globals.SERVER + "/api/v1/password/reset/",
            url: globals.CHANGE_PASSWORD_LINK,
            method: "POST",
            data: user_data
        })

        setIsSending(true)
        var request = $.ajax({
            beforeSend: function (xhr, settings) {
                xhr.setRequestHeader('Authorization', 'Token ' + token);
            },
            //url: globals.SERVER + "/api/v1/password/reset/",
            url: globals.CHANGE_PASSWORD_LINK,
            method: "POST",
            data: user_data
        });

        request.done(function (response) {
            // this.showPasswordResetMessage(response.message);
            setInstructionText(response.message)
            setConfirmDialogOpened(true)
            setIsSending(false)
        });

        request.fail(function (jqXHR, textStatus) {
            setTimeout(() => {

                try {
                    //this.showPasswordResetMessage(jqXHR.responseJSON.message);
                    setInstructionText("Email sent successfully!")
                } catch (err) {
                    //this.showPasswordResetMessage('Failed to get response from TeloPoint at this time.');
                    setInstructionText('Change password failed. Please try again.')
                }
                setConfirmDialogOpened(true)
                setIsSending(false)
            }, 2000);
        });
    }

    return (
        <>
            <div className={classes.root}>
                <main className={classes.content}>
                    <div className={classNames(classes.mainContainer)}>
                        <ApplicationBar />

                        <ApptDetailsHeader />
                        <ChangePasswordDetails />
                    </div>
                    {/* <div className={classes.instructionTextContainer}>
                        <Typography className={classes.instructionText} >
                            {instructionText}
                        </Typography>
                    </div> */}
                    <div className={classes.bottomContainer}>
                        <IconButton
                            className={classes.bottomButton}
                            onClick={() => {
                                if (!isSending) {
                                    handleChangePassword(props)
                                }
                            }}
                        >{isSending ? "Processing..." : "Continue"}</IconButton>
                    </div>
                </main>

                <ConfirmDialog
                    open={confirmDialogOpened}
                    message={instructionText}
                    onOk={() => setConfirmDialogOpened(false)}
                />
            </div>
        </>
    )
}

const ChangePassword = withCookies(
    connect(mapStateToProps)(
        withStyles(styles, { withTheme: true })(ChangePasswordFunctionClass)
    )
)
export default ChangePassword
