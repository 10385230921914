import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

// import classNames from 'classnames';
// import ApplicationBar from 'components/common/mobile/ApplicationBar';
// import SimpleBottomNavigation from 'components/common/mobile/SimpleBottomNavigation';
import { connect } from "react-redux";
import { withCookies } from 'react-cookie';
// import Icon from '@material-ui/core/Icon';
// import Button from '@material-ui/core/Button';

// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
// // import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// import ListItemText from '@material-ui/core/ListItemText';
// import TextField from '@material-ui/core/TextField';
import styles from "./styles.js";
// import { InputBase } from '@material-ui/core';
// import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PinKeyPad from '../PinKeyPad';
import  Encryption from 'components/common/Encryption';

import {
    setScreenLockStatus,
    logEvent
} from "js/actions";

//import ImmutableJS from "immutable";

const mapStateToProps = state => {
  return { 
     futureOrders: state.futureOrders,
     // scheduleData: state.scheduleData
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setScreenLockStatus: status => dispatch(setScreenLockStatus(status)),
        logEvent: event => dispatch(logEvent(event)),
    };
};
const DESIRED_PIN_CODE_SCREEN = "DESIRED_PIN_CODE_SCREEN";
const CONFIRM_PIN_CODE_SCREEN = "CONFIRM_PIN_CODE_SCREEN";
const DESIRED_PIN_CODE_INSTRUCTION_TEXT = "Create a 4 digit pin to easily login to the app.";
const CONFIRM_PIN_CODE_INSTRUCTION_TEXT = "Confirm PIN code";
const CONFIRM_PIN_CODE_ERROR_TEXT = "Code did not match.";
const SUCCESS_PIN_CODE = "SUCCESS!";

class CreatePinCodeClass extends Component {

    constructor(props){
        super(props);
        this.state = {
            ...this.state,
            screen : DESIRED_PIN_CODE_SCREEN,
            pincode : "",
            confirm_pincode : "",
            keyPadMessage : "",
            keyPadInstruction : DESIRED_PIN_CODE_INSTRUCTION_TEXT,
            module: "pin-code",
            showResetLink : false,
            resetLinkText: "Tap here to reset PIN"
            
        };
    }

    componentDidMount() {


    }

    componentDidUpdate(prevProps) {
    }

    loadData = () => {
    }
    
    onChangePinCode =(value) =>{
        if( this.state.screen === DESIRED_PIN_CODE_SCREEN) {
            this.setState({
                ...this.state, 
                pincode : value,
                keyPadMessage: ""
            });
        }
  
        if( this.state.screen === CONFIRM_PIN_CODE_SCREEN) {
            this.setState({
                ...this.state,
                confirm_pincode : value,
                keyPadMessage: ""
            });
        }

    }

    getCredentials =()=> {
        const { cookies } = this.props

        var cred = cookies.get('credentials') || '';        
        if(cred === "") return "";

        var security = new Encryption()
        var decryptedCred = JSON.parse(security.decrypt(cred))
        return decryptedCred
        //console.log("THE RESULT " +  security.decrypt(secret));
    }

    onCompletePinCode = (value) =>{
        var message = "";
        var cred = this.getCredentials();

        if( this.state.screen === DESIRED_PIN_CODE_SCREEN) {
            this.setState({
                ...this.state,
                screen : CONFIRM_PIN_CODE_SCREEN,
                pincode : value,
                keyPadMessage: message
            });

            this.props.logEvent({ 
                user: cred.username,
                module: this.state.module,
                action: "create_pin",
                description: "Entered desired PIN code"
            });
        }
  
        if( this.state.screen === CONFIRM_PIN_CODE_SCREEN) {
            if(this.state.pincode.trim() === value) {
                message = SUCCESS_PIN_CODE
                const {cookies } = this.props;

                cred = this.getCredentials()
                cred.pincode = value

                var security = new Encryption();
                var encryptedCred = security.encrypt(JSON.stringify(cred))
               //console.log("HELLO " + JSON.stringify(cred))

                cookies.set('credentials', encryptedCred, { path: '/', maxAge: 432000 });

                this.props.logEvent({ 
                    user: cred.username,
                    module: this.state.module,
                    action: "confirm_pin",
                    description: "Confirmed PIN code"
                }); 

                this.props.setScreenLockStatus(false)
                //this.goTo("/sites")
                this.goTo("/appointments/today")

                
                
            } else {
                message = CONFIRM_PIN_CODE_ERROR_TEXT

                this.props.logEvent({ 
                    user: cred.username,
                    module: this.state.module,
                    action: "wrong_pin",
                    description: "Code did not match."
                }); 
            }

            this.setState({
                ...this.state,
                confirm_pincode : value,
                keyPadMessage: message,
                showResetLink : true 
            });
        }

       //console.log(message)
 
       //console.log("Complete : " + value)
       //console.log("Complete :" + message)
    }

    startOver =()=>{

        this.setState({
                ...this.state,
                screen: DESIRED_PIN_CODE_SCREEN,
                pincode: "",
                confirm_pincode : "",
                keyPadMessage: "",
                showResetLink: false,
                keyPadInstruction : DESIRED_PIN_CODE_INSTRUCTION_TEXT
            });
    }

    goTo(url) {
        this.props.history.push(url);
    }



    render() {   
       //console.log("updating main")
        const {classes } = this.props;
        
        //var pincode = cookies.get('pincode') || ''
        ////console.log(this.props.futureOrders);
        const {pincode, confirm_pincode} = this.state
       //console.log("pincode")
       //console.log(pincode)
       //console.log("Confirm pincode")
       //console.log(confirm_pincode)

        const the_pincode = this.state.screen === DESIRED_PIN_CODE_SCREEN ? pincode : confirm_pincode
        const keyPadInstruction = this.state.screen === DESIRED_PIN_CODE_SCREEN ? DESIRED_PIN_CODE_INSTRUCTION_TEXT : CONFIRM_PIN_CODE_INSTRUCTION_TEXT

        return(
            <div className={classes.root}>
              
                <PinKeyPad 
                    digitCount={4} 
                    value={the_pincode} 
                    onChange={this.onChangePinCode} 
                    onComplete={this.onCompletePinCode}
                    keyPadMessage={this.state.keyPadMessage} 
                    keyPadInstruction={keyPadInstruction}
                    // onStartOver={this.startOver}
                    showResetLink={this.state.showResetLink}
                    resetLinkText={this.state.resetLinkText}
                    onResetLink={this.startOver}
                />
                {/* {  (this.state.screen === CONFIRM_PIN_CODE_SCREEN && this.state.keyPadMessage === CONFIRM_PIN_CODE_ERROR_TEXT ) && 
                <Grid container spacing={8} className={classes.keyDisplayContainer} > 
                <div className={classes.startOverWrapper}>
                    <Button
                        type="submit"
                        variant="contained"
                        size="medium"
                        color="primary"
                        onClick={this.startOver}
                        className={classes.loginButton}>
                        Start over
                    </Button>
                </div>
                </Grid>
             
                } */}
            </div>
        );
    }
}

CreatePinCodeClass.propTypes = {
    classes: PropTypes.object.isRequired,
};

const UnlockScreen = withCookies( connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CreatePinCodeClass)));
export default UnlockScreen;
