import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
// import classNames from 'classnames'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

// import CurrentDate from 'components/common/workflow/CurrentDate'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
// import uuid from "uuid"
import * as helper from 'components/common/Helper.js'
const mapStateToProps = state => {
    return {
        futureOrders: state.futureOrders
    }
}

const UpcomingOrdersSummaryFunctionClass = (props) => {
    const { futureOrders, classes } = props
    const [totalNumberOfDays, setTotalNumberOfDays] = useState(0)
    const [totalNumberOfAppointments, setTotalNumberOfAppointments] = useState(0)
    const [totalNumberOfOrders, setTotalNumberOfOrders] = useState(0)

    useEffect(() => {
        var noOfDays = 0
        var totalAppointments = 0
        var totalOrders = 0
        console.log("Future orders")
        console.log(futureOrders)

        if (futureOrders !== undefined) {
            var sorted_fo = helper.sortFutureOrdersObjectByKey(futureOrders.future_orders, 'asc');

            for (var date_key in sorted_fo) {
                if (futureOrders.future_orders[date_key].draw_list !== undefined &&
                    futureOrders.future_orders[date_key].draw_list.length > 0) {
                    noOfDays++
                }

                futureOrders.future_orders[date_key].draw_list.forEach(appointments => {
                    totalAppointments++
                    appointments.patient_list.forEach(patient => {
                        totalOrders++
                    })
                })

            }
            setTotalNumberOfDays(noOfDays)
            setTotalNumberOfAppointments(totalAppointments)
            setTotalNumberOfOrders(totalOrders)
        }

    }, [futureOrders])

    return (
        <>
            <div className={classes.summaryContainer}>
                <Grid container justify="space-between">
                    <div>
                        {/* <CurrentDate key={uuid.v4()} /> */}
                        <div className={classes.workflowTitle}>
                            <Typography
                                className={classes.workflowLabel}
                                constraint="body1">Upcoming Appointments</Typography>
                        </div>

                        <Typography className={classes.orderCountLabel} style={{ paddingTop: "8px" }}>No. of Days: {totalNumberOfDays}</Typography>
                        <Typography className={classes.orderCountLabel} >Appointments: {totalNumberOfAppointments}</Typography>
                        <Typography className={classes.orderCountLabel}>Total Orders: {totalNumberOfOrders}</Typography>
                    </div>

                </Grid>

            </div>
        </>
    )
}

const UpcomingOrdersSummary = withCookies(
    connect(mapStateToProps)(
        withStyles(styles, { withTheme: true })(UpcomingOrdersSummaryFunctionClass)
    )
)
export default UpcomingOrdersSummary
