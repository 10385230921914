import React, { useRef, useEffect, useState } from "react";
import { geolocated, geoPropTypes } from 'react-geolocated'
import classNames from 'classnames';
//import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import GpsOffIcon from '@material-ui/icons/GpsOff';
import * as globals from 'components/common/GlobalConstants';


const GPSControl = (props) => {
    const mounted = useRef();
    const [gpsIconEnabled, setGpsIconEnabled] = useState(true);
    const [displayGPSIcon, setDisplayGPSIcon] = useState(true);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            if (globals.DEBUG_ALLOWED) {
             //   console.log("GPSControl props.isGeolocationEnabled:" + props.isGeolocationEnabled)
            }

            if (props.displayGPSIcon !== undefined) {
                setDisplayGPSIcon(props.displayGPSIcon)
            }

            if (props.onGPSEvent) {
                props.onGPSEvent(props)
            }

            if (props.onPositionError) {
                if (props.positionError) {
                    props.onPositionError(props.positionError)
                }
            }

            if (props.positionError && (props.positionError.code === 1 || props.positionError.code === 2)) {
                if (gpsIconEnabled !== false) {
                    setGpsIconEnabled(false)
                }
            } else {
                if (gpsIconEnabled !== true) {
                    setGpsIconEnabled(true)
                }
            }

        }
    },[props, gpsIconEnabled]);

    const { classes } = props;
    return (

        displayGPSIcon === true ?
            (gpsIconEnabled === true ?
                <GpsFixedIcon className={classNames(classes.gpsIcon, gpsIconEnabled ? classes.online : classes.offline)} />
                :
                <GpsOffIcon className={classNames(classes.gpsIcon, gpsIconEnabled ? classes.online : classes.offline)} />)
            : ""

    )
}

GPSControl.propTypes = { ...GPSControl.propTypes, ...geoPropTypes };

export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(withStyles(styles)(GPSControl));