import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({
    buttons: {
        margin: theme.spacing.unit * 2,
        marginLeft: 0,
    },
});

class CommonDialogClass extends Component {
    
    // constructor(props) {
    //     super(props);
    //     //this.state = {...this.state, visible : false };
    // }

    // const dialogdata = {
    //     "save_status_done" : {"title":"save","content": "Are you sure?","buttons" : ["Yes","No"],"visible": false, eventHandler: this.onSave}
    // };
     //             <CommonDialog data={this.state.dialogdata['save_status_done']} />
    componentWillMount() {
        // console.log("dialog componentWillMount");
        const { title, content, buttons, eventHandler, visible, onClose } = this.props.data;
    
        this.setState({...this.state, 
                        visible : visible, 
                        title: title, 
                        content : content,
                        buttons : buttons, 
                        eventHandler : eventHandler,
                        onClose : onClose
                    });
    }

    componentDidMount() {
        // console.log("dialog componentDidMount");
        
    }
    
    internalEventHandler = (value) => {
        // console.log("internalCloseHandler");
        // console.log(value);
        this.state.eventHandler(value);
        this.props.data.visible = false;
        this.setState({...this.state});
    };

    render() {   
        const { classes } = this.props;
        // console.log("RENDER OF commondialog");
        // console.log(this.state.visible);
        return(
            <Dialog
            open={this.props.data.visible}
            onClose={()=>this.internalEventHandler("")}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">{this.state.title}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                   {this.state.content}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {this.state.buttons.map(button => 
                        <Button 
                            key={button} 
                            onClick={() => this.internalEventHandler(button)} 
                            variant="contained"
                            color={button === "Cancel"? "default": "secondary"} // need to have improved version here
                            className={classes.buttons}
                        >{button}</Button>
                    )}
                </DialogActions> 
            </Dialog>

        );
    }
}

CommonDialogClass.propTypes = {
    classes: PropTypes.object.isRequired,
};

const CommonDialog = CommonDialogClass;
export default withStyles(styles)(CommonDialog);