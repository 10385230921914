import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
import classNames from 'classnames'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import { Link } from "react-router-dom"

const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const TopNavigationFunctionClass = (props) => {
    const { classes, match } = props

    const active_tab = match.params.appointment_type

    const links = [
        {
            "to": "today",
            "name": "Today"
        },
        {
            "to": "upcoming",
            "name": "Upcoming"
        },
        {
            "to": "past",
            "name": "Past"
        },
    ]

    return (
        <div className={classes.topNavContainer}>
            {
                links.map(link => {
                    const active = active_tab === link.to ? classes.activeTopNav : ""
                    return (<Link key={link.to} to={link.to} className={classNames(classes.topNavLink, active)}>
                        {link.name}
                    </Link>)
                })
            }
        </div>
    )
}

const TopNavigation = withCookies(
    connect(mapStateToProps)(
        withStyles(styles, { withTheme: true })(TopNavigationFunctionClass)
    )
)
export default TopNavigation
