import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FilterListIcon from '@material-ui/icons/FilterList';
import JustAFillerIcon from '@material-ui/icons/Brightness1';

const styles = theme => ({
  mainMenu: {
    padding: 0,
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
  },
  menuItem: {
    padding: "2px 6px"
  },
  icons: {
    fontSize: 16
  },
  fillerIcon: {
    fontSize: 16,
    visibility: "hidden"
  },
  listItemIcon: {
    marginRight: 8
  },
  filterButton: {
    backgroundColor: "white",
    fontSize: 10,
    padding: "5px 10px",
    borderRadius: 0,
    textTransform: "none",
    minWidth: 100
  },
  filterButtonIcon: {
    marginRight: 5
  },
  listItemText: {
    fontSize: 12,
    padding: 0
  },
  buttonLabel: {
    justifyContent: "flex-start"
  }
});

class FilterDropdown extends React.Component {

  constructor(props) {
    super(props);
    // const { cookies } = this.props;
    
    this.state = {
      ...this.state,
      anchorEl : null,
    }
  }

  triggerFilterHandler = (value) => {
    this.handleCloseMenu();
    this.props.onChangeFilter(value);
  }

  handleShowMenu = (event) => {
    this.setState({...this.state, anchorEl: event.currentTarget });
  }

  handleCloseMenu = () => {
    this.setState({...this.state, anchorEl: null });
  }

  render() {
    const { containerClassNames, classes, filter_values, filterBy } = this.props;
    const { anchorEl} = this.state;
    // var ctr = 0;
    const selectedItem = filter_values.filter(row => row.id === filterBy);
    return (
      <div className={containerClassNames}>
        <Button
          className={classes.filterButton}
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          color="default"
          onClick={this.handleShowMenu}
          classes={{ label: classes.buttonLabel }}
        >
          <FilterListIcon className={classNames(classes.filterButtonIcon, classes.icons)}></FilterListIcon>
          {selectedItem[0] ? selectedItem[0].label : <em>All</em>}
        </Button>
        <Menu
          id="filter-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleCloseMenu}
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{ paper: classes.mainMenu }}
        >
          {
            filter_values.map(row => {
            // ctr++;
            return (
              <MenuItem value={row.id} key={row.id} dense={true} className={classes.menuItem} onClick={() => this.triggerFilterHandler(row.id)}>
                <ListItemIcon className={classes.listItemIcon}>
                  <JustAFillerIcon className={classes.fillerIcon}></JustAFillerIcon>
                </ListItemIcon>
                <ListItemText primary={row.label} className={classes.listItemText} />
              </MenuItem>
            );
          }, this)}
        </Menu>
      </div>
    );
  }
}

FilterDropdown.propTypes = {
  filter_values: PropTypes.array.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  filterBy: PropTypes.string.isRequired,
};

export default withStyles(styles)(FilterDropdown);