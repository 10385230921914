import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
import classNames from 'classnames'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import ApplicationBar from 'components/common/workflow/ApplicationBar'
import SimpleBottomNavigation from 'components/common/workflow/SimpleBottomNavigation'

import TopNavigation from './TopNavigation'
import Today from './Today'
import Upcoming from './Upcoming'
import Past from './Past'

const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const AppointmentsFunctionClass = (props) => {
    const { classes, match } = props

    // console.log(props)
    const appointment_type = match.params.appointment_type

    const appointmentsContent = () => {
        switch (appointment_type) {
            case 'upcoming':
                return <Upcoming />

            case 'past':
                return <Past />

            default:
                return <Today />
        }
    }

    return (
        <>
            <div className={classes.root}>
                <main className={classes.content}>
                    <div className={classNames(classes.mainContainer)}>
                        <ApplicationBar />

                        <TopNavigation {...props} />

                        {appointmentsContent()}
                    </div>

                    <SimpleBottomNavigation />
                </main>
            </div>
        </>
    )
}

const Appointments = withCookies(
    connect(mapStateToProps)(
        withStyles(styles, { withTheme: true })(AppointmentsFunctionClass)
    )
)
export default Appointments
