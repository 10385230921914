import * as globals from 'components/common/GlobalConstants'

const QRCodeStyles = theme => ({
    // general
    root: {
    },

    content: {
        flexGrow: 1,
    },  

    fullWidth: {
        width: "100%",
    },

    labels: {
        fontSize: "15px !important",
        fontWeight: 500
    },

    mainContainer: {
        padding: "15px 20px",
        marginBottom: "70px"
    },

    // appointment details container
    patientDetailsContainer: {
        margin: "10px",
        padding: "10px"
    },

    labels: {
        fontSize: "15px !important",
        fontWeight: "bold",
        textAlign: "center",
        paddingTop: "20px",
    },
    
    cancelButton: {
        textTransform: 'capitalize',
        padding: "10px 20px",
        fontSize: "14px !important",
        backgroundColor: "#f7f7f7",
        color: "#333333 !important",
        fontWeight: 500,
        "&:focus": { backgroundColor: "#f7f7f7 !important" },
        "&:active": { backgroundColor: "#f7f7f7 !important" },
        "&:hover": { backgroundColor: "#f7f7f7 !important" },
        "&:disabled": { backgroundColor: "#f7f7f7 !important" },
    },

    searchDialogBox: {
        padding: "10px",
        margin: "10px",
        borderRadius: "10px"
    },

    cancelDialogBox: {
        minWidth: 300,
        margin: "20px",
        borderRadius: "10px"
    },

    addPatientBTN: {
        margin: "20px",
    },

    buttonPosition: {
        position: "absolute",
        left: "0px",
        padding: "10px 20px",
        margin: "10px"
    },

    qrCodeBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #efefef",
        padding: "20px",
    },

    dialogText: {
        textAlign: "center",
        paddingTop: "20px"
    },

    addBtnLabel: {
        color: "#00BDFF !important",
    },

    addBtn: {
        fontSize: "14px",
        textTransform: "none",
        backgroundColor: "#ebf5fa !important",
        border: "1px solid #E6E6E6",
        padding: "8px 0px 8px 0px",
        borderRadius: "5px",
        marginBottom: "10px",
        width: "200px",
        "&:active": {
            backgroundColor: "white !important",
        }
    },

    buttonPosition: {
        padding: "0px 0px 20px 0px !important",
        textAlign: "center",
    },

    TextFieldStyle: {
        width: 500,
        maxWidth: "100%",
        border:0,
        margin: "0px",
        padding: "10px 10px 10px 0px",
        paddingLeft: "10px",
        border: "1px solid #efefef",
        borderRadius: "20px",
        outline: "none",
    },

})
export default QRCodeStyles