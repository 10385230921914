import React, { Component } from 'react';
import LoadingSpinner from "components/common/mobile/LoadingSpinner.js";
import { withStyles } from '@material-ui/core/styles';
import styles from "./styles.js";
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';

// import { Document, Page, pdfjs } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class CustomPDFViewerClass extends Component {
  
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            numPages: 1,
            pageNumber: 1,
        };
    }

    onDocumentLoadSuccess = (document) => {
        const { numPages } = document;
        this.setState({
            numPages,   
            pageNumber: 1,
        });
    };

    changePage = offset => this.setState(prevState => ({
        pageNumber: prevState.pageNumber + offset,
    }));

    previousPage = () => this.changePage(-1);

    nextPage = () => this.changePage(1);

    render() {
        const { classes } = this.props;
        const { numPages, pageNumber } = this.state;

        return (
            <React.Fragment>
                PDF viewer currently not supported.
                {/* <Document
                    file={'/files/PatientInformationDocument.pdf'}
                    loading={<LoadingSpinner />}
                    onLoadSuccess={this.onDocumentLoadSuccess}
                    >
                    <Page pageNumber={pageNumber} />
                </Document>

                <div className={classNames(classes.controlsContainer)}>
                    <Button
                        disabled={pageNumber <= 1}
                        onClick={this.previousPage}
                        classes={{disabled: (pageNumber <= 1) ? classes.disabledButton: ""}}
                        className={classNames(classes.buttons, classes.leftButton)} 
                        variant="contained" 
                        color="primary"><Icon classes={{root: classes.buttonIcon}}>keyboard_arrow_left</Icon></Button>
                    
                    <Typography className={classNames(classes.pageNumbers)}>
                        Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                    </Typography>

                    <Button
                        disabled={pageNumber >= numPages}
                        onClick={this.nextPage}
                        classes={{disabled: (pageNumber >= numPages) ? classes.disabledButton: ""}}
                        className={classNames(classes.buttons, classes.rightButton)} 
                        variant="contained" 
                        color="primary"><Icon classes={{root: classes.buttonIcon}}>keyboard_arrow_right</Icon></Button>
                </div> */}
            </React.Fragment>
        );
    }
}

CustomPDFViewerClass.propTypes = {
    // classes: PropTypes.object.isRequired,
    // fullScreen: PropTypes.bool.isRequired,
    // theme: PropTypes.object.isRequired,
};

const CustomPDFViewer = withStyles(styles) (CustomPDFViewerClass);
export default CustomPDFViewer;