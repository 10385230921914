import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import $ from 'jquery';
import Icon from '@material-ui/core/Icon';
import ApplicationBar from 'components/common/mobile/ApplicationBar';
import SimpleBottomNavigation from 'components/common/mobile/SimpleBottomNavigation';
import { connect } from "react-redux";
import LinearProgress from '@material-ui/core/LinearProgress';

import * as globals from 'components/common/GlobalConstants';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import moment from 'moment-timezone';
import styles from "./styles.js";

import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import ImmutableJS from "immutable";
//import EnhancedTableHead from "components/common/mobile/EnhancedTableHead.js";
// import NoDrawListScreen from 'components/common/mobile/NoDrawListScreen.js';
import CurrentDate from 'components/common/mobile/CurrentDate.js';

import Main from '../../../Main';
import * as helper from 'components/common/Helper.js'
import { ReactComponent as CallSVG } from 'components/common/mobile/CustomOfflineSVG/call.svg';
import { ReactComponent as SitesGrayFillSVG } from 'components/common/mobile/CustomOfflineSVG/sites_gray_fill.svg';

import { 
    logEvent,
    createSiteWorkflowDefaultValues
} from "js/actions/index";

const mapDispatchToProps = dispatch => {
    return {
      logEvent: event => dispatch(logEvent(event)),
      createSiteWorkflowDefaultValues: site => dispatch(createSiteWorkflowDefaultValues(site))
    }
}

const mapStateToProps = state => {
    return { drawData: state.drawData, isLoggedIn: state.isLoggedIn};
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class SitesClass extends Component {
    static propTypes = {
      cookies: instanceOf(Cookies).isRequired
    };
  
    constructor(props) {
      super(props);
      const { cookies } = this.props;
      
      this.state = {
        ...this.state,  
        order: cookies.get('sitesOrder') ? cookies.get('sitesOrder') : 'asc',
        orderBy: cookies.get('sitesOrderBy') ? cookies.get('sitesOrderBy') : 'site_order',
        filteredBy: cookies.get('selectedSitesFilter') ? cookies.get('selectedSitesFilter') : "filter_all"
      }
    }

    componentDidMount() {
        // console.log('Sites did mount.');

        this.convertProgressBarTransformIntoWidth();

        // $( ".sites-table-row" ).sortable();
        // $( ".sites-table-row" ).disableSelection();
        this.props.logEvent({module: "sites-list", action: "visit_site_list", description: "Visited Site List Page"})
        const drawData = this.props.drawData
        
        console.log(drawData)
        
        if(drawData.draw_list !== undefined) {
            drawData.draw_list.map(site => {
                //console.log(site)
                if(site.site_type ==="individual"){
                    this.props.createSiteWorkflowDefaultValues({
                        site : site
                    })
                }

                return ''
            })
        }
    }

    // when page refreshed
    componentDidUpdate() {
        this.convertProgressBarTransformIntoWidth();
    }

    convertProgressBarTransformIntoWidth() {
        // force reactjs to use percentage width instead of scalex to support border radius        
        var percentage_cont = $("#overall-progress");
        var percentage_el = $("#overall-progress div:first-child");
        var percentage = percentage_cont.attr("aria-valuenow");

        percentage_el.css({
            'transform': 'none',
            'width': percentage + "%",
        });
    }
    
    handleChangeFilter = event => {
        const { cookies } = this.props;
        cookies.set('selectedSitesFilter', event.currentTarget.id, { path: '/'});

        this.setState({...this.state, filteredBy: event.currentTarget.id });
    };

    handleEditRoutes = event => {
        this.goToSite("/editroutes");
    };

    // handleRequestSort = (event, property) => {
    //     const { cookies } = this.props;
    //     const orderBy = property;
    //     let order = 'desc';

    //     if (this.state.orderBy === property && this.state.order === 'desc') {
    //         order = 'asc';
    //     }

    //     cookies.set('sitesOrder', order, { path: '/'});
    //     cookies.set('sitesOrderBy', orderBy, { path: '/'});
    //     this.setState({...this.state, order, orderBy });
    // };

    goToSite(url) {
        // const { cookies } = this.props;
        // cookies.set('patient-tab-value', 0, { path: '/'});
        this.props.history.push(url);
    }

    directionHandler(lat,long){
            if /* if we're on iOS, open in Apple Maps */
              ((navigator.platform.indexOf("iPhone") !== -1) || 
               (navigator.platform.indexOf("iPad") !== -1) || 
               (navigator.platform.indexOf("iPod") !== -1))
              window.open("maps://maps.google.com/maps?daddr="+ lat + "," + long + "&amp;saddr=" + Main.latitude + "," + Main.longitude);
          else /* else use Google */
              window.open("https://maps.google.com/maps?daddr="+ lat + "," + long + "&amp;saddr=" + Main.latitude + "," + Main.longitude);
            //   console.log("https://maps.google.com/maps?daddr="+ lat + "," + long + "&amp;saddr=" + Main.latitude + "," + Main.longitude);
    }
    
    navigate(lat, lng, name) {
        // If it's an iPhone..
        if ((navigator.platform.indexOf("iPhone") !== -1) || (navigator.platform.indexOf("iPod") !== -1)) {
          function iOSversion() {
            if (/iP(hone|od|ad)/.test(navigator.platform)) {
              // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
              var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
              return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
            }
          }
          var ver = iOSversion() || [0];
          var protocol = "";
          if (ver[0] >= 6) {
            protocol = 'maps://';
          } else {
            protocol = 'http://';
    
          }
          window.location = protocol + 'maps.apple.com/maps?q=' + name; //  + '&amp;ll=' + lat + ',' + lng + ',16z'
        //   window.location = protocol + 'maps.apple.com/maps?daddr=' + lat + ',' + lng + '&amp;ll=';
        }
        else {
          window.open('http://maps.google.com/maps?q=' + name); //  + '&amp;ll=' + lat + ',' + lng + ',16z'
        //   window.open('http://maps.google.com?daddr=' + lat + ',' + lng + '&amp;ll=');
        }
    }

    render() {   
        const drawData = this.props.drawData;
        
        // if(drawData.hasOwnProperty('no_draw_list')) {
        //     return(<NoDrawListScreen />);
        // }
        
        // console.log("drawData");
        // console.log(drawData);

        const { classes } = this.props;
        // const {order, orderBy} = this.state;        
        const display_name = (drawData.user !== undefined) ? drawData.user.first_name + "'s " : "";
        var totalDrawItems = 0; // whole site, completed and deferred
        var totalDone = 0; // whole site, including all status
        var allSitesCompleted = true;
        
        const SiteItem = ({data, siteDone, siteTotalDrawItems, isApproved, forFinalApproval}) => { // , hasStatRequest, hasFasting
            
            const city = data.site.city !== undefined && data.site.city !== "" ? data.site.city + " ": "";        
            const city_state_zipcode = city + [data.site.state, data.site.zipcode].filter(function(el) { return el; }).join( ", " );
            const map_link = [data.site.address, data.site.address_line_2, city_state_zipcode].filter(function(el) { return el; }).join( ", " );
            // console.log(map_link);

            // if(globals.DEBUG_ALLOWED) {
            //     console.log("Site type")
            //     console.log(data.site_type)
            //     console.log("For Final Approval(HH/Org): " + forFinalApproval)
            // }

            var targetSite = ""
            var site_type = data.site_type
            var patients_counter_per_site = ""

            if(site_type  === globals.ORGANIZATION) {
                targetSite = "/site_organization/" + data.id;
                patients_counter_per_site = <Typography className={classes.patientCounterPerSite}>{data.siteDone}/{data.siteTotalDrawItems} Draws</Typography>;
            } else  if(site_type  === globals.HOUSEHOLD) {
                targetSite = "/site_household/" + data.id;
                patients_counter_per_site = <Typography className={classes.patientCounterPerSite}>{data.siteDone}/{data.siteTotalDrawItems} Draws</Typography>;
            } else  if(site_type  === globals.INDIVIDUAL || site_type  === undefined) {
                targetSite = "/patient/" + data.patient_list[0].id + "/" + data.id
                site_type = globals.INDIVIDUAL // Setting it globals.INDIVIDUAL just incase the value is undefined
            }
            
            return (
            <TableRow 
                key={data.site.id}
                className={classNames(classes.sitesTableRow)}>
                <TableCell 
                    className={classNames(classes.tableCell, classes.sitesTableCell)}
                    >
                    <div className={classNames(classes.siteContainer, (siteDone === siteTotalDrawItems && isApproved) ? classes.siteRowCompleted : '')}>
                        {((site_type === globals.HOUSEHOLD ||
                            site_type === globals.ORGANIZATION) && forFinalApproval) &&
                            <Typography className={classes.forFinalApproval}>For Final Approval</Typography>
                        }

                        <div className={classes.siteInfo}
                            //onClick={() => this.goToSite(`/patient/${data.patient_list[0].id}/${data.id}`)}
                            onClick={() => this.goToSite(targetSite)}                        
                        >
                            <Typography 
                                className={classes.siteName}
                                variant="body1">
                            { moment(data.collection_time, "hh:mm:ss").format('hh:mm a')} 
                            </Typography>
                            <Typography
                                className={classes.siteAddress}>
                                {data.site.name}
                            </Typography>  
                            <Typography className={classes.siteType}>{site_type}</Typography>
                            {patients_counter_per_site}
                            <Typography
                                className={classes.siteAddress}>
                                {data.site.address}
                            </Typography>                    
                            { data.site.address_line_2 !== "" &&
                            <Typography
                                className={classes.siteAddress}>
                                {data.site.address_line_2}
                            </Typography>
                            } 
                                          
                            <Typography
                                className={classes.siteAddress}>
                                {/* {data.site.state + ", " + data.site.zipcode} */}
                                {city_state_zipcode}
                            </Typography>
                        </div>
                        <div className={classes.optionsContainer}>
                            <a href={"tel:" + data.site.phone_number}>
                                <CallSVG className={classNames(classes.optionsIcon)}></CallSVG>
                                {/* <img alt="TeloPoint Call Icon" className={classNames(classes.optionsIcon)} src="/images/call.svg" /> */}
                            </a>
                        </div>
                        <div className={classes.optionsContainer}
                            onClick={()=>this.navigate(data.site.latitude, data.site.longitude, map_link)}
                        > 
                            <SitesGrayFillSVG className={classNames(classes.optionsIcon)}></SitesGrayFillSVG>
                            {/* <img alt="TeloPoint Location Icon" className={classNames(classes.optionsIcon)} src="/images/sites_gray_fill.svg" /> */}
                        </div>
                    </div>
                </TableCell>
            </TableRow> 
        )};

        // temp solution, it should be sorting on multi dimensional array
        var mySitesInjectedWithKeysForSorting = [];
        // var mysites = [];
        var filteredMySites = [];
        var percentage = 0;

        if(drawData.draw_list !== undefined) {
            mySitesInjectedWithKeysForSorting = drawData.draw_list.map(site => {
                var immuSite = ImmutableJS.fromJS(site).toJS();
               
                var stats = {
                    siteDone: 0,
                    siteTotalDrawItems: 0,
                    siteApproved: true,
                };
                var site_has_stat = false;
                var site_has_fasting = false;
                site.patient_list.map(patient => {
                    patient.patient_draw.map(drawItem => {
                        if(drawItem.status === "done" || drawItem.status === "completed") {
                            stats.siteItemCompleted++;
                            totalDone++;
                            stats.siteDone++;
                        }
                        if(drawItem.status === "skipped" || drawItem.status === "refused" || drawItem.status === "unable") {
                            totalDone++;
                            stats.siteDone++;
                        }
                        
                        if(patient.urgent) {
                            site_has_stat = true;
                        }
    
                        if(patient.fasting) {
                            site_has_fasting = true;
                        }
    
                        stats.siteTotalDrawItems++;
                        totalDrawItems++;
                        return '';
                    });
                    return'';
                }); 
                
                // will be used just for sorting
                // keys that was used under row_headers
                immuSite.site_name = immuSite.site.name;
                immuSite.address = immuSite.site.address;
                immuSite.address_line_2 = (immuSite.site.address_line_2 !== undefined) ? immuSite.site.address_line_2 : '';
                immuSite.status = (stats.siteDone === stats.siteTotalDrawItems) ? "Complete" : "";
                immuSite.no_of_draws = stats.siteTotalDrawItems;
                immuSite.siteDone = stats.siteDone;
                immuSite.siteTotalDrawItems = stats.siteTotalDrawItems;
                immuSite.hasStatRequest = site_has_stat;
                immuSite.hasFasting = site_has_fasting;
                // console.log(site.site);
                // console.log("siteComplete: " + helper.siteComplete(site) + " " + helper.siteHasFinalApproval(site))
                if(site.site_type === globals.INDIVIDUAL) {
                    immuSite.isApproved = helper.siteComplete(site);// stats.siteApproved;
                } else {
                    immuSite.isApproved = helper.siteComplete(site) && helper.siteHasFinalApproval(site);
                    immuSite.forFinalApproval = helper.siteComplete(site) && !helper.siteHasFinalApproval(site);
                }

                if(!immuSite.isApproved) {
                    allSitesCompleted = false;
                }

                return immuSite;
            });
    
            // ! used bubbleSort below instead
            // mysites = stableSort(mySitesInjectedWithKeysForSorting, getSorting(order, orderBy)).map(site => {
            //     console.log(site.site_order + ". " + moment(site.collection_time, "HH:mm:ss").format("hh:mm A"))
            //     var el = <SiteItem key={site.id} data={site} siteDone={site.siteDone} siteTotalDrawItems={site.siteTotalDrawItems} hasStatRequest={site.hasStatRequest} hasFasting={site.hasFasting} isApproved={site.isApproved} forFinalApproval={site.forFinalApproval} />;
            //     var returnEl;
            //     if(this.state.filteredBy === "filter_all") {
            //         returnEl = true;
            //     } else if(this.state.filteredBy === "filter_completed" && site.siteDone === site.siteTotalDrawItems) {
            //         returnEl = true;
            //     } else if(this.state.filteredBy === "filter_with_remaining" && site.siteDone !== site.siteTotalDrawItems) {
            //         returnEl = true;
            //     }
    
            //     return((returnEl) ? el : "");
            // });

            // ---------------- bubbleSort UI Start--------------------------------------------
            const sortedSites = stableSort(mySitesInjectedWithKeysForSorting, getSorting("asc", "site_order")).map(site => {
                return site;
            });
            // var proceedBubleSort = false;
            var doneSites = [];
            // var currentSite = [];
            var remainingSites = [];

            // explode sites: done, being work on, remaining sites
            sortedSites.forEach(function (site) {
                var stats = {
                    siteDone: 0,
                    siteTotalDrawItems: 0,
                    siteApproved: true
                };

                site.patient_list.map(patient => {
                    patient.patient_draw.map(drawItem => {
                        if(drawItem.status === "done" || drawItem.status === "completed") {
                            stats.siteDone++;
                        }         
                        if(drawItem.status === "skipped" || drawItem.status === "refused" || drawItem.status === "unable") {
                            stats.siteDone++;
                        }

                        stats.siteTotalDrawItems++;
                        return '';
                    });
                    return '';
                });

                if(stats.siteDone === stats.siteTotalDrawItems && stats.siteApproved) {
                    doneSites.push(site);
                } else {
                    remainingSites.push(site);
                }
            });

            // sort doneSites first by collection time before concat
            doneSites = stableSort(doneSites, getSorting("asc", "collection_time")).map(site => {
                return site;
            });
            remainingSites = stableSort(remainingSites, getSorting("asc", "collection_time")).map(site => {
                return site;
            });

            var mergedSites = doneSites.concat(remainingSites);              
            // ---------------- bubbleSort UI End--------------------------------------------
    
            var mergedSitesUI = mergedSites.map(site => {
                // console.log(site)
                var el = <SiteItem key={site.id} data={site} siteDone={site.siteDone} siteTotalDrawItems={site.siteTotalDrawItems} hasStatRequest={site.hasStatRequest} hasFasting={site.hasFasting} isApproved={site.isApproved} forFinalApproval={site.forFinalApproval} />;
                var returnEl;
                if(this.state.filteredBy === "filter_all") {
                    returnEl = true;
                } else if(this.state.filteredBy === "filter_completed" && site.siteDone === site.siteTotalDrawItems) {
                    returnEl = true;
                } else if(this.state.filteredBy === "filter_with_remaining" && site.siteDone !== site.siteTotalDrawItems) {
                    returnEl = true;
                }
                return((returnEl) ? el : "");
            });
            filteredMySites = mergedSitesUI.filter(function (el) {
              return el !== "";
            });

            if(totalDrawItems > 0) {
                percentage = Math.round(totalDone/totalDrawItems * 100);
            }
        }

        // const row_headers = [
        //     { id: 'site_order', sortingEnabled: true, label: 'Ordering' },
        //     { id: 'site_name', sortingEnabled: true, label: 'Name' },
        //     { id: 'status', sortingEnabled: true, label: 'Status' },
        //     { id: 'no_of_draws', sortingEnabled: true, label: 'Draws' },
        // ];

        return(
            <div className={classes.root}>
                <main className={classes.content}>

                    <div className={classNames(classes.headerInfo)}>
                        <ApplicationBar title={display_name + "Patient List"}  />
                        <CurrentDate />
                        <div className={classNames(classes.progressContainer)}>

                            <Typography 
                                className={classNames(classes.allowanceTop, classes.overallProgress)}
                                align="left"
                                variant="body1">
                                Overall Progress
                            </Typography>

                            <LinearProgress 
                                id="overall-progress"
                                className={classNames(
                                    classes.progressBar,
                                )}
                                classes={{
                                    // bar: classes.progressBarContainer,
                                    colorPrimary: classes.linearColorPrimary,
                                    barColorPrimary: classes.linearBarColorPrimary //`" style="background-color: #12D003;border-radius: 40%;transform: none !important;width: ${percentage}%" class="`
                                }}
                                variant="determinate"
                                value={percentage}  />
                                {/* value={percentage} */}
                            
                            <Typography
                                style={{float: "left", marginLeft: "6px"}}
                                className={classes.progressPercentageText}>
                                {totalDone}/{totalDrawItems}
                            </Typography>
                            <Typography
                                align="right"
                                style={{marginRight: "6px"}}
                                className={classes.progressPercentageText}>
                                {percentage}% Draws Complete</Typography>
                                {/* {totalDone}/{totalDrawItems} ({percentage}% Complete) */}
                        </div>
                    </div>

                    <div className={classNames(classes.siteListContainer)}>
                        {/* <Paper> */}
                            <Table className={classes.myTable}>
                                <TableHead>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell
                                            className={classNames(classes.filterContainerTableCell)}>
                                            <div className={classNames(classes.filterContainer)}>
                                                <Button
                                                    disabled={true}
                                                    className={classNames(classes.filters, classes.filterView)}>
                                                    View:</Button>
                                                <div className={classes.filterSelectionDiv}>
                                                    <Button
                                                        id="filter_all"
                                                        className={classNames(classes.filters, this.state.filteredBy === "filter_all" ? classes.selectedFilter : "")}
                                                        onClick={this.handleChangeFilter}>
                                                        All</Button>
                                                    <Button
                                                        id="filter_completed"
                                                        className={classNames(classes.filters, this.state.filteredBy === "filter_completed" ? classes.selectedFilter : "")}
                                                        onClick={this.handleChangeFilter}>
                                                        Completed</Button>
                                                    <Button
                                                        id="filter_with_remaining"
                                                        className={classNames(classes.filters, this.state.filteredBy === "filter_with_remaining" ? classes.selectedFilter : "")}
                                                        onClick={this.handleChangeFilter}>
                                                        Remaining</Button>
                                                </div>
                                            </div>
                                        </TableCell>
                                        {/* <TableCell
                                            className={classNames(classes.lastTableCellHeader, classes.editRouteTableCell)}>
                                            <Button 
                                                id="edit_route_button"
                                                className={classNames(classes.editRoute)}
                                                disabled={filteredMySites.length === 0}
                                                onClick={this.handleEditRoutes}>
                                                Edit Route</Button>
                                        </TableCell> */}
                                    </TableRow>
                                </TableHead>

                                {/* <EnhancedTableHead
                                    row_headers={row_headers}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={this.handleRequestSort}
                                /> */}
                                <TableBody>
                                {filteredMySites.length === 0
                                ?
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classNames(classes.sitesSortableCell, classes.sitesTableCell)}>                                            
                                            <div className={classes.siteContainer}>
                                                <Typography className={classNames(classes.tableCell, classes.centered)} variant="body2">No data to show.</Typography>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                : 
                                filteredMySites
                                }
                                </TableBody>
                            </Table>
                        {/* </Paper> */}

                        <br/>
                        {/* { (totalDrawItems > 0 && drawData.draw_list !== undefined && totalDrawItems === totalDone) && */}
                        {(drawData.draw_list !== undefined && drawData.draw_list.length > 0 && allSitesCompleted) &&
                            <div className={classNames(classes.centered)}>
                                <Typography className={classes.allSitesNote}>All Sites Completed!</Typography>
                                <Icon className={classes.greatJobIcon}>check_circle</Icon>
                            </div>
                        }
                        
                        <div className={classes.toolbar} />
                    </div>
                </main>
                <SimpleBottomNavigation />
            </div>
        );
    }
}

SitesClass.propTypes = {
    classes: PropTypes.object.isRequired,
};

const Sites = withCookies( connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SitesClass)));
export default Sites;
