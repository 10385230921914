import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
// import classNames from 'classnames'

import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import {
    SignBelow
} from './Step7Components.js'
// import uuid from "uuid"

const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const Step7FunctionClass = (props) => {
    const {
        isOrderRemaining,
        classes,
        onSetBtnNextDisabled,
        patientWorkflowData,
        onChangeData,
        onAcceptSignature,
        onClearSignature
    } = props
    const [signatureData, setSignatureData] = useState({})
    const [hasEdit, setHasEdit] = useState(false)
    // load sign data to state
    const captureSignature = (payload) => {
        setHasEdit(true)
        onSetBtnNextDisabled(true)
        onChangeData(payload)
    }

    // save sign data loaded to state
    const acceptSignature = () => {
        setHasEdit(false)
        onAcceptSignature()
    }

    // clear sign data from state then save
    const clearSignature = () => {
        setHasEdit(false)
        onClearSignature()
    }

    useEffect(() => {
        setSignatureData(patientWorkflowData ? patientWorkflowData[0].workflow_data.sign_off : {})
    }, [patientWorkflowData, setSignatureData])

    // listen whenever signatureData changed
    useEffect(() => {
        if (isOrderRemaining) {
            // disable if empty object
            onSetBtnNextDisabled((signatureData && Object.keys(signatureData).length === 0) || hasEdit)
        }
    }, [hasEdit, signatureData, isOrderRemaining, onSetBtnNextDisabled])

    useEffect(() => {
        // force enable after done/unable
        if (!isOrderRemaining) {
            onSetBtnNextDisabled(false)
        }
    }, [isOrderRemaining, onSetBtnNextDisabled])

    return (
        <>
            <div className={classes.signatureContainer}>
                <SignBelow
                    readOnly={!isOrderRemaining}
                    hasEdit={hasEdit}
                    signatureData={signatureData}
                    classes={classes}
                    captureSignature={captureSignature}
                    acceptSignature={acceptSignature}
                    clearSignature={clearSignature}
                />
            </div>
        </>
    )
}

const Step7 = withRouter(
    withCookies(
        connect(mapStateToProps)(
            withStyles(styles, { withTheme: true })(Step7FunctionClass)
        )
    )
)
export default Step7
