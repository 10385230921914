import React, { useState } from 'react'

import SwipeableViews from 'react-swipeable-views'
import Animated from 'animated/lib/targets/react-dom'

// https://react-swipeable-views.com/demos/demos/#coverflow
const SwipeableTimePicker = (props) => {
    const {
        allTimes,
        initialSelectedTime,
        onChangeTime
    } = props

    const styles = {
        root: {
            margin: "120px 0px",
            overflow: 'visible',
            backgroundColor: "#F0FAFE",
        },
        slideContainer: {
            height: 40,
        },
        slide: {
            minHeight: 30,
            padding: "5px 10px",
            textAlign: "center",
            color: "#2E2E2E",
            fontSize: 20,
        },
        active: {
            color: "#68CBEF",
        },
    }

    const initialIndex = initialSelectedTime === "" ? allTimes.indexOf("08:00 AM")  : allTimes.indexOf(initialSelectedTime)

    const [slideIndex, setSlideIndex] = useState(initialIndex)
    const [position, setPosition] = useState(new Animated.Value(initialIndex))

    const handleChangeIndex = (index) => {
        setSlideIndex(index)
        onChangeTime(allTimes[index])
    }

    const handleSwitch = (index, type) => {
        if (type === 'end') {
            Animated.spring(position, { toValue: index }).start()
            return
        }

        position.setValue(index)
    }

    return (
        <SwipeableViews
            style={styles.root}
            containerStyle={styles.slideContainer}
            axis="y"
            resistance
            index={slideIndex}
            onChangeIndex={handleChangeIndex}
            onSwitching={handleSwitch}
        >
            {allTimes.map((time, currentIndex) => {
                const inputRange = allTimes.map((_, i) => i)
                const opacity = position.interpolate({
                    inputRange,
                    outputRange: inputRange.map(i => {
                        var opa_val = 0.0

                        switch (i) {
                            case currentIndex:
                                opa_val = 1
                                break
                            case currentIndex - 1:
                            case currentIndex + 1:
                                opa_val = 0.8
                                break
                            case currentIndex - 2:
                            case currentIndex + 2:
                                opa_val = 0.5
                                break
                            case currentIndex - 3:
                            // case currentIndex + 3:
                                opa_val = 0.3
                                break
                            default:
                                opa_val = 0.0
                                break
                        }

                        return opa_val//currentIndex === i ? 1 : 0.3;
                    }),
                })

                return <Animated.div
                    key={String(currentIndex)}
                    style={Object.assign(
                        { opacity },
                        styles.slide,
                        time === allTimes[slideIndex] ? styles.active : {}
                    )}
                    onClick={() => {
                        handleChangeIndex(currentIndex)
                        handleSwitch(currentIndex)
                    }}
                >{time}</Animated.div>
            })}
        </SwipeableViews>
    )
}

export default SwipeableTimePicker
