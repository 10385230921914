import React, { useState, useEffect } from 'react'

import { withStyles } from '@material-ui/core/styles'
import patientStyles from './styles'
// import classNames from 'classnames'

import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { connect } from "react-redux"


import { RadioGroup, FormControlLabel, Radio, TextField } from '@material-ui/core';

import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'

import Footer from './Footer'

import Grid from '@material-ui/core/Grid'

import { Button } from '@material-ui/core';
// import Avatar from '@material-ui/core/Avatar'
// import CheckIcon from '@material-ui/icons/Check'

import { FormControl } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const NewPatientFormFunctionClass = (props) => {
    const { classes, history, } = props

    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 18); //for setting birthday

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const backToSettings = () => {
        history.push("/workflow_settings")
    };

    const ExistingNewPatient = () => {
        history.push("/ExistingNewPatient")
    };

    const SearchPatient = () => {
        history.push("/SearchPatient")
    };

    const ScanQRCode = () => {
        history.push("/ScanQRCode")
    };

    return (
        <>
            <div className={classes.patientDetailsContainer}>
                <FormControl variant="outlined" className={classNames(classes.fullWidth)}>
                    <Typography className={classes.labels}> First Name *</Typography>
                    <TextField variant="outlined" placeholder='First Name' />
                    <br />
                    <Typography className={classes.labels}> Last Name * </Typography>
                    <TextField variant="outlined" placeholder='Last Name' />
                    <br />
                    <Typography className={classes.labels}> Middle Name (Optional) </Typography>
                    <TextField variant="outlined" placeholder='Middle Name (optional)' />
                    <br />
                    <Typography className={classes.labels}> Date of Birth * </Typography>
                    <TextField id="birth-date" variant="outlined" type="date" defaultValue="mm-dd-yy" InputLabelProps={{ shrink: true, }}
                        InputProps={{ min: minDate.toISOString().split('T')[0], }} />
                    <br />
                    <Typography className={classes.labels}> Gender * </Typography>
                    <RadioGroup row aria-label="gender" name="gender">
                        <FormControlLabel value="female" control={<Radio />} label="Male" />
                        <FormControlLabel value="male" control={<Radio />} label="Female" />
                    </RadioGroup>
                    <br />
                    <Typography className={classes.labels}> Email * </Typography>
                    <TextField variant="outlined" placeholder='ex: joe@gmail.com' />
                    <br />
                    <Typography className={classes.labels}> Phone Number * </Typography>
                    <TextField variant="outlined" placeholder='(000) 000-0000' />
                    <br />
                    <Typography className={classes.labels}> Complete Address * </Typography>
                    <TextField variant="outlined" placeholder='Enter a location' />
                    <br />
                    <TextField variant="outlined" placeholder='Street Number' />
                    <br />
                    <TextField variant="outlined" placeholder='City' />
                    <br />
                    <TextField variant="outlined" placeholder='State' />
                    <br />
                    <TextField variant="outlined" placeholder='Postal Code' />
                    <br />
                    <TextField variant="outlined" placeholder='Country' />
                    <br />
                    <TextField variant="outlined" placeholder='Apartment/Unit (optional)' />
                    <br />
                    <Button className={classes.cancelButton} onClick={ExistingNewPatient}> Add Existing/New Patient </Button>
                    <br />
                    <Button className={classes.cancelButton} onClick={SearchPatient}> Search & Select Patient </Button>
                    <br />
                    <Button className={classes.cancelButton} onClick={ScanQRCode}> Scan QR Code </Button>

                </FormControl>

                <Grid container justify="space-between" className={classes.footerContainer}>
                    <Button className={classes.cancelButton} onClick={handleClickOpen}> Cancel </Button>

                    <Button color="primary" className={classes.acceptButton} onClick={backToSettings}> Submit </Button>
                </Grid>

                <Dialog open={open} onClose={handleClose} aria-labelledby="confirm-title" id="confirm-dialog" fullScreen={false}
                    classes={{ paper: classes.cancelDialogBox }} disableBackdropClick={true}>

                    <DialogTitle id="form-dialog-title">Cancel Confirmation</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to cancel? Your changes with be discarded.
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose} className={classes.cancelButton}> Cancel </Button>
                        <Button onClick={backToSettings} className={classes.acceptButton}> Okay </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    )
}

const NewPatientForm = withRouter(
    withCookies(
        connect(mapStateToProps)(
            withStyles(patientStyles, { withTheme: true })(NewPatientFormFunctionClass)
        )
    )
)
export default NewPatientForm