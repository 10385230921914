import * as globals from 'components/common/GlobalConstants';

const styles = theme => ({
    root: {
        // display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
    },
    overrideBlue: {
        background: globals.BLUE_GRADIENT_BG,
    },
    headerInfo: {
        paddingTop: "15px",
        paddingLeft: "15px",
        paddingRight: "15px",
    },
    buttonsContainer: {
        marginRight: 11
    },
    scheduleContainer: {
        padding: "15px",
    },
    list: {
      border: "1px solid rgba(0, 0, 0, 0.16)",
      marginBottom: theme.spacing.unit,
      fontSize: "12px !important",
    },
    label: {
        fontSize: "14px !important",
        color: "#707070",
    },
    buttons: {
        fontSize: "14px !important",
        textTransform: "none",
        border: "none",
        borderRadius: 0,
        padding: "6px 16px",
        marginLeft: 6
    },
    customApplicationBarHeader: {
        padding: "7px 17px",
        marginLeft: "-15px",
        marginRight: "-15px"
    },
    listULMainHeader: {
        marginTop: 25
    },
    schedulesMainHeader: {
        color: "#707070 !important",
        fontSize: '24px !important',
        fontWeight: 'bold',
        padding: "0px !important",
        margin: "0px !important"
    },
    schedulesMainContainer: {
        margin: 15,
    },
    listUL: {
        paddingTop: 0,
        paddingBottom: 0
    },
    settingsContent: {
        paddingLeft: 16,
        paddingRight: 16,
    },
    textFieldContainer: {
        padding: 0
    },
    listItemRoot: {
        padding: "0px !important",
        // border: "1px solid black",
    },
    logsRoot: {
        fontSize: 14,
        padding: "0px !important",
        // border: "1px solid black !important",
        //boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
    },
    logsInput: {
        width: '100%',
        padding: "7px 10px !important",
        border: "1px solid #ccc !important",
        overflow: 'auto',
        whiteSpace: "nowrap",
    },
    // formControl: {
    //     width: '100%',
    // },
    logsNote: {
        fontSize: "12px !important",
        margin: "10px 0px"
    },
    clearButton: {
        color: "#767676",
        marginRight: 5
    },
    addbutton: {
        fontWeight: "bold"
    },
    blueButton: {
        padding: "4px !important",
        fontWeight: "bold",
        color: "white !important",
        backgroundColor: "#68CBEF !important",
    },
    listPaper: {
        marginTop: 30
    },
    listRoot: {
        padding: 0
    },
    listOdd: {
        backgroundColor: "#F4F4F4"
    },
    listHeader: {
        fontSize: 10,
        color: "white !important",
        backgroundColor: "#68CBEF !important",
        padding: "4px 16px"
    },
    listContent: {
        color: "#767676",
        fontSize: 14,
        padding: "8px 16px"
    }
});

export default styles;