import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
import classNames from 'classnames'

import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

// import CurrentDate from 'components/common/workflow/CurrentDate'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

// import uuid from "uuid"

const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const ApptDetailsHeaderFunctionClass = (props) => {
    const { classes, history } = props
    // const { appointment_id } = match.params
    // // init values
    // var theSite = {
    //     site: {
    //         name: ""
    //     }
    // }

    // if (drawData.draw_list !== undefined) {
    //     theSite = drawData.draw_list.find(function (site) {
    //         // appointment_id === site_id
    //         return site.id === appointment_id
    //     })
    // }

    const closeApptDetails = () => {
        history.push("/workflow_settings")
    }

    return (
        <>
            <div className={classes.apptDetailsHeaderContainer}>
                <Grid
                    container
                    alignItems="center"
                >
                    <Grid item xs={3}>
                        <IconButton aria-label="Close" className={classNames(classes.closeApptDetailsButton)} onClick={closeApptDetails}>
                            <CloseIcon className={classNames()}></CloseIcon>
                        </IconButton>
                    </Grid>
                    <Grid item xs={6} zeroMinWidth style={{ textAlign: "center" }}>
                        <Typography variant="h6">Logs</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className={classes.currentStepLabelPlaceholder}></Typography>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

const ApptDetailsHeader = withRouter(
    withCookies(
        connect(mapStateToProps)(
            withStyles(styles, { withTheme: true })(ApptDetailsHeaderFunctionClass)
        )
    )
)
export default ApptDetailsHeader
