import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import classNames from 'classnames';
//import Typography from '@material-ui/core/Typography';
import ApplicationBar from 'components/common/mobile/ApplicationBar';
import SimpleBottomNavigation from 'components/common/mobile/SimpleBottomNavigation';
import { connect } from "react-redux";

import Button from '@material-ui/core/Button';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import styles from "./styles.js";
import { TextField } from '@material-ui/core';

import { 
    updateScheduleSettings
    } from "js/actions/index";

import ImmutableJS from "immutable";

const mapStateToProps = state => {
  return { 
      drawData: state.drawData,
      scheduleData: state.scheduleData
    };
};
const mapDispatchToProps = dispatch => {
    return {
        updateScheduleSettings: schedule_settings => dispatch(updateScheduleSettings(schedule_settings))
    };
};

class SchedulesSettingClass extends Component {
    constructor(props){
        super(props);

        this.state = {
            ...this.state,
            schedule_settings: {
                distance_covered : {value: 0, unit: "mi"}
            }
           
        };

    }

    componentDidMount() {
        console.log('SchedulesSettingClass did mount.');
       
        var schedule_settings = ImmutableJS.fromJS(this.props.scheduleData.settings).toJS();
        console.log(schedule_settings);
        this.setState({...this.state,
            schedule_settings: schedule_settings, 
           // distance_covered : this.props.scheduleData.settings.distance_covered 
        });    
    }

    componentDidUpdate(prevProps) {
        // console.log("this.props.scheduleData.settings");
        // console.log(this.props.scheduleData.settings);

        // if(JSON.stringify(this.props.scheduleData) !== JSON.stringify(prevProps.scheduleData )){
        //     console.log("need to update ");
        //     console.log("this.props.scheduleData.settings");
        //     console.log(this.props.scheduleData.settings);
        //     console.log("this.state.schedule_settings");
        //     console.log(this.state.schedule_settings);
            
        //     var schedule_settings = ImmutableJS.fromJS(this.props.scheduleData.settings).toJS();

        //     this.setState({...this.state,
        //         schedule_settings: schedule_settings, 
        //        // distance_covered : this.props.scheduleData.settings.distance_covered 
        //     });    
        // }
    }

    onUpdate = () => {
        this.props.updateScheduleSettings(this.state.schedule_settings);
        // this.props.updateScheduleSettings({
        //     "id": "1448d152-c202-4acd-8dbf-9a265fc01787",
        //     "user": "7cd80e02-975c-4764-be84-dc4f7f1b9aa0",
        //     "distance_covered": {
        //       "value": 0,
        //       "unit": "mi"
        //     },
        //     "distance_editable": true,
        //     "date_from_editable": true,
        //     "date_to_editable": true,
        //     "time_from_editable": true,
        //     "time_to_editable": true,
        //     "label_editable": true,
        //     "client_timestamp": "",
        //     "client_timezone": "",
        //     "repeat_options": {
        //       "every_day": "Every Day",
        //       "every_week": "Every Week",
        //       "every_n_date": "Every N_DATE",
        //       "every_n_week": "Every N_WEEK",
        //       "every_year": "Every Year"
        //     },
        //     "scheduler_type": "",
        //     "leave_approval": 0
        //   });
        
        this.goToSite('/schedules');
        
    }

    goToSite(url) {
        this.props.history.push(url);
    }

    handleChangeDistanceCovered = (event) =>{
        console.log(event.target.value);

        var schedule_settings = this.state.schedule_settings;
        schedule_settings.distance_covered.value = event.target.value;
        this.setState({
            ...this.state,
            schedule_settings: schedule_settings
        });
    }

    goToSchedulePage() {
        this.props.history.push("/schedules");
    }

    render() {   
        const { classes } = this.props;
        console.log("RENDER");
        var distance_covered = {value: 0, unit:"mi"};
        var unit="mi";
        try {
            distance_covered = this.state.schedule_settings.distance_covered;
            console.log(distance_covered);
            unit = "Maximum travel radius (" + distance_covered.unit + ")";
        } catch(e){
            distance_covered = {value: 0,unit:"mi"};
            unit="mi";
        }
        return(
            <div className={classes.root}>
                <main className={classes.content}>
                    <ApplicationBar isInfoHidden={true} />
                    <div className={classNames(classes.schedulesMainContainer)}>
                        <div>
                            <List classes={{root: classNames(classes.listUL, classes.listULMainHeader)}}>
                                <ListItem>
                                    <ListItemText
                                        classes={{
                                            primary: classes.schedulesMainHeader
                                        }}
                                        primary="Schedule Settings"
                                    />
                                </ListItem>
                            </List>
                        </div>
                        <div className={classNames(classes.settingsContent)}>
                            <List component="nav">
                                <ListItem 
                                    button className={classNames(classes.list)}>
                                    <ListItemText classes={{secondary: classes.label}} secondary={unit} />
                                    
                                    <TextField
                                        id="standard-uncontrolled"
                                        value={distance_covered.value}
                                        className={classes.textField}
                                        // margin="normal"
                                        onChange={this.handleChangeDistanceCovered}
                                        inputProps={{
                                            style: { 
                                                textAlign: "center",
                                                padding: 0
                                            }
                                        }}
                                        />
                                </ListItem>
                                <br />
                                <Button
                                    onClick={() => this.goToSchedulePage()}
                                    variant="contained"
                                    color="default"                                
                                    className={classNames(classes.buttons)}>
                                    Cancel</Button>

                                <Button
                                    onClick={this.onUpdate}
                                    variant="contained"
                                    color="secondary"
                                    className={classNames(classes.buttons, classes.blueButton)}>
                                Save</Button>
                            </List>
                        </div>
                    </div>
                    <div className={classes.toolbar} />
                    <div className={classes.toolbar} />
                </main>
                <SimpleBottomNavigation />
            </div>
        );
    }
}

SchedulesSettingClass.propTypes = {
    classes: PropTypes.object.isRequired,
};

const ScheduleSettings = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SchedulesSettingClass));
export default ScheduleSettings;