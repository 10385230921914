import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import classNames from 'classnames';
import ApplicationBar from 'components/common/mobile/ApplicationBar';
import SimpleBottomNavigation from 'components/common/mobile/SimpleBottomNavigation';

// import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import { connect } from "react-redux";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import styles from "./styles.js";
//import { InputBase } from '@material-ui/core';
// import Paper from '@material-ui/core/Paper';
import uuid from "uuid";
import Typography from '@material-ui/core/Typography';
import $ from 'jquery';
//import Select from '@material-ui/core/Select';
//import FormControl from '@material-ui/core/FormControl';
import ConfirmDialog from 'components/common/mobile/ConfirmDialog';

import { deleteLogs } from "js/actions/index";

const mapDispatchToProps = dispatch => {
    return {
      deleteLogs: event => dispatch(deleteLogs(event)),
    };
};


const mapStateToProps = state => {
    return {          
        logs : state.logs
    };
};

class LogsClass extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            clearLogsDialogVisible : false,
            confirmDialogTitle: "",
            confirmDialogMessage: "",
            confirmDialogActionText: "",
            actionHandler: "",
            postActionHandler: ""
        }
    }

    componentWillMount() {
        // console.log('LogsClass did mount.');
        // console.log(this.props.scheduleData);
        // this.loadData();
    }

    componentDidUpdate(prevProps) {
        // this.loadData();
    }

    componentDidMount() {

        var title_height = 58; //$("li[class^='MuiListItem-root']").height();
        var computed_height = $( window ).height() - (100 + title_height + 100);
        // 100 for app bar and bottom navigation bar
        // 100 for other allowance
        // console.log($( window ).height());
        // console.log(computed_height);

        $("#logs-input").height(computed_height + "px");
    }

    // loadData = () => {
    //     this.setState({
    //         ...this.state,
    //         // zip_code_value: "",
    //     }); 
    // }

    goTo(url) {
        this.props.history.push(url);
    }

    clearLogs = () => {
        //alert("clear")
        this.props.deleteLogs()
    }

    copyallLogsToClipBoard = () => {
        // var values = this.props.logs.map((item) => {
        //     //return [item.log_date , item.module, item.action, JSON.stringify(item.description)].join(' ');
        //     return [item.log_date , item.module, item.action, item.description].join(',');
        // });
        try {
            this.copyToClipboard(this.props.logs)
        } catch(e) {
            console.log("copyallLogsToClipBoard")
            console.log(e)
        }
    }
    
    handleChangeMultiple = (event) => {
        // const { options } = event.target;
        // const values = [];
        // for (let i = 0, l = options.length; i < l; i += 1) {
        //     if (options[i].selected) {
        //         values.push(options[i].value);
        //     }
        // }
        // this.copyToClipboard(values);
    }

    // arrays of selected options
    copyToClipboard(arrs) {
        var $temp = $("<textarea>");
        $("body").append($temp);
        //var str = arrs.join("\n");
        var str = JSON.stringify(arrs)
        $temp.val(str).select();
        document.execCommand("copy");
        $temp.remove();
        //alert('Copied to Clipboard.')
    }

    setShowClearLogsDialog = (value) => {
        this.setState({ ...this.state, clearLogsDialogVisible: value});
    }

    setShowClearLogsDialogHandler = (actionHandler, postActionHandler, title, message, actionText, chainedFunction) => {
        this.setState(
            {
                ...this.state, 
                actionHandler: actionHandler, 
                postActionHandler : postActionHandler, 
                confirmDialogMessage: message,
                confirmDialogTitle: title,
                confirmDialogActionText: actionText,
            }, 
            chainedFunction
        );
    }
    
    logItems =(logs) => {
        var  items = []
            
        Object.keys(logs).forEach( (item)=> {
            //console.log("item")
            //console.log(item)
            var val = ["[" + logs[item].log_date_utc , logs[item].seq ,  logs[item].module,  logs[item].action,  logs[item].description+ "]"].join('] ['); // , item.user
            items.push(<Typography key={uuid.v4()} variant="caption">{val}</Typography>)
        })
          
        return items
    }

    render() {   
        const { classes, logs} = this.props;

        const logItems = this.logItems(logs)

        return(
            <div className={classes.root}>
                <main className={classes.content}>
                    <ApplicationBar isInfoHidden={true} />
                    <div className={classNames(classes.schedulesMainContainer)}>
                        <div>
                            <List classes={{root: classNames(classes.listUL, classes.listULMainHeader)}}>
                                <ListItem>
                                    <ListItemText
                                        classes={{
                                            root: classes.listItemRoot,
                                            primary: classes.schedulesMainHeader,
                                            secondary: classes.logsNote
                                        }}
                                        primary="Logs"
                                    />
                                </ListItem>
                                <ListItemSecondaryAction className={classNames(classes.buttonsContainer)}>
                                    <Button                                        
                                        onClick={() => {
                                            this.setShowClearLogsDialogHandler(
                                                () => this.clearLogs(), 
                                                () => { this.setShowClearLogsDialog(false) },
                                                "Clear Logs Confirmation",
                                                "Are you sure you want to clear Logs?",
                                                "Confirm",
                                                () => { this.setShowClearLogsDialog(true) }, 
                                            )
                                        }}
                                        variant="contained"
                                        color="secondary"
                                        className={classNames(classes.buttons, classes.blueButton)}>
                                    Clear</Button>
                                    <Button
                                        onClick={this.copyallLogsToClipBoard}
                                        variant="contained"
                                        color="secondary"
                                        className={classNames(classes.buttons, classes.blueButton)}>
                                    Copy</Button>
                                </ListItemSecondaryAction>
                            </List>
                        </div>
                        <div className={classNames(classes.settingsContent)}>
                            <List component="nav">
                                <ListItem 
                                    classes={{
                                        root: classes.textFieldContainer
                                    }}>
                                    <div id="logs-input" className={classes.logsInput}>
                                        {/* {logs.map((item) => {
                                            //var val = [item.log_date , item.module, item.action, JSON.stringify(item.description)].join(' ');
                                            var val = ["[" + item.log_date , item.module, item.action, item.description+ "]"].join('] ['); // , item.user
                                            return <Typography key={uuid.v4()} variant="caption">{val}</Typography>;
                                        })} */}
                                    {logItems}
                                        
                                    </div>
                                    {/* <FormControl className={classes.formControl}>
                                        <Select 
                                            multiple
                                            native
                                            // fullWidth={true}
                                            // value={"test"}
                                            // onChange={this.handleChangeMultiple}
                                            inputProps={{
                                                id: 'logs-input',
                                            }}
                                            >
                                            {logs.map((item) => {
                                                var val = [item.log_date , item.module, item.action, JSON.stringify(item.description)].join(' ');
                                                return <option key={uuid.v4()} value={val}>{val}</option>;
                                            })}
                                        </Select>                                        
                                    </FormControl> */}
                                    {/* <InputBase
                                        multiline={true}
                                        disableunderline="true"
                                        id="logs-input"
                                        fullWidth={true}
                                        rows={5}
                                        placeholder="..."
                                        value={logsContentStr}
                                        // readOnly={true}
                                        classes= {{
                                            root: classes.logsRoot,
                                            input: classes.logsInput
                                        }}
                                    /> */}
                                </ListItem>
                            </List>
                        </div>
                    </div>
                    <div className={classes.toolbar} />
                    <div className={classes.toolbar} />
                </main>
                <SimpleBottomNavigation />
                
                <ConfirmDialog 
                    visible = {this.state.clearLogsDialogVisible} 
                    title = {this.state.confirmDialogTitle} 
                    message = {this.state.confirmDialogMessage} 
                    actionText= {this.state.confirmDialogActionText} 
                    actionHandler={this.state.actionHandler} 
                    postActionHandler={this.state.postActionHandler} 
                    onClose={() =>{ 
                        setTimeout(() => {
                            this.setShowClearLogsDialog(false);
                        }, 100);
                    } }
                />
            </div>
        );
    }
}

LogsClass.propTypes = {
    classes: PropTypes.object.isRequired,
};

const Logs = connect(mapStateToProps, mapDispatchToProps) (withStyles(styles) (LogsClass));
export default Logs;
