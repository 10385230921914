const styles = theme => ({
    // root: {
    //     // display: 'flex',
    //     paddingTop: "15px !important",
    //     paddingLeft: "10px !important",
    //     paddingRight: "10px !important",
    // },

    root: {
    },
    content: {
        flexGrow: 1,
    },
    mainContainer: {
        padding: "15px 20px",
        marginBottom: "60px"
        // paddingLeft: "30px",
        // paddingRight: "30px",
    },
    // top navigation
    topNavContainer: {
        padding: "11px 0px"
    },
    topNavLink: {
        fontSize: "20px",
        marginRight: "25px",
        paddingBottom: "5px",
        color: "#AFAFAF !important"
    },
    activeTopNav: {
        color: "#333333 !important",
        borderBottom: "3px solid #69CEF1",
    },

    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
    },
    list: {
        borderBottom: "1px solid #E6E6E6 ",
        marginBottom: theme.spacing.unit,
        padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 0}px`,
        fontSize: "18px !important",
        //   whiteSpace : "nowrap"
    },
    icon: {
        fontSize: "38px !important",
    },
    imgIcon: {
        width: 38,
        height: 38
    },
    label: {
        fontSize: ".8em !important",
        color: "#707070",
    },
    version_label: {
        color: "#ff8d00 !important", // "#f31700 !important",
        fontSize: "16px !important",
    },
    buttons: {
        // margin: theme.spacing.unit * 2,
        // marginLeft: 0,
        textTransform: 'none',
        borderRadius: 0,
    },
    dialogPaper: {
        minWidth: "90%",
        margin: 10,
    },
    settingsContent: {
        // padding: "0px 16px"
    },
    forwardIcon: {
        fontSize: "14px !important",
    },
    UL: {
        marginTop: -10,
        padding: 0
    },
    miniDialogPaper: {
        maxHeight: "35%",
        minHeight: "35%",
        minWidth: "90%",
        margin: 10,
    },
    // pageTitle : {
    //   borderBottom: "1px solid #E6E6E6 ",
    //   marginBottom: theme.spacing.unit *4,
    //   padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
    //   fontSize: "20px !important",
    // }
    titleContainer: {
        marginTop: "35px",
        marginBottom: "25px",
        padding: "5px 20px 15px 20px",
        marginLeft: "-20px",
        marginRight: "-20px",
        borderBottom: "1px solid #E6E6E6",
    },
    title: {
        fontSize: "20px !important",
    },
});

export default styles