import * as globals from 'components/common/GlobalConstants';
import grey from '@material-ui/core/colors/grey';

const styles = theme => ({
    root: {
        // display: 'flex',
        // fullWidth: true
        margin: "0 !important",
        padding: "0 !important"
        
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
    },
    a: {
        boxSizing: 'border-box',
        minWidth: '10em'
    },
    headerInfo: {
      paddingTop: "15px",
      paddingLeft: "15px",
      paddingRight: "15px",
    },
    patientContainer: {
        paddingTop: "15px",
        paddingLeft: "15px",
        paddingRight: "15px",
    },
    overrideBlue: {
        background: globals.BLUE_GRADIENT_BG,
        color: "#fff",
    },
    overrideButtonBlue: {
        background: globals.LIGHT_BLUE_GRADIENT_BG,
        color: "#01579B",
    },
    avatar: {
        margin: 'auto',
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
        width: 100,
        height: 100,
        fontSize: 50,
    },
    grid_item: {
        padding: theme.spacing.unit,
    },
    margins: {
        // margin: theme.spacing.unit * 2.5,
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    warning_icon: {
        top: 50,
    },
    group: {
        margin: `${theme.spacing.unit}px 0`
    },
    swipeableTab: {
        backgroundColor: grey[50],
    },
    badge: {
        padding: `0 ${theme.spacing.unit * 2}px`,
    },
    progressBar: {
      height: 17,
    },
    // container: {
    //     display: 'flex',
    //     flexWrap: 'wrap',
    // },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 300,
    },
    table: {
        marginTop: `${theme.spacing.unit * 2.5}px auto`,
    },
    tableRow: {
        border: 'none'
    },
    patientInfoGrid: {
        textAlign: "left",
        padding: theme.spacing.unit,
    },
    icon: {
        lineHeight: 1.2,
        fontSize: 20,
    },
    textCentered: {
        textAlign: "center"
    },
    yellowPaperBG: {
        background: "#FFFBE7",
    },
    STAT: {
        fontSize: '11px !important',
        color: 'white',
        backgroundColor: '#D20815',
        display: 'inline',
        padding: `2px 8px`,
        borderRadius: 3,
        marginRight: 5,
    },
    FASTING: {
        fontSize: '11px !important',
        color: 'white',
        backgroundColor: '#0000FF',
        display: 'inline',
        padding: `2px 8px`,
        borderRadius: 3
    },
    buttons: {
        // margin: theme.spacing.unit * 2,
        marginLeft: 4,
        // backgroundColor: "#68CBEF",
        textTransform: 'none',
        borderRadius: 0,
    },
    mainButton: {
        fontSize: 18,
        padding: '10px 15px',
        backgroundColor: "#68CBEF",
        textTransform: 'none',
        borderRadius: 0,
        display: "block",
        color: "#fff",
        margin: `${theme.spacing.unit * 2.5}px auto`,
    },
    drawPatientTitle: {
        fontSize: 14,
    },
    resetButton: {
        textTransform: 'none',
        fontSize: 14,
        borderRadius: 0,
        padding: '8px 12px',
    },
    statusNotes: {
        fontSize: '14px !important',
    },
    tabContainer: {
        // padding: `${theme.spacing.unit}px 0px`,
        padding: '0px  !important'
    },
    gridContainer: {
        marginTop: 43,
        marginBottom: 25,
    },
    patientIdentityText: {
        fontSize: '14px !important',
        padding: `${theme.spacing.unit}px 0px`,
    },
    drawItemText: {
        fontSize: '12px !important',
        // fontWeight: 'bold',
        padding: `${theme.spacing.unit}px 0px`,
    },
    patientIdentityTab: {
        fontSize: '12px !important',
        fontWeight: 'bold'
        // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit}px`,
        // paddingLeft: '0px',
        // paddingRight: '0px',
    },
    patientIdentityBirthdateCellCont: {
        padding: '0px',
        border: "none",
    },
    patientIdentityCellCont: {
        padding: `0px`,
        border: "none",
    },
    patientIdentityLastCellCont: {
        paddingRight: `0px !important`,
    },
    tabLabel: {
        fontSize: '12px !important',
    },
    dialogPaper: {
        minWidth: "90%",
        margin: 10, // 18
    },
    dialogPaperForNotes:  {
        minWidth: "90%",
        // margin: 2, // 18

    },
    radioButton: {
      padding: "6px 12px",
    },
    patientAddress: {
        fontSize: '12px !important',
        width: '50%',
        float: 'left'
    },
    attachmentButton: {
        color : "#0277bd !important",
        padding: 0,
        fontSize: '14px !important',
        textAlign: 'left',
        marginBottom: "10px" 
    },
    attachmentIcon: {
        fontSize: '16px !important',
        marginRight: 5,
        // -ms-transform: rotate(20deg)
        // -webkit-transform: rotate(20deg)
        transform: "rotate(30deg)"
    }, 
    notesIcon: {
        fontSize: '16px !important',
        marginRight: 5,
        // -ms-transform: rotate(20deg)
        // -webkit-transform: rotate(20deg)
        //transform: "rotate(30deg)"
    },
    btnClosePDFContainer: {
        // zIndex: 16777271,
        // position: "absolute",
        // textAlign: "right",
        // width: "100%",
    },
    btnClosePDF: {
        // zIndex: 16777271,
        position: "absolute",
        right: 10,
        top: 10,
        // padding: "5px 10px",
        // minWidth: 20,
        // marginTop: 5,
        // marginRight: 5
        // textTransform: 'none'
        fontSize: 16,
        padding: '5px 15px',
        backgroundColor: "white",
        textTransform: 'none',
        color: "#696969",
        borderRadius: '100px',
        boxShadow: "none",
        border: "2px solid #696969"
    },
    mgrPdfViewer: {
        marginBottom: 50
    },
    mgrPdfViewerLoaderEl: {
        position: "absolute",
        width: "100%",
        textAlign: "center",
        marginTop: "50%"
    },
    zoomContainer: {
        position: "absolute",
        bottom: 1,
        right: 0,
        // padding: "5px 0px",
    },
    zoomIcons: {
        backgroundColor: "#696969",
        padding: "4px 15px",
        color: "white",
        marginRight: "5px",
        height: "21px",
        fontSize: "20px",
    },
    zoomIn: {

    },
    zoomDefault: {

    },
    zoomOut: {

    }, 
    mgrPdfViewerNavigation: {
        position: "absolute",
        width: "100%",
        bottom: 0,
        backgroundColor: "black",
        padding: "10px 0px",
        textAlign: "left"
    },
    mgrPdfViewerNavigationPreviousBtn: {
        display: "inline",
        backgroundColor: "#696969",
        padding: "5px 20px",
        marginLeft: "5px",
        '& a': {
            color: "white !important"
        }
    },
    mgrPdfViewerNavigationNextPageBtn: {
        display: "inline",
        backgroundColor: "#696969",
        padding: "5px 20px",
        '& a': {
            color: "white !important"
        }
    },
    mgrPdfViewerNavigationPages: {
        display: "inline",
        color: "white",
        padding: "5px 20px"
    },
    drawPatientRemarksField: {
        border: "1px solid #E6E6E6 !important",
        margin: "0"
    },
    txtAddBarcode: {
        border: "1px solid gray"
    },
    listContainer: {
        minHeight: 100,
        padding: "0 !important",
        margin: "0 !important",
        marginBottom: "40px !important"
    },
    btnAddPhoto: {
        backgroundColor: "#68CBEF",
        borderRadius: 0,
        width: '100%',
        margin: '15px 0px',
        // display: 'flex',
        // margin: '0px auto'
    },
    btnAddPhotoIcon: {
        marginRight: 10
    },
    barcodeValue: {
        fontSize: "18px !important"
    },
    requisitionList: {
        // backgroundColor: 'rgb(247, 247, 247)',
        paddingLeft: 15,
        marginBottom: 5,
        fontSize: 16,
        paddingTop: 3,
        paddingBottom: 3,
        wordBreak: 'break-all',
        '& span.material-icons': {
            fontSize: 16,
            marginRight: 15
        },
        '& svg': {
            width: 16,
            height: 18,
            marginRight: 15
        }
    },
    clearButton: {
        // color: '#9999998f',
        padding: 5,
        fontSize: 20,
        marginTop: 2,
    },
    photoCaptureDialogContent: {
        padding: '0px !important',
        overflow: "unset",
        flex: '1'
    },
    photoCaptureDialogActions: {
        // flex: '0 0 auto'
        margin: '10px 6px'
    },
    pdfDialogContainer: {
        // position: 'relative',
        padding: "0px !important"
    },
    imageViewer: {
        width: "100%",
        position: "absolute",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        margin: "auto"
    },
    noPhoto: {
        color: "#a9a9a9"
    },    
    addButtonContainer: {
        textAlign: "center",
        margin: "5px 0px 35px"
    },
    mainButtonWhiteBorderedSM: {
        backgroundColor: "white",
        color: "#696969",
        border: "2px solid #696969"
    },
    mainButtonGrayBorderedSM: {
        backgroundColor: "#696969 !important",
        color: "white",
        border: "2px solid #696969"
    },
    addNoteButton: {
        // fontSize: 16,
        height: 41,
        textTransform: 'none',
        borderRadius: '100px',
        boxShadow: "none",
        // padding: '0px 15px',
        padding: "0px 15px 0px 20px",
        fontSize: "18px !important",
    },
    addButtonDivider: {
        height: 39,
        border: "1px solid #696969",
        margin: "0px 10px 0px 12px"
    },
    addButtonDividerActive: {
        height: 39,
        border: "1px solid white",
        margin: "0px 10px 0px 12px"
    },
    addButtonArrowDown: {
        fontSize: "30px !important",
        fontWeight: "bold"
    },
    popperPaper: {
        border: 0,
        boxShadow: "none !important"
    },
    popperMenu: {
        padding: "0px !important",
        width: 173,
        marginTop: 2,
        '& li': {
            border: "2px solid #696969",
            paddingTop: 5,
            paddingBottom: 5,
            fontSize: 14,
            '& span.material-icons': {
                fontSize: 14,
                marginRight: 15
            },
            '& svg': {
                width: 14,
                marginRight: 15
            }
        },
        '& li:not(:last-child)': {
            borderBottom: "none",
        },
    }
});

export default styles;
