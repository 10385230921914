import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import patientStyles from './patientStyles.js'
import classNames from 'classnames'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"

import ApplicationBar from 'components/common/workflow/ApplicationBar'

import ApptDetailsHeader from './ApptDetailsHeader'
import ExistingNewPatient from './ExistingNewPatient'


const mapStateToProps = state => {
    return { drawData: state.drawData }
}

const ExistingNewPatientFunctionClass = (props) => {
    const { classes } = props

    return (
        <>
            <div className={classes.root}>
                <main className={classes.content}>
                    <div className={classNames(classes.mainContainer)}>
                        <ApplicationBar />

                        <ApptDetailsHeader />
                        <ExistingNewPatient />
                    </div>
                </main>
                 
            </div>
        </>
    )
}

const PatientSettings = withCookies(
    connect(mapStateToProps)(
        withStyles(patientStyles, { withTheme: true })(ExistingNewPatientFunctionClass)
    )
)
export default PatientSettings
