import React from 'react';
import { PropTypes } from 'prop-types'; // , instanceOf

// import { withRouter } from 'react-router-dom';
// import { withCookies, Cookies } from 'react-cookie';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles.js';

import { Button, Typography } from '@material-ui/core';
// Grid, Paper, CircularProgress, TextField
import classNames from 'classnames';
// import CustomModal from 'components/common/mobile/CustomModal';
// import isEqual from 'react-fast-compare';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

// import MuiDialogTitle from '@material-ui/core/DialogTitle';
// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';

import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import CheckIcon from '@material-ui/icons/Check'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'

// const DialogTitle = withStyles(theme => ({
//     root: {
//         borderBottom: `1px solid ${theme.palette.divider}`,
//         margin: 0,
//         padding: "16px 24px"
//     },
//     closeButton: {
//         position: 'absolute',
//         right: 4,
//         top: 8,
//         color: theme.palette.grey[500],
//     },
// }))(props => {
//     const { children, classes, onClose } = props;
//     return (
//         <MuiDialogTitle disableTypography className={classes.root}>
//             <Typography variant="h6">{children}</Typography>
//             {onClose ? (
//                 <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
//                     <CloseIcon />
//                 </IconButton>
//             ) : null}
//         </MuiDialogTitle>
//     );
// });

class ConfirmDialog extends React.Component {

    // static propTypes = {
    //   cookies: instanceOf(Cookies).isRequired
    // };

    constructor(props) {
        super(props)
        this.state = {
            confirmDialogVisible: false
        }
    }

    componentDidMount() {
    }

    componentWillMount() {
    }

    componentDidUpdate(prevProps) {
        //  console.log("componentDidUpdate confirm dialog " + this.props.visible);
        if (this.props.visible === true) {

            // var actionItems = this.actionItemsToImmutable(this.props.actionItems);
            if (this.state.confirmDialogVisible !== true) {
                this.setConfirmDialogWindowVisible(true);
            }

        } else {
            if (this.state.confirmDialogVisible === true) {
                this.setConfirmDialogWindowVisible(false);
            }
        }
    }

    setConfirmDialogWindowVisible = (value) => {
        //console.log("setConfirmDialogWindowVisible " + value);
        if (value === false) {
            this.props.onClose();
        }
        // this.props.visible = value;
        this.setState({ ...this.state, confirmDialogVisible: value });
    }

    localActionHandler = () => {
        this.props.actionHandler();
        this.props.postActionHandler();
        this.setConfirmDialogWindowVisible(false);

    }

    render() {
        const { classes, type, title, message, actionCancelText = "Cancel" } = this.props;

        return (
            <div>
                <Dialog
                    id="confirm-dialog"
                    fullScreen={false}
                    classes={{
                        paper: classes.dialogPaperForNotes
                    }}
                    open={this.state.confirmDialogVisible}
                    onEnter={this.handleOnShowDialogPatientRefused}
                    aria-labelledby="confirm-title"
                >
                    {/* <DialogTitle id="confirm-title" onClose={() => this.setConfirmDialogWindowVisible(false)}>
                        {title}
                    </DialogTitle> */}
                    <DialogContent className={classes.content}>
                        <Grid
                            container
                            alignItems="center"
                            style={{
                                flexWrap: 'nowrap'
                            }}
                        >
                            {type === "warning"
                                ?
                                <Avatar className={classes.warningAvatar}>
                                    <PriorityHighIcon className={classes.warningIcon} />
                                </Avatar>
                                :
                                <Avatar className={classes.confirmAvatar}>
                                    <CheckIcon className={classes.confirmIcon} />
                                </Avatar>
                            }
                            <Typography className={classes.confirmTitle}>{title}</Typography>
                        </Grid>
                        <div className={classes.confirmMessage}>{message}</div>
                    </DialogContent>
                    <DialogActions className={classes.actions}>
                        {actionCancelText !== "" &&
                            <Button
                                className={classNames(classes.actionButton, classes.cancelButton)}
                                onClick={() => this.setConfirmDialogWindowVisible(false)}>{actionCancelText}</Button>
                        }
                        <Button
                            variant="contained"
                            className={classNames(classes.actionButton)}
                            onClick={this.localActionHandler}>
                            {this.props.actionText}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

ConfirmDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    // actionHandler: PropTypes.object.isRequired,

};

export default withStyles(styles)(ConfirmDialog);
