import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.js'
import classNames from 'classnames'

import { withCookies } from 'react-cookie'
import { connect } from "react-redux"
import moment from 'moment'
// import uuid from "uuid"

import Typography from '@material-ui/core/Typography'

import UpcomingOrdersListItem from './UpcomingOrdersListItem'

const mapStateToProps = state => {
    return { 
        // drawData: state.drawData,
        // futureOrders: state.futureOrders
    }
}

const UpcomingOrdersListFunctionClass = (props) => {
    //const { drawData, futureOrders, classes } = props
    const {  draw_list, classes ,collection_date} = props
    // const [active, setActive] = useState(0)
    const active = 0
    // console.log("draw_list UpcomingOrdersListFunctionClass")
    // console.log(draw_list)

    // order by collection_time
    // var ordered = []
    // if (draw_list !== undefined) {
    //     ordered = draw_list.sort((a, b) => moment(a.collection_time, "HH:mm:ss").utc() - moment(b.collection_time, "HH:mm:ss").utc())
    // }

    // useEffect(() => {
    //     if (ordered.length > 0) {
    //         setActive(ordered[0].id)
    //     }
    // }, [ordered])

    const [expanded, setExpanded] = useState("")

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }

    return (
        <>
            <div className={classNames(classes.appointmentListContainer)}>
                <Typography className={classes.appointmentsLabel}>{moment(collection_date, "YYYY-MM-DD").format("MMMM D, YYYY")}</Typography>
        
                {draw_list !== undefined && draw_list.map((appt) => {
                    console.log("appointment")
                    console.log(appt)
                    console.log(appt.collection_time)
                    return (
                        // <p>{appt.collection_time}</p>
                            <UpcomingOrdersListItem
                                key={appt.collection_time}
                                appointment={appt}
                                active={active}
                                expanded={expanded}
                                handleChange={handleChange}
                            />
                    

                    )
                })}
            </div>
        </>
    )
}

const UpcomingOrdersList = withCookies(
    connect(mapStateToProps)(
        withStyles(styles, { withTheme: true })(UpcomingOrdersListFunctionClass)
    )
)
export default UpcomingOrdersList
